/*
 * Notifications Form Hooks
 */

import { useMemo } from "react";
import { extractText } from "../../../../utils/extractText";
import { DEFAULT_VALUES } from "./utils";

export const useBasicTexts = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractText(content.text, key);
      return {
        title: e("section_title"),
        proTipsTitle: e("pro_tips_section_title"),
        proTipsText: e("pro_tips_section_text")
      };
    }
    return {};
  }, [content]);
};

export const useFormTexts = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractText(content.text, key);
      return {
        emailTitle: e("email_section_title"),
        mobileTitle: e("mobile_section_title")
      };
    }
    return {};
  }, [content]);
};

export const useDefaultValues = (userData) => {
  const not = userData?.notifications;

  return useMemo(() => {
    return (not || []).reduce((acc, item) => {
      acc[item.id] = item.checked || false;
      return acc;
    }, { ...DEFAULT_VALUES });
  }, [not]);
};
