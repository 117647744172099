import React from "react";
import { Modal } from "components/Modal";
import Typography from "components/Typography";
import Button from "components/Button";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

import classes from './StartOver.module.css';

const StartOver = ({
  show,
  onClose,
  onSubmit
}) => {
  const isNotMobile = useMatchMedia({ minWidth: MQ_BP.small });

  return (
    <Modal
      show={show}
      modalWrapClassnames={classes.wrapper}
      modalClassnames={classes.modal}
      onClose={onClose}
    >
      <Typography
        component="h4"
        variant="headline"
        size="s"
        weight="bold"
      >
        Attention
      </Typography>

      <Typography
        component="p"
        variant="body"
        size="l"
        className={classes.text}
      >
        Do you want to start the process over? All current changes are not saved
      </Typography>

      <div className={classes.actions}>
        <div className={classes.button}>
          <Button
            secondary
            label={isNotMobile ? "Stay here" : "Stay"}
            onClick={onClose}
          />
        </div>
        <div className={classes.button}>
          <Button
            label="Start over"
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default StartOver;
