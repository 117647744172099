/* eslint-disable indent */
import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { MatchMedia } from "../../../MatchMedia";
import Typography from "../../../Typography";
import classes from "./NavMenu.module.css";
import steps from "../steps.json";
import useGetElementContentScroll from "../../../../hooks/useGetElementContentScroll";
import { writeStorage } from "components/Listing/steps/LocationDelivery/storage";

const NavMenuItem = (props) => {
  const {
    id,
    step,
    currStep,
    isCompleted,
    onClick,
    showStatus,
    isNewPhotosFromGarage,
    error,
    showActive
  } = props;

  const isPotosStep = step?.path === "photos";

  if (id || step.path === "general-info") {
    return (
      <li className={classes.item}>
        <NavLink
          onClick={onClick}
          to={{
            pathname: `/listing/${step.path}`,
            search: id ? `?id=${id}` : "",
            state: { prevStep: currStep }
          }}
          className={classnames(
            classes.link,
            classes.linkBasic,
            classes.linkWithAction,
            isCompleted && classes.completedLink,
            showStatus && classes.linkStatus,
            isNewPhotosFromGarage && isPotosStep && classes.isNewPhotosFromGarage,
            error && classes.errorLink
          )}
          activeClassName={
            isCompleted ? classes.completedActiveLink : classes.activeLink
          }
          isActive={(match) => {
            if (!match) {
              return false;
            }

            return showActive;
          }}
        >
          {step.title}
        </NavLink>
      </li>
    );
  }

  return (
    <li className={classes.item}>
      <span
        className={classnames(
          classes.disabledLink,
          showStatus && classes.linkStatus
        )}
      >
        {step.title}
      </span>
    </li>
  );
};

function defineStepNumber(currentLocation, linksData) {
  const currentSplitPath = currentLocation.pathname.split("/");
  const currentPath = currentSplitPath[currentSplitPath.length - 1];
  const currentPathIndex = linksData.findIndex(
    (element) => element.path === currentPath
  );

  if (currentPathIndex !== -1) {
    return currentPathIndex + 1;
  }
}

export const NavMenu = ({
  id,
  completed,
  isNewPhotosFromGarage,
  stepsWithErrors,
  isMobileNavMode,
  setIsMobileNavMode,
  listingPublished
}) => {
  const [listRef, listScroll] = useGetElementContentScroll();
  const location = useLocation();
  const stepsBeforePublished = steps.filter((step) => !step.listingPublished);
  const currentStepIndex = defineStepNumber(location, stepsBeforePublished);
  const currentStep = steps.find((step) =>
    location.pathname.includes(step.path)
  );

  const handleItemClick = useCallback(() => {
    setIsMobileNavMode(false);
    writeStorage({ locationDeliveryRentalTypeTab: 0 });
  }, [setIsMobileNavMode]);

  return (
    <nav
      className={classnames(
        classes.root,
        listScroll.left === 0 && classes.withGradient,
        listScroll.isLeftScrollOffset && classes.leftGradient,
        listScroll.isRightScrollOffset && classes.rightGradient
      )}
    >
      {!listingPublished && currentStepIndex && (
        <MatchMedia mediaQuery="(max-width: 767px)">
          <Typography
            component="p"
            variant="body"
            size="s"
            className="_hidden-all-steps-view"
          >
            <strong>{`Step ${currentStepIndex} of ${stepsBeforePublished.length}:`}</strong>
          </Typography>
        </MatchMedia>
      )}
      <ul ref={listRef} className={`${classes.list} _visible-all-steps-view`}>
        {steps.map((step) =>
          (step.listingPublished === true && !listingPublished) ||
          (step.listingPublished === false && listingPublished) ? null : (
            <NavMenuItem
              key={step.path}
              id={id}
              step={step}
              currStep={currentStep}
              isCompleted={
                completed ? completed.indexOf(step.completed) >= 0 || step.path === "availability" : false
              }
              error={
                stepsWithErrors
                  ? stepsWithErrors.indexOf(step.completed) >= 0
                  : false
              }
              onClick={handleItemClick}
              showStatus={true}
              isNewPhotosFromGarage={isNewPhotosFromGarage}
              showActive={!isMobileNavMode}
            />
          )
        )}
      </ul>
    </nav>
  );
};

NavMenu.propTypes = {
  id: PropTypes.number,
  setIsMobileNavMode: PropTypes.func.isRequired,
  listingPublished: PropTypes.bool,
  stepsWithErrors: PropTypes.arrayOf(PropTypes.string).isRequired
};

NavMenu.defaultProps = {
  listingPublished: false
};
