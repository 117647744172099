import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { useQuery } from "@apollo/client";
import { contentQuery } from "queries";
import { useStripeLoader } from "utils/stripe";
import Spinner from "components/Spinner/Spinner";
import PaymentForm from "./PaymentForm";
import PaymentPreview from "./PaymentPreview";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const PaymentView = (props) => {
  const {
    userData,
    updateUserData,
    content,
    buttonsClassName,
    buttonCancelClassName,
    stepContainerClassNames,
    paymentFormContainerClassName,
    paymentPreviewContainerClassName,
    paymentMethodStepClassName,
    buttonsContainerInnerClassName,
    variant,
    getPreviewButtons,
    onSave,
    textButtonNextStep,
    hasScrollShadow,
    isBookingProcess,
    isMyAccount
  } = props;

  const info = userData?.payment_info;
  const expDate =
    info?.exp_year && info?.exp_month
      ? new Date(info?.exp_year, info?.exp_month, 0)
      : null;
  const today = new Date();
  const isCardExpired = expDate && expDate.valueOf() < today.valueOf();

  const hasFilledData = Boolean(userData?.payment_info?.number);
  const [isEditing, setIsEditing] = useState(!hasFilledData || isCardExpired);
  const stripeLoader = useStripeLoader();
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    true
  );
  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section: "account_section_payment_settings"
    },
    skip: Boolean(content),
    fetchPolicy: "no-cache"
  });

  const resultContent = content || data?.content;

  const cancelEditing = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onSaveData = useCallback(() => {
    onSave?.();
    setIsEditing(false);
  }, [onSave]);

  useEffect(() => {
    if (!data?.content) {
      setShowNotificationPopover(true);
    }
  }, [data]);
  if (loading) {
    return <Spinner position="absolute" />;
  }

  if (!resultContent) {
    return (
      <NotificationPopover
        show={isShowNotificationPopover}
        status="error"
        text="Content not found"
        onClose={() => {
          setShowNotificationPopover(false);
        }}
      />
    );
  }

  if (isEditing || !hasFilledData) {
    return (
      <Elements stripe={stripeLoader}>
        <PaymentForm
          userData={userData}
          updateUserData={updateUserData}
          content={resultContent}
          onSave={onSaveData}
          onCancel={hasFilledData ? cancelEditing : undefined}
          paymentFormContainerClassName={paymentFormContainerClassName}
          buttonsClassName={buttonsClassName}
          buttonCancelClassName={buttonCancelClassName}
          stepContainerClassNames={stepContainerClassNames}
          isCardExpired={isCardExpired}
          textButtonNextStep={textButtonNextStep}
          isMyAccount={isMyAccount}
        />
      </Elements>
    );
  }

  return (
    <PaymentPreview
      userData={userData}
      content={resultContent}
      onStartEditing={() => setIsEditing(true)}
      paymentPreviewContainerClassName={paymentPreviewContainerClassName}
      buttonsClassName={buttonsClassName}
      buttonCancelClassName={buttonCancelClassName}
      stepContainerClassNames={stepContainerClassNames}
      getButtons={getPreviewButtons}
      variant={variant}
      isCardExpired={isCardExpired}
      textButtonNextStep={textButtonNextStep}
      paymentMethodStepClassName={paymentMethodStepClassName}
      buttonsContainerInnerClassName={buttonsContainerInnerClassName}
      hasScrollShadow={hasScrollShadow}
      isBookingProcess={isBookingProcess}
    />
  );
};

PaymentView.propTypes = {
  userData: PropTypes.object,
  updateUserData: PropTypes.func,
  content: PropTypes.object,
  buttonsClassName: PropTypes.string,
  variant: PropTypes.oneOf(["base", "compact"]),
  getPreviewButtons: PropTypes.func
};

export default PaymentView;
