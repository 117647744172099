import { gql } from '@apollo/client';

export const phoneSaveMutation = gql`
  mutation phone_save($code: String, $phone_number: String!) {
    phone_save(code: $code, phone_number: $phone_number) {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
      user {
        phone
        phone_verified
      }
    }
  }
`;
