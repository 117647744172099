import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Button from "../../../../components/Button";
import { useApiButtons, usePreviewTexts } from "./hooks";
import BasicCard from "../../../../components/cards/BasicCard";
import Typography from "../../../../components/Typography";
import Tag from "../../../../components/Tag";
import classes from "./PayoutPreview.module.css";

const PayoutPreview = (props) => {
  const { content, userData, onStartEditing } = props;

  const texts = usePreviewTexts(content);
  const buttons = useApiButtons(content);

  const account = userData?.bank_account;
  const accountType = (account?.account_type?.value || "").toUpperCase();

  return (
    <div className="t-16 b-48 t-m-20 r-m-32 l-m-32 b-m-40">
      <BasicCard
        className={classnames(
          classes.card,
          "t-16 r-20 b-40 l-20",
          "t-m-32 r-m-64 b-m-64 l-m-64",
          "r-l-52 l-l-52",
          "r-xxl-64 l-xxl-64"
        )}
      >
        <div className={classes.cardStripe} />
        <div
          className={classnames(
            classes.header,
            "row no-gutters fbc b-40 b-m-56"
          )}
        >
          <Typography
            className={classnames(classes.heading, "mb-16 mb-m-0")}
            variant="subtitle"
            size="m"
          >
            {texts.title}
          </Typography>
          {accountType && (
            <Tag className={classes.accType} text={accountType} type="alt" />
          )}
        </div>
        <InfoRow label={texts.name}>
          <Typography variant="subtitle" size="l">
            {account?.first_name
              ? [account?.first_name || "", account?.last_name || ""].join(" ")
              : "-"
            }
          </Typography>
        </InfoRow>
        <InfoRow label={texts.bank}>
          <Typography variant="subtitle" size="l">
            {account?.bank_name || "-"}
          </Typography>
        </InfoRow>
        <InfoRow label={texts.account}>
          {account?.account_number ? (
            <Typography className={classes.number} variant="headline" size="l">
              <span className={classes.altColor}>{"••••••"}</span>
              {account?.account_number}
            </Typography>
          ) : (
            <Typography variant="subtitle" size="l" weight="bold">
              {"-"}
            </Typography>
          )}
        </InfoRow>
        <InfoRow label={texts.address}>
          <Typography variant="subtitle" size="l">
            {account?.address ? [
              account?.address,
              account?.city,
              account?.state,
              account?.zip
            ].filter(Boolean).map(i => i.trim()).join(", ") : "-"}
          </Typography>
        </InfoRow>
      </BasicCard>
      {buttons.edit && (
        <div className="t-32 t-m-40">
          <Button
            secondary
            label={buttons.edit.label}
            onClick={onStartEditing}
          />
        </div>
      )}
    </div>
  );
};

const InfoRow = ({ label, children }) => (
  <div className={classes.infoRow}>
    <Typography className={classes.infoRowLabel} variant="subtitle" size="s">
      {label}
    </Typography>
    <div className={classes.infoRowContent}>
      {children}
    </div>
  </div>
);

PayoutPreview.propTypes = {
  content: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  onStartEditing: PropTypes.func.isRequired
};

export default PayoutPreview;
