import React from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Rating from "components/Rating";
import { PhotoReviewGallery } from "components/PhotoReviewGallery/PhotoReviewGallery";
import classes from "./Review.module.css";

export const Review = (props) => {
  const {
    photo: {
      src,
      alt = ''
    },
    name,
    rating,
    date,
    description = '',
    galeryPhotos = []
  } = props;
  const isDescription = description.trim().length !== 0;
  const isGallery = galeryPhotos.length !== 0;

  return (
    <div className={`${classes.container}`}>
      <div className={classes.content}>
        <div className={`${classes.autorPhoto}`}>
          <div className="t-8 b-8 t-m-20 b-m-20">
            <img
              className={`${classes.avatarImg}`}
              src={src}
              alt={alt}
            />
          </div>
        </div>
        <div className={`${classes.autorInfo}`}>
          <div className="r-xl-32">
            <Typography variant="subtitle" size="s" weight="bold">
              {name}
            </Typography>
          </div>

          <div className="l-xl-32 r-xl-32 b-8 b-m-12 t-xl-16">
            <Rating value={rating} />
          </div>

          <div className="l-xl-32 dn db-xl t-xl-8 b-xl-4">
            <Typography variant="body" size="l">
              {date}
            </Typography>
          </div>

        </div>
        { isDescription && (
          <div className={`${classes.description} b-4 t-m-0 b-m-0`}>
            <Typography variant="body" size="l">
              {description}
            </Typography>
          </div>
        )}
        { isGallery && (
          <div className={`${classes.galery} t-8 b-12 mt-12 mb-12 t-m-16 b-m-16 mt-m-16 mb-m-16`}>
            <PhotoReviewGallery photos={galeryPhotos} />
          </div>
        )}
        <div className={`${classes.date} dn-xl`}>
          <Typography variant="body" size="l" className={`${classes.dateText}`}>
            {date}
          </Typography>
        </div>
      </div>

    </div>
  );
};

Review.propTypes = {
  photo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  name: PropTypes.string,
  rating: PropTypes.number,
  date: PropTypes.string,
  description: PropTypes.string,
  galeryPhotos: PropTypes.array
};

