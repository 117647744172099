import React from "react";
import Rating from "components/Rating";
import Typography from "components/Typography";
import classes from "./RatingInfo.module.css";

export const RatingInfo = ({ ratingValue = 0, extraInfoNumber = 10, extraInfoText }) => {

  return (
    <div className={`${classes.container} fsc`}>
      <div className="mr-12">
        <Rating value={ratingValue} />
      </div>
      <div className={classes.extraInfo}>
        <Typography variant="subtitle" size="s">
          {extraInfoText && (
            <span className={classes.extraInfoText}>({extraInfoNumber} {extraInfoText})</span>
          )}
          {!extraInfoText && (
            <span className={classes.extraInfoText}>({extraInfoNumber})</span>
          )}
        </Typography>
      </div>
    </div>
  );
};

// RatingInfo.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

