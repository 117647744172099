import React, { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/client";
import { BookingContentProviderContainer } from "./processes/Booking/BookingContentProviderContainer";

export const BookingWidgetApp = () => (
  <StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <BookingContentProviderContainer />
      </BrowserRouter>
    </ApolloProvider>
  </StrictMode>
);
