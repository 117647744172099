import React from "react";
import PropTypes from "prop-types";
import { FILTERS } from "../../filters";
import MobileFilterBlock from "./MobileFilterBlock";
import Typography from "../../../Typography";
import { getObjectPositiveValuesCount } from "../../../../utils/basic";
import Switch from "../../../form/Switch";
import omit from "lodash/omit";

const MobileFilterAmenities = (props) => {
  const { amenities } = props;

  return (
    <MobileFilterBlock
      id={FILTERS.ids.amenities}
      getLabelFromValue={value => {
        const count = getObjectPositiveValuesCount(value);
        const total = amenities
          ?.map(i => i.items?.length || 0)
          ?.reduce((acc, v) => acc + v, 0);
        return count > 0 ? `${count} out of ${total} selected` : null;
      }}
      renderForm={({ value, onChange }) => (
        <div>
          {amenities?.map(group => (
            <div key={group.category} className="t-8 l-8">
              <Typography
                size="s"
                weight="bold"
                variant="subtitle"
              >
                {group.category}
              </Typography>
              <div className="col">
                {group.items?.map(i => (
                  <div key={i.id} className="row t-2 b-2 l-8 fbc">
                    <Typography size="l" variant="body">
                      {i.name}
                    </Typography>
                    <Switch
                      id={i.id}
                      name={i.id}
                      value={value[i.id] || false}
                      onChange={(checked) => {
                        onChange(omit(
                          { ...value, [i.id]: checked },
                          !checked ? i.id : null
                        ));
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
};

MobileFilterAmenities.propTypes = {
  amenities: PropTypes.array.isRequired
};

export default MobileFilterAmenities;
