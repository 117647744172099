export const ERROR_CODES = {
  LIMIT_PER_DAY: -5,
  USER_IS_NOT_A_RENTER_OR_OWNER_THIS_RENTAL: -49
};

export const ADD_DRIVER_ERROR_CODES = {
  NOTIFICATION_SENDING_ERROR: -37
};

export const OWNER_CANCELLATION_FEE_ERROR_CODE = {
  CHARGE_ON_NEXT_PAYOUT: -40
};

export const CALCULATE_PRICE_ERROR_CODES = {
  MINIMUM_RENTAL_DURATION: -52,
  UNAVAILABLE_DATES_IN_THE_SELECTED_PERIOD: -51
};

export const VIN_ERRORS = {
  VIN_ALREADY_EXISTS: -45
};

export const RESERVE_ERRORS = {
  BOOKING_RENTAL_FEE_LIMIT: -47,
  BOOKING_TII_NOT_APPLICABLE: -48,
  AVAILABILITY_ADD_ONS: -49,
  AVAILABILITY_DELIVERY: -50,
  AVAILABILITY_ADDITIONAL_INSURANCE: -53
};

export const USER_NOT_AUTHORIZED = {
  USER_NOT_AUTHORIZED: -1
};
