import { gql } from '@apollo/client';

export const deleteAccountMutation = gql`
  mutation delete_account {
    delete_account {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
      user {
        email
        email_verified
      }
    }
  }
`;
