function isEmptyString(value) {
  return typeof value === "string" && value.length < 1;
}

function isUndefined(value) {
  return typeof value === "undefined";
}

function isNull(value) {
  return typeof value === "object" && !value;
}

export function buildQueryString(data) {
  return Object
    .keys(data)
    .filter(key => {
      const value = data[key];

      return !(isEmptyString(value) || isUndefined(value) || isNull(value));
    })
    .map(key => {
      const value = data[key];

      if (typeof value === "boolean" || typeof value === "string" || typeof value === "number") {
        return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
      }

      throw new Error(`Unknown type for serialization ${typeof value} (${key})`);
    })
    .join("&");
}

export function parseQueryString(query) {
  if (!query) {
    return {};
  }
  return (query[0] === "?" ? query.substring(1) : query)
    .split("&")
    .filter(Boolean)
    .map(part => part
      .split("=")
      .map(p => decodeURIComponent(p)))
    .reduce((acc, [key, value]) => {
      acc[key] = value;

      return acc;
    }, {});
}
