export function getVerifyData(verifications, verifyList) {
  let count = 0;
  const data = {};

  verifyList.forEach((item) => {
    if (!verifications[item]) {
      data[count] = item;
      count++;
    }
  });

  return data;
}
