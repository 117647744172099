import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { contentQuery } from "../../../../queries";
import { extractText } from "../../../../utils/extractText";
import { extractInfo } from "../../../../utils/extractInfo";

import { GoodSamContent } from "./GoodSamContent";
import { MyAccountContext } from "../../MyAccountContext";
import { SpinnerBlur } from "../../../../components/Spinner/Spinner";

const section = "account_section_good_sam_membership";
const initialContent = {
  sectionTitle: "",
  proTipsSectionTitle: "",
  proTipsSectionText: "",
  sectionDescription: "",
  validateMembershipTitle: "",
  failedValidationMessageTitle: "",
  failedValidationMessageText: "",
  verifiedMemberLabel: "",
  failedValidationLabel: "",
  validateButton: {},
  joinButton: {},
  learnMoreButton: {},
  cancelButton: {},
  confirmButton: {},
  tryAgainButton: {},
  contactButton: {},
  goodSamImage: {}
};

const GoodSamDataProvider = (props) => {
  const [content, setContent] = useState(initialContent);
  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section
    },
    fetchPolicy: "no-cache"
  });

  const { setAccountSection } = props;

  useEffect(() => {
    setAccountSection(section);
  }, []);

  useEffect(() => {
    if (!loading) {
      setContent({
        sectionTitle: extractText(data.content.text, "section_title"),
        proTipsSectionTitle: extractText(
          data.content.text,
          "pro_tips_section_title"
        ),
        proTipsSectionText: extractText(
          data.content.text,
          "pro_tips_section_text"
        ),
        sectionDescription: extractText(
          data.content.text,
          "section_description"
        ),
        validateMembershipTitle: extractText(
          data.content.text,
          "validate_membership_title"
        ),
        failedValidationMessageTitle: extractText(
          data.content.text,
          "failed_validation_message_title"
        ),
        failedValidationMessageText: extractText(
          data.content.text,
          "failed_validation_message_text"
        ),
        verifiedMemberLabel: extractText(
          data.content.text,
          "verified_member_label"
        ),
        failedValidationLabel: extractText(
          data.content.text,
          "failed_validation_label"
        ),
        validateButton: extractInfo(data.content.buttons, "validate_button"),
        joinButton: extractInfo(data.content.buttons, "join_button"),
        learnMoreButton: extractInfo(data.content.buttons, "learn_more_button"),
        cancelButton: extractInfo(data.content.buttons, "cancel_button"),
        confirmButton: extractInfo(data.content.buttons, "confirm_button"),
        tryAgainButton: extractInfo(data.content.buttons, "try_again_button"),
        contactButton: extractInfo(data.content.buttons, "contact_button"),
        goodSamLogo: extractInfo(data.content.images, "good_sam_logo")
      });
    }
  }, [loading, data]);

  if (loading) {
    return <SpinnerBlur />;
  }

  return <GoodSamContent content={content} {...props} />;
};

export const GoodSam = () => (
  <MyAccountContext.Consumer>
    {(value) => <GoodSamDataProvider {...value} />}
  </MyAccountContext.Consumer>
);
