import { gql } from "@apollo/client";
import { contentBlockFragment } from '../../../components/ContentSection/queries/contentQuery';

export const signUpContentQuery = gql`
  query {
    signUpRegistration: content(section: "sign_up_registration") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const signUpPhoneContentQuery = gql`
  query {
    signUpPhone: content(section: "sign_up_phone") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const signUpCongratulationsContentQuery = gql`
  query {
    signUpCongratulations: content(section: "sign_up_congratulations") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;
