import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import classnames from "classnames";
import Button from "../../../Button";
import { closeIcon } from "../../../Icon";
import ActionBar from "../../../ActionBar/ActionBar";
import Popper from "../../../Popper/Popper";
import { useSearchContext } from "../../useSearchContext";
import { FILTERS, isFilterSet } from "../../filters";
import { clearInactiveFilters } from "components/Search/utils";
import { MQ_BP, useMatchMedia } from "../../../MatchMedia";
import classes from "./HeaderFilterButton.module.css";

const HeaderFilterButton = (props) => {
  const {
    className = "",
    id,
    popperClassName = "",
    getLabelFromValue,
    isShowClear = null,
    getSelectedCount,
    renderForm,
    popperAtEnd = false
  } = props;

  const {
    mapOpen,
    isMobileFilters,
    activeFilters,
    selectedClasses,
    filtersControl,
    applyFilters,
    resetFilter,
    isFilterDisabled,
    list,
    searchData
  } = useSearchContext();
  const buttonRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);

  const isLarge = useMatchMedia({
    minWidth: MQ_BP.large,
    maxWidth: MQ_BP.xLarge
  });
  const isXLarge = useMatchMedia({ minWidth: MQ_BP.xLarge });
  const isMediumOrLess = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isSmallFilters = isMediumOrLess || (mapOpen && isLarge);

  useEffect(() => {
    if (isMobileFilters) {
      // Close popup, when filters are going to be mobile
      setPopupOpen(false);
    }
  }, [isMobileFilters]);

  useEffect(() => {
    clearInactiveFilters(popupOpen, activeFilters, filtersControl, searchData.classes);
  }, [popupOpen, activeFilters, filtersControl, searchData.classes]);

  return (
    <Controller
      name={id}
      control={filtersControl}
      render={({ value, onChange }) => {
        const isSet = isFilterSet(id, value);
        const activeValue = activeFilters?.[id];
        const isActiveSet = isFilterSet(id, activeValue);
        let selectedCount = isActiveSet && getSelectedCount
          && getSelectedCount(activeValue);
        if (id === 'class') selectedCount = getSelectedCount(selectedClasses);
        const label = (getLabelFromValue && isActiveSet
          && getLabelFromValue(activeValue))
          || FILTERS.labels[id];
        const isShowClearButton = isShowClear !== null ? isShowClear || isSet : isSet;

        return (
          <>
            <div
              className={classnames(
                className,
                "l-8 l-m-4 l-l-8 l-xl-4 l-xxl-8",
                "r-8 r-m-4 r-l-8 r-xl-4 r-xxl-8"
              )}
            >
              <div className={`${classes.buttonWrapper} ${mapOpen && classes.isMapOpen} ${!mapOpen && classes.isMapClosed}`} ref={buttonRef}>
                <Button
                  className={
                    classnames(
                      classes.button,
                      (selectedCount > 0 || isActiveSet) && classes.buttonDoubleBorder
                    )
                  }
                  disabled={isFilterDisabled(id) && list?.length === 0}
                  iconPosition="after"
                  secondary
                  size={isSmallFilters ? "medium" : undefined}
                  onClick={() => setPopupOpen(true)}
                >
                  {label}
                  {selectedCount > 0 && ` (${selectedCount})`}
                </Button>
              </div>
            </div>
            <Popper
              paperClassName={classnames([popperClassName, classes.popperPaper])}
              open={popupOpen}
              anchorEl={buttonRef.current}
              onClose={() => setPopupOpen(false)}
              placement={popperAtEnd && !isXLarge ? "bottom-end" : "bottom-start"}
              positionFixed={true}
            >
              {renderForm({
                value: value || FILTERS.defaults[id],
                onChange
              })}
              <ActionBar
                className="t-40 b-20"
                onClear={!isShowClearButton ? null : () => {
                  resetFilter(id);
                }}
                onSubmit={() => {
                  setPopupOpen(false);
                  applyFilters();
                }}
              />
            </Popper>
          </>
        );
      }}
    />
  );
};

HeaderFilterButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  getLabelFromValue: PropTypes.func,
  getSelectedCount: PropTypes.func,
  renderForm: PropTypes.func.isRequired,
  popperAtEnd: PropTypes.bool
};

export default HeaderFilterButton;
