import React, { forwardRef, useRef } from "react";
import FieldInput from "../FieldInput/FieldInput";
import { useAutoHeight } from "../useAutoHeight";

const FieldTextarea = forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const handleInput = useAutoHeight(ref || inputRef);

  return (
    <FieldInput
      ref={ref || inputRef}
      {...props}
      type="textarea"
      onInput={handleInput}
    />
  );
});

export default FieldTextarea;
