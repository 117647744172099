import { useRef, useEffect } from "react";

const BAR_HEIGHT = "--cwrr-bottom-bar-height";

/**
 * @param {Element} bar
 * @param {Function} callback
 * @return {(function(): void)|*}
 */
function trackSize(bar, callback) {
  let observer = null;

  try {
    observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target) {
          callback(entry.target.clientHeight);
        }
      }
    });

    observer.observe(bar);
  } catch (e) {
  }

  return () => {
    if (observer) {
      observer.unobserve(bar);
      observer = null;
    }
  };
}


export function useBottomBarSize() {
  const ref = useRef();

  useEffect(() => {
    const bar = ref.current;
    let unsubscribe;

    if (bar) {
      unsubscribe = trackSize(bar, (height) => {
        document.documentElement.style.setProperty(BAR_HEIGHT, height + 'px');
      });

    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      document.documentElement.style.removeProperty(BAR_HEIGHT);
    };
  }, [ref]);

  return ref;
}
