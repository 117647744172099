import React, { useState, useCallback, useMemo } from "react";
import { AdditionalInsuranceItemHeader, AdditionalInsuranceSingleItemContent } from "components/AdditionalInsuranceList";
import Button from "components/Button";
import { chevronDownIcon, chevronUpIcon } from "components/Icon";
import { getItemData, handleSwitchChange } from "components/AdditionalInsuranceList/utils";
import { ADD_INSURANCE_LIST_MODES } from "components/AdditionalInsuranceList/constants";
import classes from "./AdditionalInsuranceSingleItem.module.css";
import cn from 'classnames';

const AdditionalInsuranceSingleItem = (props) => {
  const {
    insuranceId,
    planName,
    insuranceDescription,
    description,
    required,
    selectedPackages = [],
    itemPackages,
    setSelectedPackages,
    mode
  } = props;

  const [showOption, setShowOption] = useState(false);

  const {
    selectedItemPackages,
    isSwitchChecked
  } = useMemo(() => getItemData(
    insuranceId,
    itemPackages,
    selectedPackages?.packagesIds,
    selectedPackages?.insurancesIds
  ), [insuranceId, itemPackages, selectedPackages?.packagesIds, selectedPackages?.insurancesIds]);

  const onShowOption = useCallback(() => {
    setShowOption(show => !show);
  }, []);

  const onSwitchChange = useCallback((checked) => {
    handleSwitchChange(
      insuranceId,
      checked,
      selectedPackages,
      setSelectedPackages
    );
  }, [insuranceId, selectedPackages, setSelectedPackages]);

  return (
    <div>
      <AdditionalInsuranceItemHeader
        insuranceId={insuranceId}
        planName={planName}
        insuranceDescription={insuranceDescription}
        required={mode === ADD_INSURANCE_LIST_MODES.SETTINGS ? false : required}
        selectedItemPackages={selectedItemPackages}
        isSwitchChecked={isSwitchChecked}
        onSwitchChange={onSwitchChange}
        mode={mode}
      />

      {showOption && (
        <AdditionalInsuranceSingleItemContent
          description={description}
          itemPackages={itemPackages}
          multi_select={false}
          isItemPackagesDisabled={false}
          selectedPackages={selectedPackages}
          mode={mode}
        />
      )}

      {Boolean(description?.value) && mode === ADD_INSURANCE_LIST_MODES.SETTINGS && (
        <div className={cn(!showOption && classes.showOptionButtonMarginTop, showOption && classes.showOptionButtonMarginTopNegative)}>
          <Button
            ghost
            label={showOption ? "Hide description": "Show description"}
            className={classes.showOptionButton}
            onClick={onShowOption}
            size="medium"
            renderAtEnd={() => (
              <span
                className={classes.chevron}
              >
                {showOption ? chevronUpIcon : chevronDownIcon}
              </span>
            )}
          />
        </div>
      )}

      {mode === ADD_INSURANCE_LIST_MODES.USING && (
        <div className={cn(!showOption && classes.showOptionsButtonMarginTop)}>
          <Button
            ghost
            label={showOption? "Hide plans" : "Show plans"}
            className={classes.showOptionsButton}
            onClick={onShowOption}
            size="medium"
            renderAtEnd={() => (
              <span
                className={classes.chevron}
              >
                {showOption ? chevronUpIcon : chevronDownIcon}
              </span>
            )}
          />
        </div>
      )}
    </div>
  );
};

AdditionalInsuranceSingleItem.propTypes = {
};

export default AdditionalInsuranceSingleItem;
