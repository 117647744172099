import icon from "assets/images/good_sam_small_text.svg";
import React from "react";
import classes from "./Rates.module.css";
import cn from 'classnames';
import { calculateTotalCurrencyDollarsAndCents } from "utils/calculateСurrency/calculateTotalCurrency";
import { formatMoney } from "utils/formatNumber";
import { formatInteger } from "utils/formatNumber";

function calculateRentalRateDiscount(numWithoutDiscount, numWithDiscount) {
  const result = (numWithoutDiscount - numWithDiscount) / (numWithoutDiscount / 100);
  return result;
}

export const Rates = ({
  rentalRate,
  insurance,
  insuranceDollars,
  isGoodSamMember,
  isClientGoodSamMember,
  setIsShowBookingInsurance,
  setIsShowBookingMembership,
  setIsShowBookingRentalDetails,
  goodSamMembershipDiscountPlanUserFriendly,
  memberAndSave,
  taxesAndFeesCurrencyData = [],
  goodSamMembershipFeeCurrencyData = [],
  totalCurrencyData = [],
  goodSamMembershipDiscountPlanFeeCurrencyData = [],
  rentalRateDollarsAsPart,
  rentalFeeWithoutDurationDiscount
}) => {
  const totalCurrencyTaxesFeesSumData =
    isGoodSamMember || isClientGoodSamMember
      ? [...taxesAndFeesCurrencyData, ...goodSamMembershipFeeCurrencyData]
      : taxesAndFeesCurrencyData;
  let taxesFeesSum = formatMoney(
    calculateTotalCurrencyDollarsAndCents(totalCurrencyTaxesFeesSumData)
  );
  let totalCurrency = calculateTotalCurrencyDollarsAndCents([
    ...totalCurrencyData,
    ...totalCurrencyTaxesFeesSumData
  ]);
  totalCurrency =
    isGoodSamMember || isClientGoodSamMember
      ? totalCurrency -
        calculateTotalCurrencyDollarsAndCents(
          goodSamMembershipDiscountPlanFeeCurrencyData
        )
      : totalCurrency;

  totalCurrency = formatMoney(totalCurrency);

  const goodSamMemberItem = isClientGoodSamMember || isGoodSamMember;
  const isClientGoodSamMemberClass = isClientGoodSamMember
    ? classes.isClientGoodSamMemberClass
    : null;

  const isGoodSamMembershipDiscountPlanUserFriendly =
    goodSamMembershipDiscountPlanUserFriendly !== undefined &&
    goodSamMembershipDiscountPlanUserFriendly !== "0.00" &&
    goodSamMembershipDiscountPlanUserFriendly !== "0";

  const rentalRateDiscount = calculateRentalRateDiscount(rentalFeeWithoutDurationDiscount, rentalRateDollarsAsPart);
  const isRentalRateDiscount = !Number.isNaN(rentalRateDiscount) && Number(rentalRateDiscount) !== 0;

  return (
    <ul className={classes.root}>
      <li className={`${classes.rentalRate} ${classes.item}`}>
        <span> Rental rate
          {isRentalRateDiscount && (
            <span className={classes.rentalRateDiscount}>
            (${formatInteger(rentalFeeWithoutDurationDiscount)} — <span className={classes.rentalRateDiscountSum}>{rentalRateDiscount.toFixed()}%</span>)
            </span>
          )}
        </span> {rentalRate && <span>${rentalRate}</span>}
      </li>
      {goodSamMemberItem && isGoodSamMembershipDiscountPlanUserFriendly && (
        <li
          className={`${classes.memberSavings} ${classes.item} ${isClientGoodSamMemberClass}`}
          onClick={
            !isClientGoodSamMember
              ? () => setIsShowBookingMembership(true)
              : null
          }
        >
          <img src={icon} alt="Icon" className={classes.smallTextIcon} />
          <span className={`${classes.memberText}`}>Member savings</span>
          {memberAndSave !== null && (
            <span>
              &minus;&nbsp;${goodSamMembershipDiscountPlanUserFriendly}
            </span>
          )}
        </li>
      )}
      <li
        className={`${classes.protection} ${classes.item}`}
        onClick={() => setIsShowBookingInsurance(true)}
      >
        <span className={cn(classes.button, 'underline')}>Good Sam Protection</span>
        {insuranceDollars > 0 && <span>${insurance}</span>}
      </li>

      <li
        className={`${classes.taxesFees} ${classes.item} `}
        onClick={() => setIsShowBookingRentalDetails(true)}
      >
        <span className={cn(classes.button, 'underline')}>Taxes & fees</span>
        {taxesFeesSum &&
          !Number.isNaN(taxesFeesSum) &&
          taxesFeesSum !== "NaN" && <span>${taxesFeesSum}</span>}
      </li>
      <li className={`${classes.total} ${classes.item}`}>
        <span>Total</span>{" "}
        {totalCurrency && totalCurrency !== "NaN" && (
          <span>${totalCurrency}</span>
        )}
      </li>
    </ul>
  );
};
