/*
 * Notifications Form Utils
 */

export const FIELDS_IDS = {
  email_messages: "email_messages",
  email_booking_updates: "email_booking_updates",
  email_rental_reminder: "email_rental_reminder",
  email_rate_experience: "email_rate_experience",
  mobile_messages: "mobile_messages",
  mobile_booking_updates: "mobile_booking_updates",
  mobile_rental_reminder: "mobile_rental_reminder",
  mobile_rate_experience: "mobile_rate_experience"
};

export const DEFAULT_VALUES = {
  ...Object.values(FIELDS_IDS).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {})
};
