import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import classes from "./CheckboxCard.module.css";

const CheckboxCard = (props) => {
  const {
    id,
    className,
    classNameChecked,
    tickClassName,
    children,
    checked,
    onChange,
    variant = "shadow"
  } = props;

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  return (
    <label
      className={classnames([
        className,
        checked ? classes.checkedControl : classes.control,
        {
          [classes.shadow]: variant === "shadow",
          [classes.line]: variant === "line"
        },
        checked && classNameChecked
      ])}
    >
      <input
        id={id}
        type="checkbox"
        className={classes.checkbox}
        checked={checked}
        onChange={handleChange}
      />
      {children}
      <div className={classnames(tickClassName, classes.tick)} />
    </label>
  );
};

CheckboxCard.defaultProps = {
  checked: false
};

CheckboxCard.propTypes = {
  className: PropTypes.string,
  tickClassName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["shadow", "line"])
};

export default CheckboxCard;
