import { useCallback, useState } from "react";

import { useMutation } from "@apollo/client";
import { path } from "ramda";
import { extractSuccess } from "utils/extractErrors";
import { getPathError } from "utils/getPathError";

/**
 * @return {[function(*, *, *): Promise<{images: *, errors: *}>, boolean]}
 */
function usePhotoUpload(uploadMutation, extractData) {
  const extractRvImageUploadSuccess = extractSuccess(extractData);
  const extractUploadedImages = path(["data", extractData, "image"]);

  const [photoImageUpload] = useMutation(uploadMutation, {
    fetchPolicy: "no-cache"
  });
  const [photoImageUploadLoading, setPhotoImageUploadLoading] = useState(false);
  const [rvImageFromGarageUploadLoading, setRvImageFromGarageUploadLoading] = useState(false);

  const handlePhotoImageUpload = useCallback(async (photos, id) => {
    setPhotoImageUploadLoading(true);

    const promises = photos
      .map(async photo => {
        let result = null;
        let error = null;

        try {
          const response = await photoImageUpload({
            variables: {
              id,
              image: photo
            }
          });

          if (extractRvImageUploadSuccess(response)) {
            result = extractUploadedImages(response);
          } else {
            let temp = getPathError(response?.data, extractData);
            error = temp ? temp : 'Unknown error';
          }
        } catch (e) {
          error = e.message;
        }

        return [result, error];
      });

    const batchResult = await Promise.all(promises);

    const [images, errors] = batchResult.reduce((acc, [result, error]) => {
      if (result) {
        acc[0].push(result);
      } else {
        acc[1].push(error);
      }

      return acc;
    }, [[], []]);

    setPhotoImageUploadLoading(false);

    return {
      errors,
      images
    };
  }, [
    photoImageUpload,
    extractRvImageUploadSuccess,
    extractUploadedImages,
    extractData,
    setPhotoImageUploadLoading
  ]);

  const handleRvImageFromGarageUpload = useCallback(async (photoIds, rvId) => {
    setRvImageFromGarageUploadLoading(true);

    const promises = photoIds
      .map(async photo_id => {
        let result = null;
        let error = null;

        try {
          const response = await photoImageUpload({
            variables: {
              rv_id: rvId,
              photo_id: photo_id
            }
          });

          if (extractRvImageUploadSuccess(response)) {
            result = extractUploadedImages(response);
          } else {
            let temp = getPathError(response?.data, extractData);
            error = temp ? temp : 'Unknown error';
          }
        } catch (e) {
          error = e.message;
        }

        return [result, error];
      });

    const batchResult = await Promise.all(promises);

    const [images, errors] = batchResult.reduce((acc, [result, error]) => {
      if (result) {
        acc[0].push(result);
      } else {
        acc[1].push(error);
      }

      return acc;
    }, [[], []]);

    setRvImageFromGarageUploadLoading(false);

    return {
      errors,
      images
    };
  }, [
    photoImageUpload,
    extractRvImageUploadSuccess,
    extractUploadedImages,
    extractData,
    setRvImageFromGarageUploadLoading
  ]);

  return {
    handlePhotoImageUpload,
    photoImageUploadLoading,
    handleRvImageFromGarageUpload,
    rvImageFromGarageUploadLoading
  };
}

export default usePhotoUpload;
