import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import classes from "./GoodsamMembershipOption.module.css";
import Typography from "../Typography";
import Button from "../Button";

const GoodsamMembershipOption = (props) => {
  const {
    id,
    name,
    price,
    description,
    label,
    ctaLabel,
    onSelect,
    isActive
  } = props;

  return (
    <div
      className={classnames(classes.root, {
        [classes.rootWithLabel]: Boolean(label),
        [classes.isActive]: label === "New! Elite"
      })}
    >
      {label && (
        <div className={classes.label}>
          <Typography
            variant="subtitle"
            size="s"
            className={classnames(classes.labelText, classes.paddingReset)}
          >
            {label}
          </Typography>
        </div>
      )}
      <div className={classes.goodsamMembershipOptionContent}>
        <Typography variant="subtitle" size="s">
          {name}
        </Typography>
        <Typography
          variant="headline"
          size="m"
          className={classnames(
            classes.paddingReset,
            "mb-16",
            classes.valueText
          )}
        >
          ${price?.price?.dollars_as_part || ''}
        </Typography>
        <Typography variant="body" size="l" component="div">
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={classes.goodsamMembershipOptionContentDescription}
          />
        </Typography>
      </div>
      <div
        className={`btn-group t-12 t-m-20 b-16 b-m-20 ${classes.goodsamMembershipOptionContentButton}`}
      >
        <Button className="fw" onClick={() => onSelect(id, price)}>
          {isActive ? "Selected" : ctaLabel}
        </Button>
      </div>
    </div>
  );
};

GoodsamMembershipOption.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string,
  ctaLabel: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

export default GoodsamMembershipOption;
