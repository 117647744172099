import React, { useState, useRef, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Slider from "react-slick";
import classes from "./PhotoGridSlider.module.css";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

export const PhotoGridSlider = ({ photos, initialSlide = 1 }) => {
  const [showModalGallery, setShowModalGallery] = useState(false);
  const [slideIndex, setSlideIndex] = useState(initialSlide);
  const [slideIndex2, setSlideIndex2] = useState(initialSlide);
  const isSmallScreen = useMatchMedia({ minWidth: MQ_BP.small });

  useEffect(() => {
    if (slideIndex !== slideIndex2) mySlider?.current?.slickGoTo(slideIndex2 - 1);
  },[slideIndex, slideIndex2]);

  useEffect(() => {
    if (isSmallScreen) {
      setShowModalGallery(false);
      document.body.classList.remove(classes.bodyOverflowHidden);
    }
  },[isSmallScreen]);

  const mySlider = useRef();
  const mySlider2 = useRef();

  const settings = useMemo(() => {
    return {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: initialSlide - 1,
      afterChange: (current) => {
        setSlideIndex(current + 1);
      }
    };
  }, [initialSlide]);

  const settings2 = useMemo(() => {
    return {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: initialSlide - 1,
      afterChange: (current) => {
        setSlideIndex2(current + 1);
      }
    };
  }, [initialSlide]);

  const sliderPhotos = useMemo(() => photos.map(({ id, src, alt }) => (
    <div
      key={id}
      className={classes.sliderItem}
      onClick={() => {
        setShowModalGallery(true);
        document.body.classList.add(classes.bodyOverflowHidden);
      }}
    >
      <img src={src} alt={alt} className={classes.sliderImg}/>
    </div>
  )), [photos]);
  const renderArrows = () => {
    return (
      <div className="container mdl__slider-arrows-desktop-container">
        <div className="row">
          <div className="col-12">
            <div className="mdl__slider-arrows-desktop">
              <button
                className="slick-prev slick-arrow"
                type="button"
                aria-label="Previous"
                onClick={() => {
                  mySlider.current.slickPrev();
                  mySlider2.current.slickPrev();
                }}
              >
                Previous
              </button>
              <button
                className="slick-next slick-arrow"
                type="button"
                aria-label="Next"
                onClick={() => {
                  mySlider.current.slickNext();
                  mySlider2.current.slickNext();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="cwrr-public-rv-grid-modal">
      <div className={`mdl--full-screen mdl--slider ${classes.sliderModal} ${showModalGallery && classes.modalShow}`}>
        <div
          className={classes.modalButtonClose}
          onClick={() => {
            setShowModalGallery(false);
            document.body.classList.remove(classes.bodyOverflowHidden);
          }}
        />

        <div className={` ${classes.sliderModalHeader} mdl__slider-header`}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mdl__slider-header-controls mdl__slider-arrows-mobile">
                  <button
                    className={`slick-prev slick-arrow dn-l ${classes.arrow}`}
                    type="button"
                    aria-label="Previous"
                    onClick={() => {
                      mySlider.current.slickPrev();
                      mySlider2.current.slickPrev();
                    }}
                  >
                Previous
                  </button>

                  <ul className={`mdl__slider-dots ${classes.sliderDotsList}`}>
                    <li className="mdl__slider-dot js-mdl-slide-index">{slideIndex2}</li>
                    <li className="mdl__slider-dot">/</li>
                    <li className="mdl__slider-dot js-mdl-slide-count">
                      {photos.length}
                    </li>
                  </ul>
                  <button
                    className={`slick-next slick-arrow dn-l ${classes.arrow}`}
                    type="button"
                    aria-label="Next"
                    onClick={() => {
                      mySlider.current.slickNext();
                      mySlider2?.current?.slickNext();
                    }}
                  >
                Next
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <Slider
              ref={(slider) => (mySlider2.current = slider)}
              {...settings2}
              className="col-12 col-l-10 mdl-slider"
            >
              {photos.map(({ id, src, alt }) => {
                return (
                  <div key={id}>
                    <div className="mdl-slider__slide-spacer">
                      <div className="mdl-slider__media r-8 l-8">
                        <img
                          className="mdl-slider__img"
                          src={src}
                          alt={alt}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div className="dn db-l">
              {renderArrows()}
            </div>
          </div>
        </div>
      </div>






      <div className={classes.sliderContainer}>

        <div className={classes.slider}>
          <Slider
            ref={(slider) => (mySlider.current = slider)}
            {...settings}
            className={classes.sliderItems}
          >
            {sliderPhotos}
          </Slider>
        </div>

        <div className={classes.sliderControls}>

          <button
            id="prev"
            className={`${classes.sliderArrowPrev} slick-prev slick-arrow`}
            type="button"
            aria-label="Previous"
            onClick={() => {
              mySlider.current.slickPrev();
              mySlider2?.current?.slickPrev();
            }}
          >
          Previous
          </button>

          <button
            className={`${classes.sliderArrowNext} slick-next slick-arrow`}
            type="button"
            aria-label="Next"
            onClick={() => {
              mySlider.current.slickNext();
              mySlider2?.current?.slickNext();
            }}
          >
          Next
          </button>
        </div>
        <div className={classes.sliderDots}>
          <Typography variant="body" size="l">
            {slideIndex}
          </Typography>
          <Typography variant="body" size="l">/</Typography>
          <Typography variant="body" size="l">
            {photos.length}
          </Typography>
        </div>
      </div>
    </div>
  );
};

PhotoGridSlider.propTypes = {
  photos: PropTypes.array.isRequired,
  initialSlide: PropTypes.number
};

