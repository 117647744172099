import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import BasicCollapse from "components/RcCollapse/BasicCollapse";
import Typography from "components/Typography";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

const RequestCollapse = ({
  className,
  iconClassName,
  iconOpenStateClassName,
  iconCloseStateClassName,
  header,
  subheader,
  openHeader,
  content,
  defaultOpen,
  columnReverse,
  toRight,
  noCollapse
}) => {
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });

  const renderHeader = (open) => (
    <div className={classnames("", toRight ? "r-8" : "r-16", { "tar": toRight })}>
      <Typography
        {...(isMaxSmall
          ? { variant: "subtitle", size: "s" }
          : { variant: "body", size: "l" })}
      >
        {(open && openHeader) || header}
      </Typography>
      {!open && subheader}
    </div>
  );

  if (noCollapse) {
    return (
      <div className={className}>
        {renderHeader(true)}
        {content}
      </div>
    );
  }

  return (
    <div className={className}>
      <BasicCollapse
        defaultOpen={defaultOpen}
        columnReverse={columnReverse}
        headerClassName="r-16 r-m-0"
        iconClassName={iconClassName}
        iconOpenStateClassName={iconOpenStateClassName}
        iconCloseStateClassName={iconCloseStateClassName}
        renderHeader={renderHeader}>
        {content}
      </BasicCollapse>
    </div>
  );
};

RequestCollapse.defaultProps = {
  defaultOpen: false,
  columnReverse: false,
  toRight: false,
  noCollapse: false
};

RequestCollapse.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.node,
  openHeader: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  defaultOpen: PropTypes.bool,
  columnReverse: PropTypes.bool,
  toRight: PropTypes.bool,
  noCollapse: PropTypes.bool
};

export default RequestCollapse;
