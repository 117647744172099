import { useCallback, useEffect, useRef } from "react";

export const useMessagesInfinitePagination = (listRef, { onFire, disabled }) => {
  const called = useRef();
  const list = listRef.current;

  const onScroll = useCallback((event) => {
    if (disabled || called.current) {
      return;
    }

    const element = event.target;
    const offset = 500;
    if (element.scrollTop <= offset) {
      onFire();
      called.current = true;
    }
  }, [disabled, onFire]);

  useEffect(() => {
    called.current = false;
  }, [onFire]);

  useEffect(() => {
    if (!disabled && list) {
      list.removeEventListener("scroll", onScroll);
      list.addEventListener("scroll", onScroll);
      return () => list.removeEventListener("scroll", onScroll);
    }
  }, [disabled, onScroll, list]);
};
