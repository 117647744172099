import { useEffect, useState } from "react";

export function useRequestErrors(requestErrors, setError) {
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const errors = [];

    requestErrors.forEach(({ field, message }) => {
      if (!field) {
        errors.push(message);
      } else {
        errors.push(message);
        setError(field, {
          type: "server",
          message: message.charAt(0).toUpperCase() + message.slice(1),
          shouldFocus: true
        });
      }
    });

    if (errors.length) {
      setErrors(errors.join(". "));
    } else {
      setErrors("");
    }
  }, [requestErrors, setError]);

  return [errors];
}

export function useErrorsMessage(errors) {
  return errors?.map(i => i.message).join(". ");
}
