import React, { useCallback, useState } from "react";
import Button from "components/Button";
import { ApplyCoupon } from "components/ApplyCoupon/ApplyCoupon";
import Typography from "components/Typography";

import classes from "./Coupon.module.css";

const Coupon = ({ setCoupon, showCouponModal, setShowCouponModal }) => {

  const toggleOpen = useCallback(() => {
    setShowCouponModal((p) => !p);
  }, [setShowCouponModal]);


  const onSave = useCallback(
    (couponData) => {
      setCoupon(couponData);
      toggleOpen();
    },
    [setCoupon, toggleOpen]
  );

  const modalData = {
    title: 'Apply coupon',
    cancelButton: {
      label: 'Cancel'
    },
    confirmButton: {
      label: 'Apply'
    }
  };

  return (
    <>
      <ApplyCoupon
        show={showCouponModal}
        toggleShow={toggleOpen}
        content={modalData}
        onSave={onSave}
      />
    </>
  );
};

export default Coupon;
