import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import Button from "../../../Button/Button";
import Form from "../../../form/Form/Form";
import Message from "../../../Message";
import ProTipsCollapse from "../../../ProTipsCollapse";
import Typography from "../../../Typography";
import { RvDetailsFormContent } from "./RvDetailsFormContent";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

function resetValuesInFields(setValue, values = []) {
  values.forEach(value => {
    setValue(value, '');
  });
}

const DEFAULT_VALUES = {
  length: "",
  weight: "",
  slide_outs: "",
  sleeps: "",
  fuel_type: "",
  seatbelts: "",
  electric_service: "",
  gray_water: false,
  gray_water_tank: "",
  black_water: false,
  black_water_tank: "",
  fresh_water: false,
  fresh_water_tank: "",
  propane: false,
  propane_tank: "",
  vin: "",
  stated_value: "",
  current_miles: ""
};

export const RvDetailsForm = ({
  initialData,
  notApplicableOptions,
  requestErrors,
  onBack,
  onSave,
  onLater,
  loading,
  updateContextSaveHandler,
  listingPublished,
  prevStep,
  nextStep
}) => {
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const defaultValues = Object.assign({}, DEFAULT_VALUES, initialData);
  const {
    control,
    handleSubmit,
    setError,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm({
    defaultValues,
    mode: "onSubmit"
  });
  const [globalError] = useRequestErrors(requestErrors, setError);
  useFooterVisible(isDirty);

  // useEffect(() => {
  //   const values = [
  //     'length',
  //     'weight',
  //     'slide_outs',
  //     'sleeps',
  //     'seatbelts',
  //     'stated_value',
  //     'current_miles',
  //     'fuel_type',
  //     'electric_service'
  //   ];
  //   resetValuesInFields(setValue, values);
  // }, []);

  useEffect(() => {
    updateContextSaveHandler(
      handleSubmit(async (values) => {
        const saveResult = await onSave(values, { toNextStep: false });
        reset(getValues(), {
          keepDirty: false
        });
        return saveResult;
      })
    );
  }, [getValues, handleSubmit, onSave, reset, updateContextSaveHandler]);
  const { electric_service } = watch();

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <div className="row">
        <div className="col b-m-20">
          <Text
            id="step_title"
            section="rvDetailsSectionContent"
            component="h4"
            variant="title"
            weight="bold"
          />
          <ProTipsCollapse className="t-16 b-32" mediaParams="767px" />
        </div>
      </div>
      <RvDetailsFormContent
        control={control}
        errors={errors}
        notApplicableOptions={notApplicableOptions}
        listingPublished={listingPublished}
        electricServiceValue={electric_service}
      />
      {globalError && (
        <div className="t-32 t-s-16 t-m-20">
          <Message type="error" text={globalError} />
        </div>
      )}
      {!listingPublished && (
        <div className="row">
          { nextStep && isSmallDevice && (
            <div className="col-9 t-36 b-36">
              <Button disabled={loading} onClick={onLater} secondary>
                Skip
              </Button>
            </div>
          )}
          <div className="col t-16 t-m-20 b-16 b-m-20">
            <div className="btn-group btn-group--center btn-group--m-start btn-group_xs-fixed">
              {prevStep && (
                <ButtonContent
                  id="previous_button"
                  section="rvDetailsSectionContent"
                  onClick={onBack}
                  disabled={loading}
                  secondary
                />
              )}
              {nextStep && !isSmallDevice && (
                <Button disabled={loading} onClick={onLater} secondary>
                  Skip
                </Button>
              )}
              {nextStep && (
                <ButtonContent
                  id="save_and_next_button"
                  section="rvDetailsSectionContent"
                  type="submit"
                  disabled={loading}
                />
              )}

              {!nextStep && (
                <Button type="submit" disabled={loading}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
