import React from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import classes from "./RentalDetailsStatusBarModal.module.css";

const RentalDetailsStatusBarModal = (props) => {
  const { children, show, onClose } = props;

  return (
    <Modal
      modalClassnames={classes.modal}
      show={show}
      onClose={onClose}
      variant="basic"
      isMobileFullScreen
    >
      {children}
    </Modal>
  );
};

RentalDetailsStatusBarModal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RentalDetailsStatusBarModal;
