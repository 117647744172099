import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useMutation } from "@apollo/client";
import { useForm, useWatch } from "react-hook-form";
import { path } from "ramda";
import {
  extractServerErrors,
  extractSuccess,
  extractServerErrorsCode
} from "../../../../utils/extractErrors";
import { FieldController } from "../../../../components/form/FieldController/FieldController";
import RadioList from "../../../../components/form/Radio/RadioList";
import { FieldInput } from "../../../../components/form/FieldInput";
import { ACCOUNT_TYPES, FIELDS_IDS } from "./utils";
import {
  useApiButtons,
  useApiFields,
  useDefaultValues,
  useFormTexts
} from "./hooks";
import Checkbox from "../../../../components/form/Checkbox";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/Button";
import Form from "../../../../components/form/Form/Form";
import { VALIDATION } from "../../../../utils/validation";
import { Select } from "../../../../components/form";
import { savePayoutSettings } from "./mutations/save";
import DateSelect from "../../../../components/form/DateSelect";
import { DS_IDS } from "../../../../components/form/DateSelect/DateSelect";
import { SpinnerBack } from "../../../../components/Spinner/Spinner";
import { useStripeLoader } from "../../../../utils/stripe";
import { VERIFICATION_ERROR_CODE_LIST, DEFAULT_DATA } from "components/VerificationCodeStepsModals/constants/verificationErrorCodes";
import classes from "./PayoutForm.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import VerificationCodeStepsModals from "components/VerificationCodeStepsModals/VerificationCodeStepsModals";

const mutationName = "payout_info_save";
const extractMutationErrors = extractServerErrors(mutationName);
const extractMutationErrorCode = extractServerErrorsCode(mutationName);
const extractMutationSuccess = extractSuccess(mutationName);
const extractMutationUser = path(["data", mutationName, "user"]);

const PayoutForm = (props) => {
  const {
    content,
    userData,
    updateUserData,
    onSave,
    onSuccessSubmit = () => {},
    submitButtonData: {
      submitButtonType = 'submit',
      submitImmediately = false
    } = {},
    isVerificationsRequired
  } = props;
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );
  const [verificationStepsData, setVerificationStepsData] = useState(DEFAULT_DATA);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [action, result] = useMutation(savePayoutSettings);
  const defaultValues = useDefaultValues(userData);
  const stripeLoader = useStripeLoader();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    shouldUnregister: false,
    defaultValues
  });

  const userAccountNumber = userData?.bank_account?.[FIELDS_IDS.account_number];
  const hasFilledData = Boolean(userAccountNumber);
  const accountType = useWatch({ control, name: FIELDS_IDS.account_type });
  const agreement = useWatch({ control, name: FIELDS_IDS.agreement });
  const isIndividual = accountType === ACCOUNT_TYPES.individual;
  const isCompany = accountType === ACCOUNT_TYPES.company;

  const texts = useFormTexts(content);
  const fields = useApiFields(content);
  const buttons = useApiButtons(content);

  const onSubmit = useCallback(
    async (data) => {
      setIsSubmitting(true);
      setError("");
      const stripe = await stripeLoader;
      const dob = data[FIELDS_IDS._birthday] || {};
      const isCompany = data[FIELDS_IDS.account_type] === ACCOUNT_TYPES.company;

      /**
       * @see https://stripe.com/docs/js/tokens_sources/create_token?type=bank_account
       */
      const bankResult = await stripe.createToken("bank_account", {
        bank_account: {
          country: "US",
          currency: "usd",
          account_holder_name: [
            data[FIELDS_IDS.first_name],
            data[FIELDS_IDS.last_name]
          ].join(" "),
          account_holder_type: isCompany ? "company" : "individual",
          routing_number: data[FIELDS_IDS.routing_number],
          account_number: data[FIELDS_IDS.account_number]
        }
      });

      const error = bankResult?.error?.message;
      if (error) {
        setError(error);
        setIsSubmitting(false);
        return;
      }

      const bankAccount = bankResult?.token?.bank_account;

      if (bankAccount?.id) {

        const variables = {
          ...data,
          date_of_birth_day: undefined,
          date_of_birth_month: undefined,
          date_of_birth_year: undefined,
          date_of_birth: undefined,
          [FIELDS_IDS._birthday]: undefined,
          [FIELDS_IDS.dob_day]: dob[DS_IDS.day],
          [FIELDS_IDS.dob_month]: dob[DS_IDS.month],
          [FIELDS_IDS.dob_year]: dob[DS_IDS.year],
          [FIELDS_IDS.account_number]: bankAccount?.last4,
          [FIELDS_IDS.bank_name]: bankAccount?.bank_name,
          [FIELDS_IDS.token]: bankResult?.token.id,
          [FIELDS_IDS.stripe_bank_account_id]: bankAccount?.id
        };

        const response = await action({ variables });

        if (extractMutationSuccess(response)) {
          const user = extractMutationUser(response);
          if (user) {
            updateUserData(user);
            onSave();
            onSuccessSubmit();
          }
        }

        if (extractMutationErrors(response)?.length > 0) {

          const errorCode = extractMutationErrorCode(response);


          if (VERIFICATION_ERROR_CODE_LIST.includes(errorCode) && isVerificationsRequired) {
            setVerificationStepsData({
              code: errorCode,
              variables: data
            });
          } else {
            setShowNotificationPopover(true);
          }
        }
      } else {
        setError("Unknown error, please try again.");
      }
      setIsSubmitting(false);
    },
    [stripeLoader, action, updateUserData, onSave, onSuccessSubmit, isVerificationsRequired]
  );

  const SsnField = (
    <FieldController
      name={FIELDS_IDS.ssn}
      control={control}
      rules={VALIDATION.rules.getSSN()}
      render={(renderProps) => (
        <FieldInput
          className="fw"
          label={fields.ssn.label}
          errors={errors}
          masked
          {...renderProps}
        />
      )}
    />
  );
  useEffect(() => {
    if (error) {
      setShowNotificationPopover(true);
    }
  }, [error]);

  const mutationErrorText = extractMutationErrors(result)
    .map((e) => e.message)
    .join("");
  const notificationPopoverText = error ? error : mutationErrorText;
  const submitButtonClick = !submitImmediately
    ? (!isDirty ? onSave : undefined)
    : (handleSubmit(onSubmit));

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isSubmitting && <SpinnerBack />}
        {!hasFilledData && (
          <div className="row t-m-12 b-8 t-8 b-m-12">
            <div className="col col-m-6">
              <FieldController
                name={FIELDS_IDS.account_type}
                control={control}
                rules={VALIDATION.rules.required}
                render={(renderProps) => (
                  <RadioList
                    list={[
                      {
                        value: ACCOUNT_TYPES.individual,
                        text: fields.individualAccountType.label
                      },
                      {
                        value: ACCOUNT_TYPES.company,
                        text: fields.companyAccountType.label
                      }
                    ]}
                    {...renderProps}
                  />
                )}
              />
            </div>
          </div>
        )}
        <FieldsGroup label={texts.account}>
          <FieldController
            name={FIELDS_IDS.account_number}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.accountNumber.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
          <FieldController
            name={FIELDS_IDS.routing_number}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.routingNumber.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
          {isIndividual && SsnField}
        </FieldsGroup>
        <FieldsGroup
          label={isIndividual ? texts.name : texts.companyRepresentative}
        >
          <FieldController
            name={FIELDS_IDS.first_name}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.firstName.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
          <FieldController
            name={FIELDS_IDS.last_name}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.lastName.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
          <FieldController
            name={FIELDS_IDS._birthday}
            control={control}
            rules={VALIDATION.rules.date}
            render={(renderProps) => {
              return (
                <DateSelect
                  className={classes.dateSelect}
                  errors={errors}
                  label="Date of birth"
                  {...renderProps}
                />
              );
            }}
          />
          {isCompany && SsnField}
        </FieldsGroup>
        <FieldsGroup label={isIndividual ? texts.address : texts.companyInfo}>
          {isCompany && (
            <FieldController
              name={FIELDS_IDS.company_name}
              control={control}
              rules={VALIDATION.rules.required}
              render={(renderProps) => (
                <FieldInput
                  className="fw"
                  label={fields.companyName.label}
                  errors={errors}
                  {...renderProps}
                />
              )}
            />
          )}
          {isCompany && (
            <FieldController
              name={FIELDS_IDS.company_tax_id}
              control={control}
              rules={VALIDATION.rules.required}
              render={(renderProps) => (
                <FieldInput
                  className="fw"
                  label={fields.companyTaxId.label}
                  errors={errors}
                  {...renderProps}
                />
              )}
            />
          )}
          <FieldController
            name={FIELDS_IDS.address}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.address.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
          <FieldController
            name={FIELDS_IDS.city}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.city.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
          <FieldController
            name={FIELDS_IDS.state}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <Select
                className="fw"
                id={FIELDS_IDS.state}
                label={fields.state.label}
                errors={errors}
                options={[
                  { value: "", text: "" },
                  ...fields.state.options?.map((i) => ({
                    value: i.key,
                    text: i.value
                  }))
                ]}
                {...renderProps}
              />
            )}
          />
          <FieldController
            name={FIELDS_IDS.zip}
            control={control}
            rules={VALIDATION.rules.getZip()}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.zip.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
        </FieldsGroup>
        <div className="t-16 t-s-20 b-16 b-s-20">
          <FieldController
            name={FIELDS_IDS.agreement}
            control={control}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <Checkbox
                {...renderProps}
                text={
                  <div
                    dangerouslySetInnerHTML={{ __html: texts.agreementText }}
                  />
                }
                hasError={!!errors?.[FIELDS_IDS.agreement]}
                defaultChecked={renderProps.value}
                onChange={(event) => renderProps.onChange(event.target.checked)}
              />
            )}
          />
        </div>
        {!isSubmitting && (
          <NotificationPopover
            show={isShowNotificationPopover}
            status="error"
            text={notificationPopoverText}
            onClose={() => {
              setShowNotificationPopover(false);
            }}
            bottomIndent={{
              hasIndent: true,
              size: 'small'
            }}
          />
        )}
        {(buttons.save || hasFilledData) && (
          <div className="df t-16 t-s-20 b-16 b-s-20">
            {buttons.save && (
              <Button
                type={submitButtonType}
                disabled={result.loading || !agreement}
                label={buttons.save.label}
                onClick={submitButtonClick}
              />
            )}
            {buttons.save && hasFilledData && <div className="mr-20 mr-m-32" />}
            {hasFilledData && (
              <Button
                secondary
                disabled={result.loading}
                label="Cancel"
                onClick={onSave}
              />
            )}
          </div>
        )}
      </Form>
      <VerificationCodeStepsModals
        data={verificationStepsData}
        onResetData={setVerificationStepsData}
        onRefetchMutation={onSubmit}
      />
    </>
  );
};

const FieldsGroup = (props) => {
  const { label, children } = props;

  return (
    <div className="b-16 b-m-20">
      <div className="row b-16 b-m-20">
        <div className="col">
          <Typography component="h6" variant="subtitle" size="m">
            {label}
          </Typography>
        </div>
      </div>
      <div className={classnames(classes.fieldsList, "row")}>
        {(Array.isArray(children) ? children : [children])
          .filter(Boolean)
          .map((i, index) => (
            <div key={index} className="col-12 col-m-6 t-16 t-m-20 b-16 b-m-20">
              {i}
            </div>
          ))}
      </div>
    </div>
  );
};

PayoutForm.propTypes = {
  content: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserData: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default PayoutForm;
