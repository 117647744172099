import React from "react";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const ReferralSuccessNotification = ({
  content,
  show,
  onClose,
  isFooterVisible
}) => {

  const onNotificationClose = () => {
    onClose();
  };

  return (
    <NotificationPopover
      show={show}
      status="success"
      title={content.referrals_list_congratulations_title}
      text={content.referrals_list_congratulations_text}
      bottomIndent={{
        hasIndent: isFooterVisible,
        size: 'large'
      }}
      onClose={onNotificationClose}
    />
  );
};

export default ReferralSuccessNotification;
