import React from "react";
import classnames from "classnames";
import classes from "./FieldOptionalInputsTypeTwo.module.css";
import Radio from "../../../../form/Radio";
import { FieldInput } from "../../../../form/FieldInput";
import { FieldController } from "../../../../form/FieldController/FieldController";
import { Text } from "components/ContentSection/elements";

export const FieldOptionalInputsTypeTwo = (props) => {
  const {
    nameNothing,
    nameOptional,
    nameInputFirst,
    nameInputSecond,
    labelNothing,
    labelShow,
    labelHide,
    labelInputFirst,
    labelInputSecond,
    unitsInputFirst,
    unitsInputSecond,
    className,
    control,
    errors,
    validation,
    disabled,
    watch,
    setValue
  } = props;

  const fieldClassName = classnames(className, classes.root);
  const showOptional = watch(nameOptional);
  const nothing = watch(nameNothing);

  return (
    <div className={fieldClassName}>
      <div className={classes.radioRow}>
        <FieldController
          name={nameNothing}
          control={control}
          render={({ value, onChange }) => (
            <Radio
              id={`${nameNothing}`}
              className={classes.radioHide}
              name={`${nameOptional}-switch`}
              text={labelNothing}
              checked={value}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange(e.target.checked);
                  setValue(nameOptional, false);
                }
              }}
              disabled={disabled}
            />
          )}
        />
        <FieldController
          name={nameOptional}
          control={control}
          render={({ value, onChange }) => (
            <>
              <Radio
                id={`${nameOptional}-hide`}
                className={classes.radioHide}
                name={`${nameOptional}-switch`}
                text={labelHide}
                checked={!value && !nothing}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange(!e.target.checked);
                    setValue(nameNothing, false);
                  }
                }}
                disabled={disabled}
              />
              <Radio
                id={`${nameOptional}-show`}
                className={classes.radioShow}
                name={`${nameOptional}-switch`}
                text={labelShow}
                checked={value && !nothing}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange(e.target.checked);
                    setValue(nameNothing, false);
                  }
                }}
                disabled={disabled}
              />
            </>
          )}
        />
      </div>
      {showOptional && (
        <div className={classes.fieldsWrap}>
          <div className={classes.inputWrap}>
            <FieldController
              name={nameInputFirst}
              control={control}
              rules={
                validation && validation[nameInputFirst]
                  ? validation[nameInputFirst]
                  : null
              }
              disabled={disabled}
              render={(renderProps) => (
                <FieldInput
                  id={`${nameInputFirst}`}
                  label={labelInputFirst}
                  helperMessage={
                    <Text
                      id="hours_included_description"
                      section="otherFeesAddonsSectionContent"
                    />
                  }
                  className={classes.input}
                  errors={errors}
                  {...renderProps}
                >
                  <div className={classes.inputUnits}>{unitsInputFirst}</div>
                </FieldInput>
              )}
            />
          </div>
          <div className={classes.inputWrap}>
            <FieldController
              name={nameInputSecond}
              control={control}
              rules={
                validation && validation[nameInputSecond]
                  ? validation[nameInputSecond]
                  : null
              }
              disabled={disabled}
              render={(renderProps) => (
                <FieldInput
                  id={`${nameInputSecond}`}
                  label={labelInputSecond}
                  className={classes.input}
                  errors={errors}
                  {...renderProps}
                >
                  <div className={classes.inputUnits}>{unitsInputSecond}</div>
                </FieldInput>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

FieldOptionalInputsTypeTwo.defaultProps = {
  unavailableMessage: "Allow unlimited miles",
  availableMessage: "Set my own rates"
};
