import React from "react";
import Button from "components/Button";
import cn from 'classnames';
import classes from './AdditionalDrivers.module.css';

const AdditionalDriversFooter = (props) => {
  const {
    onClose,
    drivers
  } = props;

  return (
    <div className={classes.footer}>
      <div className={classes.footerButtonsContainer}>
        <Button
          label="Next"
          type="submit"
          disabled={drivers?.length === 0}
        />
      </div>
    </div>
  );
};

export default AdditionalDriversFooter;
