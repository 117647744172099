/*
 * Filters Utils
 */

import NumberRangePicker from "../form/NumberRangePicker/NumberRangePicker";
import size from "lodash/size";
import { includesSubArray } from "../../utils/basic";
import { ReactComponent as IconStandardRV } from "assets/images/standard-rental.svg";
import { ReactComponent as IconDeliveryOnlydRV } from "assets/images/delivery-only-rental.svg";
/*
 * Constants
 */
export const INITIAL_RENTAL_TYPE_DATA = [
  {
    id: 0,
    name: 'rentalTypeStandard',
    icon: <IconStandardRV />,
    title: "Standard rental",
    description: "This type of rental may offer both delivery and renter pick up options. If you are looking to rent a towable RV that you’ll pull or if you want to drive the RV yourself, this is the option for you. This rental type may also offer custom delivery options if you would like the RV delivered to your destination by the owner.",
    selected: false
  },
  {
    id: 1,
    name: "rentalTypeDeliveryOnly",
    icon: <IconDeliveryOnlydRV />,
    title: "Delivery only rental",
    description: "Are you looking to rent a larger RV, or don’t want the stress and hassle of driving or towing someone else’s property? This rental type is for you. These RVs are only available for delivery. Each owner sets their own rates and radius, so you will be able to see if delivery is available to your destination and how much it will cost.",
    selected: false
  }
];

const FILTERS_IDS = {
  class: "class",
  length: "length",
  price: "price",
  amenities: "amenities",
  instantRentalsOnly: "instantRentalsOnly",
  rentalTypeStandard: 'rentalTypeStandard',
  rentalTypeDeliveryOnly: 'rentalTypeDeliveryOnly',
  rentalType: 'rentalType'
};

export const FILTERS = {
  ids: FILTERS_IDS,
  labels: {
    [FILTERS_IDS.class]: "RV class",
    [FILTERS_IDS.length]: "Length",
    [FILTERS_IDS.price]: "Price",
    [FILTERS_IDS.amenities]: "Amenities",
    [FILTERS_IDS.instantRentalsOnly]: 'instantRentalsOnly',
    [FILTERS_IDS.rentalType]: 'Rental types'
  },
  defaults: {
    [FILTERS_IDS.class]: {},
    [FILTERS_IDS.length]: NumberRangePicker.initialValue,
    [FILTERS_IDS.price]: NumberRangePicker.initialValue,
    [FILTERS_IDS.amenities]: {},
    [FILTERS_IDS.instantRentalsOnly]: { isMobileChecked: false, checked: false },
    [FILTERS_IDS.rentalType]: {
      standard: false,
      deliveryOnly: false
    }
  }
};

/*
 * Utilities
 */

export function isFilterSet(id, value) {
  if (size(value) === 0) {
    return false;
  }

  if (FILTERS.defaults[id] === NumberRangePicker.initialValue) {
    return value?.start !== NumberRangePicker.initialValue.start ||
      value?.end !== NumberRangePicker.initialValue.end;
  }

  if (id === 'instantRentalsOnly') {
    return value.isMobileChecked;
  }

  if (id === 'rentalType') {
    return value.standard || value.deliveryOnly;
  }

  return true;
}

export function getSetFiltersIds(filters) {
  return Object.keys(filters || {}).filter(name => {
    return isFilterSet(name, filters[name]);
  });
}

export function filterList(list, filters) {
  if (!size(list) || !Array.isArray(list)) {
    return [];
  }

  // Extracting only set filters
  const activeFiltersIds = getSetFiltersIds(filters);

  if (!activeFiltersIds.length) {
    return list;
  }

  // Preparing filters values
  const resultFilters = activeFiltersIds.reduce((acc, id) => {
    let value = filters[id];
    switch (id) {
    case FILTERS.ids.class:
      value = Object.keys(value);
      break;
    case FILTERS.ids.amenities:
      value = Object.keys(value).map(i => parseInt(i));
      break;
    default:
      break;
    }
    acc[id] = value;
    return acc;
  }, {});

  // Filtering
  // console.log("list", list, "activeFiltersIds", activeFiltersIds, "resultFilters", resultFilters, filters);
  const newList = list.filter(item => {
    return activeFiltersIds.every(id => {
      const filterValue = resultFilters[id];

      switch (id) {
      case FILTERS.ids.class:
        return filterValue.includes(item.cl);
      case FILTERS.ids.price:
        return filterValue.start <= item.pr && item.pr <= filterValue.end;
      case FILTERS.ids.length:
        return filterValue.start <= item.le && item.le <= filterValue.end;
      case FILTERS.ids.rentalType:
        if (filterValue.standard && !filterValue.deliveryOnly) {
          return item.rt === 1 || item.rt === 3;
        } else if (!filterValue.standard && filterValue.deliveryOnly) {
          return item.rt === 2 || item.rt === 3;
        } else {
          return true;
        }
      case FILTERS.ids.amenities:
        return includesSubArray(item.am, filterValue);
      case FILTERS.ids.instantRentalsOnly:
        if (filterValue.checked) {
          return item.ir;
        } else {
          return true;
        }

      default:
        break;
      }
      return false;
    });
  });

  return newList;
}
