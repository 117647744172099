import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./style.css";

const SingleSlider = (props) => {
  const { className, inverseColors = false, ...other } = props;
  // TODO: Slider does not support "dragging" class yet:
  //  https://github.com/react-component/slider/pull/724
  const [isDragging, setIsDragging] = useState();
  const onBeforeChange = useCallback(() => setIsDragging(true), []);
  const onAfterChange = useCallback(() => setIsDragging(false), []);

  return (
    <div
      className={classnames([
        className,
        "slider",
        "slider--is-single",
        {
          "slider--dragging": isDragging,
          "slider--inverse-colors": inverseColors
        }
      ])}
    >
      <Slider
        {...other}
        onBeforeChange={onBeforeChange}
        onAfterChange={onAfterChange}
      />
    </div>
  );
};

SingleSlider.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  inverseColors: PropTypes.bool
};

export default SingleSlider;

