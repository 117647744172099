import React, { useState, useEffect } from "react";
import { CharactList } from "processes/RvProfile/components/CharactList/CharactList";
import ModalDetails from "components/ModalDetails/ModalDetails";
import Button from "components/Button";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import classes from "./CharacteristicSection.module.css";

const charactListData = [
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-length.svg',
    title: 'Length',
    numb: '5461 ft 3 in'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-weight.svg',
    title: 'Weight',
    numb: '45455 lb'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-slideout.svg',
    title: 'Slide Outs',
    numb: '4'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-length.svg',
    title: 'Length',
    numb: '5461 ft 3 in'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-weight.svg',
    title: 'Weight',
    numb: '45455 lb'
  },
  {
    icon:  'https://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-black-water.svg',
    title: 'Slide Outs',
    numb: '4'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-length.svg',
    title: 'Length',
    numb: '5461 ft 3 in'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-weight.svg',
    title: 'Weight',
    numb: '45455 lb'
  },
  {
    icon:  'https://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-black-water.svg',
    title: 'Slide Outs',
    numb: '4'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-weight.svg',
    title: 'Weight',
    numb: '45455 lb'
  },
  {
    icon:  'https://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-black-water.svg',
    title: 'Slide Outs',
    numb: '4'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-length.svg',
    title: 'Length',
    numb: '5461 ft 3 in'
  },
  {
    icon:  'http://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-weight.svg',
    title: 'Weight',
    numb: '45455 lb'
  },
  {
    icon:  'https://cw-rr.dev.quintegro.net/assets/site/public/gs/icon-black-water.svg',
    title: 'Slide Outs',
    numb: '4'
  }
];

export const CharacteristicSection = () => {
  const [showModalCharactList, setShowModalCharactList] = useState(false);
  const charactListDataMobile = [...charactListData].slice(0, 3);
  const isSmallScreen = useMatchMedia({ minWidth: MQ_BP.small });

  useEffect(() => {
    if (isSmallScreen) {
      setShowModalCharactList(false);
    }
  },[isSmallScreen]);

  return (
    <div className={classes.container}>
      <div className="dn-m">
        <CharactList data={charactListDataMobile} />
      </div>
      <div className="dn db-m">
        <CharactList data={charactListData} />
      </div>
      <div  className="dn-m t-16 b-16 mt-16">
        <Button
          label="All Details"
          onClick={() => setShowModalCharactList(true)}
          secondary
        />
      </div>
      <ModalDetails
        title="All Details"
        show={showModalCharactList}
        onClose={() => {
          setShowModalCharactList(false);
        }}
        modalClassnames={['withoutMenu']}
      >
        {(tabIndex) => (<CharactList data={charactListData} />)}
      </ModalDetails>
    </div>
  );
};

// CharacteristicSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

