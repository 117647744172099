import { gql } from "@apollo/client";

export const referralsQuery = gql`
  query {
    referral_list {
      id
      created
      source
      referralId
      referralEmail
      cases
      status
      amount {
        cents_total
        dollars_as_part
        cents_as_part
        user_friendly
      }
    }
  }
`;
