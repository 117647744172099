import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../form/Form/Form";
import Button from "../../../Button/Button";
import { LocationDeliveryFormContent } from "./LocationDeliveryFormContent";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import Message from "../../../Message";
import { Button as ButtonContent } from "components/ContentSection/elements";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import scrollIntoView from "utils/scrollIntoView";

const isLocationsArray = (item) => {
  return Boolean(item)
    && Array.isArray(item)
    && item?.length !== 0;
};
async function handleScrollIntoView (
  location,
  specificDeliveryLocations0,
  specificDeliveryLocations1,
  trigger
) {
  if (!location?.full_address) {
    scrollIntoView(`.locationScroll`, null, { behavior: "smooth", block: "start" });
  } else {
    const fields = [];
    if (isLocationsArray(specificDeliveryLocations0)) {
      specificDeliveryLocations0.forEach((_, index) => {
        fields.push(`tabs[0].specificDeliveryLocations[${index}].location`);
      });
    }

    if (isLocationsArray(specificDeliveryLocations1)) {
      specificDeliveryLocations1.forEach((_, index) => {
        fields.push(`tabs[1].specificDeliveryLocations[${index}].location`);
      });
    }
    const result = await trigger(fields, { shouldFocus: true });

    if (!result) {
      scrollIntoView(`.field-input.error`, null, { behavior: "smooth", block: "center" });
    }
  }
}

export const LocationDeliveryForm = (props) => {
  const {
    initialData,
    onResetError,
    requestErrors,
    onBack,
    onLater,
    onSave,
    locationError,
    setLocationError,
    loading,
    updateContextSaveHandler,
    listingPublished,
    prevStep,
    nextStep,
    isFooterVisible
  } = props;

  const defaultValues = Object.assign({}, initialData);
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const {
    register,
    watch,
    control,
    control: { trigger },
    handleSubmit,
    setError,
    clearErrors,
    reset,
    getValues,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues,
    mode: "onSubmit",
    shouldUnregister: false
  });
  useFooterVisible(isDirty);
  const { location, tabs } = watch();
  const [globalError] = useRequestErrors(requestErrors, setError);
  const [showRentalTypesError, setShowRentalTypesError] = useState(false);
  const [showDeliveryMethodsError, setShowDeliveryMethodsError] = useState(false);
  const {
    specificDeliveryLocations: specificDeliveryLocations0
  } = tabs[0];

  const {
    specificDeliveryLocations: specificDeliveryLocations1
  } = tabs[1];

  const handleSave = useCallback(
    (values, toNextStep) => {
      const isRentalType = values?.tabs?.[0].allowSwitch || values?.tabs?.[1].allowSwitch;
      const isDeliveryMethodError = values?.tabs?.[1].allowSwitch && !values?.tabs?.[1]?.delivery_option && !values?.tabs?.[1]?.specific_destinations;

      if (values.location?.full_address && isRentalType && !isDeliveryMethodError) {
        onSave(values, { toNextStep });
        reset(getValues(), {
          keepDirty: false
        });
      } else {
        if (!isRentalType) {
          setShowRentalTypesError(true);
          scrollIntoView(`.allowSwitch-0`, null, { behavior: "smooth", block: "start" });
          scrollIntoView(`.allowSwitch-1`, null, { behavior: "smooth", block: "start" });
        }

        if (isDeliveryMethodError) {
          setShowDeliveryMethodsError(true);
          scrollIntoView(`.delivery_option-1`, null, { behavior: "smooth", block: "center" });
        }
      }
    },
    [getValues, onSave, reset]
  );

  useEffect(() => {
    updateContextSaveHandler(
      handleSubmit((values) => handleSave(values, false))
    );
  }, [handleSave, handleSubmit, onSave, updateContextSaveHandler]);

  useEffect(() => {
    if (locationError) {
      scrollIntoView(`.locationScroll`, null, { behavior: "smooth", block: "start" });
      setLocationError(null);
    }
  }, [locationError, setLocationError]);

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <NotificationPopover
        show={showRentalTypesError}
        status="error"
        text={"Please allow at least one of the rental types"}
        onClose={() => {
          setShowRentalTypesError(false);
        }}
        bottomIndent={{
          hasIndent: isFooterVisible,
          size: 'Xlarge'
        }}
      />

      <NotificationPopover
        show={showDeliveryMethodsError}
        status="error"
        text={"Please select at least one delivery method for Delivery only rental"}
        onClose={() => {
          setShowDeliveryMethodsError(false);
        }}
        bottomIndent={{
          hasIndent: isFooterVisible,
          size: 'Xlarge'
        }}
      />
      <LocationDeliveryFormContent
        initialData={initialData}
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        nextStep={nextStep}
        loading={loading}
        onLater={onLater}
      />

      {globalError?.length > 0 && (
        <NotificationPopover
          show
          status="error"
          text={globalError}
          onClose={() => {
            onResetError([]);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}

      {!listingPublished && (
        <div className="row">
          { nextStep && isSmallDevice && (
            <>
              <div className="col-9 t-16 b-16 t-m-20 b-m-20">
                <Button disabled={loading} onClick={onLater} secondary>
                  Skip
                </Button>
              </div>
            </>
          )}
          <div className="col-12 t-16 t-m-52 b-m-20">
            <div className="btn-group btn-group--center btn-group--m-start btn-group_xs-fixed">
              {prevStep && (
                <ButtonContent
                  id="previous_button"
                  section="locationSectionContent"
                  onClick={onBack}
                  disabled={loading}
                  secondary
                />
              )}
              {nextStep && !isSmallDevice && (
                <Button disabled={loading} onClick={onLater} secondary>
                  Skip
                </Button>
              )}
              {nextStep && (
                <ButtonContent
                  id="save_and_next_button"
                  section="locationSectionContent"
                  type="submit"
                  onClick={() => {
                    handleScrollIntoView(
                      location,
                      specificDeliveryLocations0,
                      specificDeliveryLocations1,
                      trigger
                    );
                  }}
                  disabled={loading}
                />
              )}

              {!nextStep && (
                <Button
                  type="submit"
                  disabled={loading}
                  onClick={() => {
                    handleScrollIntoView(
                      location,
                      specificDeliveryLocations0,
                      specificDeliveryLocations1,
                      trigger
                    );
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
