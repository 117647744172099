import React, { useEffect, useRef, useState } from "react";
import { useApolloClient } from "@apollo/client";

function useDisabledState() {
  const client = useApolloClient();
  const [disabled, setDisabled] = useState(false);
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;

    const subscription = client
      .trackRequests()
      .subscribe((value) => {
        setTimeout(() => {
          if (ref.current) {
            setDisabled(value);
          }
        }, 0);
      });

    return () => {
      ref.current = false;
      subscription.unsubscribe();
    };
  }, [client, setDisabled, ref]);

  return disabled;
}

export function withDisableButtonOnQuery(ButtonComponent) {
  const WrappedButtonComponent = (props) => {
    const disabled = useDisabledState();

    return (
      <ButtonComponent disabled={disabled} {...props}/>
    );
  };

  WrappedButtonComponent.displayName = `withDisableButtonOnQuery(${ButtonComponent.displayName || "Button"})`;

  return WrappedButtonComponent;
}
