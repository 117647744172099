import { useContentElement } from "components/ContentSection/useContent";

export const LabelContent = ({ section, id, children }) => {
  const elementById = useContentElement(section, "form", id);

  return children(elementById?.label);
};

export const TextContent = ({ section, id, children }) => {
  const elementById = useContentElement(section, "text", id);

  return children(elementById?.value);
};
