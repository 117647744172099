import React, { useRef } from "react";
import Slider from "react-slick";
import { TabSliderItem } from "processes/RvProfile/components/TabSliderContainer/TabSlider/TabSliderItem";
import classes from "./TabSlider.module.css";

export const TabSlider = ({ data, initialSlide = 1 }) => {
  const mySlider = useRef();
  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  const sliderItems = data.map((item) => (
    <TabSliderItem {...item} />
  ));
  return (
    <div className={classes.sliderContainer}>
      <div className={classes.slider}>
        <Slider
          ref={(slider) => (mySlider.current = slider)}
          {...settings}
          className={classes.sliderItems}
        >
          {sliderItems}
        </Slider>
      </div>
      <div className={`${classes.sliderControls} fcs t-24 b-8`}>

        <button
          className={`${classes.sliderArrow} ${classes.sliderPrevArrow} slick-prev slick-arrow`}
          type="button"
          aria-label="Previous"
          onClick={() => {
            mySlider.current.slickPrev();
          }}
        >
          Previous
        </button>

        <button
          className={`${classes.sliderArrow} ${classes.sliderNextArrow} slick-next slick-arrow ml-16`}
          type="button"
          aria-label="Next"
          onClick={() => {
            mySlider.current.slickNext();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

TabSlider.propTypes = {

};

