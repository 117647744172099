import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const Spinner = (props) => {
  const { className, position } = props;

  return (
    <div
      className={classnames(className, "spinner", {
        "spinner--absolute": position === "absolute"
      })}
    >
      <div className="spinner__icon" />
    </div>
  );
};

export const SpinnerBack = (props) => {
  const { containerClassName } = props;
  return (
    <div
      className={classnames(containerClassName, "spinner-back active")}
    >
      <Spinner {...props} position="absolute" />
    </div>
  );
};

export const SpinnerBlur = (props) => {
  return (
    <div className="spinner-back active blur">
      <Spinner {...props} position="absolute" />
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf(["static", "absolute"])
};

export default Spinner;
