import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import FormContainer from "../../components/FormContainer/FormContainer";
import { SpinnerBlur } from "../../../../components/Spinner/Spinner";
import { contentQuery } from "../../../../queries";
import { MyAccountContext } from "../../MyAccountContext";
import NotificationsForm from "./NotificationsForm";
import { useBasicTexts } from "./hooks";
import PageError from "../../../../components/PageError/PageError";

const section = "account_section_notifications";

const NotificationsDataProvider = (props) => {
  const { userData, setAccountSection } = props;
  const { loading, data } = useQuery(contentQuery, {
    variables: { section },
    fetchPolicy: "no-cache"
  });

  const content = data?.content;
  const texts = useBasicTexts(content);

  useEffect(() => {
    setAccountSection(section);
  }, []);

  if (loading) {
    return (
      <SpinnerBlur />
    );
  }

  if (!content) {
    return (
      <PageError error="Content not found" />
    );
  }

  return (
    <FormContainer
      title={texts.title || ""}
      proTipsTitle={texts.proTipsTitle || ""}
      proTipsText={texts.proTipsText || ""}
      loading={loading}
    >
      <NotificationsForm userData={userData} content={content} />
    </FormContainer>
  );
};

export const Notifications = () => (
  <MyAccountContext.Consumer>
    {value => (
      <NotificationsDataProvider {...value} />
    )}
  </MyAccountContext.Consumer>
);
