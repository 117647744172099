import React, { forwardRef, useEffect, useState } from "react";
import { Circle, GoogleMap } from "@react-google-maps/api";
import style from "./style.module.css";
import { convertMilesToMeters } from "./helpers";
import { useGoogleMaps } from "components/GoogleMapsProvider/GoogleMapsProvider";
import { USA_CENTER } from "utils/map";

const basicCircleOptions = {
  strokeColor: "#4D6539",
  strokeOpacity: 0.3,
  strokeWeight: 2,
  fillColor: "#66864B",
  fillOpacity: 0.15
};

const DEFAULT_LOCATION_ZOOM = 4;

const extractInitialLocation = (v) => {
  if (v.latitude !== "" && v.longitude !== "") {
    return {
      lat: +v.latitude,
      lng: +v.longitude
    };
  }
};

export const MapContentLoader = forwardRef(function MapContentLoader(
  props,
  ref
) {
  const { isLoaded } = useGoogleMaps();
  const {
    paidMilesRadius,
    freeMilesRadius,
    showGeoObjects,
    value,
    className
  } = props;

  const [location, setLocation] = useState({
    full_address: value.full_address,
    coords: extractInitialLocation(value)
  });
  const [zoom, setZoom] = useState(DEFAULT_LOCATION_ZOOM);

  const center = location.coords || USA_CENTER;

  useEffect(() => {
    const distance = paidMilesRadius;
    let zoom;

    if (location.coords) {
      zoom = 12;

      if (distance && showGeoObjects) {
        if (distance > 650) {
          zoom = 4;
        } else if (distance > 330) {
          zoom = 5;
        } else if (distance > 170) {
          zoom = 6;
        } else if (distance > 90) {
          zoom = 7;
        } else if (distance > 40) {
          zoom = 8;
        } else if (distance > 20) {
          zoom = 9;
        } else if (distance > 10) {
          zoom = 10;
        } else if (distance > 5) {
          zoom = 11;
        }
      }
    }

    if (zoom) setZoom(zoom);
  }, [location.coords, paidMilesRadius, showGeoObjects]);

  // TODO: Use getMapCircleOptions

  const circleOptions = {
    ...basicCircleOptions,
    center,
    radius: convertMilesToMeters(1)
  };

  const circleOptionsPaid = {
    ...basicCircleOptions,
    center,
    radius: convertMilesToMeters(paidMilesRadius)
  };

  const circleOptionsFree = {
    ...basicCircleOptions,
    fillColor: "transparent",
    center,
    radius: convertMilesToMeters(freeMilesRadius)
  };

  return isLoaded ? (
    <>
      <div className={className}>
        <GoogleMap
          mapContainerClassName={style.map}
          options={{
            center,
            zoom,
            disableDefaultUI: true,
            fullscreenControl: true
          }}
        >
          {location.coords && (
            <>
              {showGeoObjects && (
                <>
                  <Circle options={circleOptionsPaid} />
                  <Circle options={circleOptionsFree} />
                </>
              )}
              {!showGeoObjects && <Circle options={circleOptions} />}
            </>
          )}
        </GoogleMap>
      </div>
    </>
  ) : (
    "Loading..."
  );
});
