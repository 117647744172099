import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Tag = (props) => {
  const {
    className,
    text,
    type,
    size,
    inverse,
    icon,
    parentRef,
    ...restProps
  } = props;

  const classNames = classnames(
    className,
    "cwrr-tag",
    parentRef && `cwrr-tag--tooltip`,
    type && `cwrr-tag--${type}`,
    size && `cwrr-tag--${size}`,
    inverse && `cwrr-tag--inverse`
  );

  return (
    <>
      <span ref={parentRef} className={classNames} {...restProps}>
        {icon && (
          <img className="cwrr-tag__icon" alt="" src={icon} />
        )}
        {text}
      </span>
    </>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  inverse: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  type: PropTypes.oneOf([
    "success",
    "warning",
    "warning-dark",
    "error",
    "info",
    "default-dark",
    "default",
    "alt"
  ])
};

export default Tag;
