import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Typography from "../Typography";
import Collapse from "rc-collapse";
import classnames from "classnames";
import CollapseIcon from "./CollapseIcon";
import { ProTipsDescription } from "components/ProTipsDescription/ProTipsDescription";
import "rc-collapse/assets/index.css";
import style from "./RcCollapse.module.css";

const RcCollapse = ({ header, content, className }) => {
  const classNames = classnames(style.collapse, className);

  const icon = useCallback(
    ({ isActive }) => (
      <CollapseIcon className={style.collapseIcon} isActive={isActive} />
    ),
    []
  );

  const headerComponent = (
    <Typography component="h6" variant="subtitle" size="m" className={style.title}>
      <strong>{header}</strong>
    </Typography>
  );

  const contentComponent = (
    <div className="b-s-16">
      <ProTipsDescription content={content} />
    </div>
  );

  return (
    <div className={classNames}>
      <Collapse expandIcon={icon}>
        <Collapse.Panel header={headerComponent}>
          {contentComponent}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

RcCollapse.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default RcCollapse;
