import React from "react";
import Typography from "components/Typography";
import Button from "components/Button";
import coverImg from "processes/PublicProfile/images/cover.jpg";
import { ProfileInfo } from "processes/PublicProfile/components/ProfileInfo/ProfileInfo";
import VehicleCard from "components/vehicles/VehicleCard";
import useCardSize from "processes/PublicProfile/hooks/useCardSize";
import { ReviewsShowMore } from "components/Reviews/components/ReviewsShowMore/ReviewsShowMore";
import { ReviewsEmpty } from "components/Reviews/components/ReviewsEmpty/ReviewsEmpty";
import { PublicProfileEmptyState } from "processes/PublicProfile/components/PublicProfileEmptyState/PublicProfileEmptyState";
import classes from "processes/PublicProfile/PublicProfile.module.css";

export const PublicProfileEmpty2 = () => {
  const cardSize = useCardSize();

  const cards = {
    "variant": "col",
    "size": cardSize,
    "imageUrl": "https://cw-rr.dev.quintegro.net/files/rv_images/400/1637483404_php619a038c775d7.376.jpg",
    "link": "https://cw-rr.dev.quintegro.net/files/rv_images/400/1637483404_php619a038c775d7.376.jpg",
    "linkTarget": "_blank",
    "id": 12,
    "title": "12",
    "name": "1983 AIRSTREAM LIMITED-CB",
    "price": 40,
    "deliveredFrom": "Delivered from Joliet, IL",
    "rvClass": "Travel Trailer",
    "length": 3,
    "sleeps": 1,
    "slideOuts": 0,
    "isFavorite": false,
    "onClickFavorite": () => {},
    "className": "dddd",
    "hasButtonControls": true,
    badge: {
      text: 'Active',
      status: 'success'
    }
  };

  const reviews = [

  ];
  const profileInfoData = {
    aboutTitle: '',
    aboutText: '',
    profileInfoButtonLabel: 'Message Owner'
  };
  const profileMainInfoData = {
    name: 'Raymond Johnsson',
    rating: 4,
    address: '',
    date: 'Joined September 2020'
  };

  const avatarData = {
    avatar: {
      src: '',
      alt: ''
    },
    hasBadge: false
  };

  const hasCoverImg = false;
  const isProfileEmpty = true;
  return (
    <div className={classes.publicProfile}>
      <div className={`${classes.headerCover}`}>
        {hasCoverImg && (
          <div className={`${classes.headerCoverInner}  profile-img`}>
            <picture className={`${classes.headerCoverImg} profile-img__img`}>
              <img src={coverImg} alt="" />
            </picture>
          </div>
        )}
      </div>
      <div className={`container b-40 b-m-80 b-l-60 mb-xl-120 b-xl-0`}>
        <div className="row">
          <div className="col-12 col-l-4 col-xl-3">
            <div
              className={`
                ${classes.profileInfoContainer} ${isProfileEmpty && classes.profileEmpty} 
                mr-neg-l-24 mr-neg-xxl-32
            `}>
              <ProfileInfo
                {...profileInfoData}
                profileMainInfoData={profileMainInfoData}
                avatarData={avatarData}
              />
            </div>
          </div>
          <div className="col-12 col-l-7 offset-l-1 col-xl-8 t-2">


            {false && (

              <div className="b-16 b-m-20">

                <div className="devider-dashed mt-16 mb-16 dn-m" />
                <div className="mb-16 mt-m-52 b-m-4 mt-l-60 mb-l-20 mb-xl-16">
                  <Typography variant="subtitle" size="l" weight="bold">
  Vehicle(s)
                  </Typography>
                </div>

                <div className={`${classes.vehicleCards} row`}>
                  <div className="col-12 col-s-6 col-xl-6 t-12 t-m-16 b-12 b-m-16">
                    <VehicleCard {...cards}/>
                  </div>
                  <div className="col-12 col-s-6 col-xl-6 t-12 t-m-16 b-12 b-m-16">
                    <VehicleCard {...cards}/>
                  </div>
                  <div className="col-12 col-s-6 col-xl-6 t-12 t-m-16 b-12 b-m-16">
                    <VehicleCard {...cards}/>
                  </div>
                </div>
                <div className="t-16 b-16 t-m-20 b-m-20 t-l-24 t-xl-20">
                  <Button
                    label="List Your RV"
                    onClick={function noRefCheck(){}}
                    secondary
                  />
                </div>

              </div>
            )}


            {false && (
              <div>

                <div className=" t-m-52 t-l-60 b-m-20">
                  <PublicProfileEmptyState contentText="You haven't added any RV yet" buttonLabel="List Your RV" />
                </div>

              </div>
            )}




            {false && (
              <div>

                <div className="t-4 t-m-20">
                  <ReviewsEmpty contentText="You haven't rented any RV yet" buttonLabel="Search RVs" />
                </div>

              </div>
            )}

            {false && (
              <div>

                <div className={`${classes.reviewsContainer} t-4 t-m-20 mb-16`}>
                  <ReviewsShowMore data={reviews} count={reviews.length} />
                </div>

              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// PublicProfileEmpty2.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };
