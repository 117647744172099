import { useMemo } from "react";
import { useMatchMedia } from "components/MatchMedia";

function useCardSize() {
  const s1 = useMatchMedia({ mediaQuery: "(max-width: 406.98px)" }); // s
  const s2 = useMatchMedia({ mediaQuery: "(min-width: 568px) and (max-width: 781.98px)" }); // s
  const s3 = useMatchMedia({ mediaQuery: "(min-width: 1024px) and (max-width: 1368.98px)" }); // s

  const m1 = useMatchMedia({ mediaQuery: "(min-width: 407px) and (max-width: 462.98px)" }); // m
  const m2 = useMatchMedia({ mediaQuery: "(min-width: 782px) and (max-width: 893.98px)" }); // m
  const m3 = useMatchMedia({ mediaQuery: "(min-width: 1369px) and (max-width: 1452.98px)" }); // m

  const ml1 = useMatchMedia({ mediaQuery: "(min-width: 463px) and (max-width: 542.98px)" }); // ml
  const ml2 = useMatchMedia({ mediaQuery: "(min-width: 894px) and (max-width: 1023.98px)" }); // ml
  const ml3 = useMatchMedia({ mediaQuery: "(min-width: 1453px) and (max-width: 1692.98px)" }); // ml

  const l1 = useMatchMedia({ mediaQuery: "(min-width: 543px) and (max-width: 567.98px)" }); // l
  const l2 = useMatchMedia({ mediaQuery: "(min-width: 1693px)" }); // l

  const cardSize = useMemo(() => {
    if (s1 || s2 || s3) return 's';
    else if (m1 || m2 || m3) return 'm';
    else if (ml1 || ml2 || ml3) return 'ml';
    else if (l1 || l2) return 'l';
  }, [l1, l2, m1, m2, m3, ml1, ml2, ml3, s1, s2, s3]);

  return cardSize;
}

export default useCardSize;
