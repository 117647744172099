import React from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "./TopTripEssentials.module.css";

const topTripMenuData = [
  {
    id: '1',
    src: 'http://cw-rr.dev.quintegro.net/files/promo_shops/1619692430.1186_1b404ae7.png',
    alt: 'Goods for adventure'
  },
  {
    id: '2',
    src: 'http://cw-rr.dev.quintegro.net/files/promo_shops/1619692641.5422_1b404ae7.png',
    alt: 'Goods for adventure'
  },
  {
    id: '3',
    src: 'http://cw-rr.dev.quintegro.net/files/promo_shops/1619692664.0489_1b404ae7.png',
    alt: 'Goods for adventure'
  },
  {
    id: '4',
    src: 'http://cw-rr.dev.quintegro.net/files/promo_shops/1619692652.9939_1b404ae7.png',
    alt: 'Goods for adventure'
  },
  {
    id: '5',
    src: 'http://cw-rr.dev.quintegro.net/files/promo_shops/1619692590.7052_1b404ae7.png',
    alt: 'Goods for adventure'
  }
];

export const TopTripEssentials = () => {

  const topTripMenuItems = topTripMenuData.map(({ id, src, alt }) => (
    <li key={id} className={`${classes.topTripMenuItem} mt-16 mb-16 mt-m-20 mb-m-20 mt-xxl-20`}>
      <div className={`${classes.topTripMenuItemInner}`}>
        <img
          src={src}
          alt={alt}
        />
      </div>
    </li>
  ));

  return (
    <div className={classes.container}>
      <div className={`fcs ${classes.topTripContainer}`}>

        <div className={`fcs ${classes.topTripTitleContainer} mb-16 mb-m-20`}>
          <Typography variant="title" size="5" className={classes.topTripTitle}>
            <strong>Top Trip Essentials</strong><br class="dn db-xxl" /> for your Adventure
          </Typography>
        </div>

        <ul className={`ui-menu ${classes.topTripMenu} mb-16 mb-m-20`}>
          {topTripMenuItems}
        </ul>

        <div
          className={`mt-16 mt-m-20 mb-16 mb-m-20 mt-xxl-0 fcs ${classes.topTripButtonContainer}`}
        >
          <Button
            label="Shop More"
            className=""
          />
        </div>
      </div>
    </div>
  );
};

// TopTripEssentials.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

