import React, { useCallback } from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { ReactComponent as IconDarkSearch } from "components/Widget/components/EmptyCardState/icon-dark-search.svg";
import { ReactComponent as IconLightSearch } from "components/Widget/components/EmptyCardState/icon-light-search.svg";
import classes from "./EmptyCardState.module.css";

const EmptyCardState = () => {
  const { theme, resetAllFilters, loadSearch, ownerDirectory } = useWidgetContext();

  const handleResetAllFilters = useCallback(() => {
    resetAllFilters();
    loadSearch({
      variables: {
        directory: ownerDirectory
      }
    });
  }, [loadSearch, ownerDirectory, resetAllFilters]);

  return (
    <>
      <div className="tac t-16 b-16 t-m-20 b-m-20">
        <div className="t-16 t-m-20 mb-8 mb-m-16">
          { theme === 'light' && <span className={classes.icon}><IconLightSearch /></span>}
          { theme === 'dark' && <span className={classes.icon}><IconDarkSearch /></span>}
        </div>
        <Typography
          className={classes.title}
          variant="subtitle"
          size="l"
          weight="bold"
        >
          No vehicles found
        </Typography>
        <Typography
          className={classes.subtitle}
          variant="body"
          size="l"
        >
          Try changing your filters, adjusting your dates, exploring the map or changing your location
        </Typography>
        <Button
          secondary
          onClick={handleResetAllFilters}
          className={`${classes.button} b-16 b-m-20 mt-16 mt-m-20`}
        >
          Clear all filters
        </Button>
      </div>
    </>
  );
};

export default EmptyCardState;
