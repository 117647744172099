import { gql } from "@apollo/client";

const referralInvite = gql`
  mutation ($emails: [String]) {
    referral_invite(emails: $emails) {
     result {
            success
            errors {
                field
                message
            }
        }
     content
   }
  }
`;

export default referralInvite;
