import { CLUB_IDS }  from "constants/memberShip";

export function isGoodSamMember(membership_details = []) {
  let isMember = false;

  membership_details.forEach(({ club_id, is_member }) => {
    if (CLUB_IDS.GOODSAM === club_id) {
      isMember = is_member;
    }
  });

  return isMember;
}
