import moment from "moment";
import React from "react";
import Typography from "../../../Typography";

export const AvailabilityInfoPeriod = ({ from, to }) => {
  if (!from || !to) {
    return null;
  }

  const periodStart = moment(from).format('MMM D');
  const periodEnd = moment(to).format('MMM D');

  const text = periodStart === periodEnd ? periodStart : `${periodStart} - ${periodEnd}`;

  return (
    <Typography variant="headline" size="m">
      {text}
    </Typography>
  );
};
