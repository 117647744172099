export const setCookie = (name, value, days) => {
  let expires = "";

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = encodeURIComponent(name) + "=" + (encodeURIComponent(value) || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  let nameEQ = encodeURIComponent(name) + "=";
  let cookies = document.cookie.split(';');

  for (let cookie of cookies) {
    let c = cookie;

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }

  return null;
};

export const eraseCookie = (name) => {
  document.cookie = encodeURIComponent(name) + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
