import React, { useState, useRef, useMemo, useEffect, useCallback } from "react";
import { downloadIcon } from "components/Icon";
import { commentIcon } from "components/Icon";
import Slider from "react-slick";
import { Modal } from "components/Modal";
import Button from "components/Button";
import Typography from "components/Typography";
import style from "./style.module.css";

const ModalCarouselInspection = (props) => {
  const {
    showModal,
    onClose,
    photos = [],
    initialSlide = 0,
    modalWrapClassnames
  } = props;
  const mySlider = useRef();
  const [slideIndex, setSlideIndex] = useState(initialSlide);
  const [slides, setSlides] = useState(photos);
  const showArrow = slides?.length > 1;

  const updateSlides = useCallback((currentSlideIndex) => {
    setSlides(slides.map((slide, slideIndex) => ({
      ...slide,
      commentChecked: currentSlideIndex === slideIndex ? !slide.commentChecked : slide.commentChecked
    })));
  }, [slides]);

  useEffect(() => {
    if (!initialSlide) setSlideIndex(1);
    else setSlideIndex(initialSlide + 1);
  }, [initialSlide, setSlideIndex]);

  const settings = useMemo(() => ({
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slideIndex - 1,
    afterChange: current => {
      setSlideIndex(current + 1);
    }
  }), [slideIndex]);

  const renderArrows = () => {
    return (
      <div className="container mdl__slider-arrows-desktop-container">
        <div className="row">
          <div className="col-12">
            <div className="mdl__slider-arrows-desktop">
              <button
                className="slick-prev slick-arrow"
                type="button"
                aria-label="Previous"
                onClick={() => {
                  mySlider.current.slickPrev();
                }}
              >
                Previous
              </button>
              <button
                className="slick-next slick-arrow"
                type="button"
                aria-label="Next"
                onClick={() => {
                  mySlider.current.slickNext();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      onClose={() => {
        onClose();
      }}
      variant="full-screen"
      modalClassnames="mdl--slider"
      modalCrossButtonClassname={style.modalCarouselCrossButton}
      modalWrapClassnames={modalWrapClassnames}
    >
      {showArrow && (
        <div className="mdl__slider-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mdl__slider-header-controls mdl__slider-arrows-mobile">
                  <button
                    className="slick-prev slick-arrow dn-l"
                    type="button"
                    aria-label="Previous"
                    onClick={() => {
                      mySlider.current.slickPrev();
                    }}
                  >
                Previous
                  </button>

                  <ul className="mdl__slider-dots">
                    <li className="mdl__slider-dot js-mdl-slide-index">{slideIndex}</li>
                    <li className="mdl__slider-dot">/</li>
                    <li className="mdl__slider-dot js-mdl-slide-count">
                      {slides.length}
                    </li>
                  </ul>
                  <button
                    className="slick-next slick-arrow dn-l"
                    type="button"
                    aria-label="Next"
                    onClick={() => {
                      mySlider.current.slickNext();
                    }}
                  >
                Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row">
          <Slider
            ref={(slider) => (mySlider.current = slider)}
            {...settings}
            className="col-12 col-l-10 mdl-slider"
          >
            {slides.map(({ id, url, alt, comment = "", caption = "", commentChecked }, index) => (
              <div key={id}>
                <div className={`mdl-slider__slide-spacer ${style.slideContainer}`}>
                  <div
                    className={`${style.slideInner} mdl-slider__media r-8 l-8`}
                  >
                    <div className={style.slide}>
                      {caption?.length !== "" && (
                        <div className={style.positionContainer}>
                          <Typography
                            variant="body"
                            size="l"
                            className={`${style.position} mt-m-12`}
                          >
                            {caption}
                          </Typography>
                        </div>
                      )}
                      <div className={style.photoContainer}>
                        { comment?.length > 0 && (
                          <Button
                            icon={commentIcon}
                            inverse
                            label=""
                            onClick={() => updateSlides(index)}
                            size="medium"
                            className={`${style.commentButton} ${!commentChecked && style.commentButtonNotChecked}`}
                          />
                        )}
                        {url && (
                          <Button
                            icon={downloadIcon}
                            inverse
                            label=""
                            onClick={() => {}}
                            size="small"
                            className={style.downloadButton}
                            href={url}
                            target="_blank"
                          />
                        )}
                        <img
                          className={`${style.photo} mdl-slider__img`}
                          src={url}
                          alt={alt}
                        />
                        {commentChecked && comment?.length > 0 && <div className={style.shadowBottom} />}
                      </div>
                      <div className={style.descriptionContainer}>
                        {commentChecked && (
                          <Typography
                            variant="body"
                            size="l"
                            className={`${style.description} mt-4`}
                          >
                            {comment}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          {showArrow && (
            <div className="dn db-l">
              {renderArrows()}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalCarouselInspection;
