/**
 * @param {String} event
 * @param {Object} [options]
 */
export function pushSegmentAnalyticsTrack(event, options = {}) {
  try {
    const { analytics } = window;

    if (typeof analytics === "object" && typeof analytics.track === "function") {
      analytics.track(event, options);
    }
  } catch (err) {
    console.error(err);
  }
}

/**
 * @param {String} event
 * @param {Object} [options]
 */
export function pushSegmentAnalyticsIdentify(id, options = {}) {
  try {
    const { analytics } = window;

    if (typeof analytics === "object" && typeof analytics.identify === "function") {
      if (typeof id === "undefined") {
        analytics.identify(options);
        return;
      }
      analytics.identify(id, options);
    }
  } catch (err) {
    console.error(err);
  }
}
