import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { pipe } from "ramda";

import { ListingContext } from "../../ListingContext";
import { AvailabilityForm } from "./AvailabilityForm";
import { filterInitialData } from "../filterInitialData";
import { rvVisibilityAndAvailability } from "../../mutations/rvVisibilityAndAvailability";

import { rvDelete } from "../../mutations/rvDelete";
import {
  extractServerErrors,
  extractSuccess
} from "../../../../utils/extractErrors";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { availabilitySectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";

const processInitialData = pipe(
  filterInitialData([
    "availability_calendar",
    "daily_rate",
    "minimum_daily_rate",
    "visible",
    "publicUrl"
  ])
);

const extractRvVisibilitySuccess = extractSuccess("rv_visibility");
const extractRvVisibilityErrors = extractServerErrors("rv_visibility");
const extractRvDeleteSuccess = extractSuccess("rv_delete");
const extractRvDeleteErrors = extractServerErrors("rv_delete");

// TODO: update indents

export const AvailabilityDataProvider = ({
  context,
  updateContext,
  refetchData
}) => {
  const { id: initialId, visible: initialVisible } = context.initialData;
  const history = useHistory();

  const [mutateRvVisibilityAndAvailability, mutationResult] = useMutation(
    rvVisibilityAndAvailability
  );

  const [mutateRvDelete, mutateRvDeleteResult] = useMutation(rvDelete);

  useEffect(() => {
    history.replace(`?id=${initialId}`);
  }, [history, initialId]);

  const handleSave = useCallback(
    async (values) => {
      const response = await mutateRvVisibilityAndAvailability({
        variables: {
          ...values,
          id: initialId,
          availability_calendar: values.availability_calendar
            .map((d) => {
              const price =
                d.price && typeof d.price === "object"
                  ? d.price.dollars_as_part
                  : d.price;

              return {
                date: d.date,
                available: d.available,
                price
              };
            })
            .filter((d) => !d.available || d.price)
        }
      });

      if (extractRvVisibilitySuccess(response)) {
        updateContext({
          initialData: {
            ...context.initialData,
            visible: !initialVisible
          }
        });
      }
    },
    [
      context.initialData,
      initialId,
      initialVisible,
      mutateRvVisibilityAndAvailability,
      updateContext
    ]
  );

  const handleDelete = useCallback(async () => {
    const response = await mutateRvDelete({
      variables: {
        id: initialId
      }
    });

    if (extractRvDeleteSuccess(response)) {
      window.location = '/profile';
    }
  }, [initialId, mutateRvDelete]);

  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  return (
    <>
      <AvailabilityForm
        initialData={processInitialData(context.initialData)}
        requestErrors={[
          ...extractRvVisibilityErrors(mutationResult),
          ...extractRvDeleteErrors(mutateRvDeleteResult)
        ]}
        onSave={handleSave}
        onDelete={handleDelete}
        updateContextSaveHandler={updateContextSaveHandler}
        rvId={initialId}
      />
      {(mutationResult.loading || mutateRvDeleteResult.loading) && (
        <SpinnerBack />
      )}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const availabilitySectionContentPredicate = everyKey([
  "availabilitySectionContent"
]);
export const Availability = () => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <ContentLoader
          query={availabilitySectionContentQuery}
          predicate={availabilitySectionContentPredicate}
          render={() => (
            <AvailabilityDataProvider
              context={context[0]}
              updateContext={context[1]}
              refetchData={context[2]}
            />
          )}
        />
      )}
    </ListingContext.Consumer>
  );
};
