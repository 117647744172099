import moment from "moment";

export function createCalculatePriceFetchData(state, periodFrom, periodTo) {
  const departure_date = moment(periodFrom || state.date.departure).format(
    "YYYY-MM-DD"
  );
  const return_date = moment(periodTo || state.date.return).format(
    "YYYY-MM-DD"
  );
  return {
    departure_date,
    return_date,
    delivery_location: state.deliveryLocation,
    insurance_package: state.insurancePackage,
    good_sam_membership_package: state.goodsamMembershipPlanId,
    rental_type: state.rental_type || 1,
    specific_delivery_location_id: state.specific_delivery_location_id || null
  };
}
