import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const calculatePriceQuery = gql`
  ${baseFragments.money}
  ${baseFragments.rental.price}

  query calculatePrice(
    $rv: Int!
    $current_rental: Int
    $departure_date: String!
    $return_date: String!
    $delivery_location: String
    $coupon_code: String
    $insurance_package: Int
    $add_ons: [Int]
    $good_sam_membership_package: String
    $rental_fee: Int
    $delivery_fee: Int
    $is_trip_interruption_insurance: Boolean
    $date_of_birth: String
    $rental_type: Int
    $specific_delivery_location_id: Int
    $is_predefined: Boolean
    $no_split_payments: Boolean
    $additional_insurances: [Int]
  ) {
    calculate_price(
      rv: $rv
      current_rental: $current_rental
      departure_date: $departure_date
      return_date: $return_date
      delivery_location: $delivery_location
      coupon_code: $coupon_code
      insurance_package: $insurance_package
      add_ons: $add_ons
      delivery_fee: $delivery_fee
      good_sam_membership_package: $good_sam_membership_package
      rental_fee: $rental_fee
      is_trip_interruption_insurance: $is_trip_interruption_insurance
      date_of_birth: $date_of_birth
      rental_type: $rental_type
      specific_delivery_location_id: $specific_delivery_location_id
      is_predefined: $is_predefined,
      no_split_payments: $no_split_payments
      additional_insurances: $additional_insurances
    ) {
      result {
        success
        errors {
          code
          message
        }
      }
      price {
        ...RentalPrice
      }
      price_form_your_suggestion {
        desc {
          id
          value
          type
        }
        total {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        items {
          id
          type
          title {
            id
            value
            type
          }
          value {
            id
            value
            type
          }
          desc {
            id
            value
            type
          }
          style
          separ
          sub_items {
            title {
              id
              value
              type
            } 
            value {
              id
              value
              type
            }
            units
          }
          action {
            title
            id
            pos
          }
        }
      }
      price_form_detailed {
        desc {
          id
          value
          type
        }
        total {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        items {
          id
          type
          title {
            id
            value
            type
          }
          value {
            id
            value
            type
          }
          desc {
            id
            value
            type
          }
          style
          separ
          sub_items {
            title {
              id
              value
              type
            } 
            value {
              id
              value
              type
            }
            units
          }
          action {
            title
            id
            pos
          }
        }
      }
    }
  }
`;
