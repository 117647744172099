import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import {
  Availability,
  GeneralInfo,
  RvDetails,
  ListingDetails,
  Photos,
  Amenities,
  LocationDelivery,
  Fees,
  OtherFees,
  AdditionalInsurance
} from "../../steps";
import { useIdFromLocation } from "../../../../hooks/useIdFromLocation";
import STEPS from "../steps.json";
import { parseQueryString } from "../../../../utils/queryString";

export const StepsRouter = ({
  listingPublished,
  isRvCreated,
  stepsCompleted,
  setFinalModalShow,
  finalModalWasShowed,
  setFinalModalWasShowed,
  scrollToStart
}) => {
  const id = useIdFromLocation();
  const location = useLocation();

  useEffect(() => {
    scrollToStart();
  }, [location.pathname, scrollToStart]);

  const isFromPreview = (search) => {
    const query = parseQueryString(search);
    return query?.from === "preview";
  };

  const areAllStepsFinished = useCallback(
    () =>
      stepsCompleted &&
      STEPS.every(
        (step) =>
          step.listingPublished || stepsCompleted.includes(step.completed)
      ),
    [stepsCompleted]
  );

  const getCurrentStepIndex = useCallback(
    () => STEPS.findIndex((step) => location.pathname.includes(step.path)),
    [location.pathname]
  );

  const getNextStep = useCallback(() => {
    const currentStepIndex = getCurrentStepIndex();

    let nextStep = currentStepIndex !== -1 ? STEPS[currentStepIndex + 1] : null;

    if (stepsCompleted && currentStepIndex !== -1) {
      for (let i = currentStepIndex + 1; i < STEPS.length; i++) {
        if (
          !STEPS[i].listingPublished &&
          !stepsCompleted.includes(STEPS[i].completed)
        ) {
          nextStep = STEPS[i];
          break;
        }
      }

      if (!nextStep) {
        for (let i = 0; i < currentStepIndex; i++) {
          if (
            !STEPS[i].listingPublished &&
            !stepsCompleted.includes(STEPS[i].completed)
          ) {
            nextStep = STEPS[i];
            break;
          }
        }
      }
    }

    return nextStep;
  }, [getCurrentStepIndex, stepsCompleted]);

  const getPrevStep = useCallback(() => {
    /* if (location.state && location.state.prevStep) {
      return location.state.prevStep;
    } */
    const currentStepIndex = getCurrentStepIndex();

    if (currentStepIndex !== -1) {
      return STEPS[currentStepIndex - 1];
    }
  }, [getCurrentStepIndex]);

  const getCurrStep = useCallback(() => {
    const currentStepIndex = getCurrentStepIndex();

    if (currentStepIndex !== -1) {
      return STEPS[currentStepIndex];
    }
  }, [getCurrentStepIndex]);

  useEffect(() => {
    if (stepsCompleted) {
      const steps = areAllStepsFinished();
      const fromPreview = isFromPreview(location.search);

      if (steps && !finalModalWasShowed && !listingPublished && !fromPreview) {
        setFinalModalShow(true);
      }

      if (!steps && !fromPreview) {
        setFinalModalWasShowed(false);
      }

      if (fromPreview) {
        setFinalModalWasShowed(true);
      }
    }
  }, [
    areAllStepsFinished,
    finalModalWasShowed,
    listingPublished,
    location.search,
    setFinalModalShow,
    setFinalModalWasShowed,
    stepsCompleted
  ]);

  if (id && !isRvCreated) {
    // we can't go further because our data is inconsistent state
    return null;
  }

  const routes = [
    {
      path: "/listing/availability",
      exact: true,
      component: Availability,
      condition: isRvCreated && listingPublished
    },
    {
      path: "/listing/general-info",
      exact: true,
      component: GeneralInfo,
      condition: !isRvCreated || !listingPublished
    },
    {
      path: "/listing/rv-details",
      exact: true,
      component: RvDetails,
      condition: isRvCreated
    },
    {
      path: "/listing/listing-details",
      exact: true,
      component: ListingDetails,
      condition: isRvCreated
    },
    {
      path: "/listing/photos",
      exact: true,
      component: Photos,
      condition: isRvCreated
    },
    {
      path: "/listing/amenities",
      exact: true,
      component: Amenities,
      condition: isRvCreated
    },
    {
      path: "/listing/location-delivery",
      exact: true,
      component: LocationDelivery,
      condition: isRvCreated
    },
    {
      path: "/listing/fees",
      exact: true,
      component: Fees,
      condition: isRvCreated
    },
    {
      path: "/listing/add-ons",
      exact: true,
      component: OtherFees,
      condition: isRvCreated
    },
    {
      path: "/listing/additional-insurance",
      exact: true,
      component: AdditionalInsurance,
      condition: isRvCreated
    }
  ];

  const redirects = [
    {
      to: "/listing/general-info",
      condition: !isRvCreated || !listingPublished
    },
    {
      to: "/listing/availability",
      condition: isRvCreated && listingPublished
    }
  ];

  return (
    <Switch>
      {routes.map(({ condition, component: Component, ...routeProps }) =>
        condition ? (
          <Route key={routeProps.path} {...routeProps}>
            <Component
              currStep={getCurrStep()}
              nextStep={getNextStep()}
              prevStep={getPrevStep()}
            />
          </Route>
        ) : null
      )}
      {redirects.map(({ condition, ...redirectProps }) =>
        condition ? (
          <Redirect key={redirectProps.to} {...redirectProps} />
        ) : null
      )}
    </Switch>
  );
};

StepsRouter.propTypes = {
  listingPublished: PropTypes.bool,
  isRvCreated: PropTypes.bool,
  stepsCompleted: PropTypes.arrayOf(PropTypes.string)
};

StepsRouter.defaultProps = {
  listingPublished: false,
  isRvCreated: false
};
