export function doNeedTooltip(value, width) {
  let flag = false;
  const el = document.createElement("span");
  el.innerHTML = value;
  document.body.appendChild(el);
  const w = el.offsetWidth;
  if (w > width) flag = true;
  document.body.removeChild(el);
  return flag;
}
