/* eslint-disable indent */
import React from "react";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { isUserRenter } from "processes/Rentals/utils";

function calculateDiscount(price = {}, initialFee, targetFee) {
  const fee = price?.[initialFee]?.cents_total;
  const discount = price?.[targetFee]?.cents_total;

  if (!fee || !discount) return price?.[initialFee];
  const diffDollars = Math.floor(Number(((fee - discount) / 100)));
  const diffCents = ((fee - discount) % 100);

  return {
    dollars_as_part: diffDollars,
    cents_as_part: diffCents
  };
}

export const useRentalCancellingData = (rental = {}, isIMGlobalIntegrationIsEnabled) => {
  if (!rental) {
    return {
      tableData: [],
      modalTitle: '',
      extraTextData: []
    };
  }

  const {
    role = {},
    price,
    price_refunds_after_potential_cancellation: priceRefunds,
    price_after_potential_cancellation: priceFinalAmount
  } = rental;

  if (!price || !priceRefunds || !priceFinalAmount) {
    return {
      tableData: [],
      modalTitle: '',
      extraTextData: []
    };
  }

  const isRenter = isUserRenter(role);

  const modalTitle = "Fees after cancelling";
  const tableData = [];
  let extraTextData = [];

  if (isRenter) {
    if (
      price.payment_total
      || priceRefunds.payment_total
      || priceFinalAmount.payment_total
    ) {
      tableData.push({
        id: 'row-1',
        textSize: "xLargeNormal",
        extraRowClasses: ['borderTopNone', 'borderTopSolid-db-m', 'borderTopPaddingBottomNone-db-m'],
        cols: [
          {
            id: "col-1",
            content: "Total"
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.payment_total} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.payment_total} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.payment_total} isBold />)
          }
        ]
      });
    }
    if (
      price.rental_fee?.cents_total
      || priceRefunds.rental_fee?.cents_total
      || priceFinalAmount.rental_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-2',
        extraRowClasses: ['borderTopSolid'],
        cols: [
          {
            id: "col-1",
            content: "Rental fee"
          },
          {
            id: "col-2",
            content: (
              <FormattedPrice
                value={calculateDiscount(price, 'rental_fee', 'coupon_discount')}
                isBold
              />
            )
          },
          {
            id: "col-3",
            content: (
              <FormattedPrice
                value={calculateDiscount(priceRefunds, 'rental_fee', 'coupon_discount')}
                isBold
              />
            )
          },
          {
            id: "col-4",
            content: (
              <FormattedPrice
                value={calculateDiscount(priceFinalAmount, 'rental_fee', 'coupon_discount')}
                isBold
              />
            )
          }
        ]
      });
    }
    if (
      price.cleaning_fee?.cents_total
      || priceRefunds.cleaning_fee?.cents_total
      || priceFinalAmount.cleaning_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-3',
        cols: [
          {
            id: "col-1",
            content: 'Cleaning fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.cleaning_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.cleaning_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.cleaning_fee} isBold />)
          }
        ]
      });
    }
    if (
      price.disposal_fee?.cents_total
      || priceRefunds.disposal_fee?.cents_total
      || priceFinalAmount.disposal_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-4',
        cols: [
          {
            id: "col-1",
            content: 'Disposal fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.disposal_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.disposal_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.disposal_fee} isBold />)
          }
        ]
      });
    }
    if (
      price.delivery_fee?.cents_total
      || priceRefunds.delivery_fee?.cents_total
      || priceFinalAmount.delivery_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-5',
        cols: [
          {
            id: "col-1",
            content: 'Delivery fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.delivery_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.delivery_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.delivery_fee} isBold />)
          }
        ]
      });
    }
    const addOns = price.add_ons || priceRefunds.add_ons || priceFinalAmount.add_ons || [];
    const addOnsSubRows = [];

    if (addOns?.length) {
      addOns.forEach((addOn) => {
        addOnsSubRows.push({
          id: `addon_${addOn.id}`,
          textSize: "small",
          extraRowClasses: ['borderTopNone', 'smallPadding'],
          cols: [
            {
              id: "col-1",
              content: addOn.name
            },
            {
              id: "col-2",
              content: (<FormattedPrice value={price.add_ons_fee} isBold />)
            },
            {
              id: "col-3",
              content: (<FormattedPrice value={priceRefunds.add_ons_fee} isBold />)
            },
            {
              id: "col-4",
              content: (<FormattedPrice value={priceFinalAmount.add_ons_fee} isBold />)
            }
          ]
        });
      });
    }
    if (
      price.add_ons_fee?.cents_total ||
      priceRefunds.add_ons_fee?.cents_total ||
      priceFinalAmount.add_ons_fee?.cents_total
    ) {
      let addOnsContent = 'Add-ons';
      if (addOns.length) {
        addOnsContent = "Add-ons";
      }

      tableData.push({
        id: 'row-6',
        cols: [
          {
            id: "col-1",
            content: addOnsContent
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.add_ons_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.add_ons_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.add_ons_fee} isBold />)
          }
        ]
      });
    }

    if (
      price.booking_fee?.cents_total ||
      priceRefunds.booking_fee?.cents_total ||
      priceFinalAmount.booking_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-20',
        cols: [
          {
            id: "col-1",
            content: `Booking and processing fee`
          },
          {
            id: "col-2",
            content: (
              <FormattedPrice
                value={calculateDiscount(price, 'booking_fee', 'good_sam_membership_discount')}
                isBold
              />
            )
          },
          {
            id: "col-3",
            content: (
              <FormattedPrice
                value={calculateDiscount(priceRefunds, 'booking_fee', 'good_sam_membership_discount')}
                isBold
              />
            )
          },
          {
            id: "col-4",
            content: (
              <FormattedPrice
                value={calculateDiscount(priceFinalAmount, 'booking_fee', 'good_sam_membership_discount')}
                isBold
              />
            )
          }
        ]
      });
    }

    if (
      price.insurance_package_name ||
      priceRefunds.insurance_package_name ||
      priceFinalAmount.insurance_package_name
    ) {
      tableData.push({
        id: 'row-10',
        cols: [
          {
            id: "col-1",
            content: `Rental Protection - ${price.insurance_package_name}`
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.insurance_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.insurance_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.insurance_fee} isBold />)
          }
        ]
      });
    }

    if (
      isIMGlobalIntegrationIsEnabled && (
        price?.trip_interruption_insurance?.cents_total ||
        priceRefunds?.trip_interruption_insurance?.cents_total ||
        priceFinalAmount?.trip_interruption_insurance?.cents_total)
    ) {
      tableData.push({
        id: 'row-10',
        cols: [
          {
            id: "col-1",
            content: `Trip Interruption Insurance`
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.trip_interruption_insurance} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.trip_interruption_insurance} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.trip_interruption_insurance} isBold />)
          }
        ]
      });
    }

    if (
      price?.good_sam_membership_fee?.cents_total ||
      priceRefunds?.good_sam_membership_fee?.cents_total ||
      priceFinalAmount?.good_sam_membership_fee?.cents_total
    ) {
      tableData.push({
        id: 'good_sam_membership_fee',
        cols: [
          {
            id: "col-1",
            content: <span>Good Sam Membership fee</span>
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.good_sam_membership_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.good_sam_membership_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.good_sam_membership_fee} isBold />)
          }
        ]
      });
    }
    if (
      price.taxes?.total?.cents_total ||
      priceRefunds.taxes?.total?.cents_total ||
      priceFinalAmount.taxes?.total?.cents_total
    ) {
      tableData.push({
        id: 'row-14',
        extraRowClasses: ['borderBottomSolid'],
        cols: [
          {
            id: "col-1",
            content: `Taxes`
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={price.taxes.total} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.taxes.total} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.taxes.total} isBold />)
          }
        ]
      });
    }

    extraTextData = [
      {
        id: 'extraText-1',
        content: (
          <span>Cancelations initiated by the owner will result in a full refund. Cancellations initiated by the renter are subject to the <a href="https://support.rvrentals.com/hc/en-us/articles/4418570201495-What-is-the-cancellation-policy-">cancellation policy</a></span>
        )
      }
    ];
  }

  return {
    tableData,
    modalTitle,
    extraTextData
  };
};
