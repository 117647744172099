import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useApiButtons, usePreviewTexts } from "./hooks";
import { explainerRedIconOutline } from "components/Icon";
import BasicCard from "components/cards/BasicCard";
import Typography from "components/Typography";
import Button from "components/Button";
import { stripeToLocalBrand } from "utils/stripe";
import { MQ_BP, useMatchMedia } from "../MatchMedia";
import PaymentBrandLogo from "./PaymentBrandLogo";
import classes from "./PaymentPreview.module.css";

const PaymentPreview = (props) => {
  const {
    variant = "base",
    content,
    userData,
    onStartEditing,
    paymentPreviewContainerClassName,
    paymentMethodStepClassName,
    buttonsContainerInnerClassName,
    stepContainerClassNames,
    getButtons,
    isCardExpired,
    hasScrollShadow,
    isBookingProcess
  } = props;
  const screenWidthFromLargeToXLarge = useMatchMedia({
    minWidth: MQ_BP.large,
    maxWidth: MQ_BP.xLarge
  });

  const isCompact = variant === "compact";
  const texts = usePreviewTexts(content);
  const apiButtons = useApiButtons(content);

  const info = userData?.payment_info;
  const shortYear = info?.exp_year?.slice(-2, info.exp_year.length);

  return (
    <div className={classnames(classes.root, paymentPreviewContainerClassName, { [classes.compact]: isCompact }, "t-16 t-m-20 b-16 b-m-20")}>
      <div className={classnames(paymentMethodStepClassName ? "l-m-0 r-m-0" : "l-m-32 r-m-32")}>
        <div className="t-16 t-m-20 b-16 b-m-20">
          <BasicCard className={classnames(classes.card, paymentMethodStepClassName)}>
            <div
              className={classnames(
                classes.header,
                "t-16 b-12 r-16 l-16",
                isCompact
                  ? "r-m-48 l-m-48"
                  : ["r-m-64 l-m-64", "r-l-50 l-l-50", "r-xl-52 l-xl-52", "r-xxl-64 l-xxl-64"],
                "t-m-32 b-m-12"
              )}
            >
              <PaymentBrandLogo className={classes.brand} brand={stripeToLocalBrand(info?.brand)} />
              <div className="fbc">
                <Typography className={classnames(classes.title)} variant="subtitle" size="m">
                  {texts.title}
                </Typography>
              </div>
              <Typography className={classnames(classes.subtitle)} variant="subtitle" size="s">
                {texts.numberLabel}
              </Typography>
              <Typography
                className={classes.cardNumber}
                variant="headline"
                size={
                  (isCompact && !paymentMethodStepClassName) ||
                  (paymentMethodStepClassName && screenWidthFromLargeToXLarge)
                    ? "m"
                    : "l"
                }
              >
                {[...Array(3)].map((i, index) => (
                  <span
                    key={index}
                    className={classnames(
                      classes.altColor,
                      classes.pointContainer,
                      !paymentMethodStepClassName && classes.cardNumberGroup,
                      paymentMethodStepClassName && classes.cardNumberGroupMiddle
                    )}
                  >
                    <span>•</span>
                    <span>•</span>
                    <span>•</span>
                    <span>•</span>
                  </span>
                ))}
                <span key="number" className={`${classes.numberContainer} ${classes.cardNumberGroup}`}>
                  {info?.number.split("").map((d, _index) => (
                    <span key={_index}>{d}</span>
                  ))}
                </span>
              </Typography>
            </div>
            <div
              className={classnames(
                classes.footer,
                "r-20 b-40 l-20",
                isCompact
                  ? "r-m-48 l-m-48"
                  : ["r-m-64 l-m-64", "r-l-50 l-l-50", "r-xl-52 l-xl-52", "r-xxl-64 l-xxl-64"],
                "t-m-12 b-m-60",
                "fbc"
              )}
            >
              <div>
                <Typography className={classes.holderLabel} variant="subtitle" size="s">
                  {texts.holderLabel}
                </Typography>
                <Typography className={classes.bottomValue} variant="subtitle" size="l">
                  {info?.holder}
                </Typography>
              </div>
              <div className={classnames(classes.expires, "df fdc tar")}>
                <Typography className={classes.expiresLabel} variant="subtitle" size="s">
                  {texts.expiresLabel}
                </Typography>
                <div className={classnames({ [classes.expired]: isCardExpired }, "fsc")}>
                  {isCardExpired && <div className={classnames(classes.expiredIcon, "mr-8")} />}
                  <Typography
                    className={
                      classnames(
                        isCardExpired && classes.expiredNumbers,
                        classes.expiresNumbers
                      )}
                    variant="subtitle"
                    size="l"
                    weight="bold"
                  >
                    {info?.exp_month && info?.exp_year ? [info.exp_month, shortYear].join("/"): "-"}
                  </Typography>
                </div>
              </div>
            </div>
          </BasicCard>
        </div>
        {apiButtons.edit && (
          <div className={
            classnames(
              stepContainerClassNames?.buttonsClassName,
              classes.footerButtonsContainer,
              hasScrollShadow && classes.hasScrollShadow,
              isBookingProcess && classes.bookingProcess,
              buttonsContainerInnerClassName,
              "t-16 t-m-20 b-16 b-m-20"
            )}>
            <div className={classes.footerButtonsContainerInner}>
              <Button secondary label={apiButtons.edit.label} onClick={onStartEditing} />
              {getButtons?.({ isCardExpired }) || null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PaymentPreview.propTypes = {
  variant: PropTypes.oneOf(["base", "compact"]),
  content: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  onStartEditing: PropTypes.func.isRequired,
  buttonsClassName: PropTypes.string,
  getButtons: PropTypes.func,
  isCardExpired: PropTypes.bool.isRequired
};

export default PaymentPreview;
