import get from "lodash/get";

export function getPathError(data, path) {
  const errors = get(data, [path, "result", "errors"]);

  if (Array.isArray(errors)) {
    errors.filter((err) => !err.field);

    return errors.length > 0
      ? errors[0].message
      : "The server responded with an error. Please, try the operation later";
  }

  return null;
}
