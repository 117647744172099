import React, { useMemo, useState, useEffect } from "react";
import FilterClass from "components/Widget/components/WidgetFilters/desctopFilters/FilterClass";
import FilterCalendar from "components/Widget/components/WidgetFilters/desctopFilters/FilterCalendar";
import FilterLocations from "components/Widget/components/WidgetFilters/desctopFilters/FilterLocations";
import MobileFilterClass from "components/Widget/components/WidgetFilters//mobileFilters/MobileFilterClass";
import MobileFilterCalendar from "components/Widget/components/WidgetFilters/mobileFilters/MobileFilterCalendar";
import MobileFilterLocations from "components/Widget/components/WidgetFilters/mobileFilters/MobileFilterLocations";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { Select } from "components/form";
import { SORT } from "components/Widget/sort";
import { useMatchMedia } from "components/MatchMedia";
import { getClasses, getLocation } from "components/Widget/utils/utils";
import cn from "classnames";
import classes from "components/Widget/components/WidgetFilters/WidgetFilters.module.css";

const WidgetFilters = () => {
  const {
    theme,
    activeSort,
    setActiveSort,
    allCards,
    cardsLength
  } = useWidgetContext();

  const classesCount = useMemo(() => {
    const allClasses = getClasses(allCards);
    return allClasses?.length || 0;
  }, [allCards]);

  const locationsCount = useMemo(() => {
    const allLocations = getLocation(allCards);
    return allLocations?.length || 0;
  }, [allCards]);

  const isSmallScreen = useMatchMedia({ mediaQuery: "(max-width: 719.98px)" });
  const isShowFilters = typeof cardsLength === 'number' && cardsLength > 9;

  return (
    <>
      <div
        className={cn(
          classes.filters,
          ((locationsCount <= 1 || classesCount <= 1) && (locationsCount > 1 || classesCount > 1)) && classes.filtersSmall,
          (locationsCount <= 1 && classesCount <= 1) && classes.filtersVerySmall,
          !isShowFilters && classes.isHiddenFilters
        )}
      >
        {!isSmallScreen && isShowFilters && (
          <>
            <FilterCalendar />
            { locationsCount > 1 && <FilterLocations /> }
            { classesCount > 1 && <FilterClass /> }
          </>
        )}
        {isSmallScreen && isShowFilters && (
          <div className="dn-m">
            <div className="mt-8">
              <MobileFilterCalendar />
            </div>
            { locationsCount > 1 && (
              <div>
                <MobileFilterLocations />
              </div>
            )}
            { classesCount > 1 && (
              <div>
                <MobileFilterClass />
              </div>
            )}
            <div className="devider-solid mt-0 mb-8" />
          </div>
        )}

        <div className={`${classes.sort} mt-24 ml-m-8`}>
          <Select
            className={`${classes.sortSelect} ${theme === 'dark' && classes.darkTheme} fw`}
            classNameLabel={classes.sortSelectLabel}
            id="sort"
            label="Sort"
            options={SORT.options}
            value={activeSort}
            onChange={setActiveSort}
            data-menu-class-name={`${classes.menuSelectSort} ${theme === 'dark' && classes.darkTheme}`}
          />
        </div>
      </div>
    </>
  );
};

export default WidgetFilters;
