import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Button from "components/Button";
import { ModalAdditionalDrivers } from "components/ModalAdditionalDrivers/ModalAdditionalDrivers";
import cn from 'classnames';
import classes from "./AdditionalDriversEmptyState.module.css";

const AdditionalDriversEmptyState = (props) => {
  const {
    onAddDrivers
  } = props;

  return (
    <div className="mt-m-0">
      <Typography
        variant="body"
        size="l"
        className={classes.description}
      >
        You can add additional driver
      </Typography>

      <Button
        secondary
        onClick={onAddDrivers}
        className="mt-12 mb-16 mt-m-20 mb-m-20"
      >
        Add driver
      </Button>
    </div>
  );
};

AdditionalDriversEmptyState.propTypes = {

};

export default AdditionalDriversEmptyState;
