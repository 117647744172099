import React, { useState, useCallback, useEffect } from "react";
import HeaderFilterButton from "./HeaderFilterButton";
import { FieldController } from "components/form/FieldController/FieldController";
import { RentalType } from "components/RentalTypes/RentalType";
import { FILTERS, INITIAL_RENTAL_TYPE_DATA } from "../../filters";
import { setSelectedRentalType, updateRentalType } from "components/Search/utils";
import classnames from "classnames";
import classes from "./FilterRentalTypes.module.css";

const FilterRentalTypes= ({ mapOpen, filtersControl, filtersWatch }) => {
  const [rentalTypeList, setRentalTypeList] = useState(INITIAL_RENTAL_TYPE_DATA);
  const { rentalType } = filtersWatch();
  const { standard, deliveryOnly } = rentalType;

  const setSelected = useCallback((name, isSelected, onChange, type) => {
    setSelectedRentalType(name, isSelected, rentalTypeList, setRentalTypeList);

    const rentalTypeData = filtersControl.getValues().rentalType;
    onChange({
      ...rentalTypeData,
      [type]: isSelected
    });
  }, [filtersControl, rentalTypeList, setRentalTypeList]);

  useEffect(() => {
    updateRentalType(INITIAL_RENTAL_TYPE_DATA, standard, deliveryOnly, setRentalTypeList);
  }, [standard, deliveryOnly]);

  return (
    <HeaderFilterButton
      className={classnames(
        classes.root,
        mapOpen ? classes.isMapOpen : classes.isMapClosed
      )}
      popperClassName={classes.popper}
      popperAtEnd
      id={FILTERS.ids.rentalType}
      getLabelFromValue={value => value?.full_address || null}
      getSelectedCount={value => {
        let count = 0;
        if (value.standard) {
          count++;
        }
        if (value.deliveryOnly) {
          count++;
        }
        return count;
      }}
      isShowClear={standard || deliveryOnly}
      renderForm={() => (
        <div className="">
          <div className="df">
            <FieldController
              name="rentalType"
              control={filtersControl}
              render={(renderProps) => (
                <>
                  <div className="mt-16 mr-16 mr-m-24">
                    <RentalType
                      {...rentalTypeList[0]}
                      onSelected={(name, isSelected) => {
                        setSelected(name, isSelected, renderProps.onChange, 'standard');
                      }}
                    />
                  </div>

                  <div className="mt-16">
                    <RentalType
                      {...rentalTypeList[1]}
                      onSelected={(name, isSelected) => {
                        setSelected(name, isSelected, renderProps.onChange, 'deliveryOnly');
                      }}
                    />
                  </div>
                </>
              )}
            />
          </div>
        </div>
      )}
    />
  );
};

FilterRentalTypes.propTypes = {};

export default FilterRentalTypes;
