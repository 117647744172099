import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "components/Typography";
import { ReactComponent as IconCheck } from "assets/images/icon-check.svg";
import classes from "./RentalTypes.module.css";

export const RentalType = (props) => {
  const {
    icon,
    title,
    description,
    name,
    onSelected,
    selected
  } = props;

  return (
    <div
      className={classnames(
        `${classes.container} l-24 r-24 t-16 b-16 b-m-24 l-m-32 t-m-24`,
        selected && classes.selected
      )}
      onClick={() => {
        onSelected(name, !selected);
      }}>

      <header className="fbs">
        <div
          className={classnames(
            `${classes.rvIcon}`,
            name === 'rentalTypeDeliveryOnly' && classes.rentalTypeDeliveryOnlyIcon
          )}
        >
          {icon}
        </div>
        {selected && <div className={`${classes.icon} mr-m-8`}><IconCheck /></div>}
      </header>

      <div>
        <Typography
          variant="body"
          size="l"
          weight="bold"
        >
          {title}
        </Typography>
      </div>

      <div className={classes.description}>
        <Typography variant="body" size="s">
          {description}
        </Typography>
      </div>

    </div>
  );
};

RentalType.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelected:  PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};


