import React, { forwardRef, useCallback, useMemo } from "react";
import classnames from "classnames";
import { COLORS } from "../../utils/styles";
import PaymentBrandLogo from "./PaymentBrandLogo";
import { stripeToLocalBrand } from "../../utils/stripe";
import "./StripeInput.css";

const StripeInput = forwardRef((props, ref) => {
  const {
    className,
    Element,
    onChange,
    value,
    stripeType,
    stripeBrand,
    ...other
  } = props;

  const _onChange = useCallback((object) => {
    onChange(object?.empty ? {} : object);
  }, [onChange]);

  const isCard = useMemo(() => stripeType === "card", []);
  const cardBrand = isCard && value?.brand;

  return (
    <>
      <Element
        {...other}
        className={classnames(className, {
          card: isCard
        })}
        onChange={_onChange}
        onReady={element => (ref.current = element)}
        options={OPTIONS}
      />
      {cardBrand && (
        <PaymentBrandLogo
          className="stripe-input__brand"
          brand={stripeToLocalBrand(cardBrand)}
        />
      )}
    </>
  );
});

const OPTIONS = {
  style: {
    base: {
      fontFamily: "Aeonik, Arial, Helvetica, sans-serif",
      fontSize: "16px",
      fontWeight: "normal"
    },
    invalid: {
      color: COLORS.error
    }
  }
};

export default StripeInput;
