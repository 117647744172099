import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import PaymentView from "components/payment/PaymentView";
import { userQuery } from "components/ModalAdditionalDrivers/queries/userQuery";
import { SpinnerBack } from "components/Spinner/Spinner";
import Button from "components/Button";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import classes from "./PaymentMethod.module.css";

const PaymentMethod = ({ onNextStep, paymentMethodStepClassName }) => {
  const {
    loading: userQueryLoading,
    data: userQueryData
  } = useQuery(userQuery, {
    fetchPolicy: "no-cache"
  });
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const [userNewData, setUserNewData] = useState(null);
  const userData = userQueryData?.user;

  const updateUserData = useCallback(
    (newPartialData) => {
      setUserNewData({ ...userData, ...newPartialData });
    },
    [userData]
  );
  const handleNextStep = useCallback(() => {
    onNextStep();
  }, [onNextStep]);

  const getPreviewButtons = useCallback(
    ({ isCardExpired }) => (
      <div className={classes.footerButtonNext}>
        <Button
          onClick={handleNextStep}
          label={isSmallDevice ? "Proceed" : "Proceed to checkout"}
          disabled={isCardExpired}
        />
      </div>
    ),
    [handleNextStep, isSmallDevice]
  );

  if (userQueryLoading) {
    return <SpinnerBack />;
  }

  return (
    <>
      <PaymentView
        userData={userNewData || userData}
        updateUserData={updateUserData}
        onSave={handleNextStep}
        getPreviewButtons={getPreviewButtons}
        textButtonNextStep={isSmallDevice ? "Proceed" : "Proceed to checkout"}
        paymentFormContainerClassName={classes.paymentFormContainerClassName}
        paymentPreviewContainerClassName={classes.paymentPreviewContainerClassName}
        buttonsClassName={classes.footerButtons}
        buttonCancelClassName={classes.footerButtonCancel}
        buttonsContainerInnerClassName={classes.buttonsContainerInner}
        paymentMethodStepClassName={paymentMethodStepClassName}
      />
    </>
  );
};

export default PaymentMethod;
