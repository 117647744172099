import { useMemo } from "react";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { useChatContext } from "../ChatContext";
import { antiExchangeFilter, getStatusPriority } from "../utils";

export const usePreparedThreads = (threads, search, unreadOnly) => {
  const { getThreadUnreadCount, getThreadInfo } = useChatContext();

  /*
   * Ordering
   */
  const ordered = useMemo(() => {
    let result = threads || [];
    if (result.length === 0) {
      return [];
    }

    result = orderBy(antiExchangeFilter(result).map(i => ({
      item: i, // Do not spread, but save item for not to break Twilio Conversation instance
      statusPriority: getStatusPriority(i.status?.id), // TODO: Status API Connection
      timestamp: moment(i.lastMessage?.dateCreated || i.dateCreated).unix()
    })), ["statusPriority", "timestamp"], ["desc", "desc"]);

    return result.map(i => i.item);
  }, [threads]);

  /*
   * Filtering
   */
  return useMemo(() => {
    let result = ordered;
    if (result.length === 0) {
      return [];
    }
    if (unreadOnly) {
      result = result.filter(i => getThreadUnreadCount(i) > 0);
    }
    if (search?.length > 0) {
      result = result.filter(i => {
        const title = getThreadInfo(i)?.title || "";
        return title.toLowerCase()?.includes(
          search?.toLowerCase()
        );
      });
    }
    return result;
  }, [search, unreadOnly, getThreadUnreadCount, getThreadInfo, ordered]);
};
