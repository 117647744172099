import React, { useCallback, useState, useMemo } from "react";
import { AdditionalInsuranceItemHeader, AdditionalInsuranceMultipleItemContent } from "components/AdditionalInsuranceList";
import { getItemData, handleSwitchChange, handleRadioTap, handleCheckTap } from "components/AdditionalInsuranceList/utils";
import { chevronDownIcon, chevronUpIcon } from "components/Icon";
import Button from "components/Button";
import { ADD_INSURANCE_LIST_MODES, ADD_INSURANCE_EXTRA_IDS } from "components/AdditionalInsuranceList/constants";
import { FormElementsBlock } from "components/form/FormElementsBlock";
import classes from "./AdditionalInsuranceMultipleItem.module.css";
import cn from 'classnames';

const AdditionalInsuranceMultipleItem = (props) => {
  const {
    insuranceId,
    name,
    insuranceDescription,
    required,
    multi_select,
    itemPackages = [],
    selectedPackages = {},
    setSelectedPackages,
    mode,
    formElementsData
  } = props;

  const [showOptions, setShowOptions] = useState(false);

  const isExtraFields = itemPackages.some(item => item?.id === ADD_INSURANCE_EXTRA_IDS.PACKAGE_ID && selectedPackages.packagesIds.includes(ADD_INSURANCE_EXTRA_IDS.PACKAGE_ID)) && formElementsData && showOptions;

  const {
    selectedItemPackages,
    isItemPackagesDisabled,
    isSwitchChecked
  } = useMemo(() => getItemData(
    insuranceId,
    itemPackages,
    selectedPackages?.packagesIds,
    selectedPackages?.insurancesIds
  ), [insuranceId, itemPackages, selectedPackages?.packagesIds, selectedPackages?.insurancesIds]);

  const onShowOptions = useCallback(() => {
    setShowOptions(show => !show);
  }, []);

  const onRadioTap = useCallback((id) => {
    handleRadioTap(
      id,
      itemPackages,
      selectedPackages,
      setSelectedPackages,
      mode,
      insuranceId
    );
  }, [insuranceId, itemPackages, mode, selectedPackages, setSelectedPackages]);

  const onCheckTap = useCallback((id, checked) => {
    handleCheckTap(
      id,
      checked,
      itemPackages,
      selectedPackages,
      setSelectedPackages,
      mode,
      insuranceId
    );
  }, [insuranceId, itemPackages, mode, selectedPackages, setSelectedPackages]);

  const onSwitchChange = useCallback((checked) => {
    if (checked && mode === ADD_INSURANCE_LIST_MODES.SETTINGS) {
      setShowOptions(true);
    }

    handleSwitchChange(
      insuranceId,
      checked,
      selectedPackages,
      setSelectedPackages
    );
  }, [insuranceId, mode, selectedPackages, setSelectedPackages]);

  return (
    <>
      <AdditionalInsuranceItemHeader
        planName={name}
        insuranceDescription={insuranceDescription}
        required={mode === ADD_INSURANCE_LIST_MODES.SETTINGS ? false : required}
        selectedItemPackages={selectedItemPackages}
        isSwitchChecked={isSwitchChecked}
        onSwitchChange={onSwitchChange}
        showOptions={showOptions}
        mode={mode}
      />

      {showOptions && (
        <AdditionalInsuranceMultipleItemContent
          multi_select={multi_select}
          itemPackages={itemPackages}
          selectedPackages={selectedPackages}
          onRadioTap={onRadioTap}
          onCheckTap={onCheckTap}
          isItemPackagesDisabled={isItemPackagesDisabled}
        />
      )}

      {isExtraFields && (
        <div className="mt-16 mb-12 mt-m-20 mb-m-16">
          <FormElementsBlock
            {...formElementsData}
          />
        </div>
      )}

      <div className={cn(!showOptions && classes.showOptionsButtonMarginTop)}>
        <Button
          ghost
          label={showOptions? "Hide plans" : "Show plans"}
          className={classes.showOptionsButton}
          onClick={onShowOptions}
          size="medium"
          renderAtEnd={() => (
            <span
              className={classes.chevron}
            >
              {showOptions ? chevronUpIcon : chevronDownIcon}
            </span>
          )}
        />
      </div>
    </>
  );
};

AdditionalInsuranceMultipleItem.propTypes = {
};

export default AdditionalInsuranceMultipleItem;
