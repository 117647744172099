import size from "lodash/size";

const FILTERS_IDS = {
  class: "class",
  locations: "locations",
  date: "date"
};

export const FILTERS = {
  ids: FILTERS_IDS,
  labels: {
    [FILTERS_IDS.class]: "RV Class",
    [FILTERS_IDS.locations]: "RV Locations",
    [FILTERS_IDS.date]: "Departure - Return"
  },
  defaults: {
    [FILTERS_IDS.class]: {},
    [FILTERS_IDS.locations]: {},
    [FILTERS_IDS.date]: {
      departure: null,
      return: null,
      fullDeparture: null,
      fullReturn: null
    }
  }
};


export function isFilterSet(id, value) {
  if (size(value) === 0) {
    return false;
  }
  if (id === 'date') {
    if (!value.departure && !value.return) {
      return false;
    }
  }
  return true;
}

export function filterCards(cards = [], filters) {
  let filteredCard = [];
  const cls = filters.class;
  const locs = filters.locations;

  if (size(cls)) {
    const classNames = Object.values(cls).map(({ name }) => name);
    cards.forEach((card, index) => {
      const cardRvClass = card.cl;
      if (classNames.includes(cardRvClass)) {
        filteredCard.push(cards[index]);
      }
    });
  }

  if (size(locs)) {
    const locationNames = Object.values(locs).map(({ name }) => name);
    const filteredCardLocs = [];
    let restCard = cards;
    if (filteredCard.length !== 0) {restCard = filteredCard;}
    restCard.forEach((card, index) => {
      const cardLocation = card.lc.replace('Delivered from ', '');
      if (locationNames.includes(cardLocation)) {
        filteredCardLocs.push(restCard[index]);
      }
    });

    filteredCard = filteredCardLocs;
  }

  if (!size(cls) && !size(locs)) {
    filteredCard = cards;
  }
  return filteredCard;
}
