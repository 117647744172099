import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useMutation } from "@apollo/client";

import { ValidateMembershipModalForm } from "./ValidateMembershipModalForm";
import { ValidateMembershipModalFail } from "./ValidateMembershipModalFail";
import { goodSamMembershipValidateMutation } from "./mutations/goodSamMembershipValidateMutation";
import ValidateMembershipModal from "./ValidateMembershipModal";
import { extractSuccess } from "../../utils/extractErrors";
import { SpinnerBack } from "../Spinner/Spinner";

const extractGoodSamMembershipValidateSuccess = extractSuccess(
  "good_sam_membership_validate"
);

export const ValidateMembership = ({
  content,
  show,
  toggleShow,
  setIsGoodSamMember,
  setIsDeclined,
  toggleShowModal
}) => {
  const [inValidationFailed, setInValidationFailed] = useState(false);
  const [success, setSuccess] = useState(false);

  const showValidationFailed = useCallback(() => {
    setInValidationFailed(true);
  }, [setInValidationFailed]);

  const hideValidationFailed = useCallback(() => {
    setInValidationFailed(false);
  }, [setInValidationFailed]);

  const toggleValidationModal = useCallback(() => {
    toggleShow();
    setInValidationFailed(false);
  }, [toggleShow]);

  const [action, result] = useMutation(goodSamMembershipValidateMutation);

  const handleGoodSamMembershipVerification = useCallback(
    async (variables) => {
      const response = await action({ variables });

      if (extractGoodSamMembershipValidateSuccess(response)) {
        setSuccess(true);
        setIsGoodSamMember(true);
      }

      if (!extractGoodSamMembershipValidateSuccess(response)) {
        showValidationFailed();
        setSuccess(false);
        setIsDeclined(true);
      }
    },
    [action]
  );

  useEffect(() => {
    if (success) {
      toggleShow();
    }
  }, [success]);

  if (inValidationFailed) {
    return (
      <ValidateMembershipModal show={show} onClose={toggleValidationModal}>
        <ValidateMembershipModalFail
          content={content}
          hideValidationFailed={hideValidationFailed}
        />
      </ValidateMembershipModal>
    );
  }

  return (
    <>
      <ValidateMembershipModal show={show} onClose={toggleShowModal}>
        <ValidateMembershipModalForm
          content={content}
          toggleShow={toggleShow}
          handleVerify={handleGoodSamMembershipVerification}
        />
        {result.loading && <SpinnerBack />}
      </ValidateMembershipModal>
    </>
  );
};

ValidateMembership.propTypes = {
  content: PropTypes.shape({
    failedValidationMessageTitle: PropTypes.string.isRequired,
    failedValidationMessageText: PropTypes.string.isRequired,
    tryAgainButton: PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired,
    contactButton: PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired,
    cancelButton: PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired,
    confirmButton: PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
  setIsGoodSamMember: PropTypes.func.isRequired,
  setIsDeclined: PropTypes.func.isRequired
};
