import { useCallback, useEffect, useState } from "react";
import get from "lodash/get";

export const useFeesFormDiscounts = ({ form, defaultValues, validate }) => {
  const fieldName = "discounts";
  const watchDiscounts = form.watch(fieldName);
  const discountsError = get(form.formState.errors, fieldName);
  const hasRowError = discountsError && discountsError.type === "row";
  const [rowError, setRowError] = useState(null);

  const validateDiscounts = useCallback(
    () => {
      const discountsLast = watchDiscounts[watchDiscounts.length - 1];

      if (discountsLast) {
        const validValue = Number.MAX_SAFE_INTEGER;
        const discountsLastFromValid = validate.DISCOUNT_FROM(
          discountsLast.from
        );
        const discountsLastFromValue = parseInt(discountsLast.from, 10);

        if (discountsLastFromValid) {
          if (discountsLastFromValue <= validValue && hasRowError) {
            form.clearErrors(fieldName);
          }
        }
      }
    },
    [form, hasRowError, validate, watchDiscounts]
  );

  useEffect(() => {
    if (discountsError && discountsError.type === "row")
      setRowError(discountsError.message);
    else setRowError(null);
  }, [discountsError]);

  useEffect(() => {
    const discountsPrevIndex = watchDiscounts.length - 2;
    const discountsLastIndex = watchDiscounts.length - 1;
    const discountsPrev = watchDiscounts[discountsPrevIndex];
    const discountsLast = watchDiscounts[discountsLastIndex];
    const discountLastFromName = `${fieldName}[${discountsLastIndex}].from`;
    const discountPrevTillName = `${fieldName}[${discountsPrevIndex}].till`;
    const discountLastTillName = `${fieldName}[${discountsLastIndex}].till`;

    let discountsLastFrom, discountsPrevFrom;

    const updateDiscountTill = async () => {
      let discountLastFromError = get(
        form.formState.errors,
        discountLastFromName
      );
      const discountsPrevFromValid = validate.DISCOUNT_FROM(discountsPrev.from);
      const discountsLastFromValid = validate.DISCOUNT_FROM(discountsLast.from);
      const hasManualError =
        discountLastFromError && discountLastFromError.type === "manual";
      discountsPrevFrom = parseInt(discountsPrev.from, 10);
      const discountsPrevTill = parseInt(discountsPrev.till, 10);

      /* if (!discountLastFromError && !discountsLast.from) {
        form.setValue(discountLastFromName, String(discountsPrevFrom + 1));
      } */

      if (discountsLastFromValid) {
        discountsLastFrom = parseInt(discountsLast.from, 10);
        const discountsLastFromLarger = discountsLastFrom > discountsPrevFrom;

        if (
          !discountsLastFromLarger &&
          !hasManualError &&
          discountsPrevFromValid
        ) {
          form.setError(discountLastFromName, {
            type: "manual",
            message: `Must be more than ${discountsPrev.from}`
          });
          discountLastFromError = true;
        }
        if (
          discountsLastFromLarger &&
          (hasManualError || discountLastFromError)
        ) {
          form.clearErrors(discountLastFromName);
        }
      } else {
        await form.triggerFormValidate(discountLastFromName);
      }

      if (!discountLastFromError) {
        if (discountsPrevTill !== discountsLastFrom - 1) {
          form.setValue(discountPrevTillName, String(discountsLastFrom - 1));
        }
      } else {
        if (discountsPrev.till !== "") {
          form.setValue(discountPrevTillName, "");
        }
      }
    };

    if (discountsPrev && discountsLast) {
      updateDiscountTill();
    }

    if (discountsLast && discountsLast.till !== defaultValues.till) {
      form.setValue(discountLastTillName, defaultValues.till);
    }

    if (discountsLast) {
      validateDiscounts(hasRowError);
    }
  }, [
    defaultValues.till,
    form,
    form.formState,
    hasRowError,
    validateDiscounts,
    validate.DISCOUNT_FROM,
    watchDiscounts,
    validate
  ]);

  return [validateDiscounts, rowError];
};
