import React from "react";
import PropTypes from "prop-types";
import { ProTipsContent } from "./ProTipsContent";

const ProTips = ({ title, description, style }) => {

  return (
    <ProTipsContent
      title={title}
      content={description}
      className={style}
    />
  );
};

ProTips.propTypes = {
  style: PropTypes.object.isRequired
};

export default ProTips;
