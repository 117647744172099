import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { passwordChangeMutation } from "../mutations/passwordChangeMutation";
import {
  extractServerErrors,
  extractSuccess
} from "../../../../../utils/extractErrors";
import { PasswordForm } from "./PasswordForm";

const extractPasswordChangeErrors = extractServerErrors("password_change");
const extractPasswordChangeSuccess = extractSuccess("password_change");

export const Password = ({
  content,
  userData,
  sectionInEditing,
  toggleSectionInEditing
}) => {
  const [inEditing, setInEditing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const toggleInEditing = () => {
    setShowError(false);
    setInEditing(!inEditing);
    toggleSectionInEditing(!inEditing);
  };

  const [action, result] = useMutation(passwordChangeMutation);

  const handlePasswordChange = useCallback(
    async (variables) => {
      const response = await action({ variables });

      if (!extractPasswordChangeSuccess(response)) {
        setShowError(true);
        return;
      }

      const {
        data: {
          password_change: {
            result: { success }
          }
        }
      } = response;

      if (success) {
        setInEditing(false);
        toggleSectionInEditing(false);
        setShowError(false);
        setShowSuccess(true);
      }
    },
    [action, toggleSectionInEditing]
  );

  return (
    <>
      <NotificationPopover
        show={showSuccess}
        status="success"
        text={"Password successfully updated"}
        onClose={() => {
          setShowSuccess(false);
        }}
        bottomIndent={{
          hasIndent: true,
          size: 'small'
        }}
      />

      <PasswordForm
        content={content}
        inEditing={inEditing}
        sectionInEditing={sectionInEditing}
        toggleInEditing={toggleInEditing}
        onSubmit={handlePasswordChange}
        requestErrors={extractPasswordChangeErrors(result)}
        showError={showError}
        loading={result.loading}
      />
    </>
  );
};
