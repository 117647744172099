import { gql } from "@apollo/client";

export const savePhoneMutation = gql`
  mutation savePhone($code: String, $phone_number: String!) {
    phone_save(code: $code, phone_number: $phone_number) {
      result {
        success
        errors {
          field
          message
        }
      }
    }
  }
`;
