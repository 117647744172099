import { gql } from '@apollo/client';

export const phoneVerifyMutation = gql`
  mutation phone_verify($code: String) {
    phone_verify(code: $code) {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
      user {
        phone
        phone_verified
      }
    }
  }
`;

