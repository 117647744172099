
import { useCallback, useEffect, useReducer, useRef } from "react";
import { isElementScrolledAtBottom } from "../../../utils/basic";

export const useThreadScrollState = (
  listRef,
  { messages, thread, loading }
) => {
  // Using ref & updater for not to recreate scroll listeners every change
  const [, updateState] = useReducer(p => p + 1, 0);
  const isScrollAtEnd = useRef(true);

  const onScroll = useCallback((event) => {
    const element = event.target;
    if (!element) {
      return null;
    }
    const newValue = isElementScrolledAtBottom(element);
    if (newValue !== isScrollAtEnd.current) {
      isScrollAtEnd.current = newValue;
      updateState();
    }
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef.current]);

  const scrollToEnd = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
      isScrollAtEnd.current = true;
      updateState();
    }
    // Mustn't be changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Scrolling to a bottom of messages list on a new message,
     * but only if the scroll was at the bottom
     */
    if (isScrollAtEnd.current) {
      scrollToEnd();
    }
  }, [messages?.length, scrollToEnd]);

  useEffect(() => {
    if (thread) {
      scrollToEnd();
    }
  }, [thread, scrollToEnd, loading]);

  useEffect(() => {
    isScrollAtEnd.current = true;
    updateState();
  }, [thread]);

  return { isScrollAtEnd: isScrollAtEnd.current, scrollToEnd };
};
