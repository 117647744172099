import React from "react";
import { LocalSlider } from "processes/RvProfile/components/LocalSection/LocalSlider/LocalSlider";

export const LocalSection = () => {
  return (
    <div className="container t-56 b-52 t-m-80 b-m-80">
      <div className="b-m-12">
        <LocalSlider />
      </div>
    </div>
  );
};

// LocalSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

