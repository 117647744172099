import React from "react";
import PropTypes from "prop-types";
import SearchProvider from "./SearchProvider";
import { GoogleMapsProvider, useGoogleMaps } from "../GoogleMapsProvider";
import Map from "./components/Map/Map";
import { useSearchContext } from "./useSearchContext";
import Spinner from "../Spinner/Spinner";
import PageError from "../PageError/PageError";
import classes from "./MapBlockContainer.module.css";

const MapBlockContainer = ({ location }) => {
  return (
    <GoogleMapsProvider>
      <SearchProvider location={location}>
        <Content />
      </SearchProvider>
    </GoogleMapsProvider>
  );
};

export const Content = () => {
  const { error, loading } = useSearchContext();
  const { isLoaded: isMapLoaded } = useGoogleMaps();

  if (loading || !isMapLoaded) {
    return (
      <div className={classes.root}>
        <Spinner position="absolute" />
      </div>
    );
  }

  if (error) {
    return (
      <PageError error={error} />
    );
  }

  return (
    <div className={classes.root}>
      <Map className={classes.map} />
    </div>
  );
};

MapBlockContainer.propTypes = {
  location: PropTypes.string
};

export default MapBlockContainer;
