import { gql } from "@apollo/client";

export const contentQuery = gql`
  query($section: String) {
    content(section: $section) {
      text {
        id
        value
        type
      }
      buttons {
        id
        label
        type
        disabled
        link
      }
      images {
        id
        srcset {
          url
          width
          height
          type
          rotation
          crop_area_x1
          crop_area_y1
          crop_area_x2
          crop_area_y2
        }
      }
      form {
        id
        label
        disabled
        options {
          key
          value
        }
        max_length
      }
    }
  }
`;

export const linksContentQuery = gql`
  query {
    linksContent: content(section: "links") {
      buttons {
        id
        link
      }
    }
  }
`;
