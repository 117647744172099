import React, { useCallback, useEffect, useState } from "react";
import { useBookingModalContext } from "../../BookingModalContext";
import StepContainer from "../../components/StepContainer/StepContainer";
import Button from "components/Button";
import { AdditionalInsuranceList } from "components/AdditionalInsuranceList";
import { TextItem } from "components/TextItem";
import {
  getTotalPrice,
  isNextStep,
  getTags,
  setInitialDefaultTags,
  setDefaultTags,
  hasExtraInsurance,
  getDefaultValues,
  resetDefaultValues
} from "components/AdditionalInsuranceList/utils";
import { ADD_INSURANCE_LIST_MODES, ADD_INSURANCE_EXTRA_IDS } from "components/AdditionalInsuranceList/constants";
import Typography from "components/Typography";
import { convertCents } from "utils/convertCents";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { FormElementsBlock } from "components/form/FormElementsBlock";
import { useForm } from "react-hook-form";
import { SpinnerBack } from "components/Spinner/Spinner";
import classes from "./AdditionalInsurance.module.css";

const AdditionalInsurance = ({ hasScrollShadow }) => {
  const {
    handleNextStep,
    addInsuranceList = {},
    selectedPackagesAdditionalInsurance,
    setSelectedPackagesAdditionalInsurance,
    isLoading,
    setIsInterruptionVisible,
    isClearTii,
    setIsClearTii,
    tiiDefaultTags,
    setTiiDefaultTags
  } = useBookingModalContext();

  const form_tags = getTags(addInsuranceList);

  const {
    control,
    handleSubmit,
    setError: formSetError,
    clearErrors,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: { ...getDefaultValues(form_tags), ...tiiDefaultTags },
    shouldUnregister: false
  });

  const [error, setError] = useState("");
  const isSwitchChecked = selectedPackagesAdditionalInsurance?.insurancesIds?.includes(ADD_INSURANCE_EXTRA_IDS.INSURANCE_ID);

  const onNextStep = useCallback(() => {
    if (isNextStep(selectedPackagesAdditionalInsurance, addInsuranceList)) {
      setTiiDefaultTags(setDefaultTags(getValues()));
      setIsInterruptionVisible(hasExtraInsurance(selectedPackagesAdditionalInsurance));
      handleNextStep();
    } else {
      setError("Error Insurance");
    }
  }, [selectedPackagesAdditionalInsurance, addInsuranceList, setTiiDefaultTags, getValues, setIsInterruptionVisible, handleNextStep]);

  useEffect(() => {
    if (Boolean(isClearTii)) {
      setIsClearTii(false);
      setTiiDefaultTags(setInitialDefaultTags());
      reset(resetDefaultValues(getValues()));
    }
  }, [getValues, isClearTii, reset, setIsClearTii, setTiiDefaultTags, setValue]);

  useEffect(() => {
    if (!isSwitchChecked && Object.keys(errors)?.length > 0) {
      clearErrors();
    } else {
      for(const error of Object.values(errors)) {
        if(error?.ref?.focus) {
          error?.ref?.focus?.();
          break;
        }
      }
    }
  }, [errors, isSwitchChecked, clearErrors]);

  if (isLoading) return <SpinnerBack />;

  const footer = (
    <div className={classes.footer}>
      <div>
        <Typography
          variant="body"
          size="l"
          className={classes.name}
        >
          <span className={classes.total}>Total: </span>
          <span className="">${convertCents(getTotalPrice(selectedPackagesAdditionalInsurance, addInsuranceList))}</span>
        </Typography>
      </div>

      <div className={classes.buttons}>
        <Button
          onClick={handleSubmit(onNextStep)}
          label="Next"
          className="dddd"
        />
      </div>
    </div>
  );

  return (
    <StepContainer buttons={footer} hasScrollShadow={hasScrollShadow} buttonsContainerClassName={classes.buttonsContainerClassName}>
      <div className="mt-12">

        <div className="mb-24 mb-m-32 mb-l-24">
          <TextItem plainStyle={classes.descPlainStyle} data={addInsuranceList?.description} />
        </div>

        <AdditionalInsuranceList
          insurances={addInsuranceList?.insurances}
          selectedPackages={selectedPackagesAdditionalInsurance}
          setSelectedPackages={setSelectedPackagesAdditionalInsurance}
          mode={ADD_INSURANCE_LIST_MODES.USING}
          isLastBorder={false}
        />

        {isSwitchChecked && (
          <div className="mt-16 mb-12 mt-m-20 mb-m-16">
            <FormElementsBlock
              form_header={addInsuranceList?.form_header}
              form_footer={addInsuranceList?.form_footer}
              form_elements={addInsuranceList?.form_elements}
              form_tags={form_tags}
              formParams={{
                control,
                handleSubmit,
                setError: formSetError,
                clearErrors,
                reset,
                getValues,
                errors
              }}
            />
          </div>
        )}

        {error?.length > 0 && (
          <NotificationPopover
            show
            status="error"
            text={error}
            onClose={() => {
              setError('');
            }}
            bottomIndent={{
              hasIndent: true,
              size: 'small'
            }}
          />
        )}
      </div>
    </StepContainer>
  );
};

export default AdditionalInsurance;
