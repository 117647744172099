import React from "react";
import Typography from "components/Typography";
import { parseFormattedText }from "utils/parseFormattedText";
import cn from "classnames";
import classes from "./FormattedText.module.css";

const FormattedText = (props) => {
  const {
    data,
    tagCommonStyle,
    tagDesc
  } = props;

  const parseItems = parseFormattedText(data);

  return (
    <div className={classes.container}>
      {parseItems.map(({ s: str, tVal: tag }, index) => {
        if (tag) {
          let tempTagDescItem = null;

          for(let i = 0; i < tagDesc.length; i++) {
            if (tagDesc[i].tag === tag) {
              tempTagDescItem = tagDesc[i];
              break;
            }
          }

          if (tempTagDescItem) {
            const {
              classNames = "",
              onClick = null
            } = tempTagDescItem;

            return (
              <span key={index}>
                <Typography
                  className={cn(classNames, classes.tag, classes.str)}
                  onClick={onClick}
                  component="span"
                >
                  {str}
                </Typography>
              </span>
            );
          } else {
            return (
              <span className={classes.item} key={index}>
                <Typography
                  component="span"
                  className={cn(tagCommonStyle, classes.str)}
                >
                  {str}
                </Typography>
              </span>
            );
          }
        } else {
          return (
            <span className={classes.item} key={index}>
              <Typography
                component="span"
                className={cn(tagCommonStyle, classes.str)}
              >
                {str}
              </Typography>
            </span>
          );
        }
      })}
    </div>
  );

};

FormattedText.propTypes = {
};

export default FormattedText;
