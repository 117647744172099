import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { getPathError } from "utils/getPathError";

export function useSubmitReview(mutation, pathError, handleError) {
  const [submitReview, { loading }] = useMutation(mutation);

  const handleSubmitReview = useCallback(async (variables) => {
    try {
      const { data } = await submitReview({ variables });
      handleError(getPathError(data, pathError));
      return getPathError(data, pathError);
    } catch (e) {
      handleError(e.message);
      return e.message;
    }
  }, [submitReview, pathError, handleError]);

  return [handleSubmitReview, loading];
}
