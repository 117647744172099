export const mergeAddons = (prevAddons = [], nextAddons = []) => {
  let newAddons = [];
  const prevAddonsIds = prevAddons.map(({ id }) => id);

  nextAddons.forEach((addon) => {
    if (prevAddonsIds.includes(addon?.id)) {
      newAddons.push(addon);
    }
  });

  return newAddons;
};
