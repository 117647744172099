/*
 * Payment Form Hooks
 */

import { useMemo } from "react";
import { extractText } from "../../utils/extractText";
import { extractField } from "../../utils/extractField";
import { extractButton } from "../../utils/extractButton";
import { DEFAULT_VALUES, FIELDS_IDS } from "./utils";
import { withDefaultValues } from "../../utils/basic";

export const usePreviewTexts = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractText(content.text, key);
      return {
        title: e("completed_view_title"),
        numberLabel: e("completed_view_card_number_label"),
        holderLabel: e("completed_view_card_holder_label"),
        expiresLabel: e("completed_view_expires_label")
      };
    }
    return {};
  }, [content]);
};

export const useApiFields = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractField(content.form, key);
      return {
        cardNumber: e("card_number"),
        cardHolder: e("card_holder"),
        expDate: e("expiration_date"),
        cvv: e("cvv"),
        address: e("address"),
        city: e("city"),
        state: e("state"),
        zip: e("zip"),
        postalCode: e("postal_code"),
        country: e("country")
      };
    }
    return {};
  }, [content]);
};

export const useApiButtons = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractButton(content.buttons, key);
      return {
        save: e("save_button"),
        edit: e("completed_view_update_button")
      };
    }
    return {};
  }, [content]);
};

export const useDefaultValues = (userData) => {
  const paymentInfo = userData?.payment_info;

  return useMemo(() => {
    const info = paymentInfo || {};
    return withDefaultValues(
      {
        [FIELDS_IDS.card_holder]: info.holder,
        [FIELDS_IDS.country]: info.country,
        [FIELDS_IDS.address]: info.address,
        [FIELDS_IDS.city]: info.city,
        [FIELDS_IDS.state]: info.state,
        [FIELDS_IDS.zip]: info.zip
      },
      DEFAULT_VALUES
    );
  }, [paymentInfo]);
};
