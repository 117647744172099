import React from "react";
import { RatingInfo } from "processes/RvProfile/components/RatingInfo/RatingInfo";
import RvUserAvatar from "processes/RvProfile/components/RvUserAvatar/RvUserAvatar.js";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "./UserInfo.module.css";

export const UserInfo = () => {

  return (
    <div className={classes.container}>
      <div className={classes.userInfoOuter}>
        <div className="">
          <div className={classes.userInfoInner}>
            <div className="mt-16 mb-24 mt-m-20"><RvUserAvatar /></div>
            <div className="">
              <div>
                <Typography variant="subtitle" size="l">
                  Hosted by <span className="">Raymond J.</span>
                </Typography>
              </div>
              <div className={classes.userInfoDateContainer}>
                <div className="b-m-12 t-m-2 r-l-16 r-xxl-36">
                  <RatingInfo extraInfoText="Reviews" />
                </div>
                <div class="data-devider dn db-l dn-xl db-xxl mb-l-12" />
                <div className="b-l-12 l-l-16 l-xl-0 l l-xxl-36">
                  <Typography variant="body" size="l">
                  Joined December 2020
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" t-12 b-16 t-m-20 l-m-24 l-l-80 ml-l-4 t-l-8 b-l-20 t-xl-20">
          <Button
            label="Message"
            secondary
            className="mr-16"
          />
          <Button
            label="Owner’s Profile"
          />
        </div>
      </div>
    </div>
  );
};

// UserInfo.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

