import "react-image-crop/dist/ReactCrop.css";
import React, { useState, useEffect, useCallback } from "react";
import classes from "./PhotoEditTool.module.css";
import ImageCrop from "./ImageCrop";
import { EditorModal } from "./EditorModal";
import { EditorToolbar } from "./EditorToolbar";
import { useDimensions } from "./useDimensions";
import { defaultTo, lensProp, over, path, pick, pipe } from "ramda";

function getInitialCrop(crop, width, height) {
  const { aspect } = crop;
  const targetWidth = crop.width !== undefined ? crop.width : height * aspect;
  const targetHeight = crop.height !== undefined ? crop.height : width / aspect;

  if (width > height) {
    // landscape image - full height
    return {
      ...crop,
      y: crop.y !== undefined ? crop.y : 0,
      height: crop.height !== undefined ? crop.height : height,
      x: crop.x !== undefined ? crop.x : (width - targetWidth) / 2,
      width: targetWidth
    };
  }

  // portrait image - full width
  return {
    ...crop,
    x: crop.x !== undefined ? crop.x : 0,
    width: crop.width !== undefined ? crop.width : width,
    y: crop.y !== undefined ? crop.y : (height - targetHeight) / 2,
    height: targetHeight
  };
}

function resetCrop(crop, width, height) {
  const { aspect } = crop;
  const targetWidth = height * aspect;
  const targetHeight = width / aspect;

  if (width >= targetWidth) {
    // landscape image - full height
    return {
      ...crop,
      y: 0,
      height,
      x: (width - targetWidth) / 2,
      width: targetWidth
    };
  }

  // portrait image - full width
  return {
    ...crop,
    x: 0,
    width,
    y: (height - targetHeight) / 2,
    height: targetHeight
  };
}

function flipDimensions(turn, w, h) {
  return turn === 90 || turn === 270 ? [h, w] : [w, h];
}

function getDimensionsWithinContainer([iw, ih], [w, h]) {
  if (iw < 1 || ih < 1) {
    return [0, 0];
  }

  // fit by width
  const nw1 = Math.floor(w);
  const nh1 = Math.floor((ih * nw1) / iw);

  if (nh1 <= h) {
    return [nw1, nh1];
  }

  // fit by height
  const nh2 = Math.floor(h);
  const nw2 = Math.floor((iw * nh2) / ih);

  return [nw2, nh2];
}

const INITIAL_CROP = {
  turn: 0,
  unit: "px",
  aspect: 4 / 3
};

function calculateDimensions(
  turn,
  imageDimensions,
  containerWidth,
  containerHeight,
  gap
) {
  const [width, height] = getDimensionsWithinContainer(
    flipDimensions(turn, imageDimensions.width, imageDimensions.height),
    [containerWidth, containerHeight]
  );

  const [imageWidth, imageHeight] = flipDimensions(turn, width, height);

  return {
    width,
    height,
    imageWidth,
    imageHeight
  };
}

const processInitialItemCropValues = (item) => {
  const crop = INITIAL_CROP;

  if (item) {
    const itemSrcSet = item.srcset[0];
    crop.x = itemSrcSet.crop_area_x1;
    crop.y = itemSrcSet.crop_area_y1;
    crop.width = itemSrcSet.crop_area_x2 - itemSrcSet.crop_area_x1;
    crop.height = itemSrcSet.crop_area_y2 - itemSrcSet.crop_area_y1;
    crop.turn = itemSrcSet.rotation * 90;
    crop.aspect = crop.width / crop.height;
  }

  return crop;
};

const extractImageDimensions = pipe(
  path(["srcset", 0]),
  over(lensProp("width"), defaultTo(0)),
  over(lensProp("height"), defaultTo(0)),
  pick(["width", "height"])
);

const ImageCropContainer = (props) => {
  const {
    item,
    onDone,
    className,
    hasImageCaption,
    initialImageCaption
  } = props;

  const src = item?.srcset[0].url;
  const INITIAL_ITEM_CROP = processInitialItemCropValues(item);
  const [crop, setCrop] = useState(
    pick(["turn", "aspect", "unit"], INITIAL_ITEM_CROP)
  );
  const [imageDimensions, setImageDimensions] = useState(
    extractImageDimensions(item)
  );
  const [ref, containerWidth, containerHeight] = useDimensions();

  const { width, height, imageWidth, imageHeight } = calculateDimensions(
    crop.turn,
    imageDimensions,
    containerWidth,
    containerHeight
  );

  useEffect(() => {
    setCrop((prevData) => {
      const { turn } = prevData;
      const dimensionsForImage = calculateDimensions(
        turn,
        imageDimensions,
        containerWidth,
        containerHeight
      );
      const kWidth =
        (turn === 0 || turn === 180
          ? imageDimensions.width
          : imageDimensions.height) / dimensionsForImage.width;
      const kHeight =
        (turn === 0 || turn === 180
          ? imageDimensions.height
          : imageDimensions.width) / dimensionsForImage.height;

      return getInitialCrop(
        {
          ...prevData,
          turn,
          width: Math.round(INITIAL_ITEM_CROP.width / kWidth),
          height: Math.round(INITIAL_ITEM_CROP.height / kHeight),
          x: Math.round(INITIAL_ITEM_CROP.x / kWidth),
          y: Math.round(INITIAL_ITEM_CROP.y / kHeight)
        },
        dimensionsForImage.imageWidth,
        dimensionsForImage.imageHeight
      );
    });
  }, [
    setCrop,
    imageDimensions,
    containerWidth,
    containerHeight,
    INITIAL_ITEM_CROP
  ]);

  const imageStyle = {
    maxWidth: "none",
    maxHeight: "none",
    transform: `translate(${(width - imageWidth) / 2}px,${
      (height - imageHeight) / 2
    }px) rotate(${crop.turn}deg)`,
    width: imageWidth,
    height: imageHeight
  };

  const updateTurn = useCallback(
    (delta) => {
      setCrop((prevData) => {
        const turn = (prevData.turn + delta + 360) % 360;
        const { width, height } = calculateDimensions(
          turn,
          imageDimensions,
          containerWidth,
          containerHeight
        );

        return resetCrop({ ...prevData, turn }, width, height);
      });
    },
    [setCrop, imageDimensions, containerWidth, containerHeight]
  );

  const handleRotateLeft = useCallback(() => {
    updateTurn(-90);
  }, [updateTurn]);

  const handleRotateRight = useCallback(() => {
    updateTurn(90);
  }, [updateTurn]);

  const handleFinishCrop = useCallback((imageCaptionValue) => {
    const kWidth =
      (crop.turn === 0 || crop.turn === 180
        ? imageDimensions.width
        : imageDimensions.height) / width;
    const kHeight =
      (crop.turn === 0 || crop.turn === 180
        ? imageDimensions.height
        : imageDimensions.width) / height;

    const cropData = {
      turn: crop.turn,
      x: Math.round(crop.x * kWidth),
      y: Math.round(crop.y * kHeight),
      width: Math.round(crop.width * kWidth),
      height: Math.round(crop.height * kHeight)
    };

    onDone(cropData, imageCaptionValue);
  }, [crop, imageDimensions, width, height, onDone]);

  return (
    <div className={classes.canvas}>
      <div className={`${classes.imageCropContainer} ${className}`}>
        <div className={classes.imageCropContainerOffset} ref={ref}>
          <div className={classes.imageCropPosition}>
            <ImageCrop
              className={classes.imageCrop}
              style={{ width, height }}
              imageStyle={imageStyle}
              src={src}
              crop={crop}
              minWidth={32}
              minHeight={32}
              onChange={(nextCrop) => {
                setCrop((prevData) => ({ ...prevData, ...nextCrop }));
              }}
              onImageLoaded={(i) => {
                setImageDimensions({
                  width: i.naturalWidth,
                  height: i.naturalHeight
                });
              }}
            />
          </div>
        </div>
      </div>
      <EditorToolbar
        hasImageCaption={hasImageCaption}
        initialImageCaption={initialImageCaption}
        className={classes.toolbarContainer}
        onDone={handleFinishCrop}
        onRotateLeft={handleRotateLeft}
        onRotateRight={handleRotateRight}
      />
    </div>
  );
};

export const PhotoEditTool = (props) => {
  const {
    className,
    item,
    onDone,
    onCancel,
    hasImageCaption,
    initialImageCaption
  } = props;

  return (
    <EditorModal
      isOpen={!!item}
      onClose={onCancel}
      render={({ onClose }) => (
        <ImageCropContainer
          item={item}
          onDone={onDone}
          className={className}
          hasImageCaption={hasImageCaption}
          initialImageCaption={initialImageCaption}
        />
      )}
    />
  );
};
