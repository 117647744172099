import { useRef, useMemo, useEffect, useState } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { LocalSliderItem } from "processes/RvProfile/components/LocalSection/LocalSliderItem/LocalSliderItem";
import Slider from "react-slick";

export function useLocalSlider(items, classes) {
  const isTablet = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.medium });
  const isDesctop = useMatchMedia({ minWidth: MQ_BP.medium });
  const [showArrows, setShowArrows] = useState(true);
  const sliderRef = useRef();

  const settings = useMemo(() => {
    const commonSettings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 300,
      fade: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      draggable: false,
      swipe: true,
      touchMove: true,
      dotsClass: classes.sliderDots,
      appendDots: dots => (
        <div
          className={`${classes.dotsContainer}`}
        >
          <ul className={`${classes.dots} ui-menu mt-28 mt-m-52`}>{dots} </ul>
        </div>
      )
    };
    const stgs = {
      ...commonSettings,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: items.length > 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            ...commonSettings,
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: items.length > 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            ...commonSettings,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return stgs;
  }, [classes.dots, classes.dotsContainer, classes.sliderDots, items.length]);

  useEffect(() => {
    if (isTablet && items.length <= 2) setShowArrows(false);
    else if (isDesctop && items.length <= 3) setShowArrows(false);
    else setShowArrows(true);
  }, [isTablet, isDesctop, items.length]);

  const content = useMemo(() => {
    const sliderContent = items.map((item) => (
      <LocalSliderItem {...item} classes={classes} />
    ));

    return (
      <div className={classes.localSlider}>
        <Slider
          ref={(slider) => (sliderRef.current = slider)}
          {...settings}
        >
          {sliderContent}
        </Slider>
      </div>
    );
  }, [classes, items, settings]);

  return [content, sliderRef, showArrows];
}
