import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import classes from "./SmallDayPicker.module.css";

function setModifiers(from, to, enteredTo, cls) {
  let startOpposite = to < from ? from : null;
  let endOpposite = to < from ? to : null;
  const startOnly = !to && !enteredTo && from ? from : null;

  if (!to) {
    startOpposite = null;
    endOpposite = null;
  }
  if (!to && enteredTo && enteredTo < from) startOpposite = enteredTo;
  if (!to && enteredTo && enteredTo < from) endOpposite = from;

  const modifiers = {
    [cls.start]: from,
    [cls.end]: enteredTo,
    [cls.onlyEnteredTo]: !from && !to && enteredTo,
    [cls.startOpposite]: startOpposite,
    [cls.endOpposite]: endOpposite,
    [cls.startOnly]: startOnly
  };

  return modifiers;
}

const WEEKDAYS_NAMES = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const SmallDayPicker = (props) => {
  const {
    numberOfMonths,
    initialMonth,
    onChangeDate = () => {},
    value: {
      from: valueFrom = null,
      to: valueTo = null
    } = {},
    className = '',
    externalClassNames = null
  } = props;

  const [state, setState] = useState({
    from: null,
    to: null,
    enteredTo: null
  });

  const handleDayMouseEnter = useCallback((day) => {
    const { from, to } = state;
    if (!(from && to)) {
      setState({
        ...state,
        enteredTo: day
      });
    }
  }, [state, setState]);

  const handleDayClick = useCallback(
    (day) => {
      const { from, to, enteredTo } = state;
      if ((from && to) || (!from && !to && !enteredTo)) {
        setState({
          from: day,
          to: null,
          enteredTo: null
        });
      } else if (!from && !to && enteredTo) {
        setState({
          from: enteredTo,
          to: null,
          enteredTo: enteredTo
        });
      } else {
        let newState = {
          ...state,
          to: day,
          enteredTo: day
        };

        if (day < from) {
          newState = {
            from: day,
            to: from,
            enteredTo: from
          };
        }
        setState(newState);
        onChangeDate(newState);
      }
    },
    [state, onChangeDate]
  );

  const renderDayWithData = useCallback(
    (day) => (
      <div className={externalClassNames?.daySquare || classes.daySquare}>
        {day.getDate()}
      </div>
    ),
    [externalClassNames?.daySquare]
  );

  useEffect(() => {
    if (valueFrom && valueTo) {
      setState({
        from: valueFrom,
        to: valueTo,
        enteredTo: valueTo
      });
    } else if (!valueFrom && !valueTo) {
      setState({
        from: null,
        to: null,
        enteredTo: null
      });
    }
  }, [valueFrom, valueTo]);

  const { from, to, enteredTo } = state;
  const disabledDays = { before: new Date() };
  const selectedDays = [from, { from, to: enteredTo }];
  const modifiers = setModifiers(from, to, enteredTo, externalClassNames || classes);

  return (
    <DayPicker
      classNames={externalClassNames || classes}
      className={`${className}`}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      fromMonth={new Date()}
      initialMonth={initialMonth || new Date()}
      // month={initialMonth || new Date()}
      modifiers={modifiers}
      disabledDays={disabledDays}
      selectedDays={selectedDays}
      numberOfMonths={numberOfMonths}
      weekdaysShort={WEEKDAYS_NAMES}
      renderDay={renderDayWithData}
      showOutsideDays
    />
  );
};

SmallDayPicker.propTypes = {
  classNames: PropTypes.string,
  externalClassNames: PropTypes.string,
  value: PropTypes.shape({
    from: PropTypes.oneOfType([
      // PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    to: PropTypes.oneOfType([
      // PropTypes.string,
      PropTypes.instanceOf(Date)
    ])
  }),
  initialMonth: PropTypes.instanceOf(Date),
  numberOfMonths: PropTypes.oneOf([1, 2]),
  onChangeDate: PropTypes.func
};
