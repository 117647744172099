import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { contentQuery } from "../../queries";
import { extractHtml } from "../../utils/exctractHtml";
import { extractText } from "../../utils/extractText";
import { ProTipsContent } from "./ProTipsContent";

const ProTips = ({ section, style }) => {
  const [proTipData, setProTipData] = useState({
    title: "",
    content: ""
  });

  const { loading, data } = useQuery(contentQuery, {
    fetchPolicy: "no-cache",
    variables: {
      section
    }
  });

  useEffect(() => {
    if (!loading && data?.["content"]?.text) {
      setProTipData({
        title: extractText(data["content"].text, "pro_tips_section_title"),
        content: extractHtml(data["content"].text, "pro_tips_section_text")
      });
    }
  }, [data, loading]);

  return (
    <ProTipsContent
      title={proTipData.title}
      content={proTipData.content}
      className={style}
    />
  );
};

ProTips.propTypes = {
  section: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired
};

export default ProTips;
