import React, { useState, useEffect, useCallback, useRef } from "react";
import size from "lodash/size";
import { ModalPayoutForm } from "components/ModalPayoutForm/ModalPayoutForm";
import { VERIFICATION_STEPS, VERIFICATION_ORDER_DEFAULT } from "./constants";
import { useVerificationCheck } from "./hooks/useVerificationCheck";
import { getVerifyData } from "./utils";
import { EmailVerifyModal } from "components/Verification/EmailVerifyModal";
import { PhoneVerifyModal } from "components/Verification/PhoneVerifyModal";
import { SpinnerBack } from "components/Spinner/Spinner";

const VerificationStepsModals = (props) => {
  const {
    verifyList = VERIFICATION_ORDER_DEFAULT,
    children,
    show = true,
    process,
    updateUserData = () => {},
    onClose
  } = props;

  const {
    loading,
    phone,
    userData,
    accountTypeValue,
    verifications
  } = useVerificationCheck();

  const isVerificationsLoaded = useRef(false);
  const [showChildren, setShowChildren] = useState(false);
  const [verifyData, setVerifyData] = useState(null);

  const handleOnClose = useCallback(() => {
    setVerifyData(null);
    setShowChildren(false);
    onClose();
  }, [onClose]);

  const onNextStep = useCallback(() => {
    const nextStep = verifyData.data?.[verifyData.count + 1];

    if (nextStep) {
      setVerifyData(previousState => ({
        ...previousState,
        current: nextStep,
        count: verifyData.count + 1
      }));
    } else {
      setVerifyData(null);
      setShowChildren(true);
    }
  }, [verifyData]);

  useEffect(() => {
    if (verifications && !isVerificationsLoaded.current) {
      const data = getVerifyData(verifications, verifyList);

      if (size(data)) {
        setVerifyData({
          count: 0,
          current: data[0],
          data
        });
      } else {
        setShowChildren(true);
      }
      isVerificationsLoaded.current = true;
    }
  }, [verifications, verifyList]);

  if (!show) {
    return null;
  }

  if (loading) {
    return <SpinnerBack />;
  }

  return (
    <>
      <EmailVerifyModal
        show={verifyData?.current === VERIFICATION_STEPS.email}
        onClose={handleOnClose}
        process={process}
      />

      <PhoneVerifyModal
        show={verifyData?.current === VERIFICATION_STEPS.phone}
        onClose={handleOnClose}
        onVerified={onNextStep}
        userPhone={phone}
      />

      <ModalPayoutForm
        show={verifyData?.current === VERIFICATION_STEPS.payoutInfo}
        onClose={onClose}
        onSuccessSubmit={onNextStep}
        userData={userData}
        updateUserData={updateUserData}
        accountType={accountTypeValue}
      />

      {showChildren && children()}
    </>
  );
};

export default VerificationStepsModals;
