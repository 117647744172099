const APOSTROPHE = "’";
const APOSTROPHE_S = `${APOSTROPHE}s`;

/**
 * @param {String} string
 * @param params
 * @return {String}
 */
export const possessive = (
  string,
  params: { returnEnding: false, checkDot: false } = {}
) => {
  if (!string) {
    return string;
  }

  const preparedString = (params?.checkDot ? string.replace(".", "") : string);
  const lastChar = preparedString.slice(-1).toLowerCase();
  const ending = ["s", "x"].includes(lastChar) ? APOSTROPHE : APOSTROPHE_S;

  return `${params?.returnEnding ? "" : string}${ending}`;
};

export const maybePlural = (string, count) => {
  return `${string}${count > 1 ? "s" : ""}`;
};
