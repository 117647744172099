import React, { useState, useEffect, useCallback } from "react";
import Form from "../../components/form/Form/Form";
import { Controller, useForm } from "react-hook-form";
import { FieldPhone } from "../../components/form/FieldPhone";
import Checkbox from "../../components/form/Checkbox";
import { VALIDATION } from "utils/validation";
import Button from "components/Button";
import { LabelContent } from "components/ContentSection/LabelContent";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { Button as ButtonContent } from "components/ContentSection/elements";
import cn from "classnames";
import classes from "./signUp.module.css";

const defaultValues = {
  phone_number: "+1"
};

export const PhoneNumberVerificationForm = (props) => {
  const {
    isCodeSent,
    loading,
    onSubmit,
    onEdit,
    error,
    initialPhone,
    buttonsVariant = "base",
    onCancel
  } = props;

  const {
    register,
    control,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState
  } = useForm({
    mode: "all",
    defaultValues: {
      phone_number: initialPhone || defaultValues.phone_number
    }
  });
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );

  const values = watch();
  const { errors } = formState;

  useEffect(() => {
    if (error) {
      setError("phone_number", {
        type: "server",
        message: error
      });
      setShowNotificationPopover(true);
    } else {
      clearErrors("phone_number");
      setShowNotificationPopover(false);
    }
  }, [error, setError, clearErrors]);

  const submitButton = (
    <ButtonContent
      id="phone_save"
      section="signUpPhone"
      disabled={
        !values.phone_number ||
        !values.agree ||
        Object.keys(errors).length > 0 ||
        loading
      }
      type="submit"
    />
  );

  const stopPropagateParentForm = useCallback((callback) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      callback();
    };
  },[]);

  const isButtonsVariantAlt = buttonsVariant === "alt";

  return (
    <div className="t-16 t-s-20 b-16 b-s-20">
      <Form
        onSubmit={stopPropagateParentForm(handleSubmit(onSubmit))}
      >
        <div className="row">
          <div className="col-12 col-m-9 r-m-0 col-l-7 col-xxl-6 r-l-16 r-xxl-36">
            <Controller
              name="phone_number"
              control={control}
              rules={VALIDATION.rules.phone}
              render={(renderProps) => (
                <LabelContent section="signUpPhone" id="phone">
                  {(label) => (
                    <FieldPhone
                      disabled={isCodeSent}
                      autoComplete="off"
                      className={cn('fw', classes.fieldPhone)}
                      id="signup-phone"
                      label={label}
                      errors={errors}
                      {...renderProps}

                    />
                  )}
                </LabelContent>
              )}
            />
          </div>
          {(isCodeSent || !isButtonsVariantAlt) && (
            <div className="col df t-32 t-m-40 t-l-0 l-xxl-0">
              {isCodeSent ? (
                <ButtonContent
                  id="phone_edit"
                  section="signUpPhone"
                  onClick={onEdit}
                  className={cn(classes.phoneEditButton, 'underline')}
                />
              ) : isButtonsVariantAlt ? null : (
                submitButton
              )}
            </div>
          )}
        </div>
        {!isCodeSent && (
          <div className="t-16 t-s-20">
            <div className="t-8 b-8">
              <LabelContent section="signUpPhone" id="agreement">
                {(label) => (
                  <Checkbox
                    ref={register({ required: "Agreement is required" })}
                    id="signup-agree"
                    name="agree"
                    html={label}
                    hasError={!!errors?.agree}
                  />
                )}
              </LabelContent>
            </div>
          </div>
        )}
        {!isCodeSent && (
          <div className="df t-32 t-m-40">
            {onCancel && (
              <Button
                className="mr-16 mr-l-24 mr-xxl-32"
                onClick={onCancel}
                secondary
              >
                Cancel
              </Button>
            )}
            {isButtonsVariantAlt ? submitButton : null}
          </div>
        )}
      </Form>
    </div>
  );
};
