import { gql } from "@apollo/client";

export const imageForEditToolQuery = gql`
  query imageForEditTool(
    $id: Int!
  ) {
    image_for_edit_tool(
      id: $id
    ) {
      srcset {
        url
        type
        height
        width
        rotation
        crop_area_x1
        crop_area_x2
        crop_area_y1
        crop_area_y2
      }
    }
  }
`;
