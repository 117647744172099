import { gql } from "@apollo/client";

export const verifyPhoneMutation = gql`
  mutation verifyPhone($code: String) {
    phone_verify(code: $code) {
      result {
        success
        errors {
          field
          message
        }
      }
    }
  }
`;
