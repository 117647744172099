import { gql } from "@apollo/client";

export const chatConversationMutation = gql`
  mutation chatConversation($user_id: Int!) {
    chat_create_conversation(user_id: $user_id) {
      result {
        success
        errors {
          message
        }
      }
      conversation {
        id
      }
    }
  }
`;
