import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { SpinnerBack } from "components/Spinner/Spinner";
import { ModalPayoutForm } from "components/ModalPayoutForm/ModalPayoutForm";
import { EmailVerifyModal } from "./EmailVerifyModal";
import { PhoneVerifyModal } from "./PhoneVerifyModal";
import { useVerificationCheck } from "./hooks";

const getAccountType = path(["bank_account", "account_type", "value"]);

function getAccountTypeValue(data) {
  let accountTypeValue = getAccountType(data);

  if (accountTypeValue === '-') {
    accountTypeValue = '';
  }

  return accountTypeValue;
}

const WithVerificationsCheck = (props) => {
  const {
    onClose,
    disabled,
    children,
    process,
    isNotStandardFlow,
    checkPayoutInfo
  } = props;

  const {
    loading,
    isEmailVerified,
    isPhoneVerified,
    isPayoutInfoVerified,
    phone,
    userData
  } = useVerificationCheck(disabled, checkPayoutInfo);

  const [showPhoneVerifyModal, setShowPhoneVerifyModal] = useState(true);

  const handleOnPhoneVerifyDone = useCallback(() => {
    setShowPhoneVerifyModal(false);
  }, []);

  if (disabled) {
    return null;
  }

  if (loading) {
    return <SpinnerBack />;
  }

  if (!isEmailVerified) {
    return <EmailVerifyModal show onClose={onClose} process={process} />;
  }

  const accountTypeValue = getAccountTypeValue(userData);

  if (!isPhoneVerified && showPhoneVerifyModal) {
    return (
      <PhoneVerifyModal
        show
        onClose={onClose}
        onVerified={isNotStandardFlow ? handleOnPhoneVerifyDone : onClose}
        userPhone={phone}
      />
    );
  }

  if (!isPayoutInfoVerified) {
    return (
      <ModalPayoutForm
        show
        onClose={onClose}
        onSuccessSubmit={onClose}
        userData={userData}
        updateUserData={onClose}
        accountType={accountTypeValue}
        submitButtonData={{
          submitButtonType: "button",
          submitImmediately: true
        }}
      />
    );
  }

  return children();
};

WithVerificationsCheck.propTypes = {
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.func.isRequired,
  checkPayoutInfo: PropTypes.bool
};

export default WithVerificationsCheck;
