import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import classnames from "classnames";
import BasicCollapse from "../../../RcCollapse/BasicCollapse";
import Typography from "../../../Typography";
import { useSearchContext } from "../../useSearchContext";
import { FILTERS, isFilterSet } from "../../filters";
import classes from "./MobileFilterBlock.module.css";

const MobileFilterBlock = (props) => {
  const { id, getLabelFromValue, renderForm, onReset, isShowClear = null } = props;
  const { filtersControl, resetFilter, isFilterDisabled, list } = useSearchContext();

  return (
    <Controller
      name={id}
      control={filtersControl}
      render={({ onChange }) => {
        const value = filtersControl.getValues(id);
        const _isFilterSet = isFilterSet(id, value);
        const valueLabel = Boolean(getLabelFromValue && value)
          ? getLabelFromValue(value)
          : null;
        const isShowClearButton = isShowClear !== null ? isShowClear : _isFilterSet;
        return (
          <div
            className={classnames(
              classes.root,
              { [classes.disabled]: isFilterDisabled(id) && list?.length === 0 }
            )}
          >
            <div className="t-8 r-16 b-8 l-16">
              <BasicCollapse
                headerClassName={classes.header}
                iconClassName={classes.headerIcon}
                renderHeader={(open) => (
                  <div>
                    <div className="row no-gutters f-nowrap fbc">
                      <Typography variant="subtitle" size="s" weight="bold">
                        {FILTERS.labels[id]}
                      </Typography>
                      {open && isShowClearButton && (
                        <Typography
                          className={classes.clearer}
                          variant="subtitle"
                          size="s"
                          onClick={(event) => {
                            event.stopPropagation();
                            resetFilter(id);
                            onReset && onReset();
                          }}
                        >
                          Clear
                        </Typography>
                      )}
                    </div>
                    {!open && valueLabel && (
                      <Typography className="ml-16" variant="body" size="l">
                        {valueLabel}
                      </Typography>
                    )}
                  </div>
                )}>
                <div className="b-12">
                  {renderForm({
                    value: value || FILTERS.defaults[id],
                    onChange
                  })}
                </div>
              </BasicCollapse>
            </div>
          </div>
        );
      }}
    />
  );
};

MobileFilterBlock.propTypes = {
  id: PropTypes.string.isRequired,
  getLabelFromValue: PropTypes.func,
  renderForm: PropTypes.func.isRequired
};

export default MobileFilterBlock;
