import React from "react";
import { Checkbox } from "components/ModalInspections";
import { Select } from "components/ModalInspections";
import { Photos } from "components/ModalInspections";
import { IntValue } from "components/ModalInspections";
import { Comment } from "components/ModalInspections";
import { Addons } from "components/ModalInspections";
import { Signature } from "components/ModalInspections";
import { GROUP_TYPES } from "components/ModalInspections/constants";

export const GroupItem = ({ item }) => {
  const { type } = item;

  switch (type) {
  case GROUP_TYPES.CHECK:
    return (
      <Checkbox {...item} />
    );
  case GROUP_TYPES.PHOTO:
    return (
      <Photos {...item} />
    );
  case GROUP_TYPES.INT:
    return (
      <IntValue {...item} />
    );
  case GROUP_TYPES.STR:
    return (
      <Comment {...item} />
    );
  case GROUP_TYPES.SELECT:
    return (
      <Select {...item} />
    );
  case GROUP_TYPES.ADDONS:
    return (
      <Addons {...item} />
    );
  case GROUP_TYPES.SIGNATURE:
    return (
      <Signature {...item} />
    );
  default:
    return null;
  }
};
