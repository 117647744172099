import React from "react";
import PropTypes from "prop-types";
import { TaxCard } from "components/TaxCard";
import { MoneyType } from "types/money";
import classes from "./TaxCardList.module.css";

export const TaxCardList = ({ data = [], tab }) => {
  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {
          data.map((item, index) => (
            <div className={classes.listItem} key={index}            >
              <TaxCard {...item} tab={tab} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

TaxCardList.propTypes = {
  tab: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      billing_amount: MoneyType,
      total_earning: MoneyType,
      expected_payout: MoneyType,
      owner_earns: MoneyType,
      taxes: MoneyType,
      rental_fee: MoneyType,
      delivery_fee: MoneyType,
      cleaning_fee: MoneyType,
      disposal_fee: MoneyType,
      add_on_fee: MoneyType,
      mileage_overage_fee: MoneyType,
      generator_hours_overage_fee: MoneyType,
      mileage_overage: PropTypes.number,
      generator_hours_overage: PropTypes.number,
      payout_count: PropTypes.number,
      renter_id: PropTypes.number,
      rental_id: PropTypes.number,
      nights: PropTypes.number,
      your_share: PropTypes.string,
      billing_date: PropTypes.string,
      scheduled_date: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      rv_name: PropTypes.string,
      rv_url: PropTypes.string,
      departure_date: PropTypes.string,
      return_date: PropTypes.string,
      tab: PropTypes.string
    })
  )
};
