import PropTypes from "prop-types";

export const NavDataType = PropTypes.shape({
  id: PropTypes.string,
  cb: PropTypes.func,
  extraRowClasses: PropTypes.arrayOf(PropTypes.string),
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      extraColClasses: PropTypes.arrayOf(PropTypes.string)
    })
  )
});

export const TableDataType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    textSize: PropTypes.oneOf([
      'small',
      'large',
      'xLarge'
    ]),
    /*
        Classes for extraRowClasses:

        "borderTopSolid",       // add an top solid border to the current row
        "borderTopSolid-db-m",  // add an top solid border to the current row at 768+ resolution
        "borderTopSolid-dn-m",  // remove an top solid border to the current row at 768+ resolution
        "borderTopSolid-db-l",  // add an top solid border to the current row at 1024+ resolution
        "borderTopSolid-dn-l",  // remove an top solid border to the current row at 1024+ resolution

        "borderBottomSolid",             // add a top solid border from the next row
        "borderBottomSolid-db-m",        // add a top solid border from the next row at 768+ resolution
        "borderBottomSolid-dn-m",        // remove a top solid border from the next row at 768+ resolution
        "borderBottomSolid-db-l",        // add a top solid border from the next row at 1024+ resolution
        "borderBottomSolid-dn-l",        // remove a top solid border from the next row at 1024+ resolution

        "borderTopDashed",      // add an top dashed border to the current row
        "borderTopDashed-db-m", // add an top dashed border to the current row at 768+ resolution
        "borderTopDashed-dn-m", // remove an top dashed border to the current row at 768+ resolution
        "borderTopDashed-db-l", // add an top dashed border to the current row at 1024+ resolution
        "borderTopDashed-dn-l", // remove an top dashed border to the current row at 1024+ resolution

        "borderBottomDashed",             // add a top dashed border from the next row
        "borderBottomDashed-db-m",        // add a top dashed border from the next row at 768+ resolution
        "borderBottomDashed-dn-m",        // remove a top dashed border from the next row at 768+ resolution
        "borderBottomDashed-db-l",        // add a top dashed border from the next row at 1024+ resolution
        "borderBottomDashed-dn-l",        // remove a top dashed border from the next row at 1024+ resolution

        "borderTopNone",       // remove a top border to the current row
        "borderBottomNone",    // remove a top border from the next row
        "borderTopBottomNone", // remove a top border to the current row and top border from the next row

        "smallPadding", // set a content of row padding equal to 12px top and 12px bottom
        "largePadding", // set a content of row padding equal to 12px top and 12px bottom
        "mediumPaddingBottom", // set a content of row bottom padding equal to 8px,
        "textPaddingBottomNone", // set a text of row bottom padding equal to 0px
        "textMediumMarginBottomNegative", // set a text of row bottom padding equal to -4px at 320+ resolution and set a text of row bottom padding equal to 0px at 768+ resolution
        "rowIsNotNarrow", // makes the row not narrow, removing the paddings on the sides (padding-left: 0; padding-right: 0)
      */
    extraRowClasses: PropTypes.arrayOf(PropTypes.string), // all possible classes for fine-tuning the table are described above
    cols: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        extraContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        colspan: PropTypes.oneOfType([
          PropTypes.number.isRequired,
          PropTypes.shape({
            xxSmallDevice: PropTypes.number.isRequired,
            smallDevice: PropTypes.number.isRequired
          })]),
        // "xLargeText", "boldText", "normalText"
        extraColClasses: PropTypes.arrayOf(PropTypes.string)
      })
    ).isRequired
  }));

export const ExtraTextDataType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    extraRowClasses: PropTypes.arrayOf(PropTypes.string)
  })
);
export const BannerDataType = PropTypes.shape({
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  extraContainerClasses: PropTypes.arrayOf(PropTypes.string),
  extraContentClasses: PropTypes.arrayOf(PropTypes.string)
});

export const NotesDataType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    extraRowClasses: PropTypes.arrayOf(PropTypes.string)
  })
);
