import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { renderDay } from "processes/Booking/components/Calendar/renderDay";
import { MQ_BP } from "components/MatchMedia";
import { Modal } from "components/Modal";
import { BigDayPicker } from "components/BigDayPicker";
import { useWindowSize } from "../hooks/useWindowSize";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import { CalendarModalFooter } from "processes/Booking/components/Calendar/elements/CalendarModalFooter";
import { Header } from "./elements/Header";
import classes from "./Calendar.module.css";

const Calendar = (props) => {
  const {
    show,
    minimumDuration,
    onSelectDate,
    onCloseCalendar,
    availability,
    departureDate,
    returnDate,
    dailyRateCentsTotal,
    dailyRateDollarAsPart,
    dateIntervalErrorMessage,
    isDateIntervalError,
    calculatePriceLoading,
    valueCalculatePriceData,
    price,
    total,
    discount,
    handleOnCalculatePrice,
    isInstantRental,
    instantRentalDuration,
    isDisabledPrevDates = false,
    setResetAllError = () => {}
  } = props;

  const isSizeLarge = useWindowSize() < MQ_BP.large;
  const isSizeMedium = useWindowSize() < MQ_BP.medium;
  const [isShowDiscount, setIsShowDiscount] = useState(false);
  const [isShowFooterDataSizeMedium, setShowFooterDataSizeMedium] = useState(
    false
  );

  const [{ from, to }, setPeriod] = useState({
    from: departureDate,
    to: returnDate
  });
  const resetPeriod = useCallback(() => {
    setPeriod({
      from: undefined,
      to: undefined
    });
    setIsShowDiscount(false);
  }, [setPeriod]);

  const onClickSelect = useCallback(() => {
    onSelectDate(from, to);
    onCloseCalendar();
  }, [from, to, onSelectDate, onCloseCalendar]);

  useEffect(() => {
    if (from && to) {
      handleOnCalculatePrice(from, to);
      setResetAllError(false);
    }
    if (!from || !to) {
      setShowFooterDataSizeMedium(false);
    }
  }, [from, handleOnCalculatePrice, setResetAllError, to]);

  useEffect(() => {
    if (!departureDate && !returnDate) {
      resetPeriod();
      setResetAllError(true);
    }
  }, [departureDate, returnDate, resetPeriod, setResetAllError]);

  useEffect(() => {
    if (from && to) {
      setShowFooterDataSizeMedium(true);
    }
  }, [from, to, valueCalculatePriceData]);

  useEffect(() => {
    if (!isDateIntervalError) setIsShowDiscount(true);
  }, [isDateIntervalError]);

  const isShowFooter =
    !isSizeMedium ||
    (isSizeMedium &&
      from &&
      to &&
      isShowFooterDataSizeMedium &&
      !calculatePriceLoading);

  const mdlRef = useScrollBarWidth();

  let extraData = {
    extraClasses: classes
  };
  let fromMonth = null;

  if (isDisabledPrevDates) {
    extraData = {
      ...extraData,
      extraDisabledDays: { before: new Date() }
    };
    fromMonth = new Date();
  }

  return (
    <Modal
      show={show}
      modalWrapClassnames={classes.modalRoot}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
      onClose={onCloseCalendar}
      mdlRef={mdlRef}
    >
      <div className="container">
        <div className="row">
          <div className="col-xxl-10 offset-xxl-1">
            <div className={classes.gutterRemover}>
              <Header
                from={from}
                to={to}
                resetPeriod={resetPeriod}
                minimumDuration={minimumDuration}
                calculatePriceLoading={calculatePriceLoading}
                instantRentalData={{
                  isInstantRental,
                  instantRentalDuration
                }}
              />

              <div className={classes.calendarDayPickerContainer}>
                <BigDayPicker
                  extraData={extraData}
                  isDisabledPrevDates={isDisabledPrevDates}
                  initialMonth={from}
                  fromMonth={fromMonth}
                  numberOfMonths={isSizeLarge ? 1 : 2}
                  value={{ from, to }}
                  onChange={setPeriod}
                  availability={availability}
                  filterBusyDays={() => []}
                  renderDay={(day, daysData) =>
                    renderDay(
                      day,
                      daysData,
                      dailyRateCentsTotal,
                      dailyRateDollarAsPart,
                      classes
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {isShowFooter && (
          <CalendarModalFooter
            discount={discount}
            from={from}
            to={to}
            total={total}
            price={price}
            isLoading={calculatePriceLoading}
            isShowDiscount={isShowDiscount}
            isDateIntervalError={isDateIntervalError}
            dateIntervalErrorMessage={dateIntervalErrorMessage}
            onCloseCalendar={onCloseCalendar}
            onClickSelect={onClickSelect}
            classes={classes}
          />
        )}
      </div>
    </Modal>
  );
};

Calendar.defaultProps = {
  departureDate: null,
  returnDate: null
};

Calendar.propTypes = {
  show: PropTypes.bool.isRequired,
  departureDate: PropTypes.oneOfType([
    // PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  returnDate: PropTypes.oneOfType([
    // PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minimumDuration: PropTypes.number.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  onCloseCalendar: PropTypes.func.isRequired,
  availability: PropTypes.array,
  total: PropTypes.number,
  discount: PropTypes.number,
  dailyRateCentsTotal: PropTypes.number,
  dailyRateDollarAsPart: PropTypes.number,
  dateIntervalErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null)
  ])
};

export default Calendar;
