import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const photosMutation = gql`
  ${fragments.images}

  mutation rvUpdate(
    $id: Int!
    $photos_order: [Int]!
    $photos_to_delete: [Int]!
  ) {
    rv_update(
      id: $id
      step: "photos"
      photos_order: $photos_order
      photos_to_delete: $photos_to_delete
    ) {
      result {
        success
        errors {
          field
          message
        }
      }
      rv {
        id
        listing_steps_completed
        fields_awaiting_moderation
        status {
          key
          value
        }
        ...Images
      }
    }
  }
`;
