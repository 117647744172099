import React, { useCallback } from "react";
import Typography from "components/Typography";
import Tag from "components/Tag";
import { TrashIcon } from "components/Icon/TrashIcon";
import cn from 'classnames';
import classes from "./AdditionalDriversListItem.module.css";

function getTagStatusData(status) {
  switch (status) {
  case 0:
    return {
      text: 'invite sent',
      type: 'default-dark'
    };
  case 1:
    return {
      text: 'verified',
      type: 'success'
    };
  case 2:
    return {
      text: 'declined',
      type: 'error'
    };
  default:
    return {
      text: 'expired',
      type: 'warning-dark'
    };
  }
}

const AdditionalDriversListItem = (props) => {
  const {
    id,
    rental,
    allowedRemoveDrivers,
    name,
    email,
    status,
    isOwner,
    onDelete
  } = props;

  const onDeleteDriver = useCallback(() => {
    onDelete(rental, email);
  }, [email, onDelete, rental]);

  return (
    <div className={cn(classes.container, classes.listItem, 't-4 l-16 r-16 b-16 l-m-24 r-m-24 l-xxl-32 r-xxl-32')}>
      <div className={classes.headerLine}>
        <div className={cn(classes.names)}>
          <Typography
            variant="subtitle"
            size="s"
            weight="bold"
            className={cn(
              classes.name,
              isOwner && classes.isOwner
            )}
          >
            {name}
          </Typography>
        </div>

        {!isOwner && (
          <>

            <div className={cn(classes.tagContainer, 'dn db-m')}>
              <Tag
                size="medium"
                className={classes.tag}
                {...getTagStatusData(status)}
              />
            </div>

            {allowedRemoveDrivers && (
              <div
                className={cn(classes.deleteIcon, 'mt-8 mt-m-16')}
                onClick={onDeleteDriver}
              >
                <TrashIcon />
              </div>
            )}
          </>
        )}
      </div>

      <div className={cn(classes.emailContainer, 'mt-4')}>
        <Typography
          variant="body"
          size="s"
          className={cn(
            classes.email
          )}
        >
          {email}
        </Typography>
      </div>

      {!isOwner && (
        <div className={cn(classes.tagContainer, 'dn-m mt-8')}>
          <Tag
            size="medium"
            className={classes.tag}
            {...getTagStatusData(status)}
          />
        </div>
      )}

    </div>
  );
};

AdditionalDriversListItem.propTypes = {

};

export default AdditionalDriversListItem;
