import React from "react";
import chunk from "lodash/chunk";
import classnames from "classnames";
import Typography from "components/Typography";

import style from "./RvInfo.module.css";

const RvInfo = ({ list, columns = 1 }) => {
  // Couldn't solve text overflow issue with a plain CSS, so using chunking
  return (
    <div className={style.info}>
      {chunk(list, Math.ceil(list.length / columns)).map((column, cIndex) => (
        <div key={cIndex} className={style.infoItemColumn}>
          {column.map((item) => (
            <div key={item.title} className={style.infoItem}>
              <div className={style.titleWrap}>
                <Typography
                  variant="body"
                  size="l"
                  weight="normal"
                  className={style.title}
                >
                  {item.title}
                </Typography>
              </div>
              <div
                className={classnames(
                  style.valueWrap,
                  "l-8",
                  { "r-32": cIndex !== columns - 1 }
                )}
              >
                <Typography
                  variant="body"
                  size="l"
                  weight="normal"
                  className={
                    Boolean(item.onClick) ? style.touchableValue : style.value
                  }
                >
                  <span onClick={item.onClick}>
                    {item.value}
                  </span>
                </Typography>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RvInfo;
