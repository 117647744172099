import React from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "./PublicProfileEmptyState.module.css";

export const PublicProfileEmptyState = ({ contentText, buttonLabel }) => {

  return (
    <div className={`${classes.container}`}>
      <div className="t-4 t-m-0">
        <Typography variant="subtitle" size="l" weight="bold" className={classes.title}>
          My vehicle(s)
        </Typography>
      </div>

      <div className="">
        <Typography variant="body" size="l" className={classes.contentText}>
          {contentText}
        </Typography>
      </div>
      <div className="t-12 b-16 t-m-20 b-m-20">
        <Button
          label={buttonLabel}
          onClick={() => {}}
          secondary
        />
      </div>
    </div>
  );
};

PublicProfileEmptyState.propTypes = {

};

