import { gql } from "@apollo/client";

export const rvImageFromGarageUpload = gql`
  mutation rv_image_from_garage_upload($rv_id: Int!, $photo_id: Int!) {
    rv_image_from_garage_upload(rv_id: $rv_id, photo_id: $photo_id) {
      result {
        success
        errors {
          field
          message
        }
      }
      image {
        id
        srcset {
          url
        }
        caption
      }
    }
  }
`;
