import { tickDarkGreenIcon, radioButtonQuaternaryDarkIcon } from "components/Icon";
import { ReactComponent as TickDarkGreenButtonFullIcon } from "../images/tickDarkGreenButtonFullIcon.svg";
import { ReactComponent as TickDarkGreenButtonIcon } from "../images/tickDarkGreenButtonIcon.svg";
import { ReactComponent as TickLightGrayButtonIcon } from "../images/tickLightGrayButtonIcon.svg";
import { ReactComponent as TickQuaternaryDarkButtonIcon } from "../images/tickQuaternaryDarkButtonIcon.svg";
import { ReactComponent as RadioQuaternaryDarkButtonIcon } from "../images/radioQuaternaryDarkButtonIcon.svg";
import { tickQuaternaryDarkIcon } from "components/Icon";

export  function getStepsMenuItemIcon(step, isSmallSize) {

  if (isSmallSize) {
    if (step?.completed && step?.selected) {
      return <TickDarkGreenButtonFullIcon />;
    } else if (!step?.selected && step?.completed) {
      return <TickDarkGreenButtonIcon />;
    } else if (step?.selected && !step?.completed) {
      return <TickQuaternaryDarkButtonIcon />;
    }

    return <RadioQuaternaryDarkButtonIcon />;
  } else {
    if (step?.completed && step?.selected) {
      return tickDarkGreenIcon;
    } else if (!step?.selected && step?.completed) {
      return tickDarkGreenIcon;
    } else if (step?.selected && !step?.completed) {
      return tickQuaternaryDarkIcon;
    }

    return radioButtonQuaternaryDarkIcon;
  }

}

export function setCompletedToStep(steps = [], currentStepIndex = 0) {
  if (steps.length && steps[currentStepIndex]) {
    return steps.map((step, stepIndex) =>
      stepIndex === currentStepIndex ? { ...step, completed: true } : step
    );
  } else return steps;
}

export function setWasSelectedToStep(steps = [], currentStepIndex = 0) {
  if (steps.length && steps[currentStepIndex]) {
    return steps
      .map((step, stepIndex) =>
        stepIndex === currentStepIndex ? { ...step, wasSelected: true } : step
      );
  } else return steps;
}

export function setSelectedToStep(steps = [], currentStepIndex = 0) {
  if (steps.length && steps[currentStepIndex]) {
    return steps
      .map((step) => ({ ...step, selected: false }))
      .map((step, stepIndex) =>
        stepIndex === currentStepIndex ? { ...step, selected: true } : step
      );
  } else return steps;
}

export function setSelectedToStepById(steps = [], stepId) {
  if (steps.length && stepId) {
    return steps
      .map((step) => ({ ...step, selected: false }))
      .map((step) =>
        step.id === stepId ? { ...step, selected: true } : step
      );
  } else return steps;
}

export function setSelectedAndWasSelectedToStep(steps = [], currentStepIndex = 0) {
  if (steps.length && steps[currentStepIndex]) {
    return steps
      .map((step) => ({ ...step, selected: false }))
      .map((step, stepIndex) =>
        stepIndex === currentStepIndex ? { ...step, selected: true, wasSelected: true  } : step
      );
  } else return steps;
}

export function setSelectedAndWasSelectedAndCompletedToStep(steps = [], currentStepIndex = 0) {
  if (steps.length && steps[currentStepIndex]) {
    return steps
      .map((step) => ({ ...step, selected: false }))
      .map((step, stepIndex) =>
        stepIndex === currentStepIndex ? { ...step, selected: true, wasSelected: true, completed: true  } : step
      );
  } else return steps;
}

export function setSelectedAndCompletedToStep(
  steps = [],
  currentStepIndex = 0
) {
  if (steps.length && steps[currentStepIndex]) {
    return steps
      .map((step) => ({ ...step, selected: false }))
      .map((step, stepIndex) =>
        stepIndex === currentStepIndex
          ? { ...step, selected: true, completed: true }
          : step
      );
  } else return steps;
}

export function getCompletedSteps(steps = []) {
  if (steps.length) {
    return steps.filter((step) => step.completed);
  } else return [];
}

export function getStepIndexById(steps, stepId) {
  if (steps.length) {
    return steps.find((step) => step.id === stepId)?.index;
  } else return [];
}

export function isFirstStep(currentStepIndex = 0) {
  return currentStepIndex === 0;
}

export function isLastStep(steps = [], currentStepIndex = 0) {
  if (steps.length) {
    return currentStepIndex + 1 >= steps.length;
  } else return false;
}
export function isFirstStepSelected(steps = []) {
  return steps[0].selected;
}

export function isLastStepSelected(steps = []) {
  return steps[steps?.length - 1].selected;
}

export function isIndexOfStep(currentStepIndex = 0, indexOfStep) {
  return currentStepIndex === indexOfStep;
}

