import React from "react";
import PropTypes from "prop-types";
import { Review } from "components/Reviews/components/Review/Review";
import classes from "./ReviewsList.module.css";

export const ReviewsList = ({ data = [] }) => {
  const reviews = data.map((review, index) =>(
    <div
      key={review?.id}
      className={`t-16 b-16 t-m-20 b-m-20 ${index !== 0 ? 'mt-m-20' : ''}`}
    >
      <Review {...review} />
    </div>
  ));

  return (
    <div className={classes.container}>
      <div className={`${classes.reviews} `}>
        {reviews}
      </div>
    </div>
  );
};

ReviewsList.propTypes = {
  photo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  name: PropTypes.string,
  rating: PropTypes.number,
  date: PropTypes.string,
  description: PropTypes.string,
  galeryPhotos: PropTypes.array
};
