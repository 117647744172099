import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import { TabSliderMenu } from "processes/RvProfile/components/TabSliderContainer/TabSliderMenu/TabSliderMenu";
import { TabSliderItem } from "processes/RvProfile/components/TabSliderContainer/TabSlider/TabSliderItem";
import classes from "processes/RvProfile/components/TabSliderContainer/TabSlider/TabSlider.module.css";

export const TabSliderContainer = ({ contentData, menuData }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className={classes.container}>
      <div className="row">
        <div className="col-m-4 col-l-3 dn db-m">
          <div className={classes.menuContainer}>
            <TabSliderMenu data={menuData} tabActive={tabIndex} onClick={(index) => setTabIndex(index)} />
          </div>
          <div className="t-2 b-2 l-24 r-24 t-m-0">
            <Button
              ghost
              label="View All"
              onClick={function noRefCheck(){}}
            />
          </div>
        </div>
        <div className="col-m-8 col-l-9">
          <TabSliderItem {...contentData[tabIndex]} />
        </div>
      </div>
    </div>
  );
};

TabSliderContainer.propTypes = {
  photos: PropTypes.array.isRequired,
  initialSlide: PropTypes.number
};

