import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const acceptLastSuggestion = gql`
  ${baseFragments.result}
  mutation acceptLastSuggestion($id: Int!, $signature_name: String) {
    accept_last_suggestion(id: $id, signature_name: $signature_name) {
      result {
        ...Result
      }
    }
  }
`;

export const declineRental = gql`
  ${baseFragments.result}
  mutation declineRental($id: Int!, $details: String, $reason: Int) {
    decline(id: $id, details: $details, reason: $reason) {
      result {
        ...Result
      }
    }
  }
`;

export const cancelRental = gql`
  ${baseFragments.result}
  mutation cancelRental($id: Int!, $details: String, $reason: Int, $force: Boolean) {
    cancel(id: $id, details: $details, reason: $reason, force: $force) {
      result {
        ...Result
      }
    }
  }
`;

export const withdrawLastSuggestion = gql`
  ${baseFragments.result}
  mutation withdrawLastSuggestion($id: Int!) {
    withdraw_last_suggestion(rental: $id) {
      result {
        ...Result
      }
    }
  }
`;

export const declineLastSuggestion = gql`
  ${baseFragments.result}
  mutation declineLastSuggestion($id: Int!) {
    decline_last_suggestion(id: $id) {
      result {
        ...Result
      }
    }
  }
`;
