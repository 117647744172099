import React from "react";
import PropTypes from "prop-types";
import { FILTERS } from "../../filters";
import MobileFilterBlock from "./MobileFilterBlock";
import NumberRangePicker from "../../../form/NumberRangePicker/NumberRangePicker";
import classes from "./MobileFilterLength.module.css";

const MobileFilterLength = (props) => {
  const { min, max } = props;

  return (
    <MobileFilterBlock
      id={FILTERS.ids.length}
      getLabelFromValue={value => (
        Number.isFinite(value.start) && Number.isFinite(value.end)
          ? `${value.start}-${value.end} ft`
          : null
      )}
      renderForm={({ value, onChange }) => (
        <NumberRangePicker
          className="mt-24 mb-16"
          startInputClassName={classes.startInput}
          endInputClassName={classes.endInput}
          sliderClassName="rc-slider-track__dark"
          id="length"
          startLabel="Min. ft"
          endLabel="Max. ft"
          min={min}
          max={max}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

MobileFilterLength.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number
};

export default MobileFilterLength;
