import { gql } from "@apollo/client";

export const generateMedallionToken = gql`
  mutation generate_medallion_token (
    $first_name: String!
    $last_name: String!
    $middle_name: String
    $date_of_birth: String!
    $redirect_url: String!
  ) {
    generate_medallion_token(
      first_name: $first_name
      last_name: $last_name
      middle_name: $middle_name
      date_of_birth: $date_of_birth
      redirect_url: $redirect_url
    ) {
      token
      result {
        success
        errors {
          code
          field
          message
        }
      }
    }
  }
`;
