import React, { useState, useCallback } from "react";
import Checkout from "components/BecomeMemberShip/steps/Checkout";
import { useMutation } from "@apollo/client";
import { goodSamMembershipOrder } from "../mutations/goodSamMembershipOrder";
import {
  extractServerErrors,
  extractSuccess,
  extractServerErrorsCode,
  extractServerErrorMessage
} from "utils/extractErrors";
import { VERIFICATION_ERROR_CODE_LIST, DEFAULT_DATA } from "components/VerificationCodeStepsModals/constants/verificationErrorCodes";
import VerificationCodeStepsModals from "components/VerificationCodeStepsModals/VerificationCodeStepsModals";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { SpinnerBack } from "components/Spinner/Spinner";
import classes from "./CheckoutContainer.module.css";

const extractGoodSamMembershipOrderSuccess = extractSuccess("good_sam_membership_order");
const extractGoodSamMembershipOrderErrors = extractServerErrors("good_sam_membership_order");
const extractGoodSamMembershipOrderErrorCode = extractServerErrorsCode("good_sam_membership_order");
const extractGoodSamMembershipOrderErrorMessage = extractServerErrorMessage("good_sam_membership_order");

const CheckoutContainer = (props) => {
  const { onNextStep, planId, planPrice, classes: parentClass } = props;
  const [verificationStepsData, setVerificationStepsData] = useState(DEFAULT_DATA);
  const [error, setError] = useState("");
  const [goodSamMembershipOrderAction, { loading }] = useMutation(
    goodSamMembershipOrder
  );

  const handlePay = useCallback(async () => {
    const variables = {
      offer_id: planId
    };

    const response = await goodSamMembershipOrderAction({
      variables
    });

    if (extractGoodSamMembershipOrderErrors(response)?.length > 0) {
      const errorCode = extractGoodSamMembershipOrderErrorCode(response);
      const errorText = extractGoodSamMembershipOrderErrorMessage(response);

      if (VERIFICATION_ERROR_CODE_LIST.includes(errorCode)) {
        setVerificationStepsData({
          code: errorCode,
          variables
        });
      } else {
        setError(errorText);
      }
    }

    if (extractGoodSamMembershipOrderSuccess(response)) {
      onNextStep();
    }
  }, [goodSamMembershipOrderAction, onNextStep, planId]);

  return (
    <div className={`${classes.checkoutContainer} ${parentClass.checkoutContainer}`}>
      <Checkout
        planPrice={planPrice}
        onNextStep={handlePay}
        disabled={loading}
      />

      <VerificationCodeStepsModals
        data={verificationStepsData}
        onResetData={setVerificationStepsData}
        onRefetchMutation={handlePay}
      />

      {error?.length !== 0 && (
        <NotificationPopover
          show
          status="error"
          text={error}
          onClose={() => {
            setError('');
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}

      {loading && <SpinnerBack />}
    </div>
  );
};

CheckoutContainer.propTypes = {};

export default CheckoutContainer;
