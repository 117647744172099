import React from "react";
import PropTypes from "prop-types";
import classes from "./ProTipsDescription.module.css";

export const ProTipsDescription = ({ content, className }) => (
  <div className={`${classes.container} ${className}`}>
    <div
      className={classes.content}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </div>
);

ProTipsDescription.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string
};
