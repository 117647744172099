import { gql } from "@apollo/client/core";

export const rvListingPreview = gql`
  query rvListingPreview($id: Int!) {
    rv_listing_preview(id: $id) {
      result {
        success
        errors {
          field
          message
          section
        }
      }
    }
  }
`;
