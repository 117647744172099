import React, { useCallback, useState } from "react";
import { useMutation } from '@apollo/client';
import { sendPhoneVerificationMutation } from "../mutations/sendPhoneVerificationCodeMutation";
import { phoneSaveMutation } from "../mutations/phoneSaveMutation";
import { extractServerErrors, extractSuccess, extractServerErrorsCode } from "../../../../../utils/extractErrors";
import { PhoneNumberForm } from "./PhoneNumberForm";
import { SpinnerBack } from "components/Spinner/Spinner";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { ERROR_CODES } from "constants/errorCodes";

const extractSendPhoneVerificationCodeSuccess = extractSuccess("send_phone_verification_code");
const extractSendPhoneVerificationCodeErrors = extractServerErrors("send_phone_verification_code");
const extractSendPhoneVerificationCodeErrorsCode = extractServerErrorsCode("send_phone_verification_code");
const extractPhoneSaveSuccess = extractSuccess("phone_save");
const extractPhoneSaveErrors = extractServerErrors("phone_save");

export const PhoneNumber = ({ content, userData, sectionInEditing, toggleSectionInEditing }) => {
  const [phoneNumber, setPhoneNumber] = useState(userData.phone || "");
  const [isVerified, setIsVerified] = useState(userData.phone_verified || false);
  const [inEditing, setInEditing] = useState(false);
  const [error, setError] = useState("");
  const [inVerifying, setInVerifying] = useState(false);
  const [isSendPhoneVerificationCodeWhenOpenModal, setSendPhoneVerificationCodeWhenOpenModal] = useState(false);

  const toggleInEditing = () => {
    setInEditing(!inEditing);
    toggleSectionInEditing(!inEditing);
  };

  const [action, result] = useMutation(phoneSaveMutation);
  const [
    sendPhoneVerificationCode,
    sendPhoneVerificationCodeResult
  ] = useMutation(sendPhoneVerificationMutation);

  const handleSendPhoneVerificationCode = useCallback(async () => {
    try {
      const response = await sendPhoneVerificationCode();

      if (extractSendPhoneVerificationCodeErrors(response)?.length !== 0) {
        setError(extractSendPhoneVerificationCodeErrors(response)?.[0]?.message);
        setInVerifying(false);
      }

      if (extractSendPhoneVerificationCodeSuccess(response)) {
        setError('');
        setInVerifying(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [sendPhoneVerificationCode]);

  const handlePhoneSave = useCallback(async variables => {
    try {
      const response = await action({ variables });

      if (extractPhoneSaveErrors(response)?.length !== 0) {
        setError(extractPhoneSaveErrors(response)?.[0]?.message);
        setInVerifying(false);
        setIsVerified(false);
        setPhoneNumber(response?.data?.phone_save?.user?.phone);
        return;
      }

      const {
        data: {
          phone_save: {
            user: {
              phone,
              phone_verified
            }
          }
        }
      } = response;

      if (extractPhoneSaveSuccess(response)) {
        setPhoneNumber(phone);
        setIsVerified(phone_verified);
        setInEditing(false);
        toggleSectionInEditing(false);
        setInVerifying(true);
        setError('');
      }
    } catch (err) {
      console.error(err);
    }
  }, [action, setPhoneNumber, setIsVerified, setInEditing, setInVerifying, toggleSectionInEditing]);

  return (
    <>
      <PhoneNumberForm
        content={content}
        onChange={setPhoneNumber}
        phoneNumber={phoneNumber}
        handleSendPhoneVerificationCode={handleSendPhoneVerificationCode}
        isVerified={isVerified}
        setIsVerified={setIsVerified}
        isSendPhoneVerificationCodeWhenOpenModal={isSendPhoneVerificationCodeWhenOpenModal}
        setSendPhoneVerificationCodeWhenOpenModal={setSendPhoneVerificationCodeWhenOpenModal}
        inVerifying={inVerifying}
        setInVerifying={setInVerifying}
        inEditing={inEditing}
        sectionInEditing={sectionInEditing}
        toggleInEditing={toggleInEditing}
        onSubmit={handlePhoneSave}
        loading={result.loading}
      />
      {error?.length !== 0 && (
        <NotificationPopover
          show={true}
          status="error"
          text={error}
          onClose={() => {
            setError('');
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}
      {(result.loading || sendPhoneVerificationCodeResult.loading) && <SpinnerBack />}
    </>
  );
};
