import {
  INSPECTION_STATUS_KEYS,
  INSPECTION_STATUS_ICONS,
  INSPECTION_MILEAGE_AND_GENERATOR_IDS
} from "utils/constants/inspections";
import { isUserRenter } from "processes/Rentals/utils";

export function getInspectionData(data = {}, isInspectionOverage, isRenter) {
  const {
    groups,
    visible_from,
    is_available,
    status: { key: statusKey },
    is_full
  } = data;

  let isShowMileage = false;
  let isShowGenerator = false;
  let mileageValue = '';
  let generatorValue = '';
  let mileageMinValue = 0;
  let generatorMinValue = 0;
  let mileageMinError = "";
  let generatorMinError = "";

  try {
    for (const groupIter of groups) {
      for (const itemIter of groupIter.items) {
        const value = itemIter.value === "" ? itemIter.value  : JSON.parse(itemIter.value);

        if (itemIter.id === INSPECTION_MILEAGE_AND_GENERATOR_IDS.MILEAGE) {
          const { min, minError } = JSON.parse(itemIter.data);

          mileageMinValue = min;
          mileageMinError = minError;
          isShowMileage = true;
          mileageValue = value;
        }

        if (itemIter.id === INSPECTION_MILEAGE_AND_GENERATOR_IDS.GENERATOR) {
          const { min, minError } = JSON.parse(itemIter.data);

          generatorMinValue = min;
          generatorMinError = minError;
          isShowGenerator = true;
          generatorValue = value;
        }
      }
    }
  } catch(e) {
    console.log(e);
  }

  const dateStamp = Date.now() / 1000;
  const timeFlag = (dateStamp > visible_from);
  const availableForPass = is_available;
  const submitMode = statusKey === INSPECTION_STATUS_KEYS.INFO && (isShowMileage || isShowGenerator);
  const haveActions = (timeFlag && availableForPass) || (!submitMode && availableForPass && timeFlag);

  let statusIcon = INSPECTION_STATUS_ICONS.NO_ICON;
  let buttonDisabled = true;

  if (submitMode) {
    buttonDisabled = !(timeFlag && availableForPass) || isRenter;
  } else {
    buttonDisabled = !(statusKey !== INSPECTION_STATUS_KEYS.INFO && is_full);
  }
  if (!isInspectionOverage) {
    if (buttonDisabled) {
      statusIcon = INSPECTION_STATUS_ICONS.LIGHT_GRAY;
    } else {
      if (submitMode) {
        statusIcon = INSPECTION_STATUS_ICONS.GRAY;
      } else {
        statusIcon = INSPECTION_STATUS_ICONS.GREEN;
      }
    };
  } else {
    statusIcon = INSPECTION_STATUS_ICONS.NO_ICON;
  }

  return {
    isShowMileage,
    isShowGenerator,
    mileageValue,
    generatorValue,
    mileageMinValue,
    generatorMinValue,
    mileageMinError,
    generatorMinError,
    submitMode,
    haveActions,
    buttonDisabled,
    statusIcon
  };
}

export function getInspectionsData(data) {
  const {
    inspection_pick_up,
    inspection_drop_off,
    inspection_overage,
    role
  } = data;

  const isRenter = isUserRenter(role);
  const pickUpInspectionData = getInspectionData(inspection_pick_up, inspection_overage, isRenter);
  const dropOffInspectionData = getInspectionData(inspection_drop_off, inspection_overage, isRenter);

  const { haveActions: pickUpHaveActions } = pickUpInspectionData;
  const { haveActions: dropOffHaveActions } = dropOffInspectionData;
  let isShowInspections = false;

  if (pickUpHaveActions || dropOffHaveActions || inspection_overage) {
    isShowInspections = true;
  }

  return {
    pickUp: pickUpInspectionData,
    dropOff: dropOffInspectionData,
    isShowInspections
  };
}
