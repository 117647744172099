import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import PaymentView from "components/payment/PaymentView";
import { userQuery } from "processes/MyAccount/queries";
import { SpinnerBack } from "components/Spinner/Spinner";
import Button from "components/Button";
import classes from "./PaymentMethod.module.css";

const PaymentMethod = ({ onNextStep, paymentMethodStepClassName, stepId }) => {
  const {
    loading: userQueryLoading,
    data: userQueryData
  } = useQuery(userQuery, {
    fetchPolicy: "no-cache"
  });

  const [userNewData, setUserNewData] = useState(null);
  const userData = userQueryData?.user;

  const updateUserData = useCallback(
    (newPartialData) => {
      setUserNewData({ ...userData, ...newPartialData });
    },
    [userData]
  );
  const handleOnSave = useCallback(() => {
    onNextStep({ idStep: stepId });
  }, [onNextStep, stepId]);

  const handleNextStep = useCallback(() => {
    onNextStep();
  }, [onNextStep]);

  const getPreviewButtons = useCallback(
    ({ isCardExpired }) => (
      <div className={classes.footerButtonNext}>
        <Button
          onClick={handleNextStep}
          label="Proceed to checkout"
          disabled={isCardExpired}
        />
      </div>
    ),
    [handleNextStep]
  );

  if (userQueryLoading) {
    return <SpinnerBack />;
  }

  return (
    <PaymentView
      userData={userNewData || userData}
      updateUserData={updateUserData}
      onSave={handleOnSave}
      getPreviewButtons={getPreviewButtons}
      textButtonNextStep="Update"
      buttonsClassName={classes.footerButtons}
      buttonCancelClassName={classes.footerButtonCancel}
      buttonsContainerInnerClassName={classes.buttonsContainerInner}
      paymentMethodStepClassName={paymentMethodStepClassName}
    />
  );
};

export default PaymentMethod;
