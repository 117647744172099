import React, { forwardRef } from "react";
import { GoogleMapsProvider } from "components/GoogleMapsProvider/GoogleMapsProvider";
import { MapContentLoader } from "./MapContentLoader";

const libraries = ["places"];

export const MapComponent = forwardRef((props, ref) => (
  <GoogleMapsProvider libraries={libraries}>
    <MapContentLoader ref={ref} {...props} />
  </GoogleMapsProvider>
));
