import React from "react";
import Typography from "components/Typography";
import avatarEmpty from "processes/Rentals/RentalDetails/components/ProfileInfoAside/no-avatar.svg";
import classes from "./ProfileInfoDeletedAside.module.css";

const ProfileInfoDeletedAside = () => {

  return (
    <div className={classes.container}>
      <div className='t-32 r-24 l-24 b-32 b-l-28 b-xl-32 l-xxl-32 r-xxl-32'>
        <div className="fss">
          <div className="mr-24">
            <div className={classes.avatar}>
              <img
                className={classes.avatarImg}
                src={avatarEmpty}
                alt={"User Deleted"}
              />
            </div>
          </div>

          <div>
            <div>
              <Typography
                variant="subtitle"
                size="l"
                weight="bold"
                component="strong"
              >
                <span className={classes.name}>
                  DELETED
                </span>
              </Typography>
            </div>

            <div className="mt-xl-4">
              <Typography
                variant="body"
                size="l"
              >
                Account has been deleted
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfoDeletedAside;
