import React from "react";
import PropTypes from "prop-types";
import { FILTERS } from "../../filters";
import VehicleClassCardsList from "../../../vehicles/VehicleClassCardsList";
import MobileFilterBlock from "./MobileFilterBlock";
import { getObjectPositiveValuesCount } from "../../../../utils/basic";
import omit from "lodash/omit";
import classes from "./MobileFilterClass.module.css";

const MobileFilterClass = (props) => {
  const { classesList } = props;

  return (
    <MobileFilterBlock
      id={FILTERS.ids.class}
      getLabelFromValue={value => {
        const count = getObjectPositiveValuesCount(value);
        const total = classesList.length;
        return count > 0 ? `${count} out of ${total} selected` : null;
      }}
      renderForm={({ value, onChange }) => {
        return (
          <VehicleClassCardsList
            className={classes.list}
            list={classesList}
            state={value}
            onChange={({ name, checked }) => {
              onChange(omit(
                { ...value, [name]: checked },
                !checked ? name : null
              ));
            }}
          />
        );
      }}
    />
  );
};

MobileFilterClass.propTypes = {
  classesList: PropTypes.array.isRequired
};

export default MobileFilterClass;
