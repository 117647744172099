import React from "react";
import AutocompleteAddress from "components/form/AutoComplete/AutocompleteAddress";
import { useGoogleMaps } from "components/GoogleMapsProvider";

export const AutocompleteLocationAddress = (props) => {
  const { isLoaded } = useGoogleMaps();

  return isLoaded ? (
    <AutocompleteAddress
      {...props}
    />
  ) : (
    "Loading..."
  );
};
