import React, { useRef, useState, useCallback } from "react";
import Typography from "components/Typography";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as IconQuestion } from "assets/images/icon-question.svg";
import { FieldArray } from "components/form/FieldArray";
import uniqueId from "lodash/uniqueId";
import { FieldController } from "components/form/FieldController/FieldController";
import { RX_FLOAT, RX_INT } from "utils/constants/RX";
import { AutocompleteLocationAddress }from "components/Listing/steps/LocationDelivery/components/AutocompleteLocationAddress/AutocompleteLocationAddress";
import { GoogleMapsProvider } from "components//GoogleMapsProvider";
import { isSafeInt } from "utils/isSafeInt";
import { FieldInput } from "components/form/FieldInput";
import Button from "components/Button";
import { trashIcon } from "components/Icon";
import { FieldTextarea } from "components/form/FieldTextarea";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Switch from "components/form/Switch";
import scrollIntoView from "utils/scrollIntoView";
import classes from "../LocationRentalTabs/LocationRentalTab.module.css";

export const SpecificDestinations = (props) => {

  const {
    control,
    control: { setValue, getValues },
    tabIndex,
    errors,
    clearErrors,
    watch
  } = props;

  const { location, tabs } = watch();
  const {
    allowSwitch,
    specific_destinations,
    specificDeliveryLocations
  } = tabs[tabIndex];

  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const specificDeliveryLocationsAddButton = useRef(null);
  const [specificDeliveryLocationsCount, setSpecificDeliveryLocationsCount] = useState(specificDeliveryLocations?.length);

  const onSpecificDeliveryLocationsRemove = useCallback(
    (index, rowCount) => {
      const specificDeliveryLocationsIds = getValues(`tabs[${tabIndex}].specificDeliveryLocationsIds`);
      const deletedSpecificDeliveryLocationsIds = getValues(`tabs[${tabIndex}].deletedSpecificDeliveryLocationsIds`);
      const deletedTab = specificDeliveryLocationsIds[index];
      specificDeliveryLocationsIds.splice(index, 1);
      setValue(`tabs[${tabIndex}].deletedSpecificDeliveryLocationsIds`, [...deletedSpecificDeliveryLocationsIds, deletedTab]);
      setValue(`tabs[${tabIndex}].specificDeliveryLocationsIds`, [...specificDeliveryLocationsIds]);
      if (rowCount === 0) {
        setValue(`tabs[${tabIndex}].specific_destinations`, false);
      }
      setSpecificDeliveryLocationsCount(rowCount);
    },
    [getValues, setValue, tabIndex]
  );
  return (
    <>
      <div className="row fsc mt-20 mt-m-28 mt-xl-24">
        <div className="col fsc">
          <Typography variant="subtitle" size="m" className={classes.subTitle}>
            Specific destinations
          </Typography>

          <Tooltip
            tooltipContainerClassName={classes.tooltipQuestionContainer}
            trigger={['click']}
            tooltipContent="For a specific campground or location that you offer flat-rate delivery to, enter the
            location and fee here. Also include information about what is included with this
            delivery rate."
          >
            {(ref) => (
              <span className={`${classes.btnIcon} dib`} ref={ref}>
                <IconQuestion  />
              </span>
            )}
          </Tooltip>

        </div>
        <div className={`col-auto specific_destinations-${tabIndex}`}>
          <div className="t-xl-4">
            <FieldController
              name={`tabs[${tabIndex}].specific_destinations`}
              control={control}
              render={(renderProps) => (
                <Switch
                  id={`specific_destinations-${tabIndex}`}
                  {...renderProps}
                  onChange={(checked) => {
                    renderProps.onChange(checked);
                    if (checked) {
                      scrollIntoView(`.specific_destinations-${tabIndex}`, null, { behavior: "smooth", block: "start" });
                    }
                    if (specificDeliveryLocationsCount === 0) {
                      specificDeliveryLocationsAddButton.current.click();
                      setSpecificDeliveryLocationsCount(1);
                    }

                  }}
                  disabled={!allowSwitch || location?.full_address === ""}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={!allowSwitch || !specific_destinations ? 'dn mt-8' : 'mt-8 mt-m-12'}>
        <FieldArray
          control={control}
          trigger={() => {return true;}}
          name={`tabs[${tabIndex}].specificDeliveryLocations`}
          onRemove={onSpecificDeliveryLocationsRemove}
          defaultValues={
            {
              id: uniqueId('new-row_'),
              location: {
                full_address: "",
                latitude: "",
                longitude: ""
              },
              delivery_fee: '',
              description: ""
            }
          }
          errors={errors}
          renderRow={(rowProps) => {
            return (
              <>
                <div key={rowProps.id} className={`${classes.destination} t-32 l-16 r-16 b-36 b-m-48 t-m-36 mb-16 mb-m-20`}>

                  <div className="row">

                    <div className="col col-12 col-m-7 mb-32 mb-m-36">
                      <FieldController
                        control={control}
                        name={`${rowProps.fieldName}[${rowProps.rowIndex}].location`}
                        defaultValue={rowProps.location}
                        rules={{
                          required: specific_destinations && allowSwitch ? "Delivery Location is required" : false,
                          validate: (v) => {
                            if (v?.full_address?.length === 0 && specific_destinations && allowSwitch ) {
                              return "Incorrect input";
                            }
                          }
                        }}
                        render={(renderProps) => {
                          const error = errors?.tabs?.[tabIndex]?.specificDeliveryLocations?.[rowProps.rowIndex]?.location || false;

                          return (
                            <GoogleMapsProvider>
                              <AutocompleteLocationAddress
                                label={"Delivery location"}
                                hasError={Boolean(error)}
                                defaultValue={rowProps.location.full_address}
                                isReturnAllTypes={true}
                                className={`${classes.destinationField}`}
                                {...renderProps}
                                onChange={(e) => {
                                  renderProps.onChange(e);
                                  clearErrors(`${rowProps.fieldName}[${rowProps.rowIndex}].location`);
                                }}
                                onChangeValue={() => {
                                  setValue(`${rowProps.fieldName}[${rowProps.rowIndex}].location`, {
                                    full_address: "",
                                    latitude: "",
                                    longitude: ""
                                  });
                                }}
                              />
                            </GoogleMapsProvider>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 col-m-5">
                      <div className="row">

                        <div className="col">
                          <FieldController
                            control={control}
                            name={`${rowProps.fieldName}[${rowProps.rowIndex}].delivery_fee`}
                            defaultValue={rowProps.delivery_fee}
                            rules={{
                              required: specific_destinations && allowSwitch ? "Price is required" : false,
                              validate: (v) => {
                                if (!isSafeInt(parseInt(v, 10) * 100) && specific_destinations && allowSwitch) {
                                  return "Incorrect input";
                                }
                              },
                              pattern: {
                                value: RX_FLOAT,
                                message: "Incorrect input"
                              }
                            }}
                            render={(renderProps) => (
                              <FieldInput
                                {...renderProps}
                                onChange={(e) => {
                                  renderProps.onChange(e.target.value);
                                  if (e.target.value) {
                                    clearErrors(`${rowProps.fieldName}[${rowProps.rowIndex}].delivery_fee`);
                                  }
                                }}
                                id={`price-${rowProps.rowIndex}`}
                                label={"Delivery fee, $"}
                                errors={errors}
                                className={`fw ${classes.destinationField}`}
                                helperMessage="e.g. $10"
                              />
                            )}
                          />
                        </div>

                        <div className="col-auto">
                          <Button
                            label=""
                            secondary
                            onClick={rowProps.handleRemove}
                            icon={trashIcon}
                          />
                        </div>

                      </div>
                    </div>

                  </div>

                  <div className="row mt-36 t-2 mt-m-0 t-m-0">
                    <div className="col">
                      <FieldController
                        control={control}
                        name={`${rowProps.fieldName}[${rowProps.rowIndex}].description`}
                        defaultValue={rowProps.description}
                        render={(renderProps) => {
                          const { value } = renderProps;
                          return (
                            <FieldTextarea
                              style={{ minHeight: '76px' }}
                              resize="none"
                              rows={2}
                              className={`fw ${classes.destinationField}`}
                              id={`description-${rowProps.rowIndex}`}
                              name={`description-${rowProps.rowIndex}`}
                              label={"Description of delivery"}
                              maxLength={500}
                              helperMessage={!value && !isSmallDevice ? "Tell us about the delivery conditions to this location" : ""}
                              {...renderProps}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }}
          render={({ handleAdd }) => {
            return (
              <>
                <div className="b-16 t-m-0 b-m-20">
                  <Button
                    ref={specificDeliveryLocationsAddButton}
                    secondary
                    label="Add"
                    onClick={() => {
                      handleAdd();
                      const specificDeliveryLocationsIds = getValues(`tabs[${tabIndex}].specificDeliveryLocationsIds`);
                      setValue(`tabs[${tabIndex}].specificDeliveryLocationsIds`, [...specificDeliveryLocationsIds, 0]);
                    }}
                  />
                </div>
                <div className="devider-solid mb-16 mt-16 mt-m-16 mb-m-4" />
              </>
            );
          }}
        />
      </div>
    </>
  );
};
