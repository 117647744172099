import React from "react";
import NoItemsPageNotice from "components/NoItemsPageNotice/NoItemsPageNotice";

import icon from "./icon.svg";

const EmptyVehiclesList = () => {

  return (
    <div>
      <div className="b-xl-60" />

      <NoItemsPageNotice
        icon={icon}
        title="There are no vehicles to display"
        description="Try changing your search"
      />

      <div className="b-40 b-m-60" />

      <div className="b-0 b-xl-60" />
    </div>
  );
};

export default EmptyVehiclesList;
