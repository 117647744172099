import React, { useState } from "react";
import { BookingModal } from "./components/BookingModal";
import { BookingWidget } from "./BookingWidget";
import { useHistory } from "react-router-dom";
import { BOOKING_CONTINUE_HASH } from "./components/BookingModal/BookingModal";

export const BookingContent = (props) => {
  const {
    isAllData = false,
    price,
    onCalculatePrice,
    onRefetchRvInitialData,
    state,
    dispatch,
    onReserve,
    isIMGlobalIntegrationIsEnabled,
    analyticsData
  } = props;
  const { location } = useHistory();
  const [isShowModal, setShowModal] = useState(
    () => location.hash === BOOKING_CONTINUE_HASH && isAllData
  );

  const [isShowModalBookingWidget, setIsShowModalBookingWidget] = useState(
    false
  );
  const [isShowCalendar, setShowCalendar] = useState(false);
  const [isShowPickUpDeliveryModal, setShowPickUpDeliveryModal] = useState(
    false
  );
  const [rentalId, setRentalId] = useState(null);
  const [isShowBookingInsurance, setIsShowBookingInsurance] = useState(false);
  const [isShowBookingMembership, setIsShowBookingMembership] = useState(false);
  const [isShowBookingRentalDetails, setIsShowBookingRentalDetails] = useState(
    false
  );
  return (
    <BookingModal
      isAllData={isAllData}
      isShowModalBookingWidget={isShowModalBookingWidget}
      setIsShowModalBookingWidget={setIsShowModalBookingWidget}
    >
      {(isInModal) => (
        <BookingWidget
          analyticsData={analyticsData}
          isAllData={isAllData}
          isInModal={isInModal}
          isShowModalBookingWidget={isShowModalBookingWidget}
          setIsShowModalBookingWidget={setIsShowModalBookingWidget}
          isShowModal={isShowModal}
          setShowModal={setShowModal}
          isShowCalendar={isShowCalendar}
          setShowCalendar={setShowCalendar}
          isShowPickUpDeliveryModal={isShowPickUpDeliveryModal}
          setShowPickUpDeliveryModal={setShowPickUpDeliveryModal}
          rentalId={rentalId}
          setRentalId={setRentalId}
          isShowBookingInsurance={isShowBookingInsurance}
          setIsShowBookingInsurance={setIsShowBookingInsurance}
          isShowBookingMembership={isShowBookingMembership}
          setIsShowBookingMembership={setIsShowBookingMembership}
          isShowBookingRentalDetails={isShowBookingRentalDetails}
          setIsShowBookingRentalDetails={setIsShowBookingRentalDetails}
          state={state}
          price={price}
          onCalculatePrice={onCalculatePrice}
          onRefetchRvInitialData={onRefetchRvInitialData}
          dispatch={dispatch}
          onReserve={onReserve}
          isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
        />
      )}
    </BookingModal>
  );
};
