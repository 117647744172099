import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import BookingPaymentOptionsTable from "processes/Booking/components/BookingModal/steps/BookingPaymentOptions/BookingPaymentOptionsTable/BookingPaymentOptionsTable";
import Typography from "components/Typography";
import { FieldTextarea } from "components/form/FieldTextarea";
import { useForm, useWatch } from "react-hook-form";
import Form from "components/form/Form/Form";
import { FieldController } from "components/form/FieldController/FieldController";
import StepContainer from "processes/Booking/components/BookingModal/components/StepContainer/StepContainer";
import Button from "components/Button";
import Checkbox from "components/form/Checkbox";
import { VALIDATION } from "utils/validation";
import { useBookingModalContext } from "processes/Booking/components/BookingModal/BookingModalContext";
import { SpinnerBack } from "components/Spinner/Spinner";
import { withDisableButtonOnQuery } from "../../../../withDisableButtonOnQuery";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import classes from "./BookingPaymentOptionsTable.module.css";

const ConfirmButton = withDisableButtonOnQuery(Button);

const BookingPaymentOptions = ({ modalRef, isVisible, hasScrollShadow }) => {
  const {
    prices,
    price_form_detailed,
    pricesLoading,
    pricesError,
    reserve,
    reserveLoading,
    reserveError,
    errors: reserveErrors,
    pricesErrors,
    isInterruptionVisible,
    isLoadingCalculatePriceRefetch,
    isLoading
  } = useBookingModalContext();

  const isMinHeightMedium = useMatchMedia({ mediaQuery: "(min-width: 1024px)" });
  const [
    isShowNotificationPopoverReserve,
    setShowNotificationPopoverReserve
  ] = useState(false);
  const [
    isShowNotificationPopoverPrice,
    setShowNotificationPopoverPrice
  ] = useState(false);
  const isMinSmallMaxMedium = useMatchMedia({
    minWidth: MQ_BP.small,
    maxWidth: MQ_BP.medium
  });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      message: "",
      agreement: false
    }
  });
  useEffect(() => {
    if (reserveErrors?.length) {
      setShowNotificationPopoverReserve(true);
    }
  }, [reserveErrors]);
  useEffect(() => {
    if (pricesErrors?.length) {
      setShowNotificationPopoverPrice(true);
    }
  }, [pricesErrors]);
  const isAgreed = useWatch({ control, name: "agreement" });

  if (isLoading) return <SpinnerBack />;

  const totalPrice = prices?.prepayment_for_rental?.user_friendly;

  const agreementField = (
    <FieldController
      name="agreement"
      control={control}
      rules={VALIDATION.rules.trueCheckbox}
      render={(renderProps) => (
        <Checkbox
          {...renderProps}
          className="mr-32"
          text={
            <div>
              I verify that I meet all the Good Sam RV Rentals &nbsp;
              <a href="/terms-of-service" target="_blank">
                Terms of Service.
              </a>
            </div>
          }
          hasError={!!errors?.agreement}
          onChange={(event) => {
            renderProps.onChange(event.target.checked);
          }}
        />
      )}
    />
  );

  const buttons = (
    <>
      {(isMinSmallMaxMedium || isMinHeightMedium) && agreementField}
      {totalPrice && (
        <ConfirmButton
          label={`Confirm and pay`}
          onClick={handleSubmit(({ message }) => {
            reserve(message);
          })}
          disabled={!isAgreed}
          className={classes.confirmButton}
        />
      )}
    </>
  );

  return (
    <StepContainer
      buttons={!pricesLoading ? buttons : null}
      buttonsClassName={classes.buttons}
      hasScrollShadow={hasScrollShadow}
      isPaymentOptions
    >
      {(pricesLoading || isLoadingCalculatePriceRefetch) && <SpinnerBack />}
      {pricesError && (
        <NotificationPopover
          show={isShowNotificationPopoverPrice}
          status="error"
          text={pricesError}
          onClose={() => {
            setShowNotificationPopoverPrice(false);
          }}
        />
      )}
      {prices && !pricesLoading && !isLoadingCalculatePriceRefetch && (
        <Form>
          <BookingPaymentOptionsTable
            prices={prices}
            price_form_detailed={price_form_detailed}
          />

          <div className="t-16 t-m-20">
            <Typography variant="subtitle" size="m">
              Any questions or comments about your trip? Contact the owner using
              the text box below.
            </Typography>
          </div>
          {isVisible && ( // Helps to fix textarea autosize
            <div className="t-16 t-m-20 b-36 b-m-8">
              <FieldController
                name="message"
                control={control}
                render={(renderProps) => (
                  <FieldTextarea
                    resize="none"
                    rows={2}
                    className="fw"
                    id="message"
                    name="message"
                    label="Your message"
                    maxLength={500}
                    helperMessage="(Optional)"
                    {...renderProps}
                  />
                )}
              />
            </div>
          )}
          {!isMinSmallMaxMedium && !isMinHeightMedium && <div className="t-8">{agreementField}</div>}
        </Form>
      )}

      {reserveError && (
        <NotificationPopover
          show={isShowNotificationPopoverReserve}
          status="error"
          text={reserveError}
          onClose={() => {
            setShowNotificationPopoverReserve(false);
          }}
        />
      )}
      {reserveLoading && <SpinnerBack />}
    </StepContainer>
  );
};

BookingPaymentOptions.propTypes = {
  /**
   * Provide modal ref, so tooltips may determine boundaries to fit
   */
  modalRef: PropTypes.object
};

export default BookingPaymentOptions;
