import React from "react";
import { FieldInput } from "components/form/FieldInput";
import { FieldTextarea } from "components/form/FieldTextarea";
import { FieldController } from "components/form/FieldController/FieldController";
import DateOfBirthInput from "components/form/DateOfBirthInput/DateOfBirthInput";
import { RE_DATE_OF_BIRTH_DAY } from "utils/constants/re";
import { FIELD_TYPES } from "constants/fieldTypes";
import { RX_FLOAT, RX_INT } from "utils/constants/RX";
import classes from "./FormElements.module.css";

function dateOfBirthValidation(fieldValue, reset, getValues) {
  const arr = fieldValue.split('/');
  const value = `${arr[2]}-${arr[0]}-${arr[1]}`;

  if (fieldValue.length < 10) {
    reset(getValues(), {});
  }
  if (RE_DATE_OF_BIRTH_DAY.test(value))
  {
    return true;
  }
  else if (!fieldValue) {
    return "Required field";
  }
  else if (!RE_DATE_OF_BIRTH_DAY.test(value)) {
    return "Invalid Date";
  }
}

const getFormElement = (
  type,
  label,
  tag,
  {
    control,
    setError,
    clearErrors,
    reset,
    getValues,
    errors
  }
) => {

  switch (type) {
  case FIELD_TYPES.DATE:
    return (
      <FieldController
        name={tag}
        control={control}
        rules={{
          required: "",
          validate: (v) => {
            return dateOfBirthValidation(v, reset, getValues);
          }
        }}
        render={(renderProps) => (
          <DateOfBirthInput
            name={tag}
            id={tag}
            label={label}
            errors={errors}
            getValues={control.getValues}
            setError={setError}
            clearErrors={clearErrors}
            renderProps={renderProps}
          />
        )}
      />
    );
  case FIELD_TYPES.TEXT:
    return (
      <FieldController
        name={tag}
        control={control}
        rules={{
          required: "Required field",
          validate: (v) => {
            if (v?.length !== 0) {
              return true;
            }
            if (v?.length === 0) return "Required field";
          }
        }}
        render={(renderProps) => (
          <FieldInput
            className="fw"
            id={tag}
            label={label}
            errors={errors}
            {...renderProps}
          />
        )}
      />
    );
  case FIELD_TYPES.STRING:
    return (
      <FieldController
        name={tag}
        control={control}
        rules={{
          required: "Required field",
          validate: (v) => {
            if (v?.length !== 0) {
              return true;
            }
            if (v?.length === 0) return "Required field";
          }
        }}
        render={(renderProps) => (
          <FieldInput
            className="fw"
            id={tag}
            label={label}
            errors={errors}
            {...renderProps}
          />
        )}
      />
    );
  case FIELD_TYPES.INT:
    return (
      <FieldController
        name={tag}
        control={control}
        rules={{
          required: "Required field",
          validate: (v) => {
            if (v?.length !== 0) {
              return true;
            }
            if (v?.length === 0) return "Required field";
          },
          pattern: {
            value: RX_INT,
            message: "Incorrect input"
          }
        }}
        render={(renderProps) => (
          <FieldInput
            className="fw"
            id={tag}
            label={label}
            errors={errors}
            {...renderProps}
          />
        )}
      />
    );
  case FIELD_TYPES.FLOAT:
    return (
      <FieldController
        name={tag}
        control={control}
        rules={{
          required: "Required field",
          validate: (v) => {
            if (v?.length !== 0) {
              return true;
            }
            if (v?.length === 0) return "Required field";
          },
          pattern: {
            value: RX_FLOAT,
            message: "Incorrect input"
          }
        }}
        render={(renderProps) => (
          <FieldInput
            className="fw"
            id={tag}
            label={label}
            errors={errors}
            {...renderProps}
          />
        )}
      />
    );
  case FIELD_TYPES.TEXTAREA:
    return (
      <FieldController
        name={tag}
        control={control}
        rules={{
          required: "Required field",
          validate: (v) => {
            if (v?.length !== 0) {
              return true;
            }
            if (v?.length === 0) return "Required field";
          }
        }}
        render={(renderProps) => (
          <FieldTextarea
            resize="none"
            rows={3}
            className="fw"
            id={tag}
            label={label}
            maxLength={1500}
            errors={errors}
            {...renderProps}
          />
        )}
      />
    );
  default:
    break;
  }
};

const FormElements = (props) => {
  const {
    form_elements,
    form_tags,
    formParams
  } = props;

  return (
    <div>
      <div className={classes.fieldsList}>
        {form_elements.map(({ type, label, tag }) => {
          if (form_tags.includes(tag)){
            return (
              <div className={classes.fieldsListItem}>
                {getFormElement(
                  type,
                  label,
                  tag,
                  formParams
                )};
              </div>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

FormElements.propTypes = {

};

export default FormElements;
