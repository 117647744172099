import React, { memo } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";
import { MessageType, ThreadInfoType, ThreadType } from "../types";
import Typography from "../../Typography";
import classes from "./Message.module.css";

const Message = (props) => {
  const {
    className,
    data,
    threadInfo,
    children,
    isSystem,
    isReceived,
    avatar
  } = props;

  return (
    <div
      className={classnames(
        classes.root,
        className,
        "df",
        {
          [classes.isSystem]: isSystem,
          [classes.isReceived]: isReceived,
          [classes.isSent]: !isReceived,
          "r-16 r-m-32": isReceived,
          "l-16 l-m-32": !isReceived
        }
      )}
    >
      {isReceived && (
        <div className={classnames(classes.avatar, "df fcc mr-12")}>
          <img src={avatar || threadInfo?.avatar} alt="" />
        </div>
      )}
      <div
        className={classnames(
          classes.text,
          "t-4 r-16 b-8 l-16"
        )}
      >
        {children || (
          <Typography variant="body" size="l">
            {data.body}
          </Typography>
        )}
        <Typography className={classes.date} variant="caption">
          {moment(data.timestamp).format("hh:mm a")}
        </Typography>
      </div>
    </div>
  );
};

Message.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isSystem: PropTypes.bool,
  isReceived: PropTypes.bool,
  data: MessageType.isRequired,
  thread: ThreadType.isRequired,
  threadInfo: ThreadInfoType,
  avatar: PropTypes.string
};

export default memo(Message);
