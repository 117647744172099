import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { last } from 'lodash';
import Checkbox from "components/form/Checkbox";
import classnames from "classnames";
import classes from "./ChoosePhotosListItem.module.css";

const getSmallestPhotoUrl = (srcset) => last(srcset)?.url;

export const ChoosePhotosListItem = (props) => {
  const {
    onChangePhoto = () => {},
    image: { srcset } = {},
    is_used_on_rentals,
    id
  } = props;

  const [checked, setChecked] = useState(false);
  const imgSrc = srcset && getSmallestPhotoUrl(srcset);

  const handleOnChange = useCallback((event) => {
    setChecked(event.target.checked);
    onChangePhoto(id, event.target.checked);
  }, [id, onChangePhoto]);

  const handleOnClick = useCallback(() => {
    setChecked(!checked);
    onChangePhoto(id, !checked);
  }, [checked, id, onChangePhoto]);

  return (
    <div className={classes.container}>
      <div
        className={
          classnames(
            classes.content,
            checked && classes.checked,
            is_used_on_rentals && classes.disabled
          )}
      >
        <img
          src={imgSrc}
          alt=""
          className={
            classnames(
              classes.img,
              checked && classes.checked,
              is_used_on_rentals && classes.disabled
            )}
          onClick={handleOnClick}
        />
        <Checkbox
          checked={is_used_on_rentals || checked}
          className={
            classnames(
              classes.checkbox,
              checked && classes.checked,
              is_used_on_rentals && classes.disabled
            )}
          pseudoCheckClassName={classes.checkboxSquare}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

ChoosePhotosListItem.propTypes = {
  image: PropTypes.shape({
    srcset: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string
      })
    )
  }),
  id: PropTypes.string,
  is_used_on_rentals: PropTypes.bool,
  onChangePhoto: PropTypes.func
};
