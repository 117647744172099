import { gql } from "@apollo/client";

export const goodSamMembershipOrder = gql`
  mutation goodSamMembershipOrder($offer_id: String!) {
    good_sam_membership_order(offer_id: $offer_id) {
      result {
        success
        errors {
          code
          field
          message
        }
      }
      user {
        id
        good_sam_membership_validation {
          key
          value
        }
      }
    }
  }
`;
