import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import MenuPipe from "components/MenuPipe/MenuPipe";
import { setClasses } from "components/TableInfo/utils";
import classes from "./ModalDetails.module.css";

function getModalDetailsContent(children, tabId, tabIndex) {
  if (children && typeof children === "function" ) return children(tabId, tabIndex);
  if (children && typeof children !== "function" ) return children;
  return null;
}

const ModalDetails = (props) => {
  const {
    show,
    menuData = [],
    title,
    onClose,
    modalClassnames = [],
    children
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [tabId, setTabId] = useState(menuData?.[0]?.id);
  const hasMenu = menuData?.length !== 0;
  return (
    <Modal
      show={show}
      modalWrapClassnames={classes.modalContainer}
      modalClassnames={`${classes.modal} ${setClasses([...modalClassnames], classes)}`}

      modalCrossButtonClassname={classes.crossButton}
      onClose={onClose}
    >
      <div className={`${classes.container}`}>

        <header
          className={`${classes.header} ${hasMenu ? classes.hasMenu : ''} container t-16`}
        >
          <Typography variant="subtitle" size="m" weight="bold">
            {title}
          </Typography>

          { hasMenu && (
            <div className={`${classes.menu} mt-20`}>
              <MenuPipe
                data={menuData}
                onClickItem={(tabId, tabIndex) => {
                  setTabIndex(tabIndex);
                  setTabId(tabId);
                }}
              />
            </div>
          )}
        </header>

        <main className={`${classes.content} t-20`}>
          <div className="container">
            {getModalDetailsContent(children, tabId, tabIndex)}
          </div>
        </main>
      </div>

    </Modal>
  );

};

ModalDetails.propTypes = {
  show: PropTypes.bool

};

export default ModalDetails;
