const currentYear = new Date().getFullYear();
const firstDateMonth = /0|1/;
const datesMonth = /01|02|03|04|05|06|07|08|09|10|11|12/;
const firstDay = /0|1|2|3/;
const firstDayFebruary = /0|1|2/;
const daysMonth = /01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31/;
const daysShortMonth = /01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30/;
const daysMonthFebruary = /01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29/;
const shortMonths = /02|04|06|09|11/;
const february = /02/;
const oneDayYear = /1|2/;
const twoDaysYear = /19|20/;
const threeDaysYear = /190|191|192|193|194|195|196|197|198|199|200|201|202/;
const minYear = 1900;
const maxYear = currentYear;

const getFirstDayMonth = (value) => value.substring(0, 1);
const getMonth = (value) => value.substring(0, 2);

const getFirstDay = (value) => value.substring(3, 4);
const getDay = (value) => value.substring(3, 5);

const getFirstDayYear = (value) => value.substring(6, 7);
const getTwoDaysYear = (value) => value.substring(6, 8);
const getThreeDaysYear = (value) => value.substring(6, 9);
const getYear = (value) => value.substring(6, 10);

const isValidDays = (value, re) => value.match(re) !== null;
const setErrors = (fieldName, setError) => setError(fieldName, { message: "Invalid Date" });

/* test month */
function testFirstDayMonth(value) {
  return isValidDays(getFirstDayMonth(value), firstDateMonth);
}

function testMonth(value) {
  const isValidFirstDayMonth = testFirstDayMonth(value);
  const isValidMonth = isValidDays(getMonth(value), datesMonth);

  return isValidFirstDayMonth && isValidMonth;
}

/* test day of month */
function testFirstDay(value) {
  const isValidMonth = testMonth(value);
  let isValidFirstDay = isValidDays(getFirstDay(value), firstDay);

  if (isValidDays(getMonth(value), february)) {
    isValidFirstDay = isValidDays(getFirstDay(value), firstDayFebruary);
  }

  return isValidMonth && isValidFirstDay;
}

function testDay(value) {
  const isValidFirstDayMonth = testFirstDay(value);

  let isValidDay = isValidDays(getDay(value), daysMonth);

  if (isValidDays(getMonth(value), shortMonths)) {
    isValidDay = isValidDays(getDay(value), daysShortMonth);
  }
  if (isValidDays(getMonth(value), february)) {
    isValidDay = isValidDays(getDay(value), daysMonthFebruary);
  }

  return isValidFirstDayMonth && isValidDay;
}

/* test day of year */
function testFirstDayYear(value) {
  const isValidDay = testDay(value);
  const isValidFirstDayYear = isValidDays(getFirstDayYear(value), oneDayYear);

  return isValidDay && isValidFirstDayYear;
}

function testTwoDayYear(value) {
  const isValidFirstDayYear = testFirstDayYear(value);
  const isValidTwoDayYear = isValidDays(getTwoDaysYear(value), twoDaysYear);

  return isValidFirstDayYear && isValidTwoDayYear;
}

function testThreeDaysYear(value) {
  const isValidTwoDayYear = testTwoDayYear(value);
  const isValidThreeDaysYear = isValidDays(getThreeDaysYear(value), threeDaysYear);

  return isValidTwoDayYear && isValidThreeDaysYear;
}

function testYear(value) {
  const isValidThreeDaysYear = testThreeDaysYear(value);
  const year = Number(getYear(value));
  let isValidYear = true;

  if (year < minYear || year > maxYear) isValidYear = false;

  return isValidThreeDaysYear && isValidYear;
}

export function validateDateOfBirthMask(value, setError, clearErrors, fieldName) {
  if (value.length === 0) {
    clearErrors(fieldName);
    return true;
  } else if (value.length === 1) {
    if (testFirstDayMonth(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";
    }
  } else if (value.length === 2 || value.length === 3) {
    if (testMonth(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";
    }
  } else if (value.length === 4) {
    if (testFirstDay(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";
    }
  } else if (value.length === 5 || value.length === 6) {
    if (testDay(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";
    }
  } else if (value.length === 7) {
    if (testFirstDayYear(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";
    }
  } else if (value.length === 8) {
    if (testTwoDayYear(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";
    }
  } else if (value.length === 9) {
    if (testThreeDaysYear(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";
    }
  } else if (value.length === 10) {
    if (testYear(value)) {
      clearErrors(fieldName);
      return true;
    } else {
      setErrors(fieldName, setError);
      return "Invalid Date";

    }
  }
}
