import orderBy from "lodash/orderBy";

const SORT_IDS = {
  distance: "distance",
  price: "price",
  length: "length"
};

const SORT_TYPES = {
  asc: "asc",
  desc: "desc"
};

const prop = {
  'distance_asc': 'ds',
  'price_desc': 'pr',
  'price_asc': 'pr',
  'length_asc': 'le',
  'length_desc': 'le'
};

const ascSortId = (id) => `${id}_${SORT_TYPES.asc}`;
const descSortId = (id) => `${id}_${SORT_TYPES.desc}`;

const sortOptions = [
  { id: SORT_IDS.distance, type: SORT_TYPES.asc, text: "Distance" },
  { id: SORT_IDS.price, type: SORT_TYPES.desc, text: "Price: High to low", itemProp: "pr" },
  { id: SORT_IDS.price, type: SORT_TYPES.asc, text: "Price: Low to high", itemProp: "pr" },
  { id: SORT_IDS.length, type: SORT_TYPES.asc, text: "Length: Short to long", itemProp: "le" },
  { id: SORT_IDS.length, type: SORT_TYPES.desc, text: "Length: Long to short", itemProp: "le" }
].map(i => ({
  ...i,
  value: i.type === SORT_TYPES.asc ? ascSortId(i.id) : descSortId(i.id)
}));

export const sort = (list, key, direction) => {
  return orderBy(list, [key], direction);
};

export const SORT = {
  default: sortOptions[0].value,
  options: sortOptions,
  getItemProp: (value) => prop[value]
};
