import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { FILTERS } from "components/Widget/filters";
import MobileFilterBlock from "./MobileFilterBlock";
import classes from "./MobileFilterCalendar.module.css";
import classesDarkTheme from "./MobileFilterCalendarDarkTheme.module.css";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { SmallDayPicker } from "components/SmallDayPicker/SmallDayPicker";
import moment from "moment";

const initialMonth = new Date();
initialMonth.setDate(initialMonth.getDate());

const MobileFilterCalendar = () => {
  const { filtersWatch, handleSetActiveFilters, theme, loadSearch, ownerDirectory } = useWidgetContext();
  const [calendarKey, setCalendarKey] = useState(1);
  const { date: filterDate } = filtersWatch();
  const { fullDeparture } = filterDate;
  const [currentMounth, setCurrentMounth] = useState(fullDeparture || initialMonth);

  useEffect(() => {
    setCalendarKey(key => key + 1);
  }, [theme]);

  useEffect(() => {
    if (fullDeparture) {
      setCurrentMounth(fullDeparture);
    } else {
      setCurrentMounth(initialMonth);
    }
  }, [fullDeparture] );

  const hanldeAfterClearFilter = useCallback(() => {
    loadSearch({
      variables: {
        directory: ownerDirectory
      }
    });
  }, [loadSearch, ownerDirectory]);

  return (
    <MobileFilterBlock
      id={FILTERS.ids.date}
      hanldeAfterClearFilter={hanldeAfterClearFilter}
      getLabelFromValue={() => {
        if (!filterDate.departure || !filterDate.return) {
          return false;
        }
        return `${filterDate.departure} - ${filterDate.return}`;
      }}
      renderForm={({ value, onChange }) => {
        return (
          <div className="mt-2 ml-12">
            <SmallDayPicker
              key={calendarKey}
              numberOfMonths={1}
              initialMonth={currentMounth}
              value={{
                from: value.fullDeparture,
                to: value.fullReturn
              }}
              onChangeDate={(state) => {
                const departure_date = moment(state?.from).format("DD-MM-YYYY");
                const return_date = moment(state?.to).format("DD-MM-YYYY");

                onChange({
                  departure: departure_date,
                  return: return_date,
                  fullDeparture: state?.from,
                  fullReturn: state?.to
                });

                handleSetActiveFilters();

                loadSearch({
                  variables: {
                    directory: ownerDirectory,
                    departure_date,
                    return_date
                  }
                });
              }}
              externalClassNames={theme === 'dark' ? classesDarkTheme : classes}
            />
          </div>
        );
      }}
    />
  );
};

MobileFilterCalendar.propTypes = {
};

export default MobileFilterCalendar;
