import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import PasswordResetPage from "./PasswordResetPage";
import { extractServerErrors, extractSuccess, extractServerErrorMessage } from "../../utils/extractErrors";
import { passwordRecoveryMutation } from "./mutations/passwordRecoveryMutation";
import { SpinnerBack } from "../../components/Spinner/Spinner";
import { trimSpaces } from "../../utils/trimSpaces";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const extractRecoveryErrors = extractServerErrors("password_recovery");
const extractRecoverySuccess = extractSuccess("password_recovery");
const extractRecoveryErrorMessage = extractServerErrorMessage("password_recovery");

const trimSpacesPasswordReset = trimSpaces(["email"]);

export const PasswordResetContainer = () => {
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [action, result] = useMutation(passwordRecoveryMutation);

  const handleSubmit = useCallback(
    async (variables) => {
      const response = await action({
        variables: trimSpacesPasswordReset(variables)
      });

      if (extractRecoverySuccess(response)) {
        setSuccess(true);
      } else if (extractRecoveryErrors(response)) {
        setError(extractRecoveryErrorMessage(response));
      }
    },
    [action, setSuccess]
  );

  return (
    <>
      <PasswordResetPage
        isSuccess={isSuccess}
        loading={result.loading}
        onPasswordRestoreSubmit={handleSubmit}
      />
      {result.loading && <SpinnerBack />}
      {error?.length !== 0 && (
        <NotificationPopover
          show
          status="error"
          text={error}
          onClose={() => {
            setError('');
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}
    </>
  );
};
