import FieldOptionalInputs from "./FieldOptionalInputs";
import React from "react";
import { FieldInput } from "../../../form/FieldInput";
import { FieldArray } from "../../../form/FieldArray";
import Select from "../../../form/Select";
import Button from "../../../Button";
import { trashIcon } from "../../../Icon";
import { RX_FLOAT, RX_INT } from "../../../../utils/constants/RX";
import { isSafeInt } from "../../../../utils/isSafeInt";
import classnames from "classnames";
import { checkFieldIsNotApplicable } from "../checkFieldIsNotApplicable";
import { FieldController } from "../../../form/FieldController/FieldController";
import { FieldOptionalInputsTypeTwo } from "./FieldOptionalInputsTypeTwo";
import { LabelContent } from "components/ContentSection/LabelContent";
import { LabelsContent } from "components/ContentSection/LabelsContent";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";

const priceData = [
  {
    text: "Per night",
    value: "1"
  },
  {
    text: "Flat",
    value: "2"
  }
];

export const OtherFeesFormContent = ({
  control,
  watch,
  setValue,
  errors,
  triggerFormValidate,
  addonsDefaultValues,
  notApplicableOptions
}) => (
  <>
    <div className="row t-16 t-m-20 b-16 b-m-20">
      <div className="col">
        <div>
          <Text
            id="mileage_rates_section_title"
            section="otherFeesAddonsSectionContent"
            component="h6"
            variant="subtitle"
          />
          <FieldController
            name="limited_daily_mileage"
            control={control}
            disabled={checkFieldIsNotApplicable(
              "limited_daily_mileage",
              notApplicableOptions
            )}
            render={({
              value: isAvailable,
              onChange: setAvailable,
              disabled
            }) => (
              <LabelsContent
                section="otherFeesAddonsSectionContent"
                ids={[
                  "unlimited_miles",
                  "specific_mileage_rates",
                  "miles_included",
                  "mileage_overage_rate"
                ]}
              >
                {(labels) => (
                  <FieldOptionalInputs
                    disabled={disabled}
                    id="mileagerates"
                    nameFirst="miles_included"
                    nameSecond="mileage_overage_rate"
                    labelFirst={labels.miles_included}
                    labelSecond={labels.mileage_overage_rate}
                    unitsFirst={
                      <Text
                        id="miles_included_units"
                        section="otherFeesAddonsSectionContent"
                      />
                    }
                    unitsSecond={
                      <Text
                        id="mileage_overage_rate_description"
                        section="otherFeesAddonsSectionContent"
                      />
                    }
                    availableMessage={labels.specific_mileage_rates}
                    unavailableMessage={labels.unlimited_miles}
                    isAvailable={isAvailable}
                    setAvailable={setAvailable}
                    control={control}
                    errors={errors}
                    validation={{
                      miles_included: {
                        required: "Miles included is required",
                        validate: (v) => {
                          if (!isSafeInt(parseInt(v, 10)))
                            return "Incorrect input";
                        },
                        pattern: {
                          value: RX_INT,
                          message: "Incorrect input"
                        }
                      },
                      mileage_overage_rate: {
                        required: "Mileage Overage Rate is required",
                        validate: (v) => {
                          if (!isSafeInt(parseInt(v, 10) * 100))
                            return "Incorrect input";
                        },
                        pattern: {
                          value: RX_FLOAT,
                          message: "Incorrect input"
                        }
                      }
                    }}
                  />
                )}
              </LabelsContent>
            )}
          />
        </div>
      </div>
    </div>
    <div className="row t-16 t-m-20 b-16 b-m-20">
      <div className="col">
        <div>
          <Text
            id="generator_rates_section_title"
            section="otherFeesAddonsSectionContent"
            component="h6"
            variant="subtitle"
          />
          <LabelsContent
            section="otherFeesAddonsSectionContent"
            ids={[
              "unlimited_hours",
              "specific_hour_rates",
              "hours_included",
              "generator_overage_rate"
            ]}
          >
            {(labels) => (
              <FieldOptionalInputsTypeTwo
                nameNothing="generator_not_applicable"
                nameOptional="limited_generator_hours"
                nameInputFirst="generator_hours_included"
                nameInputSecond="generator_overage_rate"
                labelNothing="Not applicable"
                labelShow={labels.specific_hour_rates}
                labelHide={labels.unlimited_hours}
                labelInputFirst={labels.hours_included}
                labelInputSecond={labels.generator_overage_rate}
                unitsInputSecond={
                  <Text
                    id="generator_overage_rate_description"
                    section="otherFeesAddonsSectionContent"
                  />
                }
                unitsInputFirst={
                  <Text
                    id="hours_included_units"
                    section="otherFeesAddonsSectionContent"
                  />
                }
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                validation={{
                  generator_hours_included: {
                    required: "Generator Hours Included is required",
                    validate: (v) => {
                      if (Number(v) < 0 || Number(v) > 24) return "Can't be more than 24";
                    },
                    pattern: {
                      value: RX_INT,
                      message: "Incorrect input"
                    }
                  },
                  generator_overage_rate: {
                    required: "Generator Overage Rate is required",
                    validate: (v) => {
                      if (!isSafeInt(parseInt(v, 10) * 100))
                        return "Incorrect input";
                    },
                    pattern: {
                      value: RX_FLOAT,
                      message: "Incorrect input"
                    }
                  }
                }}
              />
            )}
          </LabelsContent>
        </div>
      </div>
    </div>
    <div className="row t-16 t-m-20 b-16 b-m-0">
      <div className="col">
        <div className="row">
          <div className="col">
            <Text
              id="suggested_fees_section_title"
              section="otherFeesAddonsSectionContent"
              component="h6"
              variant="subtitle"
            />
          </div>
        </div>
        <div className="row t-16 t-m-20 b-m-20">
          <div className="col-9 col-s-4 t-16 t-m-20 b-16 b-m-20">
            <FieldController
              name="cleaning_fee"
              control={control}
              rules={{
                validate: (v) => {
                  if (v && !isSafeInt(parseInt(v, 10) * 100))
                    return "Incorrect input";
                },
                pattern: {
                  value: RX_FLOAT,
                  message: "Incorrect input"
                }
              }}
              render={(renderProps) => (
                <LabelContent
                  section="otherFeesAddonsSectionContent"
                  id="cleaning_fee"
                >
                  {(label) => (
                    <FieldInput
                      className="fw"
                      id="cleaningfee"
                      label={label}
                      errors={errors}
                      helperMessage="(Optional)"
                      {...renderProps}
                    />
                  )}
                </LabelContent>
              )}
            />
          </div>
          <div className="col-9 col-s-4 t-16 t-m-20 b-16 b-m-20">
            <FieldController
              name="disposal_fee"
              control={control}
              rules={{
                validate: (v) => {
                  if (v && !isSafeInt(parseInt(v, 10) * 100))
                    return "Incorrect input";
                },
                pattern: {
                  value: RX_FLOAT,
                  message: "Incorrect input"
                }
              }}
              render={(renderProps) => (
                <LabelContent
                  section="otherFeesAddonsSectionContent"
                  id="disposal_fee"
                >
                  {(label) => (
                    <FieldInput
                      className="fw"
                      id="disposalfee"
                      label={label}
                      errors={errors}
                      helperMessage="(Optional)"
                      {...renderProps}
                    />
                  )}
                </LabelContent>
              )}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="t-16 t-m-20 b-16 b-m-20">
      <div className="row">
        <div className="col">
          <Text
            id="add_ons_section_title"
            section="otherFeesAddonsSectionContent"
            component="h6"
            variant="subtitle"
          />
        </div>
      </div>
      <div className="t-16 t-m-20">
        <FieldArray
          control={control}
          trigger={triggerFormValidate}
          name="add_ons"
          defaultValues={addonsDefaultValues}
          errors={errors}
          renderRow={(rowProps) => (
            <>
              <div className="row-dashed-devider no-row-dashed-devider-m" />
              <div className="row">
                <FieldController
                  control={control}
                  name={`${rowProps.fieldName}[${rowProps.rowIndex}].name`}
                  defaultValue={rowProps.name}
                  rules={{ required: "Add-on is required" }}
                  render={(renderProps) => (
                    <LabelContent
                      section="otherFeesAddonsSectionContent"
                      id="add_on_name"
                    >
                      {(label) => (
                        <div className="col-12 col-s t-16 t-m-20 b-16 b-m-20">
                          <FieldInput
                            {...renderProps}
                            onChange={(e) => {
                              renderProps.onChange(e.target.value);
                              triggerFormValidate(
                                `${rowProps.fieldName}[${rowProps.rowIndex}].name`
                              );
                            }}
                            className="fw"
                            id={`name-${rowProps.rowIndex}`}
                            label={label}
                            errors={errors}
                          />
                        </div>
                      )}
                    </LabelContent>
                  )}
                />
                <div className="col-auto t-16 t-m-20 b-16 b-m-20">
                  <div className="row no-gutters f-nowrap">
                    <FieldController
                      control={control}
                      name={`${rowProps.fieldName}[${rowProps.rowIndex}].price`}
                      defaultValue={rowProps.price}
                      rules={{
                        required: "Price is required",
                        validate: (v) => {
                          if (!isSafeInt(parseInt(v, 10) * 100))
                            return "Incorrect input";
                        },
                        pattern: {
                          value: RX_FLOAT,
                          message: "Incorrect input"
                        }
                      }}
                      render={(renderProps) => (
                        <LabelContent
                          section="otherFeesAddonsSectionContent"
                          id="add_on_price"
                        >
                          {(label) => (
                            <FieldInput
                              {...renderProps}
                              onChange={(e) => {
                                renderProps.onChange(e.target.value);
                                triggerFormValidate(
                                  `${rowProps.fieldName}[${rowProps.rowIndex}].price`
                                );
                              }}
                              className="fw field-input--no-right-radius field-input--no-right-border field-input--addon"
                              id={`price-${rowProps.rowIndex}`}
                              label={label}
                              errors={errors}
                            />
                          )}
                        </LabelContent>
                      )}
                    />
                    <FieldController
                      control={control}
                      name={`${rowProps.fieldName}[${rowProps.rowIndex}].price_type`}
                      defaultValue={rowProps.price_type}
                      rules={{ required: "Price Type is required" }}
                      render={(renderProps) => (
                        <Select
                          {...renderProps}
                          onChange={(v) => {
                            renderProps.onChange(v);
                            triggerFormValidate(
                              `${rowProps.fieldName}[${rowProps.rowIndex}].price_type`
                            );
                          }}
                          className="field-input--no-left-radius field-input--addonselect"
                          id={`price_type-${rowProps.rowIndex}`}
                          label="Price type"
                          options={priceData}
                          errors={errors}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-auto t-16 t-m-20 b-16 b-m-20">
                  <div className="btn-group">
                    <Button
                      secondary
                      icon={trashIcon}
                      onClick={rowProps.handleRemove}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          render={({ handleAdd, rowsCount }) => (
            <div
              className={classnames(
                "btn-group",
                rowsCount > 0 && "t-32 t-m-40"
              )}
            >
              <ButtonContent
                id="add_add_on_button"
                section="otherFeesAddonsSectionContent"
                onClick={handleAdd}
                secondary
              />
            </div>
          )}
        />
      </div>
    </div>
  </>
);
