import { map, path, pathOr, pick, pipe, join, filter, or, is } from "ramda";

export function extractServerErrorsCode(mutationName) {
  return path(["data", mutationName, "result", "errors", 0, 'code']);
}

export function extractServerErrorsTitle(mutationName) {
  return path(["data", mutationName, "result", "errors", 0, 'title']);
}

export function extractServerErrors(mutationName) {
  return pipe(
    pathOr([], ["data", mutationName, "result", "errors"]),
    map(pick(["field", "message", 'code']))
  );
}

export function extractServerErrorMessage(mutationName) {
  return pipe(
    extractServerErrors(mutationName),
    filter((error) => error?.message?.length > 0),
    map((error) => `${error?.message || ""}`),
    join("\n")
  );
}

export function extractSuccess(mutationName) {
  return path(["data", mutationName, "result", "success"]);
}

export function extractErrors(mutationName) {
  return path(["data", mutationName, "result", "errors"]);
}

export function extractQuerySuccess(queryName) {
  return pipe(
    path(["data", queryName]),
    or(is(Object), is(Array))
  );
}

export function extractSuccessWithoutResultParameter(mutationName) {
  return path(["data", mutationName, "success"]);
}

export function extractSuccessWithoutDataParameter(mutationName) {
  return path([mutationName, "result", "success"]);
}

export function getErrorByCode(result, code) {
  return result?.errors?.find((i) => i?.code === code);
}

export function extractQueryData(queryName) {
  return path(["data", queryName]);
}

export function getErrorsAndSuccess(name) {
  return {
    isSuccess: extractSuccess(name),
    isQuerySuccess: extractQuerySuccess(name),
    isErrors: extractErrors(name),
    errorCode: extractServerErrorsCode(name),
    errorMessage: extractServerErrorMessage(name),
    errorTitle: extractServerErrorsTitle(name),
    errors: extractServerErrors(name),
    getQueryData: extractQueryData(name)
  };
}
