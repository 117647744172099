/* eslint-disable indent */
import React, { forwardRef, useEffect, useState, useCallback } from "react";
import { useGoogleMaps } from "components/GoogleMapsProvider";
import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import style from "./style.module.css";
import { convertMilesToMeters } from "./helpers";
import { USA_CENTER } from "utils/map";
import locationOutlineIcon from "./icons/new-location-outline.svg";

const basicCircleOptions = {
  strokeColor: "#4D6539",
  strokeOpacity: 0.3,
  strokeWeight: 2,
  fillColor: "#66864B",
  fillOpacity: 0.15
};


const DEFAULT_LOCATION_ZOOM = 4;

const extractInitialLocation = (v) => {
  if (v.latitude !== "" && v.longitude !== "") {
    return {
      lat: +v.latitude,
      lng: +v.longitude
    };
  } else {
    return undefined;
  }
};

const circlePoints = (lat, lng, r) => {
  const d = r * 360 / (2 * Math.PI * 6371008);

  return [
    { position: { lat: lat - d, lng: lng } },
    { position: { lat: lat + d, lng: lng } },
    { position: { lat: lat, lng: lng - d } },
    { position: { lat: lat, lng: lng + d } }
  ];
};

export const MapContent = forwardRef((props) => {
  const { isLoaded } = useGoogleMaps();
  const {
    showGeoObjects = true,
    value,
    initialLocation,
    paidDelivery,
    setMapLoaded,
    specificDeliveryLocations,
    showAllLocations,
    isSelectedSpecificLocation,
    hasNotDeliveryRadius,
    isDefaultLocationOnly
  } = props;

  const locationMarkers = specificDeliveryLocations.map(loc => (
    <>
      <Marker
        key={loc.location.full_address}
        icon={{ url: locationOutlineIcon }}
        position={{
          lat: Number(loc.location.latitude),
          lng: Number(loc.location.longitude)
        }}
      />
    </>
  ));
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState({});
  const [initialLocationData, setInitialLocationData] = useState({});

  const centerInitialLocationData = initialLocationData.coords || USA_CENTER;

  const circleOptions = {
    ...basicCircleOptions,
    center: centerInitialLocationData,
    radius: convertMilesToMeters(1)
  };

  const circleOptionsFree = {
    ...basicCircleOptions,
    center: centerInitialLocationData,
    radius: convertMilesToMeters(paidDelivery)
  };

  useEffect(() => {
    if (value) {
      setLocation({
        full_address: value.full_address,
        coords: extractInitialLocation(value)
      });
    } else {
      setLocation({});
    }
  }, [value]);

  useEffect(() => {
    if (initialLocation) {
      setInitialLocationData({
        full_address: initialLocation.full_address,
        coords: extractInitialLocation(initialLocation)
      });
    }
  }, [initialLocation]);

  useEffect(() => {
    if (isLoaded) setMapLoaded(isLoaded);
  }, [isLoaded, setMapLoaded]);

  const handleOnLoad = useCallback((m) => {
    let positions = [];
    if (initialLocationData.coords !== undefined && !isSelectedSpecificLocation && !hasNotDeliveryRadius) {
      const radius = convertMilesToMeters(paidDelivery);
      positions = circlePoints(
        initialLocationData.coords.lat,
        initialLocationData.coords.lng,
        radius
      );
    }
    if (location.coords !== undefined) {
      positions = [
        ...positions,
        {
          position: {
            lat: Number(location.coords.lat),
            lng: Number(location.coords.lng)
          }
        }
      ];
    }
    if (specificDeliveryLocations.length > 0 && showAllLocations) {
      const locs = specificDeliveryLocations.map((loc) => ({
        position: {
          lat: Number(loc.location.latitude),
          lng: Number(loc.location.longitude)
        }
      }));
      positions = [...positions, ...locs];
    }
    if (positions.length !== 0) {
      const bounds = new window.google.maps.LatLngBounds();
      positions.forEach(({ position }) => bounds.extend(position));
      m.fitBounds(bounds);

      if (isSelectedSpecificLocation) {
        setTimeout(() => {
          m.setZoom(13);
        }, 100);
      }

      if (isDefaultLocationOnly) {
        setTimeout(() => {
          m.setZoom(13);
        }, 100);
      }

    } else {
      m.setCenter(USA_CENTER);
      m.setZoom(4);
    }

    if (!map) setMap(m);
  }, [hasNotDeliveryRadius, initialLocationData.coords, isDefaultLocationOnly, isSelectedSpecificLocation, showAllLocations, location.coords, map, paidDelivery, specificDeliveryLocations]);

  useEffect(() => {
    if (map) {
      handleOnLoad(map);
    }
  }, [handleOnLoad, map, initialLocationData, location]);

  return isLoaded ? (
    <>
      <div className={`${style.mapContainer}`}>
        <div className={`${style.mapContainerInner}`}>
          <GoogleMap
            defaultZoom={DEFAULT_LOCATION_ZOOM}
            mapContainerClassName={style.map}
            options={{
              disableDefaultUI: true
            }}
            onLoad={handleOnLoad}
          >
            {initialLocationData.coords && (
              <>
                {showGeoObjects && (
                  <>
                    <Circle options={circleOptionsFree} />
                  </>
                )}
                {!showGeoObjects && <Circle options={circleOptions} />}
              </>
            )}
           <>
            { showAllLocations && locationMarkers}
            { !showAllLocations && location?.coords?.lat && (
              <>
                <Marker
                  icon={{ url: locationOutlineIcon }}
                  position={{
                    lat: location.coords.lat,
                    lng: location.coords.lng
                  }}
                />
              </>
            )}
          </>
          </GoogleMap>
        </div>
      </div>
    </>
  ) : (
    "Loading..."
  );
});
