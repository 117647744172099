import React, { useState, useCallback } from "react";
import Typography from "components/Typography";
import Button from "components/Button";
import Rating from "components/Rating";
import ReviewPhotosList from "./ReviewPhotosList";
import EditReview from "./EditReview";
import ModalCarousel from "components/ModalCarousel/ModalCarousel";
import RequestCollapse from "../RequestHistory/RequestCollapse";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { convertPhotosData } from "utils/convertPhotosData";
import classnames from "classnames";

import style from "./style.module.css";

const CompletedReview = ({ data, isOpponent }) => {

  const [showModalGallery, setShowModalGallery] = useState(false);
  const [initialSlide, setInitialSlide] = useState(1);
  const isXxSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const isSmallDevice = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.medium });

  const openModalGallery = (slide, isLast) => {
    if (isLast) {
      if (isXxSmallDevice) setInitialSlide(3);
      if (isSmallDevice) setInitialSlide(4);
      else setInitialSlide(5);
    } else setInitialSlide(slide);
    setShowModalGallery(true);
  };

  const closeModalGallery = () => {
    setShowModalGallery(false);
  };

  const photos = convertPhotosData(data?.photos);

  return (
    <div className={classnames(
      "b-m-20 mb-m-24 ",
      !isOpponent && 't-m-20 mt-xl-0 mb-xl-0 mb-l-0',
      isOpponent && 'mt-m-20 t-m-0 mt-xl-20'
    )}>
      <div className={classnames(style.reviewContent, "fss")}>
        <div
          className={classnames(
            style.reviewContent__avatar,
            "col-2 col-l-1 col-xl-auto t-20 b-20 r-0 l-0 mr-xxl-32"
          )}
        >
          <div className={style.reviewContent__imgContainer}>
            <img
              className={style.reviewContent__img}
              src={data.avatarUrl}
              alt={data.name}
            />
          </div>
        </div>

        <div
          className={classnames(
            style.review__contentWrap,
            "review__content-wrap col-xl-auto r-0 l-0 l-l-2 l-xl-24 l-xxl-0"
          )}>
          <div
            className={classnames(
              isOpponent && style.review__contentHeaderOpponent,
              "review__content-header t-0 fsc"
            )}>
            <Typography variant="subtitle" size="s" weight="bold">
              {data.name}
            </Typography>
            <div
              className={classnames(
                style.reviewContent__rating,
                isOpponent && 'mt-4',
                !isOpponent && ' mt-m-4 mt-l-0',
                "r-16 l-16 mr-16 ml-16 r-l-32 l-l-32 mr-l-32 ml-l-32"
              )}
            >
              <Rating value={data.rating} maxValue={5} />
            </div>
            <div
              className={classnames(
                isOpponent && 'mt-0',
                !isOpponent && ' mt-m-4 mt-l-0',
              )}>
              <Typography
                variant="body"
                size="l"
                className={style.myReviews__mutedText}
              >
                {data.date}
              </Typography>
            </div>
          </div>
          <div className="review__content-text t-0 b-8 b-m-0 b-l-16">
            <Typography variant="body" size="l" className={style.testimonial}>
              {data.testimonial}
            </Typography>
          </div>
        </div>
      </div>
      {data.photos.length !== 0 && (
        <div
          className={classnames(
            "review__content-photos row mb-m-48 mb-l-32 mt-m-32 mt-l-16 mt-xl-16 l-m-8 l-l-12 l-xl-16 t-l-4 t-xl-0 mr-neg-m-16 mr-neg-l-24 mr-xl-0",
            style.review__contentPhotos
          )}>
          <ReviewPhotosList
            photoArray={data.photos}
            openModalGallery={openModalGallery}
            setInitialSlide={setInitialSlide}
          />
          <ModalCarousel
            showModal={showModalGallery}
            photos={photos}
            onClose={closeModalGallery}
            initialSlide={initialSlide}
          />
        </div>
      )}
    </div>
  );
};

const CompletedReviewMobile = ({ actor, data, modalFunction }) => {

  const [showModalGallery, setShowModalGallery] = useState(false);
  const [initialSlide, setInitialSlide] = useState(1);

  const openModalGallery = (slide, isLast) => {
    if (isLast) setInitialSlide(3);
    else setInitialSlide(slide);
    setShowModalGallery(true);
  };

  const closeModalGallery = () => {
    setShowModalGallery(false);
  };
  const photos = convertPhotosData(data?.photos);
  return (
    <div className={classnames(style.reviewContent, style.reviewContent_mobile, "t-20 fd fdc b-8 b-m-0")}>
      <div className="review-content-small__header fsc t-12 b-8">
        <div className={classnames(style.reviewContent__avatar, "")}>
          <div className={style.reviewContent__imgContainer}>
            <img
              className={style.reviewContent__img}
              src={data.avatarUrl}
              alt={data.name}
            />
          </div>
        </div>
        <div className="fd fdc l-16">
          <Typography
            variant="body"
            size="l"
            weight="bold"
            className={style.reviewContent_small__headerName}
          >
            {data.name}
          </Typography>
          <div className={classnames(style.reviewContent__rating_small, "")}>
            <Rating value={data.rating} maxValue={5} />
          </div>
        </div>
      </div>
      <div className="review-content-small__content fd fdc">
        <div className="b-4 t-2 t-m-4 mt-4 mt-m-0">
          <Typography variant="body" size="l" className={style.testimonial}>
            {data.testimonial}
          </Typography>
        </div>
        {data.photos.length !== 0 && (
          <div className="review__content-photos mt-16 mt-m-0 t-8 b-8 t-m-32 b-m-32 mr-neg-16 mr-neg-m-0">
            <ReviewPhotosList photoArray={data.photos} openModalGallery={openModalGallery}/>
            <ModalCarousel
              showModal={showModalGallery}
              photos={photos}
              onClose={closeModalGallery}
              initialSlide={initialSlide}
            />
          </div>
        )}
      </div>
      <div className="review-content-small__footer fbc mt-12 mt-m-0 t-2 t-m-0">
        <Typography
          variant="body"
          size="l"
          className={style.myReviews__mutedText}
        >
          {data.date}
        </Typography>
        {actor === "self" && (
          <Button
            ghost
            label="Edit my review"
            onClick={() => {
              modalFunction();
            }}
          />
        )}
      </div>
    </div>
  );
};

const EmptyReview = ({ actor, modalFunction, mobile }) => {
  return (
    <div
      className={classnames(
        style.myReviewsReviewEmpty,
        mobile ? "" : "col-12 r-0 l-0"
      )}
    >
      <Typography
        variant="body"
        size="l"
        className={style.myReviews__mutedText}
      >
        {actor === "self"
          ? "You have not added a review yet"
          : `${actor} has not reviewed their experience yet.`}
      </Typography>
      {actor === "self" && (
        <div className="t-24 b-16 t-m-20">
          <Button
            secondary
            label="Add review"
            onClick={() => modalFunction()}
          />
        </div>
      )}
    </div>
  );
};

const Review = (props) => {
  const {
    completed,
    actor,
    data,
    opponentRole,
    refetchRental,
    rentalId,
    isOpponent,
    setReviewState,
    setShowNotificationPopover
  } = props;

  const emptyData = {
    testimonial: "",
    rating : 0,
    photos: [],
    moderated: null,
    avatarUrl: '',
    name: data?.name || '',
    date: ''
  };
  const [showEditReview, setShowEditReview] = useState(false);

  const openEditReview = () => {
    setShowEditReview(true);
  };

  const closeEditReview = () => {
    setShowEditReview(false);
  };
  const handleActionAfterSubmit = useCallback(() => {
    setShowNotificationPopover(true);
    setShowEditReview(false);
    setReviewState(true);
    refetchRental();
  }, [
    refetchRental,
    setReviewState,
    setShowEditReview,
    setShowNotificationPopover
  ]);

  const reviewData = data?.testimonial ? data : emptyData;
  return (
    <div
      className={classnames(
        style.myReviewsReview,
        "mb-m-0 ml-0 ml-m-0 mr-m-0 mt-8 mb-8 b-8 b-m-0 mt-m0 mb-m0 mr-0 ml-m-0 mr-m-0",
        !isOpponent && 'mt-m-40',
        isOpponent && style.myReviewsReviewOpponent,
        isOpponent && 'mt-0 mb-8 mb-m-44 mt-m-0 mt-l-24 mt-xl-40 mt-xxl-40'
      )}
    >
      <div className="dn-m">
        {completed ? (
          <RequestCollapse
            header={actor === "self" ? <span  className="">My review</span> : `${opponentRole}'s review`}
            content={
              <CompletedReviewMobile
                actor={actor}
                data={reviewData}
                modalFunction={openEditReview}
              />
            }
            className="fw "
          />
        ) : (
          <div className="l-16 l-m-16">
            <Typography variant="subtitle" size="s" weight="bold">
              {actor === "self" ? "My review" : `${opponentRole}'s review`}
            </Typography>
            <EmptyReview actor={actor} modalFunction={openEditReview} mobile />
          </div>
        )}
      </div>

      <div className="dn db-m">
        <div className="my-reviews__review-header fbc">
          <Typography variant="subtitle" size="l">
            {actor === "self" ? "My review" : `${opponentRole}'s review`}
          </Typography>
          {completed && actor === "self" && (
            <Button
              ghost
              label="Edit my review"
              onClick={() => {
                openEditReview();
                setShowNotificationPopover(false);
              }}
              className="mt-8 underline"
            />
          )}
        </div>
        {completed ? (
          <CompletedReview actor={actor} data={reviewData} isOpponent={isOpponent} />
        ) : (
          <EmptyReview actor={actor} modalFunction={openEditReview} />
        )}
      </div>
      <EditReview
        showModal={showEditReview}
        onClose={closeEditReview}
        onActionAfterSubmit={handleActionAfterSubmit}
        initialData={reviewData}
        rentalId={rentalId}
      />

    </div>
  );
};

export default Review;
