import React, { useState, useMemo } from "react";
import { useLocalSlider } from "processes/RvProfile/components/LocalSection/hooks/useLocalSlider";
import MenuPipe from "components/MenuPipe/MenuPipe";
import classes from "processes/RvProfile/components/LocalSection/LocalSlider/LocalSlider.module.css";

const sliderData = [
  {
    id: 'local-parks',
    items: [
      {
        id: '1',
        address: 'Jackson, NJ 111',
        description: 'Indian Rock RV Park',
        goodSamBadge: true,
        rating: 4,
        ratingValue: '4.2',
        img: {
          src: 'https://s3.amazonaws.com/images.goodsam.com/trailerlifedirectory/largefeatured/500x/pho_201821081_01.jpg',
          alt: ''
        }
      },
      {
        id: '2',
        address: 'Blairstown, NJ 111',
        description: 'TripleBrook Camping Resort',
        goodSamBadge: false,
        rating: 4,
        ratingValue: '5.2',
        img: {
          src: 'https://s3.amazonaws.com/images.goodsam.com/trailerlifedirectory/largefeatured/500x/pho_980000120_01.jpg',
          alt: ''
        }
      },
      {
        id: '3',
        address: 'Jackson, NJ 222',
        description: 'Indian Rock RV Park',
        goodSamBadge: true,
        rating: 2,
        ratingValue: '4.2',
        img: {
          src: 'https://s3.amazonaws.com/images.goodsam.com/trailerlifedirectory/largefeatured/500x/pho_201204095_01.jpg',
          alt: ''
        }
      },
      {
        id: '4',
        address: 'Blairstown, NJ 222',
        description: 'TripleBrook Camping Resort',
        goodSamBadge: true,
        rating: 4,
        ratingValue: '7.2',
        img: {
          src: 'https://s3.amazonaws.com/images.goodsam.com/trailerlifedirectory/largefeatured/500x/pho_201821096_01.jpg',
          alt: ''
        }
      }
    ]
  },
  {
    id: 'local-campgrounds',
    items: [
      {
        id: '5',
        address: 'Lehighton, PA',
        description: 'Indian Rock RV Park',
        goodSamBadge: true,
        rating: 4,
        ratingValue: '4.2',
        img: {
          src: 'https://s3.amazonaws.com/images.goodsam.com/trailerlifedirectory/largefeatured/500x/pho_210100434_01.jpg',
          alt: ''
        }
      },
      {
        id: '6',
        address: 'Blairstown, NJ 111',
        description: 'TripleBrook Camping Resort',
        goodSamBadge: false,
        rating: 4,
        ratingValue: '5.2',
        img: {
          src: 'https://s3.amazonaws.com/images.goodsam.com/trailerlifedirectory/largefeatured/500x/pho_910001062_01.jpg',
          alt: ''
        }
      }
    ]
  }
];

const localNavData = [
  {
    id: 'local-parks',
    title: 'Local Parks'
  },
  {
    id: 'local-campgrounds',
    title: 'Local Campgrounds'
  }
];

export const LocalSlider = () => {
  const [sliderTabId, setSliderTabId] = useState(localNavData?.[0]?.id);
  const sliderItems = useMemo(
    () => sliderData.find(({ id }) => id === sliderTabId )?.items,
    [sliderTabId]
  );
  const [sliderContent, sliderRef, showArrows] = useLocalSlider(sliderItems, classes);

  return (
    <div className={classes.slider}>
      <div className={classes.sliderNav}>

        <div className="row">
          <div className="col">
            <div className="dib">
              <MenuPipe
                data={localNavData}
                onClickItem={(tabId) => {
                  setSliderTabId(tabId);
                }}
              />
            </div>
          </div>

          <div className={`col-auto dn ${showArrows ? 'db-m' : ''} mt-m-8`}>
            <button
              className={`${classes.navArrow} ${classes.navPrevArrow} slick-prev slick-arrow`}
              type="button"
              aria-label="Previous"
              onClick={() => {
                sliderRef.current.slickPrev();
              }}
            >
              Previous
            </button>
            <button
              className={`${classes.navArrow} ${classes.navNextArrow} slick-next slick-arrow ml-m-16 ml-l-24 ml-xxl-32`}
              type="button"
              aria-label="Next"
              onClick={() => {
                sliderRef.current.slickNext();
              }}
            >
              Next
            </button>
          </div>
        </div>

      </div>
      <div className={`${classes.sliderContent} t-28 t-m-36`}>
        {sliderContent}
      </div>
    </div>
  );
};

// LocalSlider.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

