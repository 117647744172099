import React from "react";
import Modal from "components/Modal/Modal";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "./DeleteAccountModal.module.css";

export const DeleteAccountModal = (props) => {
  const {
    show,
    onClose,
    onDeleteAccount
  } = props;

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={`${classes.modalWrap}`}
      modalClassnames={`${classes.modal}`}
      modalCrossButtonClassname={classes.crossButton}
      variant="full-screen-less-768"
    >
      <div>
        <Typography
          variant="headline"
          size="m"
          className={classes.title}
        >
          Delete account
        </Typography>
        <div className="">
          <Typography
            className={classes.subtitle}
            variant="subtitle"
            size="s"
          >
            Are you sure you want to delete your account? It will be impossible to restore account data
          </Typography>
        </div>

        <div className="row">
          <div className="col t-16 t-m-20 b-16 b-m-20">
            <div className={`df ${classes.buttons}`}>
              <Button onClick={onClose} secondary className={'mr-16 mr-m-32'}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onDeleteAccount();
                  onClose();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  );
};
