import { arrayOf, instanceOf, number, shape, string } from "prop-types";

export const ThreadType = shape({
  sid: string,
  dateCreated: instanceOf(Date),
  dateUpdated: instanceOf(Date),
  friendlyName: string,
  lastMessage: shape({
    index: number,
    dateCreated: instanceOf(Date)
  })
});

export const ThreadInfoType = shape({
  avatar: string,
  title: string
});

export const MessageType = shape({
  attributes: shape({
    users: arrayOf(shape({
      user_id: number,
      rental_id: number,
      header: shape({
        type: string,
        text: string.isRequired
      }),
      description: string,
      quote: arrayOf(shape({
        name: string.isRequired,
        value_old: string,
        value_new: string,
        url: string
      })),
      action_buttons: arrayOf(shape({
        id: string.isRequired,
        type: string,
        label: string.isRequired
      }))
    }))
  }),
  author: string,
  body: string,
  index: number,
  participantSid: string,
  sid: string,
  type: string,
  dateUpdated: instanceOf(Date),
  timestamp: instanceOf(Date)
});
