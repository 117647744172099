import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { path } from "ramda";
import { PhotosContentLoader } from "./PhotosContentLoader";
import { PhotoEditTool } from "components/PhotoEditTool";
import { imageForEditToolQuery } from "./queries/imageForEditToolQuery";
import { SpinnerBack } from "../../../Spinner/Spinner";

const extractImage = path(["image_for_edit_tool"]);

export const PhotosContainer = ({
  initialData,
  isGarage,
  garagePhotos,
  onBack,
  onLater,
  onSave,
  onUpload,
  onDelete,
  onOrder,
  onCrop,
  onGaragePhotosContinue,
  onShowGaragePhotosModal,
  onCloseGaragePhotosModal,
  loading,
  requestErrors,
  updateContextSaveHandler,
  listingPublished,
  prevStep,
  nextStep
}) => {
  const [items, setItems] = useState(initialData);
  const [itemsOrder, setItemsOrder] = useState([]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [currentCaption, setCurrentCaption] = useState('');

  useEffect(() => {
    const nextItems = initialData.filter(
      (item) => !itemsToDelete.includes(parseInt(item.id, 10))
    );

    nextItems.sort(function (a, b) {
      return (
        itemsOrder.indexOf(parseInt(a.id, 10)) -
        itemsOrder.indexOf(parseInt(b.id, 10))
      );
    });

    setItems(nextItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const [
    loadImageForEditTool,
    { data: imageForEditToolData, refetch, loading: loadingImageForEditTool }
  ] = useLazyQuery(imageForEditToolQuery);

  useEffect(() => {
    if (itemToEdit) {
      loadImageForEditTool({
        variables: {
          id: parseInt(itemToEdit, 10)
        }
      });
    }
  }, [loadImageForEditTool, itemToEdit]);

  const handleSave = useCallback(
    ({ toNextStep }) => {
      onSave({ toNextStep });
      setItemsToDelete([]);
    },
    [onSave, setItemsToDelete]
  );

  useEffect(() => {
    updateContextSaveHandler(() => handleSave({ toNextStep: false }));
  }, [handleSave, updateContextSaveHandler]);

  const handleDelete = useCallback(
    (id) => {
      const nextItems = items.filter((item) => item.id !== id);
      setItems(nextItems);
      setItemsToDelete((prev) => prev.concat([parseInt(id, 10)]));
      onDelete(id, nextItems);
    },
    [setItems, items, onDelete, setItemsToDelete]
  );

  const handleReorder = useCallback(
    (updateItems) => {
      const nextItems = updateItems(items);
      setItems(nextItems);
      setItemsOrder(nextItems.map((item) => parseInt(item.id, 10)));
      onOrder(nextItems);
    },
    [items, setItems, onOrder, setItemsOrder]
  );

  const handleEdit = useCallback(
    (id) => {
      const caption = items.find(item => item.id === id)?.caption;
      setCurrentCaption(caption);
      setItemToEdit(id);
    },
    [setItemToEdit, items, setCurrentCaption]
  );

  const handleDoneEditing = useCallback(
    async (data, imageCaptionValue) => {
      const normalizedValue = {
        id: parseInt(itemToEdit, 10),
        x1: data.x,
        x2: data.x + data.width,
        y1: data.y,
        y2: data.y + data.height,
        rotation: data.turn / 90
      };

      setItemToEdit(null);
      await onCrop(normalizedValue, imageCaptionValue);
      await refetch();
    },
    [itemToEdit, onCrop, refetch]
  );

  const handleCancelEditing = useCallback(() => {
    setItemToEdit(null);
  }, [setItemToEdit]);

  const handleUpload = useCallback(
    (files) => {
      onUpload(files, itemsToDelete);
    },
    [itemsToDelete, onUpload]
  );
  return (
    <>
      <PhotosContentLoader
        items={items}
        isGarage={isGarage}
        garagePhotos={garagePhotos}
        onReorder={handleReorder}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onUpload={handleUpload}
        onBack={onBack}
        onLater={onLater}
        onSave={handleSave}
        onGaragePhotosContinue={onGaragePhotosContinue}
        onShowGaragePhotosModal={onShowGaragePhotosModal}
        onCloseGaragePhotosModal={onCloseGaragePhotosModal}
        loading={loading}
        requestErrors={requestErrors}
        listingPublished={listingPublished}
        prevStep={prevStep}
        nextStep={nextStep}
      />
      <PhotoEditTool
        // hasImageCaption
        initialImageCaption={currentCaption}
        item={itemToEdit ? extractImage(imageForEditToolData) : null}
        onDone={handleDoneEditing}
        onCancel={handleCancelEditing}
      />
      {loadingImageForEditTool && <SpinnerBack />}
    </>
  );
};

PhotosContainer.propTypes = {
  initialData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      srcset: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          type: PropTypes.string
        })
      )
    })
  ),
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  updateContextSaveHandler: PropTypes.func.isRequired,
  listingPublished: PropTypes.bool.isRequired,
  requestErrors: PropTypes.arrayOf(
    PropTypes.exact({
      field: PropTypes.string,
      message: PropTypes.string
    })
  )
};

PhotosContainer.defaultProps = {
  initialData: [],
  loading: false
};
