/* eslint-disable indent */
import React from "react";
import { changePrice } from "utils/changePrice";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import { isUserRenter, isUserOwner } from "processes/Rentals/utils";

function calculateDiscount(price = {}, initialFee, targetFee) {
  const fee = price?.[initialFee]?.cents_total;
  const discount = price?.[targetFee]?.cents_total;

  if (!fee || !discount) return price?.[initialFee];
  const diffDollars = Math.floor(Number(((fee - discount) / 100)));
  const diffCents = ((fee - discount) % 100);

  return {
    dollars_as_part: diffDollars,
    cents_as_part: diffCents
  };
}

export const useRentalCancellingTableRow = (priceFinalAmount) => {
  const { rental, isIMGlobalIntegrationIsEnabled } = useRentalDetailsContext();
  const {
      role,
      price_original: priceOriginal,
      price_refunds: priceRefunds
  } = rental;

  if (!priceRefunds || !priceOriginal || !priceFinalAmount) {
    return {
      tableData: [],
      modalTitle: '',
      extraTextData: []
    };
  }
  const isOwner = isUserOwner(role);
  const isRenter = isUserRenter(role);
  const duration = priceFinalAmount.duration;
  const modalTitle = isOwner ? 'Payout info' : "Payment info";
  const tableData = [];
  let extraTextData = [];

  if (isRenter) {
    if (
        priceOriginal.payment_total ||
        priceRefunds.payment_total ||
        priceFinalAmount.payment_total
      ) {
      tableData.push({
        id: 'row-1',
        textSize: "xLarge",
        extraRowClasses: ['borderTopNone', 'borderTopSolid-db-m', 'borderTopSolidWithoutMarginBottom-db-m'],
        cols: [
          {
            id: "col-1",
            content: "Total*"
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.payment_total} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.payment_total} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.payment_total} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal.rental_fee?.cents_total ||
      priceRefunds.rental_fee?.cents_total ||
      priceFinalAmount.rental_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-2',
        extraRowClasses: ['borderTopSolid'],
        cols: [
          {
            id: "col-1",
            content: "Rental fee"
          },
          {
            id: "col-2",
            content:  (
              <FormattedPrice
                value={calculateDiscount(priceOriginal, 'rental_fee', 'coupon_discount')}
                isBold
              />
            )
          },
          {
            id: "col-3",
            content:  (
              <FormattedPrice
                value={calculateDiscount(priceRefunds, 'rental_fee', 'coupon_discount')}
                isBold
              />
            )
          },
          {
            id: "col-4",
            content:  (
              <FormattedPrice
                value={calculateDiscount(priceFinalAmount, 'rental_fee', 'coupon_discount')}
                isBold
              />
            )
          }
        ]
      });
    }

    if (
      priceOriginal.cleaning_fee?.cents_total ||
      priceRefunds.cleaning_fee?.cents_total ||
      priceFinalAmount.cleaning_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-3',
        cols: [
          {
            id: "col-1",
            content: 'Cleaning fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.cleaning_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.cleaning_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.cleaning_fee} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal.disposal_fee?.cents_total ||
      priceRefunds.disposal_fee?.cents_total ||
      priceFinalAmount.disposal_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-4',
        cols: [
          {
            id: "col-1",
            content: 'Disposal fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.disposal_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.disposal_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.disposal_fee} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal.delivery_fee?.cents_total ||
      priceRefunds.delivery_fee?.cents_total ||
      priceFinalAmount.delivery_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-5',
        cols: [
          {
            id: "col-1",
            content: 'Delivery fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.delivery_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.delivery_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.delivery_fee} isBold />)
          }
        ]
      });
    }

    const addOns = priceOriginal.add_ons || priceRefunds.add_ons || priceFinalAmount.add_ons || [];
    const addOnsSubRows = [];

    if (addOns?.length) {
      addOns.forEach((addOn) => {
        addOnsSubRows.push({
          id: `addon_${addOn.id}`,
          textSize: "small",
          extraRowClasses: ['borderTopNone', 'smallPadding'],
          cols: [
            {
              id: "col-1",
              content: addOn.name
            },
            {
              id: "col-2",
              content: (<FormattedPrice value={priceOriginal.add_ons_fee} isBold />)
            },
            {
              id: "col-3",
              content: (<FormattedPrice value={priceRefunds.add_ons_fee} isBold />)
            },
            {
              id: "col-4",
              content: (<FormattedPrice value={priceFinalAmount.add_ons_fee} isBold />)
            }
          ]
        });
      });
    }

    if (
      priceOriginal.add_ons_fee?.cents_total ||
      priceRefunds.add_ons_fee?.cents_total ||
      priceFinalAmount.add_ons_fee?.cents_total
    ) {
      let addOnsContent = 'Add-ons';
      if (addOns.length) {
        addOnsContent = "Add-ons";
      }

      tableData.push({
        id: 'row-6',
        cols: [
          {
            id: "col-1",
            content: addOnsContent
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.add_ons_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.add_ons_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.add_ons_fee} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal.booking_fee?.cents_total ||
      priceRefunds.booking_fee?.cents_total ||
      priceFinalAmount.booking_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-20',
        cols: [
          {
            id: "col-1",
            content: `Booking and processing fee`
          },
          {
            id: "col-2",
            content:  (
              <FormattedPrice
                value={calculateDiscount(priceOriginal, 'booking_fee', 'good_sam_membership_discount')}
                isBold
              />
            )
          },
          {
            id: "col-3",
            content:  (
              <FormattedPrice
                value={calculateDiscount(priceRefunds, 'booking_fee', 'good_sam_membership_discount')}
                isBold
              />
            )
          },
          {
            id: "col-4",
            content:  (
              <FormattedPrice
                value={calculateDiscount(priceFinalAmount, 'booking_fee', 'good_sam_membership_discount')}
                isBold
              />
            )
          }
        ]
      });
    }
    if (
      priceOriginal.insurance_package_name ||
      priceRefunds.insurance_package_name ||
      priceFinalAmount.insurance_package_name
    ) {
      tableData.push({
        id: 'row-10',
        cols: [
          {
            id: "col-1",
            content: `Rental Protection** - ${priceOriginal.insurance_package_name}`
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.insurance_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.insurance_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.insurance_fee} isBold />)
          }
        ]
      });
    }

    if (
      isIMGlobalIntegrationIsEnabled && (
      priceOriginal?.trip_interruption_insurance?.cents_total ||
      priceRefunds?.trip_interruption_insurance?.cents_total ||
      priceFinalAmount?.trip_interruption_insurance?.cents_total)
    ) {
      tableData.push({
        id: 'rental_nterruption_insurance',
        cols: [
          {
            id: "col-1",
            content: <span>Trip Interruption Insurance <sup>3</sup></span>
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.trip_interruption_insurance} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.trip_interruption_insurance} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.trip_interruption_insurance} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal?.good_sam_membership_fee?.cents_total ||
      priceRefunds?.good_sam_membership_fee?.cents_total ||
      priceFinalAmount?.good_sam_membership_fee?.cents_total
    ) {
      tableData.push({
        id: 'good_sam_membership_fee',
        cols: [
          {
            id: "col-1",
            content: <span>Good Sam Membership fee</span>
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.good_sam_membership_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.good_sam_membership_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.good_sam_membership_fee} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal.taxes?.total?.cents_total ||
      priceRefunds.taxes?.total?.cents_total ||
      priceFinalAmount.taxes?.total?.cents_total
    ) {
      tableData.push({
        id: 'row-14',
        extraRowClasses:  ['borderBottomSolid'],
        cols: [
          {
            id: "col-1",
            content: `Taxes`
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.taxes.total} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.taxes.total} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.taxes.total} isBold />)
          }
        ]
      });
    }

    extraTextData =  [
      {
        id: 'extraText-1',
        content: (
          <span>Cancelations initiated by the owner will result in a full refund. Cancellations initiated by the renter are subject to the <a href="https://support.rvrentals.com/hc/en-us/articles/4418570201495-What-is-the-cancellation-policy-">cancellation policy</a></span>
        )
      }
    ];
  }
  if (isOwner) {
    if (
      priceOriginal.rental_fee?.cents_total ||
      priceRefunds.rental_fee?.cents_total ||
      priceFinalAmount.rental_fee?.cents_total
    ) {

      const perDay = changePrice(priceFinalAmount.rental_fee, (x) => x / duration);

      const perDayContent = perDay?.cents_total
        ? <span>Rental fee <span className="dib no-wrap">(${perDay.user_friendly} x {duration} nights)</span></span>
        : <span>Rental fee</span>;

      tableData.push({
        id: 'row-1',
        extraRowClasses: ['borderTopNone', 'borderTopSolid-db-m'],
        cols: [
          {
            id: "col-1",
            content: (
              perDayContent
            )
          },
          {
            id: "col-2",
            content:  (<FormattedPrice value={priceOriginal.rental_fee} isBold />)
          },
          {
            id: "col-3",
            content:  (<FormattedPrice value={priceRefunds.rental_fee} isBold />)
          },
          {
            id: "col-4",
            content:  (<FormattedPrice value={priceFinalAmount.rental_fee} isBold />)
          }
        ]
      });
    }
    if (
      priceOriginal.cleaning_fee?.cents_total ||
      priceRefunds.cleaning_fee?.cents_total ||
      priceFinalAmount.cleaning_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-3',
        cols: [
          {
            id: "col-1",
            content: 'Cleaning fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.cleaning_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.cleaning_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.cleaning_fee} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal.disposal_fee?.cents_total ||
      priceRefunds.disposal_fee?.cents_total ||
      priceFinalAmount.disposal_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-4',
        cols: [
          {
            id: "col-1",
            content: 'Disposal fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.disposal_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.disposal_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.disposal_fee} isBold />)
          }
        ]
      });
    }

    if (
      priceOriginal.delivery_fee?.cents_total ||
      priceRefunds.delivery_fee?.cents_total ||
      priceFinalAmount.delivery_fee?.cents_total
    ) {
      tableData.push({
        id: 'row-5',
        cols: [
          {
            id: "col-1",
            content: 'Delivery fee'
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.delivery_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.delivery_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.delivery_fee} isBold />)
          }
        ]
      });
    }

    const addOns = priceOriginal.add_ons || priceRefunds.add_ons || priceFinalAmount.add_ons || [];
    const addOnsSubRows = [];

    if (addOns?.length) {
      addOns.forEach((addOn) => {
        addOnsSubRows.push({
          id: `addon_${addOn.id}`,
          textSize: "small",
          extraRowClasses: ['borderTopNone', 'smallPadding'],
          cols: [
            {
              id: "col-1",
              content: addOn.name
            },
            {
              id: "col-2",
              content: (<FormattedPrice value={priceOriginal.add_ons_fee} isBold />)
            },
            {
              id: "col-3",
              content: (<FormattedPrice value={priceRefunds.add_ons_fee} isBold />)
            },
            {
              id: "col-4",
              content: (<FormattedPrice value={priceFinalAmount.add_ons_fee} isBold />)
            }
          ]
        });
      });
    }
    if (
      priceOriginal.add_ons_fee?.cents_total ||
      priceRefunds.add_ons_fee?.cents_total ||
      priceFinalAmount.add_ons_fee?.cents_total
    ) {
      let addOnsContent = 'Add-ons';
      if (addOns.length) {
        addOnsContent = "Add-ons";
      }

      tableData.push({
        id: 'row-6',
        cols: [
          {
            id: "col-1",
            content: addOnsContent
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.add_ons_fee} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.add_ons_fee} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.add_ons_fee} isBold />)
          }
        ]
      });
    }

    if (priceOriginal.owner_share || priceRefunds.owner_share || priceFinalAmount.owner_share) {
      tableData.push({
        id: 'row-11',
        cols: [
          {
            id: "col-1",
            content: 'Your share'
          },
          {
            extraColClasses: ['boldText'],
            id: "col-2",
            content: `${priceOriginal.owner_share}%`
          },
          {
            extraColClasses: ['boldText'],
            id: "col-3",
            content: `${priceRefunds.owner_share || 0}%`
          },
          {
            extraColClasses: ['boldText'],
            id: "col-4",
            content: `${priceFinalAmount.owner_share}%`
          }
        ]
      });
    }
    if (priceOriginal.owner_earns || priceRefunds.owner_earns || priceFinalAmount.owner_earns) {
      tableData.push({
        id: 'row-9',
        extraRowClasses: ['borderTopSolid', 'borderBottomSolid'],
        textSize: 'xLarge',
        cols: [
          {
            id: "col-1",
            content: "You earn"
          },
          {
            id: "col-2",
            content: (<FormattedPrice value={priceOriginal.owner_earns} isBold />)
          },
          {
            id: "col-3",
            content: (<FormattedPrice value={priceRefunds.owner_earns} isBold />)
          },
          {
            id: "col-4",
            content: (<FormattedPrice value={priceFinalAmount.owner_earns} isBold />)
          }
        ]
      });
    }

    extraTextData =  [
      {
        id: 'extraText-1',
        content: (
          <span>Cancelations initiated by the owner will result in a full refund. Cancellations initiated by the renter are subject to the <a href="https://support.rvrentals.com/hc/en-us/articles/4418570201495-What-is-the-cancellation-policy-">cancellation policy</a></span>
        )
      }
    ];
  }


  return {
    tableData,
    modalTitle,
    extraTextData
  };
};
