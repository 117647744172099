import React, { forwardRef, useCallback, useEffect } from "react";
import { Controller } from "react-hook-form";
import Radio from "../../../form/Radio";
import classes from "./RvModelSelectorList.module.css";
import Typography from "../../../Typography";
import { Text } from "components/ContentSection/elements";

const RvModelSelectorListRow = forwardRef((props, ref) => {
  const { item, name, value, onChange, autoSelect } = props;
  const { id, model, rvClass } = item;
  const itemId = `rv${id}`;

  const handleClick = useCallback(() => {
    onChange(item);
  }, [onChange, item]);

  useEffect(() => {
    if (autoSelect) {
      setTimeout(() => {
        handleClick();
      }, 0);
    }
  }, [handleClick, autoSelect]);

  return (
    <div className={classes.contentItem} onClick={handleClick}>
      <div className={classes.cellModel}>
        <Radio
          hideLabel
          ref={ref}
          id={`${itemId}`}
          name={name}
          value={id}
          text={`${model} - ${rvClass}`}
          checked={id === value?.id}
          onChange={onChange}
        />
        <span className={classes.cellModelText}>{model}</span>
      </div>
      <div className={classes.cellClass}>
        <span className={classes.cellClassText}>{rvClass}</span>
      </div>
    </div>
  );
});

RvModelSelectorListRow.displayName = "RvModelSelectorListRow";

const RvModelSelectorListTable = ({ list, control }) => {
  return (
    <>
      <div className={`${classes.header} row`}>
        <div className={`${classes.headerItem} col-6`}>
          <Text id="model_column_title" section="generalInfoSectionContent" />
        </div>
        <div className={`${classes.headerItem} col-6`}>
          <Text
            id="rv_class_column_title"
            section="generalInfoSectionContent"
          />
        </div>
      </div>
      <Controller
        control={control}
        name="rvId"
        rules={{ required: "Required" }}
        render={(renderProps) => (
          <div className={classes.content}>
            {list.map((item, index) => (
              <RvModelSelectorListRow
                autoSelect={!renderProps.value && index === 0}
                key={item.id}
                item={item}
                {...renderProps}
              />
            ))}
          </div>
        )}
      />
    </>
  );
};

export const RvModelSelectorList = ({ list, control }) => {
  return (
    <>
      {list.length > 2 && (
        <Text
          id="multiple_choice_title"
          section="generalInfoSectionContent"
          variant="subtitle"
          size="m"
          weight="bold"
        />
      )}
      <RvModelSelectorListTable list={list} control={control} />
    </>
  );
};
