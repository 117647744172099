import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Modal } from "../../../Modal";
import { AvailabilityInfoPeriod } from "./AvailabilityInfoPeriod";
import classes from "./AvailabilityInfo.module.css";
import { FieldInput } from "../../../form/FieldInput";
import Switch from "../../../form/Switch";
import { RX_INT } from "../../../../utils/constants/RX";
import Button from "../../../Button";
import Typography from "../../../Typography";
import { isSafePrice } from "../../../../utils/isSafePrice";

const AvailabilityNotApplicable = ({ onClose }) => (
  <div className={classes.notApplicableBody}>
    <Typography variant="headline" size="m">
      Action not applicable
    </Typography>
    <Typography>
      There exist pending or confirmed rental requests for this date or set of dates.
      You need to decline or cancel those rentals in order to change the availability.
    </Typography>
    <div className="t-16 t-m-20 b-8">
      <Button secondary onClick={onClose}>
        Ok
      </Button>
    </div>
  </div>
);

const AvailabilityInfoContent = (props) => {
  const {
    control,
    errors,
    from,
    to,
    minPrice,
    onCancel,
    onApply
  } = props;

  const isAvailable = useWatch({ control, name: "available" });
  const priceHelperMessage = `Price cannot be lower than $${minPrice}`;

  return (
    <div className={classes.body}>
      <AvailabilityInfoPeriod from={from} to={to}/>
      <div className="t-16 t-m-20 b-12 b-m-20 mb-2 mb-m-0">
        <Controller
          name="available"
          control={control}
          render={(renderProps) => (
            <Switch
              id="availability_info_available"
              text="Available"
              {...renderProps}
            />
          )}
        />
      </div>
      <Typography variant="body" size="s" className={classes.availableText}>
        Deactivating selected period(s) will not cancel confirmed rental(s)
      </Typography>
      {isAvailable && (
        <div className="t-16 t-m-20 b-16 b-m-20">
          <Controller
            name="price"
            control={control}
            rules={{
              required: "Daily rate is required",
              validate: (v) => {
                const n = parseInt(v, 10);

                if (!isSafePrice(n)) {
                  return "Incorrect input";
                }

                if (n < minPrice) {
                  return priceHelperMessage;
                }

                return true;
              },
              pattern: {
                value: RX_INT,
                message: "Incorrect input"
              }
            }}
            render={(renderProps) => (
              <FieldInput
                className={classes.priceField}
                masked
                id="availability_info_price"
                label="Price per night"
                helperMessage={priceHelperMessage}
                maxLength={5}
                {...renderProps}
              />
            )}
          />
        </div>
      )}
      <div className="btn-group t-16 t-m-20 b-8">
        <Button secondary onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export const AvailabilityInfo = (props) => {
  const {
    show,
    from,
    to,
    minPrice,
    defaultPrice,
    defaultAvailability,
    isNotApplicable,
    onApply,
    onCancel
  } = props;

  const {
    control,
    reset,
    handleSubmit,
    formState,
    watch
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      price: defaultPrice,
      available: defaultAvailability
    },
    mode: "all"
  });

  useEffect(() => {
    if (show) {
      reset({
        price: defaultPrice,
        available: defaultAvailability
      });
    }
  }, [show]);

  const handleApply = useCallback(() => {
    const { price, available } = watch();

    onApply({
      price: parseInt(price, 10),
      available: !!available
    });
    onCancel();
  }, [onCancel, onApply, watch]);

  return (
    <Modal
      show={show}
      onClose={onCancel}
      variant={"full-screen-less-768"}
      modalClassnames={classes.modal}
    >
      {isNotApplicable ? (
        <AvailabilityNotApplicable
          onClose={onCancel}
        />
      ) : (
        <AvailabilityInfoContent
          from={from}
          to={to}
          control={control}
          errors={formState.errors}
          minPrice={minPrice}
          onCancel={onCancel}
          onApply={handleSubmit(handleApply)}
        />
      )}
    </Modal>
  );
};

AvailabilityInfo.defaultProps = {
  from: null,
  to: null,
  minPrice: 0,
  defaultPrice: 0,
  defaultAvailability: false
};

AvailabilityInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  from: PropTypes.object,
  to: PropTypes.object,
  minPrice: PropTypes.number,
  defaultPrice: PropTypes.number,
  defaultAvailability: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isNotApplicable: PropTypes.bool.isRequired
};
