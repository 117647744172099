import { gql } from "@apollo/client";

export const rvAmenitiesQuery = gql`
  query {
    rvAmenities: rv_amenities {
      category,
      items {
        id,
        name,
        icon
      }
    }
  }
`;
