import { useQuery } from '@apollo/client';
import { COMBINED_QUERY } from 'processes/Booking/queries/combinenQuery';

export const useCombinedQuery = (rvId, features) => {
  const { data, loading, error, refetch } = useQuery(COMBINED_QUERY, {
    variables: { id: rvId, for_listing: false, features },
    fetchPolicy: "no-cache",
    skip: !rvId
  });

  return {
    rvInitialData: { rv : data?.rvInitialData },
    featureFlagsList: { feature_flags_list: data?.featureFlagsList },
    membershipDetails: { membership_details: data?.membershipDetails },
    loading,
    error,
    refetch
  };
};
