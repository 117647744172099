import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ListingContext } from "../../ListingContext";
import { NavMenu } from "./NavMenu";
import { NavStatus } from "./NavStatus";
import BasicButton from "../../../Button/BasicButton";
import { MatchMedia, useMatchMedia } from "../../../MatchMedia";

const STATUS_MESSAGES = {
  2: "You are ready to publish your listing!",
  3: "We are reviewing your listing and will notify you when it is published.",
  moderation:
    "Your listing is being reviewed and you will be notified once it is approved.",
  publishError: "Please complete all sections"
};

const AsideNavContentLoader = ({
  context,
  updateContext,
  isMobileNavMode,
  setIsMobileNavMode,
  stepsWithErrors,
  showDetailsToggle,
  setDetailsModalActive
}) => {
  const isBelow768 = useMatchMedia({
    maxWidth: 767
  });

  const {
    id,
    status,
    listing_steps_completed: completed,
    public_url,
    fields_awaiting_moderation: moderation,
    is_new_photos_from_garage
  } = context && context.initialData ? context.initialData : {};

  const { isListingPublished, isListingModerated, errors, isGarage } = context
    ? context
    : {};

  const getStatusMessage = useCallback(() => {
    if (isBelow768 && errors && errors.length > 0) {
      return [STATUS_MESSAGES.publishError, "error"];
    }

    const statusKey = status?.key;

    if (statusKey === "4" && moderation) {
      return [STATUS_MESSAGES.moderation];
    }

    if (STATUS_MESSAGES[statusKey]) return [STATUS_MESSAGES[statusKey]];
  }, [isBelow768, errors, status?.key, moderation]);

  return (
    <aside className="no-gutter col-12 col-xl-2-wide">
      <NavStatus
        code={status?.key}
        text={status?.value}
        message={getStatusMessage()}
        setDetailsModalActive={setDetailsModalActive}
        showDetailsToggle={showDetailsToggle}
        context={context}
        updateContext={updateContext}
      />
      <NavMenu
        id={id}
        completed={completed}
        isNewPhotosFromGarage={is_new_photos_from_garage && isGarage}
        isMobileNavMode={isMobileNavMode}
        setIsMobileNavMode={setIsMobileNavMode}
        listingPublished={isListingPublished}
        stepsWithErrors={stepsWithErrors}
      />
      {isListingModerated && public_url && (
        <>
          <MatchMedia mediaQuery="(max-width: 767px), (min-width: 1440px)">
            <div className="btn-group btn-group--start fw t-16 t-m-20 b-16 b-m-20">
              <BasicButton
                href={public_url}
                target="_blank"
                rel="noopener noreferrer"
                label="View public profile"
                secondary
                className="fw-m"
              />
            </div>
          </MatchMedia>
          <MatchMedia mediaQuery="(max-width: 1439px) and (min-width: 768px)">
            <div className="row">
              <div className="col">
                <div className="row t-16 b-16 b-m-0">
                  <div className="col t-16 t-m-0 b-16 b-m-12">
                    <div className="btn-group btn-group--center btn-group--m-start">
                      <BasicButton
                        href={public_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        label="View public profile"
                        secondary
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MatchMedia>
        </>
      )}
    </aside>
  );
};

export const AsideNav = ({
  isMobileNavMode,
  setIsMobileNavMode,
  stepsWithErrors,
  setDetailsModalActive,
  showDetailsToggle
}) => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <AsideNavContentLoader
          context={context[0]}
          updateContext={context[1]}
          isMobileNavMode={isMobileNavMode}
          setIsMobileNavMode={setIsMobileNavMode}
          stepsWithErrors={stepsWithErrors}
          setDetailsModalActive={setDetailsModalActive}
          showDetailsToggle={showDetailsToggle}
        />
      )}
    </ListingContext.Consumer>
  );
};

AsideNav.propTypes = {
  setIsMobileNavMode: PropTypes.func.isRequired,
  stepsWithErrors: PropTypes.arrayOf(PropTypes.string).isRequired
};
