import { gql } from "@apollo/client";
import { contentBlockFragment } from "components/ContentSection/queries/contentQuery";

export const generalInfoSectionContentQuery = gql`
  query {
    generalInfoSectionContent: content(section: "listing_step_general_info") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const rvDetailsSectionContentQuery = gql`
  query {
    rvDetailsSectionContent: content(section: "listing_step_rv_details") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const listingDetailsSectionContentQuery = gql`
  query {
    listingDetailsSectionContent: content(
      section: "listing_step_listing_details"
    ) {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const rvPhotosSectionContentQuery = gql`
  query {
    rvPhotosSectionContent: content(section: "listing_step_photos") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const amenitiesSectionContentQuery = gql`
  query {
    amenitiesSectionContent: content(section: "listing_step_amenities") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const locationSectionContentQuery = gql`
  query {
    locationSectionContent: content(section: "listing_step_location") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const rentalFeesSectionContentQuery = gql`
  query {
    rentalFeesSectionContent: content(section: "listing_step_fees") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const otherFeesAddonsSectionContentQuery = gql`
  query {
    otherFeesAddonsSectionContent: content(section: "listing_step_add_ons") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const availabilitySectionContentQuery = gql`
  query {
    availabilitySectionContent: content(section: "listing_step_availability") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;

export const additionalInsuranceSectionContentQuery = gql`
  query {
    additionalInsuranceSectionContent: content(section: "listing_step_additional_insurance") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;
