import React from "react";
import Typography from "components/Typography";
import { TopTripEssentials } from "processes/RvProfile/components/TopTripEssentials/TopTripEssentials";
import classes from "./GetReadyInfoSection.module.css";

export const GetReadyInfoSection = () => {
  return (
    <div className={`${classes.container}`}>
      <header className={classes.header}>
        <div className={classes.headerInner}>
          <div className={classes.headerInnerItem}>
            <picture>
              <source srcset="http://cw-rr.dev.quintegro.net/files/promo_shops/1619692430.1176_1b404ae7.jpg" media="(min-width: 768px)" />
              <img src="http://cw-rr.dev.quintegro.net/files/promo_shops/1619692430.1176_1b404ae7.jpg" alt="Get stocked" />
            </picture>
          </div>
          <div className={`${classes.headerInnerItem} dn db-m `}>
            <div className={`${classes.headerContent} t-m-64 l-48 r-48 mt-l-2 t-xl-80`}>
              <div>
                <Typography variant="overline"  className={classes.amenitiesOverlineText}>
              Get stocked
                </Typography>
              </div>
              <div className={classes.getReadyTitle}>
                <Typography variant="title" size="2"  className={classes.amenitiesTitleText}>
              Get Ready
                </Typography>
              </div>
              <div className="dn db-l">
                <Typography variant="subtitle" size="s">
              Being a part of the camping world family means you'll never be alone on the open road. With over 130 supercenters nationwide and more than 1500 RV technicians we are proud to be the number one RV service provider since 1966.
                  <br /> <br />

Our RV service team will keep you on the road we offer a variety of products and services for all.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        <div className={``}>
          <div className=" t-48 t-m-40 l-m-48 ">
            <div className="dn-m">
              <Typography variant="overline"  className={classes.amenitiesOverlineText}>
              Get stocked
              </Typography>
            </div>
            <div className={`${classes.getReadyTitle} dn-m`}>
              <Typography variant="title" size="2"  className={classes.amenitiesTitleText}>
              Get Ready
              </Typography>
            </div>

            <div className="dn-l">
              <Typography variant="subtitle" size="s">
              Being a part of the camping world family means you'll never be alone on the open road. With over 130 supercenters nationwide and more than 1500 RV technicians we are proud to be the number one RV service provider since 1966.
                <br /> <br />

Our RV service team will keep you on the road we offer a variety of products and services for all.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-40 mt-l-0">
        <TopTripEssentials />
      </div>
    </div>
  );
};

// GetReadyInfoSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

