import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { FieldController } from "../../../../components/form/FieldController/FieldController";
import { extractServerErrors } from "../../../../utils/extractErrors";
import { extractField } from "../../../../utils/extractField";
import Switch from "../../../../components/form/Switch";
import Typography from "../../../../components/Typography";
import { FormError } from "../General/FormErrors";
import { saveNotificationsSettings } from "./mutations/save";
import { useDefaultValues, useFormTexts } from "./hooks";
import { FIELDS_IDS } from "./utils";

const mutationName = "notifications_change";
const extractMutationErrors = extractServerErrors(mutationName);

const NotificationsForm = (props) => {
  const { content, userData } = props;
  const [action, result] = useMutation(saveNotificationsSettings);
  const defaultValues = useDefaultValues(userData);
  const texts = useFormTexts(content);

  const { control } = useForm({
    defaultValues,
    mode: "onChange"
  });

  const renderSwitch = useCallback(
    (name) => {
      const field = extractField(content.form, name);
      return (
        <FieldController
          name={name}
          control={control}
          render={(renderProps) => (
            <div className="df t-8 b-8">
              <Switch
                {...renderProps}
                id={name}
                text={field?.label}
                disabled={field?.disabled || false}
                mobileVariant="stretch-reverse"
                onChange={(checked) => {
                  renderProps.onChange(checked);
                  action({
                    variables: {
                      notifications: [
                        {
                          id: name,
                          checked
                        }
                      ]
                    }
                  });
                }}
              />
            </div>
          )}
        />
      );
    },
    [content, control]
  );

  const renderBlock = useCallback((label, children) => {
    return (
      <div className="b-16 b-m-20">
        <Typography variant="subtitle" size="m" weight="bold">
          {label}
        </Typography>
        <div className="df fdc">{children}</div>
      </div>
    );
  }, []);

  return (
    <div className="df fdc">
      {renderBlock(
        texts.emailTitle,
        <>
          {renderSwitch(FIELDS_IDS.email_messages)}
          {renderSwitch(FIELDS_IDS.email_booking_updates)}
          {renderSwitch(FIELDS_IDS.email_rental_reminder)}
          {renderSwitch(FIELDS_IDS.email_rate_experience)}
        </>
      )}
      <div className="t-16 t-m-20" />
      {renderBlock(
        texts.mobileTitle,
        <>
          {renderSwitch(FIELDS_IDS.mobile_messages)}
          {renderSwitch(FIELDS_IDS.mobile_booking_updates)}
          {renderSwitch(FIELDS_IDS.mobile_rental_reminder)}
          {renderSwitch(FIELDS_IDS.mobile_rate_experience)}
        </>
      )}
      <FormError requestErrors={extractMutationErrors(result)} />
    </div>
  );
};

NotificationsForm.propTypes = {
  content: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
};

export default NotificationsForm;
