/*
 * Payout Form Utils
 */

export const PAYOUT_DATA = {
  "text": [
    {
      "id": "account_information_section_title",
      "value": "Account information",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "person_name_section_title",
      "value": "Name on the account",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "address_section_title",
      "value": "Billing address",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "company_representative_section_title",
      "value": "Company Representative",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "company_information_section_title",
      "value": "Company Information",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "date_of_birth_label",
      "value": "Date of birth",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "agreement_text",
      "value": "<p>I agree to Camping World RV Rentals <a href=\"https://www.campingworld.com/\" target=\"_blank\">Terms of Service</a> and the <a href=\"https://stripe.com/connect-account/legal\" target=\"_blank\">Stripe Connected Account Agreement</a>.</p>\r\n",
      "type": "html",
      "__typename": "TextItem"
    },
    {
      "id": "completed_view_title",
      "value": "Account information",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "completed_view_name_label",
      "value": "Name",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "completed_view_bank_label",
      "value": "Bank",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "completed_view_account_label",
      "value": "Account",
      "type": "plain",
      "__typename": "TextItem"
    },
    {
      "id": "completed_view_address_label",
      "value": "Address",
      "type": "plain",
      "__typename": "TextItem"
    }
  ],
  "buttons": [
    {
      "id": "save_button",
      "label": "Update",
      "type": "primary",
      "disabled": false,
      "link": null,
      "__typename": "Button"
    },
    {
      "id": "completed_view_update_button",
      "label": "Edit details",
      "type": "additional",
      "disabled": false,
      "link": null,
      "__typename": "Button"
    }
  ],
  "images": null,
  "form": [
    {
      "id": "individual_account_type",
      "label": "Individual",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "company_account_type",
      "label": "Company",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "account_number",
      "label": "Account number",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "routing_number",
      "label": "Routing number",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "ssn",
      "label": "SSN",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "first_name",
      "label": "First name",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "last_name",
      "label": "Last name",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "month",
      "label": "Month",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "day",
      "label": "Day",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "year",
      "label": "Year",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "company_name",
      "label": "Company Name",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "company_tax_id",
      "label": "Company Tax ID",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "address",
      "label": "Street address",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "city",
      "label": "City",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "state",
      "label": "State",
      "disabled": false,
      "options": [
        {
          "key": "AK",
          "value": "Alaska",
          "__typename": "KeyValuePair"
        },
        {
          "key": "AL",
          "value": "Alabama",
          "__typename": "KeyValuePair"
        },
        {
          "key": "AR",
          "value": "Arkansas",
          "__typename": "KeyValuePair"
        },
        {
          "key": "AZ",
          "value": "Arizona",
          "__typename": "KeyValuePair"
        },
        {
          "key": "CA",
          "value": "California",
          "__typename": "KeyValuePair"
        },
        {
          "key": "CO",
          "value": "Colorado",
          "__typename": "KeyValuePair"
        },
        {
          "key": "CT",
          "value": "Connecticut",
          "__typename": "KeyValuePair"
        },
        {
          "key": "DC",
          "value": "District of Columbia",
          "__typename": "KeyValuePair"
        },
        {
          "key": "DE",
          "value": "Delaware",
          "__typename": "KeyValuePair"
        },
        {
          "key": "FL",
          "value": "Florida",
          "__typename": "KeyValuePair"
        },
        {
          "key": "GA",
          "value": "Georgia",
          "__typename": "KeyValuePair"
        },
        {
          "key": "HI",
          "value": "Hawaii",
          "__typename": "KeyValuePair"
        },
        {
          "key": "IA",
          "value": "Iowa",
          "__typename": "KeyValuePair"
        },
        {
          "key": "ID",
          "value": "Idaho",
          "__typename": "KeyValuePair"
        },
        {
          "key": "IL",
          "value": "Illinois",
          "__typename": "KeyValuePair"
        },
        {
          "key": "IN",
          "value": "Indiana",
          "__typename": "KeyValuePair"
        },
        {
          "key": "KS",
          "value": "Kansas",
          "__typename": "KeyValuePair"
        },
        {
          "key": "KY",
          "value": "Kentucky",
          "__typename": "KeyValuePair"
        },
        {
          "key": "LA",
          "value": "Louisiana",
          "__typename": "KeyValuePair"
        },
        {
          "key": "MA",
          "value": "Massachusetts",
          "__typename": "KeyValuePair"
        },
        {
          "key": "MD",
          "value": "Maryland",
          "__typename": "KeyValuePair"
        },
        {
          "key": "ME",
          "value": "Maine",
          "__typename": "KeyValuePair"
        },
        {
          "key": "MI",
          "value": "Michigan",
          "__typename": "KeyValuePair"
        },
        {
          "key": "MN",
          "value": "Minnesota",
          "__typename": "KeyValuePair"
        },
        {
          "key": "MO",
          "value": "Missouri",
          "__typename": "KeyValuePair"
        },
        {
          "key": "MS",
          "value": "Mississippi",
          "__typename": "KeyValuePair"
        },
        {
          "key": "MT",
          "value": "Montana",
          "__typename": "KeyValuePair"
        },
        {
          "key": "NC",
          "value": "North Carolina",
          "__typename": "KeyValuePair"
        },
        {
          "key": "ND",
          "value": "North Dakota",
          "__typename": "KeyValuePair"
        },
        {
          "key": "NE",
          "value": "Nebraska",
          "__typename": "KeyValuePair"
        },
        {
          "key": "NH",
          "value": "New Hampshire",
          "__typename": "KeyValuePair"
        },
        {
          "key": "NJ",
          "value": "New Jersey",
          "__typename": "KeyValuePair"
        },
        {
          "key": "NM",
          "value": "New Mexico",
          "__typename": "KeyValuePair"
        },
        {
          "key": "NV",
          "value": "Nevada",
          "__typename": "KeyValuePair"
        },
        {
          "key": "NY",
          "value": "New York",
          "__typename": "KeyValuePair"
        },
        {
          "key": "OH",
          "value": "Ohio",
          "__typename": "KeyValuePair"
        },
        {
          "key": "OK",
          "value": "Oklahoma",
          "__typename": "KeyValuePair"
        },
        {
          "key": "OR",
          "value": "Oregon",
          "__typename": "KeyValuePair"
        },
        {
          "key": "PA",
          "value": "Pennsylvania",
          "__typename": "KeyValuePair"
        },
        {
          "key": "RI",
          "value": "Rhode Island",
          "__typename": "KeyValuePair"
        },
        {
          "key": "SC",
          "value": "South Carolina",
          "__typename": "KeyValuePair"
        },
        {
          "key": "SD",
          "value": "South Dakota",
          "__typename": "KeyValuePair"
        },
        {
          "key": "TN",
          "value": "Tennessee",
          "__typename": "KeyValuePair"
        },
        {
          "key": "TX",
          "value": "Texas",
          "__typename": "KeyValuePair"
        },
        {
          "key": "UT",
          "value": "Utah",
          "__typename": "KeyValuePair"
        },
        {
          "key": "VA",
          "value": "Virginia",
          "__typename": "KeyValuePair"
        },
        {
          "key": "VT",
          "value": "Vermont",
          "__typename": "KeyValuePair"
        },
        {
          "key": "WA",
          "value": "Washington",
          "__typename": "KeyValuePair"
        },
        {
          "key": "WV",
          "value": "West Virginia",
          "__typename": "KeyValuePair"
        },
        {
          "key": "WI",
          "value": "Wisconsin",
          "__typename": "KeyValuePair"
        },
        {
          "key": "WY",
          "value": "Wyoming",
          "__typename": "KeyValuePair"
        }
      ],
      "max_length": null,
      "__typename": "FormItem"
    },
    {
      "id": "zip",
      "label": "ZIP code",
      "disabled": false,
      "options": null,
      "max_length": null,
      "__typename": "FormItem"
    }
  ],
  "__typename": "ContentBlock"

};

export const FIELDS_IDS = {
  account_number: "account_number",
  account_type: "account_type",
  address: "address",
  bank_name: "bank_name",
  city: "city",
  company_name: "company_name",
  company_tax_id: "company_tax_id",
  _birthday: "_birthday",
  dob_day: "dob_day",
  dob_month: "dob_month",
  dob_year: "dob_year",
  first_name: "first_name",
  last_name: "last_name",
  routing_number: "routing_number",
  ssn: "ssn",
  state: "state",
  stripe_bank_account_id: "stripe_bank_account_id",
  token: "token",
  zip: "zip",
  agreement: "agreement"
};

export const ACCOUNT_TYPES = {
  notSelected: "0",
  individual: "1",
  company: "2"
};

export const DEFAULT_VALUES = {
  ...Object.values(FIELDS_IDS).reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {}),
  [FIELDS_IDS.account_type]: ACCOUNT_TYPES.individual,
  [FIELDS_IDS.agreement]: false
};
