import React from "react";
import PropTypes from "prop-types";
import badgeImage from "processes/Rentals/RentalDetails/components/ProfileInfoAside/goodsam-badge.svg";
import avatarEmpty from "processes/Rentals/RentalDetails/components/ProfileInfoAside/no-avatar.svg";
import classes from "./ProfileInfoAsideAvatar.module.css";

const ProfileInfoAsideAvatar = ({ avatar: { src, alt } = {}, hasBadge }) => (
  <div className={classes.avatar}>
    <div>
      <img
        className={classes.avatarImg}
        src={src || avatarEmpty}
        alt={alt}
      />
    </div>
    {hasBadge && (
      <div className={`${classes.avatarBadgeContainer}`}>
        <img
          src={badgeImage}
          alt="Good Sam"
          className={classes.avatarBadge}
        />
      </div>
    )}
  </div>
);


ProfileInfoAsideAvatar.propTypes = {
  avatar: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  hasBadge: PropTypes.bool
};

export default ProfileInfoAsideAvatar;
