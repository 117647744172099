function getKey() {
  return `CWRR:rentalFilters`;
}

export function readStorage() {
  try {
    const data = JSON.parse(window.localStorage.getItem(getKey()));
    if (typeof data === "object" && data !== null) {
      return data;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
}

/**
 * @param {object|{}} data
 */
export function writeStorage(data) {
  try {
    if (typeof data === "object") {
      let serialObj = JSON.stringify(data);
      window.localStorage.setItem(getKey(), serialObj);
    } else {
      window.localStorage.setItem(getKey(), {});
    }
  } catch (e) {
    window.localStorage.setItem(getKey(), {});
  }
}
