/**
 * RV Basic utils
 */

export const getRvGeneratorValue = (rvData) => {
  if (!rvData || rvData.generator_not_applicable) {
    return null;
  }
  if (rvData.limited_generator_hours === false) {
    return {
      text: "Unlimited",
      isUnlimited: true
    };
  }
  const hours = rvData.generator_hours_included;
  return {
    text: `${hours} ${hours === 1 ? "hour" : "hours"} included per day`,
    isFinite: true
  };
};

export const getRvMileageValue = (rvData) => {
  if (!rvData || rvData.is_trailed_class) {
    return null;
  }
  if (rvData.limited_daily_mileage === false) {
    return {
      text: "Unlimited",
      isUnlimited: true
    };
  }

  return {
    text: `${rvData.miles_included} miles per day`,
    isFinite: true
  };
};

export const getRvPickUpLocation = (rvData, fullAddress) => {
  const isDelivery = rvData.delivery_option
    && rvData.location?.full_address !== fullAddress;
  return {
    text: isDelivery ? "Delivery" : "Pick-Up",
    value: fullAddress,
    isDelivery
  };
};

export const getRvLocation = ({
  hasSpecificLocations,
  isInitialLocation,
  rentalType,
  fullAddress
}) => {
  const isAllowChooseLocation = (rentalType === 1 && hasSpecificLocations) || rentalType === 2;
  const isDelivery = (rentalType === 1 && !isInitialLocation) || rentalType === 2;

  return {
    text: isDelivery ? "Delivery" : "Pick-Up",
    value: fullAddress,
    isAllowChooseLocation
  };
};

export const isFlatPrice = (priceType) => {
  return priceType?.key === "2";
};

export const getPriceTypeText = (priceType) => {
  return priceType?.key && !isFlatPrice(priceType)
    ? ` / ${priceType?.value}`
    : '';
};
