import PlanSelection from "./PlanSelection";
import PaymentMethod from "./PaymentMethod";
import CheckoutContainer from "./CheckoutContainer";

export const steps = [
  {
    id: "plan-selection",
    title: "Plan selection",
    index: 0,
    stepNumber: 1,
    component: PlanSelection,
    wasSelected: true,
    completed: false,
    selected: true
  },
  {
    id: "payment-method",
    title: "Payment method",
    index: 1,
    stepNumber: 2,
    component: PaymentMethod,
    wasSelected: false,
    completed: false,
    selected: false
  },
  {
    id: "checkout",
    title: "Checkout",
    index: 2,
    stepNumber: 3,
    component: CheckoutContainer,
    wasSelected: false,
    completed: false,
    selected: false
  }
];
