import { useCallback, useEffect } from "react";

export function useOnResize(handler) {
  const memoizedHandler = useCallback(handler, []);

  useEffect(() => {
    memoizedHandler();
  }, []);

  window.addEventListener("resize", memoizedHandler);

  return () => {
    window.removeEventListener("resize", memoizedHandler);
  };
}
