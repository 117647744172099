import React, { forwardRef, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Radio } from "components/form";
import classes from "./GoodsamInsuranceOptionCollapse.module.css";
import { ContentSectionElementTextTag } from "components/ContentSection/elements/Text";
import { formatCents } from "utils/convertCents";

const GoodsamInsuranceOptionCollapse = forwardRef(
  function BookingInsuranceOptionCollapse(props, ref) {
    const {
      wrapRef,
      value,
      id,
      checked,
      label,
      price,
      period,
      description,
      onChange,
      ...other
    } = props;
    const [isCollapseActive, setIsCollapseActive] = useState(false);
    const [isShowItem, setIsShowItem] = useState(false);
    const timeoutRef = useRef(0);
    const collapseRef = useRef(null);

    const handleChange = (e) => {
      onChange(e);
    };
    const handleClick = (e) => {
      setIsCollapseActive(!isCollapseActive);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (collapseRef.current) {
        timeoutRef.current = setTimeout(() => {
          wrapRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }, 200);
      }
    };
    return (
      <div
        ref={collapseRef}
        className={classnames(
          classes.collapse,
          isCollapseActive && classes.collapseActive,
          !isCollapseActive && classes.collapseHide
        )}
      >
        <div className={classes.collapseHeader}>
          <Radio
            ref={ref}
            id={id}
            value={value}
            checked={checked}
            className={classes.collapseRadio}
            onChange={handleChange}
            onClick={() => {
              if (
                (!isShowItem && checked) ||
                (isShowItem && checked) ||
                (!isShowItem && !checked)
              ) {
                setIsShowItem(!isShowItem);
              }
            }}
            {...other}
          >
            <div className={`${classes.label} fw-700`}>{label}</div>
          </Radio>
          <div className="fss">
            <div
              className={`${classes.label} ${classes.labelRight} tar`}
              onClick={(e) => {
                handleClick();
              }}
            >
              <span className="fw-700">{`$${price.dollars_as_part}.`}</span>
              {formatCents(price.cents_as_part)}/{period}
            </div>
          </div>
        </div>
        <div className={classes.collapseContent}>
          <ContentSectionElementTextTag
            content={{ type: "html", value: description }}
            elementId={`InsuranceOptionCard-${id}`}
            tag="div"
            className={classes.insuranceOptionCard}
          />
        </div>
      </div>
    );
  }
);

GoodsamInsuranceOptionCollapse.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  price: PropTypes.shape({
    cents_total: PropTypes.number.isRequired,
    cents_as_part: PropTypes.number.isRequired,
    dollars_as_part: PropTypes.number.isRequired
  }),
  period: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default GoodsamInsuranceOptionCollapse;
