import React, { useState, useCallback } from "react";
import { submitReview } from "../../mutations/submitReview";
import { getReviewPhotoId } from "processes/Rentals/utils";
import EditReviewModal from "processes/Rentals/RentalDetails/components/AllReviews/EditReviewModal";
import { useSubmitReview }from "processes/Rentals/RentalDetails/hooks/useSubmitReview";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { SpinnerBack } from "components/Spinner/Spinner";

function resetSubmitData(id, data) {
  const photosOrder = data?.photos?.map(photo => getReviewPhotoId(photo?.id, "_"));
  return {
    rental: id,
    testimonial: data?.testimonial || '',
    rating: data?.rating || 0,
    photos_order: photosOrder || [],
    photos_to_delete: []
  };
}

const EditReview = (props) => {
  const {
    showModal,
    rentalId,
    initialData,
    onActionAfterSubmit,
    onClose
  } = props;

  const [globalError, setGlobalError] = useState(null);
  const [handleSubmitReview, submitReviewLoading] = useSubmitReview(
    submitReview,
    'submit_review',
    setGlobalError
  );
  const [submitData, setSubmitData] = useState(resetSubmitData(rentalId, initialData));
  const needsModeration = initialData.moderated !== null ? !initialData.moderated : null;

  const handleClose = useCallback(() => {
    setSubmitData(resetSubmitData(rentalId, initialData));
    onClose();
  }, [initialData, onClose, rentalId, setSubmitData]);

  const updateTestimonial = useCallback((value) => {
    setSubmitData((data) => ({
      ...data,
      testimonial: value
    }));
  }, [setSubmitData]);

  const updateStars = useCallback((star) => {
    setSubmitData((data) => ({
      ...data,
      rating: star
    }));
  }, [setSubmitData]);

  const updatePhotoIds = useCallback((idsToOrder, idToDelete) => {
    const idstoPhotosOrder = idsToOrder.map(photo => getReviewPhotoId(photo, "_"));
    function getIdsToPhotoDelete (data) {
      return idToDelete
        ? [...data.photos_to_delete, getReviewPhotoId(idToDelete, "_")]
        : data.photos_to_delete;
    }

    setSubmitData((data) => ({
      ...data,
      photos_order: idstoPhotosOrder,
      photos_to_delete: getIdsToPhotoDelete(data)
    }));
  }, [setSubmitData]);

  const handleSubmit = useCallback(async() => {
    const errors = await handleSubmitReview(submitData);
    if (!errors) onActionAfterSubmit();
  }, [
    handleSubmitReview,
    onActionAfterSubmit,
    submitData
  ]);

  return (
    <>
      {submitReviewLoading && <SpinnerBack />}
      <EditReviewModal
        showModal={showModal}
        rentalId={rentalId}
        initialPhotos={initialData.photos}
        data={submitData}
        needsModeration={needsModeration}
        updateTestimonial={updateTestimonial}
        updateStars={updateStars}
        updatePhotoIds={updatePhotoIds}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />

      {globalError && (
        <NotificationPopover
          show
          status="error"
          text={globalError}
          onClose={() => {
            setGlobalError(null);
          }}
        />
      )}
    </>
  );
};

export default EditReview;
