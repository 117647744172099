import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function getText(text, html) {
  if (text) {
    return text;
  }

  return <span dangerouslySetInnerHTML={{ __html: html }} />;
}

const Checkbox = forwardRef((props, ref) => {
  const {
    className,
    pseudoCheckClassName,
    labelTextClassName,
    id,
    text,
    html,
    hasError,
    mobileVariant,
    ...inputProps
  } = props;

  const classNames = classnames(
    className,
    "checkbox",
    `mobile--${mobileVariant}`,
    {
      error: hasError
    }
  );

  const classNameText = classnames({
    checkbox__txt: true,
    [labelTextClassName]: !!labelTextClassName,
    "checkbox__txt--html": !text && !!html
  });

  const classPseudoCheck = classnames(
    "checkbox__pseudo-check",
    pseudoCheckClassName
  );
  return (
    <label className={classNames}>
      <input ref={ref} type="checkbox" id={id} {...inputProps} />
      <span className={classPseudoCheck} />
      <span className={classNameText}>{getText(text, html)}</span>
    </label>
  );
});

Checkbox.propTypes = {
  className: PropTypes.string,
  pseudoCheckClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultChecked: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  mobileVariant: PropTypes.oneOf(["base", "stretch-reverse"])
};

export default Checkbox;
