import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Modal } from "../../../../../components/Modal";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import Filters from "./Filters";
import { RentalStatusType } from "processes/Rentals/types";
import classes from "./FiltersModal.module.css";

const FiltersModal = (props) => {
  const { open, onClose, initialValues, onSave, statuses } = props;
  const [values, setValues] = useState(initialValues);

  const mdlRef = useScrollBarWidth();

  const onChangeFilter = useCallback(({ id, checked }) => {
    setValues(p => ({ ...p, [id]: checked }));
  }, []);

  const onCancel = useCallback(() => {
    setValues(initialValues);
    onClose();
  }, [initialValues, onClose]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  return (
    <Modal
      show={open}
      onClose={onCancel}
      showCross
      modalWrapClassnames={classnames(
        classes.modalRoot,
        ""
      )}
      modalClassnames={classnames(
        classes.modal,
        "mdl--full-screen-less-768"
      )}
      mdlRef={mdlRef}
    >
      <div className={`${classes.content}`}>
        <Typography variant="headline" size="m">
          Filter
        </Typography>
        <div className="b-120 b-m-0 mb-40 mb-m-0">
          <Filters
            values={values}
            onChange={onChangeFilter}
            statuses={statuses}
          />
        </div>
      </div>
      <div className={classnames(classes.bar, "df t-20 b-20")}>
        <Button secondary label="Cancel" onClick={onCancel} />
        <div className="r-16 r-m-24" />
        <Button
          label="Apply"
          onClick={() => {
            onSave(values);
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

FiltersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  statuses: PropTypes.arrayOf(RentalStatusType).isRequired
};

export default FiltersModal;
