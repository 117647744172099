import React, { forwardRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import size from "lodash/size";
import passwordShownIcon from "./icons/passwordShownIcon";
import passwordHiddenIcon from "./icons/passwordHiddenIcon";
import { useFieldFocus } from "../useFieldFocus";

function renderHint(hint) {
  if (!hint) {
    return null;
  }

  return <div className="field-input__hint">{hint}</div>;
}

const useValidation = (inputValue, maskedInput) => {
  if (!maskedInput) {
    return inputValue;
  }

  return inputValue.replace(/\D/g, "");
};

function renderMaxLength(maxLength, value, type, hideMaxLength) {
  if (!maxLength || hideMaxLength) {
    return null;
  }

  const { length } = "" + value;

  return (
    <div className="field-input__counter">
      {`${length}/${maxLength}${
        type === "textarea" ? " characters maximum" : ""
      }`}
    </div>
  );
}

const stringValueMapper = (v) => `${v}`;

const FieldInput = forwardRef((props, ref) => {
  const {
    id,
    label,
    value: rawValue,
    valueMapper = stringValueMapper,
    type,
    disabled,
    maxLength,
    hideMaxLength,
    icon,
    helperMessage,
    errorMessage,
    hasError,
    placeholder,
    className,
    onFocus,
    onBlur,
    children,
    masked,
    inputComponent,
    ...inputProps
  } = props;

  const value = valueMapper(rawValue);
  const [isVisiblePassword, setVisiblePassword] = useState(false);
  const [focused, handleFocus, handleBlur] = useFieldFocus(onFocus, onBlur);

  const fieldClassNames = classnames(
    "field-input",
    `field-input--type--${type}`,
    {
      "no-label": !label,
      disabled,
      focused,
      filled: size(value) || placeholder,
      error: hasError
    },
    className
  );

  const hintElement = renderHint(hasError ? errorMessage : helperMessage);
  const maxLengthElement = renderMaxLength(
    maxLength,
    value,
    type,
    hideMaxLength
  );

  const isPassword = type === "password";
  const fieldIcon = isPassword
    ? isVisiblePassword
      ? passwordShownIcon
      : passwordHiddenIcon
    : icon;

  const iconClickHandler = useCallback(() => {
    if (isPassword) {
      setVisiblePassword((visible) => !visible);
    }
  }, [isPassword, setVisiblePassword]);

  const iconClassName = classnames({
    "field-input__icon": true,
    "field-input__icon--password": isPassword
  });

  const InputComponent =
    inputComponent || (type === "textarea" ? "textarea" : "input");

  return (
    <div className={fieldClassNames}>
      {fieldIcon && (
        <div className={iconClassName} onClick={iconClickHandler}>
          {fieldIcon}
        </div>
      )}
      <InputComponent
        className="field-input__field"
        id={id}
        ref={ref}
        maxLength={maxLength}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={useValidation(value, masked)}
        disabled={disabled}
        type={isVisiblePassword ? "text" : type}
        {...inputProps}
      />
      {label && (
        <label htmlFor={id} className="field-input__label">
          {label}
        </label>
      )}
      {(hintElement || maxLengthElement) && (
        <div className="field-input__helpers">
          {hintElement}
          {maxLengthElement}
        </div>
      )}
      {children}
    </div>
  );
});

FieldInput.displayName = "FieldInput";

FieldInput.defaultProps = {
  type: "text"
};

FieldInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  valueMapper: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  hideMaxLength: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  helperMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  masked: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export default FieldInput;
