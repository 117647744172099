/* eslint-disable indent */
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import Typography from "../../../../../components/Typography";
import FieldPhone from "../../../../../components/form/FieldPhone";
import Form from "../../../../../components/form/Form/Form";
import Button from "../../../../../components/Button";
import { tickGreenIcon } from "../../../../../components/Icon";
import classes from "../ContactSection.module.css";
import cn from 'classnames';
import { PhoneVerifyCodeModal } from "components/Verification";

export const PhoneNumberForm = ({
  phoneNumber,
  handleSendPhoneVerificationCode,
  content,
  onSubmit,
  isVerified,
  setIsVerified,
  inVerifying,
  setInVerifying,
  toggleInEditing,
  inEditing,
  sectionInEditing
}) => {
  const leftpanel = {
    subtitle: content.phoneSectionTitle,
    value: phoneNumber,
    emptyValue: "",
    errorMsg: ""
  };

  const centralpanel = {
    action: "Verify"
  };

  const rightpanel = {
    action: "Update",
    emptyAction: "Add phone number"
  };

  const { handleSubmit, errors, control } = useForm({});

  const onClosePhoneVerifyCodeModal = useCallback(() => {
    setInVerifying(false);
  }, [setInVerifying]);

  const eddingContent = inEditing ? (
    <div className="row mt-16 mt-m-20">
      <div className="col col-m-6">
        <Controller
          name="phone_number"
          control={control}
          defaultValue={phoneNumber || ""}
          render={(renderProps) => (
            <FieldPhone
              {...renderProps}
              id="phone-save"
              errors={errors}
              className={`fw`}
            />
          )}
        />
      </div>

      <div className="col-auto col-m-6">
        <Button type="submit">Save</Button>
      </div>
    </div>
    ) : (
    <div>
      {phoneNumber && (
        <div className={`${classes.alignCenter} row`}>
          <div className="col-m-6">
            <Typography
              component="h6"
              variant="subtitle"
              size="s"
              className={classes.phoneText}
            >
              {leftpanel.value}
            </Typography>
          </div>

          <div className="col-m-6">
            <Button
              onClick={handleSendPhoneVerificationCode}
              disabled={isVerified}
              ghost
              className={classes.isVerifiedButton}
            >
              {!isVerified ? (
                centralpanel.action
              ) : (
                <div className={`${classes.isVerified}`}>
                  {tickGreenIcon}
                  <span>{content.phoneVerifiedLabel}</span>
                </div>
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} protect>
      <div className="t-m-2">
        <div className="devider-solid mt-28 mb-16 mt-m-36 mb-m-20" />
        <div className="fbc">
          <div>
            <Typography
              variant="subtitle"
              size="m"
              className={classes.sectionTitle}
            >
              {leftpanel.subtitle}
            </Typography>
          </div>
          <div className="mt-8 mt-m-2">
            <Button
              ghost
              onClick={toggleInEditing}
              disabled={sectionInEditing && !inEditing}
              className={cn('mb-2 mb-m-0 mt-m-4', classes.editButton)}
            >
              {inEditing
                ? "Cancel"
                : leftpanel.value && leftpanel.value.trim() !== ""
                ? rightpanel.action
                : rightpanel.emptyAction}
            </Button>
          </div>
        </div>

        {eddingContent}

        <PhoneVerifyCodeModal
          show={inVerifying}
          onHide={onClosePhoneVerifyCodeModal}
          setIsVerified={setIsVerified}
        />
      </div>
    </Form>
  );
};
