import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { RentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import { useLocationQueryParam } from "hooks/useLocationQueryParam";
import { useErrorsMessage } from "hooks/useRequestErrors";
import { rentalQuery } from "processes/Rentals/RentalDetails/queries/rentalQuery";
import { featureFlagsListQuery } from "queries/featureFlagsListQuery";
import Spinner, { SpinnerBack } from "components/Spinner/Spinner";
import { checkIMGlobalIntegrationIsEnabled } from "utils/featureFlags/checkIMGlobalIntegrationIsEnabled";
import { extractServerErrors } from "utils/extractErrors";
import PageError from "components/PageError/PageError";
import { useRentalActions } from "processes/Rentals/utils";

const extractRentalErrors = extractServerErrors("rental");

const RentalDetailsProvider = (props) => {
  /**
   * Rental payment modal for pre-defined rentals
   */
  const [showRentalPaymentModal, setShowRentalPaymentModal] = useState(false);

  const openRentalPaymentModal = useCallback(() => {
    setShowRentalPaymentModal(true);
  }, []);

  const closeRentalPaymentModal = useCallback(() => {
    setShowRentalPaymentModal(false);
  }, []);

  /*
   * Utils
   */

  const id = useLocationQueryParam("id");
  const rentalId = Number(id);
  const rentalIdVars = useMemo(() => ({ id: rentalId }), [rentalId]);

  /*
   * RV Query
   */

  const { loading, data, refetch } = useQuery(
    rentalQuery,
    {
      variables: rentalIdVars,
      fetchPolicy: "no-cache"
    }
  );


  const { data: featureFlagsListData } = useQuery(featureFlagsListQuery, {
    variables: {
      features: ["IMGlobal Integration Is Enabled"]
    },
    fetchPolicy: "no-cache"
  });
  const rental = data?.rental?.rental;
  const errors = extractRentalErrors({ data });
  const error = useErrorsMessage(errors);
  const isIMGlobalIntegrationIsEnabled = checkIMGlobalIntegrationIsEnabled(featureFlagsListData);

  /*
   * Actions
   */
  const {
    executeAction,
    isLoadingAction,
    actionsModal
  } = useRentalActions({ rental, onSuccess: refetch, isIMGlobalIntegrationIsEnabled });

  const initialAction = useLocationQueryParam("action");
  useEffect(() => {
    if (initialAction && rental) {
      executeAction(initialAction);
    }
  }, [executeAction, initialAction, rental]);

  /*
   * Result Value
   */

  const value = useMemo(() => ({
    loading,
    rental,
    errors,
    showRentalPaymentModal,
    openRentalPaymentModal,
    closeRentalPaymentModal,
    refetch,
    executeAction,
    isIMGlobalIntegrationIsEnabled,
    data
  }), [loading, rental, errors, showRentalPaymentModal, openRentalPaymentModal, closeRentalPaymentModal, refetch, executeAction, isIMGlobalIntegrationIsEnabled, data]);

  if (error) {
    return <PageError error={error} />;
  }

  if (!rentalId) {
    return <Redirect to="/rentals" />;
  }

  if (!rental) {
    return <Spinner position="absolute" />;
  }

  return (
    <div>
      <RentalDetailsContext.Provider value={value} {...props} />
      {isLoadingAction && (
        <SpinnerBack />
      )}
      {actionsModal}
    </div>
  );
};

RentalDetailsProvider.propTypes = {};

export default RentalDetailsProvider;
