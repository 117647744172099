import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { PhotosContainer } from "./PhotosContainer";
import { ListingContext } from "../../ListingContext";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { photosMutation } from "../../mutations/photosMutation";
import { rvImageFromGarageUpload } from "./mutations/rvImageFromGarageUpload";
import { rvHasNewGaragePhotosReset } from "./mutations/rvHasNewGaragePhotosReset";
import { garagePhotosQuery } from "./queries/garagePhotosQuery";
import { photoInstantRemoveMutation } from "../../mutations/photoInstantRemoveMutation";
import { photoInstantOrderMutation } from "../../mutations/photoInstantOrderMutation";
import { photoImageUploadMutation } from "components/Listing/mutations/photoImageUploadMutation";
import usePhotoUpload from "hooks/usePhotoUpload";
import { usePhotoInstant } from "hooks/usePhotoInstant";
import { pipe, prop, map, filter, path, pick, defaultTo } from "ramda";
import {
  extractServerErrors,
  extractSuccess
} from "../../../../utils/extractErrors";
import { photosImageCropMutation } from "../../mutations/photosImageCropMutation";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { rvPhotosSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import debounce from "lodash/debounce";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";

const processInitialData = pipe(prop("images"));
const normalizeValues = pipe(
  map((v) => parseInt(v.id, 10)),
  filter((v) => v)
);
const extractRvHasNewGaragePhotosResetSuccess = extractSuccess("rv_has_new_garage_photos_reset");
const extractRvImageCropSuccess = extractSuccess("rv_image_crop");
const extractRvImageCropResult = path(["data", "rv_image_crop", "image"]);
const extractImageUploadErrors = extractServerErrors("rv_image_upload");
const extractRvUpdateErrors = extractServerErrors("rv_update");
const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "photos"),
  map(pick(["field", "message"]))
);

const PhotosDataProvider = (props) => {
  const {
    context,
    updateContext,
    refetchRvData,
    currStep,
    prevStep,
    nextStep
  } = props;

  useFooterVisible(false);
  const isSegmentSent = useRef(null);
  const isUploadGaragePhotos = useRef(false);
  const [globalError, setGlobalError] = useState(null);
  const [garagePhotos, setGaragePhotos] = useState([]);
  const [variablesPhotoOrder, setVariablesPhotoOrder] = useState(null);
  const { id: initialId, rv_garage_id: rvGarageId, is_new_photos_from_garage: isNewPhotosFromGarage } = context.initialData;
  const isFooterVisible = context?.isFooterVisible;
  const isGarage = context?.isGarage;
  const history = useHistory();

  const [
    loadGaragePhotos,
    {
      data: garagePhotosData,
      error: garagePhotosError,
      loading: garagePhotosLoading
    }] = useLazyQuery(garagePhotosQuery, {
    fetchPolicy: "no-cache"
  });

  const [rvHasNewGaragePhotosResetMutation] = useMutation(rvHasNewGaragePhotosReset);
  const [cropMutation, cropMutationResult] = useMutation(photosImageCropMutation);
  const [saveStep, saveStepResult] = useMutation(photosMutation);

  const { handleRvImageFromGarageUpload, rvImageFromGarageUploadLoading } = usePhotoUpload(
    rvImageFromGarageUpload,
    'rv_image_from_garage_upload'
  );

  const { handlePhotoImageUpload, photoImageUploadLoading } = usePhotoUpload(
    photoImageUploadMutation,
    'rv_image_instant_upload'
  );
  const [handlePhotoInstantRemove] = usePhotoInstant(
    photoInstantRemoveMutation,
    'rv_image_instant_remove',
    setGlobalError
  );
  const [handlePhotoInstantOrder] = usePhotoInstant(
    photoInstantOrderMutation,
    'rv_images_instant_order',
    setGlobalError
  );

  const debouncedPhotoInstantOrder = useMemo(
    () => debounce(handlePhotoInstantOrder, 2000),
    [handlePhotoInstantOrder]
  );

  const [photoImageUploadErrors, setPhotoImageUploadErrors] = useState([]);

  const handleBeforeunload = useCallback(
    () => {
      if (variablesPhotoOrder) handlePhotoInstantOrder(variablesPhotoOrder);
    },
    [handlePhotoInstantOrder, variablesPhotoOrder]
  );
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeunload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [handleBeforeunload]);

  const handleUpload = useCallback(async (values, deleted) => {
    const { images, errors } = await handlePhotoImageUpload(values, initialId);
    setPhotoImageUploadErrors(errors);

    const currentImages = context.initialData.images
      .filter((item) => !deleted.includes(parseInt(item.id, 10)));

    updateContext({
      initialData: {
        ...context.initialData,
        images: [
          ...currentImages,
          ...images
        ]
      }
    });

    refetchRvData({ variables: { id: initialId } });
    if (errors.length > 0) {
      setGlobalError(errors.join(", "));
    }
  }, [
    context.initialData,
    handlePhotoImageUpload,
    initialId,
    updateContext,
    setGlobalError,
    setPhotoImageUploadErrors,
    refetchRvData
  ]);

  const handleDelete = useCallback(
    async (id, nextItems) => {
      const variables = {
        rv_id: initialId,
        photo_id: Number(id)
      };
      const errors = await handlePhotoInstantRemove(variables);
      if (!nextItems.length) {
        refetchRvData({ variables: { id: initialId } });
      }
      if (!errors) {
        updateContext({
          initialData: {
            ...context.initialData,
            images: [
              ...nextItems
            ]
          }
        });
        isUploadGaragePhotos.current = true;
      }
    },
    [context.initialData, handlePhotoInstantRemove, initialId, updateContext, refetchRvData]
  );

  const handleReorder = useCallback(
    async (ids) => {
      const variables = {
        rv_id: initialId,
        photos_order: normalizeValues(ids),
        timestamp: Date.now() / 1000
      };
      setVariablesPhotoOrder(variables);
      await debouncedPhotoInstantOrder(variables);

      updateContext({
        initialData: {
          ...context.initialData,
          images: [
            ...ids
          ]
        }
      });
    },
    [context.initialData, debouncedPhotoInstantOrder, initialId, updateContext]
  );

  const handleCrop = useCallback(
    async (values, imageCaptionValue) => {
      const response = await cropMutation({
        variables: {
          ...values,
          caption: imageCaptionValue
        }
      });

      if (extractRvImageCropSuccess(response)) {
        const nextImages = context.initialData.images.map((image) => {
          if (image.id === String(values.id)) {
            return extractRvImageCropResult(response);
          }

          return image;
        });

        updateContext({
          initialData: {
            ...context.initialData,
            images: nextImages
          }
        });
      }
    },
    [cropMutation, context.initialData, updateContext]
  );

  const handleSave = useCallback(
    async ({ toNextStep = true }) => {
      if (toNextStep) {
        pushGoogleAnalytics("listVehiclePhotos");
        const rv = context?.initialData;
        handleListingSegment(rv, "Lead Form Step Completed", "RV Photos", 4, {
          cust_asset_class: rv?.class,
          cust_asset_id: String(rv?.id),
          cust_asset_make: rv?.make,
          cust_asset_model: rv?.model,
          cust_asset_year: String(rv?.year),
          cust_asset_length: rv?.length?.inches_total,
          cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
          cust_asset_sleepnumber: rv?.sleeps,
          cust_asset_slideouts: rv?.slide_outs,
          cust_asset_weight: rv?.weight
        });
        history.push(`/listing/${nextStep.path}?id=${initialId}`, {
          prevStep: currStep
        });
      }
    },
    [context?.initialData, history, nextStep.path, initialId, currStep]
  );

  const handleBack = useCallback(() => {
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);

  const handleGaragePhotosContinue = useCallback(async (photoIds = [])  => {
    const { errors } = await handleRvImageFromGarageUpload(photoIds, initialId);

    setPhotoImageUploadErrors(errors);

    refetchRvData({ variables: { id: initialId } });
    isUploadGaragePhotos.current = true;

    if (errors.length > 0) {
      setGlobalError(errors.join(", "));
    }
  }, [handleRvImageFromGarageUpload, initialId, refetchRvData]);

  const handleShowGaragePhotosModal = useCallback(async() => {

    if (isNewPhotosFromGarage) {
      const handleRvHasNewGaragePhotosReset = async() => {
        const response = await rvHasNewGaragePhotosResetMutation({
          variables: {
            rv_id: initialId
          },
          fetchPolicy: "no-cache"
        });

        if (extractRvHasNewGaragePhotosResetSuccess(response)) {
          refetchRvData({ variables: { id: initialId } });
        }
      };

      handleRvHasNewGaragePhotosReset();
    }

    if (rvGarageId && isUploadGaragePhotos?.current) {
      loadGaragePhotos({
        variables: {
          rv_garage_id: rvGarageId
        },
        fetchPolicy: "no-cache"
      });
    }
  }, [
    initialId,
    isNewPhotosFromGarage,
    loadGaragePhotos,
    refetchRvData,
    rvGarageId,
    rvHasNewGaragePhotosResetMutation
  ]);

  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  useEffect(() => {
    if (!isSegmentSent.current) {
      const rv = context?.initialData;
      handleListingSegment(context?.initialData, "Lead Form Step Viewed", "RV Photos", 4, {
        cust_asset_class: rv?.class,
        cust_asset_id: String(rv?.id),
        cust_asset_make: rv?.make,
        cust_asset_model: rv?.model,
        cust_asset_year: String(rv?.year),
        cust_asset_length: rv?.length?.inches_total,
        cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
        cust_asset_sleepnumber: rv?.sleeps,
        cust_asset_slideouts: rv?.slide_outs,
        cust_asset_weight: rv?.weight
      });
      isSegmentSent.current = true;
    }
  }, [context?.initialData]);

  useEffect(() => {
    if (garagePhotosData?.garage_photos) {
      setGaragePhotos(garagePhotosData?.garage_photos);
      isUploadGaragePhotos.current = false;
    }

    if (garagePhotosError) {
      setGaragePhotos([]);
    }
  }, [
    garagePhotosData,
    garagePhotosError,
    initialId,
    refetchRvData,
    rvHasNewGaragePhotosResetMutation
  ]);

  useEffect(() => {
    if (rvGarageId) {
      loadGaragePhotos({
        variables: {
          rv_garage_id: rvGarageId
        },
        fetchPolicy: "no-cache"
      });
    }
  }, [loadGaragePhotos, rvGarageId]);

  const publishErrors = filterPublishErrorsBySection(context.errors);

  const stepErrors = [
    ...extractImageUploadErrors(photoImageUploadErrors),
    ...extractRvUpdateErrors(saveStepResult),
    ...extractRvUpdateErrors(cropMutationResult)
  ];

  return (
    <>
      <PhotosContainer
        initialData={processInitialData(context.initialData)}
        isGarage={isGarage}
        garagePhotos={garagePhotos}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        onDelete={handleDelete}
        onOrder={handleReorder}
        onUpload={handleUpload}
        onCrop={handleCrop}
        onGaragePhotosContinue={handleGaragePhotosContinue}
        onShowGaragePhotosModal={handleShowGaragePhotosModal}
        onCloseGaragePhotosModal={() => {}}
        loading={
          photoImageUploadLoading ||
          saveStepResult.loading ||
          cropMutationResult.loading
        }
        requestErrors={publishErrors.length ? publishErrors : stepErrors}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        prevStep={prevStep}
        nextStep={nextStep}
      />
      {globalError && (
        <NotificationPopover
          show
          status="error"
          text={globalError}
          onClose={() => {
            setGlobalError(null);
          }}
          bottomIndent={{
            hasIndent: isFooterVisible,
            size: 'Xlarge'
          }}
        />
      )}
      {(photoImageUploadLoading ||
        garagePhotosLoading ||
        rvImageFromGarageUploadLoading ||
        cropMutationResult.loading ||
        saveStepResult.loading) && <SpinnerBack />}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const rvPhotosSectionContentPredicate = everyKey(["rvPhotosSectionContent"]);
export const Photos = ({ currStep, prevStep, nextStep }) => (
  <ListingContext.Consumer>
    {(context) => (
      <ContentLoader
        query={rvPhotosSectionContentQuery}
        predicate={rvPhotosSectionContentPredicate}
        render={() => (
          <PhotosDataProvider
            context={context[0]}
            updateContext={context[1]}
            refetchRvData={context[2]}
            currStep={currStep}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        )}
      />
    )}
  </ListingContext.Consumer>
);
