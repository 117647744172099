import { useEffect, useRef } from "react";

/**
 * To prevent sticking scroll at top, we need to return scroll position back,
 * when new messages are added (tracking loading state)
 */
export const useMessagesScrollFixOnPagination = (
  listRef,
  { loadingMore, thread }
) => {
  const scrollHeight = useRef();
  const list = listRef.current;

  useEffect(() => {
    if (loadingMore) {
      scrollHeight.current = list.scrollHeight;
    } else if (scrollHeight.current) {
      if (list.scrollTop === 0) {
        const newScroll = list.scrollHeight - scrollHeight.current;
        if (newScroll > 0) {
          list.scrollTop = newScroll;
        }
      }
      scrollHeight.current = null;
    }
  }, [list, loadingMore]);

  useEffect(() => {
    scrollHeight.current = null;
  }, [thread]);
};
