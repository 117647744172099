import { gql } from "@apollo/client";

export const favoriteMutation = gql`
  mutation favorite(
    $id: Int!,
    $is_favorite: Boolean!,
  ) {
    favorite(rv: $id, set: $is_favorite) {
      result {
        success
        errors {
          field
          message
        }
      }
    }
  }
`;
