import Button from "components/Button";
import React from "react";
import { whiteLightningIcon } from "components/Icon/index";
import classes from "./BookingContinueButton.module.css";

export const BookingContinueButton = (props) => {
  const {
    disabled,
    contentText,
    buttonText,
    onClick,
    classesParent = {},
    isInstantRental,
    rentalType,
    deliveryOnlyData = {}
  } = props;

  const isDeliveryOnlyLocationSelected = deliveryOnlyData?.locationSelected;
  const disabledButton = rentalType === 2 && !isDeliveryOnlyLocationSelected;

  return (
    <div
      className={`${classes.continueButtonContainer} ${classesParent.continueButtonContainer} container l-s-0 r-s-0`}
    >
      <div className={`row ${classesParent.continueButtonContainerInner}`}>
        <div
          className={`${classes.continueButtonInner} ${classesParent.continueButtonInner} col-12 col-s-8 col-m-12`}
        >
          <div className={`${classesParent.continueButton}`}>
            <Button
              disabled={disabled || disabledButton}
              onClick={onClick}
              className={`${classes.buttonStyle} ${classesParent.buttonStyle}`}
              label={buttonText}
              icon={isInstantRental && whiteLightningIcon}
              iconPosition="after"
            />
          </div>
          {contentText && (
            <div className={`${classesParent.continueText}`}>
              <p
                className={`${classes.title} ${classesParent.continueButtonTitle}`}
              >
                {contentText}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
