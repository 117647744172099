import React, { useEffect, useMemo, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { createRowRenderer } from "./createRowRenderer";
import { List, CellMeasurerCache } from "react-virtualized";

class ListCache extends CellMeasurerCache {
  updateWidth(defaultWidth) {
    this._defaultWidth = defaultWidth;

    this.clearAll();

    return this;
  }

  updateMinHeight(height) {
    this._minHeight = height;
    this._defaultHeight = height;

    return this;
  }
}

const cache = new ListCache({
  minHeight: 300,
  defaultHeight: 300,
  defaultWidth: 300,
  fixedWidth: true,
  keyMapper(rowIndex) {
    return rowIndex;
  }
});

const FixedWidthList = (props) => {
  const {
    data,
    width,
    height,
    isScrolling,
    onChildScroll,
    scrollTop,
    columnCount,
    rowCount,
    isInFavorites,
    onChangeFavorite,
    searchData,
    mapOpen,
    breakpoints,
    userId
  } = props;

  const listRef = useRef();

  useEffect(() => {
    cache.updateMinHeight(300).updateWidth(width);
  }, [width, mapOpen]);

  const rowHeight = useCallback(({ index }) => cache.getHeight(index), [cache]);

  const rowRenderer = useMemo(() => {
    return createRowRenderer({
      data,
      cache,
      columnCount,
      isInFavorites,
      onChangeFavorite,
      searchData,
      mapOpen,
      breakpoints,
      userId
    });
  }, [
    data,
    columnCount,
    isInFavorites,
    onChangeFavorite,
    searchData,
    mapOpen,
    breakpoints,
    userId
  ]);

  return (
    <List
      key={width}
      ref={listRef}
      autoHeight
      width={width}
      height={height}
      isScrolling={isScrolling}
      onScroll={onChildScroll}
      scrollTop={scrollTop}
      rowCount={rowCount}
      rowHeight={rowHeight}
      rowRenderer={rowRenderer}
    />
  );
};

FixedWidthList.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  isScrolling: PropTypes.bool,
  onChildScroll: PropTypes.func,
  scrollTop: PropTypes.number,
  columnCount: PropTypes.number,
  rowCount: PropTypes.number,
  isInFavorites: PropTypes.func,
  onChangeFavorite: PropTypes.func,
  searchData: PropTypes.object,
  mapOpen: PropTypes.bool
};

export default FixedWidthList;
