import classnames from "classnames";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import React, { useRef, useState } from "react";
import BookingModalMenu from "./components/Menu/BookingModalMenu";
import AsideRvInfo from "./components/AsideRvInfo/AsideRvInfo";
import { useBookingModalContext } from "./BookingModalContext";
import Spinner from "components/Spinner/Spinner";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import { useScrollShadow } from "processes/Booking/components/hooks/useScrollShadow";
import { NBSP_CODE } from "utils/basic";
import style from "./BookingModalContent.module.css";

const BookingModalContent = ({ show, onClose }) => {
  const ref = useRef(null);
  const {
    steps,
    loading,
    session,
    rvData,
    stepIndex,
    currentStepIndex,
    setStepIndex,
    onClickStep,
    handleOnClickStep,
    completedSteps,
    isModalHidden,
    setShowAsideRvInfoDetails,
    rentalType
  } = useBookingModalContext();

  const currentStep = steps[stepIndex];
  const isPaymentMethodStep = stepIndex === 1;
  const isMaxMedium = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isMinHeightMedium = useMatchMedia({ mediaQuery: "(min-width: 1024px) and (min-height: 730px)" });
  const mdlRef = useScrollBarWidth();
  const stepContainerRef = useRef(null);
  const scrollShadowData = useScrollShadow(stepContainerRef, loading);

  return (
    <Modal
      showCross
      show={show}
      onClose={onClose}
      modalClassnames={classnames(
        style.modal
      )}
      modalWrapClassnames={classnames(
        style.modalRoot
      )}
      modalCrossButtonClassname={style.crossButton}
      {...(isMaxMedium || !isMinHeightMedium ? { variant: "full-screen" } : null)}
      mdlRef={mdlRef}
    >
      <div ref={ref} className={style.modalContent}>
        {!loading && rvData && (
          <main className="">
            <div className={classnames("row t-16 t-m-0", style.wrap)}>
              <div
                className={classnames(
                  style.mobileHeader,
                  "t-16 t-m-20 b-m-20 p-12 fw"
                )}
              >
                <Typography
                  variant="subtitle"
                  size="m"
                  weight="normal"
                  className="dn-l db"
                >
                  {`Request to book${NBSP_CODE}`}
                </Typography>
                <Typography
                  variant="subtitle"
                  size="m"
                  weight="bold"
                  className="dn-l db"
                >
                  {rvData.rv_name}
                </Typography>
              </div>
              <AsideRvInfo
                rvData={rvData}
                startDate={session.departureDate}
                endDate={session.returnDate}
                fullAddress={session.location}
                rentalType={rentalType}
              />
              <section
                className={classnames(
                  style.content,
                  "df fdc mr-0 ml-0 mr-l-32 ml-l-32"
                )}
              >
                <BookingModalMenu
                  steps={steps}
                  currentStep={currentStepIndex}
                  completedSteps={completedSteps}
                  onClickItem={handleOnClickStep}
                  hasScrollShadow={scrollShadowData.top}
                  setShowAsideRvInfoDetails={setShowAsideRvInfoDetails}
                />
                <div ref={stepContainerRef} className={classnames(style.stepsContainer, isPaymentMethodStep && style.isPaymentMethodStep, "df fdc fw ")}>
                  {steps.map((i, index) => {
                    const isActive = currentStepIndex === index;
                    return (
                      <div
                        key={i.id}
                        className={classnames(
                          isActive ? style.stepActive : style.stepInactive
                        )}
                      >
                        <i.component
                          isVisible={isActive}
                          modalRef={ref}
                          hasScrollShadow={scrollShadowData.bottom}
                          isPaymentMethodStep={isPaymentMethodStep}
                        />
                      </div>
                    );
                  })}
                </div>
              </section>
            </div>
          </main>
        )}
        {loading && (
          <div className="t-60 b-60 fcc">
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BookingModalContent;
