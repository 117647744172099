import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Typography from "components/Typography";
import Button from "components/Button";
import ReferUsersModal from "components/ReferUsersModal/ReferUsersModal";
import ReferralSuccessNotification from "components/ReferralSuccessNotification/ReferralSuccessNotification";

import { contentQuery } from "../../../../queries";
import { extractText } from "utils/extractText";
import { extractHtml } from "utils/exctractHtml";

import classes from "./ReferralAsideInfo.module.css";

const ReferralAsideInfo = () => {
  const [content, setContent] = useState({
    listing_notification_title: "",
    listing_notification_text: "",
    listing_notification_accept_button:"",
    referrals_list_congratulations_title: "",
    referrals_list_congratulations_text: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section: "referral_content"
    },
    fetchPolicy: "no-cache"
  });

  const openReferModal = () => {
    setShowModal(true);
  };

  const closeReferModal = () => {
    setShowModal(false);
  };

  const openSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (!loading) {
      setContent({
        listing_notification_title: extractText(data.content.text, "listing_notification_title"),
        listing_notification_text: extractHtml(data.content.text, "listing_notification_text"),
        listing_notification_accept_button: extractText(data.content.text, "listing_notification_accept_button"),
        referrals_list_congratulations_title: extractText(data.content.text, "referrals_list_congratulations_title"),
        referrals_list_congratulations_text: extractText(data.content.text, "referrals_list_congratulations_text")
      });
    }
  }, [loading, data]);

  if (loading) return null;

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <Typography
          className={classes.title}
          component="h6"
          variant="subtitle"
          size="m"
        >
          {content.listing_notification_title}
        </Typography>
      </div>
      <div className={classes.block}>
        <div className={classes.description} dangerouslySetInnerHTML={{ __html: content.listing_notification_text }} />

        <div className={classes.cta}>
          <Button
            label={content.listing_notification_accept_button}
            onClick={openReferModal}
          />
        </div>
      </div>

      <ReferUsersModal
        show={showModal}
        onClose={closeReferModal}
        onSuccess={openSuccessModal}
      />

      <ReferralSuccessNotification
        show={showSuccessModal}
        content={content}
        onClose={closeSuccessModal}
      />
    </div>);
};

export default ReferralAsideInfo;
