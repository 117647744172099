import React from "react";
import classnames from "classnames";
import Typography from "components/Typography";
import classes from "./CharactList.module.css";

export const CharactList = ({ data,  variant = 'default' }) => {

  const charactListItems = data.map(({ icon, title, numb }) => (
    <li
      className={classnames(
        classes.charactListItem,
        variant === 'default' && ``
      )}>
      <div className={`${classes.charactListItemInner} tac t-16 b-16 t-m-20 b-m-20 `}>
        <div className={`${classes.charactListImgContainer} tac mt-8 mb-4`}>
          <img src={icon} alt={icon} className={classes.charactListImg} />
        </div>
        <Typography variant="caption" className={classes.title}>
          <span className={classes.titleText}>{title}</span>
        </Typography>
        <Typography variant="subtitle" size="s" weight="bold"  className={classes.numb}>
          {numb}
        </Typography>
      </div>
    </li>
  ));
  return (
    <div className={classes.container}>
      <ul className={`${classes.charactList} ui-menu ${classes[variant]}`} >
        {charactListItems}
      </ul>
    </div>
  );
};

// CharactList.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

