import { gql } from "@apollo/client";

export const chatOpponentQuery = gql`
  query($id: Int!) {
    user(id: $id) {
      id
      short_name
      avatar {
        id
        srcset {
          url
        }
      }
    }
  }
`;

export const chatOpponentsQuery = gql`
  query {
    chat_opponents {
      id
      short_name
      avatar {
        id
        srcset {
          url
        }
      }
    }
  }
`;

export const chatsQuery = gql`
  query {
    chats {
      opponent {
        id
        short_name
        avatar {
          id
          srcset {
            url
          }
        }
      }
    }
  }
`;
