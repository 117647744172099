import React from "react";
import Typography from "components/Typography";
import RcCollapse from "components/RcCollapse";

export const Header = (props) => {

  const { proTipsSectionTitle, proTipsSectionText, sectionTitle } = props;

  return (
    <div>
      <div className="mb-m-20 mb-xl-8">
        <Typography
          variant="headline"
          size="s"
          className=""
        >
          {sectionTitle}
        </Typography>
      </div>

      <div className="t-16 dn-m">
        <RcCollapse
          header={proTipsSectionTitle}
          content={proTipsSectionText}
        />
      </div>
    </div>
  );
};
