import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import classes from "./InfoTexts.module.css";

const InfoTexts = (props) => {
  const { className, label, value } = props;

  if (!value) {
    return null;
  }

  return (
    <div className={classnames(className, classes.root)}>
      <Typography className={classes.label} variant="body" size="s">
        {label}
      </Typography>
      <Typography className={classes.value} variant="body" size="l">
        {value}
      </Typography>
    </div>
  );
};

InfoTexts.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default InfoTexts;
