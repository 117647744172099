import React from "react";
import PropTypes from "prop-types";
import desctopBadge from "assets/images/badge-goodsam-user-desktop.svg";
import mobileBadge from "assets/images/badge-goodsam-mobile.svg";
import avatarEmpty from "assets/images/avatar-placeholder.svg";
import classes from "./RvUserAvatar.module.css";

const RvUserAvatar = ({ avatar: { src, alt } = {}, hasBadge }) => (
  <div className={classes.avatar}>
    <div>
      <img
        className={classes.avatarImg}
        src={src || avatarEmpty}
        alt={alt}
      />
    </div>
    {true && (
      <div className={`${classes.avatarBadgeContainer}`}>
        <div className={`${classes.avatarBadgeContent}`}>
          <picture>
            <source
              srcSet={mobileBadge}
              media="(max-width: 767.98px)"
            />
            <source
              srcSet={mobileBadge}
              media="(min-width: 1024px) and (max-width: 1439.98px)"
            />
            <img src={desctopBadge} alt="Good Sam" />
          </picture>
        </div>
      </div>
    )}
  </div>
);

RvUserAvatar.propTypes = {
  avatar: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  hasBadge: PropTypes.bool
};

export default RvUserAvatar;
