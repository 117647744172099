import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { TAB_IDS } from "processes/MyAccount/steps/TransactionHistory/constants";
import { downloadRedIcon } from "components/Icon";
import { TaxCardList } from "components/TaxCard";
import Typography from "components/Typography";
import Button from "components/Button";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { MoneyType } from "types/money";
import classes from "./TaxCardContainer.module.css";

export const TaxCardContainer = ({
  data = [],
  total,
  taxes,
  expected,
  downloadUrl,
  tab
}) => {

  return (
    <div className={classes.container}>
      <div
        className={classnames(
          classes.header,
          "mb-16 mb-m-20"
        )}
      >
        <div className="fbc mb-m-8">
          <div>
            <Typography
              variant='subtitle'
              size='m'
              className={classes.totalText}
            >
              {
                `${tab === TAB_IDS.COMPLETED
                  ? 'Paid out total'
                  : 'Pending payouts'}:`
              }
            </Typography>
          </div>

          <div className="fbc">
            <Typography
              variant='subtitle'
              size='m'
              className={classes.totalPriceContainer}
            >
              {
                tab === TAB_IDS.COMPLETED
                  ? <FormattedPrice value={total} className={classes.totalPrice} isSubBold />
                  : <FormattedPrice value={expected} className={classes.totalPrice} isSubBold />
              }
            </Typography>
            <div
              className={classnames(
                classes.downloadIcon,
                "df mt-4 ml-4"
              )}
            >
              <Button
                icon={downloadRedIcon}
                inverse
                label=""
                size="small"
                className={classes.downloadButton}
                href={downloadUrl}
                download
              />
            </div>
          </div>
        </div>

        {taxes && (
          <>
            <div className="devider-dashed mt-4 mb-4"></div>

            <div className="fbc mt-m-8">
              <div>
                <Typography
                  variant='body'
                  size="l"
                  className={classes.taxesText}
                >
                  Total taxes:
                </Typography>
              </div>

              <div>
                <Typography
                  variant='body'
                  size="l"
                >
                  <FormattedPrice value={taxes} isSubBold className={classes.taxesPrice} />
                </Typography>
              </div>
            </div>
          </>
        )}


      </div>

      <div className={classes.content}>
        <TaxCardList data={data} tab={tab} />
      </div>
    </div>
  );
};

TaxCardContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      billing_amount: MoneyType,
      total_earning: MoneyType,
      expected_payout: MoneyType,
      owner_earns: MoneyType,
      taxes: MoneyType,
      rental_fee: MoneyType,
      delivery_fee: MoneyType,
      cleaning_fee: MoneyType,
      disposal_fee: MoneyType,
      add_on_fee: MoneyType,
      mileage_overage_fee: MoneyType,
      generator_hours_overage_fee: MoneyType,
      mileage_overage: PropTypes.number,
      generator_hours_overage: PropTypes.number,
      payout_count: PropTypes.number,
      renter_id: PropTypes.number,
      rental_id: PropTypes.number,
      nights: PropTypes.number,
      your_share: PropTypes.string,
      billing_date: PropTypes.string,
      scheduled_date: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      rv_name: PropTypes.string,
      rv_url: PropTypes.string,
      departure_date: PropTypes.string,
      return_date: PropTypes.string,
      tab: PropTypes.string
    })
  ),
  taxes: MoneyType,
  total: MoneyType,
  expected: MoneyType,
  downloadUrl: PropTypes.string,
  tab: PropTypes.string
};
