import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "components/Typography";
import Tag from "components/Tag";
import Button from "components/Button";
import { chatIcon } from "components/Icon";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { useStartChatAction } from "components/chat/api";
import { useHistory } from "react-router-dom";
import Action from "processes/Rentals/components/Action";
import {
  getTagTypeFromStatus,
  isUserOwner,
  isUserRenter,
  getPickUpLocationTitle,
  getRentalDetailsPath
} from "processes/Rentals/utils";
import { formatApiDate } from "utils/dateTime";
import { isInvalidOwnerStatus } from "processes/Rentals/utils";
import { ListItemType } from "../../types";
import classes from "./ListItem.module.css";

const ListItem = (props) => {
  const { push } = useHistory();
  const { className, item } = props;
  const isMinMedium = useMatchMedia({ minWidth: MQ_BP.medium });
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const isMaxMedium = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isMinXLarge = useMatchMedia({ minWidth: MQ_BP.xLarge });

  const isRenter = isUserRenter(item.role);
  const isOwner = isUserOwner(item.role);
  const rentalTypeValue = props?.item?.rental_type?.value || "Standard";

  const { startChat, startChatLoading } = useStartChatAction(
    isRenter ? item.owner.id : item.renter.id
  );

  const status = item.status && (
    <Tag
      className={classes.status}
      size="small"
      text={item.status.name}
      type={getTagTypeFromStatus(item.status)}
    />
  );

  const additionalStatus = item.additional_status && (
    <Tag
      className={classes.additionalStatus}
      size="small"
      text={item.additional_status.value}
      type="error"
    />
  );

  const statuses = (
    <div className={classnames(classes.statuses, "fss fdc")}>
      {status}
      {additionalStatus}
    </div>
  );

  const header = (
    <div className={classnames(classes.mainHeader, "df")}>
      <Typography
        className={classes.title}
        variant="subtitle"
        size="m"
        component="div"
      >
        {!isMaxSmall && <Action item={item} />}
        <div className="fw-500">{item.rv.rv_name}</div>
      </Typography>
      {!isMaxSmall && statuses}
    </div>
  );

  const additionalInfo = Boolean(item.additional_info) && (
    <Typography className={classes.additionalInfo} variant="body" size="l">
      {item.additional_info}
    </Typography>
  );

  const price = isRenter
    ? item.price.rental_total?.user_friendly
    : item.price.owner_earns?.user_friendly;
  const dates = [
    formatApiDate(item.departure_date),
    formatApiDate(item.return_date)
  ].join(" - ");
  const duration = `${item.duration} ${item.duration > 1 ? "nights" : "night"}`;

  const pushRental = useCallback(() => {
    push(getRentalDetailsPath(item.id));
  }, [item]);

  const pickUpLocationLabel = getPickUpLocationTitle(item);

  const infos = [
    { label: "Pick-up/Drop off", value: dates },
    { label: "Rental type", value: rentalTypeValue },
    {
      label: pickUpLocationLabel,
      value: item.pick_up_location,
      className: classes.infoLocation
    }
  ];

  return (
    <div
      className={classnames(className, classes.root, "df")}
      onClick={isMaxSmall ? pushRental : undefined}
    >
      <div
        className={classnames(
          classes.main,
          "t-16 b-16 t-m-20 r-m-20 b-m-20 l-m-24 l-l-20"
        )}
      >
        {!isMinMedium && header}
        <div className="df t-4 t-m-8 t-l-0">
          <div className={classnames(classes.photo, "mr-16 mr-m-32")}>
            <img src={item.rv.title_image} alt={item.rv.rv_name} />
          </div>
          <div className={classes.mainTexts}>
            {isMaxSmall && (
              <>
                {statuses}
                <Typography variant="body" size="l" className={classes.date}>
                  {dates}
                </Typography>
                {additionalInfo}
              </>
            )}
            {!isMaxSmall && (
              <>
                {isMinMedium && header}
                {isMinXLarge ? (
                  <div className={classnames(classes.infos, "t-40")}>
                    {infos.map((i) => (
                      <InfoRow
                        key={i.label}
                        className={i.className}
                        label={i.label}
                        value={i.value}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="df">
                    <div className="mr-16">
                      {infos.map((i) => (
                        <InfoLabel label={i.label} key={i.label} />
                      ))}
                    </div>
                    <div>
                      {infos.map((i) => {
                        if (i?.label === "Pick-up location") {
                          return (
                            <InfoValue
                              value={i.value}
                              className={classes.infoLocation}
                              key={i.label}
                            />
                          );
                        }
                        return <InfoValue value={i.value} key={i.label} />;
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {!isMaxSmall && (
        <div
          className={classnames(
            isMaxMedium ? "devider-dashed" : "devider-dashed--vertical",
            classes.devider
          )}
        />
      )}
      {!isMaxSmall && (
        <div
          className={classnames(
            classes.end,
            "df t-20 r-20 b-20 l-24 r-l-32 b-l-32 l-l-32"
          )}
        >
          <div className={classes.endContent}>
            {price && (
              <div className={classes.priceContainer}>
                <Typography
                  className={classes.price}
                  variant="headline"
                  size="s"
                >
                  {`$${price}`}
                </Typography>
              </div>
            )}

            <div className={classes.additionalInfoContainer}>
              {additionalInfo}
            </div>

            <div className={classes.chatButtonContainer}>
              <Button
                className={classnames(classes.chatButton, {
                  [classes.hidden]: isInvalidOwnerStatus(item)
                })}
                secondary
                icon={chatIcon}
                iconPosition="after"
                onClick={startChat}
                disabled={startChatLoading}
              />
            </div>

            <div className={classes.detailsButtonContainer}>
              <Button
                className={classes.detailsButton}
                onClick={pushRental}
                label={item.details_button.value}
                secondary={item.details_button.key === "1"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const InfoLabel = ({ label }) => {
  return (
    <Typography className={classes.infoLabel} variant="body" size="l">
      {label}
    </Typography>
  );
};

const InfoValue = ({ value, className }) => {
  return (
    <Typography
      className={`${classes.infoValue} ${className} fw-500`}
      variant="body"
      size="l"
    >
      {value}
    </Typography>
  );
};

const InfoRow = ({ className, label, value }) => {
  return (
    <div className={classnames(className, classes.info, "df l-32 r-32")}>
      <InfoLabel label={label} />
      <InfoValue value={value} />
    </div>
  );
};

ListItem.propTypes = {
  className: PropTypes.string,
  item: ListItemType.isRequired
};

export default memo(ListItem);
