/*
 * Sort Utils
 */

import size from "lodash/size";
import orderBy from "lodash/orderBy";

/*
 * Utilities
 */

export const SORT_TYPES = {
  asc: "asc",
  desc: "desc"
};

const ascSortId = (id) => `${id}_${SORT_TYPES.asc}`;
const descSortId = (id) => `${id}_${SORT_TYPES.desc}`;

const sort = (list, key, isDesc) => {
  return orderBy(list, [key], [isDesc ? "desc" : "asc"]);
};

/**
 * @param items Array of:
 * {
 *   id: string,
 *   type: SORT_TYPES.asc/desc,
 *   text: string
 *   custom?: () => []
 *   itemProp?: string
 * }
 * @param defaultSortOptionIndex
 */
export const createSort = (items, defaultSortOptionIndex = 0) => {
  /**
   * Select options
   */
  const options = items.map(i => ({
    ...i,
    value: i.type === SORT_TYPES.asc ? ascSortId(i.id) : descSortId(i.id)
  }));

  /**
   * Provides faster access to the current item
   * without having to calculate type and id every time
   */
  const sortOptionsByValue = options.reduce((acc, item) => {
    const { id, value, type, itemProp, custom } = item;
    const isDesc = type === SORT_TYPES.desc;
    acc[value] = { item, id, itemProp, isDesc, isAsc: !isDesc, custom };
    return acc;
  }, {});

  /**
   * Utilities
   */
  const utils = {
    getItemProp: (value) => sortOptionsByValue[value].itemProp || null,
    isEqual: (value, id) => sortOptionsByValue[value].id === id,
    isAsc: (value) => sortOptionsByValue[value].isAsc,
    isDesc: (value) => sortOptionsByValue[value].isDesc,
    getCustom: (value) => sortOptionsByValue[value].custom
  };

  /**
   * Result sort function
   */
  const sortList = (list, sortValue) => {
    if (!size(list) || !Array.isArray(list)) {
      return [];
    }

    const isDesc = utils.isDesc(sortValue);
    const itemProp = utils.getItemProp(sortValue);
    const custom = utils.getCustom(sortValue);

    if (custom) {
      return custom(list, isDesc);
    } else if (itemProp) {
      return sort(list, itemProp, isDesc);
    }

    return list;
  };

  return {
    options,
    sortList,
    defaultValue: options[defaultSortOptionIndex].value,
    ...utils
  };
};
