import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const editLastSuggestion = gql`
  ${baseFragments.result}
  mutation editLastSuggestion(
    $add_ons: [Int]
    $delivery_location: String
    $departure_date: String!
    $message: String
    $rental: Int!
    $return_date: String!
    $rental_fee: Int
    $signature_name: String
    $specific_delivery_location_id: Int
    $delivery_fee: Int
  ) {
    edit_last_suggestion(
      add_ons: $add_ons
      delivery_location: $delivery_location
      departure_date: $departure_date
      message: $message
      rental: $rental
      return_date: $return_date
      rental_fee: $rental_fee
      signature_name: $signature_name
      delivery_fee: $delivery_fee
      specific_delivery_location_id: $specific_delivery_location_id
    ) {
      result {
        ...Result
      }
    }
  }
`;

export const makeSuggestion = gql`
  ${baseFragments.result}
  mutation makeSuggestion(
    $add_ons: [Int]
    $delivery_location: String
    $departure_date: String!
    $message: String
    $rental: Int!
    $return_date: String!
    $rental_fee: Int
    $signature_name: String
    $specific_delivery_location_id: Int
    $delivery_fee: Int
  ) {
    make_suggestion(
      add_ons: $add_ons
      delivery_location: $delivery_location
      departure_date: $departure_date
      message: $message
      rental: $rental
      return_date: $return_date
      rental_fee: $rental_fee
      signature_name: $signature_name
      specific_delivery_location_id: $specific_delivery_location_id
      delivery_fee: $delivery_fee
    ) {
      result {
        ...Result
      }
    }
  }
`;
