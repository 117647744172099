import React from "react";
import Button from "components/Button/Button";

const PickUpDeliveryModalFooter = (props) => {
  const {
    classes,
    handleOnClosePickUpDeliveryModal,
    onSave,
    isDisabled
  } = props;

  return (
    <div className={`${classes.pickUpDeliveryModalFooter}`}>
      <div
        className={`container ${classes.pickUpDeliveryModalFooterRowContainer}`}
      >
        <div
          className={`${classes.pickUpDeliveryModalFooterRow} row l-0 r-0 ml-0 mr-0`}
        >
          <div
            className={`${classes.pickUpDeliveryModalFooterRowInner} col-12 col-xxxl-12 l-0 r-0 ml-0 mr-0`}
          >
            <div
              className={`row l-0 r-0 ml-0 mr-0 col-12 ${classes.pickUpDeliveryModalFooterContent}`}
            >
              <div className="dn db-m l-0 r-0 ml-0 mr-0 col-l-4 col-xl-3 col-xxxl-4 ">
                <div
                  className={` ${classes.pickUpDeliveryModalFooterText}`}
                >
                </div>
              </div>
              <div
                className={`col-l-8 r-m-0 l-m-24 col-xl-9 col-xxxl-8`}
              >
                <div
                  className={` ${classes.pickUpDeliveryModalFooterBtns}`}
                >
                  <Button
                    secondary
                    onClick={handleOnClosePickUpDeliveryModal}
                    className={` ${classes.pickUpDeliveryModalFooterBtnsCancel}`}
                  >
                  Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={onSave}
                    disabled={isDisabled}
                  >
                  Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickUpDeliveryModalFooter;
