import React from "react";
import Typography from "components/Typography";
import { ReactComponent as IconCheck } from "assets/images/icon-check.svg";
import { ReactComponent as IconClose } from "assets/images/icon-close.svg";
import classes from "./AmenitiesList.module.css";

function getAvailableIcon(available) {
  let availableIcon = null;
  if (typeof available === 'boolean') {
    if (available) availableIcon = <IconCheck />;
    else availableIcon = <IconClose />;
  }
  return availableIcon;
}

export const AmenitiesList = ({ data = [] }) => {

  const amenitiesItems = data.map(({ id, photo, title, available }) => {
    const availableIcon = getAvailableIcon(available);
    return (
      <li key={id} className={`${classes.amenitiesItem}`}>
        <div className={`
          ${classes.amenitiesItemInner} 
          tac`
        }>

          <span className={classes.amenitiesAvailable}>{availableIcon}</span>
          <div className={`${classes.amenitiesImgContainer} mb-12`}>
            <img src={photo} alt={photo} className={classes.amenitiesImg}/>
          </div>
          <div className={`${classes.amenitiesSubTitleContainer} mb-4`}>
            <Typography variant="body" size="l"  className={classes.amenitiesSubTitle}>
              {title}
            </Typography>
          </div>

        </div>
      </li>
    );
  });
  return (
    <div className={classes.container}>
      <ul className={`${classes.amenities} ui-menu`} >
        {amenitiesItems}
      </ul>
    </div>
  );
};

// AmenitiesList.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

