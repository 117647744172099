import React, { useState, useRef } from "react";
import Typography from "components/Typography";
import Button from "components/Button";
import { useDescriptionCollapse } from "processes/RvProfile/components/DescriptionSection/DescriptionList/hooks/useDescriptionCollapse";
import classes from "./DescriptionList.module.css";

export const DescriptionContent = ({ id, title, desc }) => {
  const descriptionRef = useRef(null);
  const isCollapse = useDescriptionCollapse(descriptionRef);
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div key={id} className={`${classes.descListItem} mb-16 mb-m-20`}>
      <div>
        <Typography variant="subtitle" size="l" weight="bold">
          {title}
        </Typography>
      </div>

      <div
        className={`
          ${classes.desctiption} 
          ${showDescription && classes.showDescription} 
          ${isCollapse && classes.desctiptionCollapse}
        `}
        ref={descriptionRef}
      >
        <Typography variant="subtitle" size="s">
          {desc}
        </Typography>
      </div>

      {isCollapse && (
        <div className="t-16 b-16 t-m-20 b-m-20">
          <Button
            label={showDescription ? "Show Less" : "Read More"}
            onClick={() => setShowDescription((p) => !p)}
            secondary
          />
        </div>
      )}
    </div>
  );
};

export const DescriptionList = ({ data = [] }) => {
  return (
    <div className={classes.container}>
      <div className={`${classes.descList} `} >
        <DescriptionContent {...data[0]} />
        <DescriptionContent {...data[1]} />
        <DescriptionContent {...data[2]} />
      </div>
    </div>
  );
};

// DescriptionList.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

