import React from "react";
import cn from "classnames";
import Typography from "components/Typography";
import { LaunchIcon } from "components/Icon/LaunchIcon";

import classes from "./VehicleCard.module.css";

const VehicleCard = ({
  vehicle,
  isActive,
  onSelectVehicle
}) => {

  const pinClick = () => {
    onSelectVehicle(
      vehicle.id,
      vehicle.rv_name,
      vehicle.title_image,
      vehicle.daily_rate?.cents_total,
      vehicle.daily_rate?.dollars_as_part,
      vehicle.availability_calendar,
      vehicle.minimum_duration,
      vehicle.location,
      vehicle.allow_standard_rental,
      vehicle.allow_delivery_only_rental,
      vehicle.delivery_option,
      vehicle.dor_delivery_option,
      vehicle.paid_delivery,
      vehicle.dor_paid_delivery,
      vehicle.specific_delivery_locations,
      vehicle.dor_specific_delivery_locations,
      vehicle.add_ons
    );
  };

  return (
    <div className={cn("t-8 t-m-16 b-8 b-m-4", classes.wrapper)}>
      <div
        className={cn(
          classes.card,
          isActive && classes.isActive
        )}
        onClick={pinClick}
      >
        <button
          className={cn(
            classes.pin,
            isActive && classes.isActive
          )}
          type="button"
        />

        <div className={classes.image} style={{ backgroundImage: `url(${vehicle.title_image})` }} >
          <a className={classes.link} href={vehicle.public_url} target="_blank" rel="noreferrer">
            <LaunchIcon className={classes.linkIcon} />
          </a>
        </div>

        <div className={classes.description}>
          <div className={classes.header}>
            <div>
              <div className={classes.name}>
                <Typography
                  variant="subtitle"
                  weight="bold"
                  className={classes.nameText}
                >
                  {vehicle.rv_name}
                </Typography>
              </div>

              <div className={classes.location}>
                <Typography
                  variant="body"
                  className={classes.locationText}
                >
                  {vehicle.location.short_address}
                </Typography>
              </div>

              <div className={classes.params}>
                <div className={cn(classes.class, classes.param)}>
                  <Typography
                    variant="body"
                    className={classes.paramName}
                  >
                    RV Class
                  </Typography>

                  <Typography
                    variant="body"
                    className={classes.paramValue}
                  >
                    {vehicle.class}
                  </Typography>
                </div>

                <div className={classes.param}>
                  <Typography
                    variant="body"
                    className={classes.paramName}
                  >
                    Length (ft)
                  </Typography>

                  <Typography
                    variant="body"
                    className={classes.paramValue}
                  >
                    {vehicle.length.feet_as_part}
                  </Typography>
                </div>

                <div className={classes.param}>
                  <Typography
                    variant="body"
                    className={classes.paramName}
                  >
                    Sleeps
                  </Typography>

                  <Typography
                    variant="body"
                    className={classes.paramValue}
                  >
                    {vehicle.sleeps}
                  </Typography>
                </div>

                <div className={cn(classes.outs, classes.param)}>
                  <Typography
                    variant="body"
                    className={classes.paramName}
                  >
                    Slide Outs
                  </Typography>

                  <Typography
                    variant="body"
                    className={classes.paramValue}
                  >
                    {vehicle.slide_outs}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.price}>
              <Typography
                variant="subtitle"
                weight="bold"
                className={classes.priceText}
              >
                ${vehicle.daily_rate.dollars_as_part}
              </Typography>

              <Typography
                variant="body"
                className={classes.pricePeriod}
              >
                per night
              </Typography>
            </div>
          </div>
        </div>

        <div className={cn("t-2 b-4 l-16 r-16 t-m-8 b-m-8", classes.mobileLocation)}>
          <Typography
            variant="body"
            size="l"
          >
            {vehicle.location.short_address}
          </Typography>
        </div>
      </div>
    </div >
  );
};

export default VehicleCard;
