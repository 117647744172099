import { useLocation } from "react-router-dom";
import { parseQueryString } from "../utils/queryString";
import { ensureInt } from "../utils/ensureInt";

export function useIdFromLocation() {
  const location = useLocation();
  const query = parseQueryString(location.search);

  return ensureInt(query.id);
}
