import React from "react";
import PropTypes from "prop-types";
import { FILTERS } from "../../filters";
import VehicleClassCardsList from "../../../vehicles/VehicleClassCardsList";
import { getObjectPositiveValuesCount } from "../../../../utils/basic";
import HeaderFilterButton from "./HeaderFilterButton";
import classes from "./FilterClass.module.css";
import omit from "lodash/omit";

const FilterClass = (props) => {
  const { classesList } = props;

  return (
    <HeaderFilterButton
      popperClassName={classes.popper}
      id={FILTERS.ids.class}
      getSelectedCount={getObjectPositiveValuesCount}
      renderForm={({ value, onChange }) => {
        return (
          <VehicleClassCardsList
            className="mt-4"
            list={classesList}
            state={value}
            onChange={({ name, checked }) => {
              onChange(omit(
                { ...value, [name]: checked },
                !checked ? name : null
              ));
            }}
          />
        );
      }}
    />
  );
};

FilterClass.propTypes = {
  classesList: PropTypes.array.isRequired
};

export default FilterClass;
