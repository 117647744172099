import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const rentalQuery = gql`
  ${baseFragments.button}
  ${baseFragments.keyValue}
  ${baseFragments.money}
  ${baseFragments.rv.addon}
  ${baseFragments.rental.status}
  ${baseFragments.rental.price}

  fragment RentalSuggestion on RentalSuggestion {
    add_ons
    departure_date
    duration
    id
    initiator {
      ...KeyValue
    }
    message
    pick_up_location
    price {
      ...RentalPrice
    }
    return_date
    specific_delivery_location_id
  }

  fragment UserInfo on User {
    id
    short_name
    first_name
    last_name
    country
    city
    state
    email
    avatar {
      id
      srcset {
        url
      }
    }
    rating
    phone
    good_sam_membership_validation {
      key
      value
    }
    driver_verification {
      key
      value
    }
    deleted
  }

  query rental($id: Int!) {
    rental(id: $id) {
      rental {
        id
        rental_type {
          ...KeyValue
        }
        specific_delivery_location_id
        action
        action_button {
          ...KeyValue
        }
        content {
          text {
            value
          }
        }
        departure_date
        details_button {
          ...KeyValue
        }
        alert {
          title
          text
          text_type
          type
          buttons {
            id
            type
            label
            link
          }
        }
        duration
        overages_conditions {
          miles_included
          mileage_overage_rate {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          limited_daily_mileage
          limited_generator_hours
          is_trailed_class
          generator_not_applicable
          generator_overage_rate {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          generator_hours_included
        }
        history {
          action_link {
            ...KeyValue
          }
          active_action
          action_buttons {
            ...Button
          }
          add_ons
          additional_text
          date_time
          departure_date
          pick_up_location
          price {
            ...MoneyReturn
          }
          price_full {
            ...RentalPrice
          }
          price_form {
            desc {
              id
              value
              type
            }
            total {
              cents_total
              dollars_as_part
              cents_as_part
              user_friendly
            }
            items {
              id
              type
              title {
                id
                value
                type
              }
              value {
                id
                value
                type
              }
              style
              separ
              sub_items {
                title {
                  id
                  value
                  type
                } 
                value {
                  id
                  value
                  type
                }
                units
              }
              action {
                title
                id
                pos
              }
            }
          }
          return_date
          text
          title
        }
        info_text
        info_title
        info_button
        owner {
          ...UserInfo
        }
        renter_review {
          moderated
          rating
          testimonial
          date
          author_name
          author_photo {
              id
              srcset {url}
          }
          photos {
            id 
            srcset {url}
          }
          is_user_deleted
        }
        owner_review {
          moderated
          rating
          testimonial
          date
          author_name
          author_photo {
              id
              srcset {url}
          }
          photos {
            id 
            srcset {url}
          }
          is_user_deleted
        }
        partner_phone
        pick_up_location
        price {
          ...RentalPrice
        }
        price_refunds {
          ...RentalPrice
        }
        price_after_potential_cancellation {
          ...RentalPrice
        }
        price_refunds_after_potential_cancellation {
          ...RentalPrice
        }
        price_original {
          ...RentalPrice
        }
        progress_bar {
          item
          style
        }
        red_box_text
        red_box_title
        renter {
          ...UserInfo
        }
        return_date
        role {
          key
          value
        }
        rv {
          id
          rv_name
          title_image
          class
          add_ons {
            ...RvAddon
          }
          is_trailed_class
          miles_included
          limited_daily_mileage
          mileage_overage_rate {
            ...MoneyReturn
          }
          generator_hours_included
          generator_not_applicable
          limited_generator_hours
          generator_overage_rate {
            ...MoneyReturn
          }
          delivery_option
          dor_delivery_option
          specific_delivery_locations {
            id
            delivery_fee {
              cents_total
              dollars_as_part
              user_friendly
            }
            description
            location {
                full_address
                latitude
                longitude
            }
          }
          dor_specific_delivery_locations {
            id
            delivery_fee {
              cents_total
              dollars_as_part
              user_friendly
            }
            description
            location {
              full_address
              latitude
              longitude
            }
          }
          location {
            full_address
          }
          minimum_duration
          daily_rate {
            ...MoneyReturn
          }
          paid_delivery
          dor_paid_delivery
          location {
            state
            city
            latitude
            longitude
            full_address
            short_address
          }
          public_url
        }
        status {
          ...RentalStatus
        }
        additional_status {
          key
          value
        }
        additional_info
        show_confirmed_section
        show_suggestion_button
        confirmed_suggestion {
          ...RentalSuggestion
        }
        last_suggestion {
          ...RentalSuggestion
        }
        is_trip_interruption_insurance_changable
        overage_section {
          drop_off_generator_hours
          drop_off_mileage
          drop_off_submit_button_disabled
          generator_hours_overage
          generator_hours_overage_fee {
            ...MoneyReturn
          }
          mileage_overage
          mileage_overage_fee {
            ...MoneyReturn
          }
          overage_payment_status {
            ...KeyValue
          }
          pick_up_generator_hours
          pick_up_mileage
          pick_up_submit_button_disabled
          show_drop_off_section
          show_drop_off_submit_button
          show_fees_section
          show_overage_section
          show_pick_up_section
          show_pick_up_submit_button
          total_overage_fee {
            ...MoneyReturn
          }
        }
        inspection_pick_up {
          status {
            key
            value
          }
          visible_from
          mileage
          generator_hours
          time_start {
            timestamp
            user_friendly
          }
          time_end {
            timestamp
            user_friendly
          }
          signature_owner
          signature_renter
          images {
            image {
              id
              srcset {
                url
              }
            }
            comment
          }
          is_full
          groups {
            id
            edit_title {
              value
            }
            disp_title {
              value
            }
            desc {
              value
            }
            is_step
            items {
              id
              title
              type
              data
              value
            }
          }
          review_group_ids
          summary_group_ids
          view_group_ids
          is_available
        }
        inspection_drop_off {
          status {
            key
            value
          }
          visible_from
          mileage
          generator_hours
          time_start {
            timestamp
            user_friendly
          }
          time_end {
            timestamp
            user_friendly
          }
          signature_owner
          signature_renter
          images {
            image {
              id
              srcset {
                url
              }
            }
            comment
          }
          is_full
          groups {
            id
            edit_title {
              value
            }
            disp_title {
              value
            }
            desc {
              value
            }
            is_step
            items {
              id
              title
              type
              data
              value
            }
          }
          review_group_ids
          summary_group_ids
          view_group_ids
          is_available
        }
        inspection_overage {
          mileage
          mileage_fee {
            user_friendly
            dollars_as_part
            cents_as_part
          }
          generator
          generator_fee {
            user_friendly
            dollars_as_part
            cents_as_part
          }
          total_fee{
            ...MoneyReturn
          }
          payment_status {
            key
            value
          }
        }
        is_predefined
        additional_drivers {
          id
          rental
          email
          name
          status
          user
        }
        additional_drivers_limit
        price_final_pf {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          desc {
            id
            value
            type
          }
          items {
            id
            type
            title {
              id
              value
              type
            }
            action {
              title
              id
              pos
            }
            value {
              id
              value
              type
            }
            units
            style
            separ
            sub_items {
              title {
                id
                value
                type
              } 
              value {
                id
                value
                type
              }
              units
            }
          }
        }
        price_refunds_pf {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          desc {
            id
            value
            type
          }
          items {
            id
            type
            title {
              id
              value
              type
            }
            action {
              title
              id
              pos
            }
            value {
              id
              value
              type
            }
            units
            style
            separ
            sub_items {
              title {
                id
                value
                type
              } 
              value {
                id
                value
                type
              }
              units
            }
          }
        }
        price_original_pf {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          desc {
            id
            value
            type
          }
          items {
            id
            type
            title {
              id
              value
              type
            }
            action {
              title
              id
              pos
            }
            value {
              id
              value
              type
            }
            units
            style
            separ
            sub_items {
              title {
                id
                value
                type
              } 
              value {
                id
                value
                type
              }
              units
            }
          }
        }
        rental_details_short_pf {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          desc {
            id
            value
            type
          }
          items {
            id
            type
            title {
              id
              value
              type
            }
            action {
              title
              id
              pos
            }
            value {
              id
              value
              type
            }
            units
            style
            separ
            sub_items {
              title {
                id
                value
                type
              } 
              value {
                id
                value
                type
              }
              units
            }
          }
        }
        rental_details_pf {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          desc {
            id
            value
            type
          }
          items {
            id
            type
            title {
              id
              value
              type
            }
            action {
              title
              id
              pos
            }
            value {
              id
              value
              type
            }
            units
            style
            separ
            sub_items {
              title {
                id
                value
                type
              } 
              value {
                id
                value
                type
              }
              units
            }
          }
        }
      }
      result {
        success
        errors {
          code
          field
          message
        }
      }
    }
  }
`;
