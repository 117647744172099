import React from "react";

const passwordShownIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "block" }}
  >
    <path
      d="M2.08362 10.1825C2.08362 10.1825 5.28362 4.58252 10.0836 4.58252C14.8836 4.58252 18.0836 10.1825 18.0836 10.1825C18.0836 10.1825 14.8836 15.7825 10.0836 15.7825C5.28362 15.7825 2.08362 10.1825 2.08362 10.1825Z"
      stroke="#212B36"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0831 12.5837C11.4086 12.5837 12.4831 11.5092 12.4831 10.1837C12.4831 8.85821 11.4086 7.78369 10.0831 7.78369C8.75762 7.78369 7.68311 8.85821 7.68311 10.1837C7.68311 11.5092 8.75762 12.5837 10.0831 12.5837Z"
      stroke="#212B36"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M3 17.2998L17 3.2998"
      stroke="#212B36"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default passwordShownIcon;
