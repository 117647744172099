import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import Button from "components/Button";
import { useNavExtraLinkHover } from "processes/MyAccount/hooks/useNavExtraLinkHover";
import classes from "./NavMenuItem.module.css";
import classnames from "classnames";

const NavMenuItem = (props) => {
  const { id, step, onClick, showActive } = props;
  const isExtraStep = step?.isExtraStep;

  const linkExtraRef = useRef(null);

  useNavExtraLinkHover(linkExtraRef, classes);

  return isExtraStep ? (
    <li className={classes.item}>
      <Button
        id={step.id}
        href={step?.link ? step.link : null}
        target="_blank"
        noopener="true"
        norefferer="true"
        ghost
        disabled={step.disabled}
        className={classnames(
          classes.link,
          classes.linkBasic,
          classes.linkWithAction,
          classes.linkExtra,
          step.disabled && classes.linkExtraDisabled
        )}
        ref={linkExtraRef}
      >
        {step.label}
      </Button>
    </li>
  ) : (
    <li className={classes.item}>
      <NavLink
        onClick={onClick}
        to={`/account/${step.path}${id ? `?id=${id}` : ""}`}
        className={classnames(
          classes.link,
          classes.linkBasic,
          classes.linkWithAction
        )}
        activeClassName={classes.activeLink}
        isActive={(match) => {
          if (!match) {
            return false;
          }

          return showActive;
        }}
      >
        {step.title}
      </NavLink>
    </li>
  );
};

export default NavMenuItem;
