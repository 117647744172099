import { gql } from "@apollo/client";

export const membershipValidate = gql`
  mutation membership_validate(
    $club_id: String!, 
    $membership_number: String!, 
    $last_name: String!, 
    $zip: String!
  ) {
    membership_validate(
      club_id: $club_id,
      membership_number: $membership_number, 
      last_name: $last_name,
      zip: $zip
      ) {
      result {
        success
        errors {
          code
          field
          message
        }
      }
    }
  }
`;
