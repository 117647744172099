import React, { useState, useEffect } from 'react';
import { useQuery } from "@apollo/client";
import { contentQuery } from "../../../../queries";
import { MyAccountContext } from "../../MyAccountContext";
import { extractText } from "../../../../utils/extractText";
import { SpinnerBlur } from "../../../../components/Spinner/Spinner";
import ReferralsContent from './components/ReferralsContent/ReferralsContent';

const section = "referral_content";
const initialContent = {
  sectionTitle: "",
  notificationTitle: "",
  notificationText: "",
  notificationButtonText: "",
  referUsersButtonText: "",
  referralsListHeaderText: "",
  referralsListHeaderEarn: "",
  referralsListHeaderTooltip: "",
  referralsListEmpty: "",
  proTipsSectionTitle: "",
  proTipsSectionText: "",
  referrals_list_congratulations_title:"",
  referrals_list_congratulations_text:""
};

const ReferralsDataProvider = (props) => {
  const [content, setContent] = useState(initialContent);
  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section
    },
    fetchPolicy: "no-cache"
  });

  const { setAccountSection } = props;

  useEffect(() => {
    setAccountSection(section);
  }, [setAccountSection]);

  useEffect(() => {
    if (!loading) {
      setContent({
        sectionTitle: extractText(data.content.text, "section_title"),
        notificationTitle: extractText(data.content.text, "notification_title"),
        notificationText: extractText(data.content.text, "notification_text"),
        notificationButtonText: extractText(data.content.text, "notification_button_text"),
        referUsersButtonText: extractText(data.content.text, "refer_users_button_text"),
        referralsListHeaderText: extractText(data.content.text, "referrals_list_header_text"),
        referralsListHeaderEarn: extractText(data.content.text, "referrals_list_header_earn"),
        referralsListHeaderTooltip: extractText(data.content.text, "referrals_list_header_tooltip"),
        referralsListEmpty: extractText(data.content.text, "referrals_list_empty"),
        proTipsSectionTitle: extractText(data.content.text, "pro_tips_section_title"),
        proTipsSectionText: extractText(data.content.text, "pro_tips_section_text"),
        referrals_list_congratulations_title: extractText(data.content.text, "referrals_list_congratulations_title"),
        referrals_list_congratulations_text: extractText(data.content.text, "referrals_list_congratulations_text")
      });
    }
  }, [loading, data]);

  if (loading) {
    return <SpinnerBlur />;
  }

  return <ReferralsContent content={content} {...props} />;
};

export const Referrals = () => (
  <MyAccountContext.Consumer>
    {(value) => <ReferralsDataProvider {...value} />}
  </MyAccountContext.Consumer>
);
