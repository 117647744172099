import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Tag from "components/Tag";
import { TextItem } from "components/TextItem";
import Typography from "components/Typography";
import { MemberItemValue } from "components/MembershipDetails";
import { useTagSize } from "components/Tag/hooks/useTagSize";
import NotificationPopover from "components/NotificationPopover";
import cn from "classnames";
import classes from "./MemberItem.module.css";

export const MemberItem = (props) => {
  const {
    club_name,
    club_icon,
    club_description,
    plan_type,
    activation_date,
    expiration_date,
    member_number,
    auto_renew,
    manage_button_caption,
    manage_button_url,
    renew_button_caption,
    renew_button_url,
    support_info
  } = props;

  const tagSize = useTagSize();

  return (
    <div className={cn(classes.container, 'b-12 b-m-20')}>
      <header className={classes.header}>
        <div className={classes.titleContainer}>
          <div className={classes.clubIconContainer}>
            <img
              src={club_icon}
              alt={club_name}
              className={classes.clubIcon}
            />
          </div>

          <div>
            <Typography
              component="h3"
              variant="subtitle"
              size="m"
              className={classes.clubName}
            >
              {club_name}
            </Typography>
          </div>
        </div>

        <div>
          <Tag
            size={tagSize}
            text="Active"
            type="success"
          />
        </div>
      </header>

      <div className={cn(classes.content, 't-16 t-m-20')}>
        <div className={classes.planType}>
          <MemberItemValue title="Plan type" value={plan_type} />
        </div>
        <div className={classes.activationDate}>
          <MemberItemValue title="Activation date" value={activation_date} />
        </div>
        <div className={classes.expirationDate}>
          <MemberItemValue title="Expiration date" value={expiration_date} />
        </div>
        <div className={classes.memberNumber}>
          <MemberItemValue title="Member number" value={member_number} />
        </div>
        <div className={classes.autoRenew}>
          <MemberItemValue title="Auto renew" value={auto_renew ? "On" : "Off"} />
        </div>
      </div>

      <div>
        <Typography
          variant="body"
          size="l"
          className={classes.description}
        >
          {club_description}
        </Typography>
      </div>

      {Boolean(support_info?.length) && (
        <div className={classes.supportInfo}>
          {support_info.map(({ phone, title, description }) => (
            <div className={classes.supportInfoItem}>
              <NotificationPopover
                variant="static"
                status="default"
                show={Boolean(phone)}
                title={title}
                text={(
                  <>
                    <div>
                      <TextItem
                        data={description}
                        plainStyle={classes.memberDescriptionValuePlainStyle}
                        htmlStyle={classes.memberDescriptionHtmlStyle}
                      />
                    </div>
                    <a href={`tel:${phone}`} className={classes.clubPhoneNumberLink}>{phone}</a>
                  </>
                )}
                notificationInnerClassName={classes.notificationInner}
              />
            </div>
          ))}
        </div>
      )}

      <footer className="t-16 b-16 t-m-24">
        <Button
          secondary
          href={renew_button_url}
          target="_blank"
          noopener="true"
          norefferer="true"
          className={cn(classes.footerButton, 'mr-24 btn btn--secondary')}
        >
          {renew_button_caption}
        </Button>

        <Button
          ghost
          href={manage_button_url}
          target="_blank"
          noopener="true"
          norefferer="true"
          className={classes.footerButton}
        >
          {manage_button_caption}
        </Button>
      </footer>
    </div>
  );
};

MemberItem.propTypes = {

};
