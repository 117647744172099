import React from "react";
import classnames from "classnames";
import Typography from "../../../../../components/Typography";
import RentalMileageItem from "./RentalMileageItem";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { RENTAL_INSPECTION_TYPES } from "processes/Rentals/utils";
import { Button as ButtonContent } from "components/ContentSection/elements";
import style from "./style.module.css";

const RentalMileageMobile = ({
  inspectionOverageData,
  inspectionsData,
  feeTag,
  mileageOverageValue,
  mileageFeeValue,
  isShowMileageOverage,
  isShowGeneratorOverage,
  generatorFeeValue,
  generatorOverageValue,
  supportLink,
  setCurrentInspection,
  setAddMileageModalData,
  openAddMileage,
  setShowInspectionModal
}) => {
  return (
    <div className="t-8 r-16 l-16">
      <div>
        {feeTag && (
          <div
            className={classnames(style.rentalMileage__feeTag, "df t-8 b-8")}
          >
            {feeTag}
          </div>
        )}
        {inspectionOverageData && (
          <div className="">
            {isShowMileageOverage && (
              <div className="row t-8">
                <div className="col-auto">
                  <Typography
                    variant="body"
                    size="l"
                    className=""
                  >
                    Mileage overage
                  </Typography>
                </div>
                <div className="col-auto">
                  <Typography
                    variant="body"
                    size="l"
                    className=""
                  >
                    <span className="l-8">
                      <strong>{mileageOverageValue}</strong> miles
                      {mileageFeeValue !== 0 && mileageFeeValue !== null && <span className="ml-4">(<strong>${mileageFeeValue}</strong>)</span>}
                    </span>
                  </Typography>
                </div>
              </div>
            )}
            {isShowGeneratorOverage && (
              <div className="row t-2">
                <div className="col-auto">
                  <Typography
                    variant="body"
                    size="l"
                    className=""
                  >
                    Generator overage
                  </Typography>
                </div>
                <div className="col-auto">
                  <Typography
                    variant="body"
                    size="l"
                    className=""
                  >
                    <span className="l-8">
                      <strong>{generatorOverageValue}</strong> hours
                      {generatorFeeValue !== 0 && generatorFeeValue !== null && <span className="ml-4">(<strong>${generatorFeeValue}</strong>)</span>}
                    </span>
                  </Typography>
                </div>
              </div>
            )}
            <div className="devider-dashed mt-8 mb-0" />
          </div>
        )}
        {inspectionOverageData && inspectionOverageData?.total_fee && (
          <div className="t-8 b-8">
            <div className="row">
              <div className="col">
                <Typography
                  variant="body"
                  size="l"
                  weight="bold"
                  className=""
                >
                  Additional fee
                </Typography>
              </div>
              <div className="col-auto">
                <Typography
                  variant="body"
                  size="l"
                  className=""
                >
                  <FormattedPrice value={inspectionOverageData?.total_fee} isBold />
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classnames(style.rentalMileageMobile__wrap, "mt-8")}>
        <div
          className={classnames(
            style.rentalMileageItems
          )}
        >
          <RentalMileageItem
            type={RENTAL_INSPECTION_TYPES.start}
            inspectionsData={inspectionsData.pickUp}
            openAddMileage={openAddMileage}
            setCurrentInspection={setCurrentInspection}
            setAddMileageModalData={setAddMileageModalData}
            setShowInspectionModal={setShowInspectionModal}
          />


          <RentalMileageItem
            type={RENTAL_INSPECTION_TYPES.end}
            inspectionsData={inspectionsData.dropOff}
            openAddMileage={openAddMileage}
            setCurrentInspection={setCurrentInspection}
            setAddMileageModalData={setAddMileageModalData}
            setShowInspectionModal={setShowInspectionModal}
          />
        </div>
      </div>
      {supportLink && (
        <div className="mt-16">
          {supportLink}
        </div>
      )}

      <div className="mt-32">
        <Typography
          variant="body"
          size="l"
          component="div"
        >
          No Good Sam mobile app?
          <div>
            <ButtonContent
              id="download_mobile_app_for_ios"
              section="linksContent"
              label="Download for iOS"
              ghost
              newTab
              className={classnames(style.outerLink, 'underline')}
            />
            <span>/</span>
            <ButtonContent
              id="download_mobile_app_for_android"
              section="linksContent"
              label="Android"
              ghost
              newTab
              className={classnames(style.outerLink, 'underline')}
            />
          </div>
        </Typography>
      </div>
    </div>
  );
};

export default RentalMileageMobile;
