import { useEffect, useCallback, useState } from "react";
import { getScrollBarWidth } from "utils/getScrollBarWidth";

const SCROLL_WIDTH = "--cwrr-scroll-width";
const scrollWidth = getScrollBarWidth();
/**
 * @param {Element} node
 * @param {Function} callback
 * @return {(function(): void)|*}
 */
function trackSize(node, callback) {
  let observer = null;

  try {
    observer = new ResizeObserver(() => {
      const isScroll = node.offsetWidth > node.clientWidth;
      if (isScroll) callback(scrollWidth);
      else callback(0);
    });

    observer.observe(node);
  } catch (e) {}

  return () => {
    if (observer) {
      observer.unobserve(node);
      observer = null;
    }
  };
}

export function useScrollBarWidth(refNode) {
  const [node, setNode] = useState(refNode);

  const ref = useCallback((node) => {
    if (!refNode) setNode(node);
  }, [refNode]);

  useEffect(() => {
    let unsubscribe;

    if (node) {
      unsubscribe = trackSize(node, (scrollWidth = 0) => {
        if (node) {
          node.style.setProperty(SCROLL_WIDTH, scrollWidth + "px");
          document.documentElement.style.setProperty(SCROLL_WIDTH, `${scrollWidth}px`);
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (node) {
        node.style.setProperty(SCROLL_WIDTH, 0 + "px");
        document.documentElement.style.setProperty(SCROLL_WIDTH, `0px`);
      }
    };
  }, [node, refNode]);

  return ref;
}
