import React, { useCallback, useState } from "react";
import { useMutation } from '@apollo/client';
import { deleteAccountMutation } from "../mutations/deleteAccountMutation";
import { DeleteAccountForm } from 'processes/MyAccount/steps/General/DeleteAccount/DeleteAccountForm.js';
import { SpinnerBack } from "components/Spinner/Spinner";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import {
  extractServerErrors,
  extractSuccess
} from "utils/extractErrors";
import { useHistory } from "react-router-dom";
import { forceLogout } from "apollo/forceLogout";

const extractDeleteAccountSuccess = extractSuccess("delete_account");
const extractDeleteAccountErrors = extractServerErrors("delete_account");

export const DeleteAccount = () => {
  const [deleteAccountAction, result] = useMutation(deleteAccountMutation);
  const [showError, setShowError] = useState(false);
  const [requestErrors, setRequestErrors] = useState(null);

  const onDeleteAccount = useCallback(
    async () => {
      const response = await deleteAccountAction();

      if (!extractDeleteAccountSuccess(response)) {
        setShowError(true);
        setRequestErrors(extractDeleteAccountErrors(response));
        return;
      }

      const {
        data: {
          delete_account: {
            result: { success }
          }
        }
      } = response;

      if (success) {
        setShowError(false);
        forceLogout();
      }
    },
    [deleteAccountAction]
  );
  return (
    <div className="mb-16 mb-m-20">

      <DeleteAccountForm
        onDeleteAccount={onDeleteAccount}
      />

      {result.loading && <SpinnerBack />}

      <NotificationPopover
        show={showError}
        status="error"
        text={requestErrors}
        onClose={() => {
          setShowError(false);
          setRequestErrors(null);
        }}
      />

    </div>
  );
};
