import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import classes from "./Tooltip.module.css";

const canUseDOM = Boolean(
  typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
);

const mutationObserverDefaults = {
  childList: true,
  subtree: true
};

function TooltipTrigger({
  children,
  // Some defaults changed in the hook implementation.
  // For backward compatibility we have to override them here.
  closeOnReferenceHidden = false,
  defaultTooltipShown,
  getTriggerRef,
  modifiers,
  offset = [0, 12],
  mutationObserverOptions = mutationObserverDefaults,
  onVisibilityChange,
  placement = "top",
  portalContainer = canUseDOM ? document.body : null,
  tooltip,
  tooltipShown,
  usePortal = canUseDOM,
  ...restProps
}) {
  const {
    triggerRef,
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    state
  } = usePopperTooltip(
    {
      // Some props renamed in the hook implementation.
      defaultVisible: defaultTooltipShown,
      onVisibleChange: onVisibilityChange,
      visible: tooltipShown,
      closeOnTriggerHidden: closeOnReferenceHidden,
      offset,
      ...restProps
    },
    {
      placement,
      modifiers
    }
  );

  const reference = children({
    // No longer required, for backward compatibility.
    getTriggerProps: (props) => props,
    triggerRef: setTriggerRef
  });

  const popper = tooltip({
    tooltipRef: setTooltipRef,
    getArrowProps,
    getTooltipProps,
    placement: state ? state.placement : undefined
  });

  useEffect(() => {
    if (typeof getTriggerRef === "function") getTriggerRef(triggerRef);
  }, [triggerRef, getTriggerRef]);

  return (
    <>
      {reference}
      {visible
        ? usePortal
          ? createPortal(popper, portalContainer)
          : popper
        : null}
    </>
  );
}

function Tooltip(props) {
  const {
    children,
    tooltipContent,
    tooltipContainerClassName,
    tooltipArrowClassName,
    tooltipOnClick,
    component,
    styles = null,
    ...restProps
  } = props;

  return (
    <>
      <TooltipTrigger
        tooltip={({ tooltipRef, getArrowProps, getTooltipProps }) => (
          <div
            {...getTooltipProps({
              ref: tooltipRef,
              className: `${classes.container} ${tooltipContainerClassName}`,
              style: styles
            })}
            onClick={tooltipOnClick}
          >
            <div
              {...getArrowProps({
                className: `${classes.arrow} ${tooltipArrowClassName}`
              })}
            />
            {tooltipContent}
          </div>
        )}
        {...restProps}
      >
        {({ triggerRef }) => {
          if (typeof children === "function") {
            return children(triggerRef);
          } else if(component && typeof component === 'object') {
            const {
              Component,
              props = {},
              children
            } = component;

            return (
              <Component {...props} ref={triggerRef}>
                {children}
              </Component>
            );
          } else {
            return null;
          }
        }}
      </TooltipTrigger>
    </>
  );
}

Tooltip.propTypes = {
  /**
   * Class for the tooltip container
   */
  tooltipContainerClassName: PropTypes.string,
  /**
   * Class for the tooltip arrow
   */
  tooltipArrowClassName: PropTypes.string,
  /**
   * Сontent of the tooltip
   */
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  /**
   * You can pass the component that calls the tooltip as a prop.
   */
  component: PropTypes.shape({
    Component: PropTypes.element.isRequired,
    props: PropTypes.shape({}),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired
  }),
  /**
   * Placement tooltip defaults to "top"
   */
  placement: PropTypes.oneOf([
    'auto', 'auto-start', 'auto-end',
    'top', 'top-start', 'top-end',
    'bottom', 'bottom-start', 'bottom-end',
    'right', 'right-start', 'right-end',
    'left', 'left-start', 'left-end'
  ]),
  /**
   * Event or events that trigger the tooltip.
   * Use null if you want to disable all events.
   * It's useful in cases when you control the state of the tooltip.
   * trigger: TriggerType | TriggerType[] | null, where TriggerType = 'click' | 'double-click' | 'right-click' | 'hover' | 'focus', defaults to hover.
   *
   * Example: ['click', 'hover']
   */
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  /**
   * The visibility state of the tooltip.
   * Use this prop if you want to control the state of the tooltip.
   * Note that delayShow and * delayHide are not used if the tooltip is controlled.
   * You have to apply delay on your external state.
   */
  tooltipShown: PropTypes.bool,
  /**
   * Delay in hiding the tooltip (ms), defaults to 0
   */
  delayHide: PropTypes.number,
  /**
   * Delay in hiding the tooltip (ms), defaults to 0
   */
  delayShow: PropTypes.number,
  /**
   * If true, closes the tooltip when user clicks outside the trigger element, defaults to true
   */
  closeOnOutsideClick: PropTypes.bool,
  /**
   * The initial visibility state of the tooltip when the hook is initialized, defaults to false
   */
  defaultVisible: PropTypes.bool,
  /**
   * If true, the tooltip will stick to the cursor position. You would probably want to use this option with hover trigger.
   */
  followCursor: PropTypes.bool,
  /**
   * Offset of the tooltip from its container, defaults to [0, 16]
   */
  offset: PropTypes.arrayOf(PropTypes.number),
  /**
   * This is a shorthand for popperOptions.modifiers offset modifier option.
   * The default value means the tooltip will be placed 6px away from the trigger element
   * (to reserve enough space for the arrow element), defaults to [0, 12]
   */
  modifiers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    options: PropTypes.shape({
      offset: PropTypes.arrayOf(PropTypes.number)
    })
  })),
  /**
   * Called with the tooltip state, when the visibility of the tooltip changes.
   */
  onVisibilityChange: PropTypes.func
};

export default Tooltip;
