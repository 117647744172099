import { gql } from '@apollo/client';

export const sendPhoneVerificationMutation = gql`
  mutation send_phone_verification_code {
    send_phone_verification_code {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
      user {
        phone
        phone_verified
      }
    }
  }
`;
