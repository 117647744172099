import { defaultTo, pipe, prop } from "ramda";
import { STEP_FILTER, STEP_MODEL, STEP_RV_ID } from "components/Listing/steps/GeneralInfo/calculateStep";
import size from "lodash/size";

export const ensureStrings = list => list.map(item => `${item}`);

export const extractList = listName => pipe(
  prop(listName),
  defaultTo([])
);

export const filterList = (list) => (term) => {
  const normalizedTerm = term.toLowerCase();

  const filteredList = list
    .filter(item => item.toLowerCase().indexOf(normalizedTerm) === 0);

  return filteredList.length > 0 ? filteredList : list;
};

export const isStepValid = (step, { year, manufacturer, make, model, class: cl, rvId = {} }) => {
  if (step === STEP_FILTER) {
    if (year?.length && manufacturer?.length && make?.length && /^\d{4}$/.test(year)) {
      return true;
    } else {
      return false;
    }
  } else if (step === STEP_MODEL) {
    if (model?.length && cl?.length) {
      return true;
    } else {
      return false;
    }
  } else if (step === STEP_RV_ID) {
    return size(rvId);
  }
};
