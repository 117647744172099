import React from "react";
import Typography from "components/Typography";
import { profileIcon } from "components/Icon";
import cn from "classnames";
import classes from "./ProfileInfoAside.module.css";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

const ProfilePreDefinedAside = (props) => {
  const { name, email } = props;

  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });

  return (
    <div
      aria-label={name}
      className={cn(
        !isMaxSmall && `${classes.container} t-24 b-24 l-32 r-32`
      )}
    >
      <div>
        <Typography component="p" variant="body">
          <span className="df">
            <span className="df-l fcc">
              {profileIcon}
            </span>

            <span className='l-12'>
              <a href={`mailto:${email}`}>
                <span>
                  {email}
                </span>
              </a>
            </span>
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default ProfilePreDefinedAside;
