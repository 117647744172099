export const steps = [
  {
    title: "General settings",
    completed: "general-settings",
    path: "general-settings"
  },
  //  {
  //    "title": "Notifications",
  //    "completed": "notifications",
  //    "path": "notifications"
  //  },
  {
    title: "My profile",
    completed: "my-profile",
    path: "my-profile"
  },
  {
    title: "Membership details",
    completed: "good-sam-membership",
    path: "good-sam-membership"
  },
  {
    title: "My referrals",
    completed: "my-referrals",
    path: "my-referrals"
  },
  {
    title: "Payment info",
    completed: "payment",
    path: "payment"
  },
  {
    title: "Payout settings",
    completed: "payout",
    path: "payout"
  },
  {
    title: "Driver verification",
    completed: "driver-verification",
    path: "driver-verification"
  },
  {
    title: "Transaction history",
    completed: "transaction-history",
    path: "transaction-history"
  }
];
