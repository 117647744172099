import React, { forwardRef, useEffect, useState, useCallback, useMemo } from "react";
import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import style from "./style.module.css";
import { convertMilesToMeters } from "./helpers";
import { useGoogleMaps } from "../../../../../GoogleMapsProvider";
import { USA_CENTER } from "../../../../../../utils/map";
import locationOutlineIcon from "./icons/location-outline-gray.svg";

const basicCircleOptions = {
  strokeColor: "#4D6539",
  strokeOpacity: 0.3,
  strokeWeight: 2,
  fillColor: "#66864B",
  fillOpacity: 0.15
};

const extractInitialLocation = (v) => {
  if (v.latitude !== "" && v.longitude !== "") {
    return {
      lat: +v.latitude,
      lng: +v.longitude
    };
  } else {
    return undefined;
  }
};

const circlePoints = (lat, lng, r) => {
  const d = r * 360 / (2 * Math.PI * 6371008);

  return [
    { position: { lat: lat - d, lng: lng } },
    { position: { lat: lat + d, lng: lng } },
    { position: { lat: lat, lng: lng - d } },
    { position: { lat: lat, lng: lng + d } }
  ];
};

export const MapLocationDeliveryLoader = forwardRef(function MapLocationDeliveryLoader(
  props
) {
  const { isLoaded } = useGoogleMaps();
  const {
    paidMilesRadius,
    freeMilesRadius,
    value,
    locations = [],
    isShowDeliveryOption,
    isShowLocations
  } = props;

  const [location, setLocation] = useState({
    full_address: value.full_address,
    coords: extractInitialLocation(value)
  });
  const [map, setMap] = useState(null);
  const center = useMemo(() => location.coords || USA_CENTER, [location.coords]);

  const locationMarkers = locations.map(loc => (
    <>
      <Marker
        key={loc.location}
        icon={{ url: locationOutlineIcon }}
        position={{
          lat: Number(loc.latitude),
          lng: Number(loc.longitude)
        }}
      />
    </>
  ));

  useEffect(() => {
    if (value?.full_address) {
      setLocation({
        full_address: value.full_address,
        coords: {
          lat: Number(value.latitude),
          lng: Number(value.longitude)
        }
      });
    } else {
      setLocation({
        full_address: '',
        coords: undefined
      });
    }
  }, [value, value?.full_address]);

  const circleOptions = {
    ...basicCircleOptions,
    center,
    radius: convertMilesToMeters(1)
  };

  const circleOptionsPaid = {
    ...basicCircleOptions,
    center,
    radius: convertMilesToMeters(paidMilesRadius)
  };

  const circleOptionsFree = {
    ...basicCircleOptions,
    fillColor: "transparent",
    center,
    radius: convertMilesToMeters(freeMilesRadius)
  };

  const handleOnLoad = useCallback((m) => {
    if (location.coords === undefined) {
      m.setCenter(USA_CENTER);
      m.setZoom(4);
    } else if (
      location.coords !== undefined
      && !isShowDeliveryOption
      && (locations.length === 0 || !isShowLocations)
    ) {
      let locs = [];
      const positions = {
        position: {
          lat: Number(location.coords.lat),
          lng: Number(location.coords.lng)
        }
      };
      locs.push(positions);

      const radius = convertMilesToMeters(1);
      const positions2 = circlePoints(center.lat, center.lng, radius);

      locs = [...locs, ...positions2];
      const bounds = new window.google.maps.LatLngBounds();
      locs.forEach(({ position }) => bounds.extend(position));
      m.fitBounds(bounds);
    } else {
      let locs = [];
      if (location.coords !== undefined) {
        const positions = {
          position: {
            lat: Number(location.coords.lat),
            lng: Number(location.coords.lng)
          }
        };
        locs.push(positions);
      }
      if (isShowDeliveryOption) {
        const radius = convertMilesToMeters(paidMilesRadius);
        const positions = circlePoints(center.lat, center.lng, radius);

        locs = [...locs, ...positions];
      }
      if (isShowLocations && locations.length !== 0) {
        const positions = locations.map((loc) => ({
          position: {
            lat: Number(loc.latitude),
            lng: Number(loc.longitude)
          }
        }));
        locs = [...locs, ...positions];
      }
      if (locs.length !== 0) {
        const bounds = new window.google.maps.LatLngBounds();
        locs.forEach(({ position }) => bounds.extend(position));
        m.fitBounds(bounds);
      }
    }
    if (!map) setMap(m);
  }, [
    location.coords,
    isShowDeliveryOption,
    locations,
    map,
    center,
    isShowLocations,
    paidMilesRadius
  ]);

  useEffect(() => {
    if (map) {
      handleOnLoad(map);
    }
  }, [
    handleOnLoad,
    location,
    location?.coords,
    location?.full_address,
    locations,
    map,
    isShowLocations,
    isShowDeliveryOption,
    center
  ]);

  return isLoaded ? (
    <>
      <div>
        <GoogleMap
          defaultZoom={4}
          mapContainerClassName={style.map}
          options={{
            disableDefaultUI: true
          }}
          onLoad={handleOnLoad}
        >
          {location.coords && (
            <>
              {isShowDeliveryOption && (
                <>
                  <Circle options={circleOptionsPaid} />
                  <Circle options={circleOptionsFree} />
                </>
              )}
              {!isShowDeliveryOption && <Circle options={circleOptions} />}
            </>
          )}

          <>
            { isShowLocations && locationMarkers}
          </>
        </GoogleMap>
      </div>
    </>
  ) : (
    "Loading..."
  );
});
