import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Button from "components/Button";
import { ChoosePhotosModal } from "components/ChoosePhotos";
import { ReactComponent as QuestionIcon } from "./question-icon.svg";
import Tooltip from "components/Tooltip/Tooltip";
import classnames from "classnames";
import classes from "./ChoosePhotosPanel.module.css";

export const ChoosePhotosPanel = (props) => {
  const {
    title = "My Garage photos",
    tooltipText = "Choose from existing photos you've added in My Garage. Once selected, the photos will be visible to renters on the platform.",
    photos = [],
    onContinue = () => {},
    onShowChoosePhotosModal,
    onCloseChoosePhotosModal
  } = props;

  const [showChoosePhotosModal, setShowChoosePhotosModal] = useState(false);

  const handleShowChoosePhotosModal = useCallback(() => {
    setShowChoosePhotosModal(true);
    onShowChoosePhotosModal();
  }, [onShowChoosePhotosModal]);

  const handleCloseChoosePhotosModal = useCallback(() => {
    setShowChoosePhotosModal(false);
    onCloseChoosePhotosModal();
  }, [onCloseChoosePhotosModal]);

  return (
    <div
      className={classnames(
        classes.container,
        ""
      )}
    >
      <div
        className={classnames(
          classes.inner,
          "l-16 r-16 t-16 b-16"
        )}
      >
        <div className={classes.content}>
          <Typography variant="subtitle" size="s" className={classes.title}>

            <span
              className={classnames(
                classes.questionIcon,
                "df mr-12"
              )}
            >
              <Tooltip
                tooltipContainerClassName={classes.questionTooltipContainer}
                tooltipContent={tooltipText}
                offset={[8, 8]}
                placement="top-start"
              >
                {(ref) => (
                  <QuestionIcon ref={ref} />
                )}
              </Tooltip>
            </span>

            {title}

          </Typography>

          <Button
            secondary
            className={classnames(
              classes.button,
              "mt-16"
            )}
            onClick={handleShowChoosePhotosModal}
            label="Choose photos"
          />
        </div>
      </div>

      { showChoosePhotosModal && (
        <ChoosePhotosModal
          show={showChoosePhotosModal}
          onClose={handleCloseChoosePhotosModal}
          onContinue={onContinue}
          photos={photos}
        />
      )}
    </div>
  );
};

ChoosePhotosPanel.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string,
        srcset: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string
          })
        )
      }),
      id: PropTypes.string,
      is_used_on_rentals: PropTypes.bool
    })
  ),
  title: PropTypes.string,
  tooltipText: PropTypes.string,
  onContinue: PropTypes.func.isRequired,
  onShowChoosePhotosModal: PropTypes.func.isRequired,
  onCloseChoosePhotosModal: PropTypes.func.isRequired
};
