// TODO ChangeBlock refactoring https://quintegro.atlassian.net/browse/CWRR-3518
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";
import { useForm } from "react-hook-form";
import { pathOr, path } from "ramda";
import classnames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { useJsApiLoader } from "@react-google-maps/api";
import { rvAvailabilityCalendar } from "queries/rvAvailabilityCalendar";
import { PickUpDeliveryModalProvider } from "processes/Booking/components/PickUpDeliveryModal/PickUpDeliveryModalProvider";
import { BookingCalendarProvider } from "processes/Booking/components/Calendar/BookingCalendarProvider";
import { tripInterruptionInsuranceCancellationContent } from "processes/Rentals/queries/tripInterruptionInsuranceCancellationContent";
import { useCalculatePrice } from "processes/Booking/CalculatePrice/useCalculatePrice";
import AddOnsModal from "components/AddOns/AddOnsModal";
import Spinner from "components/Spinner/Spinner";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import Form from "components/form/Form/Form";
import InfoBlock from "components/info/InfoBlock";
import EditableInfoTexts from "components/info/EditableInfoTexts";
import Button from "components/Button";
import BasicCollapse from "components/RcCollapse/BasicCollapse";
import Typography from "components/Typography";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { calendarIcon, editIcon } from "components/Icon";
import { FieldTextarea } from "components/form/FieldTextarea";
import { FieldInput } from "components/form/FieldInput";
import { FieldController } from "components/form/FieldController/FieldController";
import { isUserOwner, isUserRenter } from "../../utils";
import getLocationFromSuggestion from "../utils/getLocationFromSuggestion";
import formatDate from "../utils/formatDate";
import { RentalSuggestionType } from "../types";
import RentalFeeModal from "./RentalFeeModal";
import DeliveryFeeModal from "./DeliveryFeeModal";
import { Button as ButtonContent } from "components/ContentSection/elements";
import { PriceForm } from "components/PriceForm";
import { ACTION_IDS } from "constants/priceForm";
import cn from 'classnames';
import classes from "./ChangeBlock.module.css";

const getErrorText = path([[0], "message"]);

const isTII = path(['price', "trip_interruption_insurance", 'cents_total']);

const getInitialAddons = (suggestion, rv) => {
  return suggestion.map((i) => rv.add_ons?.find((a) => a.name === i)?.id);
};

const locationDataDefault = {
  1: {
    locationSelected: false,
    fullAddress: "",
    deliveryRadius: null,
    specificDeliveryLocation: null,
    radioState: 'location-1'
  },
  2: {
    locationSelected: false,
    fullAddress: "",
    deliveryRadius: null,
    specificDeliveryLocation: null,
    radioState: 'location-1'
  }
};

const libraries = ["places"];

const ChangeBlock = (props) => {
  const {
    className,
    currentSuggestName,
    modalShow,
    expandable,
    rental,
    onSubmit,
    isSingleBlock,
    isIMGlobalIntegrationIsEnabled,
    errors,
    setErrors
  } = props;

  // Data
  const isOwner = isUserOwner(rental.role);
  const isRenter = isUserRenter(rental.role);
  const { last_suggestion, confirmed_suggestion, rv, is_trip_interruption_insurance_changable, status } = rental;

  const suggestion = last_suggestion ?? confirmed_suggestion;

  const hasTII = isTII(rental) !== 0;

  const [state, setState] = useState(() => {
    return ({
      rental_fee: suggestion.price.rental_fee.cents_total / 100,
      delivery_fee: suggestion.price.delivery_fee.cents_total / 100,
      rental_fee_calculated: suggestion.price.rental_fee_calculated?.dollars_as_part,
      delivery_fee_calculated: suggestion.price.delivery_fee_calculated?.dollars_as_part,
      rental_type: Number(rental.rental_type.key),
      departure_date: suggestion.departure_date,
      return_date: suggestion.return_date,
      add_ons: getInitialAddons(suggestion.add_ons, rv),
      add_ons_names: suggestion.add_ons,
      delivery_location: suggestion.pick_up_location,
      pick_up_location: rental.rv.location.short_address,
      specific_delivery_location_id: suggestion.specific_delivery_location_id,
      coupon_code: suggestion.price.coupon_info?.code,
      is_trip_interruption_insurance: hasTII,
      total: suggestion.price.total?.user_friendly,
      locationData: locationDataDefault,
      init: false
    });
  });

  const [globalError, setGlobalError] = useState(null);
  const [isShowErrorNotificationPopover, setShowErrorNotificationPopover] = useState(false);
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(false);
  const [showInterruptionInsuranceField, setShowInterruptionInsuranceField] = useState(false);
  const [isDisabledButtonSubmit, setIsDisabledButtonSubmit] = useState(true);
  const [message, setMessage] = useState(currentSuggestName === "edit_suggestion" ? last_suggestion?.message || "" : "");
  const [iterruptionInsuranceValue, setIterruptionInsuranceValue] = useState("");
  const libRef = React.useRef(libraries);

  const GOOGLE_MAPS_API_KEY = window?.CWRR?.GoogleAPIKey || process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: "en",
    libraries: libRef.current
  });

  // Modals
  const [showAddOnsModal, toggleAddOnsModal] = useReducer((p) => !p, false);

  const [showRentalFeeModal, setShowRentalFeeModal] = useState(false);

  const [showDeliveryFeeModal, setShowDeliveryFeeModal] = useState(false);

  const [showPickUpModal, togglePickUpModal] = useReducer((p) => !p, false);

  const [showDatesModal, toggleDatesModal] = useReducer((p) => !p, false);

  // Utils
  const locationLabel = state.rental_type === 1 ? "Pick-up/Delivery location" : "Location";

  // Delivery
  const deliveryRadius = {
    1: rv.delivery_option,
    2: rv.dor_delivery_option
  };
  let paidDelivery = rv.paid_delivery;
  let specificDeliveryLocations = rv.specific_delivery_locations;

  if (state.rental_type === 2) {
    paidDelivery = rv.dor_paid_delivery;
    specificDeliveryLocations = rv.dor_specific_delivery_locations;
  }

  useEffect(() => {
    if (!state.init) {
      getLocationFromSuggestion(
        suggestion,
        specificDeliveryLocations,
        rental,
        state.rental_type,
        isLoaded
      ).then((locationData) => {
        setState((previousState) => ({
          ...previousState,
          locationData,
          init: true
        }));
      }).catch((error) => { console.info(error); });
    }
  }, [isLoaded, rental, state.rental_type, specificDeliveryLocations, suggestion, state.init]);

  const isStatusInProgress = status?.name === "In progress";
  const label = !isSingleBlock ? "Your suggestion" : "";
  const withMessage = !isSingleBlock || isOwner;
  const isCollapseBlock = useMatchMedia({
    maxWidth: isSingleBlock ? MQ_BP.small : MQ_BP.large
  });

  const hasPickUpLocation = rv.delivery_option
    || rv.dor_delivery_option
    || rv.specific_delivery_locations.length > 0
    || rv.dor_specific_delivery_locations.length > 0;

  // Addons
  const hasAddons = rv.add_ons?.length > 0;
  const hasSelectedAddons = state.add_ons?.length > 0;
  const selectedAddons = useMemo(() => {
    const result = [];
    rv.add_ons.forEach((add_on) => {
      const item = state.add_ons.find((a) => a === add_on.id);

      if (item) {
        result.push(add_on);
      }
    });

    return result;
  }, [rv.add_ons, state.add_ons]);

  // Callbacks
  const onShowRentalFeeModal = useCallback(
    () => {
      setShowRentalFeeModal(true);
    },
    []
  );

  const onCloseRentalFeeModal = useCallback(
    () => {
      setShowRentalFeeModal(false);
    },
    []
  );

  const onShowDeliveryFeeModal = useCallback(
    () => {
      setShowDeliveryFeeModal(true);
    },
    []
  );

  const onCloseDeliveryFeeModal = useCallback(
    () => {
      setShowDeliveryFeeModal(false);
    },
    []
  );

  const showFieldOrPopover = useCallback(
    () => {
      if (isIMGlobalIntegrationIsEnabled && hasTII) {
        if (!is_trip_interruption_insurance_changable) {
          setShowNotificationPopover(true);
        } else {
          setShowInterruptionInsuranceField(true);
        }
      }
      setIsDisabledButtonSubmit(false);
      setGlobalError(null);
      setShowErrorNotificationPopover(false);
    },
    [hasTII, isIMGlobalIntegrationIsEnabled, is_trip_interruption_insurance_changable]
  );


  const onSubmitRentalFee = useCallback(
    (value) => {
      setState((previousState) => ({
        ...previousState,
        rental_fee: Number(value),
        rental_fee_calculated: Number(value)
      }));
      onCloseRentalFeeModal();
      setIsDisabledButtonSubmit(false);
      setGlobalError(null);
      setShowErrorNotificationPopover(false);
    },
    [onCloseRentalFeeModal]
  );

  const onSubmitDeliveryFee = useCallback(
    (value) => {
      setState((previousState) => ({
        ...previousState,
        delivery_fee: Number(value),
        delivery_fee_calculated: Number(value)
      }));
      onCloseDeliveryFeeModal();
      setIsDisabledButtonSubmit(false);
      setGlobalError(null);
      setShowErrorNotificationPopover(false);
    },
    [onCloseDeliveryFeeModal]
  );

  const onSubmitAddOns = useCallback(
    (items) => {
      setState((previousState) => ({
        ...previousState,
        add_ons: items.map((i) => i.id),
        add_ons_names: items.map((i) => i.name),
        rental_fee: isRenter ? null : previousState.rental_fee,
        delivery_fee: isRenter ? null : previousState.delivery_fee,
        rental_fee_calculated: isRenter ? undefined : previousState.rental_fee_calculated,
        delivery_fee_calculated: isRenter ? undefined : previousState.delivery_fee_calculated
      }));

      toggleAddOnsModal();
      showFieldOrPopover();
    },
    [isRenter, showFieldOrPopover]
  );

  const {
    control,
    setError,
    clearErrors,
    formState: { errors: fieldsErrors }
  } = useForm({
    defaultValues: {
      signature: ''
    },
    mode: "onChange"
  });

  const [pricesResult, onCalculatePrice] = useCalculatePrice(rv.id, rental.id);

  useEffect(() => {
    if (state.init) {
      onCalculatePrice({
        departure_date: state.departure_date,
        return_date: state.return_date,
        delivery_location: state.delivery_location,
        coupon_code: state.coupon_code,
        rental_fee: typeof state.rental_fee_calculated === "number" ? Math.round(state.rental_fee) : null,
        delivery_fee: typeof state.delivery_fee_calculated === "number" ? Math.round(state.delivery_fee) : null,
        rental_type: state.rental_type,
        add_ons: state.add_ons,
        specific_delivery_location_id: state.specific_delivery_location_id,
        is_trip_interruption_insurance: state.is_trip_interruption_insurance
      });
    }
  }, [onCalculatePrice, state, state.add_ons, state.coupon_code, state.delivery_fee, state.delivery_location, state.departure_date, state.rental_fee, state.rental_type, state.return_date, state.specific_delivery_location_id, suggestion, suggestion.price.delivery_fee.dollars_as_part, suggestion.price.delivery_fee_calculated?.dollars_as_part, suggestion.price.rental_fee.dollars_as_part, suggestion.price.rental_fee_calculated?.dollars_as_part]);

  const submit = useCallback(() => {
    const submitData = {
      departure_date: state.departure_date,
      return_date: state.return_date,
      add_ons: state.add_ons,
      message: message,
      rental_fee: typeof state.rental_fee_calculated === "number" ? Math.round(state.rental_fee) : null,
      delivery_fee: typeof state.delivery_fee_calculated === "number" ? Math.round(state.delivery_fee) : null,
      signature_name: iterruptionInsuranceValue
    };

    // TODO check this condition
    switch (state.locationData[state.rental_type].radioState) {
    case "location-1":
      // free delivery
      onSubmit({ ...submitData });
      break;

    case "location-2":
      // delivery radius
      onSubmit({
        ...submitData,
        delivery_location: state.delivery_location
      });
      break;

    default:
      // specific delivery location
      onSubmit({
        ...submitData,
        specific_delivery_location_id: state.specific_delivery_location_id
      });
      break;
    }

    if (iterruptionInsuranceValue.length !== 0) {
      setShowInterruptionInsuranceField(false);
    } else {
      setError("signature", { message: "Signature is required" });
    }
    setShowNotificationPopover(false);
  }, [state.departure_date, state.return_date, state.add_ons, state.rental_fee_calculated, state.rental_fee, state.delivery_fee_calculated, state.delivery_fee, state.locationData, state.rental_type, state.delivery_location, state.specific_delivery_location_id, message, iterruptionInsuranceValue, onSubmit, setError]);

  const onSelectLocation = useCallback(
    (values) => {
      togglePickUpModal();

      setState((previousState) => ({
        ...previousState,
        delivery_location: values.deliveryLocation,
        specific_delivery_location_id: values.specific_delivery_location_id,
        locationData: values.locationData,
        pick_up_location: values.fullAddress,
        rental_fee: isRenter ? null : previousState.rental_fee,
        rental_fee_calculated: isRenter ? undefined : previousState.rental_fee_calculated,
        delivery_fee: values.deliveryFee?.dollars_as_part,
        delivery_fee_calculated: undefined
      }));

      showFieldOrPopover();
    },
    [isRenter, showFieldOrPopover]
  );

  const onSelectDate = useCallback(
    (from, to) => {
      setState((previousState) => ({
        ...previousState,
        departure_date: formatDate(from),
        return_date: formatDate(to),
        rental_fee: null,
        rental_fee_calculated: undefined,
        delivery_fee: isRenter ? null : previousState.delivery_fee,
        delivery_fee_calculated: isRenter ? undefined : previousState.delivery_fee_calculated
      }));

      showFieldOrPopover();
    },
    [isRenter, showFieldOrPopover]
  );


  const onPriceFormAction = useCallback((id) => {
    switch (id) {
    case ACTION_IDS.ADJUST_RENTAL_FEE:
      onShowRentalFeeModal();
      break;
    case ACTION_IDS.ADJUST_DELIVERY_FEE:
      onShowDeliveryFeeModal();
      break;
    default:
      break;
    }
  }, [onShowRentalFeeModal, onShowDeliveryFeeModal]);

  /*
   * Prices
   */

  const prices = pricesResult?.data;
  const price_form_your_suggestion = pricesResult?.price_form_your_suggestion;

  const totalPrice = isOwner ? prices?.owner_earns : prices?.payment_total;

  const pricesTable = price_form_your_suggestion ?
      (
        <PriceForm
          data={[price_form_your_suggestion]}
          onAction={onPriceFormAction}
        />
      ) : null;

  const divider = <div className="devider-solid mt-12 mb-12 mt-m-16 mb-m-16" />;

  const resultErrors = useMemo(() => {
    const pe = pricesResult?.errorMessage
      ? [{ message: pricesResult?.errorMessage }]
      : null;

    return pe || errors;
  }, [errors, pricesResult]);

  useEffect(() => {
    if (resultErrors) {
      setGlobalError(getErrorText(resultErrors));
      setShowErrorNotificationPopover(true);
    } else {
      setShowErrorNotificationPopover(false);
    }
  }, [resultErrors]);

  useEffect(() => {
    setShowErrorNotificationPopover(false);
    setGlobalError(null);
  }, []);

  /*
   * Queries
   */

  const { data: rvCalendar } = useQuery(rvAvailabilityCalendar, {
    variables: { id: rental?.rv?.id, current_rental: rental?.id },
    fetchPolicy: "no-cache",
    skip: !rental || !modalShow
  });

  const { data: tripInterruptionInsuranceCancellationData } = useQuery(tripInterruptionInsuranceCancellationContent, {
    variables: {
      section: "trip_interruption_insurance_cancellation"
    },
    fetchPolicy: "no-cache"
  });

  const tripIICValue = pathOr(
    "",
    ["tripInterruptionInsuranceCancellationContent", 'text', '0', 'value'],
    tripInterruptionInsuranceCancellationData
  );

  return (
    <>
      {isShowErrorNotificationPopover && globalError && getErrorText(resultErrors) !== "Signature is required" && (
        <NotificationPopover
          show={isShowErrorNotificationPopover}
          status="error"
          text={globalError}
          onClose={() => {
            setGlobalError(null);
            setShowErrorNotificationPopover(false);
            setErrors(null);
          }}
        />
      )}
      {isIMGlobalIntegrationIsEnabled && isShowNotificationPopover && !isOwner && hasTII && (
        <NotificationPopover
          show={true}
          notificationClassName={classes.notification}
          notificationInnerClassName={classes.notificationInner}
          status="warning"
          title="The coverage for trip interruption / interior protection insurance remains the prior amount purchased."
          text={(
            <span className="db mb-12">

              <span className="db">
                You may contact IMG to make date changes or to increase the insured trip cost via:
              </span>

              <span className="db">
                Email: {" "}
                <ButtonContent
                  id="imglobal_policies_email"
                  section="linksContent"
                  label="itravelpolicies@imglobal.com"
                  ghost
                  newTab
                  prefixLink="mailto:"
                  className={cn(classes.tiiLink, 'underline')}
                />
              </span>

              <span className="db">
                Website: {" "}
                <ButtonContent
                  id="imglobal"
                  section="linksContent"
                  label="Secure Message Center - IMG"
                  ghost
                  newTab
                  className={cn(classes.tiiLink, 'underline')}
                />
              </span>
            </span>
          )}
          onClose={() => {
            setShowNotificationPopover(false);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}

      <RentalFeeModal
        show={showRentalFeeModal}
        onClose={onCloseRentalFeeModal}
        onSubmit={onSubmitRentalFee}
      />

      <DeliveryFeeModal
        show={showDeliveryFeeModal}
        onClose={onCloseDeliveryFeeModal}
        onSubmit={onSubmitDeliveryFee}
      />

      {rvCalendar && (
        <BookingCalendarProvider
          rvId={rv.id}
          show={showDatesModal}
          onSelectDate={onSelectDate}
          onCloseCalendar={toggleDatesModal}
          total={state.total}
          departureDate={moment(state.departure_date).toDate()}
          returnDate={moment(state.return_date).toDate()}
          minimumDuration={rv.minimum_duration}
          availability={rvCalendar?.rv_availability_calendar}
          dailyRateCentsTotal={rv.daily_rate.cents_total}
          dailyRateDollarAsPart={rv.daily_rate.dollars_as_part}
          currentRentalId={rental.id}
          isDisabledPrevDates={!isStatusInProgress ? true : false}
          rentalType={state.rental_type}
          specific_delivery_location_id={state.specific_delivery_location_id}
        />
      )}
      <InfoBlock
        className={classnames(className, classes.root, {
          [classes.isSingleBlock]: isSingleBlock
        })}
        label={label}
        expandable={expandable}
        defaultOpen
      >
        <div className={classnames(classes.info, "df b-16")}>
          <div className={classnames(classes.main, "mr-80")}>
            <div className={classnames(classes.dates, "df")}>
              <EditableInfoTexts
                className={classes.date}
                label="Pick-up"
                value={formatDate(state.departure_date)}
                icon={calendarIcon}
                onClick={toggleDatesModal}
              />
              <div
                className={classnames(
                  classes.datesDivider,
                  "mt-8 mb-8 ml-16 mr-16 ml-m-24 mr-m-24"
                )}
              />
              <EditableInfoTexts
                className={classes.date}
                label="Drop off"
                value={formatDate(state.return_date)}
                icon={calendarIcon}
                onClick={toggleDatesModal}
              />
            </div>
            {hasPickUpLocation && (
              <>
                {divider}
                <EditableInfoTexts
                  label={locationLabel}
                  value={state.delivery_location || state.pick_up_location}
                  valueClassName={
                    !state.delivery_location && !rental?.pick_up_location ? classes.valueAlt : ""
                  }
                  icon={editIcon}
                  onClick={togglePickUpModal}
                />
                <PickUpDeliveryModalProvider
                  rvId={rv.id}
                  show={showPickUpModal}
                  onClosePickUpDeliveryModal={togglePickUpModal}
                  initialLocation={rv.location}
                  paidDelivery={paidDelivery}
                  onSelectLocation={onSelectLocation}
                  rentalType={state.rental_type}
                  locationData={state.locationData}
                  deliveryRadius={deliveryRadius}
                  specificDeliveryLocations={specificDeliveryLocations}
                />
              </>
            )}
            {hasAddons && (
              <>
                {divider}
                <EditableInfoTexts
                  label="Add-ons"
                  value={
                    !hasSelectedAddons
                      ? "None"
                      : state.add_ons_names.join(', ')
                  }
                  valueClassName={!hasSelectedAddons ? classes.valueAlt : ""}
                  icon={editIcon}
                  onClick={toggleAddOnsModal}
                />
                <AddOnsModal
                  list={rv.add_ons}
                  initialSelected={selectedAddons}
                  show={showAddOnsModal}
                  onClose={toggleAddOnsModal}
                  onSubmit={onSubmitAddOns}
                />
              </>
            )}
          </div>
          <div className={classnames(classes.prices)}>
            {isCollapseBlock ? (
              <div>
                {divider}
                <BasicCollapse
                  renderHeader={() => (
                    <Typography variant="body" size="l" className={classes.headerTitle}>
                      Rental pricing
                    </Typography>
                  )}
                >
                  {(open) => (
                    <div>
                      {open ? (
                        pricesTable
                      ) : (
                        <Typography variant="body" size="l">
                          {totalPrice ? `$${totalPrice?.user_friendly}` : ""}
                        </Typography>
                      )}
                    </div>
                  )}
                </BasicCollapse>
              </div>
            ) : (
              pricesTable
            )}
            {pricesResult.loading && (
              <div className="fcc t-40 b-40">
                <Spinner />
              </div>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          {withMessage && (
            <div className="t-16 t-m-20">
              <FieldTextarea
                resize="none"
                rows={3}
                className="fw"
                id="message"
                name="message"
                label="Your message"
                maxLength={500}
                helperMessage="(Optional)"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  showFieldOrPopover();
                }}
              />
            </div>
          )}
          {isIMGlobalIntegrationIsEnabled && showInterruptionInsuranceField && !isOwner && hasTII && (
            <div className="t-32 mt-2 t-m-40 m-t-0">
              <Typography variant="body" size="l" weight="bold">
                {tripIICValue}
              </Typography>
              <div className="t-8">
                <Form>
                  <FieldController
                    name="signature"
                    control={control}
                    render={(renderProps) => (
                      <FieldInput
                        {...renderProps}
                        errors={fieldsErrors}
                        placeholder="Signature"
                        id="signature"
                        className="fw"
                        onChange={(e) => {
                          setIterruptionInsuranceValue(e.target.value);
                          clearErrors("signature");
                          setIsDisabledButtonSubmit(false);
                          setGlobalError(null);
                          setShowErrorNotificationPopover(false);
                        }}
                        value={iterruptionInsuranceValue}
                      />
                    )}
                  />
                </Form>
              </div>

              <div className="t-2 t-m-12">
                <Typography variant="body" size="l">
                  If more than ten days have passed since the issuance of the existing policy at the time of the owner's approval of the your suggestion, the policy  and the coverage for trip interruption remains for the previous amount purchased.
                </Typography>
              </div>
            </div>
          )}
          <div
            className={classnames(
              classes.submit,
              "df b-16 b-m-20",
              isIMGlobalIntegrationIsEnabled && showInterruptionInsuranceField && !isOwner ? 't-12 t-m-20' : withMessage ? "t-28 t-m-40" : "t-12 t-m-20"
            )}
          >
            <Button
              label="Suggest a change"
              onClick={submit}
              disabled={globalError || isDisabledButtonSubmit}
            />
          </div>
        </div>
      </InfoBlock>
    </>
  );
};

ChangeBlock.propTypes = {
  className: PropTypes.string,
  modalRef: PropTypes.any.isRequired,
  modalShow: PropTypes.bool.isRequired,
  expandable: PropTypes.bool,
  data: RentalSuggestionType.isRequired,
  rental: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSingleBlock: PropTypes.bool,
  errors: PropTypes.any
};

export default ChangeBlock;
