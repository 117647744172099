export const PRICE_FORM_ITEM_STYLE = {
  COMMON: 0,
  HEAD: 1
};

export const PRICE_FORM_ITEM_SEPARATOR = {
  NONE: 0,
  SOLID: 1,
  DASH: 2,
  JOIN: 3
};

export const PRICE_FORM_ITEM_ACTION_POS = {
  OTHER: 0,
  BUTTON: 1,
  TITLE: 2,
  VALUE: 3
};

export const PRICE_FORM_ITEM_INFO_TYPE = {
  TOOLTIP: 0,
  MODAL: 1
};

export const PRICE_FORM_ITEM_TAGS = {
  BOLD: 'bold',
  DISCOUNT: 'discount',
  ACTION: 'action',
  LIGHT: 'light'
};

