import React from "react";
import Typography from "components/Typography";
import classes from "./MileageAndGenerator.module.css";

export const MileageAndGenerator = ({ title, value, units }) => {

  const valueText = `${value} ${units.toLowerCase()}`;

  return (
    <div className={classes.container}>
      <div>
        {title && (
          <Typography
            variant="body"
            size="l"
            className={classes.title}
          >
            {title}
          </Typography>
        )}
      </div>
      <div>
        {value && (
          <Typography
            variant="body"
            size="l"
            weight="bold"
            className={classes.value}
          >
            {valueText}
          </Typography>
        )}
      </div>
    </div>
  );
};
