/* eslint-disable indent */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import classes from "./BookingRentalDetailsModalContent.module.css";
import Typography from "../../../components/Typography";
import TooltipToggleQuestion from "../../../components/TooltipToggleQuestion/TooltipToggleQuestion";
import Button from "../../../components/Button";
import Spinner from "components/Spinner/Spinner";

const BookingRentalDetailsModalContentValue = ({ value, isGoodSamMember, isNegative }) => {
  const valueTextClassNames = isGoodSamMember
    ? `${classes.valueTextContainer} fw-500`
    : `${classes.valueTextContainer} ${classes.valueTextMembership} fw-500`;

  if (typeof value === "string") {
    return (
      <span
        className={`fw-500 ${valueTextClassNames} ${classes.valueText} ${classes.valueSimpleText}`}
      >
        {value}
      </span>
    );
  }
  let valueText =
    value.user_friendly === "Free" ? "Free" : isNegative ? `− $${value.user_friendly}` : `$${value.user_friendly}`;

  if (valueText === '$undefined') {
    valueText = <span className={classes.valueSpinner}><Spinner position="absolute" /></span>;
  }

  return (
    <span>
      <span className={`${classes.valueText} ${valueTextClassNames}`}>
        {valueText}
      </span>
    </span>
  );
};

const BookingRentalDetailsModalContent = (props) => {
  const {
    content,
    isGoodSamMember,
    setIsGoodSamMember,
    modalRef,
    isClientGoodSamMember
  } = props;

  return (
    <div className="t-8 t-m-12">
      <table className={classes.table}>
        <tbody>
          {content.items.map((item) =>
            item.id === "membership" &&
            (isClientGoodSamMember ||
              !isGoodSamMember ||
              item.user_friendly === "0.00") ? null : (
              <React.Fragment key={item.id}>
                <tr
                  className={classnames(
                    `${classes.row} `,
                    item.helperMessage && classes.rowBeforeHelper,
                    item.id === "membership" && classes.rowMembership,
                    item.bigRow && classes.bigRow
                  )}
                >
                  <td className={`${classes.cell} `}>
                    <div className={classnames(`${classes.cellContainer} `)}>
                      <div className={classes.label}>
                        <Typography variant="body" size="l" component="div">
                          <span className={`${classes.labelText}`}>
                            {item.label}
                          </span>
                          {item.id === "membership" && (
                            <div className={classes.labelBtnContainer}>
                              <Button
                                ghost
                                size="medium"
                                className={`${classes.btnLink} ${classes.labelBtn}`}
                                onClick={() => setIsGoodSamMember(false)}
                              >
                                Remove
                              </Button>
                            </div>
                          )}
                        </Typography>
                        {item.description && item.id !== "delivery_fee" && (
                          <div className={`${classes.tooltipBtn} t-8 b-8`}>
                            <TooltipToggleQuestion
                              boundaryRef={modalRef}
                              disablePortal
                            >
                                <Typography
                                  variant="body"
                                  size="l"
                                  component="div"
                                >
                                  {item.description}
                                </Typography>

                            </TooltipToggleQuestion>
                          </div>
                        )}
                        {item.description && item.id === "delivery_fee" && (
                          <div className={`${classes.tooltipBtn} t-8 b-8`}>
                            <TooltipToggleQuestion
                              boundaryRef={modalRef}
                              disablePortal
                            >
                                <Typography
                                  variant="body"
                                  size="l"
                                  component="div"
                                >
                                  {item.description}{" "}
                                  <b>This rate covers round-trip mileage.</b>
                                </Typography>
                            </TooltipToggleQuestion>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td
                    className={`${classes.cell} ${classes.cellValue} ${
                      item.id === "membership"
                        ? classes.cellValueMembership
                        : null
                    }`}
                  >
                    <div
                      className={`${classes.cellContainer} ${classes.cellValueContainer}`}
                    >
                      <Typography variant="body" size="l" component="div">
                        <BookingRentalDetailsModalContentValue
                          value={item.value}
                          isGoodSamMember={!(item.id === "membership")}
                          isNegative={item.id === "membership_saving"}
                        />
                      </Typography>
                    </div>
                  </td>
                </tr>
                {item.helperMessage ? (
                  <tr className={`${classes.row} ${classes.rowHelper}`}>
                    <td
                      colSpan="2"
                      className={`${classes.cell} ${classes.cellHelper}`}
                    >
                      <Typography
                        variant="body"
                        size="l"
                        component="div"
                        className={classes.colorInherit}
                      >
                        {item.helperMessage}
                      </Typography>
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            )
          )}
        </tbody>
        <tfoot>
          <tr className={`${classes.row} ${classes.rowFooter}`}>
            <td
              className={`${classes.cell} ${classes.cellLabel} ${classes.cellFooter}`}
            >
              <div className={`${classes.cellContainer}`}>
                <div className={classes.label}>
                  <Typography
                    variant="body"
                    size="l"
                    component="div"
                    className={`${classes.totalText}`}
                  >
                    <span className="fw-500">{content.total.label}</span>
                  </Typography>
                </div>
              </div>
            </td>
            <td
              className={`${classes.cell} ${classes.cellValue} ${classes.cellFooter}`}
            >
              <div
                className={`${classes.cellContainer} ${classes.cellValueContainer}`}
              >
                <Typography
                  variant="body"
                  size="l"
                  component="div"
                  className={`${classes.totalValueText}`}
                >
                  <BookingRentalDetailsModalContentValue
                    value={content.total.value}
                    isGoodSamMember={true}
                  />
                </Typography>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const propTypesValue = PropTypes.oneOfType([
  PropTypes.shape({
    cents_total: PropTypes.number.isRequired,
    cents_as_part: PropTypes.number.isRequired,
    dollars_as_part: PropTypes.number.isRequired
  }),
  PropTypes.string
]);

BookingRentalDetailsModalContent.propTypes = {
  content: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
        value: propTypesValue.isRequired,
        description: PropTypes.string.isRequired,
        helperMessage: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    total: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: propTypesValue.isRequired
    })
  }).isRequired,
  modalRef: PropTypes.object.isRequired,
  isGoodSamMember: PropTypes.bool.isRequired,
  setIsGoodSamMember: PropTypes.func.isRequired
};

export default BookingRentalDetailsModalContent;
