import React from "react";
import memoize from "lodash/memoize";
import { CellMeasurer } from "react-virtualized";
import VirtualItem from "./VirtualItem";

export function createRowRenderer(props) {
  const {
    data,
    cache,
    columnCount,
    isInFavorites,
    onChangeFavorite,
    searchData,
    mapOpen,
    breakpoints,
    userId
  } = props;

  const getItems = memoize((index) => {
    const start = index * columnCount;
    const end = start + columnCount;

    return data.slice(start, end);
  });

  return function rowRenderer({ key, index, parent, style }) {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild, measure }) => (
          <div key={key} style={style} ref={registerChild}>
            <VirtualItem
              items={getItems(index)}
              isInFavorites={isInFavorites}
              onChangeFavorite={onChangeFavorite}
              onReady={measure}
              searchData={searchData}
              mapOpen={mapOpen}
              breakpoints={breakpoints}
              userId={userId}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };
}
