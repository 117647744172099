import React from "react";
import { GroupItem } from "components/ModalInspections";

export const Group = ({ items = [] }) => (
  items.map((item) => {
    const { id } = item;

    return (
      <div key={id}>
        <GroupItem item={item} />
      </div>
    );
  })
);
