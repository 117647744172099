import React from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import classes from "./BookingRentalTypeModal.module.css";
import { ReactComponent as IconStandard } from "processes/Booking/BookingRentalTypeModal/icon-standard.svg";
import { ReactComponent as IconDeliveryOnly } from "processes/Booking/BookingRentalTypeModal/icon-delivery-only.svg";
import Modal from "components/Modal/Modal";

const BookingRentalTypeModal = (props) => {
  const { show, onClose } = props;

  return (
    <Modal
      show={show}
      onClose={onClose}
      showCross={true}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
      render={() => (
        <div>
          <Typography variant="headline" size="m" className={classes.title}>
            Rental types
          </Typography>

          <div className={`${classes.contentContainer} mt-32 mt-m-52`}>
            <div className={`${classes.item}`}>

              <div className={classes.iconStandardContainer}>
                <IconStandard />
              </div>

              <div className="mt-m-4">
                <Typography variant="subtitle" size="m" className={classes.subtitle}>
                Standard rental
                </Typography>

                <Typography variant="subtitle" size="s" className={`${classes.description}`}>
                This type of rental may offer both delivery and renter pick up options. If you are looking to rent a towable RV that you’ll pull or if you want to drive the RV yourself, this is the option for you. This rental type may also offer custom delivery options if you would like the RV delivered to your destination by the owner.
                </Typography>
              </div>
            </div>

            <div className={`mt-56 mt-m-80 t-m-4 mt-l-0 t-l-0 l-l-56 ml-l-64 ${classes.item}`}>

              <div className={classes.iconDeliveryOnlyContainer}>
                <IconDeliveryOnly />
              </div>

              <div  className="mt-m-4">
                <Typography variant="subtitle" size="m" className={classes.subtitle}>
                Delivery only rental
                </Typography>

                <Typography variant="subtitle" size="s" className={classes.description}>
                Are you looking to rent a larger RV, or don’t want the stress and hassle of driving or towing someone else’s property? This rental type is for you. These RVs are only available for delivery. Each owner sets their own rates and radius, so you will be able to see if delivery is available to your destination and how much it will cost.
                </Typography>
              </div>

            </div>
          </div>
        </div>
      )}
    />
  );
};

BookingRentalTypeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default BookingRentalTypeModal;
