/*
 * Chat utils
 */

export const CHAT_OPEN_EVENT_ID = "show_chat";

export const tagTypeByStatus = {
  inworks: "default",
  confirmed: "warning-dark",
  inprogress: "success"
};

export const getStatusPriority = (id) => ({
  inworks: 3,
  confirmed: 2,
  inprogress: 1
})[id] || 0;


export const antiExchangeFilter = (threads) => threads.filter((thread) => !thread?.channelState?.uniqueName?.endsWith('_exchange')); // filters only exchange threads

export const dispatchChatOpenEvent = (conversationId) => {
  document.dispatchEvent(new CustomEvent(CHAT_OPEN_EVENT_ID, {
    detail: {
      id: conversationId
    }
  }));
};
