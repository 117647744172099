import { MQ_BP, useMatchMedia } from "components/MatchMedia";

export function useTagSize() {
  const isMobile = useMatchMedia({ maxWidth: MQ_BP.small });
  const isTablet = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.medium });

  let size = 'large';

  if (isMobile) size = 'small';
  else if (isTablet) size = 'medium';

  return size;
}
