import React, { useState, useRef, useMemo, useEffect } from "react";
import Slider from "react-slick";
import { Modal } from "components/Modal";
import style from "./style.module.css";

const ModalCarousel = (props) => {
  const {
    showModal,
    onClose,
    photos = [],
    initialSlide = 0,
    modalWrapClassnames
  } = props;
  const mySlider = useRef();
  const [slideIndex, setSlideIndex] = useState(initialSlide);
  const showArrow = photos?.length > 1;

  useEffect(() => {
    if (!initialSlide) setSlideIndex(1);
    else setSlideIndex(initialSlide + 1);
  }, [initialSlide, setSlideIndex]);

  const settings = useMemo(() => ({
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slideIndex - 1,
    afterChange: current => {
      setSlideIndex(current + 1);
    }
  }), [slideIndex]);

  const renderArrows = () => {
    return (
      <div className="container mdl__slider-arrows-desktop-container">
        <div className="row">
          <div className="col-12">
            <div className="mdl__slider-arrows-desktop">
              <button
                className="slick-prev slick-arrow"
                type="button"
                aria-label="Previous"
                onClick={() => {
                  mySlider.current.slickPrev();
                }}
              >
                Previous
              </button>
              <button
                className="slick-next slick-arrow"
                type="button"
                aria-label="Next"
                onClick={() => {
                  mySlider.current.slickNext();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      onClose={() => {
        onClose();
      }}
      variant="full-screen"
      modalClassnames="mdl--slider"
      modalCrossButtonClassname={style.modalCarouselCrossButton}
      modalWrapClassnames={modalWrapClassnames}
    >
      {showArrow && (
        <div className="mdl__slider-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mdl__slider-header-controls mdl__slider-arrows-mobile">
                  <button
                    className="slick-prev slick-arrow dn-l"
                    type="button"
                    aria-label="Previous"
                    onClick={() => {
                      mySlider.current.slickPrev();
                    }}
                  >
                Previous
                  </button>

                  <ul className="mdl__slider-dots">
                    <li className="mdl__slider-dot js-mdl-slide-index">{slideIndex}</li>
                    <li className="mdl__slider-dot">/</li>
                    <li className="mdl__slider-dot js-mdl-slide-count">
                      {photos.length}
                    </li>
                  </ul>
                  <button
                    className="slick-next slick-arrow dn-l"
                    type="button"
                    aria-label="Next"
                    onClick={() => {
                      mySlider.current.slickNext();
                    }}
                  >
                Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row">
          <Slider
            ref={(slider) => (mySlider.current = slider)}
            {...settings}
            className="col-12 col-l-10 mdl-slider"
          >
            {photos.map(({ id, src, alt }) => {
              return (
                <div key={id}>
                  <div className="mdl-slider__slide-spacer">
                    <div className="mdl-slider__media r-8 l-8">
                      <img
                        className="mdl-slider__img"
                        src={src}
                        alt={alt}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {showArrow && (
            <div className="dn db-l">
              {renderArrows()}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalCarousel;
