import React from "react";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import { PhoneNumberVerificationFormContainer } from "processes/SignUp/PhoneNumberVerificationFormContainer";
import { ContentLoader } from "components/ContentSection";
import { signUpPhoneContentQuery } from "processes/SignUp/queries/signUpContentQuery";
import classes from "./PhoneVerifyModal.module.css";

function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const signUpPhoneContentPredicate = everyKey(["signUpPhone"]);

export const PhoneVerifyModal = ({ show, onClose, onVerified, userPhone }) => {
  return (
    <ContentLoader
      query={signUpPhoneContentQuery}
      predicate={signUpPhoneContentPredicate}
    >
      <Modal
        show={show}
        onClose={onClose}
        modalClassnames={`${classes.modal}`}
        isMobileFullScreen
      >
        <div className="t-m-12 b-m-28 l-m-56 r-m-56">
          <Typography
            className={classes.title}
            component="h6"
            variant="headline"
            size="m"
          >
            Phone number verification
          </Typography>
          <div className="b-16 b-m-20">
            <Typography
              className={classes.subtitle}
              component="h5"
              variant="subtitle"
              size="s"
            >
              Please provide your cell phone number below. In an effort to
              protect users, we verify your identity via SMS before you can
              create a booking request.
            </Typography>
          </div>
          <PhoneNumberVerificationFormContainer
            done={onVerified}
            initialPhone={userPhone}
            buttonsVariant="alt"
            onCancel={onClose}
          />
        </div>
      </Modal>
    </ContentLoader>
  );
};
