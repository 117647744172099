import { gql } from "@apollo/client";

export const additionalDriverRemoveMutation = gql`
  mutation additional_driver_remove ($rental: Int!, $email: String!) {
    additional_driver_remove(rental: $rental, email: $email) {
      result {
        success
        errors {
          code
          message
        }
      }
    }
  }
`;

