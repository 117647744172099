export const makeLogoutRequest = async (baseUrl) => {
  return await fetch(`${baseUrl}/site/ajax/auth/logout.php`, {
    body: JSON.stringify({ logout: 1 }),
    cache: 'no-cache',
    timeout: 60000,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    }
  });
};
