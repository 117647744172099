/*
 * Payout Form Hooks
 */

import { useMemo } from "react";
import { extractText } from "../../../../utils/extractText";
import { extractField } from "../../../../utils/extractField";
import { extractButton } from "../../../../utils/extractButton";
import { ACCOUNT_TYPES, DEFAULT_VALUES, FIELDS_IDS } from "./utils";
import { withDefaultValues } from "../../../../utils/basic";
import { DS_IDS } from "../../../../components/form/DateSelect/DateSelect";

export const useBasicTexts = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractText(content.text, key);
      return {
        title: e("section_title"),
        proTipsTitle: e("pro_tips_section_title"),
        proTipsText: e("pro_tips_section_text")
      };
    }
    return {};
  }, [content]);
};

export const useFormTexts = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractText(content.text, key);
      return {
        account: e("account_information_section_title"),
        name: e("person_name_section_title"),
        address: e("address_section_title"),
        companyRepresentative: e("company_representative_section_title"),
        companyInfo: e("company_information_section_title"),
        dateOfBirth: e("date_of_birth_label"),
        agreementText: e("agreement_text")
      };
    }
    return {};
  }, [content]);
};

export const usePreviewTexts = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractText(content.text, key);
      return {
        title: e("completed_view_title"),
        name: e("completed_view_name_label"),
        bank: e("completed_view_bank_label"),
        account: e("completed_view_account_label"),
        address: e("completed_view_address_label")
      };
    }
    return {};
  }, [content]);
};

export const useApiFields = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractField(content.form, key);
      return {
        individualAccountType: e("individual_account_type"),
        companyAccountType: e("company_account_type"),
        accountNumber: e("account_number"),
        routingNumber: e("routing_number"),
        ssn: e("ssn"),
        firstName: e("first_name"),
        lastName: e("last_name"),
        month: e("month"),
        day: e("day"),
        year: e("year"),
        companyName: e("company_name"),
        companyTaxId: e("company_tax_id"),
        address: e("address"),
        city: e("city"),
        state: e("state"),
        zip: e("zip")
      };
    }
    return {};
  }, [content]);
};

export const useApiButtons = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractButton(content.buttons, key);
      return {
        save: e("save_button"),
        edit: e("completed_view_update_button")
      };
    }
    return {};
  }, [content]);
};

export const useDefaultValues = (userData) => {
  const acc = userData?.bank_account;
  return useMemo(() => {
    if (!acc) {
      return DEFAULT_VALUES;
    }
    const accType = acc.account_type?.key;
    return withDefaultValues(
      {
        ...acc,
        [FIELDS_IDS._birthday]: {
          [DS_IDS.month]: acc.date_of_birth_month || "",
          [DS_IDS.day]: acc.date_of_birth_day || "",
          [DS_IDS.year]: acc.date_of_birth_year || ""
        },
        [FIELDS_IDS.account_type]:
          !accType || accType === ACCOUNT_TYPES.notSelected
            ? ACCOUNT_TYPES.individual
            : accType,
        // Clearing current value - required to fill it every time
        [FIELDS_IDS.account_number]: "",
        [FIELDS_IDS.ssn]: "",
        [FIELDS_IDS.agreement]: accType !== ACCOUNT_TYPES.notSelected
      },
      DEFAULT_VALUES
    );
  }, [acc]);
};
