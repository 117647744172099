import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import classnames from "classnames";
import Button from "components/Button";
import { closeIcon } from "components/Icon";
import { closeDarkThemeIcon } from "components/Icon";
import ActionBar from "components/ActionBar/ActionBar";
import Popper from "components/Popper/Popper";
import { useWidgetContext } from "../../../useWidgetContext";
import { FILTERS, isFilterSet } from "components/Widget/filters";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import moment from "moment";
import classes from "./FilterButton.module.css";

const FilterButton = (props) => {
  const {
    className = "",
    classNamefilterButton,
    id,
    popperClassName = "",
    getLabelFromValue,
    getSelectedCount,
    hanldeAfterClearFilter = () => {},
    renderForm,
    isActionBar = true,
    filterDate,
    closePopup = {
      close: false
    },
    setClousePopup = () => {}
  } = props;

  const {
    isMobileFilters,
    activeFilters,
    filtersControl,
    applyFilters,
    resetFilter,
    theme
  } = useWidgetContext();
  const buttonRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);

  const isSmall = useMatchMedia({ mediaQuery: "(min-width: 720px) and (max-width: 1023.98px)" });

  useEffect(() => {
    if (isMobileFilters) {
      setPopupOpen(false);
    }
  }, [isMobileFilters]);

  useEffect(() => {
    if (closePopup.close) {
      setPopupOpen(false);
      setClousePopup({ close: false });
    }
  }, [closePopup, setClousePopup]);

  return (
    <Controller
      name={id}
      control={filtersControl}
      render={({ value, onChange }) => {
        const isSet = isFilterSet(id, value);
        const activeValue = activeFilters?.[id];
        let isActiveSet = isFilterSet(id, activeValue);
        let selectedCount = isActiveSet && getSelectedCount
          && getSelectedCount(activeValue);
        let label = (getLabelFromValue && isActiveSet
          && getLabelFromValue(activeValue))
          || FILTERS.labels[id];

        if (id === 'date') {
          selectedCount = null;
          const from = moment(filterDate?.fullDeparture).format("MM-DD-YYYY");
          const to = moment(filterDate?.fullReturn).format("MM-DD-YYYY");
          label = `${from} - ${to}`;
          if (isSmall) {
            const from = moment(filterDate?.fullDeparture).format("MM/DD");
            const to = moment(filterDate?.fullReturn).format("MM/DD");
            label = `${from} - ${to}`;
          }
          if (!filterDate.departure || !filterDate.return) {
            label = 'Departure - Return';
            isActiveSet = false;
          }
        }
        return (
          <>
            <div
              className={classnames(
                className,
                isActiveSet ? classes.buttonWithClear : null,
                "l-8",
                "r-8"
              )}
            >
              <div className={classes.buttonWrapper} ref={buttonRef}>
                <Button
                  className={`${classes.button} ${classNamefilterButton} ${theme === 'dark' && classes.buttonDarkTheme}`}
                  // disabled={isFilterDisabled(id) && list?.length === 0}
                  renderAtEnd={isActiveSet ? () => (
                    <div
                      className={classes.clearIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        resetFilter(id);
                        applyFilters();
                        hanldeAfterClearFilter();
                      }}
                    >
                      {theme === 'dark' ? closeDarkThemeIcon : closeIcon}
                    </div>
                  ) : null}
                  iconPosition="after"
                  secondary
                  onClick={() => setPopupOpen(true)}
                >
                  {label}
                  {selectedCount > 0 && ` (${selectedCount})`}
                </Button>
              </div>
            </div>
            <Popper
              paperClassName={classnames([popperClassName, classes.popperPaper])}
              paperRootClassName={classes.popperRoot}
              open={popupOpen}
              anchorEl={buttonRef.current}
              onClose={() => setPopupOpen(false)}
              positionFixed={true}
            >
              {renderForm({
                value: value || FILTERS.defaults[id],
                onChange
              })}
              {isActionBar && (
                <ActionBar
                  theme={theme}
                  className={`t-40 b-20 ${classes.actionBar}`}
                  classNameClearButton={`${classes.clearButton} ${theme === 'dark' && classes.clearButtonDark}`}
                  onClear={!isSet ? null : () => {
                    resetFilter(id);
                  }}
                  onSubmit={() => {
                    setPopupOpen(false);
                    applyFilters();
                  }}
                />
              )}
            </Popper>
          </>
        );
      }}
    />
  );
};

FilterButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  getLabelFromValue: PropTypes.func,
  getSelectedCount: PropTypes.func,
  renderForm: PropTypes.func.isRequired,
  popperAtEnd: PropTypes.bool
};

export default FilterButton;
