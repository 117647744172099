import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CheckboxCard from "../cards/CheckboxCard";
import Typography from "../Typography";
import classes from "./VehicleClassCard.module.css";

const VehicleClassCard = (props) => {
  const {
    className,
    id,
    onChange,
    checked,
    image,
    name,
    description
  } = props;

  return (
    <CheckboxCard
      className={classnames([
        className,
        classes.root
      ])}
      classNameChecked={classnames([
        classes.rootChecked
      ])}
      tickClassName={classes.tick}
      id={id}
      checked={checked}
      onChange={onChange}
      variant="line"
    >
      <div className={classes.imageWrap}>
        <img className={classes.image} src={image} alt={name} />
      </div>
      <Typography className="t-m-8 b-m-8" variant="body" size="l">
        {name}
      </Typography>
      <Typography className="t-m-4 b-m-4" variant="body" size="s">
        {description}
      </Typography>
    </CheckboxCard>
  );
};

VehicleClassCard.defaultProps = {
  checked: false
};

VehicleClassCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default VehicleClassCard;
