import iconRentalProtection from "assets/images/good_sam_rental_protection-new.svg";
import React from "react";
import classes from "./Insurance.module.css";

export const Insurance = () => {
  return (
    <div className={`${classes.root}`}>
      <div className={`${classes.insurance}`}>
        <span className={`${classes.text}`}>Insurance Included</span>
      </div>
      <div className={`${classes.iconRental}`}>
        <img
          src={iconRentalProtection}
          alt="Icon"
          className={classes.iconRentalProtection}
        />
      </div>
    </div>
  );
};
