import React, { useCallback, useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  TouchSensor
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PhotoImage } from "components/PhotoImage";
import classes from "./PhotoGallery.module.css";

export function SortableItem({
  content,
  active,
  primary,
  onEdit,
  onDelete,
  disabled
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: content.id, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={active ? classes.activeCell : classes.cell}
    >
      <div className={classes.gaps}>
        <PhotoImage
          content={content}
          primary={primary}
          attributes={attributes}
          listeners={listeners}
          onEdit={onEdit}
          onDelete={onDelete}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export function PhotoGallery({ items, onReorder, onEdit, onDelete, disabled, noPrimary }) {
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback(
    ({ active }) => {
      setActiveId(active.id);
    },
    [setActiveId]
  );

  const handleDragEnd = useCallback(
    ({ active, over }) => {
      setActiveId(null);

      if (active.id === over.id) {
        return;
      }

      onReorder((items) => {
        const oldIndex = items.findIndex(({ id }) => id === active.id);
        const newIndex = items.findIndex(({ id }) => id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    },
    [onReorder, setActiveId]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, [setActiveId]);

  const activeIndex = items.findIndex(({ id }) => id === activeId);

  const dragOverlay = (
    <DragOverlay adjustScale={true}>
      {activeId ? (
        <div className={classes.gaps}>
          <PhotoImage content={items[activeIndex]} dragOverlay />
        </div>
      ) : null}
    </DragOverlay>
  );

  return (
    <div className={classes.row}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={items.map(({ id }) => id)}
          strategy={rectSortingStrategy}
        >
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              content={item}
              active={item.id === activeId}
              primary={!noPrimary && index === 0}
              onEdit={onEdit}
              onDelete={onDelete}
              disabled={disabled}
            />
          ))}
        </SortableContext>
        {dragOverlay}
      </DndContext>
    </div>
  );
}

PhotoGallery.defaultProps = {
  disabled: false
};
