import { gql } from "@apollo/client";

export const profileImageCropMutation = gql`
  mutation(
    $type: String!
    $rotation: Int!
    $crop_area_x1: Int!
    $crop_area_y1: Int!
    $crop_area_x2: Int!
    $crop_area_y2: Int!
  ) {
    profile_image_crop(
      type: $type
      rotation: $rotation
      crop_area_x1: $crop_area_x1
      crop_area_y1: $crop_area_y1
      crop_area_x2: $crop_area_x2
      crop_area_y2: $crop_area_y2
    ) {
      result {
        success
        errors {
          code
          field
          message
        }
      }
      image {
        id
        srcset {
          type
          url
        }
      }
    }
  }
`;
