export const getActiveSection = (location) => {
  switch (location) {
  case "/listing/availability": {
    return "listing_step_availability";
  }
  case "/listing/general-info": {
    return "listing_step_general_info";
  }
  case "/listing/rv-details": {
    return "listing_step_rv_details";
  }
  case "/listing/listing-details": {
    return "listing_step_listing_details";
  }
  case "/listing/photos": {
    return "listing_step_photos";
  }
  case "/listing/amenities": {
    return "listing_step_amenities";
  }
  case "/listing/location-delivery": {
    return "listing_step_location";
  }
  case "/listing/fees": {
    return "listing_step_fees";
  }
  case "/listing/add-ons": {
    return "listing_step_add_ons";
  }
  case "/listing/additional-insurance": {
    return "listing_step_additional_insurance";
  }
  default:
    return "/listing/general-info";
  }
};
