import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Rating = ({ value, maxValue }) => {
  const fullStarCount = Math.floor(value);
  const withHalfStar = value % 1 > 0;
  const generateElementsArray = useCallback(
    () =>
      Array.from({ length: maxValue }, (_, index) => (
        <div
          key={index.toString()}
          className={classnames("rating__star", {
            "rating__star--full": index < fullStarCount,
            "rating__star--half": index === fullStarCount && withHalfStar,
            "rating__star--empty":
              (withHalfStar && index > fullStarCount) ||
              (!withHalfStar && index >= fullStarCount)
          })}
        />
      )),
    [fullStarCount, maxValue, withHalfStar]
  );

  return <div className="rating">{generateElementsArray()}</div>;
};

Rating.propTypes = {
  value: PropTypes.number.isRequired
};

Rating.defaultProps = {
  value: 0,
  maxValue: 5
};

export default Rating;
