import React from "react";
import Typography from "components/Typography";
import { MapComponent } from "./components";
import classes from "./LocacionSection.module.css";

export const LocacionSection = () => {

  return (
    <div className={classes.container}>
      <Typography variant="subtitle" size="l" weight="bold">
        Location
      </Typography>
      <div className={`${classes.address} l-32`}>
        <Typography variant="body" size="l" weight="bold">
          Boston, MA
        </Typography>
      </div>
      <div className={`${classes.map} t-16 b-16 t-m-20 b-m-20`}>
        <MapComponent
          freeMilesRadius={10}
          paidMilesRadius={25}
          showGeoObjects={false}
          value={{
            "full_address": "Terminal Dr, Nashville, TN 37214, USA",
            "latitude": 36.134649,
            "longitude": -86.6680793
          }}
          className={classes.mapInner}
        />
      </div>
    </div>
  );
};

// LocacionSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

