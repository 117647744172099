import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Typography from "components/Typography";
import cn from "classnames";
import { LINKS_DEFAULT }  from "components/MembershipDetails/constants";
import classes from "./NotAMemberItem.module.css";

function getJoinButton(props) {
  const {
    built_in_join_process,
    join_button_url,
    join_button_caption,
    links: {
      joinLink
    } = {}
  } = props;

  if (built_in_join_process && typeof joinLink === 'function') {
    return (
      <>
        <Button
          size="medium"
          onClick={joinLink}
          primary
          className={cn('mr-16 mr-m-24 mr-xxl-32', classes.joinButton)}
        >
          {join_button_caption}
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          size="medium"
          primary
          href={join_button_url}
          target="_blank"
          noopener="true"
          norefferer="true"
          className={cn('mr-16', classes.joinButton)}
        >
          {join_button_caption}
        </Button>
      </>
    );
  }
}

function getMemberButton(props) {
  const {
    club_id,
    built_in_validation_process,
    validation_link_url,
    validation_link_caption,
    links: {
      memberLink
    } = {}
  } = props;

  if (built_in_validation_process && typeof memberLink === 'function') {
    return (
      <>
        <Button
          size="medium"
          ghost
          onClick={memberLink}
          primary
          className={cn(classes.memberButton)}
        >
          {validation_link_caption}
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          ghost
          size="medium"
          href={validation_link_url || LINKS_DEFAULT[club_id].MEMBER_LINK}
          target="_blank"
          noopener="true"
          norefferer="true"
          className={cn(classes.memberButton)}
        >
          {validation_link_caption}
        </Button>
      </>
    );
  }
}

export const NotAMemberItem = (props) => {
  const {
    club_name,
    club_icon,
    club_description
  } = props;

  return (
    <div className={cn(classes.container, 'b-8 b-m-20')}>
      <header className={classes.header}>
        <div className={classes.clubIconContainer}>
          <img
            src={club_icon}
            alt={club_name}
            className={classes.clubIcon}
          />
        </div>

        <div>
          <Typography
            component="h3"
            variant="subtitle"
            size="m"
            className={classes.clubName}
          >
            {club_name}
          </Typography>
        </div>
      </header>

      <div className={classes.content}>
        <Typography
          variant="body"
          size="l"
          className={classes.description}
        >
          {club_description}
        </Typography>
      </div>

      <footer className="t-16 b-16 t-m-20 b-m-12">
        {getJoinButton(props)}
        {getMemberButton(props)}
      </footer>
    </div>
  );
};

NotAMemberItem.propTypes = {

};
