import { arrayOf, number, oneOf, shape, string } from "prop-types";
import { ButtonType, MoneyType } from "utils/types";

export const RentalStatusType = shape({
  id: number.isRequired,
  name: string.isRequired,
  group: string.isRequired,
  style: oneOf(["default", "success", "warning", "danger"])
});

export const RentalProgressBarType = shape({
  item: string.isRequired,
  style: oneOf(["processing", "failed", "pending", "passed"])
});

export const RentalHistoryItemType = shape({
  action_buttons: arrayOf(ButtonType),
  add_ons: arrayOf(string),
  additional_text: string,
  date_time: string,
  departure_date: string,
  pick_up_location: string,
  price: MoneyType,
  return_date: string,
  text: string,
  title: string
});
