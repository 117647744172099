import React from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import classnames from "classnames";
import { RentalHistoryItemType } from "processes/Rentals/types";

import RequestMessageInfo from "./RequestMessageInfo";

import style from "./style.module.css";

const RequestMessage = (props) => {
  const {
    executeAction,
    item,
    isOwner,
    status,
    rental
  } = props;

  const bookingCancelledTitle = item?.text === 'Booking request was cancelled' ? 'Booking cancelled' : false;

  return (
    <div className="t-8 b-8 t-m-12 b-m-12" id={item.active_action ? 'activeHistoryAction' : undefined}>
      <div className={classnames(
        style.requestHistory__message,
        "t-4 b-8 l-16 r-16 mt-2 mt-m-0",
        "t-m-12 b-m-12 l-m-20 r-m-20"
      )}>
        <div className="request-message__header">
          {item.no_api_for_this_text_yet && ( // TODO: Change to real API field
            <Typography
              variant="body"
              size="s"
              className={style.systemMessage__mutedColorLight}>
              {item.no_api_for_this_text_yet}
            </Typography>
          )}

          {item.title && (
            <Typography
              variant="subtitle"
              size="s"
              component="h2"
            >
              {item.title}
            </Typography>
          )}

          {item.text && (
            <Typography
              className="ugc-wrap"
              variant="body"
              size="l">
              {item.text}
            </Typography>
          )}

          {bookingCancelledTitle && (
            <Typography
              variant="subtitle"
              size="s"
              component="h2"
              weight="bold">
              {bookingCancelledTitle}
            </Typography>
          )}
        </div>

        <RequestMessageInfo
          executeAction={executeAction}
          bookingInfo={item}
          isOwner={isOwner}
          status={status}
          rental={rental}
        />

        <div className={classnames(style.requestMessage__footer)}>
          <Typography
            variant="body"
            size="s"
            className={classnames(
              style.systemMessage__date,
              style.systemMessage__mutedColorLight,
              "tar"
            )}>
            {item.date_time}
          </Typography>
        </div>
      </div>
    </div>
  );
};

RequestMessage.propTypes = {
  executeAction: PropTypes.func.isRequired,
  item: RentalHistoryItemType.isRequired,
  isOwner: PropTypes.bool.isRequired
};

export default RequestMessage;
