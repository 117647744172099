import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const ButtonComponent = forwardRef(function ButtonComponent(
  { href, children, type, ...buttonProps },
  ref
) {
  if (href) {
    return (
      <a ref={ref} href={href} {...buttonProps}>
        {children}
      </a>
    );
  }

  return (
    <button ref={ref} type={type} {...buttonProps}>
      {children}
    </button>
  );
});

function getLabel(label, children) {
  return label ? label : children;
}

const BasicButton = forwardRef(function BasicButton(props, ref) {
  const {
    children,
    className,
    label,
    size,
    secondary,
    inverse,
    accent,
    ghost,
    icon,
    iconPosition,
    noLabel,
    animate,
    base,
    renderAtEnd,
    ...buttonProps
  } = props;

  const buttonLabel = getLabel(label, children);

  const buttonClassName = classnames(
    className,
    base && "btn-base",
    !base && "btn",
    size && `btn--size--${size}`,
    noLabel && `btn--no-label-${noLabel}`,
    {
      "btn--icon-only": !buttonLabel,
      "btn--secondary": secondary,
      "btn--inverse": inverse,
      "btn--accent": accent,
      "btn--ghost": ghost,
      animate
    }
  );

  return (
    <ButtonComponent ref={ref} className={buttonClassName} {...buttonProps}>
      {icon && iconPosition === "before" && (
        <span className="btn__icon">{icon}</span>
      )}
      {buttonLabel && !base && (
        <span className="btn__label">{buttonLabel}</span>
      )}
      {icon && (iconPosition === "after" || !iconPosition) && (
        <span className="btn__icon">{icon}</span>
      )}
      {renderAtEnd?.()}
      {base && children}
    </ButtonComponent>
  );
});

BasicButton.defaultProps = {
  type: "button"
};

BasicButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
  secondary: PropTypes.bool,
  inverse: PropTypes.bool,
  accent: PropTypes.bool,
  ghost: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  iconPosition: PropTypes.oneOf(["before", "after"]),
  noLabel: PropTypes.oneOf(["", "s", "m", "l", "xl", "xxl"]),
  animate: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  base: PropTypes.bool,
  renderAtEnd: PropTypes.func
};

export default BasicButton;
