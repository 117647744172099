import React from "react";
import { addonCheckedIcon, addonNotCheckedIcon } from "components/Icon";
import Typography from "components/Typography";
import cn from "classnames";
import classes from "./Addons.module.css";

export const Addons = ({ data: rawData, value: rawValue  }) => {
  const { values: items = [], comment } = JSON.parse(rawValue);
  const { names } = JSON.parse(rawData);

  const list = items.map((item, itemIndex) => {
    return (
      <div key={names[itemIndex]} className={classes.row}>
        <div>
          <div className={cn(classes.icon, 'mt-8')}>
            {item ? addonCheckedIcon : addonNotCheckedIcon}
          </div>
        </div>
        <div>
          <Typography
            variant="body"
            size="l"
            className={classes.value}
          >
            {names[itemIndex]}
          </Typography>
        </div>
      </div>
    );
  });

  return (
    <div className={cn(classes.container, 'row')}>
      <div className={cn(classes.list, 'col-12 col-m-6 col-xl-4 mb-16')}>
        {list}
      </div>
      <div className={cn(classes.comment, 'col-12 col-m-6 col-xl-4')}>
        <Typography
          variant="body"
          size="l"
          className={cn(classes.commentText)}
        >
          {comment}
        </Typography>
      </div>
    </div>
  );
};
