/*
 * Basic Utils
 */

export const NBSP_CODE = "\u00A0";

export const getObjectPositiveValuesCount = (value) => {
  return Object.values(value || {}).filter(Boolean).length;
};

export const includesSubArray = (main, sub) => {
  if (!Array.isArray(main) || !Array.isArray(sub)) {
    return false;
  }
  return sub.every(i => main.includes(i));
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getDaysInMonth = (month, year = getCurrentYear()) => {
  return parseInt(new Date(year, month, 0).getDate());
};

export const getTodayObject = () => {
  const today = new Date();
  return {
    day: today.getDate(),
    month: today.getMonth() + 1,
    year: today.getFullYear()
  };
};

export const withDefaultValues = (values, defaultValues) => {
  const result = { ...defaultValues, ...values };

  Object.keys(values).forEach((key) => {
    const value = result[key];
    if (value === null || value === undefined) {
      result[key] = defaultValues[key];
    }
  });

  return result;
};

export const prependDots = (value, dotsLength = 8) => {
  return value ? [
    ...[...Array(dotsLength)].map(i => "•"),
    value
  ].join("") : "";
};

export const isElementScrolledAtBottom = (element) => {
  return element && (
    element.scrollTop === element.scrollHeight - element.clientHeight
  );
};

export const getMailLink = (email) => {
  return `mailto:${email}`;
};

export const removeNonDigits = (string) => {
  return string?.replace(/\D/g, "");
};
