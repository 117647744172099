import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../../components/Typography";
import { MatchMedia } from "../../../../components/MatchMedia";
import RcCollapse from "../../../../components/RcCollapse";
import { SpinnerBack } from "../../../../components/Spinner/Spinner";
import classes from "./FormContainer.module.css";

const FormContainer = (props) => {
  const { title, children, proTipsTitle, proTipsText, loading } = props;

  if (loading) {
    return <SpinnerBack />;
  }

  return (
    <div className={classes.container}>
      <Typography
        component="h2"
        variant="headline"
        size="s"
        className="mb-xl-0"
      >
        {title}
      </Typography>
      {Boolean(proTipsTitle && proTipsText) && (
        <MatchMedia mediaQuery="(max-width: 767px)">
          <div className="t-16 b-16">
            <RcCollapse header={proTipsTitle} content={proTipsText} />
          </div>
        </MatchMedia>
      )}
      {children}
    </div>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  proTipsTitle: PropTypes.string,
  proTipsText: PropTypes.string,
  loading: PropTypes.bool
};

export default FormContainer;
