import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { extractServerErrors } from "utils/extractErrors";
import { emailVerifyMutation } from "./mutations/emailVerifyMutation";
import { PROCESSES } from "utils/constants/processes";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import { useRequestErrors } from "hooks/useRequestErrors";
import Message from "components/Message";
import classes from "./EmailVerifyModal.module.css";
import { SpinnerBack } from "../Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { getViewParam, BACK_URL_PARAM_KEY } from "router/useLoginRedirect";

const mutationName = "email_verify";
const extractEmailVerifyErrors = extractServerErrors(mutationName);

function createUrlParam(history) {
  const { pathname, search, hash } = history.location;
  const viewParam = getViewParam(pathname);
  const btnParam = '&btn=0';

  return `${BACK_URL_PARAM_KEY}=${
    encodeURIComponent([pathname, search, hash].join(""))
  }${viewParam}${btnParam}`;
}

export const EmailVerifyModal = ({ show, onClose, description, process = "" }) => {
  const [loading, setLoading] = useState(show);
  const history = useHistory();
  const urlParam = process === PROCESSES.BOOKING ? createUrlParam(history) : undefined;

  const [action, result] = useMutation(emailVerifyMutation, {
    onCompleted: () => setLoading(false)
  });
  const errors = extractEmailVerifyErrors(result);
  const [globalError] = useRequestErrors(errors);

  useEffect(() => {
    if (show) {
      setLoading(true);
      void action(
        {
          variables: {
            params_url: urlParam
          }
        }
      );
    }
  }, [action, urlParam, show]);

  if (loading) {
    return <SpinnerBack />;
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
      variant="basic"
    >
      <Typography component="h6" variant="headline" size="m">
        Email verification
      </Typography>
      {globalError ? (
        <Message type="error" text={globalError} />
      ) : (
        <Typography component="h5" variant="subtitle" size="s">
          {description ||
          "Your email is not verified. A verification email has been sent to your email. Please check and follow the instructions to continue."}
        </Typography>
      )}
    </Modal>
  );
};
