import React, { forwardRef, useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import classes from "./DateSelect.module.css";
import Select from "../Select/Select";
import { getCurrentYear, getDaysInMonth } from "../../../utils/basic";
import Typography from "../../Typography";

export const DS_IDS = {
  month: "month",
  day: "day",
  year: "year"
};

const FIELDS = [
  { id: DS_IDS.month, label: "Month" },
  { id: DS_IDS.day, label: "Day" },
  { id: DS_IDS.year, label: "Year" }
];

const DateSelect = forwardRef((props, ref) => {
  const {
    className,
    value,
    label,
    onChange,
    hasError,
    errorMessage,
    helperMessage,
    name,
    errors
  } = props;

  const _onChange = useCallback((value) => {
    const daysInMonth = getDaysInMonth(value.month, value.year);
    if (value.month && daysInMonth < value.day) {
      value = { ...value, day: daysInMonth.toString() };
    }
    onChange(value);
  }, [onChange]);

  const hint = errorMessage || helperMessage;

  return (
    <div ref={ref} className={classnames(className, classes.root)}>
      {label && (
        <div className="b-8">
          <Typography variant="body" size="l">
            {label}
          </Typography>
        </div>
      )}
      <div
        className={classnames(
          "field-input fw",
          {
            error: hasError
          }
        )}
      >
        <div
          className={classnames(
            classes.outerField,
            "field-input__field df fw no-gutters"
          )}
        >
          {FIELDS.map(({ id, label }) => (
            <Select
              hasError={errors?.[name]?.type === id}
              className={classnames(classes.select, "col")}
              key={id}
              id={id}
              label={label}
              options={id === DS_IDS.day && value[DS_IDS.month]
                ? createOptions(1, getDaysInMonth(
                  value[DS_IDS.month],
                  value.year
                ))
                : OPTIONS[id]
              }
              value={value[id] || ""}
              onChange={(_value) => _onChange({ ...value, [id]: _value })}
            />
          ))}
        </div>
        {hint && (
          <div className="field-input__helpers">
            <div className="field-input__hint">
              {hint}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export const DS_EMPTY_VALUE = {
  [DS_IDS.month]: "",
  [DS_IDS.day]: "",
  [DS_IDS.year]: ""
};

const createOptions = (min, max, reverse = false) => {
  let items = [...Array(max - min + 1)].map((i, index) => {
    const value = (index + min).toString();
    return { value: value.length === 1 ? `0${value}` : value, text: value.length === 1 ? `0${value}` : value };
  });

  items = reverse ? items.reverse() : items;
  return [{ value: "", text: "" }, ...items];
};

const OPTIONS = {
  [DS_IDS.day]: createOptions(1, 31),
  [DS_IDS.month]: createOptions(1, 12),
  [DS_IDS.year]: createOptions(1900, getCurrentYear(), true)
};

DateSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    month: PropTypes.string,
    day: PropTypes.string,
    year: PropTypes.string
  }),
  label: PropTypes.node,
  onChange: PropTypes.func,
  helperMessage: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasError: PropTypes.bool
};

export default DateSelect;
