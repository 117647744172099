import moment from "moment";
import { GROUP_TYPES } from "components/ModalInspections/constants";

const filteredGroups = [0, 2, 3, 4];

export function filterGroupsValue(item) {
  const { type: groupType = -1, data } = item;

  try {
    const { hiddenOnValue, units = null } = JSON.parse(data);
    const value = item?.value === "" ? "" : JSON.parse(item?.value);

    switch (groupType) {
    case GROUP_TYPES.CHECK:
      return value?.value !== hiddenOnValue;
    case GROUP_TYPES.INT:
      if (units) {
        return value !== hiddenOnValue;
      } else {
        return true;
      }
    case GROUP_TYPES.STR:
      return value !== hiddenOnValue;
    case GROUP_TYPES.SELECT:
      return value?.value !== hiddenOnValue;
    default:
      return true;
    }
  } catch (e) {
    return false;
  }

}

export function filterGroups(group) {
  const { items = [] } = group;
  const fitleredItems = items.filter(item => {
    const { type = -1 } = item;

    if (filteredGroups.includes(type)) {
      return filterGroupsValue(item);
    }
    return true;
  });

  if (fitleredItems?.length > 0) {
    return {
      ...group,
      items: fitleredItems
    };
  } else {
    return false;
  }
}

export function addAdditionalGroups(data) {
  return [
    {
      id: "signature",
      disp_title: {
        value: ""
      },
      isWidthTwoThirds: true,
      items: [
        {
          type: "signature",
          id: 0,
          title: "Renter signature",
          items: [
            {
              id: "renter-signature",
              title: "Renter signature",
              url: data?.signature_renter
            },
            {
              id: "owner-signature",
              title: "Owner signature",
              url: data?.signature_owner
            }
          ]
        }
      ]
    },
    {
      id: "inspection-summary",
      disp_title: {
        value: "Inspection summary"
      },
      isFullWidth: true,
      items: [
        {
          id: 37,
          data: JSON.stringify({ "type": "date" }),
          title: "Time started",
          type: 2,
          value: JSON.stringify({
            date: moment.unix(data?.time_start?.timestamp).format("MM/DD/YY"),
            time: moment.unix(data?.time_start?.timestamp).format("h:mm A")
          })
        },
        {
          id: 38,
          data: JSON.stringify({ "type": "date" }),
          title: "Time finished",
          type: 2,
          value: JSON.stringify({
            date: moment.unix(data?.time_end?.timestamp).format("MM/DD/YY"),
            time: moment.unix(data?.time_end?.timestamp).format("h:mm A")
          })
        }
      ]
    }
  ];
}

export function setFullWidthParameter(groups = []) {
  return groups.map((group) => {
    const type = group?.items?.[0]?.type;

    if (type === 1 || type === 6) {
      return { ...group, isFullWidth: true };
    }

    return group;
  });
}

export function sortGroups(groups, ids) {
  const newGroups = [];

  ids.forEach((groupId) => {
    const group = groups.find(({ id }) => groupId === id);
    newGroups.push(group);
  });

  return newGroups;
}

export function getGroups(data = {}) {
  const { groups: initialGroups = [], view_group_ids = [] } = data;
  const groups = setFullWidthParameter(sortGroups(initialGroups, view_group_ids));
  const additionslGroups = addAdditionalGroups(data);

  const result = [
    ...groups,
    ...additionslGroups
  ].map((group) => {
    if (group) {
      return filterGroups(group);
    }
    return false;
  }).filter(Boolean);

  return result;
}
