import classnames from "classnames";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import icon from "assets/images/good_sam_small_text.svg";
import { Controller } from "react-hook-form";
import { RX_INT } from "../../../../utils/constants/RX";
import { isSafeInt } from "../../../../utils/isSafeInt";
import Button from "../../../Button";
import { FieldArray } from "../../../form/FieldArray";
import { FieldInput } from "../../../form/FieldInput";
import { trashIcon } from "../../../Icon";
import Message from "../../../Message";
import { SpinnerBack } from "components/Spinner/Spinner";
import Typography from "../../../Typography";
import MembershipBanner from "../../../MembershipBanner";
import { InstantRentals } from "components/Listing/components/InstantRentals/InstantRentals";
import { isSafePrice } from "../../../../utils/isSafePrice";
import { useLazyQuery } from "@apollo/client";
import { userQuery } from "./queries/userQuery";
import BecomeMemberShip from "components/BecomeMemberShip";
import classes from "./Fees.module.css";
import { LabelContent } from "components/ContentSection/LabelContent";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import { membershipDetailsQuery } from "queries/membershipDetailsQuery";
import { isGoodSamMember } from "utils/isGoodSamMember";

function useControlModalVisibility() {
  const [visibility, setVisibility] = useState(false);

  const hide = useCallback(() => {
    setVisibility(false);
  }, [setVisibility]);

  const show = useCallback(() => {
    setVisibility(true);
  }, [setVisibility]);

  return {
    visibility,
    show,
    hide
  };
}

function useFetchUserData() {
  const [
    membershipDetailsRefetch, { loading: membershipDetailsLoading, data: membershipDetailsData }
  ] = useLazyQuery(membershipDetailsQuery,{
    fetchPolicy: "no-cache"
  });

  const [refetch, result] = useLazyQuery(userQuery, {
    fetchPolicy: "no-cache"
  });

  const {
    loading: userQueryLoading,
    data: userQueryData
  } = result;

  useEffect(() => {
    if (refetch) refetch();
    if (membershipDetailsRefetch) membershipDetailsRefetch();
  }, [refetch, membershipDetailsRefetch]);

  const userData = userQueryData?.user;

  return {
    userData,
    isClientGoodSamMember: isGoodSamMember(membershipDetailsData?.membership_details),
    loading: userQueryLoading || !userQueryData || membershipDetailsLoading || !membershipDetailsData,
    refetch,
    membershipDetailsRefetch
  };
}

function useGoodSamPanelMode(ownerIncomeForecastQueryData, daily_rate, isClientGoodSamMbr) {
  const [mode, setMode] = useState(0);
  const [isClientGoodSamMember, setClientGoodSamMember] = useState(isClientGoodSamMbr);

  useEffect(() => {
    setClientGoodSamMember(isClientGoodSamMbr);
  }, [isClientGoodSamMbr]);

  const discount = ownerIncomeForecastQueryData?.owner_income_forecast?.member_discount;

  const isBecomeMemberWithoutDiscount = !isClientGoodSamMember && discount === 0;
  const isBecomeMemberDiscount = !isClientGoodSamMember && discount > 0;
  useEffect(() => {
    if (isBecomeMemberWithoutDiscount) {
      setMode(1);
    } else if (isBecomeMemberDiscount) {
      if (Number(daily_rate) > 0) {
        setMode(2);
      } else {
        setMode(0);
      }
    } else if (isClientGoodSamMember) {
      setMode(0);
    }
  }, [
    setMode,
    isBecomeMemberWithoutDiscount,
    isBecomeMemberDiscount,
    daily_rate,
    isClientGoodSamMember
  ]);

  return [mode, setClientGoodSamMember];
}

export const FeesFormContent = ({
  contentData,
  control,
  errors,
  setError,
  getValues,
  clearErrors,
  triggerFormValidate,
  discountsDefaultValues,
  validateDiscounts,
  rowError,
  ownerIncomeForecastQueryData,
  loadingOwnerIncomeForecastQuery,
  daily_rate,
  isSwitchInstantRentals,
  onCloseModalPayoutForm
}) => {
  const {
    visibility: isShowBecomeMemberShip,
    show: showModal,
    hide: hideModal
  } = useControlModalVisibility();

  const {
    userData,
    isClientGoodSamMember,
    loading: loadingMembershipStatus,
    refetch,
    membershipDetailsRefetch
  } = useFetchUserData();

  const [mode, setClientGoodSamMember] = useGoodSamPanelMode(ownerIncomeForecastQueryData, daily_rate, isClientGoodSamMember);

  if (loadingMembershipStatus) {
    return <SpinnerBack />;
  }

  return (
    <>
      <BecomeMemberShip
        show={isShowBecomeMemberShip}
        setIsShowMembershipModal={showModal}
        onCloseBecomeMemberShip={hideModal}
        handleActionAfterLastStep={() => {
          refetch();
          membershipDetailsRefetch();
          setClientGoodSamMember(true);
        }}
      />
      <div className="row t-0 t-m-12 t-xl-0">
        <div className="col col-m-6">
          <div className="row t-s-20 b-s-20">
            <div className="col t-16 t-m-20 b-16 b-m-20">
              <Controller
                name="minimum_duration"
                control={control}
                rules={{
                  required: "Nights minimum is required"
                }}
                render={(renderProps) => (
                  <LabelContent
                    section="rentalFeesSectionContent"
                    id="minimum_duration"
                  >
                    {(label) => (
                      <FieldInput
                        className="fw"
                        id="minimum_duration"
                        label={label}
                        errors={errors}
                        {...renderProps}
                        onChange={(e) => {
                          renderProps.onChange(e.target.value);
                        }}
                        helperMessage={
                          <Text
                            id="minimum_duration_description"
                            section="rentalFeesSectionContent"
                          />
                        }
                      />
                    )}
                  </LabelContent>
                )}
              />
            </div>
            <div className="col t-16 t-m-20 b-16 b-m-20">
              <Controller
                name="daily_rate"
                control={control}
                rules={{
                  required: "Nightly rate is required",
                  validate: (v) => {
                    if (!isSafePrice(parseInt(v, 10))) return "Incorrect input";
                  },
                  pattern: {
                    value: RX_INT,
                    message: "Incorrect input"
                  }
                }}
                render={(renderProps) => (
                  <LabelContent
                    section="rentalFeesSectionContent"
                    id="daily_rate"
                  >
                    {(label) => (
                      <FieldInput
                        masked
                        className="fw"
                        id="daily_rate"
                        label={label}
                        errors={errors}
                        helperMessage={
                          contentData.suggested_daily_rate !== '0.00'
                            ? `Suggested price: $${contentData.suggested_daily_rate}`
                            : null
                        }
                        {...renderProps}
                      />
                    )}
                  </LabelContent>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {mode === 1 && (
        <div
          className={`${classes.becomeMemberShip} db tac df-m fjc t-4 b-32 t-m-20 b-m-20 mt-32 mb-16 mt-m-20 mb-m-20`}
        >
          <div className="fcc mr-m-32 mb-8 mb-m-0">
            <Typography variant="body" size="l">
              Become a{" "}
            </Typography>
            <span className="ml-4 mr-4">
              <img src={icon} alt="Icon" />{" "}
            </span>
            <Typography variant="body" size="l">
              Member{" "}
            </Typography>
          </div>
          <div className="">
            <Button
              secondary
              label="Choose subscription plan"
              onClick={showModal}
            />
          </div>
        </div>
      )}
      {mode === 2 && (
        <MembershipBanner
          className="t-32 t-m-20 b-16 b-m-20"
          isClientGoodSamMember={isClientGoodSamMember}
          ownerIncomeForecastQueryData={ownerIncomeForecastQueryData}
          loadingOwnerIncomeForecastQuery={loadingOwnerIncomeForecastQuery}
          handleActionAfterLastStep={() => {
            refetch();
            membershipDetailsRefetch();
          }}
        />
      )}

      <InstantRentals
        userData={userData}
        updateUserData={() => {
          refetch();
          membershipDetailsRefetch();
        }}
        onCloseModalPayoutForm={onCloseModalPayoutForm}
        form={{
          control,
          errors,
          setError,
          clearErrors,
          isSwitchInstantRentals
        }}
      />
      <div className="t-48 t-m-20 b-16 t-m-60">
        <div className="row">
          <div className="col">
            <Text
              id="discounts_section_title"
              section="rentalFeesSectionContent"
              component="h6"
              variant="subtitle"
            />
          </div>
        </div>
        <div className="t-16 t-m-20 b-16 b-m-20">
          <FieldArray
            control={control}
            trigger={triggerFormValidate}
            name="discounts"
            defaultValues={discountsDefaultValues}
            errors={errors}
            renderRow={(rowProps) => (
              <div key={rowProps.id} className="row f-nowrap">
                <Controller
                  control={control}
                  name={`${rowProps.fieldName}[${rowProps.rowIndex}].from`}
                  defaultValue={rowProps.from}
                  rules={{
                    required: "From is required",
                    validate: (v) => {
                      if (!isSafeInt(parseInt(v, 10))) return "Incorrect input";
                    },
                    pattern: {
                      value: RX_INT,
                      message: "Incorrect input"
                    }
                  }}
                  render={(renderProps) => (
                    <LabelContent
                      section="rentalFeesSectionContent"
                      id="discount_from"
                    >
                      {(label) => (
                        <div className="col col-m-3 r-0 r-m-8 r-l-12 r-xxl-16 t-20 b-20">
                          <FieldInput
                            {...renderProps}
                            onChange={(e) => {
                              renderProps.onChange(e.target.value);
                              triggerFormValidate(
                                `${rowProps.fieldName}[${rowProps.rowIndex}].from`
                              );
                            }}
                            className="field-input--fees-from field-input--no-right-radius field-input--m-right-radius field-input--no-right-border field-input--m-right-border"
                            id={`from-${rowProps.rowIndex}`}
                            label={label}
                            helperMessage={
                              <Text
                                id="discount_from_description"
                                section="rentalFeesSectionContent"
                              />
                            }
                            errors={errors}
                          />
                        </div>
                      )}
                    </LabelContent>
                  )}
                />
                <Controller
                  control={control}
                  name={`${rowProps.fieldName}[${rowProps.rowIndex}].till`}
                  defaultValue={rowProps.till}
                  render={(renderProps) => {
                    const discounts = getValues('discounts');
                    let value = "";

                    if (rowProps.rowIndex === discounts?.length - 1) {
                      value = "Unlimited";
                    } else if ((rowProps.rowIndex === discounts?.length - 2) && (discounts?.[rowProps.rowIndex + 1]?.from === "" || errors?.discounts?.[rowProps.rowIndex+1]?.from)) {
                      value = "";
                    } else if (discounts?.[rowProps.rowIndex + 1]?.from !== "") {
                      value = String(Number(discounts?.[rowProps.rowIndex + 1]?.from) - 1);
                    } else {
                      value = "Unlimited";
                    }

                    return  (
                      <LabelContent
                        section="rentalFeesSectionContent"
                        id="discount_till"
                      >
                        {(label) => (
                          <div className="col col-m-3 r-0 r-m-8 r-l-12 r-xxl-16 l-0 l-m-8 l-l-12 l-xxl-16 t-20 b-20">
                            <FieldInput
                              {...renderProps}
                              onChange={(e) => {
                                renderProps.onChange(e.target.value);
                                triggerFormValidate(
                                  `${rowProps.fieldName}[${rowProps.rowIndex}].discount`
                                );
                              }}
                              className="field-input--fees-days field-input field-input--no-left-radius field-input--no-right-radius field-input--m-left-radius field-input--m-right-radius"
                              id={`till-${rowProps.rowIndex}`}
                              label={label}
                              helperMessage={
                                <Text
                                  id="discount_till_description"
                                  section="rentalFeesSectionContent"
                                />
                              }
                              disabled
                              value={value}
                            />
                          </div>
                        )}
                      </LabelContent>
                    );}}

                />
                <Controller
                  control={control}
                  name={`${rowProps.fieldName}[${rowProps.rowIndex}].discount`}
                  defaultValue={rowProps.discount}
                  rules={{
                    required: "Discount is required",
                    validate: (v) => {
                      const discount = parseInt(v, 10);

                      if (
                        !isSafeInt(discount) ||
                        discount < 0 ||
                        discount > 99
                      ) {
                        return "Incorrect input";
                      }
                    },
                    pattern: {
                      value: RX_INT,
                      message: "Incorrect input"
                    }
                  }}
                  render={(renderProps) => (
                    <LabelContent
                      section="rentalFeesSectionContent"
                      id="discount_value"
                    >
                      {(label) => (
                        <div className="col col-m-3 l-0 l-m-8 l-l-12 l-xxl-16 t-20 b-20">
                          <FieldInput
                            {...renderProps}
                            onChange={(e) => {
                              renderProps.onChange(e.target.value);
                              triggerFormValidate(
                                `${rowProps.fieldName}[${rowProps.rowIndex}].discount`
                              );
                            }}
                            className="field-input--fees-discount field-input--no-left-radius field-input--m-left-radius field-input--no-left-border field-input--m-left-border"
                            id={`discount-${rowProps.rowIndex}`}
                            label={label}
                            errors={errors}
                            helperMessage={
                              <Text
                                id="discount_value_description"
                                section="rentalFeesSectionContent"
                              />
                            }
                          />
                        </div>
                      )}
                    </LabelContent>
                  )}
                />
                <div className="col-auto col-m-3 t-20 b-20 l-0 l-m-8 l-l-12 l-xxl-16">
                  <div className="btn-group">
                    <Button
                      secondary
                      icon={trashIcon}
                      onClick={rowProps.handleRemove}
                    />
                  </div>
                </div>
              </div>
            )}
            render={({ handleAdd, rowsCount }) => (
              <>
                {rowError && (
                  <div className="">
                    <Message type="error" text={rowError} />
                  </div>
                )}
                <div
                  className={classnames(
                    "btn-group",
                    rowsCount > 0 && "t-32 t-m-40"
                  )}
                >
                  <ButtonContent
                    id="add_discount_button"
                    section="rentalFeesSectionContent"
                    onClick={() => {
                      validateDiscounts(true);
                      handleAdd();
                    }}
                    secondary
                  />
                </div>
              </>
            )}
          />
        </div>
      </div>
      <div className="t-16 t-20 b-16 b-m-20">
        <div className="row">
          <div className="col">
            <Text
              id="security_deposit_section_title"
              section="rentalFeesSectionContent"
              component="h6"
              variant="subtitle"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-m-4 t-16 t-m-20 b-16 b-m-20">
            <Controller
              name="security_deposit"
              control={control}
              rules={{
                required: "Security deposit is required",
                validate: (v) => {
                  if (!isSafePrice(parseInt(v, 10))) return "Incorrect input";
                },
                pattern: {
                  value: RX_INT,
                  message: "Incorrect input"
                }
              }}
              render={(renderProps) => (
                <LabelContent
                  section="rentalFeesSectionContent"
                  id="security_deposit"
                >
                  {(label) => (
                    <FieldInput
                      masked
                      className="fw"
                      id="security_deposit"
                      label={label}
                      errors={errors}
                      {...renderProps}
                    />
                  )}
                </LabelContent>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};
