import { useCallback, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { manufacturersQuery } from "./queries/manufacturersQuery";
import { extractList, filterList } from "./utils";

const extractManufacturersList = extractList("rv_manufacturers");

export function useSourceManufacturers() {
  const [load, { data }] = useLazyQuery(manufacturersQuery);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) load();

    return () => {
      isMounted.current = false;
    };
  }, [load]);

  const filter = filterList(extractManufacturersList(data));

  return useCallback(async (term) => filter(term), [filter]);
}
