import React, { memo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "../../Typography";
import { arrowIcon, closeIcon } from "../../Icon";
import { ReactComponent as ChatLogoImg } from "components/chat/assets/chat-logo-rv-rentals-new.svg";
import { Checkbox } from "../../form";
import Tag from "../../Tag";
import { tagTypeByStatus } from "../utils";
import { useHistory } from "react-router-dom";
import classes from "./Header.module.css";

const Header = (props) => {
  const history = useHistory();
  const {
    title,
    onBack,
    onClose,
    unreadOnly,
    onChangeUnreadOnly,
    rentals,
    hasActiveThread
  } = props;

  const { pathname } = history.location;
  const startPathname = pathname.substring(0, 4);
  const wl = startPathname === '/wl/';

  return (
    <div className={classes.root}>
      <div className={classnames(classes.top, "df fcc")}>
        {onBack && !wl && (
          <div
            className={classnames(classes.backIcon, "df fcc")}
            onClick={onBack}
          >
            {arrowIcon}
          </div>
        )}
        <Typography className="tac" variant="subtitle" size="s" weight="bold">
          {title}
        </Typography>
        {onClose && (
          <div
            className={classnames(classes.closeIcon, "df fcc")}
            onClick={onClose}
          >
            {closeIcon}
          </div>
        )}
      </div>
      { hasActiveThread && wl && (
        <div
          onClick={onBack}
          className={classnames(classes.subHeader, "df fbc l-16 r-16")}
        >
          <div>
            <Typography variant="body" size="l" weight="bold">
              View other conversations at <span className={classes.subHeaderBackLink}>RV Rentals</span>
            </Typography>
          </div>
          <div className={classnames(classes.subHeaderLogo, "mt-8")}>
            <ChatLogoImg />
          </div>
        </div>
      )}
      {onChangeUnreadOnly && (
        <div className="t-m-8 l-24 r-24 b-8 mb-4 mb-m-2">
          <Checkbox
            text="Unread only"
            checked={unreadOnly}
            onChange={onChangeUnreadOnly}
          />
        </div>
      )}
      {rentals && (
        <div className={classnames(classes.rentals, "r-24 b-12 l-24")}>
          {rentals?.map(i => (
            <div className={classnames(classes.rental, "df")}>
              <Typography className={classnames(classes.rentalLabel, "r-8")}>
                Rental:
              </Typography>
              <a
                className={classes.rentalLink}
                href={i.link}
                target="_blank"
                rel="noreferrer"
              >
                {i.name}
              </a>
              <Tag
                size="small"
                text={i.status.text}
                type={tagTypeByStatus[i.status.id]}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  unreadOnly: PropTypes.bool,
  onChangeUnreadOnly: PropTypes.func,
  rentals: PropTypes.array
};

export default memo(Header);
