import { number, shape, string } from "prop-types";
import { MoneyType } from "utils/types";

export const AddOnListItemType = shape({
  id: number.isRequired,
  name: string.isRequired,
  price: MoneyType.isRequired,
  price_type: shape({ value: string })
});

export const AddOnSelectedItemType = AddOnListItemType;
