import React, { useCallback, useState } from "react";
import { PriceFormItem, PriceFormInfoModal } from "components/PriceForm";
import { TextItem } from "components/TextItem";
import {
  PRICE_FORM_ITEM_TAGS
} from "components/PriceForm/constants";
import cn from "classnames";
import classes from "./PriceForm.module.css";

const PriceForm = (props) => {
  const {
    data = [],
    specRender,
    onAction
  } = props;

  const [infoModalData, setInfoModalData] = useState({ show: false, title: "", data: { type: null, value: "" } });

  const onInfo = useCallback((info) => {
    if (info) {
      setInfoModalData({
        title: info?.title,
        data: info?.data,
        show: true
      });
    }
  }, []);

  const onCloseInfoModal = useCallback((info) => {
    setInfoModalData((data) => {
      return {
        ...data,
        show: false
      };
    });
  }, []);

  const formsItems = data?.[0]?.items;
  const desc = data?.[0]?.desc;

  const descTagDesc = [
    {
      tag: PRICE_FORM_ITEM_TAGS.BOLD,
      classNames: cn([classes.colorTextBodyLight, classes.bodyLBold])
    }
  ];

  if (!formsItems?.length) {
    return null;
  }

  return (
    <div>
      <div>
        {formsItems.map((item, itemIndex) => {
          if (!item?.type) {
            return (
              <PriceFormItem
                forms={data}
                key={itemIndex}
                itemInd={itemIndex}
                onAction={onAction}
                onInfo={onInfo}
              />
            );
          }

          if (specRender) {
            const specElem = specRender(item, itemIndex);

            return specElem || null;
          }

          return null;
        })}
      </div>

      {Boolean(desc?.value) && (
        <div className={classes.desc}>
          <TextItem
            data={desc}
            plainStyle={classes.formDescCommonStyle}
            tagCommonStyle={classes.formDescCommonStyle}
            htmlStyle={classes.formDescHtmlStyle}
            tagDesc={descTagDesc}
          />
        </div>
      )}

      <PriceFormInfoModal
        data={infoModalData}
        onAction={onAction}
        onClose={onCloseInfoModal}
      />
    </div>
  );
};

PriceForm.propTypes = {
};

export default PriceForm;
