import PropTypes from "prop-types";
import React from "react";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import Message from "../../../Message";
import Button from "components/Button";
import ProTipsCollapse from "../../../ProTipsCollapse";
import { PhotoCoverImage } from "components/PhotoCoverImage";
import { PhotoGallery } from "components/PhotoGallery";
import { PhotoUploadArea } from "components/PhotoUploadArea";
import { ChoosePhotosPanel } from "components/ChoosePhotos";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

export const PhotosContentLoader = (props) => {
  const {
    items,
    isGarage,
    garagePhotos,
    onReorder,
    onEdit,
    onDelete,
    onUpload,
    onBack,
    onLater,
    onSave,
    onGaragePhotosContinue,
    onShowGaragePhotosModal,
    onCloseGaragePhotosModal,
    loading,
    requestErrors,
    listingPublished,
    prevStep,
    nextStep
  } = props;
  const hasItems = items.length > 0;
  const handleOnDelete = items.length > 1 ? onDelete : null;
  const [globalError] = useRequestErrors(requestErrors);
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  return (
    <div>
      <Text
        id="step_title"
        section="rvPhotosSectionContent"
        component="h2"
        variant="headline"
        size="s"
        className="mb-16 mb-s-20 mb-m-8 mb-xl-20"
      />

      <ProTipsCollapse className="t-16 b-32" mediaParams="767px" />
      {(isGarage && garagePhotos?.length > 0) && (
        <div className="mb-16 mb-m-40 mt-m-32 mt-xl-16">
          <ChoosePhotosPanel
            photos={garagePhotos}
            onContinue={onGaragePhotosContinue}
            onShowChoosePhotosModal={onShowGaragePhotosModal}
            onCloseChoosePhotosModal={onCloseGaragePhotosModal}
          />
        </div>
      )}

      {hasItems && (
        <PhotoCoverImage
          content={items[0]}
          onEdit={onEdit}
          onDelete={handleOnDelete}
          className="b-40"
          disabled={loading}
        />
      )}
      <PhotoUploadArea
        extended={!hasItems}
        onUpload={(files) => onUpload(files)}
        disabled={loading}
      />
      {hasItems && (
        <PhotoGallery
          items={items}
          onReorder={onReorder}
          onEdit={onEdit}
          onDelete={handleOnDelete}
          disabled={loading}
        />
      )}
      {globalError && (
        <div className="t-16 t-m-40">
          <Message type="error" text={globalError} />
        </div>
      )}
      {!listingPublished && (
        <div className="row">
          { nextStep && isSmallDevice && (
            <div className="col-9 t-36 b-36">
              <Button disabled={loading} onClick={onLater} secondary>
                Skip
              </Button>
            </div>
          )}

          <div className="col t-16 t-m-40 b-16 b-m-20">
            <div className="btn-group btn-group--center btn-group--m-start btn-group_xs-fixed">
              {prevStep && (
                <ButtonContent
                  id="previous_button"
                  section="rvPhotosSectionContent"
                  secondary
                  type="button"
                  label="Previous"
                  onClick={onBack}
                  disabled={loading}
                />
              )}
              {nextStep && !isSmallDevice && (
                <Button disabled={loading} onClick={onLater} secondary>
                  Skip
                </Button>
              )}

              <ButtonContent
                id="save_and_next_button"
                section="rvPhotosSectionContent"
                onClick={onSave}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PhotosContentLoader.defaultProps = {
  items: [],
  loading: false
};

PhotosContentLoader.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      srcset: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          type: PropTypes.string
        })
      )
    })
  ),
  onBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  listingPublished: PropTypes.bool.isRequired,
  requestErrors: PropTypes.arrayOf(
    PropTypes.exact({
      field: PropTypes.string,
      message: PropTypes.string
    })
  )
};
