import React, { useState, useCallback } from "react";
import Button from "components/Button";
import Typography from "../../../../components/Typography";
import Tag from "components/Tag";
import { DriverVerifyModalCheck } from "components/Verification/DriverVerifyModal/DriverVerifyModalCheck";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import RcCollapse from "components/RcCollapse";
import { useUrlParams } from "components/Verification/DriverVerifyModal/hooks/useUrlParams";
import { DRIVER_AUTH_PASSED } from "components/Verification/DriverVerifyModal/constants";
import classes from "./DriverVerificationContent.module.css";
import { generateMedallionToken } from "components/Verification/DriverVerifyModal/mutations/generateMedallionToken";
import { SpinnerBack } from "components/Spinner/Spinner";
import { useQuery, useMutation } from "@apollo/client";
import { getErrorsAndSuccess } from "utils/extractErrors";
import { medallionDataQuery } from "components/Verification/DriverVerifyModal/queries/medallionDataQuery";
import { replace_url } from "components/Verification/DriverVerifyModal/constants";

function getTagStatus(data) {
  const key = data?.driver_verification?.key;
  const styles = {};
  if (key === "0") {
    styles.text = 'NOT VERIFIED';
    styles.type = 'default';
  } else if (key === '1' || key === '2' || key === '3' || key === '4' || key === '8') {
    styles.text = 'PENDING VERIFICATION';
    styles.type = 'warning-dark';
  } else if (key === '6' || key === '7') {
    styles.text = 'DECLINED';
    styles.type = 'error';
  } else if (key === '5') {
    styles.text = 'VERIFIED';
    styles.type = 'success';
  }
  return styles;

}

const medallionDataEAS = getErrorsAndSuccess("medallion_data");
const generateMedallionTokenMutationEAS = getErrorsAndSuccess("generate_medallion_token");

export const DriverVerificationContent = (props) => {
  const [isShowDriverVerifyModal, setShowDriverVerifyModal] = useState(false);
  const { content, userData, refetchUserQuery, refetchDriverVerificationPending, showVerificationButton } = props;
  const tagStyles = getTagStatus(userData);
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  const [error, setError] = useState("");

  const {
    loading: medallionDataQueryLoading,
    data: medallionDataQueryData,
    refetch: refetchMedallionDataQueryData
  } = useQuery(medallionDataQuery, {
    fetchPolicy: "no-cache"
  });

  const [generateMedallionTokenAction, { loading: generateMedallionTokenLoading }] = useMutation(generateMedallionToken);

  const handleDigisureClose = useCallback(() => {
    setShowDriverVerifyModal(false);
  }, []);

  const {
    proTipsSectionTitle,
    proTipsSectionText,
    sectionDescription = "",
    sectionTitle,
    verificationButton
  } = content;

  const onShowDriverVerifyModal = useCallback(async() => {
    const response = await refetchMedallionDataQueryData();
    const { date_of_birth, first_name, last_name, middle_name } = medallionDataEAS.getQueryData(response);

    if (date_of_birth && first_name && last_name) {
      const response = await generateMedallionTokenAction({
        variables: {
          date_of_birth,
          first_name,
          middle_name,
          last_name,
          redirect_url: `${window.location.origin}/account/driver-verification`
        }
      });
      if (generateMedallionTokenMutationEAS.isSuccess(response)) {
        const token = response.data.generate_medallion_token.token;
        window.location.replace(`${replace_url}${token}`);
      } else if(generateMedallionTokenMutationEAS.isErrors(response)) {
        setError(generateMedallionTokenMutationEAS.errorMessage(response));
      }
    } else {
      setShowDriverVerifyModal(true);
    }
  }, [generateMedallionTokenAction, refetchMedallionDataQueryData]);


  return (
    <div>
      <div className="">
        <div className="mb-m-20 mb-xl-8">
          <Typography
            variant="headline"
            size="s"
            className=""
          >
            {sectionTitle}
          </Typography>
        </div>

        <div className="t-16 b-32 dn-m">
          <RcCollapse
            header={proTipsSectionTitle}
            content={proTipsSectionText}
          />
        </div>

        <div className="devider-solid dn db-m mt-m-20 mb-m-20" />

        <div className="mt-m-48 mt-xl-44 mb-16 mb-m-0 b-2 b-m-0">
          <div className="fbc">
            <div>
              <Typography
                variant="subtitle"
                size="m"
                className={classes.title}
              >
                {!isSmallDevice ? "Verification status" : "Status"}
              </Typography>
            </div>
            <div className="mt-2 mt-m-0 mt-xl-2">
              <Tag
                size={isSmallDevice ? 'medium' : 'large'}
                {...tagStyles}
              />
            </div>
          </div>

          {showVerificationButton && (
            <div className="t-20 b-12 t-m-28 b-m-12 t-xl-28 b-xl-12">
              <Button
                {...verificationButton}
                accent
                onClick={onShowDriverVerifyModal}
                className={classes.verificationButton}
              />
            </div>
          )}

          {sectionDescription?.length > 0 && (
            <div className="b-12 b-m-24 t-4 t-m-16 t-xl-16">
              <Typography
                variant="body"
                size="l"
                className={classes.description}
              >
                {sectionDescription}
              </Typography>
            </div>
          )}
        </div>

        <DriverVerifyModalCheck
          show={isShowDriverVerifyModal}
          isNeedVerify={showVerificationButton}
          onClose={handleDigisureClose}
          onDriverVerificationModalSubmitSuccess={handleDigisureClose}
          redirectURL={`${window.location.origin}/account/driver-verification`}
        />

        {(medallionDataQueryLoading || generateMedallionTokenLoading) && <SpinnerBack />}
      </div>
    </div>
  );
};
