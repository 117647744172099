export const GROUP_TYPES = {
  CHECK: 0,
  PHOTO: 1,
  INT: 2,
  STR: 3,
  SELECT: 4,
  REMINDER: 5,
  ADDONS: 6,
  SIGNATURE: "signature"
};
