import React from "react";
import PropTypes from "prop-types";
import mobileBadge from "processes/PublicProfile/images/badge-goodsam-user-desktop.svg";
import desctopBadge from "processes/PublicProfile/images/badge-goodsam-mobile.svg";
import avatarEmpty from "processes/PublicProfile/images/avatar-placeholder.svg";
import classes from "./ProfileAvatar.module.css";

const ProfileAvatar = ({ avatar: { src, alt } = {}, hasBadge }) => (
  <div className={`${classes.avatar} ${hasBadge ? '' : 'mb-m-20'}`}>
    <div>
      <img
        className={classes.avatarImg}
        src={src || avatarEmpty}
        alt={alt}
      />
    </div>
    {hasBadge && (
      <div className={`${classes.avatarBadgeContainer}`}>
        <div className={`${classes.avatarBadgeContent}`}>
          <picture>
            <source srcSet={desctopBadge} media="(min-width: 1024px) and (max-width: 1439.98px)" />
            <img src={mobileBadge} alt="Good Sam" />
          </picture>
        </div>
      </div>
    )}
  </div>
);

ProfileAvatar.propTypes = {
  avatar: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  hasBadge: PropTypes.bool
};

export default ProfileAvatar;
