import { Modal } from "components/Modal";
import React from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import classes from "./Booking.module.css";

export const BookingModal = ({
  isAllData,
  children,
  isShowModalBookingWidget,
  setIsShowModalBookingWidget
}) => {
  const isMediumDevice = useMatchMedia({ maxWidth: MQ_BP.medium });
  const mdlRef = useScrollBarWidth();
  if (!(isAllData && isMediumDevice && isShowModalBookingWidget)) {
    return children(false);
  }

  return (
    <Modal
      show={isShowModalBookingWidget}
      onClose={() => setIsShowModalBookingWidget(false)}
      modalWrapClassnames={classes.modalRoot}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
      mdlRef={mdlRef}
    >
      {children(true)}
    </Modal>
  );
};
