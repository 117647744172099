import moment from "moment";

export const dateFormat = (date, placeholder) => {
  if (!date) {
    return placeholder || "?";
  }
  return moment(date).format("M/DD/YYYY");
};

export const normalizeEntries = (entries) => {
  if (!entries) return null;
  const normalizedData = {};
  for (let i = 0; i < entries.length; i++) {
    normalizedData[moment(entries[i].date).format("YYYY-MM-DD")] = entries[i];
  }
  // console.log(normalizedData);
  return normalizedData;
};
