import React, { forwardRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { BigDayPicker } from "../../../BigDayPicker";
import { renderDay } from "./renderDay";
import classes from "./AvailabilityCalendar.module.css";
import { AvailabilityInfo } from "./AvailabilityInfo";

function createDateRange(from, to, options) {
  const result = [];

  for (let d = moment(from); d.diff(to, "days") <= 0; d.add(1, "day")) {
    result.push({
      date: d.format("YYYY-MM-DD"),
      ...options
    });
  }

  return result;
}

function getPrice(centsTotal) {
  return centsTotal ? Math.round(centsTotal / 100) : 0;
}

function overwriteDates(list) {
  const tuple = list.reverse().reduce(
    (acc, item) => {
      const { date } = item;

      if (!acc[1][date]) {
        acc[1][date] = true;
        acc[0].push(item);
      }

      return acc;
    },
    [[], {}]
  );

  return tuple[0].sort((a, b) => {
    if (a.date === b.date) {
      return 0;
    }

    return a.date < b.date ? -1 : 1;
  });
}

function getPriceProp(value) {
  return value && typeof value === "object"
    ? getPrice(value.cents_total)
    : value;
}

function getPropForRange(prop, data, from, to, defaultValue, solver) {
  if (!from || !to) {
    return defaultValue;
  }

  return createDateRange(from, to, { [prop]: defaultValue })
    .map((item) => {
      const changedItem = data.find((d) => d.date === item.date);

      return changedItem ? changedItem : item;
    })
    .reduce((acc, item) => {
      const p = getPriceProp(item[prop]);

      if (typeof acc === "undefined") {
        return p;
      }

      if (acc !== p) {
        return solver ? solver(acc, p, defaultValue) : defaultValue;
      }

      return acc;
    }, undefined);
}

export const AvailabilityCalendar = forwardRef((props, ref) => {
  const { dailyRate, minimumDailyRate, value: availability, onChange } = props;

  const [value, setValue] = useState({});
  const { from, to } = value;
  const isSelected = !!(from && to);

  const handleApply = useCallback(
    (options) => {
      onChange(
        overwriteDates([...availability, ...createDateRange(from, to, options)])
      );
    },
    [from, to]
  );

  const minPrice = getPrice(minimumDailyRate?.cents_total);
  const dailyPrice = getPrice(dailyRate?.cents_total);
  const defaultPrice = getPropForRange(
    "price",
    availability,
    from,
    to,
    dailyPrice
  );

  const defaultAvailability = getPropForRange(
    "available",
    availability,
    from,
    to,
    true
  );

  const isNotApplicable = !!getPropForRange(
    "busy",
    availability,
    from,
    to,
    false,
    (prev, next) => prev || next
  );

  return (
    <>
      <BigDayPicker
        availability={availability}
        value={value}
        onChange={setValue}
        filterDisabledDays={() => []}
        renderDay={(day, daysData) =>
          renderDay(day, daysData, dailyRate?.cents_total, classes)
        }
      />
      <AvailabilityInfo
        show={isSelected}
        from={from}
        to={to}
        minPrice={minPrice}
        defaultPrice={defaultPrice ? defaultPrice : dailyPrice}
        defaultAvailability={defaultAvailability}
        isNotApplicable={isNotApplicable}
        onApply={handleApply}
        onCancel={() => setValue({})}
      />
    </>
  );
});

AvailabilityCalendar.propTypes = {
  id: PropTypes.string.isRequired,
  errors: PropTypes.object,
  rvId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  dailyRate: PropTypes.object,
  minDailyRate: PropTypes.object
};
