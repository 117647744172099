import React, { StrictMode } from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/client";
import { BrowserRouter } from "react-router-dom";
import MapBlockContainer from "./components/Search/MapBlockContainer";

export const MapBlockApp = ({ location }) => (
  <StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <MapBlockContainer location={location} />
      </BrowserRouter>
    </ApolloProvider>
  </StrictMode>
);
