import { useMemo, useState } from "react";
import { over, lensProp, pipe } from "ramda";
import moment from "moment";
import { readStorage, writeStorage } from "./storage";

function parseData(data) {
  if (data && typeof data === "string") {
    try {
      return JSON.parse(data);
    } catch (e) {}
  }

  return null;
}

function dateToString(v) {
  return v ? moment(v).format("YYYY-MM-DD") : null;
}

function stringToDate(v) {
  return v ? moment(v).toDate() : null;
}

const mapperToString = over(
  lensProp("date"),
  pipe(
    over(lensProp("departure"), dateToString),
    over(lensProp("return"), dateToString)
  )
);

const mapperFromString = over(
  lensProp("date"),
  pipe(
    over(lensProp("departure"), stringToDate),
    over(lensProp("return"), stringToDate)
  )
);

/**
 * Access to the temporary booking session
 * @param {number} rvId
 */
export function useBookingSession(rvId) {
  const [updateView, setUpdateView] = useState(0);
  const data = readStorage(rvId);

  return useMemo(() => {
    const value = mapperFromString(parseData(data));

    function updateValue(nextValue) {
      const nextData = JSON.stringify(
        mapperToString(
          typeof nextValue === "function" ? nextValue(value) : nextValue
        )
      );

      const writeResult = writeStorage(rvId, nextData);

      // force update component
      setUpdateView((v) => (v + 1) % 100);

      return writeResult;
    }

    return [value, updateValue];
  }, [rvId, data, setUpdateView]);
}
