import { gql } from "@apollo/client";

export const photoImageUploadMutation = gql`
  mutation reviewImageUpload($id: Int!, $image: Upload!) {
    review_image_upload(rental: $id, image: $image) {
      result {
        success
        errors {
          field
          message
        }
      }
      image {
        id
        srcset {
          crop_area_x1
          crop_area_x2
          crop_area_y1
          crop_area_y2
          height
          rotation
          type
          url
          width
        }
      }
    }
  }
`;
