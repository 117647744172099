import React, { useCallback, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { searchIcon } from "../../Icon";
import Header from "./Header";
import NoThreads from "./NoThreads";
import ThreadsList from "./ThreadsList";
import Footer from "./Footer";
import { ThreadType } from "../types";
import { usePreparedThreads } from "../hooks/usePreparedThreads";
import Spinner from "../../Spinner/Spinner";
import classes from "./ThreadsView.module.css";

const MAIN_TITLE = "Messages";

const ThreadsView = (props) => {
  const {
    loading, threads, activeThread, onClickThread, onClose, totalUnread
  } = props;
  const [search, setSearch] = useState("");
  const [unreadOnly, setUnreadOnly] = useState(false);

  const hasThreads = threads?.length > 0;
  const resultThreads = usePreparedThreads(
    threads, search, unreadOnly, totalUnread
  );

  const onChangeUnreadOnly = useCallback((event) => {
    setUnreadOnly(event.target.checked);
  }, []);

  return (
    <div
      className={classnames(
        classes.root,
        "df fdc",
        { [classes.hasActiveThread]: Boolean(activeThread) }
      )}
    >
      <Header
        title={MAIN_TITLE}
        onClose={onClose}
        unreadOnly={unreadOnly}
        onChangeUnreadOnly={threads?.length > 0
          ? onChangeUnreadOnly
          : undefined
        }
      />
      {loading && (
        <div className={classes.spinner}>
          <Spinner position="absolute" />
        </div>
      )}
      {!loading && !hasThreads && (
        <NoThreads />
      )}
      {hasThreads && (
        <ThreadsList
          list={resultThreads}
          onClickThread={onClickThread}
        />
      )}
      <Footer
        placeholder="Person’s Name or Listing Title"
        onInputChange={setSearch}
        iconStart={searchIcon}
        withClear
      />
    </div>
  );
};

ThreadsView.propTypes = {
  loading: PropTypes.bool,
  threads: PropTypes.arrayOf(ThreadType).isRequired,
  activeThread: PropTypes.object,
  onClickThread: PropTypes.func,
  onClose: PropTypes.func,
  totalUnread: PropTypes.number
};

export default ThreadsView;
