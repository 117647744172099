import React from "react";
import PropTypes from "prop-types";

import style from "./style.module.css";

import HeaderDetailsItem from "./HeaderDetailsItem";
import HeaderDetailsItemDataAdditional from "./HeaderDetailsItemDataAdditional";

const HeaderDetails = ({ children }) => (
  <div className={`${style.header__details_data} listing-header-details`}>
    {children}
  </div>
);

HeaderDetails.propTypes = {
  /**
   * <HeaderDetailsItem />
   */
  children: PropTypes.node.isRequired
};

HeaderDetails.Item = HeaderDetailsItem;
HeaderDetails.ItemDataAdditional = HeaderDetailsItemDataAdditional;

export default HeaderDetails;
