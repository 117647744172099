import { useRef, useState, useEffect } from "react";

/**
 * @param {Element} el
 * @param {Function} callback
 * @return {(function(): void)|*}
 */
function trackSize(el, callback) {
  let observer = null;

  try {
    observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target) {
          callback(entry.target.clientHeight);
        }
      }
    });

    observer.observe(el);
  } catch (e) {
  }

  return () => {
    if (observer) {
      observer.unobserve(el);
      observer = null;
    }
  };
}


export function useDescriptionHeight() {
  const ref = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const el = ref.current;
    let unsubscribe;

    if (el) {
      unsubscribe = trackSize(el, (height) => {
        setHeight(height);
      });

    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

    };
  }, [ref]);

  return [ref, height];
}
