import React, { useState, useCallback, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Button from "../Button";
import Typography from "components/Typography";
import { ReactComponent as IconEdit } from "assets/images/edit-icon-with-background.svg";
import { ReactComponent as IconRemove } from "assets/images/remove-with-background-icon.svg";
import { ReactComponent as IconStandarddRV } from "assets/images/standard-rental-icon.svg";
import { ReactComponent as IconDeliveryOnlydRV } from "assets/images/delivery-only-icon.svg";
import { lightningIcon } from "components/Icon";
import { ReactComponent as IconLightning } from "assets/images/lightning.svg";
import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";
import { useLocationQueryParams } from "hooks/useLocationQueryParam";
import { writeStorage } from "processes/Booking/BookingSession/storage";
import moment from "moment";
import classes from "./VehicleCard.module.css";

function addDatesInStorage(queryParams, rvId, rentalType) {
  const departure_date = queryParams.departure || null;
  const return_date = queryParams.return || null;

  if (departure_date && return_date) {

    const storageData = JSON.stringify({
      date: { departure: moment(departure_date).format("YYYY-MM-DD"), return: moment(return_date).format("YYYY-MM-DD") },
      rental_type: rentalType === 3 ? 1 : rentalType
    });

    writeStorage(rvId, storageData);
  }
}

const VehicleCard = (props) => {
  const {
    className,
    variant,
    size,
    id,
    link,
    linkTarget = '_self',
    isFavorite,
    onClickFavorite,
    imageUrl,
    title,
    name,
    rvClass,
    price,
    length,
    sleeps,
    slideOuts,
    miles,
    instantRentals,
    rentalType,
    deliveredFrom,
    onClose,
    pageIndex,
    totalPages,
    onClickPrevious,
    onClickNext,
    onClickCardLink,
    hasButtonControls,
    userId,
    badge: {
      text: badgeText,
      status: badgeStatus
    } = {},
    badge
  } = props;

  const withPagination = totalPages > 1;
  const Container = Boolean(link) ? "a" : "div";
  const [showModalRemoveCard, setShowModalRemoveCard] = useState(false);
  const queryParams = useLocationQueryParams();

  const preventPropagation = useCallback((callback) => {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();
      callback();
    };
  }, []);

  const handleClickCardLink = useCallback(() => {
    addDatesInStorage(queryParams, id, rentalType);
    onClickCardLink();
  }, [id, onClickCardLink, queryParams, rentalType]);

  return (
    <Container
      className={classnames(
        classes.root,
        className,
        "rv-card",
        `rv-card--${variant === "map" ? "col" : variant}`,
        `rv-card--${size}`, {
          "rv-card--favorite": isFavorite,
          "rv-card--map": variant === "map"
        }
      )}
      href={link}
      onClick={handleClickCardLink}
      target={linkTarget}
    >
      <div className="rv-card__media">
        {hasButtonControls && (
          <>
            <div className={`rv-card__controls-btn ${classes.btnControlsContainer}`}>
              <Button
                icon={<IconEdit />}
                label=""
                onClick={preventPropagation(() => () => {})}
                size="small"
                className={`rv-card__btn btn-controls ${classes.btnControls}`}
              />

              <Button
                icon={<IconRemove />}
                label=""
                onClick={preventPropagation(() => setShowModalRemoveCard(true))}
                size="small"
                className={`rv-card__btn btn-controls ${classes.btnControls}`}
              />
            </div>
            <Modal
              show={showModalRemoveCard}
              onClose={() => setShowModalRemoveCard(false)}
            >
              <Typography variant="title" size="5" weight="bold">
              Remove RV
              </Typography>
              <Typography variant="subtitle" size="s">
              Are you sure you want to remove your RV?
              </Typography>
              <div className="t-20">
                <Button
                  label="Cancel"
                  onClick={() => setShowModalRemoveCard(false)}
                  secondary
                />

                <Button
                  label="Remove"
                  onClick={function noRefCheck(){}}
                  className="ml-16"
                />
              </div>
            </Modal>
          </>
        )}
        {badge && (
          <div className={`rv-card__badge rv-card__badge--${badgeStatus}`}>{badgeText}</div>
        )}
        {onClose && (
          <button
            className="rv-card__icon-close"
            onClick={preventPropagation(onClose)}
          />
        )}
        <div
          className={classnames('rv-card__icon-favorite', !userId && 'rv-card__icon-favorite--hidden')}
          onClick={preventPropagation(() => onClickFavorite(id))}
        />
        <img
          className="rv-card__img"
          src={imageUrl}
          alt={title}
        />
      </div>
      <div className="rv-card__info">
        <div className="rv-card__info-top">
          {title && (
            <div className="rv-card__text">{title}</div>
          )}
          <div className="rv-card__basic-data">
            <div className="rv-card__face">
              <div className="rv-card__name">
                {name || "-"}
              </div>
              <div className="rv-card__class">{rvClass}</div>
            </div>
            {price && (
              <div className="rv-card__price">
                <div className="rv-card__price-numb-container" onClick={instantRentals ? preventPropagation(() => () => {}) : null}>
                  {instantRentals && (
                    <div className="rv-card__price-lightning">
                      <div className="rv-card__price-lightning-content">
                        <Tooltip
                          tooltipContainerClassName={"rv-card__price-tooltip"}
                          tooltipContent={(
                            <span className="rv-card__price-tooltip-value">
                            Instant Book: An easy way to book immediately without needing to send a request
                            to the RV owner for approval.
                            </span>
                          )}
                          placement="left"
                          offset={[0, 8]}
                        >
                          {(ref) => (
                            <span
                              ref={ref}
                              className="rv-card__price-tooltip-content"
                            >
                              {lightningIcon}
                            </span>
                          )}
                        </Tooltip>
                      </div>
                    </div>
                  )}
                  <div className="rv-card__price-numb">
                    {"$" + price}
                  </div>
                </div>
                <div className="rv-card__price-units">per night</div>
              </div>
            )}
          </div>
          <div
            className={classnames(
              "rv-card__features",
              rentalType === 1 && "rv-card__features--standard-type",
              rentalType === 2 && "rv-card__features--delivery-type",
              rentalType === 3 && "rv-card__features--standard-delivery-type",
              miles === 0 && "rv-card__features--has-not-miles"
            )}
          >
            <Feature id="miles" title="Miles allowed" value={miles} />
            <Feature id="length" title="Length (ft)" value={length} />
            <Feature id="sleeps" title="Sleeps" value={sleeps} />
            <Feature id="slide-outs" title="Slide outs" value={slideOuts} />
          </div>
        </div>
        <div className="rv-card__info-bottom">
          <div className="rv-card__rental-type" onClick={preventPropagation(() => () => {})}>
            {(rentalType === 1 || rentalType === 3) && (
              <span className="rv-card__rental-type--icon rv-card__rental-type--standard-icon">
                <Tooltip
                  tooltipContainerClassName={"rv-card__rental-type--tooltip"}
                  tooltipContent={(
                    <span className="">
                      <strong className="fw-500">Standard rental:</strong> Owner allows renter to drive or tow their RV. Delivery may also be available.
                    </span>
                  )}
                  trigger={['hover']}
                >
                  {(ref) => (
                    <span ref={ref}>
                      <IconStandarddRV />
                    </span>
                  )}
                </Tooltip>
              </span>
            )}

            {(rentalType === 2 || rentalType === 3) && (
              <span className="rv-card__rental-type--icon rv-card__rental-type--delivery-icon">
                <Tooltip
                  tooltipContainerClassName={"rv-card__rental-type--tooltip"}
                  tooltipContent={(
                    <span className="">
                      <strong className="fw-500">Delivery only rental:</strong> This owner will deliver and setup their RV, only. No renter driving or towing
                    </span>
                  )}
                  trigger={['hover']}
                >
                  {(ref) => (
                    <span ref={ref}>
                      <IconDeliveryOnlydRV />
                    </span>
                  )}
                </Tooltip>
              </span>
            )}
          </div>

          {deliveredFrom && (
            <div className="rv-card__location">
              <div className="rv-card__location-icon"></div>
              <div className={`rv-card__location-text ${rentalType !== 3 && "rv-card__location-text--long"}`}>
                {deliveredFrom}
              </div>
            </div>
          )}

          {withPagination && (
            <div className="rv-card__controls">
              {onClickPrevious && (
                <Button
                  className="rv-card__arrow rv-card__arrow--prev"
                  onClick={preventPropagation(onClickPrevious)}
                  disabled={pageIndex <= 1}
                />
              )}
              <div className="rv-card__numb">
                {`${pageIndex}/${totalPages}`}
              </div>
              {onClickNext && (
                <Button
                  className="rv-card__arrow rv-card__arrow--next"
                  onClick={preventPropagation(onClickNext)}
                  disabled={pageIndex >= totalPages}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const Feature = (props) => {
  const { id, title, value } = props;

  if (value === undefined || value === null) {
    return null;
  }

  return (
    <div className={classnames("rv-card__feature", `rv-card__feature--${id}`)}>
      <div className="rv-card__feature-title">{title}</div>
      <div className="rv-card__feature-numb">{value}</div>
    </div>
  );
};

Feature.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  value: PropTypes.node
};

VehicleCard.defaultProps = {
  className: "",
  variant: "row",
  size: "l"
};

export const VehicleCardVariantType = PropTypes.oneOf(["row", "col", "map"]);
export const VehicleCardSizeType = PropTypes.oneOf(["s", "m", "ml", "l"]);

VehicleCard.propTypes = {
  className: PropTypes.string,
  variant: VehicleCardVariantType,
  size: VehicleCardSizeType,
  id: PropTypes.number.isRequired,
  link: PropTypes.string,
  linkTarget: PropTypes.oneOf(["_self", "_blank"]),
  imageUrl: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onClickFavorite: PropTypes.func.isRequired,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  rvClass: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  length: PropTypes.number,
  sleeps: PropTypes.number,
  slideOuts: PropTypes.number,
  deliveredFrom: PropTypes.string,
  onClose: PropTypes.func,
  pageIndex: PropTypes.number,
  totalPages: PropTypes.number,
  onClickPrevious: PropTypes.func,
  onClickNext: PropTypes.func,
  hasButtonControls: PropTypes.bool,
  badge: PropTypes.shape({
    text: PropTypes.string,
    status: PropTypes.oneOf(["success", "warning", "default"])
  })
};

export default VehicleCard;
