import { gql } from "@apollo/client";

export const checkCouponQuery = gql`
  query checkCoupon($coupon: String!) {
    check_coupon(coupon_code: $coupon) {
      coupon {
        code
        comment
        id
        type {
          key
          value
        }
        value
      }
      result {
        success
        errors {
          field
          message
        }
      }
    }
  }
`;
