import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isUserRenter } from "processes/Rentals/utils";

import UserLink from "./UserLink";

import classes from "./Action.module.css";

const Action = React.memo((props) => {
  const { className, item } = props;

  if (!item.action) {
    return null;
  }

  const isRenter = isUserRenter(item.role);

  const text = item.content?.text[0]?.value;

  if (text.indexOf("{{") === -1) {
    return (
      <span className={classnames(className, classes.root)}>{text}</span>
    );
  }

  const [textStart, endInterval] = text.split("{{");
  const [opponent, textEnd] = endInterval.split("}}");
  const [name, role] = opponent.split("|");

  return (
    <span className={classnames(className, classes.root)}>
      {textStart}
      <UserLink id={isRenter ? item.owner.id : item.renter.id} name={name} />
      {textEnd}
    </span>
  );
});

Action.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired
};

export default Action;
