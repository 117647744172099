import React, { memo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "components/Typography";
import Button from "components/Button";
import logoIcon from "assets/images/good_sam_icon_reversed.svg";
import { getRentalDetailsPath } from "processes/Rentals/utils";
import { MessageType, ThreadType } from "../types";
import Message from "./Message";
import classes from "./SystemMessage.module.css";

function createButtonUrl(rentalId, buttonId) {
  switch (buttonId) {
  case 'search':
    return '/search';
  case 'rebook':
    return '/search';
  case 'rental':
    return getRentalDetailsPath(rentalId);
  default:
    return getRentalDetailsPath(rentalId, buttonId);
  }
}

const SystemMessage = (props) => {
  const { className, data, thread, userIdentity } = props;

  // TODO: Use variable from API
  const hideCTA = false;

  const userAttributes = data?.attributes?.users?.find(i => {
    return parseInt(i?.user_id) === parseInt(userIdentity);
  });

  const header = userAttributes?.header;
  const buttons = userAttributes?.action_buttons;
  const description = userAttributes?.description;
  const quote = userAttributes?.quote;
  const rentalId = userAttributes?.rental_id;

  if (!userAttributes) {
    return null;
  }

  return (
    <Message
      className={classnames(classes.root, className)}
      isSystem
      data={data}
      thread={thread}
      avatar={logoIcon}
      isReceived
    >
      {Boolean(header?.text) && (
        <Typography
          className={header.type === "primary"
            ? classes.baseText
            : classes.helpText
          }
          size={header.type === "primary" ? "l" : "s"}
          variant="body"
          component="div"
        >
          <div dangerouslySetInnerHTML={{ __html: header.text }} />
        </Typography>
      )}
      {Boolean(data?.body) && (
        <Typography
          className={classes.baseText}
          variant="body"
          size="l"
          component="div"
        >
          <div dangerouslySetInnerHTML={{ __html: data.body }} />
        </Typography>
      )}
      {quote?.length > 0 && (
        <div className={classes.changes}>
          {quote.map((i, index) => (
            <div key={i.name || index} className={classes.change}>
              {i.url && (
                <a href={i.url}>
                  {i.name}
                </a>
              )}
              {!i.url && i.name && (
                <Typography className={classes.changeLabel} variant="body" size="s">
                  {i.name}
                </Typography>
              )}
              {Boolean(i.value_old && i.value_new) && (
                <div className="df">
                  <Typography className={classes.changeValue} variant="body" size="l">
                    {i.value_old}
                  </Typography>
                  <Typography className={classes.changeNewValue} variant="body" size="l">
                    {i.value_new}
                  </Typography>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {buttons?.length > 0 && !hideCTA && (
        <div className="df f-wrap t-12 b-12">
          {buttons.map((button) => (
            <div key={button.id} className="df t-4 b-4">
              <Button
                size="medium"
                label={button.label}
                secondary={button.type === "secondary"}
                ghost={button.type === "link"}
                href={createButtonUrl(rentalId, button.id)}
              />
              <div className="r-12" />
            </div>
          ))}
        </div>
      )}
      {Boolean(description) && (
        <Typography
          className={classes.helpText}
          variant="body"
          size="s"
          component="div"
        >
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      )}
    </Message>
  );
};

SystemMessage.propTypes = {
  className: PropTypes.string,
  data: MessageType,
  thread: ThreadType,
  userIdentity: PropTypes.string.isRequired
};

export default memo(SystemMessage);
