import { gql } from "@apollo/client";

export const reserveQuery = gql`
  mutation reserve(
    $rv: Int!
    $departure_date: String!
    $return_date: String!
    $delivery_location: String
    $coupon_code: String
    $rental_type: Int
    $specific_delivery_location_id: Int
    $insurance_package: Int
    $no_split_payments: Boolean
    $good_sam_membership_package: String
    $add_ons: [Int]
    $message: String
    $signature_name: String
    $date_of_birth: String
    $additional_insurances: [Int]
    $tags: [TagTypeInputReserve]
  ) {
    reserve(
      rv: $rv
      departure_date: $departure_date
      return_date: $return_date
      delivery_location: $delivery_location
      coupon_code: $coupon_code
      rental_type: $rental_type
      specific_delivery_location_id: $specific_delivery_location_id
      insurance_package: $insurance_package
      no_split_payments: $no_split_payments
      good_sam_membership_package: $good_sam_membership_package
      add_ons: $add_ons
      message: $message
      signature_name: $signature_name
      date_of_birth: $date_of_birth
      additional_insurances: $additional_insurances
      tags: $tags
    ) {
      rental {
        id
        rental_type {
          key
          value
        }
        is_trip_interruption_insurance_changable
      }
      result {
        success
        errors {
          field
          message
          code
        }
      }
    }
  }
`;
