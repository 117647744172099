import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import classes from "./StepContainer.module.css";

const StepContainer = (props) => {
  const {
    children,
    buttons,
    buttonsClassName,
    buttonsContainerClassName,
    hasScrollShadow,
    isPaymentOptions,
    isPaymentMethodStep
  } = props;

  return (
    <div className={classnames(
      classes.root,
      isPaymentMethodStep && classes.isPaymentMethodStep,
      "fw"
    )}>
      {typeof children === "function" ? children({
        buttonsClassName: classes.buttonsPosition,
        buttonsContainerInner: classes.buttonsContainerInner
      }) : children}
      {buttons && (
        <div
          className={classnames(
            classes.buttons,
            classes.buttonsPosition,
            buttonsClassName,
            hasScrollShadow && classes.hasScrollShadow
          )}>
          <div className={classes.shadowContainer}></div>
          <div className={classnames(
            buttonsContainerClassName,
            classes.footerButtonsPosition,
            isPaymentOptions && classes.footerButtonsPositionPaymentOptions
          )}>
            {buttons}
          </div>
        </div>
      )}
    </div>
  );
};

StepContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  buttons: PropTypes.node,
  buttonsClassName: PropTypes.string
};

export default StepContainer;
