import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { contentQuery } from "../../queries";
import { extractText } from "../../utils/extractText";
import { extractHtml } from "../../utils/exctractHtml";
import { getActiveSection } from "../../utils/getActiveSection";
import ProTipsCollapseFields from "./ProTipsCollapseFields";

const ProTipsCollapseProvider = ({ className, mediaParams }) => {
  const { pathname } = useLocation();
  const section = getActiveSection(pathname);
  const [content, setContent] = useState({
    proTipsSectionTitle: "",
    proTipsSectionText: ""
  });
  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section
    },
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (!loading && data?.content?.text) {
      setContent({
        proTipsSectionTitle: extractText(
          data.content.text,
          "pro_tips_section_title"
        ),
        proTipsSectionText: extractHtml(
          data.content.text,
          "pro_tips_section_text"
        )
      });
    }
  }, [loading, data]);

  if (loading) {
    return null;
  }

  return (
    <ProTipsCollapseFields
      content={content}
      className={className}
      mediaParams={mediaParams}
    />
  );
};

export default ProTipsCollapseProvider;
