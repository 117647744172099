import React from "react";
import Typography from "components/Typography";
import { TextItem } from "components/TextItem";
import classes from "./PriceFormSubItem.module.css";

const PriceFormSubItem = (props) => {
  const {
    forms,
    itemInd,
    subItemInd
  } = props;

  const title = forms[0].items[itemInd].sub_items[subItemInd].title;

  return (
    <div className={classes.subItemsRow}>

      <div className={classes.title}>
        <TextItem
          data={title}
          plainStyle={classes.subCommonStyle}
          tagCommonStyle={classes.subCommonStyle}
        />
      </div>

      {forms.map((form, formIndex) => {
        const value = form.items[itemInd].sub_items[subItemInd]?.value;
        const units = form.items[itemInd].sub_items[subItemInd]?.units;

        return (
          <div className={classes.valueColumn} key={formIndex}>
            {value && (
              <TextItem
                data={value}
                plainStyle={classes.subCommonStyle}
                tagCommonStyle={classes.subCommonStyle}
              />
            )}

            {units && (
              <Typography
                variant="body"
                size="l"
                className={classes.subCommonStyle}
              >
                /{units}
              </Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};

PriceFormSubItem.propTypes = {
};

export default PriceFormSubItem;
