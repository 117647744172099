import React from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import { FieldInput } from "components/form/FieldInput";
import Form from "../form/Form/Form";
import Typography from "../Typography";
import { VALIDATION } from "utils/validation";

const defaultValues = {
  coupon: ""
};

export const ApplyCouponModalForm = (props) => {
  const { content, onSubmit, toggleShow, customError } = props;
  const { handleSubmit, errors, control } = useForm({
    defaultValues
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} protect>
      <Typography
        variant="headline"
        size="s"
        className="mt-4 mt-m-8 mt-xl-0 mb-m-4 mb-xl-0"
      >
        {content.title}
      </Typography>
      <div className="col">
        <div className="row t-16 t-m-20 b-16 b-m-20">
          <Controller
            name="coupon"
            control={control}
            defaultValue={""}
            rules={VALIDATION.rules.required}
            render={(renderProps) => (
              <FieldInput
                {...renderProps}
                label="Enter coupon number"
                id="coupon"
                className="fw"
                errors={errors}
              />
            )}
          />
        </div>
      </div>
      {customError}
      <div className="row">
        <div className="col t-16 t-m-20 b-16 b-m-20">
          <div className="btn-group">
            <Button onClick={toggleShow} secondary>
              {content.cancelButton.label}
            </Button>
            <Button type="submit">{content.confirmButton.label}</Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
