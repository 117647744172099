import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import { RX_INT } from "../../../../utils/constants/RX";
import { isSafeInt } from "../../../../utils/isSafeInt";
import Button from "../../../Button";
import Form from "../../../form/Form/Form";
import Message from "../../../Message";
import ProTipsCollapse from "../../../ProTipsCollapse";
import Typography from "../../../Typography";
import { FeesFormContent } from "./FeesFormContent";
import { useFeesFormDiscounts } from "./useFeesFormDiscounts";
import { useQuery, useLazyQuery } from "@apollo/client";
import { ownerIncomeForecast } from "./queries/ownerIncomeForecast";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import { isSafePrice } from "utils/isSafePrice";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import {
  getErrorsAndSuccess
} from "../../../../utils/extractErrors";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const rvUpdateMutationEAS = getErrorsAndSuccess("rv_update");

const DEFAULT_VALUES = {
  minimum_duration: "",
  daily_rate: "",
  discounts: [],
  security_deposit: "",
  bank_account_number: ""
};

const DISCOUNTS_DEFAULT_VALUES = {
  from: "",
  till: "",
  discount: ""
};
const NIGHTS_MAX = Number.MAX_SAFE_INTEGER;
const DISCOUNT_MAX = 99;

export const FeesForm = ({
  initialData,
  contentData,
  onResetError,
  requestErrors,
  onBack,
  onLater,
  onSave,
  updateContextSaveHandler,
  loading,
  listingPublished,
  prevStep,
  nextStep
}) => {
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );
  const [errorText, setErrorText] = useState("");
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const defaultValues = Object.assign(
    {},
    DEFAULT_VALUES,
    { ...initialData,
      minimum_days_before_instant_rental: initialData?.instant_rentals__days_before_the_rental
    }
  );
  const {
    control,
    handleSubmit,
    trigger: triggerFormValidate,
    reset,
    formState,
    formState: { isDirty, errors },
    watch,
    setValue,
    getValues,
    setError,
    clearErrors
  } = useForm({
    shouldUnregister: false,
    defaultValues,
    mode: "onSubmit"
  });

  useFooterVisible(isDirty);

  useEffect(() => {
    if (errors?.bank_account_number?.message) {
      setErrorText(errors?.bank_account_number?.message);
      setShowNotificationPopover(true);
    }
  }, [errors, errors?.bank_account_number?.message]);

  useEffect(() => {
    updateContextSaveHandler(
      handleSubmit(async (values) => {
        const saveResult = await onSave(values, { toNextStep: false });

        if (!rvUpdateMutationEAS.isErrors(saveResult)) {
          reset(getValues(), {
            keepDirty: false
          });
        }

        return saveResult;
      })
    );
  }, [handleSubmit, getValues, onSave, reset, updateContextSaveHandler, errors?.length]);

  const [globalError] = useRequestErrors(requestErrors, setError);

  const [validateDiscounts, rowError] = useFeesFormDiscounts({
    form: {
      watch,
      formState,
      setValue,
      setError,
      clearErrors,
      getValues,
      triggerFormValidate
    },
    defaultValues: DISCOUNTS_DEFAULT_VALUES,
    validate: {
      DISCOUNT_FROM: (v) => RX_INT.test(v) && isSafeInt(parseInt(v, 10))
    }
  });

  const ref = useRef({
    daily_rate: 0,
    discounts: []
  });
  const { daily_rate, discounts: discountsWatch, instant_rentals: isSwitchInstantRentals } = watch([
    "daily_rate",
    "discounts",
    "instant_rentals"
  ]);


  const getDiscounts = (discounts = []) => {
    const newDiscounts = [];
    discounts.forEach((discount) => {
      const newDiscount = {};
      newDiscount.discount =
        discount?.discount && Number(discount?.discount) <= DISCOUNT_MAX
          ? Number(discount?.discount)
          : null;
      newDiscount.from =
        discount?.from && Number(discount?.from) <= NIGHTS_MAX
          ? Number(discount?.from)
          : null;

      newDiscounts.push(newDiscount);
    });
    return newDiscounts;
  };
  const [
    refetch,
    {
      loading: loadingOwnerIncomeForecastQuery,
      data: ownerIncomeForecastQueryData
    }
  ] = useLazyQuery(ownerIncomeForecast, {
    fetchPolicy: "no-cache"
  });
  const isRefetchOwnerIncomeForecastQueryValid = useCallback(
    (isChange) => {
      return (
        isChange &&
        daily_rate &&
        isSafePrice(parseInt(daily_rate, 10)) &&
        Number(daily_rate) > 0
      );
    },
    [daily_rate]
  );

  const debouncedRefetch = useMemo(() => debounce(refetch, 500), [refetch]);

  useEffect(() => {
    let isChange = false;
    if (ref.current.daily_rate !== daily_rate) {
      ref.current.daily_rate = daily_rate;
      isChange = true;
    }
    if (!isEqual(ref.current.discounts, discountsWatch)) {
      ref.current.discounts = discountsWatch;
      isChange = true;
    }
    if (isRefetchOwnerIncomeForecastQueryValid(isChange)) {
      debouncedRefetch({
        variables: {
          daily_rate: Number(daily_rate),
          discounts: getDiscounts(discountsWatch)
        }
      });
    }
  }, [
    daily_rate,
    discountsWatch,
    isRefetchOwnerIncomeForecastQueryValid,
    debouncedRefetch
  ]);
  useEffect(() => {
    debouncedRefetch({
      variables: {
        daily_rate: Number(daily_rate),
        discounts: getDiscounts(discountsWatch)
      }
    });
  }, []);

  useEffect(() => {
    if (!isSwitchInstantRentals) {
      clearErrors("bank_account_number");
    }
  }, [clearErrors, isSwitchInstantRentals]);

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <NotificationPopover
        show={isShowNotificationPopover}
        status="error"
        text={errorText}
        onClose={() => {
          setShowNotificationPopover(false);
        }}
        bottomIndent={{
          hasIndent: true,
          size: 'Xlarge'
        }}
      />
      <div className="row">
        <div className="col">
          <Text
            id="step_title"
            section="rentalFeesSectionContent"
            component="h4"
            variant="title"
            weight="bold"
          />
          <ProTipsCollapse className="t-16 b-32" mediaParams="767px" />
        </div>
      </div>
      <FeesFormContent
        contentData={contentData}
        control={control}
        errors={formState.errors}
        getValues={getValues}
        setError={setError}
        clearErrors={clearErrors}
        triggerFormValidate={triggerFormValidate}
        discountsDefaultValues={DISCOUNTS_DEFAULT_VALUES}
        validateDiscounts={validateDiscounts}
        rowError={rowError}
        ownerIncomeForecastQueryData={ownerIncomeForecastQueryData}
        loadingOwnerIncomeForecastQuery={loadingOwnerIncomeForecastQuery}
        daily_rate={daily_rate}
        isSwitchInstantRentals={isSwitchInstantRentals}
        onCloseModalPayoutForm={() => {
          setShowNotificationPopover(false);
        }}
      />

      {globalError?.length > 0 && (
        <NotificationPopover
          show
          status="error"
          text={globalError}
          onClose={() => {
            onResetError([]);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}
      {!listingPublished && (
        <div className="row">
          { nextStep && isSmallDevice && (
            <div className="col-9 t-20 b-36">
              <Button disabled={loading} onClick={onLater} secondary>
                Skip
              </Button>
            </div>
          )}
          <div className="col t-m-20 b-16 b-m-20">
            <div className="btn-group btn-group--center btn-group--m-start btn-group_xs-fixed">
              {prevStep && (
                <ButtonContent
                  id="previous_button"
                  section="rentalFeesSectionContent"
                  onClick={onBack}
                  disabled={loading}
                  secondary
                />
              )}
              {nextStep && !isSmallDevice && (
                <Button disabled={loading} onClick={onLater} secondary>
                  Skip
                </Button>
              )}
              {nextStep && (
                <ButtonContent
                  id="save_and_next_button"
                  section="rentalFeesSectionContent"
                  type="submit"
                  onClick={() => validateDiscounts(false)}
                  disabled={loading}
                />
              )}

              {!nextStep && (
                <Button
                  type="submit"
                  onClick={() => validateDiscounts(false)}
                  disabled={loading}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
