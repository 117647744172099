import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import Typography from "../../../components/Typography";
import BookingRentalDetailsModalContent from "./BookingRentalDetailsModalContent";
import classes from "./BookingRentalDetailsModal.module.css";

const BookingRentalDetailsModal = (props) => {
  const modalRef = useRef(null);
  const {
    show,
    onClose,
    content,
    isGoodSamMember,
    setIsGoodSamMember,
    isClientGoodSamMember
  } = props;
  return (
    <Modal
      show={show}
      onClose={onClose}
      showCross={false}
      withContainer
      modalWrapClassnames={`mdl-wrap--full-screen-less-768`}
      modalCrossButtonClassname={classes.crossButton}
      render={() => (
        <div className="row fjc">
          <div className={` ${classes.mdlRowInner}`}>
            <div
              ref={modalRef}
              className={`mdl mdl--full-screen-less-768 ${classes.mdl}`}
            >
              <div
                onClick={onClose}
                className={`mdl__cross ${classes.crossButton}`}
              />
              <Typography
                variant="headline"
                size="l"
                className={`${classes.sectionTitle}`}
              >
                {content.sectionTitle}
              </Typography>
              <BookingRentalDetailsModalContent
                content={content}
                modalRef={modalRef}
                isGoodSamMember={isGoodSamMember}
                isClientGoodSamMember={isClientGoodSamMember}
                setIsGoodSamMember={setIsGoodSamMember}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

const propTypesValue = PropTypes.oneOfType([
  PropTypes.shape({
    cents_total: PropTypes.number.isRequired,
    cents_as_part: PropTypes.number.isRequired,
    dollars_as_part: PropTypes.number.isRequired
  }),
  PropTypes.string
]);

BookingRentalDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    sectionTitle: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
        value: propTypesValue.isRequired,
        description: PropTypes.string.isRequired,
        helperMessage: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    total: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: propTypesValue.isRequired
    })
  }).isRequired,
  isGoodSamMember: PropTypes.bool.isRequired,
  setIsGoodSamMember: PropTypes.func.isRequired
};

export default BookingRentalDetailsModal;
