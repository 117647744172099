import React from "react";
import Typography from "components/Typography";
import { ReactComponent as GoodsamLogo } from "components/Widget/assets/goodsam-logo-green.svg";
import { ReactComponent as GoodsamLogoWhite } from "components/Widget/assets/goodsam-logo-white-new.svg";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import classes from "components/Widget/components/WidgetHeader/WidgetHeader.module.css";

const WidgetHeader = () => {
  const {
    theme,
    headerTitle
  } = useWidgetContext();

  return (
    <>
      <div className="fbc">
        <Typography
          variant="headline"
          size="l"
          weight="bold"
          className={classes.pageTitle}
        >
          {headerTitle}
        </Typography>

        <div className={`${classes.logo} mb-xl-24`}>
          {theme === 'light' && <GoodsamLogo /> }
          {theme === 'dark' && <GoodsamLogoWhite /> }
        </div>
      </div>
    </>
  );
};

export default WidgetHeader;
