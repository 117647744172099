import React, { useEffect, useState } from "react";
import cn from 'classnames';
import { useQuery } from "@apollo/client";
import Typography from "components/Typography";
import { MatchMedia } from "components/MatchMedia";
import RcCollapse from "components/RcCollapse";
import { FieldInput } from "components/form";
import { SearchIcon } from "components/Icon/SearchIcon";
import { SpinnerBlur } from "components/Spinner/Spinner";

import { vehiclesQuery } from "../../queries/vehiclesQuery";
import VehicleCard from "../VehicleCard/VehicleCard";
import EmptyVehiclesList from "../EmptyVehiclesList/EmptyVehiclesList";
import { ProTips } from "../ProTips";

import classes from './SelectVehicle.module.css';

const SelectVehicle = ({ content, state, onSelectVehicle }) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const { loading, data } = useQuery(vehiclesQuery);

  const searchChange = (event) => {
    setSearchValue(event.target?.value);
  };

  useEffect(() => {
    if (data?.user?.vehicles) {
      if (searchValue.length) {
        setFilteredVehicles(
          data?.user?.vehicles.filter(vehicle =>
            vehicle.rv_name.toUpperCase().indexOf(searchValue.toUpperCase()) > -1
          )
        );
      }
      else {
        setFilteredVehicles(data?.user?.vehicles);
      }
    }

    else {
      setFilteredVehicles([]);
    }
  }, [data?.user?.vehicles, searchValue]);

  if (loading) {
    return (
      <SpinnerBlur />
    );
  }

  return (
    <div className={classes.wrapper}>
      <Typography
        component="h2"
        variant="headline"
        size="m"
        className={classes.title}
      >
        Select your vehicle
      </Typography>

      <MatchMedia mediaQuery="(max-width: 767px)">
        <div className="t-16 b-16">
          <RcCollapse
            header={content.title}
            content={content.description}
            className={classes.collapse}
          />
        </div>
      </MatchMedia>

      <div className="row">
        <section className={cn(classes.section, "col-12 col-l")}>
          <div className="t-16 t-m-20 b-16 b-m-20">
            <FieldInput
              className={classes.search}
              icon={<SearchIcon />}
              label="Search by year, manufacturer, make, model"
              onChange={searchChange}
              value={searchValue}
            />
          </div>

          {
            filteredVehicles.length
              ? filteredVehicles.map((vehicle) => {
                return vehicle.status.value === 'Active'
                  ? <VehicleCard
                      key={vehicle.id}
                      isActive={state.rv === vehicle.id}
                      vehicle={vehicle}
                      onSelectVehicle={onSelectVehicle}
                    />
                  : null;
              })
              : <EmptyVehiclesList />
          }
        </section>

        <aside className={classes["aside-info"]}>
          <ProTips
            title={content.title}
            description={content.description}
            style={classes}
          />
        </aside>
      </div>
    </div>
  );
};

export default SelectVehicle;
