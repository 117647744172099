import React, { useEffect, useState } from "react";
import ModalDetails from "components/ModalDetails/ModalDetails";
import { AmenitiesList } from "components/AmenitiesList/AmenitiesList";
import Button from "components/Button";
import MenuPipe from "components/MenuPipe/MenuPipe";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Typography from "components/Typography";
import classes from "./RulesSection.module.css";

const menuData = [
  {
    id: 'All',
    title: 'All'
  },
  {
    id: 'Bathroom',
    title: 'Bathroom'
  },
  {
    id: 'Kitchen',
    title: 'Kitchen'
  },
  {
    id: 'Climate Control',
    title: 'Climate Control'
  },
  {
    id: 'Entertainment',
    title: 'Entertainment'
  },
  {
    id: 'Other',
    title: 'Other'
  }
];

const amenitiesListData = {
  "All": [
    {
      id: '1',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725540.2362_01bdfda9.svg',
      title: 'Air Conditioning',
      available: true
    },
    {
      id: '2',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725432.1404_01bdfda9.svg',
      title: 'Wi-Fi',
      available: true
    },
    {
      id: '3',
      title: 'Air Conditioning',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725306.377_01bdfda9.svg'
    },
    {
      id: '4',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725540.2362_01bdfda9.svg',
      title: 'Solar',
      available: true
    },
    {
      id: '5',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725432.1404_01bdfda9.svg',
      title: 'Navigation',
      available: false
    },
    {
      id: '6',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725306.377_01bdfda9.svg',
      title: 'Navigation'
    },
    {
      id: '7',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725540.2362_01bdfda9.svg',
      title: 'Wi-Fi',
      available: false
    }
  ],
  "Bathroom": [
    {
      id: '1',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725540.2362_01bdfda9.svg',
      title: 'Air Conditioning',
      available: true
    },
    {
      id: '2',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725432.1404_01bdfda9.svg',
      title: 'Wi-Fi',
      available: true
    }
  ],
  "Kitchen": [
    {
      id: '3',
      title: 'Air Conditioning',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725306.377_01bdfda9.svg'
    },
    {
      id: '4',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725540.2362_01bdfda9.svg',
      title: 'Solar',
      available: true
    }
  ],
  "Climate Control": [
    {
      id: '5',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725432.1404_01bdfda9.svg',
      title: 'Navigation',
      available: false
    },
    {
      id: '6',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725306.377_01bdfda9.svg',
      title: 'Navigation'
    }
  ],
  "Entertainment": [
    {
      id: '7',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725540.2362_01bdfda9.svg',
      title: 'Wi-Fi',
      available: false
    }
  ],
  "Other": [
    {
      id: '2',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725432.1404_01bdfda9.svg',
      title: 'Wi-Fi',
      available: true
    },
    {
      id: '3',
      title: 'Air Conditioning',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725306.377_01bdfda9.svg'
    },
    {
      id: '4',
      photo: 'http://cw-rr.dev.quintegro.net/files/rv_amenities/1613725540.2362_01bdfda9.svg',
      title: 'Solar',
      available: true
    }
  ]
};

export const RulesSection = () => {
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const [showModal, setShowModal] = useState(false);
  const amenitiesListDataUnfilled = [...amenitiesListData["All"]].slice(0, 5);

  useEffect(() => {
    setShowModal(false);
  }, [isSmallDevice]);

  return (
    <div className={classes.container}>
      <Typography variant="subtitle" size="l" weight="bold">
        Owner Rules
      </Typography>
      <div>
        { showModal && !isSmallDevice && (
          <div className="mb-m-16">
            <MenuPipe data={menuData} />
          </div>
        )}
        <AmenitiesList
          data={
            !showModal
              ? amenitiesListDataUnfilled
              : amenitiesListData["All"]}
        />
      </div>
      <div className="t-32 b-16 t-m-24 b-m-20">
        <Button
          label={showModal ? 'Show Less' : 'All Rules'}
          onClick={() => setShowModal((p) => !p)}
          secondary
        />
      </div>
      <ModalDetails
        menuData={menuData}
        title="Amenities"
        show={showModal && isSmallDevice}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {(tabId) => (<AmenitiesList data={amenitiesListData[tabId]} />)}
      </ModalDetails>
    </div>
  );
};

// RulesSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

