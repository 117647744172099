import { gql } from "@apollo/client";

export const goodSamMembershipValidateMutation = gql`
  mutation good_sam_membership_validate($member_id: String!) {
    good_sam_membership_validate(member_id: $member_id) {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
      user {
        good_sam_membership_validation {
          key
          value
        }
      }
    }
  }
`;
