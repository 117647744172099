import React from "react";
import Button from "components/Button";
import Message from "components/Message";
import { Discount } from "./Discount";
import classes from "./Footer.module.css";
import { MQ_BP, MatchMedia } from "components/MatchMedia";
import { useWindowSize } from "../../hooks/useWindowSize";

export const Footer = ({
  period,
  discount,
  total,
  price,
  onClickCancel,
  onClickSelect,
  dateIntervalErrorMessage,
  isLoading,
  isDateIntervalError,
  isShowDiscount
}) => {
  const isSizeSmall = useWindowSize() < MQ_BP.small;

  if (!period) {
    return null;
  }

  const { from, to } = period;
  const errorAndSizeSmall = dateIntervalErrorMessage && isSizeSmall;

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContentContainer}>
        {!dateIntervalErrorMessage && (
          <div className={classes.footerContentDiscount}>
            {isShowDiscount && (
              <Discount price={price} total={total} discount={discount} />
            )}
          </div>
        )}
        {dateIntervalErrorMessage && (
          <Message type="error" text={dateIntervalErrorMessage} />
        )}
      </div>
      <div className={classes.footerButtonsContainer}>
        <MatchMedia mediaQuery="(min-width: 768px)">
          <Button
            onClick={onClickCancel}
            className={`btn--secondary ${classes.footerButtonCancel}`}
          >
            Cancel
          </Button>
        </MatchMedia>

        {!errorAndSizeSmall && (
          <Button
            onClick={onClickSelect}
            disabled={!from || !to || isDateIntervalError || isLoading}
            className={`${classes.footerButtonSelect}`}
          >
            Select dates
          </Button>
        )}
      </div>
    </footer>
  );
};
