import React, { useState } from "react";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import { EmailVerifyModal } from "components/Verification";
import { tickGreenIcon } from "../../../../../components/Icon";
import classes from "../ContactSection.module.css";

export const EmailForm = ({ email, content, isVerified }) => {
  const [inVerifying, setInVerifying] = useState(false);

  const toggleInVerifying = () => {
    setInVerifying(!inVerifying);
  };

  const leftpanel = {
    subtitle: content.emailSectionTitle,
    value: email,
    emptyValue: "",
    errorMsg: ""
  };

  const centralpanel = {
    action: "Verify"
  };

  return (
    <>
      <div>
        <div className="devider-solid mb-16 mb-m-20 mt-m-32 mt-xl-20" />

        <div>
          <Typography
            variant="subtitle"
            size="m"
            className={classes.sectionTitle}
          >
            {leftpanel.subtitle}
          </Typography>
        </div>

        <div className={`${classes.alignCenter} row`}>
          <div className=" col-m-6">
            <Typography
              component="h6"
              variant="subtitle"
              size="s"
              className={classes.emailText}
            >
              {leftpanel.value}
            </Typography>{" "}
          </div>
          <div className="col-m-6">
            <Button onClick={toggleInVerifying} disabled={isVerified} ghost>
              {!isVerified ? (
                centralpanel.action
              ) : (
                <div className={`${classes.isVerified}`}>
                  {tickGreenIcon}
                  <span>{content.emailVerifiedLabel}</span>
                </div>
              )}
            </Button>
          </div>
        </div>
        <EmailVerifyModal
          show={inVerifying}
          onClose={toggleInVerifying}
          description="Instructions have been sent to your email address."
        />
      </div>
    </>
  );
};
