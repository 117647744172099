import React, { useState, useCallback } from "react";
import { MenuPipe } from "components/MenuPipe/MenuPipe";
import { PriceForm } from "components/PriceForm";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import classes from "./PriceFormTabs.module.css";

const PriceFormTabs = (props) => {
  const {
    data = [],
    menuData,
    specRender,
    onAction
  } = props;

  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  const [tabIndex, setTabIndex] = useState({ tabIndex: 0 });

  const onClickTab = useCallback((tabId, tabIndex) => {
    setTabIndex({ tabIndex });
  }, []);

  return (
    <div>
      <div>
        <MenuPipe
          data={menuData}
          tab={tabIndex}
          isTableTabs
          onClickItem={onClickTab}
        />
      </div>
      <div className={classes.priceFormContainer}>
        <PriceForm
          data={isSmallDevice ? [data[tabIndex.tabIndex]] : data}
          specRender={specRender}
          onAction={onAction}
        />
      </div>
    </div>
  );
};

PriceFormTabs.propTypes = {
};

export default PriceFormTabs;
