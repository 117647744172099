import { gql } from "@apollo/client";

export const updateProfileMutation = gql`
  mutation(
    $first_name: String
    $last_name: String
    $zip: String
    $about: String
    $profile_image_remove: Boolean
    $cover_image_remove: Boolean
  ) {
    profile_update(
      first_name: $first_name
      last_name: $last_name
      zip: $zip
      about: $about
      profile_image_remove: $profile_image_remove
      cover_image_remove: $cover_image_remove
    ) {
      result {
        success
        errors {
          code
          field
          message
        }
      }
    }
  }
`;
