import { extractSuccessWithoutDataParameter } from "utils/extractErrors";
import { useMutation } from "@apollo/client";
import { saveTempInspection } from "processes/Rentals/RentalDetails/mutations/saveTempInspection";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";

const extractMutationSuccess = extractSuccessWithoutDataParameter(
  "save_temp_inspection"
);

export const useSaveTempInspection = ({ onSuccess } = {}) => {
  const { rental, refetch } = useRentalDetailsContext();

  const [action, result] = useMutation(saveTempInspection, {
    onCompleted: (response) => {
      if (extractMutationSuccess(response)) {
        onSuccess?.();
        refetch();
      }
    }
  });

  return {
    loading: result.loading,
    action: (type, generator, mileage) => {
      void action({
        variables: {
          id: rental.id,
          type: type,
          generator_hours: generator,
          mileage: mileage
        }
      });
    },
    errors: result?.data?.save_temp_inspection?.result?.errors
  };
};
