import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "components/Typography";
import classes from "./EditableInfoTexts.module.css";

export const EditableInfoTexts = (props) => {
  const { className, valueClassName, label, value, icon, onClick } = props;

  return (
    <div
      className={classnames(className, classes.root, {
        [classes.touchable]: Boolean(onClick)
      })}
      onClick={onClick}
    >
      <div className={classes.top}>
        <Typography className={classes.label} variant="body" size="l">
          {label}
        </Typography>
        {icon && <div className={classes.icon}>{icon}</div>}
      </div>
      <Typography
        className={classnames(valueClassName, classes.value)}
        variant="body"
        size="l"
      >
        {value}
      </Typography>
    </div>
  );
};

EditableInfoTexts.propTypes = {
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.any,
  onClick: PropTypes.func
};

export default EditableInfoTexts;
