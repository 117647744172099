import React from "react";
import { FormattedText } from "components/FormattedText";
import { ContentSectionElementTextTag } from "components/ContentSection/elements/Text";
import { CONTENT_TYPES } from "components/TextItem/constants/contentTypes";
import Typography from "components/Typography";

const TextItem = (props) => {
  const {
    data,
    plainStyle,
    tagCommonStyle,
    htmlStyle,
    tagDesc
  } = props;

  const type = data?.type;
  const value = data?.value;

  if (type === CONTENT_TYPES.FORMAT) {
    return (
      <FormattedText
        data={value}
        tagCommonStyle={tagCommonStyle}
        tagDesc={tagDesc}
      />
    );
  } else if (type === CONTENT_TYPES.HTML) {
    return (
      <div className={htmlStyle}>
        <ContentSectionElementTextTag content={{ type: 'html', value }} />
      </div>
    );
  }
  else {
    return (
      <Typography
        className={plainStyle}
      >
        {value}
      </Typography>
    );
  }

};

TextItem.propTypes = {
};

export default TextItem;
