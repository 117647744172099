/*
 * Stripe Utils
 */

import { useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";

export const useStripeLoader = () => {
  return useMemo(() => {
    const key = window?.CWRR?.StripePublicKey || process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    return loadStripe(key);
  }, []);
};

export const stripeToLocalBrand = (value) => {
  const _value = value?.toLowerCase();
  return ({
    "diners club": "diners",
    "american express": "amex"
  })[_value] || _value;
};
