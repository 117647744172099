import { gql } from "@apollo/client";

export const featureFlagsListQuery = gql`
  query feature_flags_list(
    $features: [String]!
    ) {
    feature_flags_list (
      feature_flags_list: $features
    )
  }
`;
