import { gql } from "@apollo/client";

export const photosImageCropMutation = gql`
  mutation rvImageCrop (
    $id: Int!
    $x1: Int!
    $x2: Int!
    $y1: Int!
    $y2: Int!
    $rotation: Int!
    $caption: String
  ) {
    rv_image_crop(
      image_id: $id,
      crop_area_x1: $x1
      crop_area_x2: $x2
      crop_area_y1: $y1
      crop_area_y2: $y2
      rotation: $rotation
      caption: $caption
    ) {
      result {
        success
        errors {
          field
          message
        }
      }
      image {
        id
        srcset {
          type
          url
        }
        caption
      }
    }
  }
`;
