import { gql } from "@apollo/client";

export const calculateAdditionalDriversQuery = gql`
  query calculate_additional_drivers($rental: Int!, $additional_drivers: [InputAdditionalDriver]) {
    calculate_additional_drivers(rental: $rental, additional_drivers: $additional_drivers) {
      result {
        success
        errors {
          code
          message
        }
      }
      price_form {
        total {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        desc {
          id
          value
          type
        }
        items {
          id
          type
          title {
            id
            value
            type
          }
          value {
            id
            value
            type
          }
          units
          style
          separ
        }
      }
    }
  }
`;

