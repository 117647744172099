import React, { useCallback } from "react";
import { ReactComponent as IconOfferExpired } from "./offer-expired.svg";
import { ReactComponent as IconOfferCanceled } from "./offer-canceled.svg";
import Typography from "components/Typography";
import Button from "components/Button";
import { useHistory } from "react-router-dom";
import { parseQueryString } from "utils/queryString";
import classes from "./PreDefinedStatus.module.css";

export const PreDefinedStatus = () => {
  const history = useHistory();
  const { search } = history.location;
  const { status, rv } = parseQueryString(search);
  const isExpiredStatus = status === 'expired';
  const isCanceledStatus = status === 'canceled';

  const goToRvProfile = useCallback(() => {
    window.location = rv;
  }, [rv]);

  const goToSearch = useCallback(() => {
    window.location = `/search`;
  }, []);

  return (
    <div className={classes.container}>
      <div className="tac">
        <div>
          {isExpiredStatus && <IconOfferExpired />}
          {isCanceledStatus && <IconOfferCanceled />}
        </div>
        <div className="mt-20">
          <Typography
            variant="subtitle"
            size="m"
            weight="bold"
            className={classes.title}
          >
            {isExpiredStatus && "Offer expired"}
            {isCanceledStatus && "Offer canceled"}
          </Typography>
        </div>
        <div>
          <Typography
            variant="body"
            size="l"
            className={classes.subtitle}
          >
            This offer seems to be outdated.
          </Typography>
        </div>
        <div className={classes.buttons}>
          <Button
            label="Check available dates"
            onClick={goToRvProfile}
          />
          <Button
            secondary
            label="Search other RV"
            onClick={goToSearch}
          />
        </div>
      </div>
    </div>
  );
};
