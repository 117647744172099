import { useCallback, useEffect } from "react";

const SEARCH_MAP_OFFSET = "--cwrr-search-map-offset";
const TOP_NAV_HEIGHT = 36;
const TOP_SUB_NAV_HEIGHT = 84;

function calculateMapOffset() {
  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

  if (scrollTop <= TOP_NAV_HEIGHT) {
    return TOP_SUB_NAV_HEIGHT + (TOP_NAV_HEIGHT - scrollTop);
  }

  return TOP_SUB_NAV_HEIGHT;
}

export function useMapSize(mapRef) {
  const map = mapRef?.current;

  const setOffset = useCallback(() => {
    const offset = calculateMapOffset();
    document.documentElement.style.setProperty(SEARCH_MAP_OFFSET, `${offset}px`);
  }, []);

  useEffect(() => {
    if (map) {
      setOffset();
    }
  }, [map, mapRef, setOffset]);

  useEffect(() => {
    window.addEventListener("scroll", setOffset);
    return () => {
      window.removeEventListener("scroll", setOffset);
    };
  }, [setOffset]);
}
