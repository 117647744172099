import { useEffect } from "react";
import { useContent } from "./useContent";
import { useLazyQuery } from "@apollo/client";
import { SpinnerBack } from "../Spinner/Spinner";

export const ContentLoader = ({ query, predicate, render, children }) => {
  // eslint-disable-next-line no-unused-vars
  const [content, setContent] = useContent();

  const [load] = useLazyQuery(query, {
    onCompleted: setContent
  });

  useEffect(load, [load]);

  if (!predicate(content)) {
    return <SpinnerBack />;
  }
  return typeof render === "function" ? render(content) : children;
};
