import { createContext, useContext } from "react";

export const BookingModalContext = createContext(null);

export function useBookingModalContext() {
  const context = useContext(BookingModalContext);

  if (!context) {
    throw new Error("useBookingModalContext must be used within a BookingModalProvider");
  }

  return context;
}
