export const VERIFICATION_STEPS = {
  email: 'email',
  phone: 'phone',
  payoutInfo: 'payoutInfo'
};

export const VERIFICATION_ORDER_DEFAULT = [
  VERIFICATION_STEPS.email,
  VERIFICATION_STEPS.phone,
  VERIFICATION_STEPS.payoutInfo
];
