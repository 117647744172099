import { useMutation } from "@apollo/client";
import {
  defaultTo,
  filter,
  lensProp,
  map,
  over,
  path,
  pick,
  pipe,
  prop
} from "ramda";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { convertCents } from "../../../../utils/convertCents";
import { getErrorsAndSuccess } from "utils/extractErrors";
import ProTipsCollapse from "../../../ProTipsCollapse";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ListingContext } from "../../ListingContext";
import { locationDeliveryMutation } from "../../mutations/locationDeliveryMutation";
import { filterInitialData } from "../filterInitialData";
import { LocationDeliveryForm } from "./LocationDeliveryForm";
import { ContentLoader } from "components/ContentSection";
import { locationSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { Text } from "components/ContentSection/elements";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { readStorage, writeStorage } from "components/Listing/steps/LocationDelivery/storage";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";

function compareSpecificDeliveryLocations(initialData, currentData) {
  let newSpecificDeliveryLocations = [];
  if (currentData && Array.isArray(currentData) && currentData?.length !== 0) {
    currentData.forEach(({ id, delivery_fee, description, location }) => {
      const initialItem = initialData.find(item => item.id === id);
      if (initialItem) {
        if (
          initialItem.delivery_fee !== delivery_fee
          || initialItem.description !== description
          || initialItem.location.full_address !== location.full_address
        ) {

          const new_delivery_fee = delivery_fee.replaceAll(',', '');

          const resultItem = {
            id,
            delivery_fee: Number(new_delivery_fee),
            description: description,
            location: location.full_address
          };

          newSpecificDeliveryLocations.push(resultItem);
        }
      }
    });

    currentData.forEach(({ id, delivery_fee, description, location }) => {
      if (String(id).includes('new-row_')) {
        const resultItem = {
          id: 0,
          delivery_fee: Number(delivery_fee),
          description: description,
          location: location.full_address
        };

        newSpecificDeliveryLocations.push(resultItem);
      }
    });
  }
  return newSpecificDeliveryLocations;
}

function convertDataToSubmitFormat(data) {
  let specific_delivery_locations_to_delete = data?.tabs?.[0]?.deletedSpecificDeliveryLocationsIds;
  let dor_specific_delivery_locations_to_delete = data?.tabs?.[1]?.deletedSpecificDeliveryLocationsIds;
  let specific_delivery_locations = compareSpecificDeliveryLocations(
    data?.tabs?.[0]?.initialSpecificDeliveryLocations,
    data?.tabs?.[0]?.specificDeliveryLocations
  );
  let dor_specific_delivery_locations = compareSpecificDeliveryLocations(
    data?.tabs?.[1]?.initialSpecificDeliveryLocations,
    data?.tabs?.[1]?.specificDeliveryLocations
  );

  const result = {
    allow_standard_rental: data?.tabs?.[0]?.allowSwitch,
    delivery_option: data?.tabs?.[0]?.delivery_option,
    free_delivery: Number(data?.tabs?.[0]?.free_delivery),
    paid_delivery: Number(data?.tabs?.[0]?.paid_delivery),
    delivery_price_type: data?.tabs?.[0]?.cost_per_mile ? 1 : 2,
    cost_per_mile: Number(data?.tabs?.[0]?.cost_per_mile_value),
    minimum_fee: Number(data?.tabs?.[0]?.minimum_fee),
    flat_delivery_price: Number(data?.tabs?.[0]?.flat_delivery_price),
    specific_delivery_locations_to_delete,
    specific_delivery_locations,
    specific_delivery_locations_allowed: data?.tabs?.[0]?.specific_destinations,

    allow_delivery_only_rental: data?.tabs?.[1]?.allowSwitch,
    dor_delivery_option: data?.tabs?.[1]?.delivery_option,
    dor_free_delivery: Number(data?.tabs?.[1]?.free_delivery),
    dor_paid_delivery: Number(data?.tabs?.[1]?.paid_delivery),
    dor_delivery_price_type: data?.tabs?.[1]?.cost_per_mile ? 1 : 2,
    dor_cost_per_mile: Number(data?.tabs?.[1]?.cost_per_mile_value),
    dor_minimum_fee: Number(data?.tabs?.[1]?.minimum_fee),
    dor_flat_delivery_price: Number(data?.tabs?.[1]?.flat_delivery_price),
    dor_specific_delivery_locations_to_delete,
    dor_specific_delivery_locations,
    dor_specific_delivery_locations_allowed: data?.tabs?.[1]?.specific_destinations
  };

  if (
    !result.specific_delivery_locations
    || (Array.isArray(result.specific_delivery_locations) && result.specific_delivery_locations.length === 0)) {
    delete result['specific_delivery_locations'];
  }
  if (
    !result.dor_specific_delivery_locations
    || (Array.isArray(result.dor_specific_delivery_locations) && result.dor_specific_delivery_locations.length === 0)) {
    delete result['dor_specific_delivery_locations'];
  }

  if (Array.isArray(result.specific_delivery_locations_to_delete) && result.specific_delivery_locations_to_delete.length === 0) {
    delete result['specific_delivery_locations_to_delete'];
  }

  if (Array.isArray(result.dor_specific_delivery_locations_to_delete) && result.dor_specific_delivery_locations_to_delete.length === 0) {
    delete result['dor_specific_delivery_locations_to_delete'];
  }
  return result;
}

function convertDataToInitialFormat(data) {
  const specificDeliveryLocations = data?.specific_delivery_locations?.map((d) => ({
    ...d,
    delivery_fee: convertCents(d.delivery_fee.cents_total)
  }));
  const specificDeliveryLocationsIds = data?.specific_delivery_locations?.map(({ id }) => id);
  const dorSpecificDeliveryLocations = data?.dor_specific_delivery_locations?.map((d) => ({
    ...d,
    delivery_fee: convertCents(d.delivery_fee.cents_total)
  }));
  const docSpecificDeliveryLocationsIds = data?.dor_specific_delivery_locations?.map(({ id }) => id);
  const locationRentalTab = typeof readStorage()?.locationDeliveryRentalTypeTab === 'number' ? readStorage()?.locationDeliveryRentalTypeTab : 0;

  const initialData = {
    locationRentalTab,
    location: {
      full_address: data?.location?.full_address,
      latitude: data?.location?.latitude === "" ? "" : Number(data?.location?.latitude),
      longitude: data?.location?.longitude === "" ? "" : Number(data?.location?.longitude)
    },
    tabs: [
      {
        id: 0,
        title: "Allow standard rental",
        allowSwitch: data?.allow_standard_rental,
        specific_destinations: data?.specific_delivery_locations_allowed,
        specificDeliveryLocations: specificDeliveryLocations || [],
        initialSpecificDeliveryLocations: specificDeliveryLocations || [],
        specificDeliveryLocationsIds,
        deletedSpecificDeliveryLocationsIds: [],
        delivery_price_type: data?.delivery_price_type === 0 || data?.delivery_price_type === 1 ? 1 : 2,
        cost_per_mile: data?.delivery_price_type === 0 || data?.delivery_price_type === 1,
        cost_per_mile_value: data?.cost_per_mile,
        flat_price: data?.delivery_price_type === 1,
        flat_delivery_price: (data?.flat_delivery_price === '0.00' || data?.flat_delivery_price === '0') ? "" : data?.flat_delivery_price,
        delivery_option: data?.delivery_option,
        free_delivery: data?.free_delivery,
        paid_delivery: data?.paid_delivery,
        minimum_fee: data?.minimum_fee
      },
      {
        id: 1,
        title: "Allow delivery only rental",
        allowSwitch: data?.allow_delivery_only_rental,
        specific_destinations: data?.dor_specific_delivery_locations_allowed,
        specificDeliveryLocations: dorSpecificDeliveryLocations || [],
        specificDeliveryLocationsIds: docSpecificDeliveryLocationsIds || [],
        initialSpecificDeliveryLocations: dorSpecificDeliveryLocations || [],
        deletedSpecificDeliveryLocationsIds: [],
        delivery_price_type: data?.dor_delivery_price_type === 0 || data?.dor_delivery_price_type === 1 ? 1 : 2,
        cost_per_mile: data?.dor_delivery_price_type === 0 || data?.dor_delivery_price_type === 1,
        cost_per_mile_value: data?.dor_cost_per_mile,
        flat_price: data?.dor_delivery_price_type === 2,
        flat_delivery_price: (data?.dor_flat_delivery_price === '0.00' || data?.dor_flat_delivery_price === '0') ? "" : data?.dor_flat_delivery_price,
        delivery_option: data?.dor_delivery_option,
        free_delivery: data?.dor_free_delivery,
        paid_delivery: data?.dor_paid_delivery,
        minimum_fee: data?.dor_minimum_fee
      }
    ]
  };
  return initialData;
}

const filterInitialLocation = filterInitialData([
  "location",
  "delivery_option",
  "free_delivery",
  "paid_delivery",
  "cost_per_mile",
  "minimum_fee",
  "delivery_option",
  "allow_standard_rental",
  "allow_delivery_only_rental",
  "delivery_price_type",
  "flat_delivery_price",
  "specific_delivery_locations",
  "dor_specific_delivery_locations",
  "dor_delivery_option",
  "dor_free_delivery",
  "dor_paid_delivery",
  "dor_delivery_price_type",
  "dor_cost_per_mile",
  "dor_minimum_fee",
  "dor_flat_delivery_price",
  "specific_delivery_locations_allowed",
  "dor_specific_delivery_locations_allowed"
]);

const processInitialData = pipe(
  over(lensProp("cost_per_mile"), pipe(prop("cents_total"), convertCents)),
  over(lensProp("minimum_fee"), pipe(prop("cents_total"), convertCents)),
  over(lensProp("flat_delivery_price"), pipe(prop("cents_total"), convertCents)),
  over(lensProp("dor_cost_per_mile"), pipe(prop("cents_total"), convertCents)),
  over(lensProp("dor_minimum_fee"), pipe(prop("cents_total"), convertCents)),
  over(lensProp("dor_flat_delivery_price"), pipe(prop("cents_total"), convertCents)),
  filterInitialLocation,
  convertDataToInitialFormat
);

const normalizeValues = pipe(
  over(lensProp("free_delivery"), (v) => parseInt(v, 10)),
  over(lensProp("paid_delivery"), (v) => parseInt(v, 10)),
  over(lensProp("cost_per_mile"), (v) => parseFloat(v)),
  over(lensProp("minimum_fee"), (v) => parseFloat(v))
);
const rvUpdateMutationEAS = getErrorsAndSuccess("rv_update");

const extractRv = path(["data", "rv_update", "rv"]);
const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "location"),
  map(pick(["field", "message"]))
);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "location")
);

const LocationDeliveryDataProvider = ({
  context,
  updateContext,
  currStep,
  prevStep,
  nextStep
}) => {
  const { id: initialId } = context.initialData;
  const isSegmentSent = useRef(null);
  const isFooterVisible = context?.isFooterVisible;
  const history = useHistory();
  const [locationError, setLocationError] = useState(null);
  const [formKey, setFormKey] = useState(1);
  const [action, result] = useMutation(locationDeliveryMutation);
  const [requestErrors, setRequestErrors] = useState([]);

  const handleSave = useCallback(
    async ({ location, ...values }, { toNextStep = true } = {}) => {
      const { full_address } = location;
      const data = convertDataToSubmitFormat(values);

      const variables = {
        ...data,
        id: initialId,
        location: full_address
      };

      const response = await action({ variables });

      if (rvUpdateMutationEAS.isSuccess(response)) {
        const rv = extractRv(response);
        writeStorage({ locationDeliveryRentalTypeTab: values.locationRentalTab });
        pushGoogleAnalytics("listVehicleLocationDelivery", {
          listingCity: rv?.location?.city,
          listingState: rv?.location?.state,
          listingCountry: rv?.location?.full_address,
          deliveryOption: rv?.delivery_option
        });
        handleListingSegment(rv, "Lead Form Step Completed", "Rental Types & Delivery Options", 6, {
          cust_asset_class: rv?.class,
          cust_asset_id: String(rv?.id),
          cust_asset_make: rv?.make,
          cust_asset_model: rv?.model,
          cust_asset_year: String(rv?.year),
          cust_asset_length: rv?.length?.inches_total,
          cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
          cust_asset_sleepnumber: rv?.sleeps,
          cust_asset_slideouts: rv?.slide_outs,
          cust_asset_weight: rv?.weight
        });
        updateContext({
          initialData: {
            ...context.initialData,
            ...rv
          },
          errors: cleanSectionErrors(context.errors)
        });

        if (nextStep && toNextStep) {
          history.push(`/listing/${nextStep.path}?id=${rv.id}`, {
            prevStep: currStep
          });
          writeStorage({ locationDeliveryRentalTypeTab: 0 });
        } else {
          setFormKey((key) => key + 1);
        }
      }

      if (rvUpdateMutationEAS.isErrors(response)) {
        const errors = response?.data?.rv_update?.result?.errors || [];
        const isErrorLocation = errors.find((error) => error?.field === 'location');
        if (isErrorLocation) {
          setLocationError("Please enter the address of your RV's location. This address will not be available for other users.");
        }

        setRequestErrors(rvUpdateMutationEAS.errors(response));
      }
    },
    [
      initialId,
      action,
      updateContext,
      context.initialData,
      context.errors,
      nextStep,
      history,
      currStep,
      setLocationError
    ]
  );

  const handleBack = useCallback(() => {
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);

  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );
  const handleBeforeunload = useCallback(
    () => {
      writeStorage({ locationDeliveryRentalTypeTab: 0 });
    },
    []
  );

  const onResetError = useCallback(() => {
    updateContext({
      errors:[]
    });
    setRequestErrors([]);
  }, [updateContext]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeunload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [handleBeforeunload]);

  useEffect(() => {
    if (context?.errors?.length > 0) {
      setRequestErrors(filterPublishErrorsBySection(context.errors));
    } else {
      setRequestErrors([]);
    }
  }, [context?.errors]);

  const normalizeData = () => {
    /**
     * TODO:
     *  updated context data after handleSave doesn't processed by processInitialData
     */
    return processInitialData(context.initialData);
  };

  useEffect(() => {
    if (!isSegmentSent.current) {
      const rv = context?.initialData;
      handleListingSegment(context?.initialData, "Lead Form Step Viewed", "Rental Types & Delivery Options", 6, {
        cust_asset_class: rv?.class,
        cust_asset_id: String(rv?.id),
        cust_asset_make: rv?.make,
        cust_asset_model: rv?.model,
        cust_asset_year: String(rv?.year),
        cust_asset_length: rv?.length?.inches_total,
        cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
        cust_asset_sleepnumber: rv?.sleeps,
        cust_asset_slideouts: rv?.slide_outs,
        cust_asset_weight: rv?.weight
      });
      isSegmentSent.current = true;
    }
  }, [context?.initialData]);

  return (
    <>
      <div className="locationScroll">
        <Text
          id="step_title"
          section="locationSectionContent"
          variant="headline"
          size="s"
        />
      </div>
      <ProTipsCollapse className="t-16 b-32" mediaParams="767px" />

      <LocationDeliveryForm
        key={formKey}
        initialData={normalizeData()}
        requestErrors={requestErrors}
        onResetError={onResetError}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        locationError={locationError}
        setLocationError={setLocationError}
        loading={result.loading}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        prevStep={prevStep}
        nextStep={nextStep}
        isFooterVisible={isFooterVisible}
      />
      {result.loading && <SpinnerBack />}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const locationSectionContentPredicate = everyKey(["locationSectionContent"]);

export const LocationDelivery = ({ currStep, prevStep, nextStep }) => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <ContentLoader
          query={locationSectionContentQuery}
          predicate={locationSectionContentPredicate}
          render={() => (
            <LocationDeliveryDataProvider
              context={context[0]}
              updateContext={context[1]}
              currStep={currStep}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
        />
      )}
    </ListingContext.Consumer>
  );
};
