import React, { useMemo } from "react";
import { FILTERS }  from "components/Widget/filters";
import MobileFilterBlock from "./MobileFilterBlock";
import { getObjectPositiveValuesCount } from "utils/basic";
import omit from "lodash/omit";
import classes from "./MobileFilterClass.module.css";
import Checkbox from "components/form/Checkbox";
import { getLocation } from "components/Widget/utils/utils";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import cn from "classnames";

const MobileFilterLocations = () => {
  const { filtersWatch, applyFilters, allCards, theme } = useWidgetContext();
  const { locations: filterLocations } = filtersWatch();

  const allLocations = useMemo(() => getLocation(allCards), [allCards]);

  return (
    <MobileFilterBlock
      id={FILTERS.ids.locations}
      getLabelFromValue={value => {
        const count = getObjectPositiveValuesCount(value);
        const total = count;
        return count > 0 ? `${count} out of ${total} selected` : null;
      }}
      renderForm={({ value, onChange }) => {
        return (
          <div className="ml-12 mt-28">
            <div className="row">
              {allLocations.map((i, index) => (
                <div
                  key={i.id}
                  className={`col-12 ${index !== 0 ? "mt-20" : ""}`}
                >
                  <Checkbox
                    className={cn(
                      theme === 'dark' && classes.checkboxDark,
                    )}
                    labelTextClassName={classes.checkboxLabel}
                    pseudoCheckClassName={cn(
                      classes.checkboxPseudoCheck
                    )}
                    id={i.id}
                    name={i.id}
                    text={i.name}
                    checked={filterLocations[i.id]?.checked || false}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      onChange(omit(
                        { ...value, [i.id]: { checked: checked, name: i.name } },
                        !checked ? i.id : null
                      ));
                      applyFilters();
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
};

MobileFilterLocations.propTypes = {
};

export default MobileFilterLocations;
