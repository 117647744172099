import React, { useState, useMemo, useEffect, useCallback } from "react";
import cn from 'classnames';
import { useForm } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Typography from "components/Typography";
import Radio from "components/form/Radio/Radio";
import { FieldController } from "components/form/FieldController/FieldController";
import Tooltip from "components/Tooltip/Tooltip";
import { BigVerticalRadioListContainer } from "processes/Booking/components/PickUpDeliveryModal/components/BigVerticalRadioListContainer";
import { Map } from "processes/Booking/components/PickUpDeliveryModal/components/Map/Map";
import { calculateDeliveryFee } from "processes/Booking/components/PickUpDeliveryModal/queries/calculateDeliveryFee";
import { RENTAL_TYPE } from 'utils/constants/rentalType';
import classes from './SelectDelivery.module.css';

const DEFAULT_VALUES = {
  deliveryType: null,
  location: {
    full_address: "",
    latitude: "",
    longitude: ""
  },
  radio: 'location-1'
};

const DEFAULT_DELIVERY_FEE = {
  deliveryFee: null,
  error: null
};

const locationData = {
  [RENTAL_TYPE.STANDARD]: {
    locationSelected: false,
    fullAddress: "",
    deliveryRadius: null,
    specificDeliveryLocation: null,
    radioState: 'location-1'
  },
  [RENTAL_TYPE.DELIVERY]: {
    locationSelected: false,
    fullAddress: "",
    deliveryRadius: null,
    specificDeliveryLocation: null,
    radioState: 'location-1'
  }
};

const SelectDelivery = ({ state, onSelectDelivery, onCloseError }) => {
  const [keyMap, setKeyMap] = useState(1);
  const [rentalType, setRentalType] = useState(() => {
    if (!state.allow_standard_rental) {
      return RENTAL_TYPE.DELIVERY;
    }

    if (!state.allow_delivery_only_rental) {
      return RENTAL_TYPE.STANDARD;
    }

    return RENTAL_TYPE.NULL;
  });

  const [isMapLoaded, setMapLoaded] = useState(false);
  const [showDefaultLocation, setShowDefaultLocation] = useState(false);
  const [radioState, setRadioState] = useState(() => {
    if (!state.allow_standard_rental) {
      return undefined;
    }

    return 'location-1';
  });
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(false);
  const [deliveryFeeData, setDeliveryFeeData] = useState(DEFAULT_DELIVERY_FEE);

  const [loadCalculateDeliveryFee, { data }] = useLazyQuery(
    calculateDeliveryFee,
    {
      fetchPolicy: "no-cache"
    }
  );

  const handleOnCalculateDeliveryFee = useCallback(
    (deliveryLocation, specificDeliveryLocationId) => {
      loadCalculateDeliveryFee({
        variables: {
          rv: state.rv,
          location: deliveryLocation,
          rental_type: rentalType,
          specific_delivery_location_id: specificDeliveryLocationId
        }
      });
    },
    [loadCalculateDeliveryFee, rentalType, state.rv]
  );

  useEffect(() => {
    const result = data?.calculate_delivery_fee;
    if (result?.delivery_fee) {
      setDeliveryFeeData({
        deliveryFee: result?.delivery_fee,
        error: null
      });
    } else if (result?.result?.errors) {
      setDeliveryFeeData({
        deliveryFee: null,
        error: result?.result?.errors
      });
    }
  }, [data]);

  const isMobile = useMatchMedia({ maxWidth: MQ_BP.small });

  const deliveryTypeList = useMemo(() => [
    {
      disabled: !state.allow_standard_rental,
      value: RENTAL_TYPE.STANDARD,
      text: (isMobile && rentalType) ? "Standard" : "Standard rental",
      hint: "This type of rental allows you to offer both renter pick-up and delivery. If you are comfortable with renters driving or towing your RV, this selection allows them to do so. You can also set customized delivery options. Offering delivery is not required.",
      tooltip: "This RV is only available for delivery rentals"
    },
    {
      disabled: !state.allow_delivery_only_rental,
      value: RENTAL_TYPE.DELIVERY,
      text: (isMobile && rentalType) ? "Delivery only" : "Delivery only rental",
      hint: "Do you own a larger RV or a fifth wheel? Maybe you would just feel more comfortable not allowing anyone else to drive or tow your RV on their own? This rental type allows you to set custom delivery and setup fees so you can transport your RV to your renter’s destination, stress-free.",
      tooltip: "This RV is only available for standard rentals"
    }
  ], [isMobile, rentalType, state.allow_delivery_only_rental, state.allow_standard_rental]);

  const paidDelivery = useMemo(() => {
    switch (rentalType) {
    case RENTAL_TYPE.STANDARD:
      return state.paid_delivery;

    case RENTAL_TYPE.DELIVERY:
      return state.dor_paid_delivery;

    default:
      return state.paid_delivery;
    }
  }, [rentalType, state.dor_paid_delivery, state.paid_delivery]);

  const specificDeliveryLocations = useMemo(() => {
    switch (rentalType) {
    case RENTAL_TYPE.STANDARD:
      return state.specific_delivery_locations;

    case RENTAL_TYPE.DELIVERY:
      return state.dor_specific_delivery_locations;

    default:
      return state.specific_delivery_locations;
    }
  }, [rentalType, state.dor_specific_delivery_locations, state.specific_delivery_locations]);

  const deliveryRadius = {
    [RENTAL_TYPE.STANDARD]: state.delivery_option,
    [RENTAL_TYPE.DELIVERY]: state.dor_delivery_option
  };

  const {
    watch,
    control,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: Object.assign({}, DEFAULT_VALUES),
    mode: "onClick",
    shouldUnregister: false
  });

  const { location } = watch();

  useEffect(() => {
    setKeyMap((key) => key + 1);
  }, [radioState]);

  useEffect(() => {
    const dataObject = {
      rental_type: rentalType,
      delivery_location: null,
      specific_delivery_location_id: null,
      specific_delivery_location: null,
      valid_location: false,
      error: null
    };

    if (rentalType && radioState) {
      switch (radioState) {
      case 'location-1':
        onSelectDelivery({
          ...dataObject,
          valid_location: true
        });
        break;

      case 'location-2':
        if (deliveryFeeData?.deliveryFee && currentLocation?.full_address) {
          onSelectDelivery({
            ...dataObject,
            delivery_location: currentLocation.full_address,
            valid_location: true
          });
        } else {
          onSelectDelivery({
            ...dataObject,
            valid_location: false,
            error: currentLocation?.full_address ? deliveryFeeData?.error && deliveryFeeData.error[0] : null
          });
        }
        break;

      default:
        onSelectDelivery({
          ...dataObject,
          specific_delivery_location_id: Number(radioState),
          specific_delivery_location: specificDeliveryLocations?.filter(location => location.id === Number(radioState))[0].location?.full_address,
          valid_location: true
        });
        break;
      }
    } else {
      onSelectDelivery({
        ...dataObject,
        valid_location: false
      });
    }
  }, [rentalType, radioState, onSelectDelivery, currentLocation, deliveryFeeData?.deliveryFee, state.specific_delivery_locations, specificDeliveryLocations, deliveryFeeData?.error]);

  return (
    <div className={classes.wrapper}>
      <div className={cn(classes.main, "row mt-8 mt-l-12")}>
        <div className="col-12 col-m-8 col-l-12">
          <Typography
            component="h2"
            variant="headline"
            size="m"
            className={classes.title}
          >
            Pick-up/delivery location
          </Typography>
        </div>

        <div className="t-16 t-m-20 col-12"></div>

        <div className="col-12 col-m-8 col-l-5 col-xl-4">
          <Typography
            component="p"
            variant="body"
            className={classes.rentalTypeHeader}
          >
            Select a rental type:
          </Typography>

          <div className={cn(classes.rentalTypeList, { [classes.isRentalTypeChosen]: rentalType !== null })}>
            <FieldController
              name="deliveryType"
              control={control}
              render={(renderProps) => (
                deliveryTypeList.map((item, index) =>
                  <Tooltip
                    trigger={item.disabled ? 'hover' : null}
                    tooltipContent={item.tooltip}
                    placement="top"
                  >
                    {(ref) => (
                      <div
                        className={classes.rentalTypeWrapper}
                        ref={ref}
                        id={String(item.value)}
                        key={item.value}
                        onClick={(e) => {
                          if (!item.disabled) {
                            setRentalType(item.value);
                            setCurrentLocation(null);
                            if (item.value === 1) {
                              setRadioState('location-1');
                            } else {
                              setRadioState(undefined);
                            }
                            setDeliveryFeeData(DEFAULT_DELIVERY_FEE);
                            setShowDefaultLocation(true);
                          }
                        }}
                      >
                        <Radio
                          {...renderProps}
                          className={cn(classes.rentalTypeRadio, { [classes.isDisabled]: item.disabled })}
                          id={String(item.value)}
                          text={item.text}
                          disabled={item.disabled}
                          checked={rentalType === item.value}
                          value={item.value}
                          ref={ref}
                        />

                        <Typography
                          component="p"
                          variant="body"
                          className={cn(classes.rentalTypeRadioHint, { [classes.isRentalTypeChosen]: rentalType !== null })}
                        >
                          {item.hint}
                        </Typography>


                        {index < deliveryTypeList.length - 1
                          && rentalType === null
                          && <div className="devider-solid devider-solid--light mt-8 mb-8 mt-m-12 mb-m-12" />}
                      </div>
                    )}
                  </Tooltip>
                )
              )}
            />
          </div>

          {rentalType && <div className="col-12 devider-solid mt-8 mb-8 mt-m-12 mb-m-12" />}


          {rentalType !== null && (
            <>
              <Typography
                component="p"
                variant="body"
                className={classes.rentalTypeHeader}
              >
                {rentalType === RENTAL_TYPE.STANDARD ? "Select Pick-up location:" : "Select delivery location:"}
              </Typography>

              <BigVerticalRadioListContainer
                initialLocation={state.location}
                showDefaultLocation={showDefaultLocation}
                control={control}
                errors={errors}
                isMapLoaded={isMapLoaded}
                radioState={radioState}
                currentLocation={currentLocation}
                isResetCurrentLocation={true}
                handleOnCalculateDeliveryFee={handleOnCalculateDeliveryFee}
                paidDelivery={paidDelivery}
                specificLocations={specificDeliveryLocations || []}
                setShowDefaultLocation={setShowDefaultLocation}
                setCurrentLocation={setCurrentLocation}
                setShowNotificationPopover={onCloseError}
                location={location}
                setRadioState={setRadioState}
                rentalType={rentalType}
                deliveryRadius={deliveryRadius}
                locationData={locationData}
                deliveryFeeData={deliveryFeeData}
                setDeliveryFeeData={setDeliveryFeeData}
                setValue={setValue}
              />
            </>
          )}
        </div>

        <div
          className={cn(
            'col-xl-8 col-l-7',
            classes.mapContainer
          )}
        >
          <div
            className={cn(
              'l-l-24 mt-l-12 l-xxl-32',
              classes.mapInner
            )}
          >
            <Map
              key={keyMap}
              paidDelivery={paidDelivery}
              initialLocation={state.location}
              specificDeliveryLocations={specificDeliveryLocations || []}
              value={radioState !== undefined && !showDefaultLocation ? currentLocation : null}
              setMapLoaded={setMapLoaded}
              showGeoObjects={deliveryRadius[rentalType]}
              isDefaultLocationOnly={radioState === 'location-1' && rentalType === RENTAL_TYPE.STANDARD && !deliveryRadius[rentalType]}
              isSelectedSpecificLocation={
                ((rentalType === RENTAL_TYPE.STANDARD || rentalType === RENTAL_TYPE.DELIVERY)
                  && radioState !== undefined
                  && radioState !== 'location-1'
                  && radioState !== 'location-2')
              }
              hasNotDeliveryRadius={rentalType === RENTAL_TYPE.DELIVERY && !deliveryRadius[2] && radioState === ''}
              showAllLocations={radioState === undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDelivery;
