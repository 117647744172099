import React, { useState, useCallback } from "react";
import Typography from "../../../../../components/Typography";
import { MatchMedia } from "components/MatchMedia";
import classnames from "classnames";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { useMutation } from "@apollo/client";
import ApiButton from "components/Button/ApiButton";
import Button from "components/Button";
import { acceptLastSuggestion } from "processes/Rentals/RentalDetails/mutations/actions";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import { ModalConfirmation } from "components/ModalConfirmation/ModalConfirmation";
import { maybePlural } from "utils/possessive";
import style from "./style.module.css";
import TableInfoModal from "components/TableInfo/components/TableInfoModal";
import { ModalGoTo } from "components/ModalGoTo/ModalGoTo";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import useUserBankAccount from "processes/Rentals/RentalDetails/components/RequestHistory/hooks/useUserBankAccount";
import { useRentalDetailsTableRow } from "processes/Rentals/RentalDetails/hooks/useRentalDetailsTableRow";
import { SpinnerBack } from "components/Spinner/Spinner";
import { useRentalCancellingTableRow } from "processes/Rentals/RentalDetails/hooks/useRentalCancellingTableRow";
import VerificationCodeStepsModals from "components/VerificationCodeStepsModals/VerificationCodeStepsModals";
import { isUserRenter } from "processes/Rentals/utils";
import { path } from "ramda";
import { extractServerErrors, extractSuccess, extractServerErrorMessage, extractServerErrorsCode } from "utils/extractErrors";
import { VERIFICATION_ERROR_CODE_LIST, DEFAULT_DATA } from "components/VerificationCodeStepsModals/constants/verificationErrorCodes";
import { handleRentalsSegment, getPickUpLocationTitle } from "processes/Rentals/utils";
import { PriceFormModal } from "components/PriceForm";
import { Button as ButtonContent } from "components/ContentSection/elements";
import cn from 'classnames';
import classes from "processes/Rentals/RentalDetails/components/RequestHistory/style.module.css";

const extractAcceptLastSuggestionSuccess = extractSuccess("accept_last_suggestion");
const extractAcceptLastSuggestionError = extractServerErrors("accept_last_suggestion");
const extractAcceptLastSuggestionErrorMessage = extractServerErrorMessage(
  "accept_last_suggestion"
);
const extractAcceptLastSuggestionErrorCode = extractServerErrorsCode(
  "accept_last_suggestion"
);
const getAcceptResultErrorCode = path(["accept_last_suggestion", "result", "errors", [0], "code"]);

function getErrorsMessages(errors = []) {
  if (errors?.length === 1) {
    return errors.map((error) => `${error.message}`).join("");
  }
  if (errors?.length > 1) {
    return errors.map((error, index) => `${index + 1}. ${error.message}`).join("\n");
  }

  return "";
}
const RequestMessageInfo = ({ executeAction, bookingInfo, isOwner, status, rental }) => {
  const {
    return_date,
    departure_date,
    pick_up_location,
    add_ons,
    price,
    price_full,
    price_form,
    action_buttons,
    action_link
  } = bookingInfo;

  const [showModalGoTo, setShowModalGoTo] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  const [acceptSuggestionErrors, setAcceptSuggestionErrors] = useState("");
  const [showAcceptSuggestionPopover, setShowAcceptSuggestionPopover] = useState(false);
  const [showCancellingModal, setShowCancellingModal] = useState(false);
  const [showTIIModal, setShowTIIModal] = useState(false);
  const [verificationStepsData, setVerificationStepsData] = useState(DEFAULT_DATA);
  const hasBankAccountNumber = useUserBankAccount();
  const {
    refetch,
    rental: { id: rentalId, rental_type: { key } },
    isIMGlobalIntegrationIsEnabled,
    openRentalPaymentModal
  } = useRentalDetailsContext();

  const rentalType = Number(key);
  const rentalTypeValue = rentalType === 1 ? "Standard" : "Delivery Only";
  const pickUpLocationLabel = getPickUpLocationTitle(rental);
  const isRenter = isUserRenter(rental.role);

  const [
    acceptSuggestionAction,
    { loading: acceptSuggestionLoading }
  ] = useMutation(acceptLastSuggestion);

  const onAcceptSuggestion = useCallback(async (variables) => {
    const response = await acceptSuggestionAction({ variables });

    if (extractAcceptLastSuggestionSuccess(response)) {
      setShowTIIModal(false);
      refetch();
    } else if (extractAcceptLastSuggestionError(response)?.length > 0) {
      const acceptResultErrorCode = extractAcceptLastSuggestionErrorCode(response);

      if (VERIFICATION_ERROR_CODE_LIST.includes(acceptResultErrorCode)) {
        setVerificationStepsData({
          code: acceptResultErrorCode,
          variables
        });
      } else if (acceptResultErrorCode === -14) {
        setShowModalGoTo(true);
      } else if (acceptResultErrorCode === -2) {
        setShowTIIModal(true);
      } else  {
        const errorsText = extractAcceptLastSuggestionErrorMessage(response);
        setAcceptSuggestionErrors(errorsText);
        setShowAcceptSuggestionPopover(true);
      }
    } else {
      setShowTIIModal(false);
    }
  }, [acceptSuggestionAction, refetch]);

  const [
    showNotificationPopover,
    setShowNotificationPopover
  ] = useState(false);


  const isRentalStatusCancelled = status?.name === 'Cancelled';
  const rentalPrice = rental?.price;
  const bookingCancelled = bookingInfo?.text === 'Booking request was cancelled';
  const bookingCancelledPriceTotal = isOwner
    ? rentalPrice?.owner_earns?.user_friendly
    : rentalPrice?.payment_total?.user_friendly;

  const hasButtons = action_buttons?.length > 0 || action_link;
  const hasAddons = add_ons?.length > 0;
  const hasInfos = [
    return_date,
    departure_date,
    pick_up_location,
    hasAddons
  ].some(Boolean);

  const modalTitle = isOwner ? 'Payout info' : "Payment info";

  const cancellingTableTitle = isOwner ? 'Payout info' : "Payment info";

  const cancellingTableData = [
    rental?.price_original_pf,
    rental?.price_refunds_pf,
    rental?.price_final_pf
  ];

  if (!hasInfos && !hasButtons && !bookingCancelled) {
    return null;
  }
  const totalPriceLabel = isOwner ? "You earn" : "Rental pricing";
  const pricesLabel = isOwner ? "Show payout info" : "Show payment info";
  const {
    price_original: priceOriginal,
    price_refunds: priceRefunds
  } = rental;

  const showBanner = isIMGlobalIntegrationIsEnabled && (
    priceOriginal?.trip_interruption_insurance?.cents_total
    || priceRefunds?.trip_interruption_insurance?.cents_total
    || rentalPrice?.trip_interruption_insurance?.cents_total);

  const modalConfirmationData = {
    title: "Apply Trip Interruption / Interior Protection Insurance",
    textContent: 'Please type your full name as a signature:',
    inputLabel: "Signature",
    buttons: {
      confirmButtonLabel: 'Apply',
      cancelButtonLabel: 'Cancel'
    },
    onSubmit: ({ field: value }) => {
      onAcceptSuggestion({
        id: rentalId,
        signature_name: value
      });
    },
    onClose: () => {
      setShowTIIModal(false);
    }
  };

  const modalGoToData = {
    title: "One little thing",
    textContent: 'Please add the payout information in your account first',
    buttons: {
      actionButtonLabel: 'To payout settings',
      cancelButtonLabel: 'Cancel'
    },
    linkPageTo: '/account/payout',
    onClose: () => {
      setShowModalGoTo(false);
    }
  };
  return (
    <>
      <VerificationCodeStepsModals
        data={verificationStepsData}
        onResetData={setVerificationStepsData}
        onRefetchMutation={onAcceptSuggestion}
      />
      <ModalGoTo show={showModalGoTo} {...modalGoToData} />
      <NotificationPopover
        show={showAcceptSuggestionPopover}
        status="error"
        text={acceptSuggestionErrors}
        onClose={() => {
          setShowAcceptSuggestionPopover(false);
        }}
      />
      {acceptSuggestionLoading && <SpinnerBack />}
      <ModalConfirmation
        show={showTIIModal}
        {...modalConfirmationData}
      />

      {price_form && (
        <PriceFormModal
          show={showPrices}
          title={modalTitle}
          data={[price_form]}
          onClose={() => setShowPrices(false)}
          isFooter={false}
        />
      )}

      <PriceFormModal
        show={showCancellingModal}
        onClose={() => setShowCancellingModal(false)}
        title={cancellingTableTitle}
        data={cancellingTableData}
        menuData={[
          {
            id: "1",
            title: 'Original fee'
          },
          {
            id: "2",
            title: 'Refund'
          },
          {
            id: "3",
            title: 'Final amount'
          }
        ]}
        isFooter={false}
        banner={
          showBanner && showNotificationPopover && isIMGlobalIntegrationIsEnabled && isRenter ? (
            <NotificationPopover
              show={true}
              variant="static"
              status="warning"
              title="For Trip Interruption / Interior Protection Insurance coverage please contact IMG via:"
              text={(
                <span className="db mb-12">


                  <span className="db">
                    Phone at {" "}
                    <ButtonContent
                      id="tii_phone1"
                      section="linksContent"
                      label="1-866-243-7524"
                      ghost
                      prefixLink="tel:"
                      className={cn(classes.tiiLink, 'underline')}
                    />
                    {" "} or {" "}
                    <ButtonContent
                      id="tii_phone2"
                      section="linksContent"
                      label="317-655-9798"
                      ghost
                      prefixLink="tel:"
                      className={cn(classes.tiiLink, 'underline')}
                    />
                  </span>

                  <span className="db">
                    Email at {" "}
                    <ButtonContent
                      id="imglobal_claims_email"
                      section="linksContent"
                      label="itravelclaims@imglobal.com"
                      ghost
                      prefixLink="mailto:"
                      className={cn(classes.tiiLink, 'underline')}
                    />
                  </span>

                  <span className="db">
                    Claim forms can be found: {" "}
                    <ButtonContent
                      id="imglobal"
                      section="linksContent"
                      label="Claims Center - IMG"
                      ghost
                      newTab
                      className={cn(classes.tiiLink, 'underline')}
                    />
                  </span>

                </span>
              )}
              onClose={() => { setShowNotificationPopover(false); }}
            />) : null
        }
      />

      <MatchMedia mediaQuery="(min-width: 768px)">
        <div
          className={classnames(
            style.requestMessage__info,
            "fbs mt-16 mb-16"
          )}
        >
          <div
            className={classnames(
              style.requestMessage__info__left,
              "request-message__info-left df fdc l-16"
            )}>
            {bookingCancelled && (
              <div className="request-message__row fss">
                <div
                  className={classnames(style.requestMessage__infoCell, "r-16")}
                >
                  <Typography variant="body" size="l">
                    <span
                      className="link underline"
                      onClick={() => {
                        setShowCancellingModal(true);
                        setShowNotificationPopover(true);
                      }}
                    >
                      {pricesLabel}
                    </span>
                  </Typography>
                </div>
              </div>
            )}
            {rentalTypeValue && (
              <div className="request-message__row fss">
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__label, "r-16")}
                >
                  <Typography variant="body" size="l">
                    Rental type
                  </Typography>
                </div>
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__value, "r-16")}
                >
                  <Typography variant="body" size="l" weight="bold" className={style.requestMessage__infoCell__bold}>
                    {rentalTypeValue}
                  </Typography>
                </div>
              </div>
            )}
            {departure_date && (
              <div className="request-message__row fss">
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__label, "r-16")}
                >
                  <Typography variant="body" size="l">
                    Pick-up
                  </Typography>
                </div>
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__value, "r-16")}
                >
                  <Typography variant="body" size="l"  className={style.requestMessage__infoCell__bold}>
                    {departure_date}
                  </Typography>
                </div>
              </div>
            )}
            {return_date && (
              <div className="request-message__row fss">
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__label, "r-16")}
                >
                  <Typography variant="body" size="l">
                    Drop off
                  </Typography>
                </div>
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__value, "r-16")}
                >
                  <Typography variant="body" size="l"  className={style.requestMessage__infoCell__bold}>
                    {return_date}
                  </Typography>
                </div>
              </div>
            )}
            {pick_up_location && (
              <div className="request-message__row fss">
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__label, "r-16")}
                >
                  <Typography variant="body" size="l">
                    {pickUpLocationLabel}
                  </Typography>
                </div>
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__value, "r-16")}
                >
                  <Typography variant="body" size="l">
                    {pick_up_location}
                  </Typography>
                </div>
              </div>
            )}
            {hasAddons && (
              <div className="request-message__row fss">
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__label, "r-16")}
                >
                  <Typography variant="body" size="l">
                    {maybePlural("Add-on", add_ons.length)}
                  </Typography>
                </div>
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__value, "r-16")}
                >
                  <Typography variant="body" size="l">
                    {add_ons.join(", ")}
                  </Typography>
                </div>
              </div>
            )}
            {price_full && (
              <div className="request-message__row fss">
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__label, "r-16")}
                />
                <div
                  className={classnames(style.requestMessage__infoCell, style.requestMessage__infoCell__value, "r-16")}
                >
                  <Typography variant="body" size="l">
                    <span
                      className="link underline"
                      onClick={() => {
                        if (isRentalStatusCancelled) {
                          setShowPrices(true);
                        } else {
                          setShowPrices(true);
                        }
                      }}
                    >
                      {pricesLabel}
                    </span>
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className="request-message__info-right tar">
            {bookingCancelled && (
              <>
                <Typography
                  variant="body"
                  size="l"
                  className={style.systemMessage__mutedColorLight}
                >
                  { isOwner ? "You earn" : "Rental pricing" }
                </Typography>
                <Typography
                  className={style.requestMessage__price}
                  variant="subtitle"
                  size="l"
                  component="h2"
                  weight="bold"
                >
                  ${bookingCancelledPriceTotal}
                </Typography>
              </>
            )}
            {!isOwner && price_full?.payment_total?.user_friendly && (
              <>
                <Typography
                  variant="body"
                  size="l"
                  className={style.systemMessage__mutedColorLight}
                >
                  {totalPriceLabel}
                </Typography>
                <Typography
                  className={style.requestMessage__price}
                  variant="subtitle"
                  size="l"
                  component="h2"
                  weight="normal"
                >
                  <FormattedPrice value={price_full.payment_total} />
                </Typography>
              </>
            )}

            {isOwner && price?.user_friendly && (
              <>
                <Typography
                  variant="body"
                  size="l"
                  className={style.systemMessage__mutedColorLight}
                >
                  {totalPriceLabel}
                </Typography>
                <Typography
                  className={style.requestMessage__price}
                  variant="subtitle"
                  size="l"
                  component="h2"
                  weight="normal"
                >
                  <FormattedPrice value={price} isBold />
                </Typography>
              </>
            )}
            <div className={`${style.action_buttons} fcc`}>
              {hasButtons && action_buttons.map(i => (
                <React.Fragment key={i.id}>
                  <div className={'t-16 b-16'}>
                    <ApiButton
                      data={i}
                      size="medium"
                      onClick={() => {
                        if (i?.id === 'accept') {
                          if (isRenter && rental.status?.id === 0) {
                            openRentalPaymentModal();
                          } else {
                            onAcceptSuggestion({
                              id: rentalId
                            });
                          }
                        } else {
                          executeAction(i.id);
                        }

                        if (i?.id === "edit_suggestion") {
                          handleRentalsSegment("Edit Your Suggestion");
                        }
                      }}
                    />
                  </div>
                </React.Fragment>
              ))}
              {action_link && (
                <div className={action_buttons.length >= 2 ? 'fw' : 'fcc'}>
                  <Button
                    ghost
                    label={action_link.value}
                    onClick={() => {
                      const actionKey = action_link.key;
                      if (actionKey === 'make_suggestion' && !hasBankAccountNumber && isOwner) {
                        setShowModalGoTo(true);
                      } else {
                        executeAction(action_link.key);
                      }
                    }}
                  />
                </div>
              )}
            </div>

            {isRenter
              && rental.status?.id === 0
              && (
                <div>
                  <Typography
                    variant="body"
                    size="s"
                    className={style.charge__hint}
                  >
                    You won’t be charged yet
                  </Typography>
                </div>
              )}
          </div>
        </div>
      </MatchMedia>
      <MatchMedia mediaQuery="(max-width: 767.98px)">
        <div className="fdc mt-16 mb-16">
          {(hasInfos || bookingCancelled) && (
            <div className="fdc">
              <div
                className={classnames(
                  style.requestMessage__info_small,
                  "mt-16 mb-16 l-16"
                )}
              >

                {bookingCancelled && (
                  <>
                    <div className="fss request-message__info-small-row">
                      <div className="fdc">
                        <Typography
                          variant="body"
                          size="l"
                          className={style.systemMessage__mutedColorLight}
                        >
                          { isOwner ? "You earn" : "Rental pricing" }
                        </Typography>
                        <Typography
                          variant="subtitle"
                          size="s"
                          className={style.requestMessage__infoValue}
                        >
                          ${bookingCancelledPriceTotal}
                        </Typography>
                      </div>
                    </div>
                    <div className="fss request-message__info-small-row">
                      <div className="fdc">
                        <Typography
                          variant="subtitle"
                          size="s"
                          className={style.requestMessage__infoValue}
                        >
                          <span
                            className="link underline"
                            onClick={() => {
                              setShowCancellingModal(true);
                              setShowNotificationPopover(true);
                            }}
                          >
                            {pricesLabel}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
                {rentalTypeValue && (
                  <div className="fss request-message__info-small-row">
                    <div className="fdc">
                      <Typography
                        variant="body"
                        size="l"
                        className={classnames(
                          style.systemMessage__mutedColorLight,
                          style.requestMessage__topInfoLabel
                        )}
                      >
                        Rental type
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className={cn(style.requestMessage__infoValue, style.requestMessage__infoValue__boldValue)}
                      >
                        {rentalTypeValue}
                      </Typography>
                    </div>
                  </div>
                )}
                {(departure_date && return_date) && (
                  <div className="fss request-message__info-small-row">
                    <div className="fdc">
                      <Typography
                        variant="body"
                        size="l"
                        className={classnames(
                          style.systemMessage__mutedColorLight,
                          style.requestMessage__topInfoLabel
                        )}
                      >
                        Pick-up
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className={cn(style.requestMessage__infoValue, style.requestMessage__infoValue__boldValue)}
                      >
                        {departure_date}
                      </Typography>
                    </div>
                    <Typography
                      variant="subtitle"
                      size="s"
                      className={style.requestMessage__dash}
                    >
                      -
                    </Typography>
                    <div className="fdc">
                      <Typography
                        variant="body"
                        size="l"
                        className={classnames(
                          style.systemMessage__mutedColorLight,
                          style.requestMessage__topInfoLabel
                        )}
                      >
                        Drop off
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className={cn(style.requestMessage__infoValue, style.requestMessage__infoValue__boldValue)}
                      >
                        {return_date}
                      </Typography>
                    </div>
                  </div>
                )}
                {pick_up_location && (
                  <div className="fss request-message__info-small-row">
                    <div className="fdc">
                      <Typography
                        variant="body"
                        size="l"
                        className={style.systemMessage__mutedColorLight}
                      >
                        {pickUpLocationLabel}
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className={style.requestMessage__infoValue}
                      >
                        {pick_up_location}
                      </Typography>
                    </div>
                  </div>
                )}
                {hasAddons && (
                  <div className="fss request-message__info-small-row">
                    <div className="fdc">
                      <Typography
                        variant="body"
                        size="l"
                        className={style.systemMessage__mutedColorLight}
                      >
                        Add-ons
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className={style.requestMessage__infoValue}
                      >
                        {add_ons.join(", ")}
                      </Typography>
                    </div>
                  </div>
                )}
                {!isOwner && price_full?.payment_total?.user_friendly && (
                  <div className="fss request-message__info-small-row">
                    <div className="fdc">
                      <Typography
                        variant="body"
                        size="l"
                        className={style.systemMessage__mutedColorLight}
                      >
                        {totalPriceLabel}
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className={style.requestMessage__infoValue}
                      >
                        <FormattedPrice value={price_full.payment_total} />
                      </Typography>
                    </div>
                  </div>
                )}

                {isOwner && price?.user_friendly && (
                  <div className="fss request-message__info-small-row">
                    <div className="fdc">
                      <Typography
                        variant="body"
                        size="l"
                        className={style.systemMessage__mutedColorLight}
                      >
                        {totalPriceLabel}
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className={style.requestMessage__infoValue}
                      >
                        <FormattedPrice value={price} isBold />
                      </Typography>
                    </div>
                  </div>
                )}
                {price && (
                  <div className="fss request-message__info-small-row">
                    <Typography
                      variant="subtitle"
                      size="s"
                      className={style.requestMessage__pricesLabel}
                    >
                      <span className="link underline"
                        onClick={() => {
                          if (isRentalStatusCancelled) {
                            setShowPrices(true);
                          } else {
                            setShowPrices(true);
                          }
                        }}>
                        {pricesLabel}
                      </span>
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
          {hasButtons && (
            <div className={`${style.action_buttons} t-8 b-8 fss`}>
              {action_buttons.map(i => (
                <div key={i.id} className="mt-8 mb-8">
                  <ApiButton
                    data={i}
                    size="medium"
                    onClick={() => {
                      if (i?.id === 'accept') {
                        if (isRenter && rental.status?.id === 0) {
                          openRentalPaymentModal();
                        } else {
                          onAcceptSuggestion({
                            id: rentalId
                          });
                        }
                      } else {
                        executeAction(i.id);
                      }
                    }}
                  />
                </div>
              ))}
              {action_link && (
                <div className={action_buttons.length >= 2 ? 'mt-8 mb-8 fw' : 'mt-8 mb-8 fcc'}>
                  <Button
                    ghost
                    size="medium"
                    label={action_link.value}
                    onClick={() => {
                      executeAction(action_link.key);
                    }}
                  />
                </div>
              )}

              {isRenter
                && rental.status?.id === 0
                && (
                  <div className={style.charge__wrapper}>
                    <Typography
                      variant="body"
                      size="s"
                      className={style.charge__hint}
                    >
                      You won’t be charged yet
                    </Typography>
                  </div>
                )}
            </div>
          )}
        </div>
      </MatchMedia>
    </>
  );
};

export default RequestMessageInfo;
