import { gql } from "@apollo/client";

export const loginMutation = gql`
  mutation login (
    $params_url: String
  ) {
    login (params_url: $params_url) {
      access_token_lifetime
      access_token_hash
      refresh_token_hash
      result {
        success
        errors {
          code
          field
          message
          section
        }
      }
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;
