import React, { useState, useEffect } from "react";
import classnames from "classnames";
import srcAvatarEmpty from "./avatar-empty.svg";
import { PhotoAvatarUpload } from "./PhotoAvatarUpload";
import { PhotoEditTool } from "components/PhotoEditTool";
import classes from "./PhotoAvatar.module.css";
import Spinner from "components/Spinner/Spinner";

export const PhotoAvatar = ({
  avatarId,
  avatarUrl,
  avatarAlt,
  onUpload,
  onEditDone,
  onEditCancel,
  editToolData,
  isEdit,
  disabled
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [avatarUrl]);
  return (
    <div className={classes.photoAvatar}>
      <div className={classes.photoAvatarInner}>
        {loading && (
          <Spinner className={classes.photoAvatarSpinner} position="absolute" />
        )}
        <div
          className={classnames(
            classes.photoAvatarImgContainer,
            avatarUrl && classes.photoAvatarImgContainerExtended
          )}
          onClick={() => {
            isEdit && setShowEdit(true);
          }}
        >
          <img
            alt={avatarAlt || ""}
            src={avatarUrl || srcAvatarEmpty}
            className={classnames(
              classes.photoAvatarImg,
              avatarUrl && classes.photoAvatarImgExtended
            )}
          />
        </div>
        <div
          className={classnames(
            classes.photoAvatarUploadContainer,
            avatarUrl && classes.photoAvatarUploadExtended
          )}
        >
          <PhotoAvatarUpload
            avatarUrl={avatarUrl}
            onUpload={(files) => {
              setLoading(true);
              onUpload(files);
            }}
            disabled={disabled}
          />
        </div>
        {isEdit && (
          <div className={classes.photoEditToolContainer}>
            <PhotoEditTool
              item={showEdit ? editToolData : null}
              onDone={(values) => {
                onEditDone && onEditDone(values, avatarId);
                setShowEdit(false);
                setLoading(true);
              }}
              onCancel={(values) => {
                onEditCancel && onEditCancel(values, avatarId);
                setShowEdit(false);
              }}
              className={classes.photoAvatarEditTool}
            />
          </div>
        )}
      </div>
    </div>
  );
};

PhotoAvatar.defaultProps = {
  disabled: false
};
