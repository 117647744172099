import { gql } from "@apollo/client";

export const baseFragments = {
  result: gql`
    fragment Result on Result {
      success
      errors {
        title
        code
        section
        field
        message
      }
    }
  `,
  resultError: gql`
    fragment ResultError on ResultError {
      code
      section
      field
      message
    }
  `,
  button: gql`
    fragment Button on Button {
      id
      label
      link
      disabled
      new_tab
      type
    }
  `,
  keyValue: gql`
    fragment KeyValue on KeyValuePair {
      key
      value
    }
  `,
  rv: {
    addon: gql`
      fragment RvAddon on Addon {
        id
        name
        price {
          ...MoneyReturn
        }
        price_type {
          key
          value
        }
      }
    `
  },
  rental: {
    status: gql`
      fragment RentalStatus on RentalStatus {
        id
        name
        group
        style
      }
    `,
    price: gql`
      fragment RentalPrice on RentalPrice {
        insurance_package_id
        taxes {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
        }
        duration
        duration_discount
        add_ons {
          id
          name
          price {
            ...MoneyReturn
          }
          price_type {
            key
            value
          }
        }
        add_ons_fee {
          ...MoneyReturn
        }
        add_drivers_fee {
          ...MoneyReturn
        }
        booking_fee {
          ...MoneyReturn
        }
        cleaning_fee {
          ...MoneyReturn
        }
        coupon_discount {
          ...MoneyReturn
        }
        coupon_info {
          id
          type {
            key
            value
          }
          value
          code
          comment
        }
        delivery_fee {
          ...MoneyReturn
        }
        disposal_fee {
          ...MoneyReturn
        }
        average_daily_rate_without_duration_discount {
          ...MoneyReturn
        }
        average_daily_rate {
          ...MoneyReturn
        }
        final_payment_for_rental {
          ...MoneyReturn
        }
        good_sam_membership_discount_plan {
          ...MoneyReturn
        }
        good_sam_membership_fee {
          ...MoneyReturn
        }
        good_sam_membership_discount {
          ...MoneyReturn
        }
        insurance_fee {
          ...MoneyReturn
        }
        insurance_package_name
        owner_earns {
          ...MoneyReturn
        }
        cancellation_penalty {
          ...MoneyReturn
        }
        owner_share
        instant_rental
        payments {
          name
          amount {
            ...MoneyReturn
          }
        }
        payments_without_tii {
          name
          amount {
            ...MoneyReturn
          }
        }
        payment_for_membership {
          ...MoneyReturn
        }
        payment_total {
          ...MoneyReturn
        }
        payment_total_without_tii {
          ...MoneyReturn
        }
        prepayment_for_rental {
          ...MoneyReturn
        }
        delivery_fee {
          ...MoneyReturn
        }
        rental_fee_calculated {
          ...MoneyReturn
        }
        rental_fee {
          ...MoneyReturn
        }
        delivery_fee_calculated {
          ...MoneyReturn
        }
        rental_fee_without_duration_discount {
          ...MoneyReturn
        }
        rental_total {
          ...MoneyReturn
        }
        security_deposit {
          ...MoneyReturn
        }
        trip_interruption_insurance {
          ...MoneyReturn
        }
        mileage_overage_fee {
          ...MoneyReturn
        }
        generator_hours_overage_fee {
          ...MoneyReturn
        }
        confirm_button
      }
    `,
    price_form_detailed: gql`
      fragment RentalPriceCalculationResult on RentalPriceCalculationResult {
        desc {
          id
        }
        total {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        items {
          type
          id
          title {
            id
            value
            type
          }
          value {
            id
            value
            type
          }
          separ
        }
      }
    `
  },
  money: gql`
    fragment MoneyReturn on Money {
      cents_total
      user_friendly
      cents_as_part
      dollars_as_part
    }
  `,
  paymentInfo: gql`
    fragment PaymentInfo on PaymentInfo {
      number
      holder
      brand
      exp_month
      exp_year
      city
      country
      address
      state
      zip
    }
  `,
  bankAccount: gql`
    fragment BankAccount on BankAccount {
      account_number
      account_type {
        key
        value
      }
      address
      bank_name
      city
      company_name
      company_tax_id
      date_of_birth_day
      date_of_birth_month
      date_of_birth_year
      date_of_birth
      first_name
      last_name
      routing_number
      ssn
      state
      zip
    }
  `
};
