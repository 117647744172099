import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MatchMedia, MQ_BP, useMatchMedia } from "../../../../../components/MatchMedia";
import Button from "../../../../../components/Button";
import { QuestionIconOutline } from "../../../../../components/Icon/QuestionIconOutline";
import RentalDetailsStatusBarModal from "./RentalDetailsStatusBarModal";
import RentalDetailsStatusBarList from "./RentalDetailsStatusBarList";
import Typography from "../../../../../components/Typography";
import { RentalProgressBarType } from "processes/Rentals/types";

const RentalDetailsStatusBar = (props) => {
  const { payload, statusMessage, message, messageType } = props;
  const isMaxMedium = useMatchMedia({ maxWidth: MQ_BP.medium });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isMaxMedium) {
      setShowModal(false);
    }
  }, [isMaxMedium]);

  const handleToggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  return (
    <div className="rental-details-status-bar">
      <RentalDetailsStatusBarList payload={payload} variant="horizontal" />
      <MatchMedia mediaQuery="(max-width: 1023.98px)">
        <Button
          base
          className="rental-details-status-bar__btn"
          onClick={handleToggleModal}
        >
          <QuestionIconOutline />
        </Button>
      </MatchMedia>
      <RentalDetailsStatusBarModal show={showModal} onClose={handleToggleModal}>
        <section className="b-24 b-m-20 b-l-24">
          <Typography variant="headline" size="m" component="h2">
            Rental status
          </Typography>
          <div className="rental-details-status-bar__mdl-message">
            <Typography variant="body" size="l">
              {statusMessage}
            </Typography>
          </div>
          <div className="t-16 t-m-20 b-16 b-m-0 b-l-40">
            <RentalDetailsStatusBarList
              payload={payload}
              variant="vertical"
            />
          </div>
          {message && messageType === "simple" && (
            <Typography
              variant="subtitle"
              size="s"
              className="rental-details-status-bar__mdl-message rental-details-status-bar__mdl-text"
            >
              {message}
            </Typography>
          )}
          {message && messageType === "html" && (
            <div
              className="rental-details-status-bar__mdl-message rental-details-status-bar__mdl-message-tpl t-16"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </section>
      </RentalDetailsStatusBarModal>
    </div>
  );
};

RentalDetailsStatusBar.propTypes = {
  payload: PropTypes.arrayOf(RentalProgressBarType).isRequired
};

export default RentalDetailsStatusBar;
