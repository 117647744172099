import React from "react";
import Button from "components/Button";
import classes from "components/Widget/components/VehicleCards/VehicleCardWidget.module.css";

const ShowMoreButton = ({
  showMoreCard,
  setShowMoreCard,
  cardsCount,
  cardsLength
}) => {

  if (cardsCount >= cardsLength) {
    return null;
  }

  return (
    <>
      <div className="t-32 t-m-36">
        <Button
          secondary
          onClick={() => setShowMoreCard((p) => !p)}
          className={classes.showMoreButton}
        >
          {!showMoreCard ? "Show More" : "Show Less"}
        </Button>
      </div>
    </>
  );
};

export default ShowMoreButton;
