import React from "react";
import PropTypes from "prop-types";
import { FILTERS } from "../../filters";
import MobileFilterBlock from "./MobileFilterBlock";
import NumberRangePicker from "../../../form/NumberRangePicker/NumberRangePicker";
import classes from "./MobileFilterPrice.module.css";

const MobileFilterPrice = (props) => {
  const { min, max } = props;

  return (
    <MobileFilterBlock
      id={FILTERS.ids.price}
      getLabelFromValue={value => (
        Number.isFinite(value.start) && Number.isFinite(value.end)
          ? `$${value.start}-${value.end}`
          : null
      )}
      renderForm={({ value, onChange }) => (
        <NumberRangePicker
          className="mt-24 mb-16"
          startInputClassName={classes.startInput}
          endInputClassName={classes.endInput}
          sliderClassName="rc-slider-track__dark"
          id="price"
          startLabel="From, $"
          endLabel="To, $"
          min={min}
          max={max}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

MobileFilterPrice.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number
};

export default MobileFilterPrice;
