import React, { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { acceptLastSuggestion, cancelRental, declineLastSuggestion, declineRental, withdrawLastSuggestion } from "processes/Rentals/RentalDetails/mutations/actions";
import RentalCancelModal from "processes/Rentals/RentalDetails/components/RentalCancelModal";
import { getErrorByCode, getErrorsAndSuccess } from "utils/extractErrors";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import Button from "components/Button";
import TableInfoModal from "components/TableInfo/components/TableInfoModal";
import { PriceFormModal } from "components/PriceForm";
import { useRentalCancellingData } from "processes/Rentals/RentalDetails/components/RentalCancelModal/hooks/useRentalCancellingData";
import RentalChange from "processes/Rentals/RentalChange";
import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";
import { buildQueryString } from "utils/queryString";
import { ModalChoice } from "components/ModalChoice/ModalChoice";
import { OWNER_CANCELLATION_FEE_ERROR_CODE } from "constants/errorCodes";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { Button as ButtonContent } from "components/ContentSection/elements";
import cn from 'classnames';
import classes from "./utils.module.css";

const cancelRentalMutationEAS = getErrorsAndSuccess("cancel");
const declineRentalMutationEAS = getErrorsAndSuccess("decline");

export function isInvalidOwnerStatus(data) {
  const { role, status, is_predefined } = data;
  const { id: statusId } = status;

  const isOwner = isUserOwner(role);

  return isOwner && ((statusId === 0) || (is_predefined && (statusId === 0 || statusId === 3 || statusId === 4 || statusId === 5)));
}

export function getReviewPhotoId(stringToSplit, separator) {
  return Number(stringToSplit.split(separator).pop());
}

export function getRentalStutus(rental = {}) {
  return {
    statusId: rental?.status?.id,
    statusName: rental?.status?.name
  };
}

export const checkCompletedStatus = (rental = {}) => {
  return rental?.status?.id === 8;
};

export const checkQuotePendingStatus = (rental = {}) => {
  return rental?.status?.id === 0;
};

export const checkExpiredStatus = (rental = {}) => {
  return rental?.status?.id === 5;
};

export const checkCancelationStatus = (rental = {}) => {
  const { id = -1 } = rental?.status;

  if (id === 3 || id === 4) {
    return true;
  }

  return false;
};

export const getTagTypeFromStatus = (status) => {
  return {
    "default": "default",
    "success": "success",
    "danger": "error",
    "warning": "warning-dark"
  }[status.style] || status.style;
};

export const getPickUpLocationTitle = (rental = {}) => {
  const {
    rv: {
      location: {
        short_address,
        full_address
      }
    },
    rental_type: { key },
    pick_up_location,
    specific_delivery_location_id
  } = rental;

  if (
    Number(key) === 2
      || (specific_delivery_location_id && specific_delivery_location_id > 0)
      || (pick_up_location !== short_address && pick_up_location !== full_address)
  ) {
    return "Location";
  }

  return "Pick-up location";
};

export const RENTAL_TYPES = {
  standard_rental: "1",
  delivery_only_rental: "2"
};

export const RENTAL_ROLES = {
  renter: "1",
  owner: "2"
};

export const RENTAL_INSPECTION_TYPES = {
  start: 1,
  end: 2
};

export const isUserRenter = (role) => {
  return role?.key === RENTAL_ROLES.renter;
};

export const isUserOwner = (role) => {
  return role?.key === RENTAL_ROLES.owner;
};

export const getRentalOpponent = (rental) => {
  if (!rental) {
    return null;
  }
  return isUserOwner(rental.role) ? rental.renter : rental.owner;
};

export const getRentalReviewData = (rental) => {
  if (!rental) {
    return null;
  }
  const myReviewData = isUserRenter(rental.role) ? rental?.renter_review : rental?.owner_review;
  const opponentReviewData = isUserRenter(rental.role) ? rental?.owner_review : rental?.renter_review;
  return { myReviewData, opponentReviewData };
};

export const isMembershipPassed = (user) => {
  return user?.good_sam_membership_validation?.key === "3";
};

export const handleRentalsSegment = (
  button_name = "",
  label = "",
  eventName = "Button Clicked"
) => {

  pushSegmentAnalyticsTrack(eventName, {
    cwh_business: 'RV Rentals',
    button_name,
    label,
    fbp: "",
    fbc: ""
  });
};

const ACTIONS = {
  accept: "accept",
  decline: "decline",
  cancel: "cancel",
  rebook: "rebook",
  withdraw_suggestion: "withdraw_suggestion",
  decline_suggestion: "decline_suggestion",
  edit_suggestion: "edit_suggestion",
  make_suggestion: "make_suggestion"
};

const CANCEL_TYPES = {
  cancel: "cancel",
  decline: "decline"
};

/**
 * Check if rental data item has action (specified or any)
 * @param {Object} data
 * @param {string|"any"} actionId
 * @returns {null|boolean}
 */
export const hasAction = (data, actionId) => {
  if (!data) {
    return false;
  }
  const actions = [
    ...(data.action_buttons?.map(i => i.id) || []),
    data.action_button?.key,
    data.action_link?.key
  ];
  if (actionId === "any") {
    return actions.some(Boolean);
  } else if (actionId) {
    return actions.includes(actionId);
  } else {
    return null;
  }
};

export const useRentalActions = ({ rental = {}, onSuccess, onFailure, isIMGlobalIntegrationIsEnabled }) => {
  const [showCancellingModal, setShowCancellingModal] = useState(false);
  const [showPreDefinedCancellingModal, setShowPreDefinedCancellingModal] = useState(false);
  const [showRentalCancelModal, setShowRentalCancelModal] = useState(false);
  const rentalId = rental?.id;
  const [showPayoutNotice, setShowPayoutNotice] = useState(false);
  const [cancelType, setCancelType] = useState(null);
  const [changeShow, setChangeShow] = useState(false);
  const [currentSuggestName, setCurrentSuggestName] = useState("");
  const rentalIdVars = useMemo(() => ({ id: rentalId }), [rentalId]);
  const isRenter = isUserRenter(rental?.role);
  const [cancelData, setCancelData] = useState({
    reason: 14,
    details: ''
  });
  const [ownerCancellationFeeModalData, setOwnerCancellationFeeModalData] = useState({
    show: false,
    variables: null,
    modalData: null
  });
  const [error, setError] = useState("");
  const [showCancellationPolicyPopover, setShowCancellationPolicyPopover] = useState(false);

  const priceTableRentalCancellingData = [
    rental?.price_original_pf,
    rental?.price_refunds_pf,
    rental?.price_final_pf
  ];

  const priceTableRentalCancellingMenuData = [
    {
      id: 'Original-fee',
      title: 'Original fee'
    },
    {
      id: 'Refund',
      title: 'Refund'
    },
    {
      id: 'Final-Amount',
      title: 'Final amount'
    }
  ];

  const {
    tableData: cancellingTableData,
    modalTitle: cancellingTableTitle,
    extraTextData: cancellingExtraData
  } = useRentalCancellingData(rental, isIMGlobalIntegrationIsEnabled);

  const [
    cancelRentalAction,
    { loading: cancelRentalActionLoading }
  ] = useMutation(cancelRental);

  const [
    declineRentalAction,
    { loading: declineRentalActionLoading }
  ] = useMutation(declineRental);

  const onCancelRentalAction = useCallback(async (variables) => {
    const response = await cancelRentalAction({
      variables: {
        id: rentalIdVars?.id,
        force: false,
        ...variables
      } }
    );

    if (cancelRentalMutationEAS.isSuccess(response)) {
      setCancelType(null);
      setShowCancellingModal(false);
      setShowRentalCancelModal(false);
      setOwnerCancellationFeeModalData({
        show: false,
        variables: null,
        modalData: null
      });
      onSuccess?.();
    } else if (cancelRentalMutationEAS.isErrors(response)) {
      if (cancelRentalMutationEAS.errorCode(response) === OWNER_CANCELLATION_FEE_ERROR_CODE.CHARGE_ON_NEXT_PAYOUT) {
        setOwnerCancellationFeeModalData({
          show: true,
          variables: {
            force: true,
            id: rentalIdVars?.id,
            ...variables
          },
          modalData: {
            title: cancelRentalMutationEAS.errorTitle(response),
            description: cancelRentalMutationEAS.errorMessage(response)
          }
        });
      } else {
        setError(cancelRentalMutationEAS.errorMessage(response));
      }
    }

  }, [cancelRentalAction, onSuccess, rentalIdVars?.id]);


  const onDeclineRentalAction = useCallback(async (variables) => {
    const response = await declineRentalAction({
      variables: {
        id: rentalIdVars?.id,
        ...variables
      } }
    );

    if (declineRentalMutationEAS.isSuccess(response)) {
      setCancelType(null);
      setShowCancellingModal(false);
      setShowRentalCancelModal(false);
      onSuccess?.();
    } else if (declineRentalMutationEAS.isErrors(response)) {
      setError(declineRentalMutationEAS.errorMessage(response));
    }
  }, [declineRentalAction, onSuccess, rentalIdVars?.id]);


  const handleOwnerCancellationFeeModalAction = useCallback(() => {
    onCancelRentalAction({ ...ownerCancellationFeeModalData.variables, force: true });
  }, [onCancelRentalAction, ownerCancellationFeeModalData.variables]);

  const handleOwnerCancellationFeeModalClose = useCallback(() => {
    setOwnerCancellationFeeModalData({
      show: false,
      variables: null,
      modalData: null
    });
  }, []);

  const getActionParams = useCallback((_onSuccess, _onFailure) => ({
    variables: rentalIdVars,
    onCompleted: (data) => {
      const result = Object.values(data || {})?.[0]?.result;
      const isSuccess = result?.success === true;
      if (isSuccess) {
        onSuccess?.();
        _onSuccess?.(result);
      } else {
        onFailure?.(result?.errors || []);
        _onFailure?.(result);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [rentalIdVars, onSuccess]);

  const actionParams = useMemo(() => getActionParams(), [getActionParams]);

  const acceptParams = useMemo(() => {
    return getActionParams(null, (result) => {
      const payoutError = getErrorByCode(result, -1);
      if (payoutError) {
        setShowPayoutNotice(true);
      }
    });
  }, [getActionParams]);

  const [acceptSuggestionAction, acceptResult] = useMutation(
    acceptLastSuggestion,
    acceptParams
  );

  const [withdrawSuggestionAction, withdrawSuggestionResult] = useMutation(
    withdrawLastSuggestion,
    actionParams
  );
  const [declineSuggestionAction, declineSuggestionResult] = useMutation(
    declineLastSuggestion,
    actionParams
  );

  const isLoadingAction = [
    acceptResult.loading,
    cancelRentalActionLoading,
    declineRentalActionLoading,
    withdrawSuggestionResult.loading,
    declineSuggestionResult.loading
  ].some(Boolean);

  const rentalHasAction = useCallback((actionId) => {
    return hasAction(rental, actionId) ||
      rental?.history?.some(historyItem => hasAction(historyItem, actionId));
  }, [rental]);

  const actions = useMemo(() => ({
    [ACTIONS.accept]: () => {
    },
    [ACTIONS.decline]: () => {
      if (rentalHasAction(ACTIONS.decline)) {
        if (rental.status?.id === 0) {
          setShowPreDefinedCancellingModal(true);
        } else {
          setCancelType(CANCEL_TYPES.decline);
          setShowRentalCancelModal(true);
        }
      }
    },
    [ACTIONS.cancel]: () => {
      if (rentalHasAction(ACTIONS.cancel)) {
        setCancelType(CANCEL_TYPES.cancel);
        setShowRentalCancelModal(true);

        if (isRenter) {
          setShowCancellationPolicyPopover(true);
        }
      }
    },
    [ACTIONS.edit_suggestion]: () => {
      if (rentalHasAction(ACTIONS.edit_suggestion)) {
        setChangeShow(true);
      }
    },
    [ACTIONS.make_suggestion]: () => {
      if (rentalHasAction(ACTIONS.make_suggestion)) {
        setChangeShow(true);
      }
    },
    [ACTIONS.withdraw_suggestion]: () => {
      if (rentalHasAction(ACTIONS.withdraw_suggestion)) {
        void withdrawSuggestionAction();
      }
    },
    [ACTIONS.decline_suggestion]: () => {
      if (rentalHasAction(ACTIONS.decline_suggestion)) {
        void declineSuggestionAction();
      }
    },
    [ACTIONS.rebook]: () => window.open(`/rv/${rental?.rv?.id}`, "_self")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [rental, rentalHasAction]);

  const executeAction = useCallback((actionId) => {
    const action = actions?.[actionId];
    if (action) {
      setCurrentSuggestName(actionId);
      action();
    } else {
      console.error("actions", actions, actionId);
    }
  }, [actions]);

  const onPreDefinedCancellingModalClose = useCallback(() => {
    setShowPreDefinedCancellingModal(false);
  }, []);

  const onPreDefinedCancel = useCallback(() => {
    onDeclineRentalAction();
    setShowPreDefinedCancellingModal(false);
  }, [onDeclineRentalAction]);

  let modal = null;
  if (showPayoutNotice) {
    modal = (
      <Modal
        show
        variant="basic-alt"
        onClose={() => setShowPayoutNotice(false)}
      >
        <Typography variant="headline" size="m">One little thing</Typography>
        <Typography variant="subtitle" size="s">
          Please add the payout information in your account first
        </Typography>
        <div className="btn-group">
          <Button
            className="mt-16 mb-16 mt-m-20 mb-m-20"
            secondary
            onClick={() => setShowPayoutNotice(false)}>
            Cancel
          </Button>
          <Button
            className="mt-16 mb-16 mt-m-20 mb-m-20"
            href="/account/payout">
            To payout settings
          </Button>
        </div>
      </Modal>
    );
  }

  if (cancelType && showRentalCancelModal) {
    modal = (
      <>
        <RentalCancelModal
          show
          rental={rental}
          onClose={() => setCancelType(null)}
          onSubmit={(variables) => {
            if (!isRenter) {
              if (cancelType === CANCEL_TYPES.cancel) {
                void onCancelRentalAction(variables);
              } else if (cancelType === CANCEL_TYPES.decline) {
                void onDeclineRentalAction(variables);
              }
            } else {
              setShowCancellingModal(true);
              setCancelData(variables);
              setShowRentalCancelModal(false);
            }
          }}
        />
        {error?.length > 0 && (
          <NotificationPopover
            show
            status="error"
            text={error}
            onClose={() => {
              setError('');
            }}
            bottomIndent={{
              hasIndent: true,
              size: 'small'
            }}
          />
        )}

        <NotificationPopover
          show={showCancellationPolicyPopover}
          status="warning"
          text={(
            <>
              Before you decide to cancel, please make sure to review our {" "}
              <ButtonContent
                id="cancellation_policy"
                section="linksContent"
                label="cancellation policy"
                ghost
                newTab
                className={cn(classes.policyLink, 'underline')}
              />
              {" "} for any possible charges you may incur.
            </>
          )}
          onClose={() => {
            setShowCancellationPolicyPopover(false);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />;

        {ownerCancellationFeeModalData?.show && (
          <ModalChoice
            show
            title={ownerCancellationFeeModalData?.modalData?.title}
            description={ownerCancellationFeeModalData?.modalData?.description}
            onAction={handleOwnerCancellationFeeModalAction}
            onClose={handleOwnerCancellationFeeModalClose}
          />
        )}
      </>
    );
  }

  if (showCancellingModal) {
    modal = (
      <>
        <PriceFormModal
          show={showCancellingModal}
          title={cancellingTableTitle}
          onClose={() => setShowCancellingModal(false)}
          data={priceTableRentalCancellingData}
          menuData={priceTableRentalCancellingMenuData}
          handleAction={() => {
            if (cancelType === CANCEL_TYPES.cancel) {
              void onCancelRentalAction(cancelData);
            } else if (cancelType === CANCEL_TYPES.decline) {
              void onDeclineRentalAction(cancelData);
            }

          }}
          handleCancel={() => {
            setShowCancellingModal(false);
            setShowRentalCancelModal(true);
          }}
        />
        {ownerCancellationFeeModalData?.show && (
          <ModalChoice
            show
            title={ownerCancellationFeeModalData?.modalData?.title}
            description={ownerCancellationFeeModalData?.modalData?.description}
            onAction={handleOwnerCancellationFeeModalAction}
            onClose={handleOwnerCancellationFeeModalClose}
          />
        )}
      </>
    );
  }
  if (changeShow) {
    modal = (
      <RentalChange
        rental={rental}
        currentSuggestName={currentSuggestName}
        isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
        show={changeShow}
        onClose={() => setChangeShow(false)}
        onSubmit={() => {
          onSuccess?.();
          setChangeShow(false);
        }}
      />
    );
  }

  if (showPreDefinedCancellingModal) {
    modal = (
      <Modal
        show
        variant="basic-alt"
        onClose={onPreDefinedCancellingModalClose}
      >
        <Typography variant="headline" size="m">Decline request</Typography>
        <Typography variant="subtitle" size="s">
          Are you sure you want to decline {rental.owner?.short_name}'s offer?
        </Typography>
        <div className="btn-group">
          <Button
            className="mt-16 mb-16 mt-m-20 mb-m-20"
            secondary
            onClick={onPreDefinedCancellingModalClose}>
            Back
          </Button>
          <Button
            className="mt-16 mb-16 mt-m-20 mb-m-20"
            onClick={onPreDefinedCancel}>
            Decline
          </Button>
        </div>
      </Modal>
    );
  }

  return { executeAction, isLoadingAction, actionsModal: modal };
};

export const getRentalDetailsPath = (id, action) => {
  return `/rentals/details?${buildQueryString({ id, action })}`;
};
