import React from "react";
import moment from "moment";

function getCentsTotal(price) {
  if (!price) {
    return null;
  }

  if (typeof price === "object") {
    return price.cents_total;
  }

  return Math.round(price * 100);
}

const DayPrice = (props) => {
  const { classes, isNotAvailable, isDiffPrice, dayPrice } = props;

  if (isNotAvailable) {
    return (
      <div className={classes.busy}>N/A</div>
    );
  }

  return (
    <div className={isDiffPrice ? classes.dayPriceDiff : classes.dayPrice}>
      ${dayPrice}
    </div>
  );
};

export function renderDay(
  day,
  daysData,
  dailyRateCentsTotal,
  classes
) {
  if (!day && !daysData && !dailyRateCentsTotal) {
    return null;
  }

  const indexOfTheDay = moment(day).format("YYYY-MM-DD");
  const dayCentsTotal = getCentsTotal(daysData[indexOfTheDay]?.price);
  const isDiffPrice = !!dayCentsTotal && dayCentsTotal !== dailyRateCentsTotal;
  const dayPrice = Math.round((isDiffPrice ? dayCentsTotal : dailyRateCentsTotal) / 100);
  const isNotAvailable = daysData[indexOfTheDay]?.available === false;

  return (
    <DayPrice
      classes={classes}
      isNotAvailable={isNotAvailable}
      isDiffPrice={isDiffPrice}
      dayPrice={dayPrice}
    />
  );
}
