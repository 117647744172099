import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import classnames from "classnames";
import { CardTabs } from "processes/MyAccount/steps/TransactionHistory";
import { Header } from "processes/MyAccount/steps/TransactionHistory";
import { Filters } from "processes/MyAccount/steps/TransactionHistory";
import { filterData, calculateTotal } from "processes/MyAccount/steps/TransactionHistory/utils";
import { TAB_IDS } from "processes/MyAccount/steps/TransactionHistory/constants";
import { SpinnerBack } from "components/Spinner/Spinner";
import NotificationPopover from "components/NotificationPopover";

import classes from "./TransactionHistoryContent.module.css";

const currentYear = moment().year();
const currentMounth = moment().month() + 1;

const defaultValues = {
  select1: {
    "month": "01",
    "year": String(currentYear)
  },
  select2: {
    "month": String(currentMounth),
    "year": String(currentYear)
  },
  rvSelect: "0"
};

export const TransactionHistoryContent = (props) => {
  const {
    content = {},
    value: {
      payouts,
      onGetTransactionHistory
    }
  } = props;

  useEffect(() => {
    onGetTransactionHistory({
      start_year: moment().year(),
      start_month: 1,
      end_year: moment().year(),
      end_month: moment().month() + 1
    });
  }, [onGetTransactionHistory]);

  const { data: payoutsData, loading, error, errorMessage } = payouts;

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  const {
    proTipsSectionTitle = "Pro Tips",
    proTipsSectionText = "",
    sectionTitle = "Transaction History"
  } = content;

  const {
    control,
    watch: filterWatch,
    getValues,
    setValue,
    reset: resetFilters
  } = useForm({
    defaultValues,
    shouldUnregister: false
  });

  const allData = useMemo(() => ({
    completed: payoutsData?.completed ?? [],
    upcoming: payoutsData?.upcoming ?? []
  }), [payoutsData]);

  const allCards = [...allData.completed, ...allData.upcoming];

  const [tab, setTab] = useState(TAB_IDS.COMPLETED);
  const [sectionData, setSectionData] = useState([]);
  const [currentRvSelectData, setCurrentRvSelectData] = useState(null);
  const total = useMemo(() => calculateTotal(sectionData, 'billing_amount'), [sectionData]);

  const taxes = useMemo(() => calculateTotal(sectionData, 'taxes'), [sectionData]);

  const expected = useMemo(() => calculateTotal(sectionData, 'expected_payout'), [sectionData]);

  const downloadUrl = useMemo(() => {
    const { select1, select2, rvSelect } = filterWatch(['select1', 'select2', 'rvSelect']);

    return tab === TAB_IDS.COMPLETED
      ? `/export/excel?report=tax-dashboard-completed&start_month=${Number(select1.month)}&start_year=${select1.year}&end_month=${Number(select2.month)}&end_year=${select2.year}&rv_id=${rvSelect}`
      : `/export/excel?report=tax-dashboard-upcoming&start_month=${Number(select1.month)}&start_year=${select1.year}&end_month=${Number(select2.month)}&end_year=${select2.year}&rv_id=${rvSelect}`;
  }, [filterWatch, tab]);


  const yearsOptions = useMemo(() => {
    if (payoutsData?.settings) {
      return Array.from({ length: payoutsData?.settings.end_year - payoutsData?.settings.start_year + 1 }, (_, i) => ({
        text: String(payoutsData?.settings.start_year + i),
        value: String(payoutsData?.settings.start_year + i)
      }));
    } else {
      return [{ text: String(currentYear), value: String(currentYear) }];
    }
  }, [payoutsData?.settings]);

  const filterCards = useCallback(() => {
    const filters = getValues(["rvSelect"]);

    setSectionData(filterData(filters.rvSelect, allData[tab]));

  }, [allData, getValues, tab]);

  const onDateSelectsChange = useCallback(() => {
    const { select1, select2 } = getValues(['select1', 'select2']);

    onGetTransactionHistory({
      start_year: Number(select1.year),
      start_month: Number(select1.month),
      end_year: Number(select2.year),
      end_month: Number(select2.month)
    });
  }, [getValues, onGetTransactionHistory]);

  const onRvSelectChange = useCallback(() => {
    filterCards();
  }, [filterCards]);

  const onResetFilters = useCallback(() => {
    const { select1, select2 } = defaultValues;
    resetFilters(defaultValues);
    setCurrentRvSelectData(null);
    onGetTransactionHistory({
      start_year: Number(select1.year),
      start_month: Number(select1.month),
      end_year: Number(select2.year),
      end_month: Number(select2.month)
    });
  }, [resetFilters, onGetTransactionHistory]);

  useEffect(() => {
    filterCards();
  }, [filterCards, setValue, tab]);

  return (
    <div className="mb-16 mb-m-40 mb-l-36">

      <div className="b-24 b-m-0">
        <Header
          proTipsSectionTitle={proTipsSectionTitle}
          proTipsSectionText={proTipsSectionText}
          sectionTitle={sectionTitle}
        />
      </div>

      <div
        className={classnames(
          classes.pageDevider,
          "devider-solid mt-m-20 mb-m-0"
        )}
      />

      <div className="mt-8 mb-8">
        <Filters
          allCards={allCards}
          currentRvSelectData={currentRvSelectData}
          setCurrentRvSelectData={setCurrentRvSelectData}
          control={control}
          yearsOptions={yearsOptions}
          onDateSelectsChange={onDateSelectsChange}
          onRvSelectChange={onRvSelectChange}
          filterWatch={filterWatch}
        />
      </div>

      <div
        className={classnames(
          classes.pageDevider,
          "devider-solid mt-m-20 mb-m-20 dn-m"
        )}
      />

      <div className="mt-16 mt-m-32">
        <CardTabs
          cards={sectionData}
          total={total}
          expected={expected}
          taxes={taxes}
          downloadUrl={downloadUrl}
          tab={tab}
          onResetFilters={onResetFilters}
          onTabChange={setTab}
        />
      </div>

      {
        showError
        && <NotificationPopover
          status="error"
          show={showError}
          title="Error"
          text={errorMessage}
          onClose={setShowError}
        />
      }

      {loading && <SpinnerBack />}
    </div>
  );
};
