import React, { forwardRef } from "react";
import { ErrorMessage } from "@hookform/error-message";
import get from "lodash/get";

function hasError(errors, name) {
  return Boolean(errors && get(errors, name));
}

export function withErrorMessage(FieldComponent) {
  const FieldComponentWithError = forwardRef((props, ref) => {
    const { name, errors, ...fieldProps } = props;

    return (
      <FieldComponent
        {...fieldProps}
        ref={ref}
        name={name}
        errors={errors}
        hasError={hasError(errors, name)}
        errorMessage={<ErrorMessage errors={errors} name={name}/>}
      />
    );
  });

  FieldComponentWithError.displayName = 'FieldComponentWithError';

  return FieldComponentWithError;
}
