import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import FieldInput from "../FieldInput/FieldInput";
import { useJqueryComponent } from "../../../hooks";
import { calendarIcon } from "../../Icon";

const Datepicker = ({ className, ...inputProps }) => {
  const options = useMemo(() => ({
    onSelect: inputProps.onChange
  }), [inputProps.onChange]);

  const [ref] = useJqueryComponent("Datepicker", options);

  return (
    <FieldInput
      ref={ref}
      icon={calendarIcon}
      className={classnames(className, "field-input--date")}
      {...inputProps}
    />
  );
};

Datepicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  helperMessage: PropTypes.string
};

export default Datepicker;
