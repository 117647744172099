import React from "react";
import classnames from "classnames";
import classes from "./FieldOptionalInputs.module.css";
import Radio from "../../../../form/Radio";
import { FieldInput } from "../../../../form/FieldInput";
import { FieldController } from "../../../../form/FieldController/FieldController";
import { Text } from "components/ContentSection/elements";

const FieldOptionalInputs = (props) => {
  const {
    id,
    nameFirst,
    nameSecond,
    labelFirst,
    labelSecond,
    unitsFirst,
    unitsSecond,
    availableMessage,
    unavailableMessage,
    isAvailable,
    setAvailable,
    className,
    control,
    errors,
    validation,
    disabled
  } = props;

  const fieldClassName = classnames(className, classes.root);

  return (
    <div className={fieldClassName}>
      <div className={classes.radioRow}>
        <Radio
          id={`${id}-hide`}
          className={classes.radioHide}
          name={`${id}-switch`}
          text={unavailableMessage}
          checked={!isAvailable}
          onChange={(e) => {
            if (e.target.checked) {
              setAvailable(false);
            }
          }}
          disabled={disabled}
        />
        <Radio
          id={`${id}-show`}
          className={classes.radioShow}
          name={`${id}-switch`}
          text={availableMessage}
          checked={isAvailable}
          onChange={(e) => {
            if (e.target.checked) {
              setAvailable(true);
            }
          }}
          disabled={disabled}
        />
      </div>

      {isAvailable && (
        <div className={classes.fieldsWrap}>
          <div className={classes.inputWrap}>
            <FieldController
              name={nameFirst}
              control={control}
              rules={validation[nameFirst] ? validation[nameFirst] : null}
              disabled={disabled}
              render={(renderProps) => (
                <FieldInput
                  id={`${id}-included`}
                  label={labelFirst}
                  helperMessage={
                    <Text
                      id="miles_included_description"
                      section="otherFeesAddonsSectionContent"
                    />
                  }
                  className={classes.input}
                  errors={errors}
                  {...renderProps}
                >
                  <div className={classes.inputUnits}>{unitsFirst}</div>
                </FieldInput>
              )}
            />
          </div>
          <div className={classes.inputWrap}>
            <FieldController
              name={nameSecond}
              control={control}
              rules={validation[nameSecond] ? validation[nameSecond] : null}
              disabled={disabled}
              render={(renderProps) => (
                <FieldInput
                  id={`${id}-overage`}
                  label={labelSecond}
                  className={classes.input}
                  errors={errors}
                  {...renderProps}
                >
                  <div className={classes.inputUnits}>{unitsSecond}</div>
                </FieldInput>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

FieldOptionalInputs.defaultProps = {
  unavailableMessage: "Allow unlimited miles",
  availableMessage: "Set my own rates"
};

export default FieldOptionalInputs;
