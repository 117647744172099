import React, { useState, useEffect, useCallback } from "react";
import { ModalPayoutForm } from "components/ModalPayoutForm/ModalPayoutForm";
import { VERIFICATION_ERROR_CODES, DEFAULT_DATA } from "./constants/verificationErrorCodes";
import { useFetchUserData } from "./hooks/useFetchUserData";
import { EmailVerifyModal } from "components/Verification/EmailVerifyModal";
import { PhoneVerifyModal } from "components/Verification/PhoneVerifyModal";

const defaultSteps = {
  [VERIFICATION_ERROR_CODES.email]: false,
  [VERIFICATION_ERROR_CODES.phone]: false,
  [VERIFICATION_ERROR_CODES.phoneSendCode]: false,
  [VERIFICATION_ERROR_CODES.payout]: false
};

const VerificationCodeStepsModals = (props) => {
  const {
    data,
    onResetData,
    onRefetchMutation,
    updateUserData = () => {}
  } = props;

  const {
    phone,
    userData,
    accountTypeValue,
    refetch: refetchUserData
  } = useFetchUserData();

  const { code, variables } = data;
  const [showStep, setShowStep] = useState(defaultSteps);

  const onReset = useCallback(() => {
    onResetData(DEFAULT_DATA);
    setShowStep(defaultSteps);
  }, [onResetData]);

  const onClosePhoneVerify = useCallback(() => {
    if (code === VERIFICATION_ERROR_CODES.phone && !phone) {
      refetchUserData();
    }
    onReset();
  }, [code, onReset, phone, refetchUserData]);

  const onPhoneVerifyDone = useCallback(() => {
    onReset();
    onRefetchMutation(variables);
  }, [onRefetchMutation, onReset, variables]);

  const onPayoutDone = useCallback(() => {
    onReset();
    onRefetchMutation(variables);
  }, [onRefetchMutation, onReset, variables]);

  useEffect(() => {
    if (code) {
      setShowStep({
        ...defaultSteps,
        [code]: true
      });
    }
  }, [code]);

  return (
    <div>
      {showStep[VERIFICATION_ERROR_CODES.email] && (
        <EmailVerifyModal
          show={showStep[VERIFICATION_ERROR_CODES.email]}
          onClose={onReset}
        />
      )}

      {(showStep[VERIFICATION_ERROR_CODES.phone] || showStep[VERIFICATION_ERROR_CODES.phoneSendCode]) && (
        <PhoneVerifyModal
          show={showStep[VERIFICATION_ERROR_CODES.phone] || showStep[VERIFICATION_ERROR_CODES.phoneSendCode]}
          onClose={onClosePhoneVerify}
          onVerified={onPhoneVerifyDone}
          userPhone={phone}
        />
      )}

      {showStep[VERIFICATION_ERROR_CODES.payout] && (
        <ModalPayoutForm
          show={showStep[VERIFICATION_ERROR_CODES.payout]}
          onClose={onReset}
          onSuccessSubmit={onPayoutDone}
          userData={userData}
          updateUserData={updateUserData}
          accountType={accountTypeValue}
          submitButtonData={{
            submitButtonType: "button",
            submitImmediately: true
          }}
        />
      )}
    </div>
  );
};

export default VerificationCodeStepsModals;
