import React from "react";
import PropTypes from "prop-types";
import { explainerIcon } from "../../../Icon";
import style from "./style.module.css";
import Button from "../../../Button";

const HeaderDetailsToggle = ({ onToggle }) => (
  <Button
    ghost
    className={style.header__icon_explainer_mobile}
    onClick={onToggle}
  >
    {explainerIcon}
  </Button>
);

HeaderDetailsToggle.propTypes = {
  onToggle: PropTypes.func.isRequired
};

export default HeaderDetailsToggle;
