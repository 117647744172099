import { useMemo } from "react";
import { userQuery } from "utils/segment-analytics/queries/userQuery";
import { useQuery } from "@apollo/client";

export function useSegmentAnalyticsIdentify() {
  const { loading, data } = useQuery(userQuery, {});

  const [sso, identifyData] = useMemo(() => {
    if (!loading && data?.user) {
      const { user } = data;
      const isGsMember = user?.good_sam_membership_validation?.value === "Passed";

      const idfData = {
        name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email,
        phone_number: user?.phone,
        address: {
          street: user?.payment_info?.address,
          city: user?.city,
          state: user?.state,
          postal_code: user?.zip,
          country: user?.country
        },
        gs_member: isGsMember ? 'yes' : 'no',
        gs_member_number: user?.good_sam_member_id
      };

      return [user?.sso__user_id, idfData];
    }
    return ['', {}];

  }, [data, loading]);

  return [sso, identifyData];
}
