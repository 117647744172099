import { useEffect } from "react";
import { useListingContext } from "components/Listing/ListingContext";

function useFooterVisible(isDirty) {
  const [{ isListingPublished }, updateContext] = useListingContext();



  useEffect(() => {
    const isFooterVisible = !isListingPublished || Boolean(isDirty);

    updateContext({ isFooterVisible });
  }, [isDirty, isListingPublished, updateContext]);
}

export default useFooterVisible;
