import React from "react";
import PropTypes from "prop-types";
import InfoBlock from "components/info/InfoBlock";
import { NBSP_CODE } from "utils/basic";
import { isUserOwner } from "../../utils";
import { RentalSuggestionType } from "processes/Rentals/RentalChange/types";
import InfoTexts from "components/info/InfoTexts";
import { dateFormat } from "utils/dayPickerDataFormatting";

const DetailsBlock = (props) => {
  const { label, data, expandable, rentalType, rental } = props;

  const newLabel = rentalType === 1 ? "Pick-up location" : "Location";

  const isOwner = isUserOwner(rental?.role);

  return (
    <InfoBlock label={label} paddingVariant="small" expandable={expandable}>
      <div className="df">
        <InfoTexts label="Pick-up" value={dateFormat(data.departure_date)} />
        <InfoTexts className="l-8 r-8" label={NBSP_CODE} value="—" />
        <InfoTexts label="Drop off" value={dateFormat(data.return_date)} />
      </div>
      <InfoTexts label={newLabel} value={data.pick_up_location} />
      <InfoTexts label="Add-ons" value={data.add_ons.join(", ")} />
      {isOwner
        ? (<InfoTexts
            label="You earn"
            value={"$" + data.price.owner_earns?.user_friendly || '-'}
          />)
        : (<InfoTexts
            label="Rental pricing"
            value={"$" + data.price.payment_total?.user_friendly || '-'}
          />)
      }

    </InfoBlock>
  );
};

DetailsBlock.propTypes = {
  label: PropTypes.string.isRequired,
  data: RentalSuggestionType.isRequired,
  expandable: PropTypes.bool
};

export default DetailsBlock;
