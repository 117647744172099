import React, { useCallback, useState, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import { useQuery } from "@apollo/client";
import { cancelAndDeclineReasonsQuery } from "processes/Rentals/RentalDetails/components/RentalCancelModal/query";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import { SpinnerBack } from "components/Spinner/Spinner";
import RadioList from "components/form/Radio/RadioList";
import { FieldController } from "components/form/FieldController/FieldController";
import { VALIDATION } from "utils/validation";
import Form from "components/form/Form/Form";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { getRentalOpponent, isUserOwner } from "processes/Rentals/utils";
import { FieldTextarea } from "components/form/FieldTextarea";
import { Button as ButtonContent } from "components/ContentSection/elements";
import cn from 'classnames';
import classes from "./RentalCancelModal.module.css";

function checkOtherReason(data) {
  const OTHER_REASON = '14';
  return data?.reason !== OTHER_REASON || (data?.reason === OTHER_REASON && data?.details?.length);
}

const RentalCancelModal = (props) => {
  const {
    rental,
    show,
    onClose,
    onSubmit
  } = props;
  const isOwner = isUserOwner(rental.role);
  const [detailsHelperText, setDetailsHelperText] = useState('');
  const mdlRef = useScrollBarWidth();
  const {
    control,
    handleSubmit,
    watch,
    errors,
    setError: setFormError,
    clearErrors
  } = useForm({
    defaultValues: {
      reason: null,
      details: ""
    }
  });
  const { reason, details } = watch();

  const { loading, data } = useQuery(cancelAndDeclineReasonsQuery, {
    variables: { id: rental?.id },
    fetchPolicy: "no-cache",
    skip: !rental
  });

  const onFormSubmit = useCallback((values) => {
    const reason = Number(values.reason);
    const details = values.details;
    onSubmit({ reason, details });
  }, [onSubmit]);

  const handleFormSubmit = useCallback(() => {
    if (checkOtherReason({ reason, details })) {
      onFormSubmit({ reason, details });
    } else {
      setFormError("details", {
        type: "validate",
        message: "Details is required"
      });
    }
  }, [details, onFormSubmit, reason, setFormError]);

  useEffect(() => {
    if (checkOtherReason({ reason, details })) clearErrors('details');
    if (Number(reason) === 14) setDetailsHelperText('');
    else setDetailsHelperText('');
  }, [reason, details, clearErrors]);

  if (!rental) {
    return null;
  }

  if (loading) {
    return (
      <SpinnerBack />
    );
  }

  const items = data?.cancellation_and_declining_reasons || [];
  const opponent = getRentalOpponent(rental);

  return (
    <Modal
      modalClassnames={classes.modal}
      show={show}
      onClose={onClose}
      variant="basic"
      isMobileFullScreen
      modalCrossButtonClassname={classes.crossButton}
      mdlRef={mdlRef}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={classes.content}>
          <Typography variant="headline" size="m" className={classes.title}>
            Cancel rental
          </Typography>
          <Typography variant="subtitle" size="s">
            Please make sure to review our {" "}
            <ButtonContent
              id="cancellation_policy"
              section="linksContent"
              label="cancellation policy"
              ghost
              newTab
              className={cn(classes.policyLink, 'underline')}
            />
            . Cancellations initiated by the owner will result in a full refund to the renter. Tell <strong>{opponent.short_name}</strong> why you are cancelling this rental:
          </Typography>
          <div>
            <FieldController
              name="reason"
              control={control}
              rules={VALIDATION.rules.required}
              render={(renderProps) => (
                <RadioList
                  isVertical
                  list={items.map(i => ({
                    value: i.key,
                    text: i.value
                  }))}
                  onClick={(e) => {
                    clearErrors('details');
                  }}
                  {...renderProps}
                />
              )}
            />
            <div className="t-20 t-m-20 b-20 b-m-20 t-l-16">
              <FieldController
                name="details"
                control={control}
                render={(renderProps) => (
                  <FieldTextarea
                    resize="none"
                    rows={2}
                    className="fw"
                    id="details"
                    name="details"
                    label="Additional details"
                    helperMessage={detailsHelperText}
                    errors={errors}
                    {...renderProps}
                  />
                )}
              />
            </div>
          </div>
          <div
            className={classnames(
              classes.bar,
              "btn-group t-16 t-m-20 b-16 b-m-20 t-l-24"
            )}>
            <Button secondary onClick={() => onClose(false)}>
              Cancel
            </Button>
            {isOwner && (
              <Button onClick={handleFormSubmit}>
                Cancel rental
              </Button>
            )}
            {!isOwner && (
              <Button onClick={handleFormSubmit}>
                Continue
              </Button>
            ) }
          </div>
        </div>
      </Form>
    </Modal>
  );
};

RentalCancelModal.propTypes = {
  show: PropTypes.bool.isRequired,
  rental: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RentalCancelModal;
