import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Checkbox from "components/form/Checkbox";
import { AddOnListItemType } from "components/AddOns/types";
import { getPriceTypeText } from "utils/rv";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import Typography from "components/Typography";
import classes from "./AddOnsItem.module.css";

const AddOnsItem = (props) => {
  const { item, checked, onChange } = props;
  const {
    id,
    name,
    price,
    price_type
  } = item;

  return (
    <li className={classes.item}>
      <div
        className={classnames(
          classes.itemRow,
          "df fbs",
          "t-16 b-16 t-m-20 b-m-20",
          "l-16 r-16 l-m-24 r-m-24"
        )}>
        <div>
          <Checkbox
            id={`add-ons-checkbox-${id}`}
            name="add-ons-checkbox"
            text={name}
            defaultChecked={checked}
            onChange={(value) => onChange(item)}
            mobileVariant="stretch-reverse"
          />
        </div>
        <Typography
          className={classnames(classes.colPrice, "ml-m-16 mt-2 mt-m-0")}
          variant="body"
          size="l">
          <FormattedPrice value={price} isBold />
          {getPriceTypeText(price_type)}
        </Typography>
      </div>
      <div className={classnames(classes.itemDevider, "devider-dashed")} />
    </li>
  );
};

AddOnsItem.propTypes = {
  item: AddOnListItemType,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AddOnsItem;
