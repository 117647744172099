import { gql } from "@apollo/client";

export const rvVisibilityAndAvailability = gql`
  mutation ($id: Int!, $visible: Boolean!, $availability_calendar: [CustomDateInput]!) {
    rv_availability(id: $id, dates: $availability_calendar) {
      result {
        success
        errors {
          code
          field
          section
          message
        }
      }
    }
    rv_visibility(id: $id, visible: $visible) {
      result {
        success
        errors {
          field
          message
        }
      }
    }
  }
`;
