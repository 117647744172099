import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import PaymentView from "components/payment/PaymentView";
import { userQuery } from "processes/MyAccount/queries";
import Spinner from "components/Spinner/Spinner";
import StepContainer from "processes/Booking/components/BookingModal/components/StepContainer/StepContainer";
import Button from "components/Button";

const PaymentMethod = ({ hasScrollShadow, isPaymentMethodStep, handleNextStep, setIsRefetchCalculatePrice }) => {
  const { loading: userQueryLoading, data: userQueryData } = useQuery(
    userQuery,
    {
      fetchPolicy: "no-cache"
    }
  );
  const [userNewData, setUserNewData] = useState(null);
  const userData = userQueryData?.user;

  const updateUserData = useCallback(
    (newPartialData) => {
      setUserNewData({ ...userData, ...newPartialData });
    },
    [userData]
  );

  const getPreviewButtons = useCallback(
    ({ isCardExpired }) => (
      <Button onClick={handleNextStep} label="Next" disabled={isCardExpired} />
    ),
    [handleNextStep]
  );

  const onSave = useCallback(
    () => {
      setIsRefetchCalculatePrice(true);
      handleNextStep();
    },
    [handleNextStep, setIsRefetchCalculatePrice]
  );

  if (userQueryLoading) {
    return <Spinner position="absolute" />;
  }

  return (
    <StepContainer isPaymentMethodStep={isPaymentMethodStep}>
      {({ buttonsClassName, buttonsContainerInner }) => (
        <PaymentView
          userData={userNewData || userData}
          updateUserData={updateUserData}
          onSave={onSave}
          stepContainerClassNames={{
            buttonsClassName,
            buttonsContainerInner
          }}
          variant="compact"
          getPreviewButtons={getPreviewButtons}
          hasScrollShadow={hasScrollShadow}
          isBookingProcess={true}
        />
      )}
    </StepContainer>
  );
};

export default PaymentMethod;
