import React, { useState, useEffect } from "react";
import Typography from "../../components/Typography";
import CodeVerificationControl from "../../components/form/CodeVerificationControl";
import Message from "../../components/Message";
import { Button } from "components/ContentSection/elements";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import classes from "./signUp.module.css";

export const CodeVerificationForm = (props) => {
  const { loading, onComplete, onResend } = props;

  return (
    <div>
      <Typography>
        A text message with your verification code has been sent. Please enter the code to verify your phone.
      </Typography>
      <div className="t-16 t-s-20 b-16 b-s-20">
        <CodeVerificationControl onComplete={onComplete} disabled={loading} />
        <div className="t-16 t-s-20">
          <Button
            id="send_phone_verification_code"
            section="signUpPhone"
            onClick={onResend}
            ghost
            className="underline"
          />
        </div>
      </div>
    </div>
  );
};
