import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const rvAmenitiesMutation = gql`
  ${fragments.amenities}

  mutation rvUpdate($id: Int!, $amenities: [Int]!) {
    rv_update(id: $id, step: "amenities", amenities: $amenities) {
      result {
        success
        errors {
          field
          message
        }
      }
      rv {
        id
        listing_steps_completed
        fields_awaiting_moderation
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        class
        make
        model
        year
        length {
          feet_as_part
          inches_total
        }
        price {
          user_friendly
        }
        sleeps
        slide_outs
        weight
        stated_value {
          cents_total
        }
        daily_rate {
          dollars_as_part
          cents_total
        }
        status {
          key
          value
        }
        ...Amenities
      }
    }
  }
`;
