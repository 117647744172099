import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./style.css";

const RangeSlider = (props) => {
  const { className, inverseColors = false, ...other } = props;

  return (
    <div
      className={classnames([
        className,
        "slider",
        "slider--is-range",
        { "slider--inverse-colors": inverseColors }
      ])}
    >
      <Slider range allowCross={false} {...other} />
    </div>
  );
};

RangeSlider.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.number),
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  inverseColors: PropTypes.bool
};

export default RangeSlider;
