import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../Typography";
import Checkbox from "../../../form/Checkbox";
import HeaderFilterButton from "./HeaderFilterButton";
import { FILTERS } from "../../filters";
import { getObjectPositiveValuesCount } from "../../../../utils/basic";
import classes from "./FilterAmenities.module.css";
import omit from "lodash/omit";

const FilterAmenities = (props) => {
  const { amenities } = props;

  return (
    <HeaderFilterButton
      popperClassName={classes.popper}
      id={FILTERS.ids.amenities}
      getSelectedCount={getObjectPositiveValuesCount}
      popperAtEnd
      renderForm={({ value, onChange }) => (
        <div>
          {amenities?.map(group => (
            <div key={group.category} className="b-20">
              <Typography
                size="s"
                weight="bold"
                variant="subtitle"
              >
                {group.category}
              </Typography>
              <div className="row">
                {group.items?.map(i => (
                  <div key={i.id} className="col-3 t-8 b-8">
                    <Checkbox
                      id={i.id}
                      name={i.id}
                      text={i.name}
                      checked={value[i.id] || false}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        onChange(omit(
                          { ...value, [i.id]: checked },
                          !checked ? i.id : null
                        ));
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
};

FilterAmenities.propTypes = {
  amenities: PropTypes.array.isRequired
};

export default FilterAmenities;
