import React, { useCallback } from "react";
import { useBookingModalContext } from "processes/Booking/components/BookingModal/BookingModalContext";
import { ApplyCoupon } from "components/ApplyCoupon/ApplyCoupon";

const Coupon = ({ showCouponModal, setShowCouponModal }) => {
  const { setCoupon } = useBookingModalContext();

  const toggleOpen = useCallback(() => {
    setShowCouponModal((p) => !p);
  }, [setShowCouponModal]);


  const onSave = useCallback(
    (couponData) => {
      setCoupon(couponData);
      toggleOpen();
    },
    [setCoupon, toggleOpen]
  );

  const modalData = {
    title: 'Apply coupon',
    cancelButton: {
      label: 'Cancel'
    },
    confirmButton: {
      label: 'Apply'
    }
  };
  return (
    <>
      <ApplyCoupon
        show={showCouponModal}
        toggleShow={toggleOpen}
        content={modalData}
        onSave={onSave}
      />
    </>
  );
};

export default Coupon;
