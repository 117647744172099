import React, { useState, useCallback, useEffect } from "react";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import { AdditionalDrivers } from "components/ModalAdditionalDrivers";
import { PaymentMethod } from "components/ModalAdditionalDrivers";
import { CheckoutContainer } from "components/ModalAdditionalDrivers";
import { SpinnerBack } from "components/Spinner/Spinner";
import { useLazyQuery } from "@apollo/client";
import { calculateAdditionalDriversQuery } from "./queries/calculateAdditionalDriversQuery";
import StepsMenu from "components/StepsMenu/StepsMenu";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { steps as stepsList } from "./steps";
import {
  setSelectedToStep,
  setCompletedToStep,
  setSelectedAndWasSelectedToStep,
  isLastStep
} from "components/StepsMenu/utils";
import { getErrorsAndSuccess } from "utils/extractErrors";
import cn from 'classnames';
import classes from "./ModalAdditionalDrivers.module.css";

const calculateAdditionalDriversEAS = getErrorsAndSuccess("calculate_additional_drivers");

const COMPONENT_STEPS = {
  1: AdditionalDrivers,
  2: PaymentMethod,
  3: CheckoutContainer
};

export const ModalAdditionalDrivers = (props) => {
  const {
    show,
    limitAddDrivers,
    rentalId,
    drivers = [],
    onDriversSuccessAdded,
    onDriversErrorAdded,
    onClose
  } = props;

  const [
    fetchCalculateAdditionalDrivers,
    {
      loading: calculateAdditionalDriversLoading
    }] = useLazyQuery(
    calculateAdditionalDriversQuery,
    {
      fetchPolicy: "no-cache",
      onCompleted: (response) => {
        const data = { data: response };
        if (calculateAdditionalDriversEAS.isSuccess(data)) {
          setPriceTable(response?.calculate_additional_drivers?.price_form);
          onNextStep();
        } else if(calculateAdditionalDriversEAS.isErrors(data)) {
          setTempDrivers([]);
          setError(calculateAdditionalDriversEAS.message(data));
        }
      }
    }
  );

  const mdlRef = useScrollBarWidth();
  const [error, setError] = useState("");
  const [steps, changeSteps] = useState(stepsList);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [priceTable, setPriceTable] = useState(null);
  const [tempDrivers, setTempDrivers] = useState(drivers);

  const onClickStep = useCallback(({ steps, selectedStepIndex }) => {
    changeSteps(setSelectedToStep(steps, selectedStepIndex));
    setCurrentStepIndex(selectedStepIndex);
  }, []);

  const onNextStep = useCallback(() => {
    changeSteps(setCompletedToStep(setSelectedAndWasSelectedToStep(steps, currentStepIndex + 1), currentStepIndex));
    if (!isLastStep(steps, currentStepIndex)) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }, [currentStepIndex, steps]);

  const onPrevStep = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const onAddDriversSubmit = useCallback(async(drivers) => {
    setTempDrivers(drivers);
    fetchCalculateAdditionalDrivers({
      variables: {
        rental: rentalId,
        additional_drivers: drivers
      }
    });
  }, [rentalId, fetchCalculateAdditionalDrivers]);

  const CurrentStep = COMPONENT_STEPS[currentStepIndex + 1];

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={`${classes.modalWrap}`}
      modalClassnames={`${classes.modal}`}
      modalCrossButtonClassname={classes.crossButton}
      mdlRef={mdlRef}
    >
      <div className={classes.container}>
        <header>
          <Typography
            variant="headline"
            size="m"
            weight="bold"
            className={cn(classes.title, "tac")}
          >
            Add additional drivers
          </Typography>
        </header>

        <div className={classes.menu}>
          <StepsMenu
            onClickStep={onClickStep}
            steps={steps}
            currentStepIndex={currentStepIndex}
            justifyContentCenter
          />
        </div>

        <div className={classes.content}>
          <CurrentStep
            rentalId={rentalId}
            maxDrivers={limitAddDrivers}
            onClose={onClose}
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
            onAddDriversSubmit={onAddDriversSubmit}
            onDriversSuccessAdded={onDriversSuccessAdded}
            onDriversErrorAdded={onDriversErrorAdded}
            priceTable={priceTable}
            driversList={tempDrivers}
          />
        </div>

        {error?.length > 0 && (
          <NotificationPopover
            show={true}
            status="error"
            text={error}
            onClose={() => {
              setError('');
            }}
            bottomIndent={{
              hasIndent: true,
              size: 'small'
            }}
          />
        )}

        {calculateAdditionalDriversLoading && <SpinnerBack />}
      </div>
    </Modal>
  );
};
