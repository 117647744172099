import React, { createContext, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const GoogleMapsContext = createContext({ isLoaded: false });

const GOOGLE_MAPS_API_KEY = window?.CWRR?.GoogleAPIKey || process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const libraries = ["places"];

export const GoogleMapsProvider = ({ children, ...loadScriptOptions }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: "en",
    libraries,
    ...loadScriptOptions
  });

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
