import React, { useState, useCallback } from "react";
import Checkout from "components/Checkout/Checkout";
import { useMutation } from "@apollo/client";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { getErrorsAndSuccess } from "utils/extractErrors";
import { addAdditionalDriversMutation } from "components/ModalAdditionalDrivers/mutations/addAdditionalDriversMutation";
import { SpinnerBack } from "components/Spinner/Spinner";
import classes from "./CheckoutContainer.module.css";

const addAdditionalDriversEAS = getErrorsAndSuccess("additional_drivers_add");

const CheckoutContainer = (props) => {
  const {
    rentalId,
    driversList,
    onNextStep,
    priceTable = {},
    classes: parentClass = {},
    onDriversSuccessAdded,
    onDriversErrorAdded
  } = props;

  const [error, setError] = useState("");

  const [addAdditionalDriversMutationAction, { loading }] = useMutation(
    addAdditionalDriversMutation
  );

  const handlePay = useCallback(async () => {
    const response = await addAdditionalDriversMutationAction({
      variables: {
        rental: rentalId,
        additional_drivers: driversList
      }
    });

    if (addAdditionalDriversEAS.isSuccess(response)) {
      onNextStep();
      onDriversSuccessAdded();
    } else if(addAdditionalDriversEAS.isErrors(response)) {
      setError(addAdditionalDriversEAS.errorMessage(response));
    }
  }, [addAdditionalDriversMutationAction, driversList, onDriversSuccessAdded, onNextStep, rentalId]);

  return (
    <div className={`${classes.checkoutContainer} ${parentClass.checkoutContainer} t-16 b-28 t-m-20 b-l-20`}>

      <Checkout
        priceTable={priceTable}
        onNextStep={handlePay}
        isFooterFixed
        disabled={loading}
      />

      {error?.length > 0 && (
        <NotificationPopover
          show
          status="error"
          text={error}
          onClose={() => {
            setError('');
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}

      {loading && <SpinnerBack />}
    </div>
  );
};

CheckoutContainer.propTypes = {};

export default CheckoutContainer;
