import { getIdToken, retrieveTokens, storeTokens } from "./tokens";

export async function makeRefreshRequest() {
  const { refreshToken } = retrieveTokens();

  if (!!refreshToken && refreshToken !== 'undefined') {
    await fetch(`${process.env.REACT_APP_AUTH_URL}/oAuth/token/refresh`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ refresh_token: refreshToken })
    }).then(async (response) => {
      const { token, refresh_token } = await response.json();

      storeTokens(token, refresh_token, 300, getIdToken());
    }).catch(() => storeTokens(null, null, null, null));
  } else {
    storeTokens(null, null, null, null);
  }
}
