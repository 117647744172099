import React, { useCallback, useState } from "react";
import Typography from "components/Typography";
import { Modal } from "components/Modal";
import { FieldInput } from "components/form/FieldInput";
import Button from "components/Button";
import classes from "./RentalFeeModal.module.css";

const RentalFeeModal = ({ show, onClose, onSubmit }) => {
  const [value, setValue] = useState("");

  const onChange = useCallback(
    (e) => setValue(e.target.value),
    [setValue]
  );

  return (
    <Modal
      modalClassnames={classes.modal}
      show={show}
      onClose={onClose}
      variant="basic"
    >
      <Typography variant="headline" size="m">
        Adjust rental fee
      </Typography>
      <div className="t-20 b-20">
        <FieldInput
          label="Rental fee, $"
          masked
          value={value}
          onChange={onChange}
          maxLength={7}
          hideMaxLength
        />
      </div>
      <div className="df t-20">
        <div className="r-20 r-m-32">
          <Button secondary label="Cancel" onClick={onClose} />
        </div>
        <Button label="Confirm" onClick={() => onSubmit(value)} />
      </div>
    </Modal>
  );
};

export default RentalFeeModal;
