import { useEffect, useState } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

export function useShowMoreCards() {
  const [cardsCount, setCardsCount] = useState(3);
  const isXxSmallScreen = useMatchMedia({ mediaQuery: "(max-width: 719.98px)" });
  const isSmallScreen = useMatchMedia({ mediaQuery: "(min-width: 720px) and (max-width: 1023.98px)" });
  const isMediumScreen = useMatchMedia({ minWidth: MQ_BP.medium, maxWidth: MQ_BP.large });
  const isLargeScreen = useMatchMedia({ minWidth: MQ_BP.large });

  useEffect(() => {
    if (isXxSmallScreen) {
      setCardsCount(3);
    } else if (isSmallScreen) {
      setCardsCount(6);
    } else if (isMediumScreen) {
      setCardsCount(9);
    } else if (isLargeScreen) {
      setCardsCount(12);
    }
  }, [isXxSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen]);

  return cardsCount;
}
