import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Modal } from "components/Modal";
import Typography from "components/Typography";
import Button from "components/Button";
import { Groups } from "components/ModalInspections";
import cn from "classnames";
import classes from "./ModalInspections.module.css";

export const ModalInspections = (props) => {
  const {
    show,
    groups,
    isStartInspection,
    isEndInspection,
    title,
    currentInspection,
    updateModalInspections,
    onClose
  } = props;

  const setCurrentStartInspection = useCallback(() => {
    updateModalInspections('start');
  }, [updateModalInspections]);

  const setCurrentEndInspection = useCallback(() => {
    updateModalInspections('end');
  }, [updateModalInspections]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      variant="full-screen"
      modalWrapClassnames={classes.modalRoot}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
    >
      <div className="container t-16 b-40 t-m-20 b-m-60 mb-40 mb-m-60 t-xxl-20">
        <div className={cn(classes.header, 'b-m-20')}>
          <Typography
            variant="headline"
            size="m"
            className={classes.title}
          >
            {title}
          </Typography>

          {currentInspection === 'start' && isEndInspection && (
            <Button
              ghost
              label="Go to rental end inspections"
              onClick={setCurrentEndInspection}
              className={cn(
                classes.buttonGoTo,
                "mt-m-4 mt-xl-12",
                "underline"
              )}
            />
          )}
          {currentInspection === 'end' && isStartInspection && (
            <Button
              ghost
              label="Go to rental start inspections"
              onClick={setCurrentStartInspection}
              className={cn(
                classes.buttonGoTo,
                "mt-m-4 mt-xxl-12",
                "underline"
              )}
            />
          )}
        </div>

        <div className={classes.content}>
          <Groups groups={groups} />
        </div>

      </div>
    </Modal>
  );
};

ModalInspections.propTypes = {
  show: PropTypes.bool,
  isEndInspection: PropTypes.bool,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      disp_title: PropTypes.shape({
        value: PropTypes.string
      }),
      edit_title: PropTypes.shape({
        value: PropTypes.string
      }),
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      is_step: PropTypes.bool,
      isFullWidth: PropTypes.bool,
      isWidthTwoThirds: PropTypes.bool,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.string,
          id: PropTypes.number,
          title: PropTypes.string,
          type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
          ]),
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
          ])
        })
      )
    })
  ),
  title: PropTypes.string,
  currentInspection: PropTypes.string,
  updateModalInspections: PropTypes.func,
  onClose: PropTypes.func
};

ModalInspections.defaultProps = {
  groups: {
    desc: null,
    isFullWidth: null,
    isWidthTwoThirds: null
  }
};
