import React, {  forwardRef } from "react";
import Select from "components/form/Select/Select";
import Typography from "components/Typography";
import PropTypes from "prop-types";
import classnames from "classnames";
import classes from "./DoubleSelect.module.css";

const DoubleSelect = forwardRef((props, ref) => {
  const {
    id: mainId,
    disabled: mainDisabled,
    className,
    value,
    title,
    data,
    onChange,
    hasError,
    errorMessage,
    helperMessage,
    name,
    errors
  } = props;

  const hint = errorMessage || helperMessage;

  return (
    <div ref={ref} className={classnames(className, classes.root)} id={mainId}>
      {title && (
        <div className="b-8">
          <Typography variant="body" size="l">
            {title}
          </Typography>
        </div>
      )}
      <div
        className={classnames(
          "df no-gutters",
          classes.selectsContainer,
          hasError && classes.hasError
        )}
      >
        {data.map(({
          id,
          label,
          options,
          className = "",
          classNameLabel = "",
          dataMenuClassName,
          dataMenuContainerClassName,
          disabled,
          ...rest
        }, selectIndex) => (
          <Select
            hasError={errors?.[name]?.type === id}
            className={classnames(
              classes.select,
              selectIndex === 0 && classes.select1,
              selectIndex === 1 && classes.select2,
              errors?.[name]?.type === id && selectIndex === 0 && classes.select1Error,
              className
            )}
            classNameLabel={classnames(classes.selectLabel, classNameLabel)}
            data-menu-class-name={dataMenuClassName}
            data-menu-container-class-name={dataMenuContainerClassName}
            key={id}
            id={id}
            label={label}
            options={options}
            value={value[id] || ""}
            onChange={(_value) => onChange({ ...value, [id]: _value })}
            disabled={mainDisabled || disabled}
            {...rest}
          />
        ))}
      </div>
      {hint?.length > 0 && (
        <div className="field-input__helpers">
          <div
            className={classnames(
              "field-input__hint",
              errorMessage && 'error'
            )}
          >
            {hint}
          </div>
        </div>
      )}
    </div>
  );
});

DoubleSelect.displayName = "DoubleSelect";

DoubleSelect.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string
      })),
      className: PropTypes.string,
      classNameLabel: PropTypes.string,
      dataMenuClassName: PropTypes.string,
      dataMenuContainerClassName: PropTypes.string,
      disabled: PropTypes.bool
    })
  ),
  value: PropTypes.object,
  helperMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  hasError: PropTypes.bool,
  errors: PropTypes.object,
  onChange: PropTypes.func
};
export default DoubleSelect;
