export function checkIMGlobalIntegrationIsEnabled(data = {}) {
  const {
    feature_flags_list = ""
  } = data;

  if (feature_flags_list.length) {
    const featuresParse = JSON.parse(feature_flags_list);
    if (featuresParse && featuresParse['IMGlobal Integration Is Enabled']) {
      return true;
    } else {
      return false;
    }
  }
}
