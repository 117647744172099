import { gql } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
export const contentBlockFragment = gql`
  fragment AllContent on ContentBlock {
    buttons {
      groupId: id
      label
      type
      link
      disabled
      new_tab
    }
    form {
      id
      label
      disabled
    }
    images {
      id
      srcset {
        url
        type
        width
        height
      }
    }
    text {
      id
      type
      value
    }
  }
`;

export const contentQuery = gql`
  query content($section: String) {
    content(section: $section) {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;
