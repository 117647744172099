import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "components/Typography";
import classes from "./GoodsamInsuranceOptionCard.module.css";
import { ContentSectionElementTextTag } from "components/ContentSection/elements/Text";
import { ReactComponent as IconCheck } from "./icon-check.svg";
import { formatCents } from "utils/convertCents";

const GoodsamInsuranceOptionCard = forwardRef(
  function BookingInsuranceOptionCard(props, ref) {
    const {
      value,
      id,
      checked,
      label,
      price,
      period,
      description,
      ...other
    } = props;
    return (
      <label
        htmlFor={id}
        className={classnames(classes.card, checked && classes.cardChecked)}
      >
        <input
          ref={ref}
          id={id}
          value={value}
          checked={checked}
          type="radio"
          className={classes.input}
          {...other}
        />
        <div className={classes.header}>
          <Typography variant="subtitle" size="m">
            {label}
          </Typography>
          <Typography variant="subtitle" size="s" className={classes["t-0"]}>
            <span className="fw-500">{`$${price.dollars_as_part}.`}</span>
            <span className="fw-400">{formatCents(price.cents_as_part)}/{period}</span>
          </Typography>
          <IconCheck className={classes.headerIconCheck} />
        </div>

        <ContentSectionElementTextTag
          content={{ type: "html", value: description }}
          elementId={`InsuranceOptionCard-${id}`}
          tag="div"
          className={classes.insuranceOptionCard}
        />
      </label>
    );
  }
);

GoodsamInsuranceOptionCard.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  price: PropTypes.shape({
    cents_total: PropTypes.number.isRequired,
    cents_as_part: PropTypes.number.isRequired,
    dollars_as_part: PropTypes.number.isRequired
  }),
  period: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default GoodsamInsuranceOptionCard;
