import React from "react";
import { RatingInfo } from "processes/RvProfile/components/RatingInfo/RatingInfo";
import useShareButton from "processes/PublicProfile/hooks/useShareButton.js";
import Typography from "components/Typography";
import classes from "./DataLine.module.css";

export const DataLine = () => {

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>

        <div className={classes.rating}>
          <div className="r-m-16 r-xl-32">
            <RatingInfo extraInfoText="Reviews" />
          </div>
        </div>


        <div className={`${classes.name} fsc`}>
          <div class="data-devider dn db-m" />
          <div className="r-16 l-m-16 l-xl-32 r-xl-32">
            <Typography variant="body" size="l" className={classes.classText}>
              Class C Motor Home
            </Typography>
          </div>
        </div>

        <div className={`${classes.btns} fsc`}>
          <div class="data-devider dn-l db-xl" />
          <div className="t-8 b-8 t-m-16 b-m-16 r-16 l-16 l-l-0 r-xl-32 l-xl-32">
            <button type="button" className="icon-txt-btn icon-txt-btn--only-icon-s icon-txt-btn--save">Save</button>
          </div>
          <div class="data-devider" />
          <div className="t-8 b-8 t-m-16 b-m-16 l-16 l-xl-32">
            {useShareButton()}
          </div>
        </div>

      </div>
    </div>
  );
};

// DataLine.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

