import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { AddOnListItemType, AddOnSelectedItemType } from "components/AddOns/types";
import AddOnsList from "components/AddOns/AddOnsList";
import FullScreenModal from "components/Modal/FullScreenModal";
import Button from "components/Button";
import classes from "./AddOnsModal.module.css";

const AddOnsModal = (props) => {
  const {
    list,
    initialSelected,
    show,
    onClose,
    onSubmit
  } = props;
  const [selected, setSelected] = useState(initialSelected || []);

  const _onSubmit = () => {
    onSubmit(selected);
  };

  const onChange = useCallback((items) => {
    setSelected(items);
  }, []);

  return (
    <FullScreenModal
      title="Add-Ons"
      footerButtons={(
        <>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={_onSubmit}>
            Select Add-ons
          </Button>
        </>
      )}
      show={show}
      onClose={onClose}
    >
      <div className={classes.content}>
        <div className="t-24 t-m-32">
          <AddOnsList list={list} selected={selected} onChange={onChange} />
        </div>
      </div>
    </FullScreenModal>
  );
};

AddOnsModal.propTypes = {
  list: PropTypes.arrayOf(AddOnListItemType).isRequired,
  initialSelected: PropTypes.arrayOf(AddOnSelectedItemType),
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AddOnsModal;
