import React, { forwardRef } from "react";
import { GoogleMapsProvider } from "components/GoogleMapsProvider";
import { MapContent } from "./MapContent";

const libraries = ["places"];

export const Map = forwardRef((props, ref) => (
  <GoogleMapsProvider libraries={libraries}>
    <MapContent ref={ref} {...props} />
  </GoogleMapsProvider>
));
