import React from "react";
import Typography from "components/Typography";
import Button from "components/Button";
import { ReactComponent as ClearSearchIcon } from "./search-icon.svg";
import classes from "./EmptyState.module.css";

export const EmptyState = ({ onResetFilters }) => {

  return (
    <div>
      <div className="tac t-16 b-16 t-m-20 b-m-20">
        <div className="t-16 t-m-20 mb-8 mb-m-4 b-8">
          <span className={classes.icon}><ClearSearchIcon /></span>
        </div>
        <div className="t-m-2">
          <Typography
            className={classes.title}
            variant="subtitle"
            size="m"
          >
          There is nothing
          </Typography>
        </div>
        <div className="t-2 t-m-0">
          <Typography
            className={classes.subtitle}
            variant="body"
            size="l"
          >
          There are no payouts in the selected period.<br/>You can try to update filters or select a different RV.
          </Typography>
        </div>
        <Button
          secondary
          onClick={onResetFilters}
          className={`${classes.button} b-16 b-m-20 mt-20 mt-m-20`}
        >
          Clear filters
        </Button>
      </div>
    </div>
  );
};
