import React from "react";
import classnames from "classnames";
import Typography from "components/Typography";
import GoodsamMembershipOption from "./GoodsamMembershipOption";
import { SpinnerBack } from "components/Spinner/Spinner";
import Button from "components/Button";

const GoodsamMembershipPlanSelection = ({
  classes,
  content,
  loading,
  options,
  onSavePlan,
  activeId,
  toggleVerify
}) => {

  return (
    <div className={`${classes.goodsamMembershipPlanSelection}`}>
      <Typography
        variant="subtitle"
        size="s"
        className={`tac`}
      >
        {content.membershipTitle}
      </Typography>

      <div className={`${classes.optionsWrap} t-12 t-m-20 b-12 b-m-20`}>
        {loading && <SpinnerBack />}
        {options?.map((option) => (
          <div key={option.id} className={classes.optionWrap}>
            <GoodsamMembershipOption
              id={option.id}
              label={option.title}
              name={option.quantity}
              price={{
                price: option?.price,
                taxes: option?.tax,
                priceTotal: option?.price_total
              }}
              description={option.description}
              ctaLabel={"Join for " + option.quantity}
              onSelect={onSavePlan}
              isActive={activeId === option.id}
            />
          </div>
        ))}
      </div>
      <div
        className={classnames(
          classes.validateBox,
          "mt-8 mt-m-20 mb-16 mt-m-20 t-2 t-m-0"
        )}
      >
        <div className={classes.validateBoxItems}>
          <Typography
            variant="body"
            size="l"
            className={classes.validateBoxItem}
          >
            {content.membershipValidateText}
          </Typography>
          <Typography
            variant="body"
            size="l"
            className={classes.validateBoxItem}
          >
            <Button secondary className={classes.btnLink} onClick={toggleVerify}>
              {content.membershipValidateCtaLabel}
            </Button>
          </Typography>
        </div>
      </div>
      <div className="t-m-20 b-16 b-m-20 mt-8 t-2 mt-m-0">
        <Typography
          variant="body"
          size="s"
          component="div"
          className={classes.disclaimerText}
          dangerouslySetInnerHTML={{
            __html: content.membershipDisclaimer
          }}
        />
      </div>
    </div>
  );
};

GoodsamMembershipPlanSelection.propTypes = {};

export default GoodsamMembershipPlanSelection;
