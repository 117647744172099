import React, { useState } from "react";
import { ReactComponent as GoodsamLogo } from "./goodsam-logo.svg";
import Checkbox from "components/form/Checkbox";
import Typography from "components/Typography";
import Button from "components/Button";
import classes from "./Checkout.module.css";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";

const Checkout = ({
  planPrice: { price, taxes, priceTotal },
  onNextStep,
  disabled
}) => {

  const [isTermChecked, setIiTermChecked] = useState(false);

  return (
    <div className={`${classes.checkout} mt-44 mb-8 mt-m-52 mb-m-32 mb-l-0 `}>
      <div className={`${classes.checkoutInner}`}>
        <div className="">
          <div className="devider-solid mt-12 mb-12 mb-m-16" />
          <div className={`row fcc ${classes.checkoutRow}`}>
            <div
              className={`${classes.checkoutPlan} fcc fw `}
            >
              <div className={`${classes.checkoutPlanTextContainer} fsc col-9`}>
                <GoodsamLogo />
                <Typography
                  component="p"
                  variant="body"
                  size="l"
                  className={`${classes.checkoutPlanText} fsc f-nowrap`}
                >
                membership fee
                </Typography>
              </div>
              <div className={`${classes.checkoutPlanPrice} col-3 `}>
                <Typography
                  component="p"
                  variant="body"
                  size="l"
                  className={`${classes.checkoutPlanText} fec `}
                >
                  <FormattedPrice value={price} isBold />
                </Typography>
              </div>
            </div>
          </div>

          <div className="devider-dashed mt-8 mb-8 mt-m-12 mb-m-12" />

          <div className={`row fcc ${classes.checkoutRow}`}>
            <div
              className={`${classes.checkoutPlan} fcc fw`}
            >
              <div className={`${classes.checkoutPlanTextContainer} fsc col-9`}>
                <Typography
                  component="p"
                  variant="body"
                  size="l"
                  className={`${classes.checkoutPlanText} fsc f-nowrap`}
                >
                Taxes
                </Typography>
              </div>
              <div className={`${classes.checkoutPlanPrice} col-3 `}>
                <Typography
                  component="p"
                  variant="body"
                  size="l"
                  className={`${classes.checkoutPlanText} fec `}
                >
                  <FormattedPrice value={taxes} isBold />
                </Typography>
              </div>
            </div>
          </div>
          <div className="devider-solid mt-12 mb-12 mt-m-16 mb-m-16 mb-l-20" />
          <div className={`row fcc ${classes.checkoutRow} t-l-2`}>
            <div
              className={`${classes.checkoutPlan} fcc fw`}
            >
              <div className={`${classes.checkoutPlanTextContainer} fsc col-9`}>
                <Typography
                  variant="subtitle"
                  weight="bold"
                  size="m"
                  className={`${classes.checkoutPlanText} fsc f-nowrap`}
                >
                Total
                </Typography>
              </div>
              <div className={`${classes.checkoutPlanPrice} col-3 `}>
                <Typography
                  variant="subtitle"
                  size="m"
                  className={`${classes.checkoutPlanText} fec `}
                >
                  <FormattedPrice value={priceTotal} isBold />
                </Typography>
              </div>
            </div>
          </div>
          <div className={`fcc ${classes.checkoutRow}`}>
            <div
              className={`${classes.checkoutTermsContainer} mt-24 mb-12 mt-m-28 mb-m-16`}
            >
              <div className={`${classes.checkoutTerms}`}>
                <Checkbox
                  id="checkboxTerm"
                  name="agree"
                  checked={isTermChecked}
                  html='<p>I have read and accept Good Sam RV Rentals <a href="/terms-of-use">Terms of Service</a>.</p>'
                  onChange={() => {
                    setIiTermChecked(!isTermChecked);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className={`${classes.checkoutButtonConfirm} fcc t-12 t-m-16 b-20`}
          >
            <Button
              className={classes.button}
              onClick={onNextStep}
              primary
              disabled={!isTermChecked || disabled}
            >
            Confirm and Pay <FormattedPrice value={priceTotal} isBold />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
