import { gql } from "@apollo/client";

export const insurancePackages = gql`
  query insurancePackages($rv: Int!, $rental_type: Int) {
    insurance_packages(rv: $rv, rental_type: $rental_type) {
      id
      name
      description
      is_default
      price_per_day {
        cents_total
        dollars_as_part
        cents_as_part
        user_friendly
      }
    }
  }
`;
