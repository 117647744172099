/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import { DeleteAccountModal } from "processes/MyAccount/steps/General/DeleteAccount/DeleteAccountModal/DeleteAccountModal.js";
import classes from "../ContactSection.module.css";

export const DeleteAccountForm = ({ onDeleteAccount }) => {
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
return (
    <>
      <div>
        <DeleteAccountModal
          show={showDeleteAccountModal}
          onClose={() => setShowDeleteAccountModal(false)}
          onDeleteAccount={onDeleteAccount}
        />
        <div className="devider-solid mb-16 mb-m-20 mt-24 mt-m-36 mt-l-40" />
        <div className="fbc">
          <div>
            <Typography
              variant="subtitle"
              size="m"
              className={classes.sectionTitle}
            >
              Account
            </Typography>
          </div>
          <div className="mt-8 mt-m-2">
            <Button
              ghost
              onClick={() => setShowDeleteAccountModal(true)}
              className={classes.editButton}
            >
              Delete account
            </Button>
          </div>
        </div>

        <div>
          <Typography
            variant="subtitle"
            size="s"
            className={classes.description}
          >
            Deleting an account will not cancel current rentals
          </Typography>
         </div>
      </div>
    </>
  );
};
