import React from 'react';
import { ContentSectionElementButton } from "./Button";

export const ContentSectionElementButtonGroup = (props) => {
  const {
    elementId,
    groupId,
    content,
    render,
    ...rest
  } = props;

  return (
    <>
      {content.map((buttonContent, pos) => {
        const button = (
          <ContentSectionElementButton
            key={pos}
            elementId={`${elementId}--${pos}`}
            content={buttonContent}
            {...rest}
          />
        );

        return render ? render(pos, button) : button;
      })}
    </>
  );
};
