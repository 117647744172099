import icon from "assets/images/good_sam_small_text.svg";
import React from "react";
import Button from "components/Button";
import classes from "./BecomeAMember.module.css";

export const BecomeAMember = ({
  isGoodSamMember,
  memberAndSave,
  setIsShowBookingMembership
}) => {

  const becomeAMemberNoMemberClassName = (memberAndSave || memberAndSave !== 0) ? '' : classes.becomeAMemberNoMember;
  return (
    <>
      <div
        className={`${classes.root}`}
        onClick={() => setIsShowBookingMembership(true)}
      >
        <p className={`${classes.rootText} ${becomeAMemberNoMemberClassName}`}>
          <span className={`${classes.becomeText}`}>
            <span className={` ${classes.primary}`}>Become a</span>
            <img src={icon} alt="Icon" className={classes.smallTextIcon} />
          </span>
          <span>
            <span className={` ${classes.primary}`}>member</span>{" "}
            {(memberAndSave || memberAndSave !== 0) && (
              <span className={` ${classes.memberSavingsDiscount}`}>
                and save
                <span className={` ${classes.memberSavings}`}>
                  ${memberAndSave}
                </span>
              </span>
            )}
          </span>
        </p>
        <Button
          secondary
          className={classes.chooseSubscription}
          label="Choose subscription plan"
        />
      </div>
    </>
  );
};
