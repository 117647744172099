import { useLazyQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { MatchMedia } from "../../../../components/MatchMedia";
import RcCollapse from "../../../../components/RcCollapse";
import { SpinnerBlur } from "../../../../components/Spinner/Spinner";
import Typography from "../../../../components/Typography";
import { contentQuery } from "../../../../queries";
import { extractText } from "../../../../utils/extractText";
import { MyAccountContext } from "../../MyAccountContext";
import classes from "./ContactSection.module.css";
import { MyAccountLoader } from "./MyAccountLoader";

const GeneralPanel = (props) => {
  const [sectionInEditing, setSectionInEditing] = useState(false);
  const { content, userData } = props;

  const toggleSectionInEditing = useCallback(
    (editingStatus) => {
      setSectionInEditing(editingStatus);
    },
    [setSectionInEditing]
  ); // TODO: Use FormContainer

  return (
    <div className={classes.container}>
      <Typography
        component="h2"
        variant="headline"
        size="s"
        className="mb-12 mb-s-20 mb-m-8 mb-xl-20"
      >
        {content.sectionTitle}
      </Typography>
      <MatchMedia mediaQuery="(max-width: 767px)">
        <div className="t-16 b-32">
          <RcCollapse
            header={content.proTipsSectionTitle}
            content={content.proTipsSectionText}
          />
        </div>
      </MatchMedia>
      <MyAccountLoader
        content={content}
        userData={userData}
        sectionInEditing={sectionInEditing}
        toggleSectionInEditing={toggleSectionInEditing}
      />
    </div>
  );
};

const section = "account_section_general";
const initialContent = {
  sectionTitle: "",
  proTipsSectionTitle: "",
  proTipsSectionText: "",
  emailSectionTitle: "",
  emailVerificationMessageTitle: "",
  emailVerificationMessageText: "",
  emailVerifiedLabel: "",
  phoneSectionTitle: "",
  phoneVerificationMessageTitle: "",
  phoneVerificationMessageText: "",
  phoneVerifiedLabel: "",
  passwordSectionTitle: "",
  passwordForgotText: "",
  passwordForgotLink: ""
};

const GeneralDataProvider = (props) => {
  const [generalContent, setGeneralContent] = useState(initialContent);
  const [getContent, { loading, data }] = useLazyQuery(contentQuery, {
    variables: {
      section
    },
    fetchPolicy: "no-cache"
  });

  const { setAccountSection } = props;

  useEffect(getContent, [getContent]);

  useEffect(() => {
    setAccountSection(section);
  }, [setAccountSection]);

  useEffect(() => {
    if (!loading && data?.content?.text) {
      setGeneralContent({
        sectionTitle: extractText(data.content.text, "section_title"),
        proTipsSectionTitle: extractText(
          data.content.text,
          "pro_tips_section_title"
        ),
        proTipsSectionText: extractText(
          data.content.text,
          "pro_tips_section_text"
        ),
        emailSectionTitle: extractText(
          data.content.text,
          "email_section_title"
        ),
        emailVerificationMessageTitle: extractText(
          data.content.text,
          "email_verification_message_title"
        ),
        emailVerificationMessageText: extractText(
          data.content.text,
          "email_verification_message_text"
        ),
        emailVerifiedLabel: extractText(
          data.content.text,
          "email_verified_label"
        ),
        phoneSectionTitle: extractText(
          data.content.text,
          "phone_section_title"
        ),
        phoneVerificationMessageTitle: extractText(
          data.content.text,
          "phone_verification_message_title"
        ),
        phoneVerificationMessageText: extractText(
          data.content.text,
          "phone_verification_message_text"
        ),
        phoneVerifiedLabel: extractText(
          data.content.text,
          "phone_verified_label"
        ),
        passwordSectionTitle: extractText(
          data.content.text,
          "password_section_title"
        ),
        passwordForgotText: extractText(
          data.content.text,
          "password_forgot_text"
        ),
        passwordForgotLink: extractText(
          data.content.text,
          "password_forgot_link"
        )
      });
    }
  }, [loading, data]);

  if (loading) {
    return <SpinnerBlur />;
  }

  return <GeneralPanel content={generalContent} {...props} />;
};

export const General = () => (
  <MyAccountContext.Consumer>
    {(value) => <GeneralDataProvider {...value} />}
  </MyAccountContext.Consumer>
);
