import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { ReactComponent as RentalProtectionLogo } from "./rental-protection-logo.svg";
import classnames from "classnames";
import { ReactComponent as ArrowBack } from "./arrow-back.svg";
import Typography from "components/Typography";
import classes from "./GoodsamInsuranceModal.module.css";
import { Modal } from "components/Modal";
import GoodsamInsuranceForm from "./GoodsamInsuranceForm";
import useGetElementContentScroll from "hooks/useGetElementContentScroll";
import Button from "components/Button";
import { chevronRightCircleFillIcon } from "components/Icon";
import cn from 'classnames';
import { useScrollBarWidth } from "hooks/useScrollBarWidth";

const GoodsamInsuranceModal = (props) => {
  const formRef = useRef();
  const wrapRef = useRef();
  const { formData, content, show, onClose, onSave, backTo } = props;

  const [mdlWrapRef, scroll] = useGetElementContentScroll();
  const handleSave = () => {
    formRef.current.handleSubmit(onSave);
  };
  const handleBack = useCallback(() => {
    if (backTo) {
      onClose();
      backTo.onClick?.();
    }
  }, [backTo, onClose]);
  const mdlRef = useScrollBarWidth();

  return (
    <Modal
      show={show}
      onClose={onClose}
      showCross={false}
      withContainer
      mdlWrapRef={mdlWrapRef}
      mdlRef={mdlRef}
      modalWrapClassnames={`mdl-wrap--full-screen ${classes.modalWrapper} `}
      modalClassnames={classes.modal}
      render={() => (
        <div className="row fjc" ref={wrapRef}>
          <div className="col col-m-8 col-l-12 col-xl-12 col-xxxl-10">
            <div
              className={classnames(
                "mdl mdl-wrap--full-screen ",
                classes.mdl,
                backTo && classes.mdlWidthHeaderArrowBack
              )}
            >
              {!backTo && (
                <div
                  onClick={onClose}
                  className={classnames(`mdl__cross`, classes.crossButton)}
                />
              )}
              {backTo && (
                <div
                  className={classnames(
                    classes.mdlHeader,
                    scroll.top > 0 && classes.mdlHeaderWithShadow
                  )}
                >
                  <div className={`${classes.headerBackInner}`}>
                    <Button
                      ghost
                      onClick={handleBack}
                      className={classes.btnBack}
                    >
                      <span className={classes.btnBackInner}>
                        <span className={classes.btnBackIcon}>
                          <ArrowBack />
                        </span>
                        <Typography variant="body" size="l" component="span">
                          {backTo.label}
                        </Typography>
                      </span>
                    </Button>
                  </div>
                </div>
              )}
              <div
                className={`${classes.logoWrap} mt-12 mt-m-16 mb-12 mb-m-16`}
              >
                <RentalProtectionLogo />
              </div>
              <Typography
                variant="subtitle"
                size="s"
                className={`tac`}
              >
                {content.insuranceTitle}
              </Typography>
              <div className="col-12 l-0 r-0 l-m-12 r-m-12 l-l-0 r-l-0">
                <GoodsamInsuranceForm
                  ref={formRef}
                  wrapRef={wrapRef}
                  initialData={formData}
                  content={content.insuranceFormContent}
                  onSave={onSave}
                />
                <div className="fbc t-16 t-m-28 t-l-20 b-16 b-m-20">
                  <div className={classes.confirmWrap}>
                    <div className={`${classes.confirmWrap2} col-l-12 col-xl-12 col-xxl-10`}>
                      <div className={`${classes.confirmWrapInner}`}>
                        <div className="dn db-m mr-24 mr-xxl-32">
                          <Button secondary onClick={onClose}>
                            Cancel
                          </Button>
                        </div>
                        <div className="">
                          <Button onClick={handleSave}>Confirm</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tac fw mt-l-8">
                    <Button
                      href={content.insuranceLink.to}
                      ghost
                      icon={chevronRightCircleFillIcon}
                      iconPosition="after"
                      className={cn(classes.btnLink)}
                      {...(content.insuranceLink.blank
                        ? {
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }
                        : {})}
                    >
                      <span>{content.insuranceLink.label}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

GoodsamInsuranceModal.propTypes = {
  formData: PropTypes.exact({
    insurance: PropTypes.string.isRequired
  }).isRequired,
  content: PropTypes.shape({
    insuranceTitle: PropTypes.string.isRequired,
    insuranceFormContent: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        price: PropTypes.shape({
          cents_total: PropTypes.number.isRequired,
          cents_as_part: PropTypes.number.isRequired,
          dollars_as_part: PropTypes.number.isRequired
        }),
        period: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    insuranceLink: PropTypes.exact({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      blank: PropTypes.bool
    }).isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  backTo: PropTypes.exact({
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired
  })
};

export default GoodsamInsuranceModal;
