import React, { memo, useMemo, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import VehicleCardBridge from "../VehicleCardBridge/VehicleCardBridge";
import classes from "./List.module.css";

function useReady(callback, items) {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, 100);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [callback, items]);
}

const VirtualItem = memo((props) => {
  const {
    items,
    className,
    searchData,
    isInFavorites,
    onChangeFavorite,
    onClickCardLink,
    onReady,
    mapOpen,
    userId,
    breakpoints: {
      isSpecificMedium,
      isSpecificLarge,
      isMobile,
      isLargeDesktop
    }
  } = props;

  useReady(onReady, items);

  const itemClassName = classnames(
    classes.card,
    "t-12 b-12 t-l-16 b-l-16",
    mapOpen ? "col col-xl-6 col-xxl-12" : "col-12 col-m-6 col-l-4"
  );

  return (
    <div className={classnames(className, classes.items)}>
      {items.map((item, itemIndex) => (
        <div key={item.id} className={itemClassName}>
          <VehicleCardBridge
            photoUrlPrefix={searchData.photo_url_prefix}
            linkPrefix={searchData.rv_url_prefix}
            item={item}
            itemIndex={itemIndex}
            isInFavorites={isInFavorites}
            onChangeFavorite={onChangeFavorite}
            onClickCardLink={onClickCardLink}
            variant={isLargeDesktop && mapOpen ? "row" : "col"}
            size={isSpecificMedium || isSpecificLarge || isMobile ? "s" : "m"}
            userId={userId}
          />
        </div>
      ))}
    </div>
  );
});

VirtualItem.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  searchData: PropTypes.object,
  isInFavorites: PropTypes.func,
  onChangeFavorite: PropTypes.func,
  onReady: PropTypes.func,
  mapOpen: PropTypes.bool
};

export default VirtualItem;
