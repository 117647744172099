import { gql } from "@apollo/client";

export const rvsQuery = gql`
  query ($make: String, $manufacturer: String, $year: Int) {
    rvs(make: $make, manufacturer: $manufacturer, year: $year) {
      id,
      model,
      rvClass: class
    }
  }
`;
