import React from "react";
import { Controller } from "react-hook-form";
import { FieldInput } from "../../../form/FieldInput";
import { FieldTextarea } from "../../../form/FieldTextarea";
import { LabelContent } from "components/ContentSection/LabelContent";
import { Text } from "components/ContentSection/elements";

export const ListingDetailsFormContent = ({ control, errors }) => (
  <div className="row t-s-20 b-s-20">
    <div className="col-12 t-16 t-m-20 b-16 b-m-20">
      <Controller
        name="listing_title"
        control={control}
        rules={{ required: "Listing title is required" }}
        render={(renderProps) => (
          <LabelContent
            section="listingDetailsSectionContent"
            id="listing_title"
          >
            {(label) => (
              <FieldInput
                className="fw"
                id="listing_title"
                label={label}
                maxLength={70}
                errors={errors}
                {...renderProps}
              />
            )}
          </LabelContent>
        )}
      />
    </div>
    <div className="col-12 t-16 t-m-20 b-16 b-m-20">
      <Controller
        name="listing_description"
        control={control}
        rules={{ required: "Listing description is required" }}
        render={(renderProps) => (
          <LabelContent
            section="listingDetailsSectionContent"
            id="listing_description"
          >
            {(label) => (
              <FieldTextarea
                resize="none"
                rows={3}
                className="fw"
                id="listing_description"
                label={label}
                maxLength={1500}
                errors={errors}
                {...renderProps}
              />
            )}
          </LabelContent>
        )}
      />
    </div>
    <div className="col-12 t-16 t-m-20 b-16 b-m-20">
      <Controller
        name="what_is_included"
        control={control}
        render={(renderProps) => (
          <LabelContent
            section="listingDetailsSectionContent"
            id="what_is_included"
          >
            {(label) => (
              <FieldTextarea
                resize="none"
                rows={3}
                className="fw"
                id="included"
                label={label}
                helperMessage={
                  <Text
                    id="recommendations_description"
                    section="listingDetailsSectionContent"
                  />
                }
                maxLength={1500}
                errors={errors}
                {...renderProps}
              />
            )}
          </LabelContent>
        )}
      />
    </div>
    <div className="col-12 t-16 t-m-20 b-16 b-m-20">
      <Controller
        name="recommendations"
        control={control}
        render={(renderProps) => (
          <LabelContent
            section="listingDetailsSectionContent"
            id="recommendations"
          >
            {(label) => (
              <FieldTextarea
                resize="none"
                rows={3}
                className="fw"
                id="recommendations"
                label={label}
                helperMessage={
                  <Text
                    id="recommendations_description"
                    section="listingDetailsSectionContent"
                  />
                }
                maxLength={1500}
                errors={errors}
                {...renderProps}
              />
            )}
          </LabelContent>
        )}
      />
    </div>
  </div>
);
