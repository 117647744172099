import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import NoItemsFound from "../NoItemsFound/NoItemsFound";
import ListItem from "../ListItem/ListItem";
import { ListItemType } from "../../types";
import classes from "./List.module.css";

const List = (props) => {
  const { className, items } = props;

  if (items?.length > 0) {
    return (
      <div className={classnames(className, classes.items)}>
        {items.map((item, index) => (
          <div
            key={`${item.id}-${index}`}
            className={classnames(classes.card, "t-m-20 b-m-20")}
          >
            <ListItem className={classes.listItem} item={item} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={className}>
      <NoItemsFound />
    </div>
  );
};

List.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(ListItemType)
};

export default List;
