import React from "react";
import { DescriptionList } from "processes/RvProfile/components/DescriptionSection/DescriptionList/DescriptionList";
import classes from "./DescriptionSection.module.css";

const descListData = [
  {
    id: '1',
    title: 'Listing Description',
    desc: ' This is a unique, very comfortable Winnebago Minnie Winnie Model 31K with less than 9300 miles! It’s 32.75 feet long and powered by a Ford Triton 305 HP gas V10 on a 450 chassis. The exterior is clean and ding & dent free. Custom redesigned interior is very clean. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
  },
  {
    id: '2',
    title: 'What’s Included',
    desc: ' This is a unique, very comfortable Winnebago Minnie Winnie Model 31K with less than 9300 miles! It’s 32.75 feet long and powered by a Ford Triton 305 HP gas V10 on a 450 chassis. The exterior is clean and ding & dent free. Custom redesigned interior is very clean. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
  },
  {
    id: '3',
    title: 'Things to Know',
    desc: ' This is a unique, very comfortable Winnebago Minnie Winnie Model 31K with less than 9300 miles! It’s 32.75 feet long and powered by a Ford Triton 305 HP gas V10 on a 450 chassis. The exterior is clean and ding & dent free. Custom redesigned interior is very clean. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
  }
];

export const DescriptionSection = () => {

  return (
    <div className={classes.container}>
      <DescriptionList data={descListData} />
    </div>
  );
};

// DescriptionSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

