/*
 * Validation Utils
 */

import { DS_IDS } from "../components/form/DateSelect/DateSelect";

const REQUIRED_TEXT = "Required";

export const VALIDATION = {
  rules: {
    required: { required: REQUIRED_TEXT },
    phone: {
      required: "Phone number is required",
      validate: {
        isValid: (value) => {
          return value?.match(/\d/g)?.length >= 11
            ? null
            : "Invalid phone number";
        }
      }
    },
    date: {
      required: REQUIRED_TEXT,
      validate: {
        [DS_IDS.month]: (value) => {
          return Boolean(value[DS_IDS.month]) ? null : "Month is required";
        },
        [DS_IDS.day]: (value) => {
          return Boolean(value[DS_IDS.day]) ? null : "Day is required";
        },
        [DS_IDS.year]: (value) => {
          return Boolean(value[DS_IDS.year]) ? null : "Year is required";
        }
      }
    },
    trueCheckbox: {
      required: REQUIRED_TEXT,
      validate: {
        isTrue: (v) => v === true || v === "true"
      }
    },
    getSSN: (required) => ({
      required: required || REQUIRED_TEXT,
      pattern: {
        value: /^\d+$/,
        message: "Invalid SSN code"
      }
    }),
    getZip: (required) => ({
      required: required || REQUIRED_TEXT,
      pattern: {
        value: /^((?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}|[A-Z]{1,2}[0-9R][0-9A-Z]?[ -]?[0-9][ABD-HJLNP-UW-Z]{2}|[0-9]{5}|[A-Z][0-9][A-Z][ -]?[0-9][A-Z][0-9])$/i,
        message: "Invalid ZIP code"
      }
    }),
    stripeElement: {
      required: REQUIRED_TEXT,
      validate: {
        main: (value) => {
          return value?.error?.message ? false : true;
        }
      }
    }
  }
};
