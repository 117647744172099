import style from "./style.module.css";

export const icon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={style.header__icon}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1.41 7.41L10.83 12l2.58 2.58c.39.39.38 1.03 0 1.42A.996.996 0 0112 16l-3.29-3.29a.996.996 0 010-1.41L12 8a.996.996 0 111.41 1.41z"
      fill="currentColor"
    />
  </svg>
);
