import React from "react";
import Icon from "./Icon";

export const DeliveryOnlyIcon = () => (
  <Icon>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10.8619C5 6.80487 8.32698 3.5 12.4012 3.5C16.4756 3.5 19.7945 6.80487 19.7945 10.8619C19.7945 13.6804 18.0044 16.2501 16.3008 18.1585C14.5974 20.067 12.8922 21.3354 12.8922 21.3354C12.7499 21.4422 12.5763 21.5 12.398 21.5C12.2198 21.5 12.0462 21.4422 11.9038 21.3354C11.9038 21.3354 10.1972 20.0671 8.49357 18.1585C6.78998 16.25 5 13.6801 5 10.8619ZM12.3973 16.3219C15.3932 16.3219 17.8219 13.8932 17.8219 10.8973C17.8219 7.9013 15.3932 5.4726 12.3973 5.4726C9.4013 5.4726 6.9726 7.9013 6.9726 10.8973C6.9726 13.8932 9.4013 16.3219 12.3973 16.3219Z" fill="#474342"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6094 12.4904H16.904C17.0026 12.4904 17.0822 12.5702 17.0822 12.6686C17.0822 12.767 17.0024 12.8468 16.9039 12.8468H12.4386C12.4406 12.8196 12.4418 12.792 12.4418 12.7644C12.4418 12.6265 12.4148 12.4928 12.3616 12.3668C12.3102 12.2453 12.2367 12.136 12.1428 12.0422C12.0491 11.9485 11.9398 11.8748 11.8181 11.8234C11.6923 11.77 11.5584 11.7431 11.4206 11.7431C11.2826 11.7431 11.1488 11.77 11.0228 11.8234C10.9012 11.8748 10.792 11.9483 10.6982 12.0422C10.6043 12.1359 10.5307 12.2452 10.4792 12.3668C10.4259 12.4928 10.3989 12.6265 10.3989 12.7644C10.3989 12.792 10.4 12.8196 10.4021 12.8468H8.87692C8.7784 12.8468 8.69863 12.767 8.69863 12.6686C8.69863 12.5701 8.7784 12.4903 8.87692 12.4903H9.02777V9.11725C9.02777 9.11725 9.12381 8.18493 10.2482 8.18493L14.3891 8.18501C15.5134 8.18501 15.6094 9.11733 15.6094 9.11733V12.4904ZM11.7241 10.6393C11.848 10.6393 11.9485 10.5388 11.9485 10.4148V9.57492C11.9485 9.45103 11.848 9.3505 11.7241 9.3505H10.3358C10.2118 9.3505 10.1112 9.45096 10.1112 9.57492V10.4148C10.1112 10.5388 10.2118 10.6393 10.3358 10.6393H11.7241ZM14.3705 12.2161C14.4943 12.2161 14.5949 12.1156 14.5949 11.9916V9.57494C14.5949 9.45106 14.4944 9.35052 14.3705 9.35052H13.5032C13.3791 9.35052 13.2786 9.45098 13.2786 9.57494V11.9916C13.2786 12.1156 13.3791 12.2161 13.5032 12.2161H14.3705Z" fill="#474342"/>
    <path d="M10.6885 12.7647C10.6885 12.3611 11.0169 12.0327 11.4206 12.0327C11.8242 12.0327 12.1526 12.3609 12.1526 12.7647C12.1526 12.7876 12.1514 12.8104 12.1494 12.8329C12.1149 13.2047 11.8011 13.4967 11.4206 13.4967C11.0399 13.4967 10.7261 13.2047 10.6916 12.8329C10.6896 12.8104 10.6885 12.7876 10.6885 12.7647Z" fill="#474342"/>
  </Icon>
);
