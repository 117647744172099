import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ChangeBlock from "./components/ChangeBlock";
import DetailsBlock from "./components/DetailsBlock";
import { RentalSuggestionType } from "./types";
import { possessive } from "utils/possessive";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import FullScreenModal from "components/Modal/FullScreenModal";
import { getRentalOpponent, RENTAL_ROLES } from "processes/Rentals/utils";
import { useMutation } from "@apollo/client";
import { editLastSuggestion, makeSuggestion } from "processes/Rentals/RentalChange/mutations/submit";
import { SpinnerBack } from "components/Spinner/Spinner";
import { VERIFICATION_ERROR_CODE_LIST, DEFAULT_DATA } from "components/VerificationCodeStepsModals/constants/verificationErrorCodes";
import VerificationCodeStepsModals from "components/VerificationCodeStepsModals/VerificationCodeStepsModals";
import {
  extractServerErrors,
  extractSuccess,
  extractServerErrorsCode
} from "utils/extractErrors";
import classes from "./RentalChange.module.css";

const RentalChange = (props) => {
  const { rental, currentSuggestName, show, onClose, onSubmit, isIMGlobalIntegrationIsEnabled } = props;

  const modalRef = useRef(null);
  const [errors, setErrors] = useState(null);
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const [verificationStepsData, setVerificationStepsData] = useState(DEFAULT_DATA);
  const confirmedSuggestion = rental.confirmed_suggestion;
  const lastSuggestion = rental.last_suggestion;

  const hasMadeChanges = Boolean(confirmedSuggestion || lastSuggestion);
  const priorSuggestUser = useMemo(() => {
    return hasMadeChanges ? [
      { ...rental.owner, role: RENTAL_ROLES.owner },
      { ...rental.renter, role: RENTAL_ROLES.renter }
    ].find(i => i.role === lastSuggestion?.initiator?.key) : null;
  }, [hasMadeChanges, rental.owner, rental.renter, lastSuggestion?.initiator?.key]);
  const rentalOpponent = getRentalOpponent(rental);
  const isEditMutation = priorSuggestUser &&
    priorSuggestUser?.id !== rentalOpponent?.id;

  const mutationName = isEditMutation
    ? "edit_last_suggestion"
    : "make_suggestion";

  const isStatusInWorks = rental?.status?.id === 1;
  const rentalType = Number(rental.rental_type.key);

  const extractMutationSuccess = useMemo(() => {
    return extractSuccess(mutationName);
  }, [mutationName]);

  const extractMutationErrors = useMemo(() => {
    return extractServerErrors(mutationName);
  }, [mutationName]);

  const extractMutationErrorCode = useMemo(() => {
    return extractServerErrorsCode(mutationName);
  }, [mutationName]);

  const [
    createSuggestionAction,
    createSuggestionResult
  ] = useMutation(isEditMutation ? editLastSuggestion : makeSuggestion);

  useEffect(() => {
    const errorCode = extractMutationErrorCode(createSuggestionResult);

    if (!VERIFICATION_ERROR_CODE_LIST.includes(errorCode)) {
      setErrors(extractMutationErrors(createSuggestionResult));
    }

  }, [createSuggestionResult?.data, extractMutationErrorCode, extractMutationErrors]);

  const getPriorBlock = useCallback((expandable = false) => {
    return lastSuggestion && priorSuggestUser && (
      <DetailsBlock
        expandable={expandable}
        data={lastSuggestion}
        rental={rental}
        rentalType={rentalType}
        label={[
          possessive(
            priorSuggestUser?.short_name,
            { checkDot: true }
          ),
          "prior suggestion"
        ].join(" ")}
      />
    );
  }, [lastSuggestion, priorSuggestUser, rental, rentalType]);

  const getConfirmedBlock = useCallback((expandable = false) => {
    return confirmedSuggestion && (
      <DetailsBlock
        expandable={expandable}
        rental={rental}
        data={confirmedSuggestion}
        label="Confirmed rental details"
        rentalType={rentalType}
      />
    );
  }, [confirmedSuggestion, rental, rentalType]);

  const onCreateSuggestion = useCallback(async (variables) => {
    try {
      const response = await createSuggestionAction({
        variables: {
          rental: rental.id,
          ...variables
        } }
      );

      if (extractMutationSuccess(response)) {
        onSubmit();
      }

      if (extractMutationErrors(response)?.length > 0) {
        const errorCode = extractMutationErrorCode(response);

        if (VERIFICATION_ERROR_CODE_LIST.includes(errorCode)) {
          setVerificationStepsData({
            code: errorCode,
            variables
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [createSuggestionAction, extractMutationErrorCode, extractMutationErrors, extractMutationSuccess, onSubmit, rental.id]);

  const getChangeBlock = useCallback((expandable = false) => {
    return (
      <ChangeBlock
        className={classes.changeBlock}
        currentSuggestName={currentSuggestName}
        modalRef={modalRef}
        modalShow={show}
        expandable={expandable}
        rental={rental}
        isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
        onSubmit={onCreateSuggestion}
        isSingleBlock={!hasMadeChanges}
        errors={errors}
        setErrors={setErrors}
      />
    );
  }, [currentSuggestName, show, rental, isIMGlobalIntegrationIsEnabled, onCreateSuggestion, hasMadeChanges, errors]);

  return (
    <FullScreenModal
      title={'Suggest a change'}
      show={show}
      onClose={onClose}
      isStatusInWorks={isStatusInWorks}
    >
      {createSuggestionResult.loading && (
        <SpinnerBack />
      )}
      <div ref={modalRef} className={`${classes.content} mb-80 b-20 b-m-0 mb-m-0`}>
        {isMaxSmall ? (
          <div className={classes.cardsMobile}>
            {getConfirmedBlock(true)}
            {getChangeBlock(hasMadeChanges)}
            {getPriorBlock(true)}
          </div>
        ) : (
          <div className={classes.cards}>
            {hasMadeChanges && (
              <div className={classes.details}>
                {getPriorBlock()}
                {getConfirmedBlock()}
              </div>
            )}
            {getChangeBlock()}
          </div>
        )}

        <VerificationCodeStepsModals
          data={verificationStepsData}
          onResetData={setVerificationStepsData}
          onRefetchMutation={onCreateSuggestion}
        />
      </div>
    </FullScreenModal>
  );
};

RentalChange.propTypes = {
  rental: PropTypes.shape({
    id: PropTypes.number,
    owner: PropTypes.object,
    renter: PropTypes.object,
    confirmed_suggestion: RentalSuggestionType,
    last_suggestion: RentalSuggestionType
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RentalChange;
