import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Typography from "components/Typography";
import Tooltip from "components/Tooltip/Tooltip";
import { FieldController } from "components/form/FieldController/FieldController";
import Switch from "components/form/Switch";
import { questionIcon } from "components/Icon";
import { isSafeInt } from "utils/isSafeInt";
import SingleSlider from "components/sliders/SingleSlider";
import { FieldInput } from "components/form/FieldInput";
import { RX_FLOAT, RX_INT } from "utils/constants/RX";
import Radio from "components/form/Radio";
import scrollIntoView from "utils/scrollIntoView";
import { LabelContent, TextContent } from "components/ContentSection/LabelContent";
import classnames from "classnames";
import classes from "../LocationRentalTabs/LocationRentalTab.module.css";
import get from "lodash/get";
import set from "lodash/set";

export const DeliveryRadius = (props) => {
  const {
    tabIndex,
    control,
    control: { setValue },
    errors,
    clearErrors,
    watch
  } = props;

  const { location, tabs } = watch();

  const {
    allowSwitch,
    delivery_option,
    flat_delivery_price,
    minimum_fee,
    cost_per_mile_value,
    paid_delivery,
    delivery_price_type
  } = tabs[tabIndex];

  const [radio, setRadio] = useState("cost_per_mile");

  function handleSliderChange(value) {
    setValue(`tabs[${tabIndex}].free_delivery`, value);
  }

  useEffect(() => {
    if (isSafeInt(parseInt(flat_delivery_price, 10) * 100)) {
      clearErrors(`tabs[${tabIndex}].flat_delivery_price`);
    }
    if (isSafeInt(parseInt(minimum_fee, 10) * 100)) {
      clearErrors(`tabs[${tabIndex}].minimum_fee`);
    }
    if (isSafeInt(parseInt(cost_per_mile_value, 10) * 100)) {
      clearErrors(`tabs[${tabIndex}].cost_per_mile_value`);
    }
    if (isSafeInt(parseInt(paid_delivery, 10) * 100)) {
      clearErrors(`tabs[${tabIndex}].paid_delivery`);
    }
  }, [
    clearErrors,
    flat_delivery_price,
    minimum_fee,
    cost_per_mile_value,
    paid_delivery,
    tabIndex
  ]);

  useEffect(() => {
    if (delivery_price_type === 1) {
      setRadio('cost_per_mile');
      setValue(`tabs[${tabIndex}].cost_per_mile`, true);
      setValue(`tabs[${tabIndex}].flat_price`, false);
    } else {
      setRadio('flat_price');
      setValue(`tabs[${tabIndex}].cost_per_mile`, false);
      setValue(`tabs[${tabIndex}].flat_price`, true);
    }
  }, [delivery_price_type, setValue, tabIndex]);

  return (
    <>
      <div className="row fsc mt-24 mt-m-32 mt-xl-28">
        <div className="col fsc">
          <Typography variant="subtitle" size="m" className={classes.subTitle}>
          Delivery radius
          </Typography>

          <Tooltip
            tooltipContainerClassName={classes.tooltipQuestionContainer}
            trigger={['click']}
            tooltipContent={"Set paid delivery miles to the max number of miles you are willing to deliver, from your starting point. If you would like to offer any miles for free, toggle the free miles to that total. You can charge renters a flat rate or by the mile."}
          >
            {(ref) => (
              <span className={`${classes.btnIcon} dib`} ref={ref}>
                {questionIcon}
              </span>
            )}
          </Tooltip>

        </div>
        <div className={`col-auto delivery_option-${tabIndex}`}>
          <div className="t-xl-4">
            <FieldController
              name={`tabs[${tabIndex}].delivery_option`}
              control={control}
              render={(renderProps) => (
                <Switch
                  id={`delivery_option-${tabIndex}`}
                  {...renderProps}
                  onChange={(checked) => {
                    renderProps.onChange(checked);
                    if (checked) {
                      scrollIntoView(`.delivery_option-${tabIndex}`, null, { behavior: "smooth", block: "center" });
                    }
                  }}
                  disabled={!allowSwitch || location?.full_address === ""}
                />
              )}
            />
          </div>
        </div>
      </div>


      <div className={!allowSwitch || !delivery_option ? 'dn' : ''}>
        <div className="row mt-28 mt-m-0">

          <div className="col-12 col-m">
            <div className="mt-m-40">
              <SingleSlider
                inverseColors
                onChange={handleSliderChange}
                defaultValue={tabs[tabIndex]?.free_delivery}
                max={Number(tabs[tabIndex]?.paid_delivery)}
                min={0}
              />
            </div>
          </div>

          <div className="col-12 col-m-auto order-m-first mt-36 mt-m-32">
            <div className={classes.milesField}>
              <Controller
                name={`tabs[${tabIndex}].free_delivery`}
                control={control}
                rules={{
                  required: "Free delivery is required",
                  validate: (v) => {
                    if (!isSafeInt(parseInt(v, 10) * 100))
                      return "Incorrect input";
                  }
                }}
                render={(renderProps) => {
                  return (
                    <FieldInput
                      className="fw field-input--grouped"
                      id={`tabs[${tabIndex}].free_delivery`}
                      label={"Free delivery"}
                      type="number"
                      helperMessage={`0 - ${tabs[tabIndex].free_delivery} miles`}
                      disabled
                      errors={errors}
                      {...renderProps}
                    >
                      <div className={classes.inputUnits}>miles</div>
                    </FieldInput>
                  );
                }
                }
              />
            </div>
          </div>

          <div className="col-12 col-m-auto mt-36 mt-m-32">
            <div className={classes.milesField}>
              <Controller
                name={`tabs[${tabIndex}].paid_delivery`}
                control={control}
                rules={{
                  required: delivery_option && allowSwitch ? "Paid delivery is required" : false,
                  validate: (v) => {
                    if (isSafeInt(parseInt(v, 10)) && Number(v) > 3000) {
                      return "Can't be more than 3000 miles";
                    }
                    if (!isSafeInt(parseInt(v, 10)) && delivery_option && allowSwitch) {
                      return "Incorrect input";
                    }
                  },
                  pattern: {
                    value: RX_FLOAT,
                    message: "Incorrect input"
                  }
                }}
                render={(renderProps) => {
                  if (get(errors, `tabs[${tabIndex}].paid_delivery`)) {
                    set(errors, `tabs[${tabIndex}].paid_delivery.message`, 'Can’t be less than free delivery');
                  }
                  return (
                    <FieldInput
                      className="fw field-input--grouped"
                      id={`tabs[${tabIndex}].paid_delivery`}
                      label={"Paid delivery"}
                      helperMessage={`${tabs[tabIndex].free_delivery} - ${tabs[tabIndex].paid_delivery} miles`}
                      errors={errors}
                      {...renderProps}
                    >
                      <div className={classes.inputUnits}>miles</div>
                    </FieldInput>
                  );
                }
                }
              />
            </div>
          </div>
        </div>

        <div className="row mt-36 mt-m-40">
          <div className="col-auto">
            <FieldController
              name={`tabs[${tabIndex}].cost_per_mile`}
              control={control}
              render={() => {
                return (
                  <Radio
                    id={`${tabIndex}-cost_per_mile`}
                    className={classes.radio}
                    name={`${tabIndex}-switch`}
                    text={"Cost per mile"}
                    checked={radio === "cost_per_mile"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setRadio("cost_per_mile");
                        setValue(`tabs[${tabIndex}].cost_per_mile`, true);
                        setValue(`tabs[${tabIndex}].flat_price`, false);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="col-auto">
            <FieldController
              name={`tabs[${tabIndex}].flat_price`}
              control={control}
              render={() => {
                return (
                  <Radio
                    id={`${tabIndex}-flat_price`}
                    className={classes.radio}
                    name={`${tabIndex}-switch`}
                    text={"Flat price"}
                    checked={radio === "flat_price"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setRadio("flat_price");
                        setValue(`tabs[${tabIndex}].cost_per_mile`, false);
                        setValue(`tabs[${tabIndex}].flat_price`, true);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="row mt-16 mt-m-40">
          <div
            className={classnames(
              "col-12 col-m-6",
              radio === 'flat_price' && "dn"
            )}
          >
            <Controller
              name={`tabs[${tabIndex}].cost_per_mile_value`}
              control={control}
              rules={{
                required: delivery_option && allowSwitch && radio === "cost_per_mile" ? "Cost per mile is required" : false,
                validate: (v) => {
                  if (radio === "cost_per_mile") {
                    if (v && !isSafeInt(parseInt(v, 10) * 100) && delivery_option && allowSwitch) {
                      return "Incorrect input";
                    }
                  } else {
                    return true;
                  }
                },
                pattern: {
                  value: RX_FLOAT,
                  message: "Incorrect input"
                }
              }}
              render={(renderProps) => {
                return (
                  <LabelContent
                    section="locationSectionContent"
                    id="cost_per_mile"
                  >
                    {(label) => (
                      <TextContent
                        section="locationSectionContent"
                        id="cost_per_mile_description"
                      >
                        {(helperMessage) => (
                          <FieldInput
                            className="fw field-input--grouped"
                            id={`tabs[${tabIndex}].cost_per_mile_value`}
                            label={label}
                            helperMessage={helperMessage}
                            errors={errors}
                            {...renderProps}
                          />
                        )}
                      </TextContent>
                    )}
                  </LabelContent>
                );
              }}
            />
          </div>

          <div
            className={classnames(
              "col-12 col-m-6 mt-36 mt-m-0",
              radio === 'flat_price' && "dn"
            )}>
            <Controller
              name={`tabs[${tabIndex}].minimum_fee`}
              control={control}
              rules={{
                required: delivery_option && allowSwitch && radio === "cost_per_mile" ? "Minimum fee is required" : false,
                validate: (v) => {
                  if (radio === "cost_per_mile") {
                    if (!isSafeInt(parseInt(v, 10) * 100) && delivery_option && allowSwitch) {
                      return "Incorrect input";
                    }
                  } else {
                    return true;
                  }
                },
                pattern: {
                  value: RX_FLOAT,
                  message: "Incorrect input"
                }
              }}
              render={(renderProps) => {
                return (
                  <LabelContent
                    section="locationSectionContent"
                    id="minimum_fee"
                  >
                    {(label) => (
                      <TextContent
                        section="locationSectionContent"
                        id="minimum_fee_description"
                      >
                        {(helperMessage) => (
                          <FieldInput
                            className="fw field-input--grouped"
                            id={`tabs[${tabIndex}].minimum_fee`}
                            label={label}
                            helperMessage={helperMessage}
                            errors={errors}
                            {...renderProps}
                          />
                        )}
                      </TextContent>
                    )}
                  </LabelContent>
                );
              }}
            />
          </div>
          <div
            className={classnames(
              "col-12 col-m-6",
              radio === 'cost_per_mile' && "dn"
            )}>
            <Controller
              name={`tabs[${tabIndex}].flat_delivery_price`}
              control={control}
              rules={{
                required: delivery_option && allowSwitch && radio === "flat_price" ? "Delivery price fee is required" : false,
                validate: (v) => {
                  if (radio === "flat_price") {
                    if ((!isSafeInt(parseInt(v, 10) * 100) || v === '0') && delivery_option && allowSwitch) {
                      return "Incorrect input";
                    }
                  } else {
                    return true;
                  }
                },
                pattern: {
                  value: RX_FLOAT,
                  message: "Incorrect input"
                }
              }}
              render={(renderProps) => {
                return (
                  <FieldInput
                    className="fw field-input--grouped"
                    id={`tabs[${tabIndex}].flat_delivery_price`}
                    label={"Delivery fee"}
                    helperMessage={`e.g. $10`}
                    errors={errors}
                    {...renderProps}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
