import { withContent } from "./withContent";
import { ContentSectionElementImage } from "./Image";
import {
  ContentSectionElementTextTag,
  ContentSectionElementTextRaw,
  ContentSectionElementText
} from "./Text";
import { ContentSectionElementButton } from "./Button";
import { ContentSectionElementButtonGroup } from "./ButtonGroup";

export const Image = withContent(ContentSectionElementImage, "images");
export const TextTag = withContent(ContentSectionElementTextTag, "text");
export const TextRaw = withContent(ContentSectionElementTextRaw, "text");
export const Text = withContent(ContentSectionElementText, "text");
export const Button = withContent(
  ContentSectionElementButton,
  "buttons",
  id => content => content.find(item => item.groupId === id || item.id === id)
);

export const ButtonGroup = withContent(
  ContentSectionElementButtonGroup,
  "buttons",
  id => content => content.filter(item => item.groupId === id)
);
