import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

function buildQuery(options) {
  const {
    mediaQuery,
    maxWidth,
    minWidth,
    maxHeight
  } = options;

  if (mediaQuery) {
    return mediaQuery;
  }

  return [
    minWidth ? `(min-width:${minWidth}px)` : "",
    ((minWidth && (maxWidth || maxHeight))
    || (maxWidth && (minWidth || maxHeight))
    || (maxHeight && (minWidth || maxWidth)))
    && "and",
    maxWidth ? `(max-width:${maxWidth - 0.02}px)` : "",
    maxHeight ? `(max-height:${maxHeight - 0.02}px)` : ""
  ].join(" ");
}

/**
 * @param {String} [options.mediaQuery]
 * @param {Number} [options.maxWidth]
 * @param {Number} [options.minWidth]
 * @return {boolean}
 */
export function useMatchMedia(options) {
  const resultQuery = buildQuery(options);

  const mediaQueryList = useMemo(() => window.matchMedia(resultQuery), [
    resultQuery
  ]);
  const [matches, setMatches] = useState(mediaQueryList.matches);

  const handleChangeScreen = useCallback(
    e => {
      setMatches(e.matches);
    },
    [setMatches]
  );

  useEffect(() => {
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener("change", handleChangeScreen);
    } else {
      mediaQueryList.addListener(handleChangeScreen);
    }

    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener("change", handleChangeScreen);
      } else {
        mediaQueryList.removeListener(handleChangeScreen);
      }
    };
  }, [mediaQueryList, handleChangeScreen]);

  return matches;
}

export const MatchMedia = (props) => {
  const {
    mediaQuery,
    renderLeft,
    renderRight,
    children
  } = props;
  const matches = useMatchMedia({ mediaQuery });

  if (matches && typeof renderLeft === "function") {
    return renderLeft();
  }

  if (!matches && typeof renderRight === "function") {
    return renderRight();
  }

  return matches && children ? children : null;
};

MatchMedia.propTypes = {
  mediaQuery: PropTypes.string.isRequired,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
  children: PropTypes.node
};
