import classnames from "classnames";
import React from "react";
import { AsideInfo, AsideNav, Header, StepsRouter } from "./components";
import { useMobileNavMode } from "./hooks/useMobileNavMode";
import { MyAccountContext } from "./MyAccountContext";
import { ContentLoader } from "components/ContentSection";
import { linksContentQuery } from "queries/contentQuery";
import { contentPredicate } from "utils/contentPredicate";
import { CONTENT_QUERY_LINK } from "constants/contentQuery";
import style from "./style.module.css";

const MyAccountContentProvider = ({ content }) => {
  const { accountContent, accountSection, userData } = content;
  const headerTitle = accountContent?.myAccountTitle || "My account";
  const [isMobileNavMode, setIsMobileNavMode] = useMobileNavMode();
  const classNames = classnames(
    style.myAccount,
    isMobileNavMode && "myAccount_all-steps-view",
    !isMobileNavMode && "myAccount_step-view"
  );
  const { has_payment } = userData;
  return (
    <div className={classNames}>
      <Header
        header={headerTitle}
        isMobileNavMode={isMobileNavMode}
        setIsMobileNavMode={setIsMobileNavMode}
      />
      <main className="container">
        <div className="row mr-l-0 mr-neg-xl-12 mb-l-64 mb-xl-0">
          <AsideNav
            isMobileNavMode={isMobileNavMode}
            setIsMobileNavMode={setIsMobileNavMode}
          />
          <section className="col-12 col-m-12 col-l-8 col-xl-6 ml-xl-64 _hidden-all-steps-view">
            <StepsRouter has_payment={has_payment} />
          </section>
          <AsideInfo
            className="_hidden-all-steps-view t-16 t-m-8 t-l-12 t-xl-0 b-16 b-m-8 b-l-0"
            section={accountSection}
          />
        </div>
      </main>
    </div>
  );
};

export const MyAccount = () => {
  return (
    <MyAccountContext.Consumer>
      {(context) => (
        <ContentLoader
          query={linksContentQuery}
          predicate={contentPredicate([CONTENT_QUERY_LINK])}
          render={() => (
            <MyAccountContentProvider content={context} />
          )}
        />
      )}
    </MyAccountContext.Consumer>
  );
};
