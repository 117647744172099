import React, { useMemo } from "react";
import PropTypes from "prop-types";
import NoVehiclesFound from "../NoVehiclesFound/NoVehiclesFound";
import { useSearchContext } from "../../useSearchContext";
import FixedWidthList from "./FixedWidthList";
import { useColumnAndRowCount } from "./useColumnAndRowCount";
import { AutoSizer, WindowScroller } from "react-virtualized";
import classes from "./List.module.css";
import { MQ_BP, useMatchMedia } from "../../../MatchMedia";

function useVisualBreakpoints() {
  const isSpecificMedium = useMatchMedia({
    minWidth: 624,
    maxWidth: 780
  });
  const isSpecificLarge = useMatchMedia({
    minWidth: 1024,
    maxWidth: 1165
  });
  const isMobile = useMatchMedia({ maxWidth: MQ_BP.xSmall });
  const isLargeDesktop = useMatchMedia({ minWidth: MQ_BP.xLarge });

  return useMemo(() => ({
    isSpecificMedium,
    isSpecificLarge,
    isMobile,
    isLargeDesktop
  }), [isSpecificMedium, isSpecificLarge, isMobile, isLargeDesktop]);
}

const List = (props) => {
  const { className } = props;

  const {
    searchData,
    boundedList,
    mapOpen,
    resetAllFilters,
    hasActiveFilters,
    isInFavorites,
    onChangeFavorite,
    userId
  } = useSearchContext();

  const [columnCount, rowCount] = useColumnAndRowCount(mapOpen, boundedList);

  const breakpoints = useVisualBreakpoints();

  if (boundedList?.length) {
    return (
      <div className={className}>
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <div className={classes.itemsContainer} ref={registerChild}>
                  <FixedWidthList
                    userId={userId}
                    height={height}
                    width={width}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    scrollTop={scrollTop}
                    data={boundedList}
                    columnCount={columnCount}
                    rowCount={rowCount}
                    isInFavorites={isInFavorites}
                    onChangeFavorite={onChangeFavorite}
                    searchData={searchData}
                    mapOpen={mapOpen}
                    breakpoints={breakpoints}
                  />
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </div>
    );
  }

  return (
    <div className={className}>
      <NoVehiclesFound
        onClearFilters={resetAllFilters}
        hasActiveFilters={hasActiveFilters}
      />
    </div>
  );
};

List.propTypes = {
  className: PropTypes.string
};

export default List;
