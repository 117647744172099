import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { TransactionHistoryContent } from "processes/MyAccount/steps/TransactionHistory";
import { MyAccountContext } from "processes/MyAccount/MyAccountContext";
import { useTransactionHistory } from "../../hooks/useTransactionHistory";
import { contentQuery } from "queries/contentQuery";
import { extractText } from "utils/extractText";
import { SpinnerBlur } from "components/Spinner/Spinner";
import { useMemo } from "react";

const section = "account_section_tax_dashboard";
const initialContent = {
  sectionTitle: "",
  proTipsSectionTitle: "",
  proTipsSectionText: ""
};

const TransactionHistoryProvider = (props) => {
  const [content, setContent] = useState(initialContent);
  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section
    },
    fetchPolicy: "no-cache"
  });

  const [payouts, onGetTransactionHistory] = useTransactionHistory();

  const { setAccountSection } = props;

  useEffect(() => {
    setAccountSection(section);
  }, [setAccountSection]);

  useEffect(() => {
    if (!loading) {
      setContent({
        sectionTitle: extractText(data.content.text, "section_title"),
        proTipsSectionTitle: extractText(
          data.content.text,
          "pro_tips_section_title"
        ),
        proTipsSectionText: extractText(
          data.content.text,
          "pro_tips_section_text"
        )
      });
    }
  }, [loading, data]);

  const value = useMemo(() => ({
    payouts,
    onGetTransactionHistory
  }), [onGetTransactionHistory, payouts]);

  if (loading) {
    return <SpinnerBlur />;
  }

  return (
    <TransactionHistoryContent
      content={content}
      value={value}
      {...props}
    />
  );
};

export const TransactionHistory = () => (
  <MyAccountContext.Consumer>
    {(value) => <TransactionHistoryProvider {...value} />}
  </MyAccountContext.Consumer>
);
