import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Radio = forwardRef((props, ref) => {
  const {
    id,
    className,
    text,
    hasError,
    hideLabel,
    children,
    ...inputProps
  } = props;

  const classNames = classnames(
    "radio",
    {
      "radio--no-label": hideLabel,
      error: hasError
    },
    className
  );

  return (
    <label className={classNames} htmlFor={id}>
      <input ref={ref} id={id} type="radio" {...inputProps} />
      <span className="radio__pseudo-check" />
      {text && <span className="radio__txt">{text}</span>}
      {children}
    </label>
  );
});

Radio.displayName = "Radio";

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultChecked: PropTypes.bool,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export default Radio;
