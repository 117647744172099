import size from "lodash/size";
import { FILTERS } from "components/Search/filters";
/*
 * Search Utils
 */

/**
 * Returns map point object in Google LatLng format
 * @see https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng
 */
export const getMapLatLng = (lat, lng) => ({ lat, lng });

export const getListItemLatLng = (item) => getMapLatLng(item.lt, item.ln);

export function isPointInViewport(location) {
  if (
    location.lt < location.viewport_northeast_lat
    && location.lt > location.viewport_southwest_lat
    && location.ln < location.viewport_northeast_lng
    && location.ln > location.viewport_southwest_lng
  ) {
    return true;
  }

  return false;
}

export function setSelectedRentalType(name, isSelected, list, setter) {
  const newRentalTypeList = list.map((item) => {
    if (item.name === name) return {
      ...item, selected: isSelected
    };
    return item;
  });
  setter(newRentalTypeList);
}

export function updateRentalType(data, standard, deliveryOnly, setter) {
  const newRentalTypeList = data.map((item) => {
    if (item.name === 'rentalTypeStandard') {
      return {
        ...item, selected: standard
      };
    }
    if (item.name === 'rentalTypeDeliveryOnly') {
      return {
        ...item, selected: deliveryOnly
      };
    }
    return item;
  });
  setter(newRentalTypeList);
}

export function getYNMMMParameters(searchResultsList = []) {
  if (searchResultsList.length === 0) {
    return searchResultsList;
  }
  let newSearchResultsList = [...searchResultsList].map(({ yr, mr, mk, md, ...parameters }) => {
    let nm = '';

    if (yr > 0 && mr.trim() !== "" && mk.trim() !== "" && md.trim() !== "") {
      const brand = mr === mk ? mk : `${mr} ${mk}`;
      nm = `${yr} ${brand} ${md}`;
    }
    return { ...parameters, nm, yr, mr, mk, md };
  });

  return newSearchResultsList;
}

function exludeUnnecessaryClasses(activeClasses, classesList) {
  const classes = Object.keys(activeClasses);
  const res = {};

  classesList.forEach(({ name }) => {
    if (classes.some(cl => name === cl)) {
      res[name] = true;
    }
  });

  return res;
}

export function clearInactiveFilters(isOpen, activeFilters, filtersControl, classesList) {
  if (!isOpen) {
    if (size(activeFilters)) {
      const values = filtersControl.getValues();
      const keys = Object.keys(values);
      keys.forEach((key) => {
        if (key === "class" && classesList?.length > 0) {
          filtersControl.setValue(key,  exludeUnnecessaryClasses(activeFilters[key], classesList));
        } else {
          filtersControl.setValue(key,  activeFilters[key]);
        }
      });
    } else {
      const keys = Object.keys(FILTERS.defaults);

      keys.forEach((key) => {
        filtersControl.setValue(key,  FILTERS.defaults[key]);
      });
    }
  }
}
