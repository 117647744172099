import React from "react";
import PropTypes from "prop-types";
import classes from "./DropdownListItem.module.css";

const DropdownListItem = (props) => {
  const { children, onClick } = props;

  return <li className={classes.item} onClick={onClick}>{children}</li>;
};

DropdownListItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default DropdownListItem;
