/**
 * Media Query BreakPoints
 */
export const MQ_BP = {
  xLarge: 1680,
  large: 1440,
  medium: 1024,
  small: 768,
  xSmall: 560,
  xxSmall: 320
};
