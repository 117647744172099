import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const predefinedMutation = gql`
  ${baseFragments.result}
  mutation predefined(
    $rv: Int!
    $rental_type: Int
    $departure_date: String!
    $return_date: String!
    $add_ons: [Int]
    $delivery_location: String
    $specific_delivery_location_id: Int
    $rental_fee: Int
    $delivery_fee: Int
    $renter_email: String!
    $expiration_date: String
    $insurance_package: Int
    $message: String
  ) {
    predefined(
      rv: $rv
      rental_type: $rental_type
      departure_date: $departure_date
      return_date: $return_date
      add_ons: $add_ons
      delivery_location: $delivery_location
      specific_delivery_location_id: $specific_delivery_location_id
      rental_fee: $rental_fee
      delivery_fee: $delivery_fee
      renter_email: $renter_email
      expiration_date: $expiration_date
      insurance_package: $insurance_package
      message: $message
    ) {
      result {
        ...Result
      }
      rental {
        id
      }
    }
  }
`;
