import React, { useEffect } from "react";
import WidgetHeader from "components/Widget/components/WidgetHeader/WidgetHeader";
import VehicleCardWidgetContainer from "components/Widget/components/VehicleCards/VehicleCardWidgetContainer";
import WidgetFilters from "components/Widget/components/WidgetFilters/WidgetFilters";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { useWidgetHeight } from "components/Widget/hooks/useWidgetHeight";
import { useGetWidgetData } from "components/Widget/hooks/useGetWidgetData";
import classes from "components/Widget/Widget.module.css";

export const Widget = () => {
  const {
    loadSearch,
    setTheme,
    setHeaderTitle,
    setOwnerDirectory,
    setOwnerPageUrl,
    setIframeUrl,
    setIframeNumber,
    ownerDirectory,
    iframeNumber
  } = useWidgetContext();

  const widgetRef = useWidgetHeight(iframeNumber);
  const widgetData = useGetWidgetData();

  useEffect(() => {
    if (widgetData.ownerDirectory && !ownerDirectory) {
      setOwnerDirectory(widgetData.ownerDirectory);
      loadSearch({
        variables: {
          directory: widgetData.ownerDirectory
        }
      });
    }

    if (widgetData.theme) {
      setTheme(widgetData.theme);
    }

    if (widgetData.title) {
      setHeaderTitle(widgetData.title);
    }

    if (widgetData.ownerPageUrl) {
      setOwnerPageUrl(widgetData.ownerPageUrl);
    }

    if (widgetData.iframeUrl) {
      setIframeUrl(widgetData.iframeUrl);
    }

    if (widgetData.iframeNumber) {
      setIframeNumber(widgetData.iframeNumber);
    }
  }, [
    loadSearch,
    ownerDirectory,
    setHeaderTitle,
    setOwnerDirectory,
    setOwnerPageUrl,
    setIframeUrl,
    setTheme,
    widgetData,
    setIframeNumber,
    iframeNumber
  ]);

  return (
    <div
      className={classes.container}
      ref={widgetRef}
    >
      <WidgetHeader />
      <WidgetFilters />
      <VehicleCardWidgetContainer />
    </div>
  );
};

export default Widget;
