import { gql } from "@apollo/client";

export const rentalInterruptionInsuranceContent = gql`
  query($section: String) {
    rentalInterruptionInsuranceContent: content(section: $section) {
      text {
        id
        value
        type
      }
    }
  }
`;
