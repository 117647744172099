import React from "react";
import { ReviewsShowMore } from "components/Reviews/components/ReviewsShowMore/ReviewsShowMore";
import classes from "./ReviewsSection.module.css";

export const ReviewsSection = () => {

  const photos = [
    {
      id: '1',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce83e3c2.jpg',
      alt: ''
    },
    {
      id: '2',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce85747a.jpg',
      alt: ''
    },
    {
      id: '3',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce86f2c6.jpg',
      alt: ''
    },
    {
      id: '4',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce887406.jpg',
      alt: ''
    },
    {
      id: '5',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce89edd2.jpg',
      alt: ''
    },
    {
      id: '6',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce8b5ff7.jpg',
      alt: ''
    },
    {
      id: '7',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce83e3c2.jpg',
      alt: ''
    },
    {
      id: '8',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce89edd2.jpg',
      alt: ''
    },
    {
      id: '9',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daace1f5.jpg',
      alt: ''
    },
    {
      id: '10',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daaac947.jpg',
      alt: ''
    }
  ];

  const reviews = [
    {
      id: "1",
      photo: {
        src: 'http://cw-rr.dev.quintegro.net/files/profiles/1000/1633085684_php6156e8f48d47f.150.jpg',
        alt: ''
      },
      name: 'Mike J.',
      rating: 3,
      date: 'Feb 10, 2021',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      galeryPhotos: photos
    },
    {
      id: "2",
      photo: {
        src: 'http://cw-rr.dev.quintegro.net/files/profiles/1000/1633085684_php6156e8f48d47f.150.jpg',
        alt: ''
      },
      name: 'John M.',
      rating: 3,
      date: 'Feb 10, 2021',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      galeryPhotos: photos
    }
  ];
  return (
    <div className={classes.container}>
      <ReviewsShowMore data={reviews} count={reviews.length} />
    </div>
  );
};

// ReviewsSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

