import React from "react";
import { PriceFormSubItem } from "components/PriceForm";

const PriceFormSubItems = (props) => {
  const {
    forms,
    itemInd
  } = props;

  const subItems = forms[0].items[itemInd].sub_items;

  return (
    <div>
      {subItems.map((item, itemIndex) => (
        <PriceFormSubItem
          key={itemIndex}
          forms={forms}
          itemInd={itemInd}
          subItemInd={itemIndex}
        />
      ))}
    </div>
  );
};

PriceFormSubItems.propTypes = {
};

export default PriceFormSubItems;
