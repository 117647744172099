import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import icon from "./error-icon.svg";
import classes from "./FormError.module.css";

export const FormError = ({ className, message, requestErrors, separator }) => {
  if (!requestErrors || !requestErrors.length) {
    return null;
  }

  return (
    <div className={classnames(classes.root, className)}>
      <img className="mr-12" src={icon} alt="" />
      {message && (
        <strong className={classnames(classes.intro, "mr-8")}>{message}</strong>
      )}
      <span className={classes.list}>
        {requestErrors.map((e) => e.message).join(separator)}
      </span>
    </div>
  );
};

FormError.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  requestErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.any
    })
  ),
  separator: PropTypes.string
};

FormError.defaultProps = {
  separator: ", "
};
