import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from 'moment';
import cn from 'classnames';
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { predefinedMutation } from "processes/PreDefinedRental/mutations/predefinedMutation";
import { useCalculatePrice } from "processes/Booking/CalculatePrice/useCalculatePrice";
import { Modal } from "components/Modal";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { SpinnerBlur } from "components/Spinner/Spinner";
import { STEP } from "./constants/step";
import StatusFooter from "./components/StatusFooter/StatusFooter";
import SelectVehicle from "./components/SelectVehicle/SelectVehicle";
import SelectDates from "./components/SelectDates/SelectDates";
import SelectDelivery from "./components/SelectDelivery/SelectDelivery";
import SelectAddons from "./components/SelectAddons/SelectAddons";
import Summary from "./components/Summary/Summary";
import StartOver from "./components/StartOver/StartOver";
import getCalculatePriceParams from './utils/getCalculatePriceParams';
import { writeStorage } from "utils/storageFirstView";
import classes from './PreDefinedRental.module.css';
import { TOAST_TYPE } from "constants/toastTypes";

const DEFAULT_STATE = {
  currentStep: STEP.RV,
  departure_date: undefined,
  return_date: undefined,
  dailyRateCentsTotal: 0,
  dailyRateDollarAsPart: 0,
  availability: [],
  minimum_duration: 1,
  selected_addons: [],
  block_days: true,
  renter_email: '',
  expiration_date: '',
  message: '',
  loading: true
};

const INSURANCE_ID_DEFAULT = 2;

const DEFAULT_ERROR = {
  status: false,
  message: ''
};

const PreDefinedRental = ({
  content,
  show,
  onClose
}) => {
  const history = useHistory();

  const [state, setState] = useState(DEFAULT_STATE);
  const [error, setError] = useState(DEFAULT_ERROR);
  const [showStartOverModal, setShowStartOverModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insuranceId, setInsuranceId] = useState(INSURANCE_ID_DEFAULT);
  const [valueCalculatePrice, onCalculatePrice] = useCalculatePrice(state.rv, null);

  const [createPredefinedAction, createPredefinedResult] = useMutation(predefinedMutation);

  const calculatePriceStateObject = useMemo(() => ({
    departure_date: state.departure_date,
    return_date: state.return_date,
    rv: state.rv,
    rental_fee: state.rental_fee,
    delivery_fee: state.delivery_fee,
    rental_type: state.rental_type,
    add_ons: state.selected_addons,
    delivery_location: state.delivery_location,
    specific_delivery_location_id: state.specific_delivery_location_id,
    insurance_package: insuranceId,
    is_predefined: true
  }), [insuranceId, state.delivery_fee, state.delivery_location, state.departure_date, state.rental_fee, state.rental_type, state.return_date, state.rv, state.selected_addons, state.specific_delivery_location_id]);

  useEffect(() => {
    if (!valueCalculatePrice.loading) {
      setError(DEFAULT_ERROR);

      if (valueCalculatePrice.error) {
        setError({
          status: true,
          message: valueCalculatePrice.errorMessage
        });
        setState(previousState => ({
          ...previousState,
          price: undefined,
          priceLoading: false
        }));
      } else {
        setState(previousState => ({
          ...previousState,
          price: valueCalculatePrice,
          priceLoading: false
        }));
      }
    } else {
      setState(previousState => ({
        ...previousState,
        price: undefined,
        priceLoading: true
      }));
    }
  }, [valueCalculatePrice]);

  const closeClick = useCallback(() => {
    setState(DEFAULT_STATE);
    onClose();
  }, [onClose]);

  const previousStepClick = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      currentStep: previousState.currentStep - 1
    }));
  }, []);

  const nextStepClick = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      currentStep: previousState.currentStep === STEP.LOCATION && !state.add_ons?.length // if choosen rv doesn't have addons
        ? previousState.currentStep + 2 // skip addons step
        : previousState.currentStep + 1
    }));
  }, [state.add_ons?.length]);

  const startOverClick = useCallback(() => { setShowStartOverModal(true); }, []);

  const startOverCancel = useCallback(() => { setShowStartOverModal(false); }, []);

  const onStartOverProcess = useCallback(() => {
    setState(DEFAULT_STATE);
    setShowStartOverModal(false);
  }, []);

  const submitClick = useCallback(async () => {
    setLoading(true);

    try {
      const response = await createPredefinedAction({
        variables: {
          ...getCalculatePriceParams(calculatePriceStateObject),
          renter_email: state.renter_email,
          expiration_date: state.expiration_date,
          message: state.message
        }
      });
      setLoading(false);

      if (response.data?.predefined?.rental) {
        writeStorage(response.data?.predefined?.rental.id, TOAST_TYPE.PREDEFINED, true);
        history.push(`/rentals/details?id=${response.data?.predefined?.rental.id}`);
      } else {
        setError({
          status: true,
          message: response.data?.predefined?.result?.errors[0].message
        });
      }
    } catch (e) {
      setLoading(false);

      setError({
        status: true,
        message: e.errorMessage
      });
    }
  }, [calculatePriceStateObject, createPredefinedAction, history, state.expiration_date, state.message, state.renter_email]);

  const onCloseError = useCallback(() => {
    setError(DEFAULT_ERROR);
  }, []);

  const onSelectVehicle = useCallback((
    id,
    rv_name,
    title_image,
    cents_total,
    dollars_as_part,
    availability_calendar,
    minimum_duration,
    location,
    allow_standard_rental,
    allow_delivery_only_rental,
    delivery_option,
    dor_delivery_option,
    paid_delivery,
    dor_paid_delivery,
    specific_delivery_locations,
    dor_specific_delivery_locations,
    add_ons
  ) => {
    setState(() => ({
      ...DEFAULT_STATE,
      rv: id,
      rv_name,
      title_image,
      dailyRateCentsTotal: cents_total,
      dailyRateDollarAsPart: dollars_as_part,
      availability: availability_calendar,
      minimum_duration: minimum_duration,
      location,
      allow_standard_rental,
      allow_delivery_only_rental,
      delivery_option,
      dor_delivery_option,
      paid_delivery,
      dor_paid_delivery,
      specific_delivery_locations,
      dor_specific_delivery_locations,
      add_ons
    }));
  }, []);

  const handleLocationOnCalculatePrice = useCallback(
    (periodFrom, periodTo) => {
      const departure_date = moment(periodFrom).format("YYYY-MM-DD");
      const return_date = moment(periodTo).format("YYYY-MM-DD");

      onCalculatePrice({
        departure_date,
        return_date
      });
    },
    [onCalculatePrice]
  );

  const onSelectDates = useCallback((
    departure_date,
    return_date
  ) => {

    setState((previousState) => ({
      ...previousState,
      departure_date,
      return_date,
      price: undefined
    }));

    if (departure_date && return_date) {
      handleLocationOnCalculatePrice(departure_date, return_date);
    }
  }, [handleLocationOnCalculatePrice]);

  const onSelectDelivery = useCallback(({
    rental_type,
    delivery_location,
    specific_delivery_location_id,
    specific_delivery_location,
    valid_location,
    error
  }) => {

    if (error) {
      setError({ status: true, message: error.message });
    }

    setState((previousState) => ({
      ...previousState,
      rental_type,
      delivery_location,
      specific_delivery_location_id,
      specific_delivery_location,
      valid_location,
      error
    }));
  }, []);

  const onSelectAddons = useCallback((selected_addons, selected_addons_data) => {
    setState((previousState) => ({
      ...previousState,
      selected_addons,
      selected_addons_data
    }));
  }, []);

  const handleSummaryOnCalculatePrice = useCallback(
    () => { onCalculatePrice(getCalculatePriceParams(calculatePriceStateObject)); },
    [calculatePriceStateObject, onCalculatePrice]
  );

  const onSelectFee = useCallback(({ delivery_fee, rental_fee }) => {
    onCalculatePrice(getCalculatePriceParams(
      calculatePriceStateObject,
      delivery_fee,
      rental_fee
    ));

    setState((previousState) => ({
      ...previousState,
      delivery_fee: Number(delivery_fee),
      rental_fee: Number(rental_fee)
    }));
  }, [calculatePriceStateObject, onCalculatePrice]);

  const handleChangeRenterForm = useCallback((value) => {
    setState((previousState) => ({
      ...previousState,
      ...value
    }));
  }, []);

  const renderStep = (step, steps) => {
    switch (step) {
    case STEP.RV:
      return (
        <SelectVehicle
          state={state}
          onSelectVehicle={onSelectVehicle}
          content={content}
        />
      );
    case STEP.PERIOD:
      return (
        <SelectDates
          state={state}
          onSelectDates={onSelectDates}
        />
      );
    case STEP.LOCATION:
      return (
        <SelectDelivery
          state={state}
          onSelectDelivery={onSelectDelivery}
          onCloseError={onCloseError}
        />
      );
    case STEP.ADDONS:
      return (
        <SelectAddons
          state={state}
          onSelectAddons={onSelectAddons} />
      );
    case STEP.SUMMARY:
      return <Summary
        state={state}
        insuranceData={{
          insuranceId,
          rvId: state?.rv,
          setInsuranceId,
          handleSummaryOnCalculatePrice,
          rental_type: state?.rental_type
        }}
        expiration_date={content.expiration_date?.options[0]?.value}
        onChangeForm={handleChangeRenterForm}
        onCalculatePrice={handleSummaryOnCalculatePrice}
        onSelectFee={onSelectFee}
      />;

    default:
      return null;
    }
  };

  return (
    <>
      {loading && <SpinnerBlur />}
      <Modal
        show={show}
        modalWrapClassnames={classes.wrapper}
        modalClassnames={classes.modal}
        modalCrossButtonClassname={cn(state.currentStep === STEP.LOCATION && classes.crossButtonLocation)}
        onClose={closeClick}
        variant="full-screen"
      >
        <div className={cn("container t-16 t-m-20", classes.container)}>
          <div className="row">
            <div className="col-xxl-10 offset-xxl-1">
              <div className={classes.content}>
                {renderStep(state.currentStep, state.steps)}
              </div>
            </div>
          </div>
        </div>

        <div className="b-60" />

        <StatusFooter
          onCancel={closeClick}
          onBack={previousStepClick}
          onNext={nextStepClick}
          onStartOver={startOverClick}
          onSubmit={submitClick}
          state={state}
        />
      </Modal>


      {
        error.status &&
        <NotificationPopover
          show={error.status}
          status="error"
          text={error.message}
          onClose={onCloseError}
          bottomIndent={{
            hasIndent: true,
            size: 'Xlarge'
          }}
        />
      }

      {
        showStartOverModal &&
        <StartOver
          show={showStartOverModal}
          onClose={startOverCancel}
          onSubmit={onStartOverProcess}
        />
      }
    </>
  );
};

export default PreDefinedRental;
