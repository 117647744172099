import { gql } from "@apollo/client";
import { baseFragments } from "../../../queries/fragments";

export const savePaymentInfo = gql`
  ${baseFragments.paymentInfo}

  mutation savePaymentInfo(
    $brand: String!,
    $country: String!,
    $exp_month: String!,
    $exp_year: String!,
    $holder: String!,
    $number: String!,
    $address: String!,
    $city: String!,
    $state: String,
    $token: String!,
    $zip: String!
  ) {
    payment_info_save(
      brand: $brand,
      country: $country,
      exp_month: $exp_month,
      exp_year: $exp_year,
      holder: $holder,
      number: $number,
      address: $address,
      city: $city,
      state: $state,
      token: $token,
      zip: $zip,
    ) {
      user {
        payment_info {
          ...PaymentInfo
        }
      }
      result {
        success
        errors {
          code
          field
          section
          message
        }
      }
    }
  }
`;
