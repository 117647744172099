import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const saveTempInspection = gql`
  ${baseFragments.result}
  mutation saveTempInspection(
    $id: Int!
    $type: Int!
    $generator_hours: Int
    $mileage: Int
  ) {
    save_temp_inspection(
      rental: $id
      type: $type
      generator_hours: $generator_hours
      mileage: $mileage
    ) {
      result {
        ...Result
      }
    }
  }
`;
