import { useEffect } from "react";

export function useNavExtraLinkHover(ref, classes) {

  useEffect(() => {
    if (ref?.current && classes) {

      const element = ref.current.classList.contains("btn")
        ? ref.current.querySelector(".btn__label")
        : ref.current.classList.contains("btn__label")
          ? ref.current
          : null;

      if (element) {
        const handleClick = ({ target }) => {
          target.classList.add(classes.linkExtraPressed);
        };

        const handleMouseOver = ({ target }) => {
          if (!element.contains(target)) {
            element.classList.remove(classes.linkExtraPressed);
            element.parentNode.blur();
          }
        };

        element.addEventListener('click', handleClick);
        document.addEventListener('mouseover', handleMouseOver);

        return () => {
          element.removeEventListener('click', handleClick);
          document.removeEventListener('mouseover', handleMouseOver);
        };
      }
    }
  }, [ref, classes]);
}
