import React, { useMemo } from "react";
import { FieldInput } from "../../../form/FieldInput";
import Select from "../../../form/Select";
import FieldOptionalInput from "../../../form/FieldOptionalInput";
import { checkFieldIsNotApplicable } from "../checkFieldIsNotApplicable";
import { FieldController } from "../../../form/FieldController/FieldController";
import { RX_INT } from "../../../../utils/constants/RX";
import { isSafeInt } from "../../../../utils/isSafeInt";
import { LabelContent } from "components/ContentSection/LabelContent";
import { Text } from "components/ContentSection/elements";

function getElectricServiceValue(value, listingPublished) {
  const v = String(value).trim();
  if (listingPublished) {
    return (v !== '30' && v !== '50' &&  v !== '0')
      ? '1'
      : value;
  }
  return (v !== '30' && v !== '50' &&  v !== '1')
    ? ''
    : value;
}

const FUEL_DATA = [
  {
    text: "",
    value: "0"
  },
  {
    text: "Gasoline",
    value: "1"
  },
  {
    text: "Diesel",
    value: "2"
  },
  {
    text: "Other",
    value: "3"
  }
];

const ELECTRIC_SERVICE = [
  {
    text: "",
    value: "0"
  },
  {
    text: "N/A",
    value: "1"
  },
  {
    text: "30 Amp 3 Prong",
    value: "30"
  },
  {
    text: "50 Amp 4 Prong",
    value: "50"
  }
];

export const RvDetailsFormContent = ({
  control,
  errors,
  notApplicableOptions,
  listingPublished,
  electricServiceValue
}) => {
  const elServiceValue = useMemo(() => (
    getElectricServiceValue(electricServiceValue, listingPublished)
  ), [electricServiceValue, listingPublished]);

  return (
    <>
      <div className="row t-m-20 b-m-20">
        <div className="col-6 col-m-3 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="length"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (!isSafeInt(parseInt(v, 10)) || parseInt(v, 10) < 1) return "Incorrect input";
              }
            }}
            disabled={checkFieldIsNotApplicable("length", notApplicableOptions)}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="length">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="length"
                    label={label}
                    errors={errors}
                    helperMessage={
                      <Text
                        id="length_description"
                        section="rvDetailsSectionContent"
                      />
                    }
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
        <div className="col-6 col-m-3 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="weight"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (!isSafeInt(parseInt(v, 10)) || parseInt(v, 10) < 1) return "Incorrect input";
              },
              pattern: {
                value: RX_INT,
                message: "Incorrect input"
              }
            }}
            disabled={checkFieldIsNotApplicable("weight", notApplicableOptions)}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="weight">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="weight"
                    label={label}
                    errors={errors}
                    helperMessage={
                      <Text
                        id="weight_description"
                        section="rvDetailsSectionContent"
                      />
                    }
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
        <div className="col-6 col-m-3 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="slide_outs"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (!isSafeInt(parseInt(v, 10))) return "Incorrect input";
              },
              pattern: {
                value: RX_INT,
                message: "Incorrect input"
              }
            }}
            disabled={checkFieldIsNotApplicable(
              "slide_outs",
              notApplicableOptions
            )}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="slide_outs">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="slideOuts"
                    label={label}
                    errors={errors}
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
        <div className="col-6 col-m-3 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="sleeps"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (!isSafeInt(parseInt(v, 10)) || parseInt(v, 10) < 1) return "Incorrect input";
              },
              pattern: {
                value: RX_INT,
                message: "Incorrect input"
              }
            }}
            disabled={checkFieldIsNotApplicable("sleeps", notApplicableOptions)}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="sleeps">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="sleeps"
                    label={label}
                    errors={errors}
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
      </div>
      <div className="row t-m-20 b-m-20">
        <div className="col-m-4 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="fuel_type"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (Number(v) === 0) return "Required";
              }
            }}
            disabled={checkFieldIsNotApplicable(
              "fuel_type",
              notApplicableOptions
            )}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="fuel_type">
                {(label) => {
                  return (
                    <Select
                      className={`fw`}
                      id="fuelType"
                      label={label}
                      options={FUEL_DATA}
                      errors={errors}
                      {...renderProps}
                    />
                  );
                }}
              </LabelContent>
            )}
          />
        </div>
        <div className="col-m-4 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="seatbelts"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (!isSafeInt(parseInt(v, 10))) return "Incorrect input";
              },
              pattern: {
                value: RX_INT,
                message: "Incorrect input"
              }
            }}
            disabled={checkFieldIsNotApplicable(
              "seatbelts",
              notApplicableOptions
            )}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="seatbelts">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="seatBelts"
                    label={label}
                    errors={errors}
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
        <div className="col-m-4 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="electric_service"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (Number(v) === 0) return "Required";
              }
            }}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="electric_service">
                {(label) => {
                  const { value } = renderProps;
                  const isValue = value !== '' && !errors?.electric_service ? 'filled' : '';
                  return (
                    <Select
                      className={`fw ${isValue}`}
                      id="electricService"
                      label={label}
                      options={ELECTRIC_SERVICE}
                      errors={errors}
                      {...renderProps}
                      value={elServiceValue}
                    />
                  );
                }}
              </LabelContent>
            )}
          />
        </div>
      </div>
      <div className="row t-16 t-m-20 b-16 b-m-20">
        <div className="col col-m-6">
          <div>
            <Text
              id="fresh_water_title"
              section="rvDetailsSectionContent"
              component="p"
              variant="body"
            />
            <FieldController
              name="fresh_water"
              control={control}
              disabled={checkFieldIsNotApplicable(
                "fresh_water",
                notApplicableOptions
              )}
              render={({
                value: isAvailable,
                onChange: setAvailable,
                disabled: optionalControllerDisabled
              }) => (
                <FieldController
                  name="fresh_water_tank"
                  control={control}
                  rules={
                    isAvailable
                      ? {
                          required: "Required",
                          validate: (v) => {
                            if (!isSafeInt(parseInt(v, 10)))
                              return "Incorrect input";
                          },
                          pattern: {
                            value: RX_INT,
                            message: "Incorrect input"
                          }
                        }
                      : null
                  }
                  disabled={checkFieldIsNotApplicable(
                    "fresh_water_tank",
                    notApplicableOptions
                  )}
                  render={({ disabled, ...renderProps }) => (
                    <LabelContent
                      section="rvDetailsSectionContent"
                      id="fresh_water"
                    >
                      {(label) => (
                        <FieldOptionalInput
                          id="fresh-water"
                          label={label}
                          helperMessage={
                            <Text
                              id="fresh_water_description"
                              section="rvDetailsSectionContent"
                            />
                          }
                          isAvailable={isAvailable}
                          setAvailable={setAvailable}
                          disabled={optionalControllerDisabled}
                          inputDisabled={disabled}
                          {...renderProps}
                        />
                      )}
                    </LabelContent>
                  )}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row t-16 t-m-20 b-16 b-m-20">
        <div className="col col-m-6">
          <div>
            <Text
              id="black_water_title"
              section="rvDetailsSectionContent"
              component="p"
              variant="body"
            />
            <FieldController
              name="black_water"
              control={control}
              disabled={checkFieldIsNotApplicable(
                "black_water",
                notApplicableOptions
              )}
              render={({
                value: isAvailable,
                onChange: setAvailable,
                disabled: optionalControllerDisabled
              }) => (
                <FieldController
                  name="black_water_tank"
                  control={control}
                  rules={
                    isAvailable
                      ? {
                          required: "Required",
                          validate: (v) => {
                            if (!isSafeInt(parseInt(v, 10)))
                              return "Incorrect input";
                          },
                          pattern: {
                            value: RX_INT,
                            message: "Incorrect input"
                          }
                        }
                      : null
                  }
                  disabled={checkFieldIsNotApplicable(
                    "black_water_tank",
                    notApplicableOptions
                  )}
                  render={({ disabled, ...renderProps }) => (
                    <LabelContent
                      section="rvDetailsSectionContent"
                      id="black_water"
                    >
                      {(label) => (
                        <FieldOptionalInput
                          id="black-water"
                          label={label}
                          helperMessage={
                            <Text
                              id="black_water_description"
                              section="rvDetailsSectionContent"
                            />
                          }
                          isAvailable={isAvailable}
                          setAvailable={setAvailable}
                          disabled={optionalControllerDisabled}
                          inputDisabled={disabled}
                          {...renderProps}
                        />
                      )}
                    </LabelContent>
                  )}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row t-32 t-m-40 b-16 b-m-20">
        <div className="col col-m-6">
          <Text
            id="gray_water_title"
            section="rvDetailsSectionContent"
            component="p"
            variant="body"
          />
          <FieldController
            name="gray_water"
            control={control}
            disabled={checkFieldIsNotApplicable(
              "gray_water",
              notApplicableOptions
            )}
            render={({
              value: isAvailable,
              onChange: setAvailable,
              disabled: optionalControllerDisabled
            }) => (
              <FieldController
                name="gray_water_tank"
                control={control}
                rules={
                  isAvailable
                    ? {
                        required: "Required",
                        validate: (v) => {
                          if (!isSafeInt(parseInt(v, 10)))
                            return "Incorrect input";
                        },
                        pattern: {
                          value: RX_INT,
                          message: "Incorrect input"
                        }
                      }
                    : null
                }
                disabled={checkFieldIsNotApplicable(
                  "gray_water_tank",
                  notApplicableOptions
                )}
                render={({ disabled, ...renderProps }) => (
                  <LabelContent section="rvDetailsSectionContent" id="gray_water">
                    {(label) => (
                      <FieldOptionalInput
                        id="gray-water"
                        label={label}
                        helperMessage={
                          <Text
                            id="gray_water_description"
                            section="rvDetailsSectionContent"
                          />
                        }
                        isAvailable={isAvailable}
                        setAvailable={setAvailable}
                        disabled={optionalControllerDisabled}
                        inputDisabled={disabled}
                        {...renderProps}
                      />
                    )}
                  </LabelContent>
                )}
              />
            )}
          />
        </div>
      </div>
      <div className="row t-16 t-m-20 b-16 b-m-20">
        <div className="col col-m-6">
          <div>
            <Text
              id="propane_title"
              section="rvDetailsSectionContent"
              component="p"
              variant="body"
            />
            <FieldController
              name="propane"
              control={control}
              disabled={checkFieldIsNotApplicable(
                "propane",
                notApplicableOptions
              )}
              render={({
                value: isAvailable,
                onChange: setAvailable,
                disabled: optionalControllerDisabled
              }) => (
                <FieldController
                  name="propane_tank"
                  control={control}
                  rules={
                    isAvailable
                      ? {
                          required: "Required",
                          validate: (v) => {
                            if (!isSafeInt(parseInt(v, 10)))
                              return "Incorrect input";
                          },
                          pattern: {
                            value: RX_INT,
                            message: "Incorrect input"
                          }
                        }
                      : null
                  }
                  disabled={checkFieldIsNotApplicable(
                    "propane_tank",
                    notApplicableOptions
                  )}
                  render={({ disabled, ...renderProps }) => (
                    <LabelContent section="rvDetailsSectionContent" id="propane">
                      {(label) => (
                        <FieldOptionalInput
                          id="propane"
                          label={label}
                          helperMessage={
                            <Text
                              id="propane_description"
                              section="rvDetailsSectionContent"
                            />
                          }
                          isAvailable={isAvailable}
                          setAvailable={setAvailable}
                          disabled={optionalControllerDisabled}
                          inputDisabled={disabled}
                          {...renderProps}
                        />
                      )}
                    </LabelContent>
                  )}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row t-16 t-m-20 b-16 b-m-20">
        <div className="col">
          <Text
            id="other_details_title"
            section="rvDetailsSectionContent"
            component="h6"
            size="m"
            variant="subtitle"
          />
        </div>
      </div>
      <div className="row t-m-20 b-m-40">
        <div className="col-m-4 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="vin"
            control={control}
            disabled={checkFieldIsNotApplicable("vin", notApplicableOptions)}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="vin">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="vin"
                    label={label}
                    helperMessage="(Optional)"
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
        <div className="col-m-4 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="stated_value"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (!isSafeInt(parseInt(v, 10)) || parseInt(v, 10) < 1) return "Incorrect input";
              },
              pattern: {
                value: RX_INT,
                message: "Incorrect input"
              }
            }}
            disabled={checkFieldIsNotApplicable(
              "stated_value",
              notApplicableOptions
            )}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="stated_value">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="statedValue"
                    label={`${label}, $`}
                    errors={errors}
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
        <div className="col-m-4 t-16 t-m-0 b-16 b-m-0">
          <FieldController
            name="current_miles"
            control={control}
            rules={{
              required: "Required",
              validate: (v) => {
                if (!isSafeInt(parseInt(v, 10)) || parseInt(v, 10) < 1) return "Incorrect input";
              },
              pattern: {
                value: RX_INT,
                message: "Incorrect input"
              }
            }}
            disabled={checkFieldIsNotApplicable(
              "current_miles",
              notApplicableOptions
            )}
            render={(renderProps) => (
              <LabelContent section="rvDetailsSectionContent" id="current_miles">
                {(label) => (
                  <FieldInput
                    className="fw"
                    id="currentMiles"
                    label={label}
                    errors={errors}
                    {...renderProps}
                  />
                )}
              </LabelContent>
            )}
          />
        </div>
      </div>
    </> );
};
