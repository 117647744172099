import { useMemo } from "react";
import epmtyImgTemplate from "assets/images/rv-default-placeholder.jpg";

function fillPhotos(max, photos) {
  const updatedPhotos = [...photos];
  for(let i = photos.length; i < max; i++) updatedPhotos.push(epmtyImgTemplate);

  return updatedPhotos;
}

export function useFillGridPhotosTemplates(max, photos) {
  const updatedPhotos = useMemo(() => {
    if (photos.length < max) return fillPhotos(max, photos);
    return [...photos].slice(0, max);
  }, [max, photos]);

  return updatedPhotos;
}
