import React, { useEffect, useState, useCallback } from "react";
import { ReactComponent as IconQuestion } from "assets/images/icon-question.svg";
import Tooltip from "components/Tooltip/Tooltip";
import Typography from "components/Typography";

import classes from './ReferralsList.module.css';
import ReferralsListItem from "../ReferralsListItem/ReferralsListItem";

const ReferralsList = ({ content, referrals }) => {
  if (!referrals.length) {
    return (
      <div className="t-16 t-m-4 t-xl-16">
        <Typography
          variant="subtitle"
          size="s"
          className={classes.empty}
        >
          {content.referralsListEmpty}
        </Typography>
      </div>
    );
  }

  return (
    <div className="b-16 t-8 b-m-20 t-m-8 t-xl-20 mt-2 mt-m-0">
      <div className={classes.header}>
        <div className={classes.headerRow}>
          <Typography
            variant="body"
            size="l"
            className={classes.headerText}
          >
            {content.referralsListHeaderText}
          </Typography>
        </div>
        <div className={classes.headerRow}>
          <Typography
            variant="body"
            size="l"
            className={classes.headerText}
          >
            <>
              {content.referralsListHeaderEarn}
              <Tooltip
                tooltipContainerClassName={classes.tooltipQuestionContainer}
                trigger={['hover']}
                tooltipContent={(
                  <Typography
                    variant="body"
                    size="l"
                    className={classes.tooltipQuestionContainerText}
                  >
                    {content.referralsListHeaderTooltip}
                  </Typography>
                )}
              >
                {(ref) => (
                  <span className={`${classes.btnIcon} dib`} ref={ref}>
                    <IconQuestion />
                  </span>
                )}
              </Tooltip>
            </>
          </Typography>
        </div>
      </div>

      <div className="devider-solid mt-12 mb-12 mt-m-16 mb-m-16" />

      <div className={classes.body}>
        {referrals && referrals.map(
          (referral, index) => <ReferralsListItem referral={referral} index={index} key={referral.referralId} />
        )}
      </div>
    </div>
  );
};

export default ReferralsList;
