import React, { useEffect, forwardRef, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useJqueryComponent } from "../../../hooks";
import { createCachedSelector } from "re-reselect";

function renderHint(hint) {
  if (!hint) {
    return null;
  }

  return <div className="field-input__hint">{hint}</div>;
}

function renderOptions(options) {
  return (
    options &&
    options.map(({ value, text }) => {
      return (
        <option key={value} value={value}>
          {text}
        </option>
      );
    })
  );
}

const optionSelector = createCachedSelector(
  (id, forwardedRef) => forwardedRef,
  (id, forwardedRef, select) => select,
  (forwardedRef, select) => ({
    forwardedRef,
    select
  })
)((id) => id);

const Select = forwardRef((props, forwardedRef) => {
  const {
    id,
    className,
    classNameLabel,
    disabled,
    label,
    placeholder,
    value,
    helperMessage,
    options,
    pinnedOptions,
    onChange,
    hasError,
    errorMessage,
    ...selectProps
  } = props;

  const handleSelect = useCallback(
    (e, { item }) => {
      onChange(item.value);
    },
    [onChange]
  );

  const [ref] = useJqueryComponent(
    "Select",
    optionSelector(id, forwardedRef, handleSelect)
  );

  const classNames = classnames(
    "field-input",
    {
      filled: (value && value.length > 0) || placeholder,
      disabled,
      error: hasError
    },
    className
  );

  const hintElement = renderHint(hasError ? errorMessage : helperMessage);

  useEffect(() => {
    // TODO: implement disable
  }, [ref, disabled]);

  return (
    <div className={classNames}>
      <select
        className="field-input__field"
        id={id}
        ref={ref}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        {...selectProps}
      >
        {pinnedOptions && pinnedOptions.length ? (
          <>
            <optgroup label="Pinned options">
              {renderOptions(pinnedOptions)}
            </optgroup>
            <optgroup label="Common options">{renderOptions(options)}</optgroup>
          </>
        ) : (
          renderOptions(options)
        )}
      </select>
      {label && (
        <label
          htmlFor={id}
          className={`field-input__label ${classNameLabel}`}
        >
          {label}
        </label>
      )}
      {hintElement && <div className="field-input__helpers">{hintElement}</div>}
    </div>
  );
});

Select.displayName = "Select";

Select.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  helperMessage: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Select;
