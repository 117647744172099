import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Typography from "../../../../../components/Typography";
import RentalMileageItem from "./RentalMileageItem";
import RentalMileageItemOverage from "./RentalMileageItemOverage";
import Tag from "../../../../../components/Tag";
import RequestCollapse from "../RequestHistory/RequestCollapse";
import RentalMileageMobile from "./RentalMileageMobile";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import AddMileageModal from "processes/Rentals/RentalDetails/components/RentalMileage/AddMileageModal";
import { ModalInspections } from "components/ModalInspections/ModalInspections";
import Button from "components/Button";
import classnames from "classnames";
import { support } from "utils/constants/support";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { RENTAL_INSPECTION_TYPES } from "utils/constants/inspections";
import { getGroups } from "components/ModalInspections/utils";
import { Button as ButtonContent } from "components/ContentSection/elements";
import style from "./style.module.css";

const RentalMileage = ({ inspectionOverageData, rental, onSubmitChange, inspectionsData }) => {
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const [showAddMileage, setShowAddMileage] = useState(false);
  const [addMileageModalData, setAddMileageModalData] = useState({});
  const [showInspectionModal, setShowInspectionModal] = useState(false);
  const [currentInspection, setCurrentInspection] = useState("start");
  const [modalInspectionsKey, setModalInspectionsKey] = useState(1);
  const isShowMileage = inspectionsData?.pickUp?.isShowMileage && inspectionsData?.dropOff?.isShowMileage;
  const isShowGenerator = inspectionsData?.pickUp?.isShowGenerator && inspectionsData?.dropOff?.isShowGenerator;

  const inspectionModalData = useMemo(() => {
    return {
      "start": getGroups(rental?.inspection_pick_up),
      "end": getGroups(rental?.inspection_drop_off)
    };
  }, [rental?.inspection_drop_off, rental?.inspection_pick_up]);

  const inspectionTitleData = {
    'start': "Rental start inspections",
    'end': "Rental end inspections"
  };

  const updateModalInspections = useCallback((currentInspection) => {
    setModalInspectionsKey(key => key + 1);
    setCurrentInspection(currentInspection);
  }, []);

  const preventPropagation = useCallback(() => {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();
    };
  }, []);

  const openAddMileage = () => {
    setShowAddMileage(true);
  };

  const closeAddMileage = () => {
    setShowAddMileage(false);
    onSubmitChange();
  };

  const feeTag = useMemo(() => {
    return !inspectionOverageData?.payment_status ? null : (
      <Tag
        className={style.rentalMileage__feeTagItem}
        text={inspectionOverageData?.payment_status?.value}
        size="small"
        type={
          ["1"].includes(inspectionOverageData?.payment_status?.key)
            ? "warning-dark"
            : "success"
        }
      />
    );
  }, [inspectionOverageData]);

  const mobileSubHeader = () => {
    if (!isMaxSmall) {
      return null;
    }
    if (inspectionOverageData?.payment_status?.key === "2") {
      return null;
    }

    if (inspectionOverageData?.total_fee?.cents_total === 0) {
      return (
        <div
          className={style.inspectionHeaderContainer}
        >
          <Typography variant="body" size="l">
          No overage
          </Typography>
        </div>
      );
    }

    if (inspectionOverageData?.payment_status?.key === "1") {
      return (
        <div>
          <div>
            <Typography
              variant="body"
              size="l"
              className=""
            >
              Additional fee: <FormattedPrice value={inspectionOverageData?.total_fee} isBold />
            </Typography>
          </div>
          <div className={classnames(style.rentalMileage__feeTag, "df t-8 b-8")}>
            {feeTag}
          </div>
        </div>
      );
    }

    if (inspectionsData?.dropOff?.submitMode && !inspectionsData?.pickUp?.submitMode) {
      return (
        <div
          onClick={preventPropagation()}
          className={style.inspectionHeaderContainer}
        >
          <Button
            ghost
            disabled={inspectionsData?.dropOff?.buttonDisabled}
            label={"Submit end rental values"}
            className={classnames(style.subheaderButton, 'underline')}
            onClick={() => {
              setShowAddMileage(true);
              setAddMileageModalData({
                type: RENTAL_INSPECTION_TYPES.end,
                title: "Submit end rental values",
                isShowMileage,
                isShowGenerator,
                mileageMinValue: inspectionsData?.dropOff?.mileageMinValue,
                generatorMinValue: inspectionsData?.dropOff?.generatorMinValue,
                mileageMinError: inspectionsData?.dropOff?.mileageMinError,
                generatorMinError: inspectionsData?.dropOff?.generatorMinError
              });
            }}
          />
        </div>
      );
    }

    if (inspectionsData?.pickUp?.submitMode) {
      return (
        <div
          onClick={preventPropagation()}
          className={style.inspectionHeaderContainer}
        >
          <Button
            ghost
            label={"Submit start rental values"}
            className={classnames(style.subheaderButton, 'underline')}
            onClick={() => {
              setShowAddMileage(true);
              setAddMileageModalData({
                type: RENTAL_INSPECTION_TYPES.start,
                title: "Submit start rental values",
                isShowMileage,
                isShowGenerator,
                mileageMinValue: inspectionsData?.pickUp?.mileageMinValue,
                generatorMinValue: inspectionsData?.pickUp?.generatorMinValue,
                mileageMinError: inspectionsData?.pickUp?.mileageMinError,
                generatorMinError: inspectionsData?.pickUp?.generatorMinError
              });
            }}
          />
        </div>
      );
    }

    if (rental?.inspection_pick_up?.is_full || rental?.inspection_drop_off?.is_full) {
      return (
        <div
          onClick={preventPropagation()}
          className={style.inspectionHeaderContainer}
        >
          <Button
            ghost
            label={"Show inspection details"}
            className={classnames(style.subheaderButton, 'underline')}
            onClick={() => {
              setShowInspectionModal(true);
              setAddMileageModalData({
                type: RENTAL_INSPECTION_TYPES.start,
                title: "Submit start rental values",
                isShowMileage,
                isShowGenerator,
                mileageMinValue: inspectionsData?.pickUp?.mileageMinValue,
                generatorMinValue: inspectionsData?.pickUp?.generatorMinValue,
                mileageMinError: inspectionsData?.pickUp?.mileageMinError,
                generatorMinError: inspectionsData?.pickUp?.generatorMinError
              });
            }}
          />
        </div>
      );
    }

    return null;
  };

  const supportLink = useMemo(() => {
    return (
      <Typography variant="body" size="l" component="h2" weight="normal">
        Made a mistake? &nbsp;
        <ButtonContent
          id="rv_rentals_support_email"
          section="linksContent"
          label={(
            <span className="fw-400 underline">Contact support</span>
          )}
          ghost
          newTab
          prefixLink="mailto:"
          className={classnames(style.outerLink)}
        />
      </Typography>
    );
  }, []);

  return (
    <div className={classnames(style.rentalMileage, "t-8 t-m-0 b-8 b-m-20")}>
      {isMaxSmall && (
        <RequestCollapse
          header="Inspections"
          subheader={mobileSubHeader()}
          iconClassName={style.inspectionHeaderArrow}
          iconCloseStateClassName={style.iconHeaderCloseState}
          content={
            <RentalMileageMobile
              inspectionOverageData={inspectionOverageData}
              rental={rental}
              currentInspection={currentInspection}
              inspectionsData={inspectionsData}
              feeTag={feeTag}
              mileageOverageValue={inspectionOverageData?.mileage}
              mileageFeeValue={inspectionOverageData?.mileage_fee?.dollars_as_part}
              generatorOverageValue={inspectionOverageData?.generator}
              generatorFeeValue={inspectionOverageData?.generator_fee?.dollars_as_part}
              isShowMileageOverage={inspectionOverageData?.mileage !== null}
              isShowGeneratorOverage={inspectionOverageData?.generator !== null}
              supportLink={supportLink}
              onSubmitChange={onSubmitChange}
              openAddMileage={openAddMileage}
              setCurrentInspection={setCurrentInspection}
              setAddMileageModalData={setAddMileageModalData}
              setShowInspectionModal={setShowInspectionModal}
            />
          }
        />
      )}
      {!isMaxSmall && (
        <>
          <div
            className={classnames(
              style.rentalMileageHeader,
              'mb-m-40'
            )}
          >
            <Typography
              variant="headline"
              size="s"
              weight="bold"
              className={style.rentalMileageHeaderTitle}
            >
              Inspections
            </Typography>
            <div className="mt-m-16 mt-xl-28">
              {supportLink}
            </div>
          </div>
          {inspectionOverageData && inspectionOverageData?.total_fee && (
            <div className={style.titleAdditionalFeeContainer}>
              <div className="">
                <Typography
                  variant="subtitle"
                  size="m"
                  className={style.titleAdditionalFee}
                >
                  <span className="mr-m-8">Additional fee</span> <FormattedPrice value={inspectionOverageData?.total_fee} isBold />
                </Typography>
              </div>
              <div className="">
                {feeTag}
              </div>
            </div>
          )}
          <div className={classnames("b-20", style.rentalMileage__wrap)}>
            <div
              className={classnames(
                style.rentalMileageItems,
                inspectionOverageData && style.isHaveOverage
              )}
            >

              <RentalMileageItem
                type={RENTAL_INSPECTION_TYPES.start}
                inspectionsData={inspectionsData.pickUp}
                openAddMileage={openAddMileage}
                setCurrentInspection={setCurrentInspection}
                setAddMileageModalData={setAddMileageModalData}
                setShowInspectionModal={setShowInspectionModal}
              />

              <RentalMileageItem
                type={RENTAL_INSPECTION_TYPES.end}
                inspectionsData={inspectionsData?.dropOff}
                openAddMileage={openAddMileage}
                setCurrentInspection={setCurrentInspection}
                setAddMileageModalData={setAddMileageModalData}
                setShowInspectionModal={setShowInspectionModal}
              />


              {inspectionOverageData && (
                <RentalMileageItemOverage
                  title="Overage"
                  mileageOverageValue={inspectionOverageData?.mileage}
                  mileageFeeValue={inspectionOverageData?.mileage_fee?.dollars_as_part}
                  generatorOverageValue={inspectionOverageData?.generator}
                  generatorFeeValue={inspectionOverageData?.generator_fee?.dollars_as_part}
                  isShowMileage={inspectionOverageData?.mileage !== null}
                  isShowGenerator={inspectionOverageData?.generator !== null}
                />
              )}
            </div>
          </div>
          <div className="mt-m-12">
            <Typography
              variant="body"
              size="l"
            >
              No Good Sam mobile app? {" "}
              <ButtonContent
                id="download_mobile_app_for_ios"
                section="linksContent"
                label="Download for iOS"
                ghost
                newTab
                className={classnames(style.outerLink, 'underline')}
              />
              <span>/</span>
              <ButtonContent
                id="download_mobile_app_for_android"
                section="linksContent"
                label="Android"
                ghost
                newTab
                className={classnames(style.outerLink, 'underline')}
              />
            </Typography>
          </div>
        </>
      )}
      <ModalInspections
        key={modalInspectionsKey}
        show={showInspectionModal}
        groups={inspectionModalData[currentInspection]}
        isStartInspection={rental?.inspection_pick_up?.is_full}
        isEndInspection={rental?.inspection_drop_off?.is_full}
        title={inspectionTitleData[currentInspection]}
        currentInspection={currentInspection}
        setCurrentInspection={setCurrentInspection}
        updateModalInspections={updateModalInspections}
        onClose={() => setShowInspectionModal(false)}
      />
      <AddMileageModal
        {...addMileageModalData}
        showModal={showAddMileage}
        onClose={closeAddMileage}
      />
    </div>
  );
};

RentalMileage.propTypes = {
  data: PropTypes.object.isRequired
};

export default RentalMileage;
