import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import classes from "./PhotoUploadArea.module.css";

export const PhotoUploadArea = ({ extended, onUpload, disabled }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onUpload(acceptedFiles);
    },
    [onUpload]
  );

  const { open, getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"]
    },
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled
  });

  const handleAreaClick = () => {
    if (window.Modernizr && window.Modernizr.touchevents) {
      open();
    }
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    open();
  };

  return (
    <div
      {...getRootProps(
        extended
          ? { className: classes.containerNoImg }
          : { className: classes.containerImgAdded }
      )}
    >
      <div
        className={
          isDragAccept
            ? `${classes.acceptedInner1} photoUploadAreaInner1`
            : `${classes.inner1} photoUploadAreaInner1`
        }
        onClick={handleAreaClick}
      >
        <div
          className={
            isDragAccept
              ? `${classes.acceptedInner2} photoUploadAreaInner2`
              : `${classes.inner2} photoUploadAreaInner2`
          }
        >
          <input {...getInputProps()} />
          <p className={classes.text}>
            <span className="dn di-l">Drag and drop photos or browse to </span>
            <span className={classes.button} onClick={handleButtonClick}>
              <span className="dn dib-l underline">choose files</span>
              <span className="di dn-l underline">Add photos</span>
            </span>
          </p>
          {extended && <span className={classes.uploadIcon} />}
          <p className={classes.hint}>Allowed file types: JPG, JPEG, PNG</p>
        </div>
      </div>
    </div>
  );
};

PhotoUploadArea.defaultProps = {
  disabled: false
};
