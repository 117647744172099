import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const rvDetailsMutation = gql`
  ${fragments.rvDetails}

  mutation rvUpdate(
    $id: Int!
    $length: String!
    $weight: Int!
    $slide_outs: Int!
    $sleeps: Int!
    $fuel_type: String!
    $seatbelts: Int!
    $force: Boolean!
    $electric_service: Int
    $gray_water: Boolean!
    $gray_water_tank: Int
    $black_water: Boolean!
    $black_water_tank: Int
    $fresh_water: Boolean!
    $fresh_water_tank: Int
    $propane: Boolean!
    $propane_tank: Int
    $vin: String
    $stated_value: Int
    $current_miles: Int!
  ) {
    rv_update(
      step: "rv_details"
      id: $id
      length: $length
      weight: $weight
      slide_outs: $slide_outs
      sleeps: $sleeps
      fuel_type: $fuel_type
      seatbelts: $seatbelts
      force: $force
      electric_service: $electric_service
      gray_water: $gray_water
      gray_water_tank: $gray_water_tank
      black_water: $black_water
      black_water_tank: $black_water_tank
      fresh_water: $fresh_water
      fresh_water_tank: $fresh_water_tank
      propane: $propane
      propane_tank: $propane_tank
      vin: $vin
      stated_value: $stated_value
      current_miles: $current_miles
    ) {
      result {
        success
        errors {
          field
          message
          code
        }
      }
      rv {
        id
        listing_steps_completed
        fields_awaiting_moderation
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        status {
          key
          value
        }
        class
        make
        model
        year
        ...RvDetails
        daily_rate {
          cents_total
        }
        suggested_daily_rate {
          user_friendly
        }
      }
    }
  }
`;
