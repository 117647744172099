import { gql } from "@apollo/client";

export const rvDelete = gql`
  mutation rv_delete($id: Int!) {
    rv_delete(id: $id) {
      result {
        success
        errors {
          field
          message
        }
      }
    }
  }
`;
