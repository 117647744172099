import React from "react";
import Typography from "components/Typography";
import { Group } from "components/ModalInspections";
import classes from "../../ModalInspections.module.css";
import cn from "classnames";

export const Groups = ({ groups = [] }) => (
  <div className={cn('row')}>
    { groups.map((group = {}, groupIndex) => {
      const {
        isFullWidth = false,
        isWidthTwoThirds = false,
        items, id,
        disp_title: {
          value: title
        } = {}
      } = group;

      return (
        <div
          key={id}
          className={cn(
            'mb-16 mb-m-20',
            !isFullWidth && !isWidthTwoThirds && 'col-12 col-m-6 col-l-4',
            isFullWidth && 'col-12',
            isWidthTwoThirds && 'col-12 col-l-8',
          )}
        >
          { groupIndex && <div className="devider-solid mt-12 mb-12 dn-m" />}
          {title && (
            <div>
              <Typography
                variant="subtitle"
                size="l"
                weight="bold"
                className={cn('mb-16 mb-m-20', classes.subtitle)}
              >
                {title}
              </Typography>
            </div>
          )}

          <div>
            <Group items={items} />
          </div>
        </div>
      );
    })}
  </div>
);

