import { gql } from "@apollo/client";

export const transactionHistoryQuery = gql`
query (
  $start_year: Int!, 
  $start_month: Int!, 
  $end_year: Int!, 
  $end_month: Int!
) {
    tax_dashboard (
      start_year: $start_year
      start_month: $start_month 
      end_year: $end_year 
      end_month: $end_month
    ) {
      result {
        success
      }
      settings {
        start_year
        start_month
        end_year
        end_month
      }
      upcoming {
        rental_id
        scheduled_date
        owner_earns {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        expected_payout {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        departure_date
        return_date
        nights
        first_name
        last_name
        rv_id
        rv_url
        rv_name
        cleaning_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        disposal_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        add_on_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        delivery_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        insurance_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        mileage_overage_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        mileage_overage
        generator_hours_overage_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        generator_hours_overage
        rental_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        renter_id
        your_share
        total_earning {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        payout_info_pf {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          desc {
            id
            value
            type
          }
          items {
            id
            type
            title {
              id
              value
              type
            }
            action {
              title
              id
              pos
            }
            value {
              id
              value
              type
            }
            units
            style
            separ
            sub_items {
              title {
                id
                value
                type
              } 
              value {
                id
                value
                type
              }
              units
            }
          }
        }
      }
      completed {
        rental_id
        billing_amount {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        billing_date
        billing_subtype
        departure_date
        return_date
        nights
        rv_name
        rv_id
        rv_url
        renter_id
        first_name
        last_name
        payout_count
        cleaning_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        disposal_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        add_on_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        delivery_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        insurance_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        mileage_overage_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        mileage_overage
        generator_hours_overage_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        generator_hours_overage
        rental_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        taxes {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        total_earning {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        owner_earns {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        your_share 
        payout_info_pf {
          total {
            cents_total
            dollars_as_part
            cents_as_part
            user_friendly
          }
          desc {
            id
            value
            type
          }
          items {
            id
            type
            title {
              id
              value
              type
            }
            action {
              title
              id
              pos
            }
            value {
              id
              value
              type
            }
            units
            style
            separ
            sub_items {
              title {
                id
                value
                type
              } 
              value {
                id
                value
                type
              }
              units
            }
          }
        }
      }
    }
  }
`;
