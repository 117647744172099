import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "components/Typography";
import Button from "components/Button";
import { checkIsHtml } from "utils/checkIsHtml";

const NotificationPopover = ({
  show,
  status = 'default',
  variant = 'fixed',
  title = null,
  text = null,
  bottomIndent: {
    hasIndent: hasBottomIndent,
    size: bottomIndentSize
  } = {},
  buttonData = null,
  onClose,
  notificationClassName,
  notificationInnerClassName,
  children
}) => {
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (
        popoverRef.current
        && !popoverRef.current.contains(event.target)
        && variant !== 'static'
      ) {
        onClose();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [onClose, variant]);

  if (!show) return null;

  const notificationPopoverClassName = classnames(
    notificationClassName,
    "notification-popover ",
    variant === 'static' && " notification-popover--variant-static",
    hasBottomIndent && bottomIndentSize && ` notification-popover__bottom-indent-${bottomIndentSize}`
  );

  const notificationPopoverContainer1ClassName = classnames(
    notificationInnerClassName,
    variant === 'static' && `mb-16 mt-16 mb-m-20 mt-m-20`,
    variant === 'fixed' && `r-16 l-16 ml-l-a mr-l-a col-l-10 col-xl-6`
  );

  const notificationPopoverContainer2ClassName = classnames(
    "notification-popover__container",
    variant === 'fixed' && ` l-8 r-8 t-8 b-8`
  );

  const notificationPopoverInnerClassName = classnames(
    " notification-popover__inner l-16 r-32 t-m-0 l-m-20 r-m-32",
    buttonData && "b-8 t-8",
    !buttonData && "t-2 b-16 b-m-16",
    (!status || status === "default") && " notification-popover--default ",
    status === "warning" && " notification-popover--warning ",
    status === "error" && " notification-popover--error",
    status === "success" && " notification-popover--success ",
    status === "info" && " notification-popover--info "
  );

  const notificationPopoverContentClassName = classnames(
    " notification-popover__content",
    buttonData && "b-16 b-m-20"
  );

  const notificationPopoverTextClassName = classnames(
    title && "mt-16 mt-m-12 t-m-0",
    !title && "",
  );

  const notificationPopoverTextHTMLClassName = classnames(
    title && "mt-16 mt-m-12 t-m-0",
    !title && "",
  );

  return (
    <div className={notificationPopoverClassName} ref={popoverRef}>
      <div className={notificationPopoverContainer1ClassName}>
        <div className={notificationPopoverContainer2ClassName}>
          <div className={notificationPopoverInnerClassName}>
            {onClose && <div className="notification-popover__cross" onClick={onClose} />}
            <div
              className={classnames(
                "notification-popover__content-container t-8 t-m-12 df-m"
              )}
            >
              <div className="dn db-m mr-8 mt-m-8">
                <div className="notification-popover__icon" />
              </div>
              <div className={notificationPopoverContentClassName}>
                {title && (
                  <div className="t-8">
                    <Typography
                      variant="subtitle"
                      size="s"
                      className={`notification-popover__title`}
                    >
                      {title}
                    </Typography>
                  </div>
                )}
                {text && (
                  <div>
                    {checkIsHtml(text)
                      ? <div className={notificationPopoverTextHTMLClassName} dangerouslySetInnerHTML={{ __html: text }} />
                      : <Typography
                          variant="body"
                          size="l"
                          className={notificationPopoverTextClassName}
                        >
                          {text}
                        </Typography>
                    }
                  </div>
                )}
                {buttonData && (
                  <div className="t-4 t-m-8">
                    <Button
                      size="medium"
                      secondary
                      {...buttonData}
                      className={`notification-popover__button`}
                    >
                      {buttonData?.text}
                    </Button>
                  </div>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationPopover.propTypes = {
  show: PropTypes.bool,
  variant: PropTypes.oneOf(["fixed", "static"]),
  status: PropTypes.oneOf(["default", "warning", "error", "success", "info"]),
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bottomIndent: PropTypes.shape({
    hasIndent: PropTypes.bool,
    size: PropTypes.oneOf(["small", "large", "Xlarge", "Xlarge-m"])
  }),
  buttonData: PropTypes.shape({
    text: PropTypes.string
  }),
  notificationClassName: PropTypes.string,
  notificationInnerClassName: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node
};

export default NotificationPopover;
