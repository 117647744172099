import React, { useCallback, useMemo } from "react";
import size from "lodash/size";
import { Circle, Marker } from "@react-google-maps/api";
import { getMapCircleOptions, isEqualToMarkerPosition } from "../../../../utils/map";
import { getListItemLatLng } from "../../utils";

const CustomMarker = (props) => {
  const { item, icon, clusterer, onClick, zoom } = props;

  const position = useMemo(() => getListItemLatLng(item), [item]);

  const hasClusters = size(clusterer.clusters) > 0;

  const showCircle = zoom >= 11 && clusterer.clusters?.some(c => {
    if (c.markers?.length !== 1) {
      return false;
    }
    return isEqualToMarkerPosition(c.markers[0], position);
  });

  const onClickCircle = useCallback(() => {
    onClick(position, item, 0, null);
  }, [position, onClick, item]);

  const onClickMarker = useCallback((c) => {
    onClick(c.latLng, item, -24, c.domEvent?.target);
  }, [onClick, item]);

  const markerOptions = useMemo(() => {
    // Passing an item to extract it later:
    return { vehicleItem: item };
  }, [item]);

  return (
    <>
      <Circle
        options={getMapCircleOptions(1, position)}
        onClick={onClickCircle}
        visible={showCircle}
      />
      <Marker
        key={item.id}
        icon={icon}
        position={position}
        clusterer={clusterer}
        onClick={onClickMarker}
        options={markerOptions}
        // Title as a class name (couldn't find a way to add class name directly):
        // title={showCircle || !hasClusters ? "marker-hidden" : "marker-basic"}
        zIndex={showCircle || !hasClusters ? -7771 : -7772}
      />
    </>
  );
};

export default CustomMarker;
