import { gql } from "@apollo/client";

export const additionalInsuranceList = gql`
  query additional_insurance_list(
    $rv: Int!
    $departure_date: String!
    $return_date: String!
    $rental_type: Int!
  ) {
    additional_insurance_list(
      rv: $rv
      departure_date: $departure_date
      return_date: $return_date
      rental_type: $rental_type
    ) {
      description {
        id
        type
        value
      }
      insurances {
        id
        name
        required
        multi_select
        description {
          id
          type
          value
        }
        packages {
          id
          name
          is_available
          is_default
          price
          description {
            id
            type
            value
          }
          value {
            id
            type
            value
          }
        }
        tags
      }
      form_elements {
        type
        label
        tag
      }
      form_header {
        id
        type
        value
      }
      form_footer {
        id
        type
        value
      }
    }
  }
`;
