import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

export const FieldController = ({
  render,
  name,
  control,
  disabled,
  rules,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    rules={disabled ? null : rules}
    render={(renderProps) => {
      return render({ disabled, ...renderProps });
    }}
    {...props}
  />
);

FieldController.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  rules: PropTypes.object
};
