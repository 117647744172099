import { useEffect, useState } from "react";


function calculateScrollShadow(node) {
  const isScroll = node.scrollHeight > node.offsetHeight;
  if (isScroll) {
    const nodeHeight = node.offsetHeight;
    const nodeScrollHeight = node.scrollHeight;
    const nodeScrollTop = node.scrollTop;
    const nodeScrollBottom = nodeScrollHeight - nodeHeight - nodeScrollTop;
    return {
      top: nodeScrollTop > 0,
      bottom: nodeScrollBottom > 1
    };
  }
  return {
    top: false,
    bottom: false
  };
}

function trackScroll(node, callback) {
  let observer = null;

  try {
    observer = new ResizeObserver(() => {
      const scrollData = calculateScrollShadow(node);
      callback(scrollData);
    });

    observer.observe(node);
  } catch (e) {}

  return () => {
    if (observer) {
      observer.unobserve(node);
      observer = null;
    }
  };
}


export function useScrollShadow(ref, loading) {
  const [scrollData, setScrollData] = useState({
    top: false,
    bottom: false
  });

  useEffect(() => {
    function handleScroll() {
      const node = ref.current;
      const data = calculateScrollShadow(node);
      setScrollData(data);
    }

    if (ref?.current) {
      let unsubscribe;
      const node = ref.current;

      if (node) {
        node.addEventListener("scroll", handleScroll);
        unsubscribe = trackScroll(node, (data) => {
          setScrollData(data);
        });
      }
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
        if (node) {
          node.removeEventListener("scroll", handleScroll);
        }
      };

    } else {
    }
  }, [ref, loading]);

  return scrollData;
}
