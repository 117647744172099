import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import Button from "components/Button";
import classes from "./EditorModal.module.css";

export const EditorModal = ({ isOpen, onClose, render }) => {
  useEffect(() => {
    document.body.classList[isOpen ? "add" : "remove"](classes.noScroll);

    return () => {
      document.body.classList.remove(classes.noScroll);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const modal = (
    <div className={classes.modal}>
      <div className={classes.close}>
        <Button
          inverse
          className={classes.closeButton}
          label="Close"
          onClick={onClose}
        />
      </div>
      {render({ onClose })}
    </div>
  );

  return createPortal(modal, document.body);
};
