import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { StepType } from "../../types";
import StepsMenu from "components/StepsMenu/StepsMenu";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import style from "./BookingModalMenu.module.css";

const BookingModalMenu = (props) => {
  const { steps, currentStep, onClickItem, hasScrollShadow } = props;

  const isMediumScreen = useMatchMedia({ minWidth: MQ_BP.medium });
  const isMediumAndMaxHeightSmallScreen = useMatchMedia({ minWidth: MQ_BP.medium, maxHeight: 729.98 });

  return (
    <nav className={classnames(
      style.root,
      hasScrollShadow && style.hasScrollShadow
    )}>
      <div className={style.shadowContainer}></div>
      <div className={style.stepsMenuContainer}>
        <StepsMenu
          onClickStep={onClickItem}
          steps={steps}
          currentStepIndex={currentStep}
          scrollData={{
            isScroll: true,
            isScrollBar: true,
            menuWidth: isMediumScreen && !isMediumAndMaxHeightSmallScreen? '648px' : 'auto'
          }}
        />
      </div>
    </nav>
  );
};

BookingModalMenu.propTypes = {
  steps: PropTypes.arrayOf(StepType),
  currentStep: StepType,
  completedSteps: PropTypes.arrayOf(PropTypes.string),
  onClickItem: PropTypes.func
};

export default BookingModalMenu;
