import React from "react";
import { ReactComponent as GoodSamBadge } from "assets/images/good-sam-badge.svg";
import { RatingInfo } from "processes/RvProfile/components/RatingInfo/RatingInfo";
import Typography from "components/Typography";

export const LocalSliderItem = (props) => {
  const {
    id,
    address = "",
    description = '',
    goodSamBadge,
    rating = 0,
    ratingValue = '',
    classes = {},
    img: {
      src = '',
      alt = ''
    } = {}
  } = props;
  return (
    <div className={`${classes.localSliderItem} mt-4 mb-4`}>
      <div className={`${classes.localSliderItemInner} t-4 t-m-8`}>

        <div className={`${classes.localSliderImageContainer} ml-4 mr-4 ml-m-8 mr-m-8`}>
          <img src={src} alt={alt} className={classes.localSliderImage}/>
          {goodSamBadge && (
            <div className={classes.localSliderGoodSamBadge}>
              <GoodSamBadge />
            </div>
          )}
          <div className={classes.localSliderAddressContainer}>
            <Typography
              variant="body"
              size="l"
              className={classes.localSliderAddress}
            >
              Boston, MA
            </Typography>
            <Typography
              variant="headline"
              size="m"
              weight="bold"
              className={classes.localSliderPlace}
            >
              Catskill Park
            </Typography>
          </div>
        </div>

        <div className="l-20 t-8 b-8 t-m-2 b-m-4 t-xl-24 b-xl-24">
          <RatingInfo extraInfoText="Reviews" />
        </div>
      </div>
    </div>
  );
};

// LocalSliderItem.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

