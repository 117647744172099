import React from "react";
import Typography from "components/Typography";
import { TabSlider } from "processes/RvProfile/components/TabSliderContainer/TabSlider/TabSlider";
import { TabSliderContainer } from "processes/RvProfile/components/TabSliderContainer/TabSliderContainer";
import Button from "components/Button";
import classes from "./TipsAndTricksSection.module.css";

const sliderData = [
  {
    id: '1',
    photo: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce83e3c2.jpg',
    title: '111 What is Camping World RV Rentals RV delivery?',
    description: '111 Turns out  not the only thing you can have delivered. Camping World RV Rentals owners all over the US offer RV delivery straight to your site!  how it works. Lorem ipsum'
  },
  {
    id: '2',
    photo: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce8b5ff7.jpg',
    title: '222 What is Camping World RV Rentals RV delivery?',
    description: '222 Turns out  not the only thing you can have delivered. Camping World RV Rentals owners all over the US offer RV delivery straight to your site!  how it works. Lorem ipsum'
  },
  {
    id: '3',
    photo: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce8decfe.jpg',
    title: '333 What is Camping World RV Rentals RV delivery?',
    description: '333 Turns out  not the only thing you can have delivered. Camping World RV Rentals owners all over the US offer RV delivery straight to your site!  how it works. Lorem ipsum'
  },
  {
    id: '4',
    photo: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daace1f5.jpg',
    title: '444 What is Camping World RV Rentals RV delivery?',
    description: '444 Turns out  not the only thing you can have delivered. Camping World RV Rentals owners all over the US offer RV delivery straight to your site!  how it works. Lorem ipsum'
  },
  {
    id: '5',
    photo: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daaac947.jpg',
    title: '555 What is Camping World RV Rentals RV delivery?',
    description: '555 Turns out  not the only thing you can have delivered. Camping World RV Rentals owners all over the US offer RV delivery straight to your site!  how it works. Lorem ipsum'
  }
];

const menuData = [
  {
    id: '1',
    title: '3 Types of RV Showers'
  },
  {
    id: '2',
    title: 'What is RV Delivery?'
  },
  {
    id: '3',
    title: 'Air Conditioner and Heater Operation'
  },
  {
    id: '4',
    title: 'How to Handle Propane Tanks'
  },
  {
    id: '5',
    title: 'Lorem ipsum'
  }
];

export const TipsAndTricksSection = () => {

  return (
    <div className={`container ${classes.container}`}>
      <div className="fbc mb-32 mb-m-40">
        <Typography variant="subtitle" size="l" weight="bold">
        Tips and Tricks
        </Typography>

        <div className="t-4 dn-m">
          <Button
            ghost
            label="View All"
            onClick={function noRefCheck(){}}
          />
        </div>
      </div>
      <div className="dn-m"><TabSlider data={sliderData} /></div>
      <div className="dn db-m"><TabSliderContainer contentData={sliderData} menuData={menuData} /></div>
    </div>
  );
};

// TipsAndTricksSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

