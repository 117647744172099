import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const acceptPredefinedSuggestion = gql`
  ${baseFragments.result}
  mutation acceptPredefinedSuggestion(
    $id: Int!
    $insurance_package: Int
    $good_sam_membership_package: String
    $coupon_code: String
    $signature_name: String
    $date_of_birth: String
    $no_split_payments: Boolean
    $additional_insurances: [Int]
    $tags: [TagTypeInputPredefined]
  ) {
    accept_predefined_suggestion(
      id: $id
      insurance_package: $insurance_package
      good_sam_membership_package: $good_sam_membership_package
      coupon_code: $coupon_code
      signature_name: $signature_name
      date_of_birth: $date_of_birth
      no_split_payments: $no_split_payments
      additional_insurances: $additional_insurances
      tags: $tags
  ) {
      result {
        ...Result
      }
    }
  }
`;
