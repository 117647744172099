import React, { useCallback, useEffect, useState } from "react";
import Typography from "components/Typography";
import { BigDayPicker } from "components/BigDayPicker/BigDayPicker";
import { renderDay } from "processes/Booking/components/Calendar/renderDay";
import { HeaderInterval } from "processes/Booking/components/Calendar/elements/HeaderInterval";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

import classes from './SelectDates.module.css';

const SelectDates = ({ state, onSelectDates }) => {

  const isSizeLarge = useMatchMedia({ minWidth: MQ_BP.large });

  const [{ from, to }, setPeriod] = useState({
    from: state.departure_date,
    to: state.return_date
  });

  const handleOnChange = useCallback((values) => {
    setPeriod({ from: values.from, to: values.to });
    onSelectDates(values.from, values.to);
  }, [onSelectDates]);

  const extraData = {
    extraClasses: classes,
    extraDisabledDays: { before: new Date() }
  };

  const fromMonth = new Date();

  const resetPeriod = () => {
    setPeriod({ from: undefined, to: undefined });
  };

  return (
    <div className={classes.wrapper}>
      <Typography
        component="h2"
        variant="headline"
        size="m"
        className={classes.title}
      >
        Departure/Return
      </Typography>

      <div className={classes.header}>
        <div className={classes.interval}>
          <HeaderInterval
            from={from}
            to={to}
            resetPeriod={resetPeriod} />
        </div>

        <Typography variant="body" size="l" className={classes.minDuration}>
          {`${state.minimum_duration} night(s) minimum`}
        </Typography>
      </div>

      <div className={classes.calendar}>
        <BigDayPicker
          extraData={extraData}
          initialMonth={from}
          fromMonth={fromMonth}
          isDisabledPrevDates={true}
          numberOfMonths={isSizeLarge ? 2 : 1}
          value={{ from, to }}
          onChange={handleOnChange}
          availability={state.availability}
          filterBusyDays={() => []}
          renderDay={(day, daysData) =>
            renderDay(
              day,
              daysData,
              state.dailyRateCentsTotal,
              state.dailyRateDollarAsPart,
              classes
            )
          }
        />
      </div>
    </div>
  );
};

export default SelectDates;
