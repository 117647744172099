import { gql } from "@apollo/client";

export const userAccountNumberQuery = gql`  
  query {
    user {
      bank_account {
        account_number
      }
    }
  }
`;
