export function getClasses(allCards = []) {
  let classNames = allCards.map(({ cl }) => cl);
  classNames = [...new Set(classNames)];
  const classes = classNames.map((cls, index) => {
    return {
      id: index,
      name: cls
    };
  });
  return classes;
}

export function getLocation(allCards = []) {
  let locations = allCards.map(({ lc }) => lc.replace('Delivered from ', ''));
  locations = [...new Set(locations)];
  return locations.map((lc, index) => {
    return {
      id: index,
      name: lc
    };
  });
}
