import { useEffect } from "react";

const properties = [
  {
    name: '--widget-background-color',
    color: '#474342'
  },
  {
    name: '--widget-text-border-color',
    color: '#fff'
  },
  {
    name: '--widget-card-popup-color',
    color: '#2C2A29'
  },
  {
    name: '--widget-checkbox-color',
    color: '#fff'
  },
  {
    name: '--widget-pressed-button-color',
    color: '#565453'
  },
  {
    name: '--widget-selected-dates-color',
    color: '#D7E6D5'
  },
  {
    name: '--widget-border-dates-color',
    color: '#747272'
  },
  {
    name: '--widget-text-border-2-color',
    color: '#BFC5CE'
  },
  {
    name: '--widget-text-color',
    color: '#EAECEF'
  },
  {
    name: '--widget-past-dates-color',
    color: '#666464'
  }
];

function setProperties() {
  properties.forEach(({ name, color }) => {
    document.documentElement.style.setProperty(name, color);
  });
}

function removeProperties() {
  properties.forEach(({ name }) => {
    document.documentElement.style.removeProperty(name);
  });
}

export function useTheme(theme) {
  useEffect(() => {
    if (theme === 'dark') {
      setProperties();
    } else {
      removeProperties();
    }
  }, [theme]);
}
