import { useCallback, useEffect, useState } from "react";

const useGetElementContentScroll = ({ wait = 200 } = {}) => {
  const [scroll, setScroll] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    let throttleTimeout = null;

    const handleScroll = () => {
      if (node) {
        setScroll({
          top: node.scrollTop,
          left: node.scrollLeft,
          right: node.scrollWidth - node.clientWidth - node.scrollLeft,
          isLeftScrollOffset: node.scrollLeft > 0,
          isRightScrollOffset: (node.scrollWidth - node.clientWidth - node.scrollLeft) > 0
        });
      }
      throttleTimeout = null;
    };

    const debouncedScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(handleScroll, wait);
        }
      } else {
        handleScroll();
      }
    };

    const addEvents = () => {
      if (node) {
        node.addEventListener("scroll", debouncedScroll);
      }
      window.addEventListener("resize", debouncedScroll);
      window.addEventListener("orientationchange", debouncedScroll);
    };

    const removeEvents = () => {
      if (node) {
        node.removeEventListener("scroll", debouncedScroll);
      }
      window.removeEventListener("resize", debouncedScroll);
      window.removeEventListener("orientationchange", debouncedScroll);
    };

    handleScroll();
    addEvents();

    return () => {
      removeEvents();
      if (throttleTimeout) {
        clearTimeout(throttleTimeout);
      }
    };
  }, [node, wait]);

  return [ref, scroll];
};

export default useGetElementContentScroll;
