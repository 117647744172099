import React, { useState } from "react";
import { ReactComponent as GoodsamLogo } from "./goodsam-logo.svg";
import Checkbox from "components/form/Checkbox";
import Typography from "components/Typography";
import Button from "components/Button";
import cn from 'classnames';
import classes from "./Checkout.module.css";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { PriceForm } from "components/PriceForm";

const Checkout = ({
  priceTable,
  onNextStep,
  onPrevStep,
  onClose,
  disabled,
  isFooterFixed
}) => {

  const total = priceTable?.total;

  const [isTermChecked, setIiTermChecked] = useState(false);

  return (
    <div className={`${classes.checkout}`}>
      <div className={`${classes.checkoutInner}`}>
        <div className="">
          <PriceForm data={[priceTable]} />
          <div className={`${classes.checkoutTerms} mt-16 mt-m-20 mb-16 mb-m-20`}>
            <Checkbox
              id="checkboxTerm"
              name="agree"
              checked={isTermChecked}
              html='<p>I have read and accept Good Sam RV Rentals <a href="/terms-of-use">Terms of Service</a>.</p>'
              onChange={() => {
                setIiTermChecked(!isTermChecked);
              }}
            />
          </div>

          <div
            className={cn(
              classes.checkoutButtonConfirm,
              isFooterFixed && classes.footerFixed,
              'fcc t-12 t-m-16 b-20'
            )}
          >
            <Button
              className={classes.button}
              onClick={onNextStep}
              primary
              disabled={!isTermChecked || disabled}
            >
              Confirm and pay <FormattedPrice value={total} isBold />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
