import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { retrieveTokens, storeTokens } from "apollo/tokens";
import { userIdQuery } from "queries/userIdQuery";
import { LOGIN_EVENT } from "router/useLoginRedirect";

function checkTokens() {
  const { accessToken, refreshToken } = retrieveTokens();

  return Boolean(accessToken && refreshToken);
}

export const AuthChecker = ({ children }) => {
  const hasTokens = checkTokens();
  const [load, { data, loading }] = useLazyQuery(userIdQuery);

  useEffect(() => {
    if (hasTokens) {
      load();
    }
  }, [load, hasTokens]);

  const missingTokens = !hasTokens;
  const hasInvalidTokens = hasTokens && !loading && data && !data?.user?.id;

  useEffect(() => {
    if (missingTokens || hasInvalidTokens) {
      storeTokens(null, null, null, null);
      document.dispatchEvent(new CustomEvent(LOGIN_EVENT));
      console.warn("You are not authorized");
    }
  }, [missingTokens, hasInvalidTokens]);

  return hasTokens && data ? children() : null;
};

function renderRoute(parentProps, renderProps) {
  const {
    children,
    component: Component,
    render
  } = parentProps;

  if (children) {
    return children;
  }

  if (Component) {
    return <Component {...renderProps} />;
  }

  if (render) {
    return render(renderProps);
  }

  return null;
}

export function withAuthCheck(Route) {
  const PrivateRouter = (props) => {
    const {
      children,
      component,
      render,
      ...routeProps
    } = props;

    return (
      <Route {...routeProps} render={(renderProps) => (
        <AuthChecker>
          {() => renderRoute(props, renderProps)}
        </AuthChecker>
      )} />
    );
  };

  PrivateRouter.displayName = "PrivateRouter";

  return PrivateRouter;
}
