import React from "react";
import PropTypes from "prop-types";
import VehicleClassCard from "./VehicleClassCard";

const VehicleClassCardsList = (props) => {
  const { className, list, state, onChange } = props;

  return (
    <div className={className}>
      <div className="row fixed-gutters">
        {list.map(({ icon, name, description }) => (
          <div key={name} className="col-12 col-s-6 col-m-4 t-16">
            <VehicleClassCard
              id={name}
              image={icon.srcset[0].url} // TODO: Use utilities or universal image component
              name={name}
              description={description}
              checked={state[name]}
              onChange={(checked) => onChange({ name, checked })}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

VehicleClassCardsList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.object,
    name: PropTypes.string,
    description: PropTypes.string
  })).isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default VehicleClassCardsList;
