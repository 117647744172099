import { gql } from "@apollo/client";

export const ownerIncomeForecast = gql`
  query(
    $daily_rate: Int!
    $discounts: [DiscountInputForIncomeForecast]
    $duration: Int
  ) {
    owner_income_forecast(
      daily_rate: $daily_rate
      duration: $duration
      discounts: $discounts
    ) {
      non_member_income {
        cents_as_part
        dollars_as_part
        user_friendly
      }
      member_income {
        cents_as_part
        dollars_as_part
        user_friendly
      }
      difference {
        cents_as_part
        dollars_as_part
        user_friendly
      }
      member_discount
    }
  }
`;
