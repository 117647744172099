import React from "react";
import BigVerticalRadioList from "components/form/Radio/BigVerticalRadioList";
import { FieldController } from "components/form/FieldController/FieldController";
import { getRadioList } from "processes/Booking/components/PickUpDeliveryModal/getRadioList";

const getLocation = (value, specificLocations) => {
  return specificLocations.find((location) => String(value) === String(location.id))?.location;
};

export const BigVerticalRadioListContainer = (props) => {
  const {
    initialLocation,
    deliveryFee,
    showDefaultLocation,
    control,
    errors,
    isMapLoaded,
    radioState,
    currentLocation,
    isResetCurrentLocation = false,
    handleOnCalculateDeliveryFee,
    paidDelivery,
    specificLocations,
    setShowDefaultLocation,
    setCurrentLocation,
    setValue,
    setShowNotificationPopover,
    location,
    setRadioState,
    rentalType,
    deliveryRadius,
    locationData,
    deliveryFeeData,
    setDeliveryFeeData
  } = props;

  return (
    <>
      <FieldController
        name="radio"
        control={control}
        render={(renderProps) => {
          return (
            <BigVerticalRadioList
              list={getRadioList({
                initialLocation,
                deliveryFee,
                showDefaultLocation,
                control,
                errors,
                isMapLoaded,
                radioState,
                currentLocation,
                handleOnCalculateDeliveryFee,
                paidDelivery,
                specificLocations,
                setShowDefaultLocation,
                setCurrentLocation,
                setValue,
                setShowNotificationPopover,
                rentalType,
                deliveryRadius,
                locationData,
                deliveryFeeData,
                setDeliveryFeeData
              })}
              onClick={(e) => {
                if (e.target.value !== "location-1" && e.target.value !== "location-2") {
                  setCurrentLocation(getLocation(e.target.value, specificLocations));
                  setShowDefaultLocation(false);
                  setShowNotificationPopover(false);
                } else if (e.target.value === "location-2") {
                  if (!isResetCurrentLocation) {
                    setCurrentLocation(location);
                  } else {
                    setCurrentLocation(null);
                  }
                } else if (e.target.value === "location-1") {
                  setShowNotificationPopover(false);
                  setShowDefaultLocation(true);
                }
                setRadioState(String(e.target.value));
              }}
              {...renderProps}
              value={radioState}
            />
          );
        }}
      />
    </>
  );
};
