import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Button from "components/Button";
import Typography from "components/Typography";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import {
  isFirstStep,
  isLastStep,
  getStepsMenuItemIcon,
  isFirstStepSelected,
  isLastStepSelected
} from "./utils";
import useGetElementContentScroll from "hooks/useGetElementContentScroll";
import classes from "./StepsMenu.module.css";

const StepsMenu = ({
  steps = [],
  currentStepIndex = 0,
  onClickStep,
  scrollData: {
    isScroll = false,
    isScrollBar = false,
    menuWidth = 'auto'
  } = {},
  justifyContentCenter = false,
  classNames: {
    stepsMenuContainerClassName = "",
    stepsMenuClassName = "",
    stepsMenuItemsClassName = "",
    stepsMenuItemsButtonClassName = "",
    stepsTitleContainerClassName = ""
  } = {}
}) => {
  const isSmallSize = useMatchMedia({ maxWidth: MQ_BP.small });
  const [stepsMenuRef1, stepsMenuScrollData] = useGetElementContentScroll();
  const stepsMenuRef2 = useRef(null);

  useEffect(() => {
    if (isFirstStepSelected(steps) && stepsMenuRef2.current.scrollLeft > 0) {
      stepsMenuRef2.current.scrollLeft = 0;
    } else if (isLastStepSelected(steps)) {
      stepsMenuRef2.current.scrollLeft = stepsMenuRef2.current.scrollWidth;
    }
  }, [steps]);

  if (!steps.length) return null;

  const stepTitle = steps[currentStepIndex] && steps[currentStepIndex]?.title;

  return (
    <div
      className={classnames(
        classes.stepsMenuContainer,
        stepsMenuContainerClassName,
        isScroll && classes.isScroll,
        isScroll && isScrollBar && classes.isScrollBar,
        stepsMenuScrollData.isLeftScrollOffset && classes.leftGradient,
        stepsMenuScrollData.isRightScrollOffset && classes.rightGradient
      )}
      ref={(el)=> {
        stepsMenuRef2.current = el;
        stepsMenuRef1(el);
      }}
      style={{ width: menuWidth }}
    >
      <ul
        className={classnames(
          classes.stepsMenu,
          "ui-menu ",
          stepsMenuClassName,
          justifyContentCenter && classes.justifyContentCenter
        )}
      >
        {steps.map((step, stepIndex) => (
          <li
            key={step.id}
            className={classnames(
              classes.stepsMenuItem,
            )}
          >
            <Button
              secondary
              base={isSmallSize}
              iconPosition="before"
              icon={getStepsMenuItemIcon(steps[stepIndex], isSmallSize)}
              className={classnames(
                classes.stepsMenuItemButton,
                step.selected && classes.stepsMenuItemButtonSelected,
                step.completed && classes.stepsMenuItemButtonCompleted,
                !step.wasSelected && classes.stepsMenuItemButtonNotWasSelected,
              )}
              onClick={() =>
                onClickStep({
                  steps,
                  selectedStep: step,
                  selectedStepIndex: stepIndex,
                  prevSelectedStep: steps[currentStepIndex],
                  prevSelectedStepIndex: currentStepIndex,
                  isFirstStep: isFirstStep(stepIndex),
                  isLastStep: isLastStep(steps, stepIndex)
                })
              }
              label={step.title}
            />
          </li>
        ))}
      </ul>
      <div className="tac dn-m">
        <Typography
          variant="headline"
          size="s"
          weight="bold"
          className={classes.stepTitle}
        >
          {stepTitle}
        </Typography>
      </div>
    </div>
  );
};

StepsMenu.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired, // starting from 0
      stepNumber: PropTypes.number.isRequired, // starting from 1
      component: PropTypes.elementType, // react element
      completed: PropTypes.bool.isRequired, // true if tab is completed
      selected: PropTypes.bool, // true if tab is selected
      wasSelected: PropTypes.bool // true if tab was selected
    }).isRequired
  ).isRequired,
  currentStepIndex: PropTypes.number.isRequired, // index of the current step (tab),
  onClickStep: PropTypes.func.isRequired,
  classNames: PropTypes.shape({
    stepsMenuContainerClassName: PropTypes.string,
    stepsMenuClassName: PropTypes.string,
    stepsMenuItemsClassName: PropTypes.string,
    stepsMenuItemsButtonClassName: PropTypes.string,
    stepsTitleContainerClassName: PropTypes.string
  })
};

export default StepsMenu;
