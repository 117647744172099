import React, { useState, useCallback } from "react";
import { TextItem } from "components/TextItem";
import Button from "components/Button/Button";
import { useDescriptionHeight } from "./hooks/useDescriptionHeight";
import classes from "./DescriptionCollapse.module.css";
import cn from "classnames";

const DescriptionCollapse = ({
  allowableHeight = Number.MAX_SAFE_INTEGER,
  description,
  buttonLabelShow = "More details",
  buttonLabelHidden = "Less details"
}) => {

  const [show, setShow] = useState(false);
  const [ref, height] = useDescriptionHeight();
  const isAllowableHeight = height > allowableHeight;

  const changeShow = useCallback(() => {
    setShow(show => !show);
  }, []);

  return (
    <div className={classes.container}>
      <div
        ref={ref}
        className={classes.pseudoDescription}
      >
        <TextItem data={description} plainStyle={classes.descPlainStyle} />
      </div>

      <div
        className={cn(
          classes.description,
          isAllowableHeight && !show && classes.isAllowableHeight
        )}
        style={{
          maxHeight: !show && isAllowableHeight && allowableHeight + 'px'
        }}
      >
        <TextItem data={description} plainStyle={classes.descPlainStyle} htmlStyle={classes.descHtmlStyle}/>
      </div>

      {show && isAllowableHeight && (
        <Button
          size="medium"
          ghost
          onClick={changeShow}
          className={classes.moreLessButton}
        >
          {buttonLabelHidden}
        </Button>
      )}

      {!show && isAllowableHeight && (
        <Button
          size="medium"
          ghost
          onClick={changeShow}
          className={classes.moreLessButton}
        >
          {buttonLabelShow}
        </Button>
      )}
    </div>
  );
};

DescriptionCollapse.propTypes = {
};

export default DescriptionCollapse;
