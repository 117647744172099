import { convertMilesToMeters } from "../components/Listing/steps/LocationDelivery/components/MapComponent/helpers";

export const USA_CENTER = { lat: 39.115531, lng: -94.626787 };

/**
 * Map Utils
 */

const basicCircleOptions = {
  strokeColor: "#4D6539",
  strokeOpacity: 0.3,
  strokeWeight: 2,
  fillColor: "#66864B",
  fillOpacity: 0.15
};


export const getMapCircleOptions = (radiusMiles, center) => {
  return {
    ...basicCircleOptions,
    radius: convertMilesToMeters(radiusMiles),
    center: center
  };
};

export const getMapMarkerIcon = (url, size) => {
  const gMaps = window.google.maps;
  return {
    url,
    size: new gMaps.Size(size, size),
    origin: new gMaps.Point(0, 0),
    anchor: new gMaps.Point(size / 2, size / 2)
  };
};

export const isEqualToMarkerPosition = (marker, position) => {
  const mPos = marker?.position;
  return mPos && position.lat === mPos.lat() && position.lng === mPos.lng();
};

export const EMPTY_IMAGE = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
