export const INSPECTION_STATUS = {
  EXPECTED_GENERAL_INFO: "Expected General Info",
  COMPLETED: "Completed"
};

export const INSPECTION_STATUS_KEYS = {
  INFO: "-4",
  COMPLETED: "-2"
};

export const INSPECTION_UNITS = {
  MILEAGE: 'Miles',
  GENERATOR: 'Hours'
};

export const INSPECTION_STATUS_ICONS = {
  NO_ICON: 0,
  LIGHT_GRAY: 1,
  GRAY: 2,
  GREEN: 3
};

export const INSPECTION_MILEAGE_AND_GENERATOR_IDS = {
  MILEAGE: 100002,
  GENERATOR: 100001
};

export const RENTAL_INSPECTION_TYPES = {
  start: 1,
  end: 2
};

export const RENTAL_INSPECTION_TYPE_TITLES = {
  1: {
    mainTitle: "Start rental",
    submitTitle: "Submit start rental values",
    currentInspectionTitle: "start"
  },
  2: {
    mainTitle: "End rental",
    submitTitle: "Submit end rental values",
    currentInspectionTitle: "end"
  }
};
