import { useState, useEffect } from "react";
import { userAccountNumberQuery } from "processes/Rentals/RentalDetails/components/RequestHistory/queries/userAccountNumberQuery";
import { useQuery } from "@apollo/client";
import { path } from "ramda";

function isUserBankAccountNumber(data) {
  const getBankAccountNumber = path(["user", "bank_account", "account_number"]);
  return getBankAccountNumber(data)?.length !== 0;
}

function useUserBankAccount() {
  const { data } = useQuery(userAccountNumberQuery, {
    fetchPolicy: "no-cache"
  });
  const [hasBankAccountNumber, setHasBankAccountNumber] = useState(false);

  useEffect(() => {
    if (isUserBankAccountNumber(data)) {
      setHasBankAccountNumber(true);
    } else {
      setHasBankAccountNumber(false);
    }
  }, [data]);

  return hasBankAccountNumber;

}

export default useUserBankAccount;
