import React, { useState } from "react";
import Typography from "components/Typography";
import Button from "../../../Button";
import { FieldInput } from "components/form/FieldInput";
import { FieldController } from "components/form/FieldController/FieldController";
import { ModalPayoutForm } from "components/ModalPayoutForm/ModalPayoutForm";
import Switch from "components/form/Switch";
import { path } from "ramda";
import { instantRentalIcon } from "components/Icon";
import classes from "./InstantRentals.module.css";

const getBankAccountNumber = path(["bank_account", "account_number"]);
const getAccountType = path(["bank_account", "account_type", "value"]);

function getAccountTypeValue(data) {
  let accountTypeValue = getAccountType(data);

  if (accountTypeValue === '-') {
    accountTypeValue = '';
  }

  return accountTypeValue;
}

function isUserBankAccountNumber(data) {
  return getBankAccountNumber(data)?.length !== 0;
}

export const InstantRentals = ({
  userData,
  updateUserData,
  onCloseModalPayoutForm,
  form: {
    control,
    errors,
    setError,
    clearErrors,
    isSwitchInstantRentals
  } }) => {

  const [showModalPayoutForm, setShowModalPayoutForm] = useState(false);
  const isBankAccountNumber = isUserBankAccountNumber(userData);
  const bancAccountNumber = getBankAccountNumber(userData);
  const bankAccountNumberValue = <span>• • • • • • <span className={classes.bancAccountNumber}>{bancAccountNumber}</span></span>;
  const accountTypeValue = getAccountTypeValue(userData);
  const daysBeforeRentalFieldClasses =
  !isBankAccountNumber
    ? 'r-36 mr-2 mr-m-0 r-m-8 r-l-4 r-xl-28 r-xxl-40'
    : 'r-36 r-m-8 r-l-4 r-xl-28 r-xxl-40';

  return (
    <>
      <ModalPayoutForm
        show={showModalPayoutForm}
        onClose={() => {
          setShowModalPayoutForm(false);
        }}
        onSuccessSubmit={() => {
          onCloseModalPayoutForm();
          clearErrors("bank_account_number");
        }}
        userData={userData}
        updateUserData={updateUserData}
        accountType={accountTypeValue}
        submitButtonData={{
          submitButtonType: "button",
          submitImmediately: true
        }}
      />
      <div className={`${classes.container} t-16 t-m-20`}>
        <div className={classes.content}>
          <header className={`${classes.header} fbs mb-xl-4`}>
            <div>
              <Typography variant="subtitle" size="m" className={classes.headerTitle}>
                Instant rentals {instantRentalIcon}
              </Typography>
            </div>

            <div className="t-4">
              <FieldController
                name={"instant_rentals"}
                control={control}
                render={(renderProps) => (
                  <div className="df t-8 b-8">
                    <Switch
                      id={'instant_rentals'}
                      {...renderProps}
                      onChange={(checked) => {
                        renderProps.onChange(checked);
                        if (!checked) {
                          clearErrors("minimum_days_before_instant_rental");
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </header>
          <div className={`${classes.subHeader} mb-4 mb-m-12`}>
            <Typography variant="body" size="l" className={classes.subHeaderText}>
              This feature allows renters to book immediately, without your approval. This will require you to keep your calendar up to date.
            </Typography>
          </div>
          { isSwitchInstantRentals && (
            <div className={`row ${classes.fieldsContainer}`}>

              <div className="col-12 col-m-6">
                <div className="mb-4 mb-m-12">
                  <Typography variant="body" size="l">
                  Minimum number of days before the start of the rental:
                  </Typography>
                </div>
                <div className="row">
                  <div className="col-11 col-m-8 col-l-9 r-l-8">
                    <div className={daysBeforeRentalFieldClasses}>
                      <FieldController
                        name={"minimum_days_before_instant_rental"}
                        control={control}
                        rules={{
                          validate: (v) => {
                            if (isSwitchInstantRentals && v.length === 0) {
                              setError("minimum_days_before_instant_rental", {
                                type: "validate",
                                message: "Days before the rental Required"
                              });
                              return "Days before the rental Required";
                            }
                          }
                        }}
                        render={(renderProps) => (
                          <FieldInput
                            masked
                            className="fw"
                            id="minimum_days_before_instant_rental"
                            label={"Days before the rental"}
                            errors={errors}
                            {...renderProps}
                            disabled={!isSwitchInstantRentals}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-m-6">
                <div className="l-m-0 l-l-0">
                  <div className=" mt-16 mt-m-0 mb-4 mb-m-12 t-2 t-m-0">
                    <Typography variant="body" size="l">
                    Payout information:
                    </Typography>
                  </div>
                  {isBankAccountNumber && (
                    <div className="df fsc">
                      <div className={`${classes.bankAccountFieldContainer} df fsc mr-16 mr-l-24 mr-xxl-32`}>
                        <div className="df fsc">{bankAccountNumberValue}</div>
                      </div>
                      <div className="">
                        <Button
                          className={classes.updateButton}
                          ghost
                          onClick={() => setShowModalPayoutForm(true)}
                        >
                        Update
                        </Button>
                      </div>
                    </div>
                  )}


                  {!isBankAccountNumber && (
                    <div className={classes.addPayoutInfo}>
                      <Button
                        className={classes.addPayoutInfoButton}
                        secondary
                        onClick={() => setShowModalPayoutForm(true)}
                      >
                        Add
                      </Button>

                      <div className={classes.bankAccountNumberField}>
                        <FieldController
                          name={"bank_account_number"}
                          control={control}
                          rules={{
                            validate: () => {
                              if (isSwitchInstantRentals && !isBankAccountNumber) {
                                setError("bank_account_number", {
                                  type: "validate",
                                  message: "Bank account information is required, please add now."
                                });
                                return "Bank account information is required, please add now.";
                              }
                            }
                          }}
                          render={(renderProps) => (
                            <FieldInput
                              masked
                              className="fw"
                              id="bank_account_number"
                              label={"Bank account number"}
                              {...renderProps}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

InstantRentals.propTypes = {

};
