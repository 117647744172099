import { MQ_BP, useMatchMedia } from "components/MatchMedia";

export const useColumnAndRowCount = (mapOpen, list) => {
  const isLargeDesktop = useMatchMedia({ minWidth: MQ_BP.xLarge });
  const isLarge = useMatchMedia({ minWidth: MQ_BP.large });

  const isTwoColumns = useMatchMedia({
    minWidth: 624,
    maxWidth: 1023
  });
  const isThreeColumns = useMatchMedia({
    minWidth: 1024,
    maxWidth: 1439
  });

  let columnCount = 1;
  if (isTwoColumns || (isLarge && !isLargeDesktop && mapOpen)) columnCount = 2;
  if (isThreeColumns || ((isLarge || isLargeDesktop) && !mapOpen)) columnCount = 3;

  const rowCount = Math.ceil(list.length / columnCount);

  return [columnCount, rowCount];
};
