import { gql } from "@apollo/client";
import { contentBlockFragment } from "components/ContentSection/queries/contentQuery";

export const myProfileSectionContentQuery = gql`
  query {
    myProfileSectionContent: content(section: "account_section_profile") {
      ...AllContent
    }
  }
  ${contentBlockFragment}
`;
