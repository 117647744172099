import { useCallback, useState, useEffect } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

const PIPE_WIDTH = "--cwrr-pipe-width";
const PIPE_LEFT = "--cwrr-pipe-left";

function calculatePipeData(node, cls) {
  const childs = node.getElementsByClassName(cls);
  const data = {};
  Array.from(childs).forEach((child, index) => {
    data[index] = {
      left: child.offsetLeft,
      width: child.offsetWidth
    };
  });
  return data;
}

function trackSize(node, cls, callback) {
  let observer = null;

  try {
    observer = new ResizeObserver(() => {
      const data = calculatePipeData(node, cls);
      callback(data);
    });

    observer.observe(node);
  } catch (e) {}

  return () => {
    if (observer) {
      observer.unobserve(node);
      observer = null;
    }
  };
}

function handleSetPipeData(node, data, index) {
  node.style.setProperty(PIPE_WIDTH, data[index].width + 'px');
  node.style.setProperty(PIPE_LEFT, data[index].left + 'px');
}

export function usePipeAnimation(ref, cls, tab = 0) {
  const [pipeData, setPipeData] = useState(null);
  const [activeTab, setActiveTab] = useState(tab);
  const smallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  useEffect(() => {
    if (ref?.current){
      let unsubscribe;
      const node = ref.current;

      if (node) {
        unsubscribe = trackSize(node, cls, (data) => {
          setPipeData(data);
          handleSetPipeData(node, data, activeTab);
        });
      }
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [activeTab, cls, ref, smallDevice, setPipeData]);

  const updatePipeTab = useCallback((tabIndex) => {
    if (ref.current && pipeData) {
      handleSetPipeData(ref.current, pipeData, tabIndex);
      setActiveTab(tabIndex);
    }
  }, [pipeData, ref, setActiveTab]);

  return updatePipeTab;
}
