import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { Autocomplete } from "../../../form/AutoComplete";
import { useSourceYears } from "./useSourceYears";
import { useSourceManufacturers } from "./useSourceManufacturers";
import { useSourceMakes } from "./useSourceMakes";
import { LabelContent } from "components/ContentSection/LabelContent";
import { MAXIMUM_ALLOWED_YEAR } from "./constants";

export const RvFilter = ({ control, errors, disabled }) => {
  const manufacturerValue = useWatch({ control, name: "manufacturer" });

  const yearsSource = useSourceYears();
  const manufacturersSource = useSourceManufacturers();
  const makeSource = useSourceMakes(manufacturerValue);

  return (
    <div className="row b-s-20">
      <div className="col-6 col-m-2 col-l-2 t-16 b-16 t-s-20 b-s-20">
        <Controller
          name="year"
          control={control}
          rules={{
            required: "Required",
            pattern: /^\d{4}$/,
            validate: async (value) => {
              const result = await yearsSource(value);

              if (!result.includes(value)) {
                return "Wrong year";
              }
            }
          }}
          render={(renderProps) => (
            <LabelContent section="generalInfoSectionContent" id="year">
              {(label) => (
                <Autocomplete
                  disabled={disabled}
                  source={yearsSource}
                  className="fw"
                  id="generalInfo-year"
                  name="year"
                  label={label}
                  errors={errors}
                  hasError={Boolean(errors?.year)}
                  errorMessage="Wrong year"
                  {...renderProps}
                />
              )}
            </LabelContent>
          )}
        />
      </div>
      <div className="col-m col-l-5 t-16 b-16 t-s-20 b-s-20">
        <Controller
          name="manufacturer"
          control={control}
          rules={{
            required: "Required",
            validate: async (value) => {
              const result = await manufacturersSource(value);

              if (!result.includes(value)) {
                return "Choose Manufacturer from the list";
              }
            }
          }}
          render={(renderProps) => (
            <LabelContent section="generalInfoSectionContent" id="manufacturer">
              {(label) => (
                <Autocomplete
                  disabled={disabled}
                  source={manufacturersSource}
                  className="fw"
                  id="generalInfo-manufacturer"
                  name="manufacturer"
                  label={label}
                  errors={errors}
                  hasError={Boolean(errors?.manufacturer)}
                  errorMessage="Choose Manufacturer from the list"
                  {...renderProps}
                />
              )}
            </LabelContent>
          )}
        />
      </div>
      <div className="col-m col-l-5 t-16 b-16 t-s-20 b-s-20">
        <Controller
          name="make"
          control={control}
          rules={{
            required: "Required",
            validate: async (value) => {
              const result = await makeSource(value);

              if (!result.includes(value)) {
                return "Choose Brand from the list";
              }
            }
          }}
          render={(renderProps) => (
            <LabelContent section="generalInfoSectionContent" id="make">
              {(label) => (
                <Autocomplete
                  disabled={disabled}
                  source={makeSource}
                  className="fw"
                  id="generalInfo-make"
                  name="make"
                  label={label}
                  errors={errors}
                  hasError={Boolean(errors?.make)}
                  errorMessage="Choose Brand from the list"
                  {...renderProps}
                />
              )}
            </LabelContent>
          )}
        />
      </div>
    </div>
  );
};
