import React from "react";
import PropTypes from "prop-types";
import { MatchMedia } from "../MatchMedia";
import Typography from "../Typography";
import { ProTipsDescription } from "components/ProTipsDescription/ProTipsDescription";

export const ProTipsContent = ({ title, content, className }) => {
  return (
    <MatchMedia mediaQuery="(min-width: 768px)">
      <div className={className["aside-info__item"]}>
        <div className={className["aside-info__block"]}>
          <Typography
            className={className["aside-info__title"]}
            component="h6"
            variant="subtitle"
            size="m"
          >
            <strong>{title}</strong>
          </Typography>
        </div>
        <div className={className["aside-info__block"]}>
          <div className={className["aside-info__description"]}>
            <ProTipsDescription content={content} />
          </div>
        </div>
      </div>
    </MatchMedia>
  );
};

ProTipsContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.object.isRequired
};
