import React from "react";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Typography from "components/Typography";
import { parseQueryString } from "utils/queryString";

import classes from './EmailVerification.module.css';

const HASH = '#continue-booking';

export const EmailVerification = () => {
  const history = useHistory();
  const { backUrl } = parseQueryString(history.location.search);
  const backUrlWithoutHash = backUrl.replace(HASH, '');

  return (
    <div className="container mt-60 mb-120">
      <div className={classes.wrapper}>
        <Typography variant="headline" size="l" weight="bold">
          Your account has been created. Verification email has been sent to your email box.
        </Typography>
        <Typography variant="body" size="l" className="t-16 b-16 t-m-20 b-m-20">
          Please verify your mailbox to continue.
        </Typography>
        <div className="t-16 b-16 t-m-20 b-m-20">
          <Button label="Back to RV Profile" href={backUrlWithoutHash} />
        </div>
      </div>
    </div>
  );
};
