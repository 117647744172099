import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Typography from "../../Typography";
import typingIndicator from "./typing-indicator.gif";
import classes from "./TypingIndicator.module.css";

const TypingIndicator = (props) => {
  const { typingUserName, isScrollAtEnd } = props;
  const [_typingUserName, _setTypingUserName] = useState(null);

  useEffect(() => {
    if (typingUserName) {
      // Saving to local variable to keep the display, when the new prop is null
      _setTypingUserName(typingUserName);
    }
  }, [typingUserName]);

  if (!_typingUserName) {
    return null;
  }

  return (
    <div
      className={classnames(
        classes.root,
        "df r-24 b-12 l-24",
        {
          [classes.isActive]: Boolean(typingUserName),
          [classes.isScrollAtEnd]: isScrollAtEnd
        }
      )}
    >
      <img className="mr-8" src={typingIndicator} alt="" />
      <Typography variant="body" size="l">
        {`${_typingUserName} is typing...`}
      </Typography>
    </div>
  );
};

TypingIndicator.propTypes = {
  typingUserName: PropTypes.string,
  isScrollAtEnd: PropTypes.bool
};

export default TypingIndicator;
