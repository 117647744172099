import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "../Typography";

const Message = ({
  type,
  title,
  text,
  messageClassNames,
  titleClassNames,
  textClassNames,
  iconClassNames
}) => {
  const classNames = classnames(
    "message",
    type && `message--${type} ${messageClassNames}`
  );

  return (
    <div className={classNames}>
      {/*TODO set icon from props */}
      <div className={`message__icon ${iconClassNames}`} />
      <div className="message__content">
        {title && (
          <Typography
            variant="body"
            size="l"
            component="strong"
            className={`message__title ${titleClassNames}`}
          >
            {title}
          </Typography>
        )}
        {text && (
          <Typography
            variant="body"
            size="l"
            component="p"
            className={`message__text ${textClassNames}`}
          >
            {text}
          </Typography>
        )}
      </div>
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(["error"]),
  title: PropTypes.string,
  text: PropTypes.string
};

export default Message;
