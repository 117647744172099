import React from "react";
import PropTypes from "prop-types";
import GoodsamInsuranceModal from "./GoodsamInsuranceModal";

const contentData = {
  insuranceTitle:
    "Choose the plan that’s right for you, and rest assured that all options include 24/7 Roadside Assistance.",
  insuranceFormContent: [
    {
      id: "0",
      label: "Good",
      price: {
        cents_total: 3999,
        cents_as_part: 99,
        dollars_as_part: 39
      },
      period: "day",
      description: [
        "State-minimum liability coverage",
        "Collision and comp up to $1M",
        "Deductibles from $2,000 - $4,000 depending on vehicle"
      ]
    },
    {
      id: "1",
      label: "Better",
      price: {
        cents_total: 4499,
        cents_as_part: 99,
        dollars_as_part: 44
      },
      period: "day",
      description: [
        "State-minimum liability coverage",
        "Collision and comp up to $1M",
        "Deductibles from $1,500 - $3,500 depending on vehicle"
      ]
    },
    {
      id: "2",
      label: "Best",
      price: {
        cents_total: 5499,
        cents_as_part: 99,
        dollars_as_part: 54
      },
      period: "day",
      description: [
        "$1M liability coverage",
        "Collision and comp up to $1M",
        "Deductibles from $1,000 - $3,000 depending on vehicle",
        "Windshield protection",
        "Accident interruption",
        "Concierge services"
      ]
    }
  ],
  insuranceLink: {
    label: "Learn more about coverages",
    to: "https://rvrentals.zendesk.com/hc/en-us/categories/4418360245271-Insurance-Questions",
    blank: true
  }
};

const GoodsamInsurance = (props) => {
  const { show, onClose, formData, onSelectInsurance, data, backTo } = props;

  const newContentData = {
    ...contentData,
    insuranceFormContent: data.insurance_packages.map((item, index) => ({
      ...contentData.insuranceFormContent[index],
      label: item.name,
      price: {
        cents_total: item.price_per_day.cents_total,
        cents_as_part: item.price_per_day.cents_as_part,
        dollars_as_part: item.price_per_day.dollars_as_part
      },
      description: item.description
    }))
  };
  return (
    <GoodsamInsuranceModal
      formData={formData}
      content={newContentData}
      show={show}
      onClose={onClose}
      onSave={(value) => onSelectInsurance(Number(value.insurance) + 1)}
      backTo={backTo}
    />
  );
};

GoodsamInsurance.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  backTo: PropTypes.exact({
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired
  }),
  formData: PropTypes.shape({
    insurance: PropTypes.string.isRequired
  })
};

export default GoodsamInsurance;
