import { gql } from "@apollo/client";

export const userQuery = gql`  
  query {
    user {
      id
      email
      phone
      first_name
      last_name
      city
      state
      country
      zip
      sso__user_id
      good_sam_member_id
      payment_info {
        address
      }
      good_sam_membership_validation {
        key
        value
      }
    }
  }
`;
