import React, { useState } from "react";
import GoodsamMembership from "../../../components/GoodsamMembership";

const BookingMembership = ({
  show,
  setIsShowBookingMembership,
  goodsamMembershipPlanId,
  onCloseBookingMembership
}) => {
  const [isGoodSamMember, setIsGoodSamMember] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);

  return (
    <GoodsamMembership
      show={show}
      onClose={() => setIsShowBookingMembership(false)}
      setIsShowMembershipModal={setIsShowBookingMembership}
      setIsGoodSamMember={setIsGoodSamMember}
      setIsDeclined={setIsDeclined}
      onSavePlan={onCloseBookingMembership}
      activeId={goodsamMembershipPlanId}
    />
  );
};

export default BookingMembership;
