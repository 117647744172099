import PropTypes from "prop-types";
import React from "react";
import { MatchMedia } from "../MatchMedia";
import RcCollapse from "../RcCollapse";

const ProTipsCollapseFields = ({ content, className, mediaParams }) => {
  return (
    <MatchMedia mediaQuery={`(max-width: ${mediaParams})`}>
      <div className={className}>
        <RcCollapse
          header={content.proTipsSectionTitle}
          content={content.proTipsSectionText}
        />
      </div>
    </MatchMedia>
  );
};

ProTipsCollapseFields.propTypes = {
  mediaParams: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired
};

export default ProTipsCollapseFields;
