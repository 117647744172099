import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import RentalDetailsStatusBarItem from "./RentalDetailsStatusBarItem";
import { RentalProgressBarType } from "processes/Rentals/types";

const RentalDetailsStatusBarList = ({ payload, variant }) => (
  <ul
    className={classnames(
      "rental-details-status-bar__list",
      `rental-details-status-bar__list--${variant}`
    )}
  >
    {payload.map((item) => (
      <RentalDetailsStatusBarItem key={item.item} item={item} />
    ))}
  </ul>
);

RentalDetailsStatusBarList.propTypes = {
  payload: PropTypes.arrayOf(RentalProgressBarType).isRequired,
  variant: PropTypes.oneOf(["horizontal", "vertical"])
};
RentalDetailsStatusBarList.defaultProps = {
  variant: "horizontal"
};

export default RentalDetailsStatusBarList;
