import { useCallback, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { parseQueryString } from "utils/queryString";

export const IS_ACTION_DRIVER = "ad";
export const LOGIN_EVENT = "crww-login";
export const BACK_URL_PARAM_KEY = "backUrl";
export const REDIRECT_PARAM = "redirect";
export const REDIRECT_PARAM_LOGIN = "login";
export const REDIRECT_PARAM_SIGNUP = "signup";
export const ACTION_PARAM = "action";
export const EMAIL_PARAM = "userEmail";

export const isNewDriverFlow = (search) => {
  const query = parseQueryString(search);

  return (
    query?.[ACTION_PARAM] === IS_ACTION_DRIVER
    && (query?.[REDIRECT_PARAM] === REDIRECT_PARAM_LOGIN || query?.[REDIRECT_PARAM] === REDIRECT_PARAM_SIGNUP)
    && Boolean(query?.[EMAIL_PARAM])
  );
};
export const CODE_PARAM_KEY = "code";

export const getViewParam = (pathname) => {
  const startPathname = pathname.substring(0, 4);

  return startPathname === '/wl/' ? '&view=white-label' : "";
};

export const getBackUrlParam = (search) => {
  const query = parseQueryString(search);
  return query?.[BACK_URL_PARAM_KEY];
};

export const getCodeParam = (search) => {
  const query = parseQueryString(search);
  return query?.[CODE_PARAM_KEY];
};

export const getRedirectParam = (search) => {
  const query = parseQueryString(search);
  return query?.[REDIRECT_PARAM];
};

export function useLoginRedirect() {
  const history = useHistory();

  const handler = useCallback(() => {
    const { pathname, search, hash } = history.location;

    if (isNewDriverFlow(search)) {
      const redirectParam = getRedirectParam(search);
      // hard reload
      setTimeout(() => {
        window.location.href = `/${redirectParam}${search}`;
      }, 10);
    } else if (pathname !== "/login" && window) {
      const viewParam = getViewParam(pathname);
      const btnParam = '&btn=0';

      // hard reload
      setTimeout(() => {
        window.location.href = `/login?${BACK_URL_PARAM_KEY}=${
          encodeURIComponent([pathname, search, hash].join(""))
        }${viewParam}${btnParam}`;
      }, 10);
    }
  }, [history]);

  useLayoutEffect(() => {
    document.addEventListener(LOGIN_EVENT, handler);

    return () => {
      document.removeEventListener(LOGIN_EVENT, handler);
    };
  }, [handler]);
}
