import React, { useState } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import cn from "classnames";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";

import styles from "./styles.module.css";

const Modal = ({
  children,
  show,
  onClose,
  modalWrapClassnames,
  modalCrossButtonClassname,
  modalClassnames,
  variant,
  colorVariant,
  showCross,
  render,
  withContainer,
  mdlWrapRef,
  mdlRef,
  isMobileFullScreen
}) => {
  const [active, setActive] = useState(false);
  const handleClose = () => {
    onClose();
  };

  return (
    <ReactModal
      isOpen={show}
      overlayClassName={cn(
        "mdl-wrap",
        active && show && "active",
        variant && `mdl-wrap--${variant}`,
        isMobileFullScreen && `mdl-wrap--full-screen-less-768`,
        modalWrapClassnames
      )}
      className={cn(
        styles.modalContent,
        !withContainer && "mdl",
        withContainer && "container mdl-container",
        isMobileFullScreen && `mdl--full-screen-less-768`,
        variant && `mdl--${variant}`,
        colorVariant && `mdl--${colorVariant}`,
        modalClassnames
      )}
      bodyOpenClassName="overlay-open"
      htmlOpenClassName="overlay-open"
      closeTimeoutMS={250}
      onAfterOpen={() => setActive(true)}
      onAfterClose={() => setActive(false)}
      ariaHideApp={false}
      overlayRef={mdlWrapRef}
      contentRef={mdlRef}
    >
      {!render && children}
      {render && render({ handleClose })}
      {showCross && (
        <div
          className={cn("mdl__cross", `${modalCrossButtonClassname}`)}
          onClick={handleClose}
        />
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    "full-screen",
    "full-screen-less-768",
    "basic",
    "basic-alt",
    "notification"
  ]),
  modalWrapClassnames: PropTypes.string,
  modalClassnames: PropTypes.string,
  colorVariant: PropTypes.oneOf(["dark", "light"]),
  showCross: PropTypes.bool,
  render: PropTypes.func,
  withContainer: PropTypes.bool,
  mdlWrapRef: PropTypes.func,
  mdlRef: PropTypes.func,
  isMobileFullScreen: PropTypes.bool
};

Modal.defaultProps = {
  showCross: true
};

Modal.Header = ModalHeader;
Modal.Content = ModalContent;

export default Modal;
