import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { extractServerErrorMessage, extractSuccess } from "utils/extractErrors";
import { CodeVerificationControl } from "components/form/CodeVerificationControl/CodeVerificationControl";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import Button from "components/Button";
import { phoneVerifyMutation } from "./mutations/phoneVerifyMutation";
import { sendPhoneVerificationMutation } from "./mutations/sendPhoneVerificationCodeMutation";
import classes from "./PhoneVerifyCodeModal.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { pushGoogleAnalytics } from "utils/google-analytics/push";

const extractPhoneVerifySuccess = extractSuccess("phone_verify");
const extractPhoneVerify = extractServerErrorMessage("phone_verify");
const extractSendPhoneVerificationCode = extractServerErrorMessage(
  "send_phone_verification_code"
);

export const PhoneVerifyCodeModal = ({ show, onHide, setIsVerified, isSendPhoneVerificationCodeWhenOpenModal }) => {
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );
  const [error, setError] = useState(null);
  const [phoneVerify, phoneVerifyResult] = useMutation(phoneVerifyMutation);
  const [
    sendPhoneVerificationCode,
    sendPhoneVerificationCodeResult
  ] = useMutation(sendPhoneVerificationMutation);

  const handlePhoneVerification = useCallback(
    async (code) => {
      const response = await phoneVerify({
        variables: {
          code
        }
      });

      if (!extractPhoneVerifySuccess(response)) {
        setError(extractPhoneVerify(response));
        return;
      }
      pushGoogleAnalytics("phoneVerified");
      setIsVerified(true);
      onHide();
    },
    [onHide, phoneVerify, setIsVerified]
  );

  const handleSendPhoneVerificationCode = useCallback(async () => {
    const response = await sendPhoneVerificationCode();
    setError(extractSendPhoneVerificationCode(response));
  }, [sendPhoneVerificationCode]);

  useEffect(() => {
    if (!show) {
      setError(null);
    }
  }, [show, setError]);

  useEffect(() => {
    if (error) {
      setShowNotificationPopover(true);
    }
  }, [error]);

  useEffect(() => {
    if (isSendPhoneVerificationCodeWhenOpenModal && show) {
      handleSendPhoneVerificationCode();
    }
  }, [handleSendPhoneVerificationCode, isSendPhoneVerificationCodeWhenOpenModal, show]);

  const disabled =
    sendPhoneVerificationCodeResult.loading || phoneVerifyResult.loading;

  return (
    <Modal
      show={show}
      onClose={onHide}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
      variant="basic"
    >
      <Typography component="h6" variant="headline" size="m">
        Phone number verification
      </Typography>
      <Typography variant="body" size="l">
        Text message with verification code has been sent to you. Please enter
        the code to verify your cell phone.
      </Typography>
      <CodeVerificationControl
        key={sendPhoneVerificationCodeResult.loading ? 1 : 2}
        onComplete={handlePhoneVerification}
        disabled={disabled}
      />
      <div className="t-20">
        <Button
          ghost
          onClick={handleSendPhoneVerificationCode}
          disabled={disabled}
        >
          Resend code
        </Button>
      </div>
      {error && (
        <div className="t-8 b-8">
          <NotificationPopover
            show={isShowNotificationPopover}
            status="error"
            text={error}
            onClose={() => {
              setShowNotificationPopover(false);
              setError(null);
            }}
          />
        </div>
      )}
    </Modal>
  );
};
