import React from "react";
import { EmailForm } from "./EmailForm";

export const Email = ({ content, userData }) => {
  return (
    <EmailForm
      content={content}
      email={userData.email}
      isVerified={userData.email_verified}
    />
  );
};
