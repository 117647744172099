import React from "react";
import PropTypes from "prop-types";
import { MemberItem, NotAMemberItem } from "components/MembershipDetails";
import { getLinks }  from "components/MembershipDetails/utils/getLinks";
import classes from "./MembershipDetailsList.module.css";

export const MembershipDetailsList = (props) => {
  const {
    data = [],
    links
  } = props;

  return (
    data.map((item) => {
      const { joinLink, memberLink } = getLinks(item, links);
      const { is_member } = item;
      const itemProps = {
        ...item,
        links: {
          joinLink,
          memberLink
        }
      };

      return (
        <section className={classes.section} key={item?.club_id}>
          {is_member ? (
            <MemberItem {...itemProps} />
          ) : (
            <NotAMemberItem {...itemProps} />
          )}
        </section>
      );
    })
  );
};

MembershipDetailsList.propTypes = {

};
