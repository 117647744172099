import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Tag from "components/Tag";
import Typography from "components/Typography";
import cn from "classnames";
import classes from "./MemberItemValue.module.css";

export const MemberItemValue = ({ title, value }) => (
  <div>
    <div>
      <Typography
        variant="body"
        size="s"
        className={classes.title}
      >
        {title}
      </Typography>
    </div>
    <div>
      <Typography
        variant="subtitle"
        size="s"
        className={classes.value}
      >
        {value}
      </Typography>
    </div>
  </div>
);

MemberItemValue.propTypes = {

};
