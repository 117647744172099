import moment from "moment";

export function formatDateInRequiredFormat(select1, select2) {
  return {
    periodStart: `${select1.year}-${select1.month}`,
    periodEnd: `${select2.year}-${select2.month}`
  };
}

export function isValidPeriod(dates) {
  return moment(dates.periodEnd).isSameOrAfter(dates.periodStart);
}

export function isDateMatched(periodStart, periodEnd, card) {
  // const date = moment("2013-02-08").format('YYYY-MM');
  const splitDate = card.billing_date.split('/');
  const date = `${splitDate[2]}-${splitDate[0]}`;
  const start = moment(periodStart);
  const end = moment(periodEnd);
  const isBetween = moment(date).isBetween(start, end);
  const isSameStart = moment(date).isSame(start);
  const isSameEnd = moment(date).isSame(end);

  return !!(isBetween || isSameStart || isSameEnd);
}
export function isDoubleCardDateMatched(periodStart, periodEnd, cards) {
  const isDate1Matched = isDateMatched(periodStart, periodEnd, cards[0]);
  const isDate2Matched = isDateMatched(periodStart, periodEnd, cards[1]);

  if (isDate1Matched && isDate2Matched) {
    return true;
  }
  return false;
}

export function filterByDates(periodStart, periodEnd, cards) {
  const result = [];

  cards.forEach((card) => {
    if (card.doubleCard) {
      if (isDoubleCardDateMatched(periodStart, periodEnd, card.doubleCard)) {
        result.push({
          doubleCard: { ...card.doubleCard }
        });
      }
    } else {
      if (isDateMatched(periodStart, periodEnd, card)) {
        result.push({
          ...card
        });
      }
    }
  });

  return result;
}

export function filterByRv(rvId, cards) {
  return cards.filter(card => String(card.rv_id) === rvId);
}

export function filterData(rvId, cards) {
  if (rvId === "0") {
    return cards;
  }

  return filterByRv(rvId, cards);
}

export function calculateTotal(cards, key) {
  if (cards.length && !cards[0][key]) {
    return null;
  }

  const result = cards.reduce((accumulator, card) => accumulator += card[key]?.cents_total, 0);

  return {
    dollars_as_part: Math.floor(result / 100),
    cents_as_part: result % 100,
    cents_total: result
  };
}
