function getKey(rvId) {
  return `CWRR:booking:${rvId}`;
}

/**
 * @param {number} rvId
 * @return {string|null}
 */
export function readStorage(rvId) {
  try {
    return window.localStorage.getItem(getKey(rvId));
  } catch (e) {
    return null;
  }
}

/**
 * @param {number} rvId
 * @param {string|null} data
 */
export function writeStorage(rvId, data) {
  try {
    if (typeof data === "object" && !data) {
      window.localStorage.removeItem(getKey(rvId));
    } else {
      window.localStorage.setItem(getKey(rvId), data);
    }
    return true;
  } catch (e) {
    return false;
  }
}
