import React, { useRef, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

const LENGTH = 5;
const PADDING = Array(LENGTH).fill(' ').join('');

function convertStringToArray(value) {
  return `${value}${PADDING}`
    .substr(0, LENGTH)
    .split('');
}

function noop() {
}

const Digit = ({ className, disabled, focus, value, onChange, onCursorMove }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus) {
      inputRef?.current.focus();
    } else {
      inputRef?.current.blur();
    }
  }, [focus]);

  return (
    <input
      className={className}
      disabled={disabled}
      ref={inputRef}
      value={value}
      type="text"
      inputMode="numeric"
      onChange={noop}
      onKeyDown={e => {
        switch (e.code || e.key) {
        case "ArrowLeft":
          onCursorMove(-1, false);
          break;
        case "ArrowRight":
          onCursorMove(1, false);
          break;
        case "Backspace":
          onCursorMove(-1, true);
          break;
        case "Delete":
          onCursorMove(0, true);
          break;
        default:
          break;
        }
      }}

      onBeforeInput={(e) => {
        if (e.data >= '0' && e.data <= '9') {
          onChange(e.data);
        }

        e.preventDefault();
      }}

      onKeyPress={(e) => {
        const { key } = e;

        if (key >= '0' && key <= '9') {
          onChange(e.key);
        }

        e.preventDefault();
      }}
    />
  );
};

const DigitArray = (props) => {
  const {
    disabled,
    value,
    focusIndex,
    onChange,
    onFocusNext
  } = props;

  const digits = convertStringToArray(value);

  const setDigit = useCallback((index, digit) => {
    const nextDigits = convertStringToArray(value);

    nextDigits[index] = digit;

    onChange(nextDigits.join(''));
  }, [value, onChange]);

  return (
    <div className="code-verification">
      {digits.map((d, index) => (
        <Digit
          key={index}
          className="code-verification__digit"
          disabled={disabled}
          focus={index === focusIndex}
          value={d}
          onChange={(digit) => {
            setDigit(index, digit);
            onFocusNext(index + 1);
          }}
          onCursorMove={(delta, isClear) => {
            if (isClear) {
              setDigit(index, " ");
            }

            onFocusNext(index + delta);
          }}
        />
      ))}
    </div>
  );
};

export const CodeVerificationControl = ({ disabled, onComplete }) => {
  const [value, setValue] = useState('');
  const [focusIndex, setFocusIndex] = useState(0);

  const done = useCallback((v) => {
    setValue('');
    setFocusIndex(0);
    onComplete(v);
  }, [onComplete, setValue, setFocusIndex]);

  useEffect(() => {
    if (/^\d+$/.test(value) && value.length === LENGTH) {
      done(value);
    }
  }, [value, focusIndex, done]);

  useEffect(() => {
    if (focusIndex < 0) {
      setFocusIndex(0);
    } else if (focusIndex >= LENGTH) {
      setFocusIndex(LENGTH - 1);
    }
  }, [focusIndex, setFocusIndex]);

  useEffect(() => {
    if (!disabled) {
      setFocusIndex(0);
    }
  }, [disabled, setFocusIndex]);

  return (
    <DigitArray
      disabled={disabled}
      value={value}
      focusIndex={focusIndex}
      onChange={setValue}
      onFocusNext={setFocusIndex}
    />
  );
};

CodeVerificationControl.defaultProps = {
  disabled: false
};

CodeVerificationControl.propTypes = {
  disabled: PropTypes.bool,
  onComplete: PropTypes.func.isRequired
};
