import { useMutation, useQuery } from "@apollo/client";
import { defaultTo, filter, map, pick, pipe } from "ramda";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import { extractServerErrors } from "../../../../utils/extractErrors";
import { buildQueryString, parseQueryString } from "../../../../utils/queryString";
import Button from "../../../Button";
import Form from "../../../form/Form/Form";
import Message from "../../../Message";
import { ProTipsCollapse } from "../../../ProTipsCollapse/ProTipsCollapse";
import { SpinnerBack } from "../../../Spinner/Spinner";
import Typography from "../../../Typography";
import { ListingContext } from "../../ListingContext";
import { generalInfoMutation } from "../../mutations/generalInfoMutation";
import { filterInitialData } from "../filterInitialData";
import { isStepValid } from "./utils";
import { calculateStep, STEP_FILTER, STEP_MODEL, STEP_RV_ID } from "./calculateStep";
import { MAXIMUM_ALLOWED_YEAR } from "./constants";
import { RvFilter } from "./RvFilter";
import { RvManualModelAndClass } from "./RvManualModelAndClass";
import { RvModelSelector } from "./RvModelSelector";
import { Button as ButtonContent, Text } from "components/ContentSection/elements";
import { ContentLoader } from "components/ContentSection";
import { generalInfoSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { userQuery } from "./queries/userQuery.js";
import size from "lodash/size";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";
import cn from 'classnames';

const INITIAL_VALUES = {
  manual: "",
  rvId: null,
  year: "",
  manufacturer: "",
  make: "",
  model: "",
  class: ""
};

export const GeneralInfoForm = (props) => {
  const { step, defaultValues, onSubmit, onBack, onManual, loading, nextStep } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors
  } = useForm({
    mode: "onSubmit",
    defaultValues
  });

  const filterValues = useWatch({
    control,
    name: ["year", "manufacturer", "make", 'class', 'model']
  });

  const filterRvId = useWatch({
    control,
    name: ["rvId"]
  });

  const isStepFilterValid = isStepValid(STEP_FILTER, filterValues);
  const isStepModalValid = isStepValid(STEP_MODEL, filterValues);
  const isStepRvIdValid = isStepValid(STEP_RV_ID, filterRvId);
  const submitButtonNotDisabled = isStepFilterValid && (isStepModalValid || isStepRvIdValid);
  const [globalError] = useRequestErrors(props.requestErrors, setError);

  const handleOnSubmit = useCallback(
    (data) => {
      onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <RvFilter control={control} errors={errors} disabled={step !== STEP_FILTER} clearErrors={clearErrors} />
        {step === STEP_RV_ID && (
          <RvModelSelector
            control={control}
            year={parseInt(filterValues.year)}
            manufacturer={filterValues.manufacturer}
            make={filterValues.make}
            renderManual={() => {
              return (
                <div className="t-8 b-16 b-s-20 t-m-20 mt-2 mt-m-0 f-wrap fsc">
                  <Typography variant="body" size="l">
                    {`Don’t see an option? `}
                  </Typography>
                  <ButtonContent
                    id="manual_entry_button"
                    section="generalInfoSectionContent"
                    className={cn('link underline')}
                    onClick={() => onManual(filterValues)}
                  />
                </div>
              );
            }}
            renderZeroResults={() => {
              return (
                <div className="t-8 b-16 b-s-20 t-m-20 mt-2 mt-m-0 f-wrap fsc">
                  <Text
                    id="multiple_choice_additional_info"
                    section="generalInfoSectionContent"
                    variant="body"
                    size="l"
                    className="mr-4"
                  />
                  <ButtonContent
                    id="manual_entry_button"
                    section="generalInfoSectionContent"
                    className={cn('link underline')}
                    onClick={() => onManual(filterValues)}
                  />
                </div>
              );
            }}
          />
        )}
        {step === STEP_MODEL && <RvManualModelAndClass control={control} />}
        {globalError && (
          <div className="t-s-20">
            <Message type="error" text={globalError} />
          </div>
        )}
        <div className="row">
          <div className="col t-16 t-m-20 b-16 b-m-20">
            {step === STEP_FILTER && (
              <div className="btn-group btn-group_xs-fixed">
                <ButtonContent id="next_button" section="generalInfoSectionContent" disabled={!isStepFilterValid} type="submit" />
              </div>
            )}
            {step !== STEP_FILTER && (
              <div className="btn-group btn-group_xs-fixed">
                {((step === STEP_MODEL && !defaultValues.id) || step !== STEP_MODEL) && (
                  <ButtonContent
                    id="back_button"
                    section="generalInfoSectionContent"
                    onClick={() => onBack(filterValues)}
                    disabled={loading}
                  />
                )}
                {nextStep && (
                  <ButtonContent
                    id="save_and_next_button"
                    section="generalInfoSectionContent"
                    disabled={!submitButtonNotDisabled || loading}
                    type="submit"
                  />
                )}

                {!nextStep && (
                  <Button disabled={!submitButtonNotDisabled || loading} type="submit">
                    Save
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

const GeneralInfoPanel = ({ initialData, onSave, loading, requestErrors, nextStep }) => {
  const history = useHistory();
  const location = useLocation();
  const search = parseQueryString(location.search);
  const step = calculateStep(search);
  const defaultValues = Object.assign({}, INITIAL_VALUES, initialData, search);
  const initialDataId = initialData.id;
  const [generalInfoFormKey, setGeneralInfoFormKey] = useState(1);

  const handleSubmit = useCallback(
    (values) => {
      if (step === STEP_FILTER) {
        return history.push("?" + buildQueryString({ id: initialDataId, ...values }));
      }

      onSave({
        id: initialDataId,
        rv_garage_id: !initialDataId ? "" : undefined,
        ...values
      });
    },
    [initialDataId, step, history, onSave]
  );

  const handleBack = useCallback(() => {
    history.push("?" + buildQueryString({ id: initialDataId }));
  }, [initialDataId, history]);

  const handleManual = useCallback(
    (values) => {
      if (step === STEP_RV_ID) {
        history.push("?" + buildQueryString({ id: initialDataId, manual: "yes", ...values }));
      }
    },
    [initialDataId, step, history]
  );

  useEffect(() => {
    setGeneralInfoFormKey((key) => key + 1);
  }, [initialData]);

  return (
    <div>
      <Text id="step_title" section="generalInfoSectionContent" component="h2" variant="headline" size="s" />
      <ProTipsCollapse className="t-16 b-32 t-m-4 t-xl-16" mediaParams="767px" />
      <GeneralInfoForm
        key={generalInfoFormKey}
        step={step}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        onBack={handleBack}
        onManual={handleManual}
        loading={loading}
        requestErrors={requestErrors}
        nextStep={nextStep}
      />
    </div>
  );
};

function normalizeYear(values) {
  return {
    ...values,
    year: parseInt(values.year, 10)
  };
}

function normalizeModel(values) {
  const { rvId, ...rest } = values;

  if (!rvId) {
    return values;
  }

  return {
    ...rest,
    model: rvId.model,
    class: rvId.rvClass
  };
}

function normalizeId(values) {
  const { id } = values;

  if (typeof id === "undefined") {
    return values;
  }

  return {
    ...values,
    id: parseInt(id, 10)
  };
}

const normalize = pipe(normalizeYear, normalizeModel, normalizeId);

const filterInitialGeneralInfo = filterInitialData(["id", "year", "manufacturer", "make", "model", "class", "manual"]);

const extractRvUpdateErrors = extractServerErrors("rv_update");

const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "general_info"),
  map(pick(["field", "message"]))
);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "general_info")
);
const GeneralInfoDataProvider = ({ context, updateContext, currStep, nextStep }) => {
  const { loading: userQueryLoading, data: userQueryData } = useQuery(
    userQuery,
    {}
  );
  const isSegmentSent = useRef(null);
  const history = useHistory();
  const [action, result] = useMutation(generalInfoMutation);
  const handleSave = useCallback(
    async (values) => {
      const { data } = await action({
        variables: normalize(values)
      });

      if (data.rv_update.result.success) {
        pushGoogleAnalytics("listVehicleGeneralInfo", {
          year: data?.rv_update?.rv?.year,
          manufacturer: data?.rv_update?.rv?.manufacturer,
          make: data?.rv_update?.rv?.make,
          model: data?.rv_update?.rv?.model,
          class: data?.rv_update?.rv?.class
        }, true);

        handleListingSegment(data?.rv_update?.rv, "Lead Form Step Completed", "General Info", 1);
        updateContext({
          initialData: {
            ...context.initialData,
            ...data.rv_update.rv
          },
          errors: cleanSectionErrors(context.errors)
        });

        if (nextStep) {
          history.push(`/listing/${nextStep.path}?id=${data.rv_update.rv.id}`, {
            prevStep: currStep
          });
        }
      }
    },
    [action, context.errors, context.initialData, currStep, history, nextStep, updateContext]
  );

  const rvGeneraInfoData = useMemo(() => filterInitialGeneralInfo({
    ...context.initialData,
    manual: context.initialData?.model && (context.initialData?.class === "" || context.initialData?.class) ? "yes" : ""
  }), [context.initialData]);

  useEffect(() => {
    const queryString = buildQueryString(rvGeneraInfoData);

    if (queryString && history.location.pathname.endsWith("/general-info") && history.location.search !== queryString) {
      history.replace("?" + queryString);
    }
  }, [history, history.location.search, rvGeneraInfoData]);

  useEffect(() => {
    if (!isSegmentSent.current && size(context?.initialData)) {
      handleListingSegment(context?.initialData, "Lead Form Step Viewed", "General Info", 1);
      isSegmentSent.current = true;
    } else if (!isSegmentSent.current && !size(context?.initialData) && userQueryData?.user) {
      const { user } = userQueryData;
      handleListingSegment({
        owner: {
          id: user?.id,
          good_sam_membership_validation: user?.good_sam_membership_validation
        }
      }, "Lead Form Step Viewed", "General Info", 1);
      isSegmentSent.current = true;
    }
  }, [context?.initialData, userQueryData]);

  const publishErrors = filterPublishErrorsBySection(context.errors);

  const stepErrors = extractRvUpdateErrors(result);

  return (
    <>
      <GeneralInfoPanel
        initialData={rvGeneraInfoData}
        onSave={handleSave}
        loading={result.loading}
        requestErrors={publishErrors.length ? publishErrors : stepErrors}
        nextStep={nextStep}
      />
      {result.loading && <SpinnerBack />}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const generalInfoSectionContentPredicate = everyKey(["generalInfoSectionContent"]);
export const GeneralInfo = ({ currStep, nextStep }) => (
  <ListingContext.Consumer>
    {(context) => (
      <ContentLoader
        query={generalInfoSectionContentQuery}
        predicate={generalInfoSectionContentPredicate}
        render={() => (
          <GeneralInfoDataProvider
            context={context[0]}
            updateContext={context[1]}
            currStep={currStep}
            nextStep={nextStep}
          />
        )}
      />
    )}
  </ListingContext.Consumer>
);
