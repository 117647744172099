import React, { useEffect, useState, useCallback } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MyAccountContext } from "../../MyAccountContext";
import { ProfileContentContainer } from "./ProfileContentContainer";
import { extractText } from "utils/extractText";
import { contentQuery } from "queries";
import { updateProfileMutation } from "./mutations/updateProfile";
import { myProfileQuery } from "./queries/myProfileQuery";
import { ContentLoader } from "components/ContentSection";
import { extractServerErrors, extractServerErrorMessage } from "utils/extractErrors";
import { myProfileSectionContentQuery } from "processes/MyAccount/queries/myAccountSectionContentQuery";
import Spinner from "components/Spinner/Spinner";
import classes from "./Profile.module.css";

const extractProfileErrors = extractServerErrors("profile_update");
const extractSignupErrorMessage = extractServerErrorMessage("profile_update");
const section = "account_section_profile";
const initialContent = {
  images: [],
  sectionTitle: "My Profile",
  proTipsSectionTitle: "Pro Tips",
  proTipsSectionText: "",
  sectionDescription: "",
  sectionSubtitle1: "General Info",
  sectionSubtitle2: "Profile Photo",
  sectionSubtitle3: "Cover Image",
  sectionSubtitle4: "About Me"
};

const ProfileDataProvider = (props) => {
  const [content, setContent] = useState(initialContent);
  const [updateProfile, { data: updateProfileResultData }] = useMutation(
    updateProfileMutation
  );
  const [errorValue, setErrorValue] = useState("");

  const handleUpdateProfile = useCallback(
    async (variables) => {
      const response = await updateProfile({
        variables
      });

      if (extractProfileErrors(response)) {
        const errorText = extractSignupErrorMessage(response);
        if (errorText) {
          setErrorValue(errorText);
        }
      }
    },
    [updateProfile]
  );

  const {
    loading: myProfileDataLoading,
    data: myProfileData
  } = useQuery(myProfileQuery, { fetchPolicy: "no-cache" });

  const [getContent, { loading, data }] = useLazyQuery(contentQuery, {
    variables: {
      section
    },
    fetchPolicy: "no-cache"
  });
  const { setAccountSection } = props;

  useEffect(getContent, [getContent]);
  useEffect(() => {
    setAccountSection(section);
  }, [setAccountSection]);

  useEffect(() => {
    if (!loading && data?.content?.text) {
      setContent({
        proTipsSectionTitle: extractText(
          data.content.text,
          "pro_tips_section_title"
        ),
        proTipsSectionText: extractText(
          data.content.text,
          "pro_tips_section_text"
        )
      });
    }
  }, [loading, data]);

  if (myProfileDataLoading || !myProfileData) {
    return <Spinner className={classes.profileSpinner} />;
  }

  return (
    <ProfileContentContainer
      content={content}
      myProfileData={myProfileData?.my_profile}
      handleUpdateProfile={handleUpdateProfile}
      updateProfileResultData={updateProfileResultData}
      // myProfileRefetch={myProfileLoad}
      myProfileRefetch={() => {}}
      errorValue={errorValue}
      setErrorValue={setErrorValue}
      {...props}
    />
  );
};

function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const myProfileSectionContentPredicate = everyKey(["myProfileSectionContent"]);

export const Profile = () => (
  <MyAccountContext.Consumer>
    {(value) => (
      <ContentLoader
        query={myProfileSectionContentQuery}
        predicate={myProfileSectionContentPredicate}
        render={() => <ProfileDataProvider {...value} />}
      />
    )}
  </MyAccountContext.Consumer>
);
