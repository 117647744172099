import React, { useEffect } from "react";
import Modal from "../../../Modal/Modal";
import { useSearchContext } from "../../useSearchContext";
import Typography from "../../../Typography";
import ActionBar from "../../../ActionBar/ActionBar";
import MobileFilterClass from "./MobileFilterClass";
import MobileFilterLength from "./MobileFilterLength";
import MobileFilterPrice from "./MobileFilterPrice";
import MobileFilterAmenities from "./MobileFilterAmenities";
import MobileFilterInstantRentals from "./MobileFilterInstantRentals";
import MobileFilterRentalType from "./MobileFilterRentalType";
import { clearInactiveFilters } from "components/Search/utils";
import { FILTERS } from "../../filters";
import { useWatchFields } from "../../hooks";
import size from "lodash/size";
import classes from "./MobileFilters.module.css";

const MobileFilters = () => {
  const {
    searchData,
    mobileFiltersOpen,
    setMobileFiltersOpen,
    applyFilters,
    activeFilters,
    resetAllFilters,
    filtersControl
  } = useSearchContext();

  const { hasFilledFilters } = useWatchFields();

  useEffect(() => {
    clearInactiveFilters(mobileFiltersOpen, activeFilters, filtersControl);
  }, [mobileFiltersOpen, activeFilters, filtersControl]);

  if (!searchData) {
    return null;
  }

  return (
    <Modal
      variant="full-screen"
      show={mobileFiltersOpen}
      onClose={() => setMobileFiltersOpen(false)}
      modalWrapClassnames={classes.modalRoot}
      modalClassnames={classes.modal}
    >
      <div className={classes.content}>
        <Typography variant="headline" size="s">
          Filters
        </Typography>
        <div className={classes.filters}>
          <MobileFilterClass classesList={searchData.classes} />
          <MobileFilterLength
            min={searchData.length_min}
            max={searchData.length_max}
          />
          <MobileFilterPrice
            min={searchData.price_min}
            max={searchData.price_max}
          />
          <MobileFilterAmenities amenities={searchData.amenities} />
          <MobileFilterRentalType
            filtersControl={filtersControl}
          />
          <MobileFilterInstantRentals
            filtersControl={filtersControl}
            applyFilters={applyFilters}
          />
        </div>
      </div>
      <ActionBar
        className={classes.bar}
        clearTitle="Clear all"
        onClear={hasFilledFilters ? resetAllFilters : undefined}
        onSubmit={() => {

          const { isMobileChecked: isMobileCheckedInstantRentalsOnly } = filtersControl.getValues().instantRentalsOnly;
          filtersControl.setValue("instantRentalsOnly", {
            checked: isMobileCheckedInstantRentalsOnly,
            isMobileChecked: isMobileCheckedInstantRentalsOnly
          });

          applyFilters();
          setMobileFiltersOpen(false);
        }}
      />
    </Modal>
  );
};

export default MobileFilters;
