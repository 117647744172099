import React from "react";
import Modal from "components/Modal/Modal";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "./ModalExit.module.css";

export const ModalExit = (props) => {
  const {
    show,
    onClose,
    onBack,
    onExit,
    title = "Exit without saving",
    description = "Are you sure you want to continue? All changes will be lost.",
    backButtonLabel = "Back",
    exitButtonLabel = "Exit"
  } = props;

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={classes.modalWrap}
      modalClassnames={classes.modal}
      variant="basic"
    >
      <div>
        <Typography variant="headline" size="m" weight="bold" className={classes.title}>
          {title}
        </Typography>

        <div className="mt-m-2 mt-l-0">
          <Typography variant="subtitle" size="s" className={classes.description}>
            {description}
          </Typography>
        </div>

        <div className="mt-16 mt-m-20">
          <Button
            secondary
            onClick={onBack}
            className="mr-16 mr-l-32"
          >
            {backButtonLabel}
          </Button>

          <Button onClick={onExit} >
            {exitButtonLabel}
          </Button>
        </div>
      </div>
    </Modal>

  );
};
