import { ApolloLink } from "@apollo/client";
import { storeTokens } from "./tokens";
import { LOGIN_EVENT } from "router/useLoginRedirect";
function isObject(obj) {
  return !!(obj && typeof obj === "object");
}

function hasUserNotLoggedInError(data) {
  return data.errors.some(err => {
    return isObject(err) && err.field === null && err.message === "User is not logged in";
  });
}

function checkIfUserIsNotLoggedIn(data) {
  if (!isObject(data)) {
    return false;
  }

  if (Array.isArray(data.errors) && hasUserNotLoggedInError(data)) {
    return true;
  }

  return Object.keys(data).some(key => checkIfUserIsNotLoggedIn(data[key]));
}

export const loginRedirectLink = new ApolloLink((operation, forward) => {
  return forward(operation)
    .map(data => {
      if (checkIfUserIsNotLoggedIn(data)) {
        storeTokens(null, null, null, null);
        document.dispatchEvent(new CustomEvent(LOGIN_EVENT));
      }

      return data;
    });
});
