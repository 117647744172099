import { useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";

function useImageCrop(mutation) {
  const [cropMutation, { data, loading, error }] = useMutation(mutation);
  const handleCrop = useCallback(
    (values) => {
      cropMutation({
        variables: {
          ...values
        }
      });
    },
    [cropMutation]
  );

  const handleDoneEditing = useCallback(
    (data, type) => {
      const normalizedValue = {
        type,
        crop_area_x1: data.x,
        crop_area_y1: data.y,
        crop_area_x2: data.x + data.width,
        crop_area_y2: data.y + data.height,
        rotation: data.turn / 90
      };

      handleCrop(normalizedValue);
    },
    [handleCrop]
  );

  const value = useMemo(() => {
    if (!data && !error) {
      return {
        loading,
        error: null,
        data: null
      };
    }
    if (data?.profile_image_crop?.result?.success) {
      return {
        data: data?.profile_image_crop?.image,
        loading,
        error: null
      };
    }

    if (data?.profile_image_crop?.result?.errors) {
      return {
        data: null,
        loading,
        error: data?.profile_image_crop?.result?.errors[0].message
      };
    } else if (error) {
      return {
        data: null,
        loading,
        error: error
      };
    }
  }, [data, loading, error]);
  return [handleDoneEditing, value];
}

export default useImageCrop;
