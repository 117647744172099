import { gql } from "@apollo/client";

export const rvHasNewGaragePhotosReset = gql`
  mutation rv_has_new_garage_photos_reset($rv_id: Int!) {
    rv_has_new_garage_photos_reset(rv_id: $rv_id) {
      result {
        success
        errors {
          code
          field
          message
        }
      }
    }
  }
`;
