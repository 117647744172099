import React, { useEffect, useState } from "react";
import BookingRentalDetailsModal from "./BookingRentalDetailsModal";
import { calculateTotalCurrencyDollarsAndCents } from "utils/calculateСurrency/calculateTotalCurrency";
import { formatMoney } from "utils/formatNumber";

const userData = {
  good_sam_membership_validation: {
    key: "",
    value: ""
  }
};
function getContentData({
  bookingFee,
  cleaningFee,
  deliveryFee,
  disposalFee,
  goodSamMembershipFee,
  taxes,
  milesIncluded,
  generatorHoursIncluded,
  mileageOverageRate,
  generatorOverageRate,
  isGoodSamMemberFlag,
  limitedGeneratorHours,
  limitedDailyMileage,
  generatorNotApplicable,
  isTrailedClass,
  taxesAndFeesCurrencyData = [],
  rental_type: rentalType,
  goodSamMembershipFeeCurrencyData = []
}) {

  let totalCurrencyData = isGoodSamMemberFlag
    ? [...taxesAndFeesCurrencyData, ...goodSamMembershipFeeCurrencyData]
    : taxesAndFeesCurrencyData;
  let totalCurrency = formatMoney(
    calculateTotalCurrencyDollarsAndCents(totalCurrencyData)
  );

  let contentData = {
    sectionTitle: "Taxes & fees",
    items: [
      {
        id: "cleaning_fee",
        label: "Cleaning fee",
        value: {
          cents_total: 5000,
          cents_as_part: 0,
          dollars_as_part: 50,
          user_friendly: cleaningFee === "0.00" ? "Free" : cleaningFee
        },
        description:
          "This fee is set by the owner to cover any cleaning costs associated after the return of your rental.",
        helperMessage: ""
      },
      {
        id: "disposalfee",
        label: "Disposal fee",
        value: {
          cents_total: 5000,
          cents_as_part: 0,
          dollars_as_part: 50,
          user_friendly: disposalFee === "0.00" ? "Free" : disposalFee
        },
        description:
          "This fee is set by the owner to cover the disposal of the waste tanks after the return of your rental.",
        helperMessage: ""
      },
      {
        id: "delivery_fee",
        label: "Delivery fee",
        value: {
          cents_total: 5000,
          cents_as_part: 0,
          dollars_as_part: 50,
          user_friendly: deliveryFee === "0.00" ? "Free" : deliveryFee
        },
        description:
          "This fee is calculated based on the rate and parameters the owner set to cover your delivery charges.",
        helperMessage: ""
      },
      {
        id: "service_fee",
        label: "Booking and processing fee",
        value: {
          cents_total: 18650,
          cents_as_part: 50,
          dollars_as_part: 186,
          user_friendly: bookingFee === "0.00" ? "Free" : bookingFee
        },
        // description:
        //   `This helps run the Good Sam RV Rentals platform. This fee is 10% of your total rental.`,
        helperMessage: ""
      },
      // {
      //   id: "first_booking_fee_discount"",
      //   label: "First booking fee discount",
      //   value: {
      //     cents_total: 18650,
      //     cents_as_part: 50,
      //     dollars_as_part: 186,
      //     user_friendly: bookingFeeDiscount === "0.00" ? "Free" : bookingFeeDiscount
      //   },
      //   description:
      //     "This helps run the Good Sam RV Rentals platform. This fee is 5% of your total rental.",
      //   helperMessage: ""
      // },

      {
        id: "membership",
        label: "Good Sam Membership Fee",
        value: {
          cents_total: 7900,
          cents_as_part: 0,
          dollars_as_part: 79,
          user_friendly: goodSamMembershipFee
        },
        description:
          "This fee is optional. As a Good Sam Member, you will save 50% on booking fees. In addition, you will receive discounts on retail, fuel, 10% off at participating Good Sam campgrounds, and more.",
        helperMessage: ""
      },
      {
        id: "taxes",
        label: "Taxes",
        value: {
          cents_total: 7900,
          cents_as_part: 0,
          dollars_as_part: 79,
          user_friendly: taxes
        },
        description:
          "Taxes are calculated based on state and local regulations and requirements.",
        helperMessage: ""
      }
    ],
    total: {
      label: "Total",
      value: {
        cents_total: 99900,
        cents_as_part: 0,
        dollars_as_part: 999,
        user_friendly: totalCurrency
      }
    }
  };
  if (deliveryFee === "0.00") {
    contentData.items.splice(2, 1);
  }

  if (!isTrailedClass && rentalType === 1) {
    if (limitedDailyMileage) {
      contentData = {
        ...contentData,
        items: [
          ...contentData.items,
          {
            id: "per_day",
            bigRow: true,
            label: milesIncluded + " miles per day",
            value: "Free",
            description: "",
            helperMessage: `You will be charged an additional $${mileageOverageRate} per mile beyond the allotted ${milesIncluded} miles per day. `
          }
        ]
      };
    } else {
      contentData = {
        ...contentData,
        items: [
          ...contentData.items,
          {
            id: "per_day",
            bigRow: true,
            label: "Unlimited miles",
            value: "Free",
            description: "",
            helperMessage: ""
          }
        ]
      };
    }
  }
  if (!generatorNotApplicable) {
    if (limitedGeneratorHours) {
      contentData = {
        ...contentData,
        items: [
          ...contentData.items,
          {
            id: "generator_hours",
            bigRow: true,
            label: generatorHoursIncluded + " generator hours",
            value: "Free",
            description: "",
            helperMessage: `You will be charged an additional $${generatorOverageRate} per hour beyond the allotted ${generatorHoursIncluded} hours per day.`
          }
        ]
      };
    } else {
      contentData = {
        ...contentData,
        items: [
          ...contentData.items,
          {
            id: "generator_hours",
            bigRow: true,
            label: "Unlimited generator hours",
            value: "Free",
            description: "",
            helperMessage: ""
          }
        ]
      };
    }
  }
  return contentData;
}

const BookingRentalDetails = (props) => {
  const {
    show,
    onCloseBookingRentalDetails,
    isGoodSamMemberFlag,
    isClientGoodSamMember,
    onCalculatePrice
  } = props;

  const [isGoodSamMember, setIsGoodSamMember] = useState(isGoodSamMemberFlag);
  const [content, setContent] = useState(getContentData(props));

  useEffect(() => {
    if (userData.good_sam_membership_validation.value === "Passed") {
      setIsGoodSamMember(true);
    }
    if (userData.good_sam_membership_validation.value === "Failed") {
      setIsGoodSamMember(false);
    }
  }, []);

  useEffect(() => {
    setIsGoodSamMember(isGoodSamMemberFlag);
  }, [isGoodSamMemberFlag]);

  useEffect(() => {
    setContent(
      getContentData({ ...props, isGoodSamMemberFlag: isGoodSamMember })
    );
  }, [isGoodSamMember, props]);

  return (
    <BookingRentalDetailsModal
      show={show}
      onClose={() => onCloseBookingRentalDetails(isGoodSamMember)}
      content={content}
      isGoodSamMember={isGoodSamMember}
      isClientGoodSamMember={isClientGoodSamMember}
      setIsGoodSamMember={() => {
        setIsGoodSamMember(false);
        onCalculatePrice({
          good_sam_membership_package: null
        });
      }}
    />
  );
};

export default BookingRentalDetails;
