import React, { useEffect } from "react";
import Typography from "components/Typography";
import { DataLine } from "processes/RvProfile/components/DataLine/DataLine";
import { CharacteristicSection } from "processes/RvProfile/components/CharacteristicSection/CharacteristicSection";
import { AmenitiesSection } from "processes/RvProfile/components/AmenitiesSection/AmenitiesSection";
import { RulesSection } from "processes/RvProfile/components/RulesSection/RulesSection";
import { DescriptionSection } from "processes/RvProfile/components/DescriptionSection/DescriptionSection";
import { UserInfo } from "processes/RvProfile/components/UserInfo/UserInfo";
import { LocacionSection } from "processes/RvProfile/components/LocacionSection/LocacionSection";
import { ReviewsSection } from "processes/RvProfile/components/ReviewsSection/ReviewsSection";
import { LocalSection } from "processes/RvProfile/components/LocalSection/LocalSection";
import { TipsAndTricksSection } from "processes/RvProfile/components/TipsAndTricksSection/TipsAndTricksSection";
import { PhotoGridSection } from "processes/RvProfile/components/PhotoGridSection/PhotoGridSection";
import { GetReadyInfoSection } from "processes/RvProfile/components/GetReadyInfoSection/GetReadyInfoSection";
import classes from "./RvProfile.module.css";

export const RvProfile = () => {

  /* TODO: for storybook. Remove after test */
  useEffect(() => {
    document.body.classList.add('goodsam-color-scheme');
  },[]);

  return (
    <div className={classes.container}>
      <section className="">
        <PhotoGridSection />
      </section>
      <div className="container mt-16 mt-m-20">
        <div className="row">
          <div className="col-12 col-l-7 col-xl-8">
            <div>
              <Typography variant="headline" size="m" weight="normal">
            Minnie Winnie that Wins
              </Typography>
            </div>
            <div className="b-m-12 b-xl-0">
              <Typography variant="headline" size="s" weight="bold">
            2015 Winniebago Minnie Winnie
              </Typography>
            </div>
            <div>
              <DataLine />
            </div>
            <section className="mt-16 mt-xl-8">
              <CharacteristicSection />
            </section>
            <div className="devider-dashed mt-32 mb-12 mt-m-40 mb-32 mb-m-40" />
            <section>
              <UserInfo />
            </section>
            <div className="devider-dashed mt-32 mb-32 mt-m-48 mt-l-40 mb-l-40" />
            <section>
              <AmenitiesSection />
            </section>
            <div className="devider-dashed mt-32 mb-32 mt-m-40 mb-m-40" />
            <section>
              <RulesSection />
            </section>
            <div className="devider-dashed mt-12 mb-32 mb-m-40" />
            <section>
              <DescriptionSection />
            </section>
            <div className="devider-dashed mt-32 mb-36 mt-m-40 mb-m-40" />
            <section>
              <LocacionSection />
            </section>
            <div className="devider-dashed mt-32 mb-20 mt-m-40 mb-m-40" />
            <section className="mb-m-8">
              <ReviewsSection />
            </section>
          </div>
        </div>
      </div>
      <section className={`${classes.localSection} mt-48 mt-m-60 mt-xl-40`}>
        <LocalSection />
      </section>
      <section className="mt-60 mt-m-80 mb-l-20 mb-xl-80">
        <TipsAndTricksSection />
      </section>
      <section className="t-56 b-40 b-m-60 t-m-80">
        <GetReadyInfoSection />
      </section>
    </div>
  );
};

// RvProfile.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };
