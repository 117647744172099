import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal } from "components/Modal";
import { ChoosePhotosModalContent } from "./ChoosePhotosModalContent";
import classnames from "classnames";
import classes from "./ChoosePhotosModal.module.css";

export const ChoosePhotosModal = (props) => {
  const {
    photos = [],
    show = false,
    onClose = () => {},
    onContinue = () => {}
  } = props;

  const [photoIds, setPhotosIds] = useState([]);

  useEffect(() => {
    if (show) {
      document.body.classList.add('overlay-open');
    } else {
      document.body.classList.remove('overlay-open');
    }

    return () => document.body.classList.remove('overlay-open');
  }, [show]);

  const handleChangePhoto = useCallback((id, checked) => {
    if (checked) {
      const photoId = photos.find(photo => photo.id === id)?.id;
      setPhotosIds([...photoIds, photoId]);
    } else {
      setPhotosIds(photoIds.filter(photoId => photoId!== id));
    }
  }, [photoIds, photos]);

  const handleContinue = useCallback(() => {
    if (photoIds?.length > 0) {
      onContinue(photoIds);
    }
    setPhotosIds([]);
    onClose();
  }, [onClose, onContinue, photoIds]);

  const handleClose = useCallback(() => {
    setPhotosIds([]);
    onClose();
  }, [onClose]);

  return (
    <Modal
      show={show}
      onClose={handleClose}
      modalWrapClassnames={
        classnames(
          classes.modalWrap,
          ""
        )
      }
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
    >
      <ChoosePhotosModalContent
        photos={photos}
        onContinue={handleContinue}
        onChangePhoto={handleChangePhoto}
      />
    </Modal>
  );
};

ChoosePhotosModal.propTypes = {
  show: PropTypes.bool.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string,
        srcset: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string
          })
        )
      }),
      id: PropTypes.string,
      is_used_on_rentals: PropTypes.bool
    })
  ),
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func
};
