import classnames from "classnames";
import React from "react";
import StepsMenu from "components/StepsMenu/StepsMenu";
import style from "./BookingModalMenu.module.css";

const BookingModalMenu = (props) => {
  const { steps, currentStep, onClickItem, hasScrollShadow } = props;

  return (
    <nav className={classnames(style.root,
      hasScrollShadow && style.hasScrollShadow)}>
      <div className={style.shadowContainer}></div>
      <div className={style.stepsMenuContainer}>
        <StepsMenu
          onClickStep={onClickItem}
          steps={steps}
          currentStepIndex={currentStep}
        />
      </div>
    </nav>
  );
};

export default BookingModalMenu;
