import React, { forwardRef } from "react";
import FieldInput from "../FieldInput";
import Radio from "../Radio";
import classnames from "classnames";
import classes from "./FieldOptionalInput.module.css";

const FieldOptionalInput = forwardRef((props, ref) => {
  const {
    id,
    availableMessage,
    unavailableMessage,
    isAvailable,
    setAvailable,
    className,
    disabled,
    inputDisabled,
    ...renderProps
  } = props;

  const fieldClassName = classnames(className, classes.root);

  return (
    <div className={fieldClassName}>
      <div className={classes.radioRow}>
        <Radio
          id={`${id}-yes`}
          className={classes.radioYes}
          name={`${id}-switch`}
          text={availableMessage}
          checked={isAvailable}
          disabled={disabled}
          onChange={(e) => {
            if (e.target.checked) {
              setAvailable(true);
            }
          }}
        />
        <Radio
          id={`${id}-no`}
          className={classes.radioNo}
          name={`${id}-switch`}
          text={unavailableMessage}
          checked={!isAvailable}
          disabled={disabled}
          onChange={(e) => {
            if (e.target.checked) {
              setAvailable(false);
            }
          }}
        />
      </div>
      <div className={classes.inputWrap}>
        {isAvailable && (
          <FieldInput
            ref={ref}
            id={id}
            className={classes.input}
            disabled={inputDisabled || (disabled && isAvailable)}
            {...renderProps}
          />
        )}
      </div>
    </div>
  );
});

FieldOptionalInput.defaultProps = {
  availableMessage: "Yes",
  unavailableMessage: "No"
};

export default FieldOptionalInput;
