import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Modal } from "components/Modal";
import Typography from "components/Typography";
import classes from "./FullScreenModal.module.css";

const FullScreenModal = (props) => {
  const {
    children,
    show,
    onClose,
    title,
    footerStart,
    footerButtons
  } = props;

  const hasFooter = Boolean(footerStart || footerButtons);
  const horizontalIndents = [
    "l-16 r-16",
    "l-l-32 r-l-32",
    "l-xl-64 r-xl-64 ml-xl-8 mr-xl-8",
    "l-xxl-0 r-xxl-0 ml-xxl-0 mr-xxl-0"
  ];
  const bottomIndents = hasFooter
    ? "b-120 mb-24 mb-m-64"
    : "b-l-120 b-m-80 b-48";

  return (
    <Modal
      show={show}
      onClose={onClose}
      variant="full-screen"
    >
      <div
        className={classnames(
          "t-16 t-m-20",
          bottomIndents,
          horizontalIndents
        )}>
        <div className={classes.header}>
          <Typography variant="headline" size="m">
            {title}
          </Typography>
        </div>
        <div className={classes.content}>
          {children}
        </div>
        {hasFooter && (
          <div
            className={classnames(
              classes.footer,
              "t-16 b-16 t-m-20 b-m-20"
            )}>
            <div
              className={classnames(
                classes.footerContent,
                "df fbc fw",
                horizontalIndents
              )}>
              <div>
                {footerStart}
              </div>
              <div className="btn-group btn-group--end">
                {footerButtons}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

FullScreenModal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  footerStart: PropTypes.node,
  footerButtons: PropTypes.node
};

export default FullScreenModal;
