import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef
} from "react";
import BasicButton from "./BasicButton";

function useButtonClick(onClick) {
  const timerRef = useRef(0);
  const [animate, setAnimate] = useState(false);

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      setAnimate(true);

      timerRef.current = setTimeout(() => {
        setAnimate(false);
      }, 1000);
    },
    [onClick, setAnimate, timerRef]
  );

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = 0;
      }
    },
    [timerRef]
  );

  return [handleClick, animate];
}

const Button = forwardRef(function Button({ onClick, ...buttonProps }, ref) {
  const [handleClick, animate] = useButtonClick(onClick);

  return (
    <BasicButton
      ref={ref}
      {...buttonProps}
      animate={animate}
      onClick={handleClick}
    />
  );
});

Button.propTypes = BasicButton.propTypes;

export default Button;
