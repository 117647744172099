import React, { useCallback } from "react";
import Checkbox from "components/form/Checkbox";
import { Radio } from "components/form";
import { TextItem } from "components/TextItem";
import DescriptionCollapse from "components/DescriptionCollapse/DescriptionCollapse";
import cn from "classnames";
import classes from "./AdditionalInsurancePackageItem.module.css";

const AdditionalInsurancePackageItem = (props) => {
  const {
    packageId,
    name,
    description,
    value,
    multi_select,
    selectedPackages,
    onChange,
    isItemPackagesDisabled
  } = props;

  const handleOnChange = useCallback((event) => {
    onChange(packageId, event.target.checked);
  }, [packageId, onChange]);

  const isSelectedPackage = selectedPackages.packagesIds.includes(packageId);

  return (
    <div className={classes.container}>
      <div className={cn(
        classes.header,
        multi_select && classes.headerMultiSelect,
      )}>
        <div>
          {multi_select ? (
            <Checkbox
              id={packageId}
              name={packageId}
              text={name}
              checked={isSelectedPackage}
              onChange={handleOnChange}
              labelTextClassName={classes.checkboxLabel}
              disabled={isItemPackagesDisabled}
            />
          ) : (
            <Radio
              id={packageId}
              className={classes.radio}
              name={packageId}
              text={name}
              checked={isSelectedPackage}
              onChange={() => {}}
              onClick={handleOnChange}
              disabled={isItemPackagesDisabled}
            />
          )}
        </div>
        <div>
          <TextItem data={value} plainStyle={classes.valuePlainStyle} />
        </div>
      </div>
      {description && Boolean(description?.value) && (
        <div className={cn(
          classes.descriptionContainer,
          multi_select && classes.descriptionContainerMultiSelect,
        )}>
          <DescriptionCollapse description={description}/>
        </div>
      )}
    </div>
  );
};

AdditionalInsurancePackageItem.propTypes = {
};

export default AdditionalInsurancePackageItem;
