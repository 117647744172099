import { gql, useQuery } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const membershipOffersQuery = gql`
  ${baseFragments.money}
  query membershipOffers {
    good_sam_membership_offers {
      id
      default
      title
      description
      quantity
      price {
        ...MoneyReturn
      }
      tax {
        ...MoneyReturn
      }
      price_total {
        ...MoneyReturn
      }
    }
  }
`;

export const useMembershipOffers = () => {
  return useQuery(membershipOffersQuery, {
    fetchPolicy: "no-cache"
  });
};
