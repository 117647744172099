export const getParticipantsFromThread = async ({ thread, apiClient }) => {
  const result = [];
  const party = await thread.getParticipants();
  for (const participant of party) {
    const userId = participant.state.identity;
    if (userId !== apiClient.user.identity) {
      result.push(participant);
    }
  }
  return result;
};
