import React from "react";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import { LoginLinkProvider } from "processes/Login/LoginLinkProvider";

const PasswordResetSuccess = ({ className }) => (
  <div className={className}>
    <Typography variant="headline" size="l">
      Reset password email sent
    </Typography>
    <Typography variant="subtitle" size="s">
      Please check your email to reset your password.
    </Typography>
    <div className="t-20 b-20">
      <LoginLinkProvider>
        <Button>
        Return to login
        </Button>
      </LoginLinkProvider>
    </div>
  </div>
);

export default PasswordResetSuccess;
