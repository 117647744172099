import { gql } from "@apollo/client";

export const searchQuery = gql`
  query($departure_date: String, $return_date: String, $location: String, $sleeps: Int, $multiply: Int, $directory: String, $without_name: Boolean) {
    search(
      departure_date: $departure_date,
      return_date: $return_date,
      location: $location,
      sleeps: $sleeps,
      multiply: $multiply,
      directory: $directory
      without_name: $without_name
    ) {
      redirect
      rv_url_prefix
      photo_url_prefix
      price_min
      price_max
      length_min
      length_max
      amenities {
        category
        items {
          id
          icon
          name
          active
        }
      },
      classes {
        name
        description
        mapping
        icon {
          id
          srcset {
            url
          }
        }
      }
      map {
        center_lat
        center_lng
        viewport_northeast_lat
        viewport_northeast_lng
        viewport_southwest_lat
        viewport_southwest_lng
      }
      results {
        ir
        id
        ti
        yr
        mr
        mk
        md
        ml
        cl
        pr
        le
        sl
        so
        am
        lc
        lt
        ln
        dr
        fv
        ph
        ur
        rt
        ml
        nm
      }
      rvs_in_location
    }
  }
`;
