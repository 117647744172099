import React from "react";
import Typography from "components/Typography";

export const Comment = ({ value: rawValue }) => {
  const value = rawValue === "" ? rawValue : JSON.parse(rawValue);
  return (
    <div>
      <Typography
        variant="body"
        size="l"
      >
        {value}
      </Typography>
    </div>
  );
};
