import React, { useState, useCallback } from "react";
import { Modal } from "components/Modal";
import Form from "components/form/Form/Form";
import { FieldController } from "components/form/FieldController/FieldController";
import { FieldInput } from "components/form/FieldInput";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {
  extractServerErrors,
  extractSuccess,
  extractServerErrorMessage
} from "utils/extractErrors";
import Typography from "components/Typography";
import Button from "components/Button";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { membershipValidate } from "./mutations/membershipValidate";
import { VALIDATION } from "utils/validation";
import { SpinnerBack } from "components/Spinner/Spinner";
import cn from "classnames";
import classes from "./ModalMembershipValidation.module.css";

const extractMembershipValidationSuccess = extractSuccess("membership_validate");
const extractMembershipValidationErrors = extractServerErrors("membership_validate");
const extractMembershipValidationErrorMessage = extractServerErrorMessage("membership_validate");

export const ModalMembershipValidation = (props) => {
  const {
    show = false,
    clubId = "goodsam",
    title = "Membership validation",
    onClose,
    onAfterSuccessSubmit = () => {},
    onMembershipValidationSuccess = () => {},
    onAfterErrorSubmit = () => {},
    onMembershipValidationError = () => {}
  } = props;

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      membership_number: '',
      last_name: '',
      zip: ''
    }
  });

  const [error, setError] = useState("");

  const [action, { loading }] = useMutation(membershipValidate);

  const onSubmit = useCallback(async(values) => {
    const response = await action({
      variables: {
        club_id: clubId,
        ...values
      }
    });

    if (extractMembershipValidationSuccess(response)) {
      onAfterSuccessSubmit(response?.data?.membership_validate);
      onMembershipValidationSuccess();
      onClose();
    }

    if (extractMembershipValidationErrors(response)?.length > 0) {
      setError(extractMembershipValidationErrorMessage(response));
      onAfterErrorSubmit(response?.data?.membership_validate);
      onMembershipValidationError(response?.data?.membership_validate);
    }
  }, [action, clubId, onAfterErrorSubmit, onAfterSuccessSubmit, onClose, onMembershipValidationError, onMembershipValidationSuccess]);

  const { membership_number, last_name, zip } = watch();
  const isSubmitDisabled = (!membership_number || (membership_number && membership_number === "0")) || !last_name || !zip;

  return (
    <Modal
      show={show}
      onClose={onClose}
      variant="full-screen-less-768"
      modalWrapClassnames={classes.modalRoot}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
    >
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.header}>
            <Typography
              variant="headline"
              size="m"
              className={classes.title}
            >
              {title}
            </Typography>
          </div>

          <div className={classes.content}>
            <div className="t-16 b-16 t-m-20 b-m-20">
              <FieldController
                name="membership_number"
                control={control}
                rules={VALIDATION.rules.required}
                render={(renderProps) => (
                  <FieldInput
                    className="fw"
                    label="Member number"
                    errors={errors}
                    {...renderProps}
                  />
                )}
              />
            </div>

            <div className="t-16 b-16 t-m-20 b-m-20">
              <FieldController
                name="last_name"
                control={control}
                rules={VALIDATION.rules.required}
                render={(renderProps) => (
                  <FieldInput
                    className="fw"
                    label="Last name"
                    errors={errors}
                    {...renderProps}
                  />
                )}
              />
            </div>

            <div className="t-16 b-16 t-m-20 b-m-20">
              <FieldController
                name="zip"
                control={control}
                rules={VALIDATION.rules.required}
                render={(renderProps) => (
                  <FieldInput
                    className="fw"
                    label="Zip code"
                    errors={errors}
                    {...renderProps}
                  />
                )}
              />
            </div>

          </div>

          <div className={cn(classes.footer, 't-16 b-16 t-m-20 b-m-20')}>
            <Button
              secondary
              className="mr-16 mr-l-24 mr-xxl-32"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitDisabled}
            >
              Confirm
            </Button>
          </div>

          {error?.length > 0 && (
            <NotificationPopover
              show
              status="error"
              text={error}
              onClose={() => {
                setError("");
              }}
            />
          )}

          {loading && <SpinnerBack />}
        </Form>
      </div>
    </Modal>
  );
};
