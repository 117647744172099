import React, { memo, useState } from "react";
import VehicleCardWidget from "components/Widget/components/VehicleCards/VehicleCardWidget";
import { useShowMoreCards } from "components/Widget/hooks/useShowMoreCards";
import ShowMoreButton from "components/Widget/components/VehicleCards/ShowMoreButton";
import EmptyCardState from "components/Widget/components/EmptyCardState/EmptyCardState";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { BACK_URL_PARAM_KEY } from "router/useLoginRedirect";
import classes from "./VehicleCardWidget.module.css";
import cn from "classnames";

function sliceCards(cards, showMoreCard, cardsCount) {
  if (!showMoreCard) {
    return cards.slice(0, cardsCount);
  } else {
    return cards;
  }
}

function createRvProfileUrl(url, ownerPageUrl, iframeUrl, ownerDirectory) {
  const origin = new URL(iframeUrl).origin;
  const pathname = ownerPageUrl.pathname;
  const startPathname = pathname.substring(0, 4);

  if (startPathname === '/wl/') {
    return `${origin}/wl/${ownerDirectory}/rv/${url}?${BACK_URL_PARAM_KEY}=${encodeURIComponent([pathname].join(""))}`;
  }

  return `${origin}/wl/${ownerDirectory}/rv/${url}`;
}

const VehicleCardWidgetContainer = memo(() => {
  const cardsCount = useShowMoreCards();
  const [showMoreCard, setShowMoreCard] = useState(false);
  const {
    ownerPageUrl,
    iframeUrl,
    cards,
    photoUrlPrefix,
    ownerDirectory,
    loading,
    theme
  } = useWidgetContext();

  let cardItems = sliceCards(cards, showMoreCard, cardsCount);

  cardItems = cardItems.map(({
    id,
    ph,
    ur,
    ti,
    nm,
    pr,
    lc,
    cl,
    le,
    sl,
    so,
    ml,
    rt,
    ir
  }) => {
    const rvProfileLink = createRvProfileUrl(
      ur,
      ownerPageUrl,
      iframeUrl,
      ownerDirectory
    );

    return (
      <div
        key={id}
        className={cn(
          "col-12 col-m-6 col-l-4 col-xl-3 mt-24 mt-m-32",
          classes.card
        )}
      >
        <VehicleCardWidget
          imageUrl={`${photoUrlPrefix}${ph}`}
          link={rvProfileLink}
          linkTarget="_blank"
          id={id}
          title={ti}
          name={nm}
          price={pr}
          deliveredFrom={lc}
          rvClass={cl}
          length={le}
          sleeps={sl}
          slideOuts={so}
          miles={ml}
          rentalType={rt}
          instantRental={ir}
          isDarkTheme={theme === 'dark'}
        />
      </div>
    );
  });

  return (
    <div className={classes.cardContainer}>
      {cards?.length === 0 && !loading && <EmptyCardState /> }
      {cards?.length > 0 && (
        <>
          <div className={`row mt-4`}>
            {cardItems}
          </div>

          <ShowMoreButton
            showMoreCard={showMoreCard}
            setShowMoreCard={setShowMoreCard}
            cardsCount={cardsCount}
            cardsLength={cards.length}
          />
        </>
      )}
    </div>
  );
});

export default VehicleCardWidgetContainer;
