import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';

import { extractText } from "../../../../utils/extractText";
import { contentQuery } from "../../../../queries/contentQuery";
import { SupportInfoContent } from "./SupportInfoContent";

export const SupportInfo = () => {
  const section = "account_common";
  const props = {
    title: "",
    text: "",
    phone: "",
    email: ""
  };
  const [supportInfo, setSupportInfo] = useState(props);
  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section
    }
  });

  useEffect(() => {
    if(!loading) {
      setSupportInfo({
        title: extractText(data["content"].text, "support_section_title"),
        text: extractText(data["content"].text, "support_section_text"),
        phone: extractText(data["content"].text, "support_section_phone_number"),
        email: extractText(data["content"].text, "support_section_email")
      });
    }
  }, [loading]);

  if(loading) {
    return <SupportInfoContent {...props} />;
  }

  return (
    <SupportInfoContent {...supportInfo} />
  );
};
