import { validateDateOfBirthMask } from "components/form/DateOfBirthInput/utils/validateDateOfBirthMask";
/*
  Cases:
  /^\/$/g, // /
  /^\d\/$/g, // 5/
  /^\d\d\/\/$/g, // 55//
  /^\d\d\/\d\/$/g, // 55/5/
  /^\d\d\/\d\d\/\/$/g, // 55/55//
  /^\d\d\/\d\d\/\d\/$/g, // 55/55/5/
  /^\d\d\/\d\d\/\d\d\/$/g, // 55/55/55/
  /^\d\d\/\d\d\/\d\d\d\/$/g// 55/55/555/
*/
const invalidValues = /^\/$|^\d\/$|^\d\d\/\/$|^\d\d\/\d\/$|^\d\d\/\d\d\/\/$|^\d\d\/\d\d\/\d\/$|^\d\d\/\d\d\/\d\d\/$|^\d\d\/\d\d\/\d\d\d\/$/g;

/*
  Cases:
  /^\d{3}$/g, // 555
  /^\d\d\/\d{3}$/g // 55/555
*/
const otherCases = /^\d{3}$|^\d\d\/\d{3}$/;

function isInvalidValue(curValue) {
  return !(curValue.match(invalidValues) === null && curValue.length < 11);
}

function isAllowedCharacter(char) {
  const firstCase = (!Number.isNaN(Number(char)) && typeof Number(char) === 'number');
  const secondCase = char === '/';
  return firstCase || secondCase;
}

function isFromLeftToRight(prevValueLength, curValueLength) {
  const firstCase = prevValueLength === 1 && curValueLength === 2;
  const secondCase = prevValueLength === 4 && curValueLength === 5;
  return firstCase || secondCase;
}

function isFromRightToLeft(prevValueLength, curValueLength) {
  const firstCase = prevValueLength === 4 && curValueLength === 3;
  const secondCase = prevValueLength === 7 && curValueLength === 6;
  return firstCase || secondCase;
}
function isOtherCases(curValue) {
  return curValue.match(otherCases) !== null;
}

export function parseDateOfBirthMask(curValue, prevValue, setValue, setError, clearErrors, fieldName) {
  if (isInvalidValue(curValue)) return;

  const lastCharacter = curValue.slice(-1);

  if (isAllowedCharacter(lastCharacter) || !curValue) {
    const prevValueLength = prevValue.length;
    const curValueLength = curValue.length;
    let valueTotal = curValue;

    if (isFromLeftToRight(prevValueLength, curValueLength)) {
      valueTotal = curValue + '/';
      setValue(valueTotal);
    } else if (isFromRightToLeft(prevValueLength, curValueLength)) {
      valueTotal = curValue.slice(0, -1);
      setValue(valueTotal);
    } else if (isOtherCases(curValue)) {
      valueTotal = curValue.replace(/.$/, `/${lastCharacter}`);
      setValue(valueTotal);
    } else {
      setValue(curValue);
    }
    return validateDateOfBirthMask(valueTotal, setError, clearErrors, fieldName);
  }
}
