import React from "react";
import PasswordResetForm from "./PasswordResetForm";
import PasswordResetSuccess from "./PasswordResetSuccess";
import classes from "./LoginPage.module.css";

const PasswordResetPage = (props) => {
  const {
    isSuccess,
    loading,
    onPasswordRestoreSubmit
  } = props;

  return (
    <div className={classes.page}>
      {isSuccess ? (
        <PasswordResetSuccess
          className={classes.content}
        />
      ) : (
        <PasswordResetForm
          className={classes.content}
          onPasswordRestoreSubmit={onPasswordRestoreSubmit}
          loading={loading}
          {...props}
        />
      )}
    </div>
  );
};

export default PasswordResetPage;
