import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const WithScrollToTop = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      {children}
    </>
  );
};
