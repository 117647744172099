import cn from "classnames";
import React from "react";
import PropTypes from "prop-types";

const ModalHeader = ({ children, variant }) => (
  <div className={cn("mdl__header", variant && `mdl__header--${variant}`)}>
    {children}
  </div>
);

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["center"])
};

export default ModalHeader;
