import React from "react";
import Typography from "components/Typography";
import photoPlaceholderLoaderIcon from "components/ModalInspections/components/Signature/photoPlaceholderLoaderIcon.svg";
import classnames from "classnames";
import classes from "./Signature.module.css";

export const Signature = ({ items }) => {
  return (
    <div className={classes.container}>
      <div className={`${classes.signatures} row`}>
        {items.map(({ id, title, url }, index) => (
          <div key={id} className={
            classnames(
              "col-12 col-m-6",
              classes.signaturesItem,
              !index && 'mb-16 mb-m-0'
            )}
          >
            { index && <div className="devider-solid mt-12 mb-12 dn-m" />}
            <Typography
              variant="subtitle"
              size="l"
              weight="bold"
              className={classes.title}
            >
              {title}
            </Typography>

            <div
              className={
                classnames(
                  classes.signature,
                  "mt-16 mt-m-20",
                  !url && classes.hasNotImage
                )}
            >
              {!url && (
                <div className={classes.photoPlaceholder}>
                  <img
                    src={photoPlaceholderLoaderIcon}
                    alt=""
                  />
                </div>
              )}
              {url && <img src={url} alt="" className={classes.img} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
