import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const useUrlParams = () => {
  const location = useLocation();
  const history = useHistory();

  const addParam = useCallback((name, value) => {
    const params = new URLSearchParams(location.search);
    params.set(name, value);
    history.replace({ search: params.toString(), hash: location.hash });
  }, [location, history]);

  const removeParam = useCallback((name) => {
    const params = new URLSearchParams(location.search);
    params.delete(name);
    history.replace({ search: params.toString(), hash: location.hash });
  }, [location, history]);

  const getUrlWithAddedParam = useCallback((name, value) => {
    const params = new URLSearchParams(location.search);
    params.set(name, value);
    return `${window.location.origin}${location.pathname}?${params.toString()}${location.hash}`;
  }, [location]);

  const hasParam = useCallback((name) => {
    const params = new URLSearchParams(location.search);
    return params.has(name);
  }, [location]);

  return { addParam, removeParam, getUrlWithAddedParam, hasParam };
};
