import React, { useState, useEffect, useCallback } from "react";
import { MatchMedia, MQ_BP, useMatchMedia } from "components/MatchMedia";
import { useLazyQuery } from "@apollo/client";
import RcCollapse from "components/RcCollapse";
import { Controller, useForm } from "react-hook-form";
import { FieldInput } from "components/form/FieldInput";
import Form from "components/form/Form/Form";
import { FieldTextarea } from "components/form/FieldTextarea";
import { PhotoAvatar } from "components/PhotoAvatar";
import { PhotoCoverImageSingle } from "components/PhotoCoverImageSingle";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { Button, Text, TextTag } from "components/ContentSection/elements";
import { LabelContent } from "components/ContentSection/LabelContent";
import { userQuery } from "processes/MyAccount/queries";
import Spinner from "components/Spinner/Spinner";
import classes from "./ProfileContent.module.css";

const defaultValues = {
  firstName: "",
  lastName: "",
  zip: "",
  profileDescription: ""
};

export const ProfileContent = (props) => {
  const {
    content,
    needsModeration,
    myProfileData,
    handleUpdateProfile,
    updateProfileResultData,

    avatarUrl,
    avatarEditToolData,
    onAvatarUpload,
    onAvatarEdit,

    coverContent,
    coverEditToolData,
    onCoverUpload,
    onCoverEdit,
    onCoverDelete
  } = props;
  const [
    profileContentSpinnerLoading,
    setProfileContentSpinnerLoading
  ] = useState(false);
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isNeedsModeration, setNeedsModeration] = useState(needsModeration);
  const [fieldTouched, setFieldTouched] = useState(false);

  const [showDateChangedNotification, setShowDateChangedNotification] = useState(false);

  const [userQueryLoad, { data: userQueryData }] = useLazyQuery(userQuery, {
    fetchPolicy: "no-cache"
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    clearErrors
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      firstName: myProfileData?.first_name,
      lastName: myProfileData?.last_name,
      zip: myProfileData?.zip,
      profileDescription: myProfileData?.about
    }
  });
  const { firstName, lastName, zip, profileDescription } = watch([
    "firstName",
    "lastName",
    "zip",
    "profileDescription"
  ]);

  useEffect(() => {
    if (userQueryData)
      setNeedsModeration(userQueryData?.user?.needs_moderation);
  }, [userQueryData]);
  useEffect(() => {
    if (updateProfileResultData) {
      setProfileContentSpinnerLoading(false);
    }
  }, [updateProfileResultData]);
  useEffect(() => {
    setFieldTouched(true);
    setIsSubmitDisabled(true);
    if (firstName) clearErrors("firstName");
    if (lastName) clearErrors("lastName");
    if (zip) clearErrors("zip");

    if (firstName && lastName && zip && fieldTouched)
      setIsSubmitDisabled(false);
  }, [clearErrors, firstName, lastName, zip, profileDescription]);

  const onSubmit = () => {
    handleUpdateProfile({
      first_name: firstName,
      last_name: lastName,
      zip: zip,
      about: profileDescription,
      // profile_image_remove: !avatarUrl,
      cover_image_remove: !coverContent
    });
    setIsSubmitDisabled(true);
    userQueryLoad();
    setProfileContentSpinnerLoading(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    setShowDateChangedNotification(true);
  };

  const handleCoverDelete = useCallback(() => {
    setIsSubmitDisabled(false);
    onCoverDelete({
      first_name: firstName,
      last_name: lastName,
      zip,
      about: profileDescription,
      cover_image_remove: true
    });
  }, [firstName, lastName, onCoverDelete, profileDescription, zip]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} protect>
      <div className="">
        {profileContentSpinnerLoading && (
          <Spinner className={classes.profileContentSpinner} />
        )}
        <div className="">
          <Text
            id="title"
            section="myProfileSectionContent"
            component="h2"
            variant="headline"
            size="s"
          />
          <MatchMedia mediaQuery="(max-width: 767px)">
            <div className="t-16 b-32">
              <RcCollapse
                header={content.proTipsSectionTitle}
                content={content.proTipsSectionText}
              />
            </div>
          </MatchMedia>
          {isNeedsModeration && (
            <div className="t-16 b-16 mt-m-4 mt-xl-4">
              <NotificationPopover
                show={true}
                variant="static"
                status="default"
                text={
                  <TextTag
                    id="success_message_text"
                    section="myProfileSectionContent"
                  />
                }
              />
            </div>
          )}
          <div className="mt-m-4">
            <Text
              id="general_info_section_title"
              section="myProfileSectionContent"
              component="h6"
              variant="subtitle"
              size="l"
              weight="bold"
            />
          </div>
          <div className="db df-m fb t-16 b-16 t-m-24 row">
            <div className="col-12 col-m-4 t-16 b-16">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: "Required field" }}
                render={(renderProps) => (
                  <LabelContent
                    section="myProfileSectionContent"
                    id="first_name"
                  >
                    {(label) => (
                      <FieldInput
                        {...renderProps}
                        label={label}
                        id="firstName"
                        className="fw"
                        defaultValues={myProfileData?.first_name}
                        errors={errors}
                      />
                    )}
                  </LabelContent>
                )}
              />
            </div>
            <div className="col-12 col-m-4 t-16 b-16">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "Required field" }}
                render={(renderProps) => (
                  <LabelContent
                    section="myProfileSectionContent"
                    id="last_name"
                  >
                    {(label) => (
                      <FieldInput
                        {...renderProps}
                        label={label}
                        id="lastName"
                        className="fw"
                        errors={errors}
                      />
                    )}
                  </LabelContent>
                )}
              />
            </div>
            <div className="col-12 col-m-4 t-16 b-16 r-l-4 r-xl-0">
              <Controller
                name="zip"
                control={control}
                rules={{ required: "Required field" }}
                render={(renderProps) => (
                  <LabelContent section="myProfileSectionContent" id="zip">
                    {(label) => (
                      <FieldInput
                        {...renderProps}
                        label={label}
                        id="zip"
                        className="fw"
                        errors={errors}
                      />
                    )}
                  </LabelContent>
                )}
              />
            </div>
          </div>
          <div className="mt-m-8">
            <Text
              id="profile_image_section_title"
              section="myProfileSectionContent"
              component="h6"
              variant="subtitle"
              size="l"
              weight="bold"
            />
          </div>
          <div className="t-16 b-16 mt-l-4 b-l-12 mt-m-4">
            <div className={`${classes.avatarUpload}`}>
              <PhotoAvatar
                avatarUrl={avatarUrl}
                editToolData={avatarEditToolData}
                onUpload={(files) => {
                  setIsSubmitDisabled(false);
                  onAvatarUpload(files, "profile");
                }}
                onEditDone={(values) => {
                  setIsSubmitDisabled(false);
                  onAvatarEdit(values, "profile");
                }}
                isEdit
                disabled={false}
              />
            </div>
          </div>
          <div className="t-m-24 mt-l-4">
            <Text
              id="cover_image_section_title"
              section="myProfileSectionContent"
              component="h6"
              variant="subtitle"
              size="l"
              weight="bold"
            />
          </div>
          <div className="t-16 b-32 b-m-20 t-l-20 mt-m-4 mt-l-0">
            <div className={`${classes.coverUpload}`}>
              <PhotoCoverImageSingle
                coverContent={coverContent}
                isEdit
                editToolData={coverEditToolData}
                onEditDone={(values) => {
                  setIsSubmitDisabled(false);
                  onCoverEdit(values, "cover");
                }}
                onUpload={(files) => {
                  onCoverUpload(files, "cover");
                  setIsSubmitDisabled(false);
                }}
                onCoverDelete={handleCoverDelete}
                disabled={false}
              />
            </div>
          </div>
          <Text
            id="description_section_title"
            section="myProfileSectionContent"
            component="h6"
            variant="subtitle"
            size="l"
            weight="bold"
          />
          <div className="row">
            <div className="col-12 t-16 t-m-20 b-16 b-m-20">
              <Controller
                name="profileDescription"
                control={control}
                render={(renderProps) => (
                  <FieldTextarea
                    {...renderProps}
                    resize="none"
                    rows={2}
                    className="fw"
                    id="profile_description"
                    maxLength={1500}
                    errors={errors}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-32 mt-m-20">
        <div className="col b-32 b-m-40">
          <div className="btn-group">
            <Button
              id="save_button"
              section="myProfileSectionContent"
              type="submit"
            />
          </div>
        </div>
      </div>
      {showDateChangedNotification && (
        <NotificationPopover
          show
          status="success"
          title="Success!"
          text={"You changes have been saved successfully"}
          onClose={() => {
            setShowDateChangedNotification(false);
            setIsSubmitDisabled(true);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}
    </Form>
  );
};
