import React from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import { ReviewsSelect } from "components/Reviews/components/ReviewsSelect/ReviewsSelect";
import { ReviewsList } from "components/Reviews/components/ReviewsList/ReviewsList";
import classes from "./Reviews.module.css";

export const Reviews = ({ hasSelects = false, data, count, selectVehicleOptions }) => {
  return (
    <div className={classes.container}>
      <div className={``}>
        <Typography variant="subtitle" size="l" weight="bold">
          Reviews ({count})
        </Typography>
      </div>

      {hasSelects && (
        <div className={`${classes.reviewsSelectContainer} mb-16 mt-16 mb-m-20 mt-m-20`}>
          <ReviewsSelect vehicleOptions={selectVehicleOptions} />
        </div>
      )}

      <ReviewsList data={data} />

    </div>
  );
};

Reviews.propTypes = {
  photo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  name: PropTypes.string,
  rating: PropTypes.number,
  date: PropTypes.string,
  description: PropTypes.string,
  galeryPhotos: PropTypes.array
};
