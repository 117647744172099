import { useRef, useEffect } from "react";

export function useWidgetHeight(iframeNumber) {
  const ref = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const element = ref.current;
    let observer;

    if (!observerRef.current) {
      observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const height = Math.ceil(
            entry.contentBoxSize && entry.contentBoxSize.length > 0
              ? entry.contentBoxSize[0].blockSize
              : entry.contentRect.height
          );

          if (iframeNumber) {
            window.parent.postMessage({
              "type": "rv-rentals-widget:iframe-height",
              "height": height + "px",
              iframeNumber
            },"*");
          }

        });
      });

      observerRef.current = observer;

      observer.observe(element);
    } else {
      observer = observerRef.current;
    }

    return () => {
      if (observer) {
        observer.unobserve(element);
        observer = null;
        observerRef.current = null;
      }
    };
  }, [iframeNumber, ref]);

  return ref;
}
