import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import ChatView from "./ChatView";
import Button from "../Button";
import { chatIcon } from "../Icon";
import { MQ_BP, useMatchMedia } from "../MatchMedia";
import { useChatOpponents } from "./hooks/useChatOpponents";
import ChatProvider from "./ChatProvider";
import { useChatContext } from "./ChatContext";
import { CHAT_OPEN_EVENT_ID } from "./utils";
import classes from "./ChatPopup.module.css";
import { useOnResize } from "./useOnResize";
import { useChatTopPosition } from "components/chat/hooks/useChatTopPosition";


function toggleOverlayOpenClass(isMediumDevice, isOpen) {
  if (isMediumDevice) {
    if (isOpen) {
      document.body.classList.remove('overlay-open');
    } else {
      document.body.classList.add('overlay-open');
    }
  } else {
    document.body.classList.remove('overlay-open');
  }
}

const ChatPopup = ({ chatContainerSelector }) => {
  const [open, setOpen] = useState(false);
  const isMediumDevice = useMatchMedia({ maxWidth: MQ_BP.medium });
  const { chatOpponents } = useChatOpponents();

  useEffect(() => {
    if (open) {
      document.documentElement.style.setProperty('--chat-zendesk-z-index', 100);
    } else {
      document.documentElement.style.removeProperty('--chat-zendesk-z-index');
    }
  }, [open]);

  const _close = useCallback(() => {
    toggleOverlayOpenClass(isMediumDevice, open);
    setOpen(false);
  }, [isMediumDevice, open]);

  const _open = useCallback(() => {
    toggleOverlayOpenClass(isMediumDevice, open);
    setOpen(true);
  }, [isMediumDevice, open]);

  const _toggle = useCallback(() => {
    toggleOverlayOpenClass(isMediumDevice, open);
    setOpen(p => !p);
  }, [isMediumDevice, open]);

  useOnResize(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--chat-height', `${window.innerHeight}px`);
  });

  if (!chatOpponents) {
    return null;
  }

  return (
    <ChatProvider chatOpponents={chatOpponents}>
      <OpenEventListener onFire={_open} />
      <Content
        open={open}
        close={_close}
        toggle={_toggle}
        chatContainerSelector={chatContainerSelector}
      />
    </ChatProvider>
  );
};

function convertToInteger(value, defaultValue) {
  const n = typeof value !== 'number' ? parseFloat(value) : value;

  return Number.isNaN(n) ? defaultValue : Math.round(n);
}

const Content = ({ open, close, toggle, chatContainerSelector }) => {
  const { selectThread, totalUnread, threads, getThreadUnreadCount } = useChatContext();
  useChatTopPosition();

  useEffect(() => {
    const chatContainer = document.getElementById(chatContainerSelector);

    if (chatContainer) {
      // const total = convertToInteger(totalUnread, 0);

      // only Rentals unread
      const total = threads.reduce((acc, thread) => thread.uniqueName.endsWith('_exchange') ? acc : acc + getThreadUnreadCount(thread), 0);
      chatContainer.setAttribute('data-total-unread', String(total));

      chatContainer.dispatchEvent(new CustomEvent("set-total-unread", {
        detail: {
          total
        }
      }));
    }
  }, [totalUnread, chatContainerSelector, threads, getThreadUnreadCount]);

  useEffect(() => {
    const chatContainer = document.getElementById(chatContainerSelector);

    if (chatContainer) {
      chatContainer.addEventListener("toggle-chat", toggle);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("toggle-chat", toggle);
      }
    };
  }, [toggle, chatContainerSelector]);

  const _onClose = useCallback(() => {
    close();
    // Closing active thread: this is the simplest way to update
    // unread messages count for previously active thread
    // (because of messages auto-reading for open thread)
    selectThread(null);
  }, [close, selectThread]);

  return (
    <div className={classnames({ [classes.popupOpen]: open })}>
      <ToggleButton toggle={toggle} />
      <div className={classes.popup}>
        <ChatView onClose={_onClose} />
      </div>
    </div>
  );
};

const OpenEventListener = ({ onFire }) => {
  const { setActiveThreadIfExists } = useChatContext();

  useEffect(() => {
    if (onFire && setActiveThreadIfExists) {
      function onShowChat(event) {
        onFire();

        const id = event.detail?.id;

        if (id) {
          setActiveThreadIfExists(id);
        }
      }

      document.addEventListener(CHAT_OPEN_EVENT_ID, onShowChat);

      return () => {
        document.removeEventListener(CHAT_OPEN_EVENT_ID, onShowChat);
      };
    }
  }, [onFire, setActiveThreadIfExists]);

  return null;
};

const ToggleButton = ({ toggle }) => {
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const { hasUnreadMessages } = useChatContext();

  return (
    <div className={classes.buttonContainer}>
      {hasUnreadMessages && (
        <div className={classes.unreadIndicator} />
      )}
      <Button
        className={classes.button}
        icon={chatIcon}
        iconPosition="after"
        onClick={toggle}
        size={isSmallDevice ? "medium" : undefined}
        inverse
      />
    </div>
  );
};

export default ChatPopup;
