import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import BookingPaymentOptionsTable from "./BookingPaymentOptionsTable/BookingPaymentOptionsTable";
import { RESERVE_ERRORS } from "constants/errorCodes";
import Typography from "components/Typography";
import { FieldTextarea } from "components/form/FieldTextarea";
import { useForm, useWatch } from "react-hook-form";
import Form from "components/form/Form/Form";
import { FieldController } from "components/form/FieldController/FieldController";
import StepContainer from "processes/Booking/components/BookingModal/components/StepContainer/StepContainer";
import { RENTAL_TYPE } from "utils/constants/rentalType";
import Button from "components/Button";
import Checkbox from "components/form/Checkbox";
import { VALIDATION } from "utils/validation";
import { SpinnerBack } from "components/Spinner/Spinner";
import { withDisableButtonOnQuery } from "processes/Booking/withDisableButtonOnQuery";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { checkIMGlobalIntegrationIsEnabled } from "utils/featureFlags/checkIMGlobalIntegrationIsEnabled";
import { STEPS } from "components/RentalPaymentModal/constants/steps";
import { initialDataQuery } from '../../queries/initialDataQuery';
import { acceptPredefinedSuggestion } from '../../mutations/acceptPredefinedSuggestion';
import { ADD_INSURANCE_DEFAULT_TAGS } from "components/AdditionalInsuranceList/constants";
import { getErrorsAndSuccess } from "utils/extractErrors";
import classes from "./BookingPaymentOptionsTable.module.css";

const ConfirmButton = withDisableButtonOnQuery(Button);

const acceptPredefinedSuggestionEAS = getErrorsAndSuccess("accept_predefined_suggestion");

const BookingPaymentOptions = ({
  price,
  rental,
  modalRef,
  isVisible,
  rental_type,
  hasScrollShadow,
  isInterruptionVisible,
  coupon,
  membershipId,
  insuranceId,
  calculatePriceLoading,
  calculatePriceError,
  setCoupon,
  setIsInterruptionVisible,
  setMembershipId,
  setInsuranceId,
  refetch,
  onCloseRentalPaymentModal,
  onStartDriverVerify,
  price_form_detailed,
  isNoSplitPayments,
  setIsNoSplitPayments,
  additional_insurances,
  setError: setErr,
  onClickStepById,
  onRefetchAdditionalInsuranceList,
  setSelectedPackagesAdditionalInsurance,
  selectedPackagesAdditionalInsurance,
  tiiDefaultTags,
  setIsClearTii
}) => {

  const { loading, data } = useQuery(initialDataQuery, {
    variables: {
      rvId: rental.rv.id,
      for_listing: false,
      features: ["IMGlobal Integration Is Enabled"],
      rental_type: Number(rental.rental_type.key)
    },
    fetchPolicy: "no-cache"
  });

  const isIMGlobalIntegrationIsEnabled = checkIMGlobalIntegrationIsEnabled(data);

  const [acceptAction, acceptResult] = useMutation(acceptPredefinedSuggestion);

  const isMinHeightMedium = useMatchMedia({ mediaQuery: "(min-width: 1024px)" });

  const [isShowNotificationPopoverAccept, setShowNotificationPopoverAccept] = useState(false);

  const [acceptError, setAcceptError] = useState(null);

  const [
    isShowNotificationPopoverPrice,
    setShowNotificationPopoverPrice
  ] = useState(false);

  const [acceptLoading, setAcceptLoading] = useState(false);

  const isMinSmallMaxMedium = useMatchMedia({
    minWidth: MQ_BP.small,
    maxWidth: MQ_BP.medium
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      interruption: "",
      dateOfBirth: "",
      message: "",
      agreement: false
    }
  });

  const isAgreed = useWatch({ control, name: "agreement" });

  const totalPrice = price?.prepayment_for_rental?.user_friendly;

  const agreementField = (
    <FieldController
      name="agreement"
      control={control}
      rules={VALIDATION.rules.trueCheckbox}
      render={(renderProps) => (
        <Checkbox
          {...renderProps}
          className="mr-32"
          text={
            <div>
              I verify that I meet all the Good Sam RV Rentals &nbsp;
              <a href="/terms-of-service" target="_blank">
                Terms of Service.
              </a>
            </div>
          }
          hasError={!!errors?.agreement}
          onChange={(event) => {
            renderProps.onChange(event.target.checked);
          }}
        />
      )}
    />
  );

  const onAcceptSubmit = useCallback(async ({
    message
  }) => {
    try {
      setAcceptLoading(true);
      const response = await acceptAction({
        variables: {
          id: rental.id,
          insurance_package: insuranceId,
          good_sam_membership_package: membershipId,
          coupon_code: coupon?.code,
          no_split_payments: isNoSplitPayments,
          signature_name: tiiDefaultTags[ADD_INSURANCE_DEFAULT_TAGS.NAME],
          date_of_birth: tiiDefaultTags[ADD_INSURANCE_DEFAULT_TAGS.BIRTH],
          additional_insurances
        }
      });

      if (response.data?.accept_predefined_suggestion?.result?.success) {
        onCloseRentalPaymentModal();
      }

      if (acceptPredefinedSuggestionEAS.isErrors(response)) {
        const errorCode = acceptPredefinedSuggestionEAS.errorCode(response);
        const errorMessage = acceptPredefinedSuggestionEAS.errorMessage(response);

        switch (errorCode) {
        case RESERVE_ERRORS.AVAILABILITY_DELIVERY:
          setErr(errorMessage);
          break;
        case RESERVE_ERRORS.BOOKING_RENTAL_FEE_LIMIT:
          setErr(errorMessage);
          break;
        case RESERVE_ERRORS.BOOKING_TII_NOT_APPLICABLE:
          setErr(errorMessage);
          break;
        case RESERVE_ERRORS.AVAILABILITY_ADDITIONAL_INSURANCE:
          onClickStepById(STEPS.ADDITIONAL_INSURANCE.ID);
          setErr(errorMessage);
          await onRefetchAdditionalInsuranceList();
          break;
        default:
          break;
        }

        setShowNotificationPopoverAccept(true);
        setAcceptError(acceptPredefinedSuggestionEAS.errorMessage(response));
        setAcceptLoading(false);
      }

    } catch (e) {
      setAcceptLoading(false);
      setShowNotificationPopoverAccept(true);
      setAcceptError(e.message);
    }
  }, [acceptAction, rental.id, insuranceId, membershipId, coupon?.code, isNoSplitPayments, tiiDefaultTags, additional_insurances, onCloseRentalPaymentModal, setErr, onClickStepById, onRefetchAdditionalInsuranceList]);

  const onAcceptError = useCallback(() => {
    console.info('onAcceptError');
  }, []);

  const buttons = (
    <>
      {(isMinSmallMaxMedium || isMinHeightMedium) && agreementField}
      {totalPrice && (
        <ConfirmButton
          label={`Confirm and pay`}
          onClick={handleSubmit(onAcceptSubmit, onAcceptError)}
          disabled={!isAgreed}
          className={classes.confirmButton}
        />
      )}
    </>
  );

  const onCloseNotificationPopoverPrice = useCallback(() => {
    setShowNotificationPopoverPrice(false);
  }, []);

  return (
    <StepContainer
      buttons={buttons}
      buttonsClassName={classes.buttons}
      hasScrollShadow={hasScrollShadow}
      isPaymentOptions
    >
      {calculatePriceLoading && <SpinnerBack />}

      {acceptLoading && <SpinnerBack />}

      {calculatePriceError && (
        <NotificationPopover
          show={isShowNotificationPopoverPrice}
          status="error"
          text={calculatePriceError}
          onClose={onCloseNotificationPopoverPrice}
        />
      )}

      {price && (
        <Form>
          <BookingPaymentOptionsTable
            rvData={rental.rv}
            prices={price}
            price_form_detailed={price_form_detailed}
            user={data?.user}
            addOns={rental.price?.add_ons}
            membershipId={membershipId}
            insuranceId={insuranceId}
            coupon={coupon}
            isInterruptionVisible={isInterruptionVisible}
            isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
            setCoupon={setCoupon}
            setIsInterruptionVisible={setIsInterruptionVisible}
            setMembershipId={setMembershipId}
            setInsuranceId={setInsuranceId}
            isNoSplitPayments={isNoSplitPayments}
            setIsNoSplitPayments={setIsNoSplitPayments}
            rental_type={rental_type}
            onClickStepById={onClickStepById}
            setSelectedPackagesAdditionalInsurance={setSelectedPackagesAdditionalInsurance}
            selectedPackagesAdditionalInsurance={selectedPackagesAdditionalInsurance}
            setIsClearTii={setIsClearTii}
          />

          {!isMinSmallMaxMedium && !isMinHeightMedium && <div className="t-8">{agreementField}</div>}
        </Form>
      )}

      {isShowNotificationPopoverAccept && (
        <NotificationPopover
          show={isShowNotificationPopoverAccept}
          status="error"
          text={acceptError}
          onClose={() => {
            setShowNotificationPopoverAccept(false);
          }}
        />
      )}

    </StepContainer>
  );
};

BookingPaymentOptions.propTypes = {
  /**
   * Provide modal ref, so tooltips may determine boundaries to fit
   */
  modalRef: PropTypes.object
};

export default BookingPaymentOptions;
