import { gql } from "@apollo/client";

export const submitReview = gql`
  mutation submitReview (
    $rental: Int! 
    $rating: Int!  
    $testimonial: String!  
    $session_id: String
    $photos_order: [Int]
    $photos_to_delete: [Int]
  ) {
    submit_review (
      rental: $rental
      rating: $rating 
      testimonial: $testimonial 
      session_id: $session_id
      photos_order: $photos_order
      photos_to_delete: $photos_to_delete
    ) {
        result {
          success
          errors {
            code
            message
          }
        }
    }
  }
`;
