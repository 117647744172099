/*
 * Filters Utils
 */
import size from "lodash/size";
import { RENTAL_ROLES, RENTAL_TYPES } from "processes/Rentals/utils";

const isFilterSet = (id, value) => {
  return value === true;
};

const getSettedFiltersIds = (ids, values) => {
  return ids.filter(name => {
    return isFilterSet(name, values[name]);
  });
};

const filterWithCheckboxes = (item, filters, checkboxes, getProp) => {
  const activeFilters = getSettedFiltersIds(
    Object.keys(checkboxes || {}),
    filters
  );

  return activeFilters.length === 0
    ? true : activeFilters.some(id => {
      if (filters[id]) {
        return getProp(item) === checkboxes[id];
      } else {
        return true;
      }
    });
};

const roles = {
  "i_am_renter": RENTAL_ROLES.renter,
  "i_am_owner": RENTAL_ROLES.owner
};
const types = {
  "standard_rental": RENTAL_TYPES.standard_rental,
  "delivery_only_rental": RENTAL_TYPES.delivery_only_rental
};
export const filterList = (list, filters, statuses) => {
  if (!size(list) || !Array.isArray(list)) {
    return [];
  }

  if (Object.keys(filters || {}).length === 0) {
    return list;
  }

  const statusesObject = statuses.reduce((acc, item) => {
    acc[item.id] = item.group;
    return acc;
  }, {});

  return list.filter(item => {
    const isActiveRole = filterWithCheckboxes(
      item, filters, roles, item => item.role.key
    );
    const isActiveStatus = filterWithCheckboxes(
      item, filters, statusesObject, item => item.status.group
    );

    const isActiveType = filterWithCheckboxes(
      item, filters, types, item => item.rental_type.key
    );
    return isActiveRole && isActiveStatus && isActiveType;
  });
};
