import React from "react";
import DescriptionCollapse from "components/DescriptionCollapse/DescriptionCollapse";
import { AdditionalInsurancePackageItem } from "components/AdditionalInsuranceList";
import { ADD_INSURANCE_LIST_MODES, ADD_INSURANCE_EXTRA_IDS } from "components/AdditionalInsuranceList/constants";
import cn from "classnames";
import classes from "./AdditionalInsuranceSingleItemContent.module.css";

const AdditionalInsuranceSingleItemContent = (props) => {
  const {
    description,
    mode,
    itemPackages,
    multi_select,
    isItemPackagesDisabled,
    selectedPackages
  } = props;

  return (
    <div className={classes.sectionContainer}>
      {mode === ADD_INSURANCE_LIST_MODES.USING && itemPackages.map(({ id, name, description, value }) => (
        <section className={cn(classes.section, multi_select && classes.sectionMultiSelect)} key={id}>
          <AdditionalInsurancePackageItem
            packageId={id}
            name={name}
            description={description}
            value={value}
            multi_select={multi_select}
            isItemPackagesDisabled={isItemPackagesDisabled}
            selectedPackages={selectedPackages}
            onChange={() => {}}
          />
        </section>
      ))}

      {mode === ADD_INSURANCE_LIST_MODES.SETTINGS && (
        <DescriptionCollapse
          description={description}
        />
      )}
    </div>
  );
};

AdditionalInsuranceSingleItemContent.propTypes = {
};

export default AdditionalInsuranceSingleItemContent;
