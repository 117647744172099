import React from "react";
import Modal from "components/Modal/Modal";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "./ModalChoice.module.css";

export const ModalChoice = (props) => {
  const {
    show,
    onClose,
    onAction,
    title = "",
    description = "",
    cancelButtonContent = "Back",
    actionButtonContent = "Cancel rental"
  } = props;

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalClassnames={classes.modal}
      variant="basic"
    >
      <div>
        <Typography variant="headline" size="m" weight="bold" className={classes.title}>
          {title}
        </Typography>

        <div className="mt-m-2 mt-l-0">
          <Typography variant="subtitle" size="s" className={classes.description}>
            {description}
          </Typography>
        </div>

        <div className="mt-16 mt-m-20">
          {typeof cancelButtonContent === 'function' ? (
            cancelButtonContent()
          ) : (
            <Button
              secondary
              onClick={onClose}
              className="mr-16 mr-l-32"
            >
              {cancelButtonContent}
            </Button>
          )}

          {typeof actionButtonContent === 'function' ? (
            actionButtonContent()
          ) : (
            <Button
              onClick={onAction}
            >
              {actionButtonContent}
            </Button>
          )}
        </div>
      </div>
    </Modal>

  );
};
