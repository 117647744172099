import { from, InMemoryCache, Observable } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { retrieveTokens, storeTokens } from "./tokens";
import { loginRedirectLink } from "./loginRedirectLink";
import { authenticatedUserLink } from "./authenticatedUserLink";
import { makeRefreshRequest } from "./makeRefreshRequest";
import { CustomClient } from "./CustomClient";
import { waitingLineLink } from "./waitingLineLink";
import { forceLogout } from "./forceLogout";
import { makeLogoutRequest } from "./makeLogoutRequest";

const REACT_APP_API_URL =
  process.env.NODE_ENV === "production"
    ? window.location.origin + "/api"
    : process.env.REACT_APP_API_URL;

const REACT_APP_API_KEY = window?.CWRR?.AppAPIKey || process.env.REACT_APP_API_KEY;

if (!REACT_APP_API_URL) {
  throw new Error("API endpoint is not defined");
}

if (!REACT_APP_API_KEY) {
  throw new Error("API key is not defined");
}

function is400(networkError) {
  return networkError?.statusCode === 400;
}

function is401(networkError) {
  return networkError?.statusCode === 401;
}

const authErrorLink = onError(({ networkError, operation, forward }) => {
  if (is400(networkError)) {
    // need to logout
    return new Observable(async () => {
      storeTokens(null, null, null, null);
      await makeLogoutRequest(window.location.origin).then(() => forceLogout());
    });
  }

  if (is401(networkError)) {
    // need to refresh the tokens
    return new Observable(observer => {
      makeRefreshRequest()
        .then(() => {
          // retry the operation
          forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        });
    });
  }
});

const uploadLink = createUploadLink({
  uri: REACT_APP_API_URL,
  headers: {
    "X-API-Key": REACT_APP_API_KEY
  },
  fetch(input, init) {
    const { accessToken, idToken } = retrieveTokens();
    let options = init;

    if (accessToken) {
      const { headers = {} } = init;

      options = {
        ...init,
        headers: {
          ...headers,
          "Authorization": `Bearer ${accessToken}`,
          "X-ID-Token": `${idToken}`
        }
      };
    }

    return window.fetch(input, options);
  }
});

export const client = new CustomClient({
  link: from([
    waitingLineLink,
    authenticatedUserLink,
    loginRedirectLink,
    authErrorLink,
    uploadLink
  ]),
  cache: new InMemoryCache()
});
