import React, { useState, useEffect, useCallback } from "react";
import { DriverVerifyModal } from "components/Verification/DriverVerifyModal/DriverVerifyModal";
import { useUrlParams } from "components/Verification/DriverVerifyModal/hooks/useUrlParams";
import { ACTION } from "./constants";

export const DriverVerifyModalCheck = ({
  show,
  isNeedVerify,
  paramData = {},
  onComplete,
  onClose = () => {},
  onDriverVerificationModalSubmitSuccess = () => {},
  redirectURL = ""
}) => {
  const { hasParam, removeParam } = useUrlParams();

  const [showDriverVerification, setShowDriverVerification] = useState(false);

  const {
    name: paramName = ACTION.NAME
  } = paramData;

  const handleOnComplete = useCallback(
    () => {
      setShowDriverVerification(false);
      onComplete();
    },
    [onComplete]
  );

  const handleOnClose = useCallback(
    () => {
      setShowDriverVerification(false);
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    if (isNeedVerify && hasParam(paramName)) {
      setShowDriverVerification(true);
      removeParam(paramName);
    }
  }, [handleOnComplete, hasParam, isNeedVerify, paramName, removeParam]);

  if (isNeedVerify && (show || showDriverVerification)) {
    return (
      <DriverVerifyModal
        onComplete={handleOnComplete}
        onClose={handleOnClose}
        onDriverVerificationModalSubmitSuccess={onDriverVerificationModalSubmitSuccess}
        redirectURL={redirectURL}
        show={show || showDriverVerification}
      />
    );
  }

  return null;
};
