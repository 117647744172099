import React, { useCallback, useEffect, useRef, useState } from "react";
import { ListingContext } from "../../ListingContext";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  defaultTo,
  filter,
  pick,
  pipe,
  map,
  path
} from "ramda";
import { AdditionalInsuranceContentLoader } from "./AdditionalInsuranceContentLoader";
import { additionalInsuranceMutation } from "components/Listing/mutations/additionalInsuranceMutation";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { additionalInsuranceSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";
import { getErrorsAndSuccess } from "utils/extractErrors";

const additionalInsuranceMutationEAS = getErrorsAndSuccess("rv_update");
const extractRv = path(["data", "rv_update", "rv"]);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "additional_insurance")
);

const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "additional_insurance"),
  map(pick(["field", "message"]))
);

const AdditionalInsuranceDataProvider = ({
  context,
  updateContext,
  currStep,
  prevStep,
  nextStep
}) => {
  const { id: initialId } = context.initialData;
  const isSegmentSent = useRef(null);
  const history = useHistory();
  const [action, result] = useMutation(additionalInsuranceMutation);
  const [requestErrors, setRequestErrors] = useState([]);

  const handleSave = useCallback(
    async (insurances, { toNextStep = true } = {}) => {
      const response = await action({
        variables: {
          id: initialId,
          additional_insurances: insurances
        }
      });

      if (additionalInsuranceMutationEAS.isSuccess(response)) {
        const rv = extractRv(response);
        pushGoogleAnalytics("listVehicleAdditionalInsurance");
        handleListingSegment(rv, "Lead Form Step Completed", "Additional Insurance", 8, {
          cust_asset_class: rv?.class,
          cust_asset_id: String(rv?.id),
          cust_asset_make: rv?.make,
          cust_asset_model: rv?.model,
          cust_asset_year: String(rv?.year),
          cust_asset_length: rv?.length?.inches_total,
          cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
          cust_asset_sleepnumber: rv?.sleeps,
          cust_asset_slideouts: rv?.slide_outs,
          cust_asset_weight: rv?.weight
        });
        updateContext({
          initialData: {
            ...context.initialData,
            ...rv
          },
          errors: cleanSectionErrors(context.errors)
        });

        if (nextStep && toNextStep) {
          history.push(`/listing/${nextStep.path}?id=${rv.id}`, {
            prevStep: currStep
          });
        }
      }
    },
    [
      action,
      initialId,
      updateContext,
      context.initialData,
      context.errors,
      nextStep,
      history,
      currStep
    ]
  );

  const onResetError = useCallback(() => {
    updateContext({
      errors:[]
    });
    setRequestErrors([]);
  }, [updateContext]);

  const handleBack = useCallback(() => {
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);

  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  useEffect(() => {
    if (!isSegmentSent.current) {
      const rv = context?.initialData;
      handleListingSegment(context?.initialData, "Lead Form Step Viewed", "Additional Insurance", 8, {
        cust_asset_class: rv?.class,
        cust_asset_id: String(rv?.id),
        cust_asset_make: rv?.make,
        cust_asset_model: rv?.model,
        cust_asset_year: String(rv?.year),
        cust_asset_length: rv?.length?.inches_total,
        cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
        cust_asset_sleepnumber: rv?.sleeps,
        cust_asset_slideouts: rv?.slide_outs,
        cust_asset_weight: rv?.weight
      });
      isSegmentSent.current = true;
    }
  }, [context?.initialData]);

  useEffect(() => {
    if (context?.errors?.length > 0) {
      setRequestErrors(filterPublishErrorsBySection(context.errors));
    } else {
      setRequestErrors([]);
    }
  }, [context?.errors]);

  return (
    <>
      <AdditionalInsuranceContentLoader
        initialData={context.initialData}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        loading={result.loading}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        requestErrors={requestErrors}
        onResetError={onResetError}
        prevStep={prevStep}
        nextStep={nextStep}
      />
      {result.loading && <SpinnerBack />}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const additionalInsuranceSectionContentPredicate = everyKey(["additionalInsuranceSectionContent"]);

export const AdditionalInsurance = ({ currStep, prevStep, nextStep }) => (
  <ListingContext.Consumer>
    {(context) => (
      <ContentLoader
        query={additionalInsuranceSectionContentQuery}
        predicate={additionalInsuranceSectionContentPredicate}
        render={() => (
          <AdditionalInsuranceDataProvider
            context={context[0]}
            updateContext={context[1]}
            currStep={currStep}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        )}
      />
    )}
  </ListingContext.Consumer>
);
