import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../Typography";
import Modal from "../../../Modal/Modal";
import style from "./style.module.css";

const HeaderDetailsModal = ({ show, setShow, title, children }) => (
  <Modal
    show={show}
    onClose={() => setShow(false)}
    variant="full-screen"
    modalClassnames={style.mdl}
    render={({ handleClose }) => (
      <>
        <div className="mdl__fs-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Typography component="h6" variant="subtitle" weight="bold">
                  {title}
                </Typography>
                <div
                  className="mdl__cross mdl__cross--fs-header"
                  onClick={handleClose}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mdl__fs-body">
          <div className="mdl__fs-content">
            <div className="container">
              <div className="row">
                <div className="col-12">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  />
);

HeaderDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default HeaderDetailsModal;
