import React from "react";
import { FieldController } from "components/form/FieldController/FieldController";
import Checkbox from "components/form/Checkbox";
import classes from "components/Search/components/MobileFilters/MobileFilterInstantRentals.module.css";

const MobileFilterInstantRentals = ({ filtersControl }) => {

  const { isMobileChecked } = filtersControl.getValues().instantRentalsOnly;
  return (
    <div className={`${classes.container} l-16 r-16`}>
      <FieldController
        name="instantRentalsOnly"
        control={filtersControl}
        render={(renderProps) => (
          <Checkbox
            {...renderProps}
            className={`${classes.instantRentalsOnly}`}
            pseudoCheckClassName={classes.check}
            labelTextClassName={classes.checkboxTxt}
            id={'instantRentalsOnly'}
            name={'instantRentalsOnly'}
            text="Instant rentals only"
            mobileVariant="stretch-reverse"
            onChange={(event) => {
              renderProps.onChange({ ...filtersControl.getValues().instantRentalsOnly, isMobileChecked: event.target.checked });
            }}
            checked={isMobileChecked}
          />
        )}
      />
    </div>
  );
};

MobileFilterInstantRentals.propTypes = {};

export default MobileFilterInstantRentals;
