import { useMemo } from "react";
import orderBy from "lodash/orderBy";
import moment from "moment";
import groupBy from "lodash/groupBy";

export const usePreparedMessages = (messages) => {
  return useMemo(() => {
    if (messages?.length === 0) {
      return [];
    }

    const key = "_dayTimestamp";
    const groupedByDay = groupBy(messages.map(i => {
      return ({
        ...i,
        [key]: moment(i.dateCreated).startOf("day").unix()
      });
    }), key);

    const groupedArray = Object.keys(groupedByDay).reduce((acc, timestamp) => {
      acc.push({ timestamp, messages: groupedByDay[timestamp] });
      return acc;
    }, []);

    return orderBy(groupedArray, ["timestamp"], ["asc"]);
  }, [messages]);
};
