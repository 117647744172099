import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";

const INITIAL_DELAY = 1000;

function useFormProtection(protect, onSubmit) {
  const dateRef = useRef(Date.now());
  const [touched, setTouched] = useState(false);

  const handleTouch = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const handleSubmit = useCallback(
    (e) => {
      if (!touched && onSubmit) {
        if (
          !protect ||
          (protect && Date.now() - dateRef.current) > INITIAL_DELAY
        ) {
          return onSubmit(e);
        }
      }

      e.preventDefault();
      e.stopPropagation();

      return false;
    },
    [protect, onSubmit, touched]
  );

  return [handleSubmit, handleTouch];
}

const Form = ({ children, protect, onSubmit, ...restProps }) => {
  const [handleSubmit, handleTouch] = useFormProtection(protect, onSubmit);

  return (
    <div>
      <form noValidate onSubmit={handleSubmit} {...restProps}>
        {children}
        {protect && (
          <input
            type="checkbox"
            className="visually-hidden"
            name="confirm"
            onChange={handleTouch}
            onFocus={handleTouch}
            tabIndex={-1}
          />
        )}
      </form>
    </div>
  );
};

Form.defaultProps = {
  protect: false
};

Form.propTypes = {
  protect: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form;
