import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import Button from "../../../Button";
import Form from "../../../form/Form/Form";
import Message from "../../../Message";
import Modal from "../../../Modal/Modal";
import ProTipsCollapse from "../../../ProTipsCollapse";
import Typography from "../../../Typography";
import { AvailabilityCalendar } from "./AvailabilityCalendar";
import { AvailabilityFormContent } from "./AvailabilityFormContent";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";

const DEFAULT_VALUES = {
  visible: false
};

export const AvailabilityForm = ({
  initialData,
  requestErrors,
  onSave,
  onDelete,
  updateContextSaveHandler,
  rvId
}) => {
  const defaultValues = Object.assign({}, DEFAULT_VALUES, initialData);
  const {
    control,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues
  });
  useFooterVisible(isDirty);

  const [globalError] = useRequestErrors(requestErrors, setError);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    updateContextSaveHandler(
      handleSubmit((values) => {
        onSave(values);
        reset(getValues(), {
          keepDirty: false
        });
      })
    );
  }, [getValues, handleSubmit, onSave, reset, updateContextSaveHandler]);

  const handleDelete = () => {
    setShowConfirmationModal(false);
    onDelete();
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSave)}>
        <div className="row b-m-20">
          <div className="col">
            <Text
              id="step_title"
              section="availabilitySectionContent"
              component="h4"
              variant="title"
              weight="bold"
            />
            <ProTipsCollapse className="t-16 b-12" mediaParams="767px" />
          </div>
        </div>
        <div className="row b-m-20">
          <div className="col">
            <Controller
              name="availability_calendar"
              control={control}
              render={(renderProps) => (
                <AvailabilityCalendar
                  id="availability_calendar_content"
                  errors={errors}
                  rvId={rvId}
                  dailyRate={initialData.daily_rate}
                  minimumDailyRate={initialData.minimum_daily_rate}
                  {...renderProps}
                />
              )}
            />
          </div>
        </div>
        <AvailabilityFormContent control={control} errors={errors} />
        <div className="devider-solid mt-16 mt-m-20 mb-16 mb-m-20" />
        <div className="row">
          <div className="col t-16 t-m-20 b-48 b-m-20 b-l-40">
            <div className="btn-group">
              <ButtonContent
                id="delete_button"
                section="availabilitySectionContent"
                secondary
                onClick={() => setShowConfirmationModal(true)}
              />
            </div>
          </div>
        </div>
        {globalError && (
          <div className="t-32 t-s-16 t-m-20">
            <Message type="error" text={globalError} />
          </div>
        )}
      </Form>
      <Modal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        modalWrapClassnames="mdl-wrap--full-screen-less-768"
        modalClassnames="mdl--simple mdl--full-screen-less-768"
      >
        <Typography variant="headline" size="s">
          Are you sure you want to delete this vehicle?
        </Typography>
        <Typography variant="body" size="l">
          Deleting this vehicle will remove your progress. To hide it from
          others, simply mark it as deactivated.
        </Typography>
        <div className="btn-group t-12 t-m-20 b-m-20 b-16">
          <Button secondary onClick={() => setShowConfirmationModal(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Delete</Button>
        </div>
      </Modal>
    </>
  );
};
