import { useState, useEffect } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { useHistory } from "react-router-dom";

export function useMobileNavMode() {
  const [isMobileNavMode, setIsMobileNavMode] = useState(false);
  const isMediumDevice = useMatchMedia({ minWidth: MQ_BP.small });
  const history = useHistory();

  useEffect(() => {
    const hasMenu = history?.location?.state?.menu;

    if (hasMenu) setIsMobileNavMode(true);
    if (isMediumDevice) {
      setIsMobileNavMode(false);

      if (hasMenu) {
        history.replace({ pathname: '/account/general-settings', state:{ menu: false } });
      }
    }
  }, [history, history?.location?.state, isMediumDevice]);

  return [isMobileNavMode, setIsMobileNavMode];
}
