import React from "react";
import PropTypes from "prop-types";
import { useFieldArray } from "react-hook-form";
import get from "lodash/get";

function hasError(errors, name) {
  return Boolean(errors && get(errors, name));
}

export const FieldArray = ({
  control,
  trigger,
  name,
  defaultValues,
  renderRow,
  errors,
  render,
  onRemove
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  const handleAdd = async () => {
    if (hasError(errors, name)) return undefined;
    if (fields.length > 0) {
      const lastFieldIndex = fields.length - 1;
      const lastFieldNames = Object.entries(fields[lastFieldIndex]).reduce(
        (prev, curr) => {
          if (curr[0] === "id") return prev;
          return [...prev, `${name}[${lastFieldIndex}].${curr[0]}`];
        },
        []
      );
      const validated = await trigger(lastFieldNames);
      if (!validated) return undefined;
    }
    append(defaultValues);
  };

  const handleRemove = (index) => {
    remove(index);
    if (onRemove) {
      onRemove(index, fields.length - 1);
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          {renderRow({
            fieldName: name,
            rowIndex: index,
            handleRemove: () => handleRemove(index),
            ...field
          })}
        </React.Fragment>
      ))}
      {render({
        handleAdd,
        fieldName: name,
        rowsCount: fields.length
      })}
    </>
  );
};

FieldArray.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValues: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  renderRow: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  trashIconWrapClasses: PropTypes.string
};
