/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Typography from "../../../../../components/Typography";
import FieldInput from "../../../../../components/form/FieldInput";
import Form from "../../../../../components/form/Form/Form";
import Button from "../../../../../components/Button";
import classes from "../ContactSection.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const defaultValues = {
  new_password: ""
};

export const PasswordForm = ({
  content,
  onSubmit,
  toggleInEditing,
  inEditing,
  sectionInEditing,
  showError,
  requestErrors
}) => {
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );
  const leftpanel = {
    subtitle: content.passwordSectionTitle,
    value: "",
    emptyValue: "",
    errorMsg: ""
  };

  const rightpanel = {
    action: "Update",
    emptyAction: "Update"
  };

  const { handleSubmit, errors, control } = useForm({
    defaultValues
  });
  useEffect(() => {
    if (requestErrors?.length) {
      setShowNotificationPopover(true);
    }
  }, [requestErrors]);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="t-m-2 t-l-0">
        <div className="devider-solid mb-16 mb-m-20 mt-24 mt-m-36 mt-l-40" />
        <div className="fbc">
          <div>
            <Typography
              variant="subtitle"
              size="m"
              className={classes.sectionTitle}
            >
              {leftpanel.subtitle}
            </Typography>
          </div>
          <div>
            <Button
              ghost
              onClick={toggleInEditing}
              disabled={sectionInEditing && !inEditing}
              className={classes.editButton}
            >
              {inEditing
                ? "Cancel"
                : leftpanel.value && leftpanel.value.trim() !== ""
                ? rightpanel.action
                : rightpanel.emptyAction}
            </Button>
          </div>
        </div>

        {inEditing && (
          <div className="mt-20">
            <div className={`${classes.passwordEditor}`}>
              <Controller
                name="new_password"
                control={control}
                render={(renderProps) => (
                  <FieldInput
                    {...renderProps}
                    type="password"
                    id="new-password"
                    label="New password"
                    errors={errors}
                    className={`${classes.generalSettingsInput}`}
                  />
                )}
              />
            </div>

            <div className="mt-28 mt-m-28 mb-16 mb-m-20">
              <Button type="submit">Update</Button>
            </div>
          </div>
        )}
        {showError && (
          <NotificationPopover
            show={isShowNotificationPopover}
            status="error"
            text={requestErrors.map((e) => e.message).join("")}
            onClose={() => {
              setShowNotificationPopover(false);
            }}
            bottomIndent={{
              hasIndent: true,
              size: 'small'
            }}
          />
        )}
      </div>
    </Form>
  );
};
