import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Modal from "../Modal/Modal";
import { ReactComponent as MembershipLogo } from "./membership-logo.svg";
import classes from "./GoodsamMembershipModal.module.css";
import Typography from "../Typography";
import GoodsamMembershipPlanSelection from "./GoodsamMembershipPlanSelection";
import { ReactComponent as ArrowLeftIcon } from "./arrow-left.svg";
import Button from "../Button";
import useGetElementContentScroll from "../../hooks/useGetElementContentScroll";
import StepsMenu from "components/StepsMenu";

const GoodsamMembershipModal = (props) => {
  const { show, onClose, backTo } = props;

  /* props for the modal window <GoodsamMembership>, which contains a step-by-step menu:
      onClickStep
      onNextStep
      steps
      currentStepIndex
      CurrentStepComponent (GoodsamMembershipPlanSelection - component default)
      planPrice
  */
  const {
    steps,
    CurrentStepComponent = GoodsamMembershipPlanSelection,
    currentStepIndex
  } = props;

  const isPaymentMethodStep =
    steps &&
    steps.length &&
    steps[currentStepIndex] &&
    steps[currentStepIndex]?.id === "payment-method";

  const isCheckoutStep =
    steps &&
    steps.length &&
    steps[currentStepIndex] &&
    steps[currentStepIndex]?.id === "checkout";
  const [mdlWrapRef, scroll] = useGetElementContentScroll();

  const handleBack = useCallback(() => {
    if (backTo) {
      onClose();
      backTo.onClick?.();
    }
  }, [backTo, onClose]);
  const modalClassnames = steps && steps.length && classes.stepsModalContainer;
  const modalNotGoodsamClassnames = (isPaymentMethodStep || isCheckoutStep) && classes.modalNotGoodsamClassnames;
  let modalRowClassnames =
    steps && steps.length && !isPaymentMethodStep && classes.stepsModalRow;
  modalRowClassnames = isCheckoutStep && classes.checkoutStepsModalRow;
  let stepModalClassnames = steps && steps.length && `col-m-8 `;
  stepModalClassnames =
    steps && steps.length && isPaymentMethodStep
      ? `${classes.paymentMethodStep} l-m-24 l-l-0 r-m-24 r-l-0 col-m-12`
      : stepModalClassnames;

  return (
    <Modal
      show={show}
      onClose={onClose}
      showCross={false}
      withContainer
      modalWrapClassnames={`mdl-wrap--full-screen-less-768`}
      modalClassnames={`${modalClassnames} ${modalNotGoodsamClassnames}`}
      mdlWrapRef={mdlWrapRef}
      render={() => (
        <div className={classnames(`row fjc `, modalRowClassnames)}>
          <div
            className={classnames(
              `col col-m-8 col-l-auto`,
              stepModalClassnames
            )}
          >
            <div
              className={classnames(
                `mdl mdl--full-screen-less-768 ${classes.mdl}`,
                backTo && classes.mdlWithHeader,
                steps && steps.length && classes.stepsModalInner,
                isPaymentMethodStep && classes.paymentMethodModalInner
              )}
            >
              {!backTo && (
                <div
                  onClick={onClose}
                  className={classnames(
                    `mdl__cross`,
                    steps && steps.length && classes.stepsModalMdlCrossButton
                  )}
                />
              )}

              {backTo && (
                <div
                  className={classnames(
                    classes.mdlHeader,
                    scroll.top > 0 && classes.mdlHeaderWithShadow
                  )}
                >
                  <Button
                    ghost
                    onClick={handleBack}
                    className={classes.btnBack}
                  >
                    <span className={classes.btnBackInner}>
                      <span className={classes.btnBackIcon}>
                        <ArrowLeftIcon />
                      </span>
                      <Typography variant="body" size="l" component="span">
                        {backTo.label}
                      </Typography>
                    </span>
                  </Button>
                </div>
              )}
              <div className={`${classes.logoWrap} mt-16 mb-16`}>
                <MembershipLogo />
              </div>
              <div
                className={classnames(
                  classes.stepsContainer,
                  isPaymentMethodStep &&
                    ` 
                    l-0 r-0
                    ${classes.paymentMethodStepContainer} `
                )}
              >
                {steps && steps.length && (
                  <div
                    className={`${classes.stepsMenuContainer} t-12 t-m-16 mb-0 mb-m-16`}
                  >
                    <StepsMenu {...props} justifyContentCenter />
                  </div>
                )}
                <CurrentStepComponent
                  classes={classes}
                  paymentMethodStepClassName={
                    isPaymentMethodStep && classes.paymentMethodStepClassStyle
                  }
                  {...props}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

GoodsamMembershipModal.propTypes = {
  activeId: PropTypes.string,
  content: PropTypes.shape({
    membershipTitle: PropTypes.string.isRequired,
    membershipValidateText: PropTypes.string.isRequired,
    membershipValidateCtaLabel: PropTypes.string.isRequired,
    membershipDisclaimer: PropTypes.string.isRequired
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      default: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      quantity: PropTypes.string,
      price: PropTypes.shape({
        user_friendly: PropTypes.string
      }).isRequired
    }).isRequired
  ).isRequired,
  loading: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleVerify: PropTypes.func.isRequired,
  onSavePlan: PropTypes.func.isRequired,
  backTo: PropTypes.exact({
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired
  }),
  // props for the modal window <GoodsamMembership>, which contains a step-by-step menu
  onClickStep: PropTypes.func,
  onNextStep: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  currentStepIndex: PropTypes.number,
  CurrentStepComponent: PropTypes.elementType, // react element (GoodsamMembershipPlanSelection - component default)
  planPrice: PropTypes.string
};

export default GoodsamMembershipModal;
