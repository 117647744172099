import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { contentQuery } from "../../../../queries";
import { useNeedDriverVerification } from "components/Verification/DriverVerifyModal/hooks/useNeedDriverVerification";
import { extractText } from "../../../../utils/extractText";
import { extractInfo } from "../../../../utils/extractInfo";

import { DriverVerificationContent } from "./DriverVerificationContent";
import { MyAccountContext } from "../../MyAccountContext";
import { SpinnerBlur } from "../../../../components/Spinner/Spinner";

const section = "account_section_driver_verification";
const initialContent = {
  sectionTitle: "",
  proTipsSectionTitle: "",
  proTipsSectionText: "",
  sectionDescription: "",
  verificationButton: {}
};

const DriverVerificationProvider = (props) => {
  const [content, setContent] = useState(initialContent);
  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section
    },
    fetchPolicy: "no-cache"
  });

  const {
    isNeedDriverVerify,
    refetchDriverVerificationPending
  } = useNeedDriverVerification();

  const { setAccountSection } = props;

  useEffect(() => {
    setAccountSection(section);
  }, []);

  useEffect(() => {
    if (!loading) {
      setContent({
        sectionTitle: extractText(data.content.text, "title"),
        proTipsSectionTitle: extractText(
          data.content.text,
          "pro_tips_section_title"
        ),
        proTipsSectionText: extractText(
          data.content.text,
          "pro_tips_section_text"
        ),
        sectionDescription: extractText(
          data.content.text,
          "description"
        ),
        statusTitle: extractText(
          data.content.text,
          "status_title"
        ),
        verificationButton: extractInfo(data.content.buttons, "driver_verification")
      });
    }
  }, [loading, data]);

  if (loading) {
    return <SpinnerBlur />;
  }

  return (
    <DriverVerificationContent
      content={content}
      showVerificationButton={isNeedDriverVerify}
      refetchDriverVerificationPending={refetchDriverVerificationPending}
      {...props}
    />
  );
};

export const DriverVerification = () => (
  <MyAccountContext.Consumer>
    {(value) => <DriverVerificationProvider {...value} />}
  </MyAccountContext.Consumer>
);
