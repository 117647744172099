import React from "react";
import Typography from "components/Typography";
import { addonCheckedIcon, addonNotCheckedIcon } from "components/Icon";
import classes from "./Checkbox.module.css";

export function Checkbox({ title, value }) {
  const { value: checked, comment } = JSON.parse(value);

  return (
    <div className={classes.container}>
      <div>
        <div className={`${classes.icon} mt-8`}>
          { !checked ? addonCheckedIcon : addonNotCheckedIcon }
        </div>
      </div>
      <div>
        {title && (
          <Typography
            variant="body"
            size="l"
            weight="bold"
            className={classes.title}
          >
            {title}
          </Typography>
        )}

        {comment && (
          <Typography
            variant="body"
            size="l"
            className={classes.comment}
          >
            {comment}
          </Typography>
        )}
      </div>
    </div>
  );
}
