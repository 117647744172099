import React from "react";
import { RentalType } from "components/Listing/steps/LocationDelivery/components/RentalType/RentalType";
import { DeliveryRadius } from "components/Listing/steps/LocationDelivery/components/DeliveryRadius/DeliveryRadius";
import { SpecificDestinations } from "components/Listing/steps/LocationDelivery/components/SpecificDestinations/SpecificDestinations";
import classnames from "classnames";

export const LocationRentalTab = (props) => {
  const {
    control,
    errors,
    clearErrors,
    watch,
    tabIndex
  } = props;

  const { tabs } = watch();
  const {
    delivery_option
  } = tabs[tabIndex];

  return (
    <>
      <div>
        <RentalType
          tabIndex={tabIndex}
          control={control}
          watch={watch}
        />
      </div>

      <div>
        <DeliveryRadius
          tabIndex={tabIndex}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          watch={watch}
        />
      </div>

      <div className={classnames(
        "devider-solid",
        !delivery_option && 'mt-20 mt-m-28',
        delivery_option && 'mt-36 mt-m-60'
      )} />

      <div>
        <SpecificDestinations
          tabIndex={tabIndex}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          watch={watch}
        />
      </div>
    </>
  );
};
