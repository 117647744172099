import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../Typography";
import Button from "../../../Button";
import { closeIcon } from "../../../Icon";
import icon from "./icon.svg";
import classes from "./NoVehiclesFound.module.css";

const NoVehiclesFound = (props) => {
  const { onClearFilters, hasActiveFilters } = props;

  // TODO: Use NoItemsPageNotice (but double check indents)

  return (
    <div className="df fcc fdc t-32 b-16 b-m-20">
      <div className="t-16 b-16 t-m-20 b-m-20">
        <img className={classes.icon} src={icon} alt="Icon" />
      </div>
      <Typography className="tac" variant="subtitle" size="m" weight="bold">
        No vehicles found
      </Typography>
      <Typography className="tac" variant="body" size="l">
        Try changing your filters, adjusting your dates, exploring the map or changing your location
      </Typography>
      {hasActiveFilters && (
        <div className="t-12 b-16 t-m-20 b-m-20">
          <Button
            secondary
            icon={closeIcon}
            iconPosition="after"
            onClick={onClearFilters}
          >
            Clear all filters
          </Button>
        </div>
      )}
      <div className="b-0 b-l-60" />
      <div className="b-40 m-b-60" />
      <div className="b-40 m-b-60" />
    </div>
  );
};

NoVehiclesFound.propTypes = {
  onClearFilters: PropTypes.func.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired
};

export default NoVehiclesFound;
