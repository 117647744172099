import { gql } from '@apollo/client';

export const passwordRecoveryMutation = gql`
  mutation password_recovery($email: String!) {
    password_recovery(email: $email) {
      result {
        success
        errors {
          code
          field
          section
          message
        }
      }
    }
  }
`;
