import React from "react";
import { useQuery } from "@apollo/client";
import { insurancePackages } from "./queries/insurancePackages";
import GoodsamInsurance from "components/GoodsamInsurance/GoodsamInsurance";

const BookingInsurance = ({
  rvId,
  rentalType,
  show,
  onClose,
  backTo,
  onSelectInsurance,
  insurance = undefined
}) => {
  const { loading, data } = useQuery(insurancePackages, {
    fetchPolicy: "no-cache",
    variables: {
      rv: rvId,
      rental_type: rentalType
    }
  });
  if (loading) return null;

  const defaultId = data?.insurance_packages?.find(({ is_default }) => Boolean(is_default)).id;

  return (
    <GoodsamInsurance
      show={show}
      onClose={onClose}
      backTo={backTo}
      formData={{ insurance: insurance ? String(Number(insurance) - 1) : defaultId - 1 }}
      data={data}
      onSelectInsurance={onSelectInsurance}
    />
  );
};

export default BookingInsurance;
