import React from "react";
import PropTypes from "prop-types";
import defaultRvImage from "assets/images/rv-default-placeholder.jpg";
import style from "./style.module.css";
import { ContentSectionElementImage as Image } from "../../../ContentSection/elements/Image";

export const ProfileImg = ({ image }) => {
  if (image) {
    return (
      <Image
        content={image}
        elementId={image.id}
        className={style["profile-img"]}
        alt={image.alt}
      />
    );
  }

  return (
    <img
      className={style["profile-img"]}
      src={defaultRvImage}
      alt="placeholder"
    />
  );
};

ProfileImg.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string.isRequired,
    srcset: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }).isRequired
    ).isRequired
  })
};

ProfileImg.defaultProps = {
  src: defaultRvImage
};
