import React from "react";
import Typography from "components/Typography";
import classes from "./DateValue.module.css";

export const DateValue = ({ title, date, time }) => {
  const valueTextMinSmallScreen = `${date}, ${time}`;
  const valueTextMaxSmallScreen = (<span><span>{date},</span><span className="db">{time}</span></span>);

  return (
    <div className={classes.container}>
      <div>
        {title && (
          <Typography
            variant="body"
            size="l"
            className={classes.title}
          >
            {title}
          </Typography>
        )}
      </div>
      <div>
        <Typography
          variant="body"
          size="l"
          weight="bold"
          className={classes.value}
        >
          <span className="dn db-m">{valueTextMinSmallScreen}</span>
          <span className="dn-m">{valueTextMaxSmallScreen}</span>
        </Typography>
      </div>
    </div>
  );
};
