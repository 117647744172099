import { useQuery } from "@apollo/client";
import { userQuery } from "../queries/userQuery";
import { path } from "ramda";

const getAccountType = path(["bank_account", "account_type", "value"]);

function getAccountTypeValue(data) {
  let accountTypeValue = getAccountType(data);

  if (accountTypeValue === '-') {
    accountTypeValue = '';
  }

  return accountTypeValue;
}

export const useFetchUserData = () => {
  const { data, refetch } = useQuery(userQuery);

  const user = data?.user || {};
  const accountTypeValue = getAccountTypeValue(user);

  return {
    phone: user.phone,
    userData: user,
    accountTypeValue,
    refetch
  };
};

