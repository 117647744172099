import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ThreadsView from "./components/ThreadsView";
import ThreadView from "./components/ThreadView";
import Spinner from "../Spinner/Spinner";
import PageError from "../PageError/PageError";
import { useChatContext } from "./ChatContext";
import classes from "./ChatView.module.css";

const ChatView = ({ onClose }) => {
  const { apiClient, initError } = useChatContext();

  if (initError) {
    return <PageError error={initError} />;
  }

  if (!apiClient) {
    return <Spinner position="absolute" />;
  }

  return (
    <Content apiClient={apiClient} onClose={onClose} />
  );
};

const Content = ({ apiClient, onClose }) => {
  const {
    loadingThreads,
    threads,
    activeThread,
    selectThread,
    getThreadInfo
  } = useChatContext();

  const onThreadBack = useCallback(() => {
    selectThread(null);
  }, [selectThread]);

  return (
    <div className={classnames(classes.root, "df fdc")}>
      <ThreadsView
        loading={loadingThreads}
        threads={threads}
        activeThread={activeThread}
        onClickThread={selectThread}
        onClose={onClose}
      />
      <ThreadView
        apiClient={apiClient}
        activeThread={activeThread}
        onBack={onThreadBack}
        onClose={onClose}
        getThreadInfo={getThreadInfo}
      />
    </div>
  );
};

ChatView.propTypes = {
  onClose: PropTypes.func
};

export default ChatView;
