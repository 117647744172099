/*
 * Search specific hooks
 */

import { useWatch } from "react-hook-form";
import { useSearchContext } from "./useSearchContext";
import { isFilterSet } from "./filters";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { favoriteMutation } from "./mutations/favoritesMutation";
import { extractSuccess } from "../../utils/extractErrors";
import { MQ_BP, useMatchMedia } from "../MatchMedia";
import { remove } from "lodash";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";

export const useWatchFields = () => {
  const { filtersControl } = useSearchContext();

  const fields = useWatch({ control: filtersControl });

  return {
    fields,
    hasFilledFilters: Object.keys(fields).some((name) => {
      return isFilterSet(name, fields[name]);
    })
  };
};

const extractFavoriteSuccess = extractSuccess("favorite");

export const useFavorites = (initialList, user = {}) => {
  const [favorites, setFavorites] = useState([]);
  const [action] = useMutation(favoriteMutation);

  const onChangeFavorite = useCallback(
    async (id) => {
      const isFavoritePrev = favorites.includes(id);
      const isFavoriteNow = !isFavoritePrev;

      // Changing current value instantly without waiting for response
      if (isFavoritePrev) {
        setFavorites(favorites.filter((i) => i !== id));
      } else {
        setFavorites([...favorites, id]);
      }

      const response = await action({
        variables: { id: id, is_favorite: isFavoriteNow }
      });
      if (!extractFavoriteSuccess(response)) {
        // Reverting back previous value
        setFavorites(favorites);
      }
      pushGoogleAnalytics("favorite", {
        vehicleID: id
      });

      let productIndex = 0;
      const product = initialList.find((pr, index) => {
        if (pr.id === id) {
          productIndex = index;
          return true;
        } else {
          return false;
        }
      });

      const pr = {
        location: String(product?.lc?.replace('Delivered from ', '')),
        asset_make: String(product.mk),
        asset_model: String(product.md),
        asset_year: String(product.yr),
        asset_length: Number(product.le) * 12,
        asset_sleepnumber: Number(product.sl),
        asset_class: String(product.cl),
        asset_id: String(product.id),
        asset_price: Number(product.pr),
        asset_brand: product?.mr,
        asset_slideouts: Number(product.so),
        fbp: "",
        fbc: ""
      };

      if (isFavoriteNow) {
        pushSegmentAnalyticsTrack("Product Added to Wishlist", {
          cwh_business: 'RV Rentals',
          ...pr
        });
      } else {
        pushSegmentAnalyticsTrack("Product Removed from Wishlist", {
          cwh_business: 'RV Rentals',
          ...pr
        });
      }
    },
    [favorites, action, initialList, user?.id]
  );

  const isInFavorites = useCallback(
    (id) => {
      return favorites.includes(id);
    },
    [favorites]
  );

  useEffect(() => {
    if (initialList) {
      setFavorites(initialList?.filter((i) => i.fv).map((i) => i.id));
    }
  }, [initialList]);

  return { isInFavorites, onChangeFavorite };
};

export const useFooterVisibilityProcessing = (mapOpen) => {
  const isSmallPhone = useMatchMedia({ maxWidth: MQ_BP.small });

  const toggleFooter = useCallback((hidden) => {
    const footer = document.querySelector(".footer");
    if (footer) {
      const cn = "footer--hidden";
      if (hidden) {
        footer.classList.add(cn);
      } else {
        footer.classList.remove(cn);
      }
    }
  }, []);

  useEffect(() => {
    toggleFooter(mapOpen && isSmallPhone);
  }, [mapOpen, isSmallPhone, toggleFooter]);

  useEffect(() => {
    return () => toggleFooter(false);
  }, [toggleFooter]);
};
