import React from 'react';
import Typography from "../../Typography";

/* This function searches for the text in curly brackets in the string and replaces it with the desired values */
const formatValue = (value, variables) => {
  return value.replace(/\{(.+)\}/g, (str, prop) => variables[prop] || str);
};

const ContentSectionElementTextHtml = ({ elementId, tag, value, render, ...rest }) => {
  const Component = tag;

  const result = (
    <Component id={elementId} {...rest} dangerouslySetInnerHTML={{ __html: value }}/>
  );

  return typeof render === "function" ? render(result) : result;
};

const ContentSectionElementTextFormat = ({ elementId, tag, value, render, ...rest }) => {
  const Component = tag;

  const result = (
    <Component id={elementId} {...rest}>
      {value}
    </Component>
  );

  return typeof render === "function" ? render(result) : result;
};

ContentSectionElementTextHtml.defaultProps = {
  tag: "div"
};

const ContentSectionElementTextPlain = ({ elementId, tag, value, render, ...rest }) => {
  const Component = tag;

  const result = (
    <Component id={elementId} {...rest}>
      {value}
    </Component>
  );

  return typeof render === "function" ? render(result) : result;
};

ContentSectionElementTextPlain.defaultProps = {
  tag: "span"
};

export const ContentSectionElementTextTag = ({ content, ...rest }) => {
  const { type, value } = content;

  if (type === "html") {
    return (
      <ContentSectionElementTextHtml value={value} {...rest}/>
    );
  }

  if (type === "format") {
    const { variables = {} } = rest || {};
    return (
      <ContentSectionElementTextFormat value={formatValue(value, variables)} {...rest} />
    );
  }

  return (
    <ContentSectionElementTextPlain value={value} {...rest}/>
  );
};

export const ContentSectionElementTextRaw = ({ content, render }) => {
  const { type, value } = content;

  return typeof render === "function" ? render(value, type) : value;
};

export const ContentSectionElementText = (props) => (
  <ContentSectionElementTextTag
    tag={Typography}
    {...props}
  />
);

ContentSectionElementText.defaultProps = {
  component: "div"
};
