import React from "react";
import RentalDetailsContent from "./RentalDetailsContent";
import RentalDetailsProvider from "./RentalDetailsProvider";
import { ContentLoader } from "components/ContentSection";
import { linksContentQuery } from "queries/contentQuery";
import { contentPredicate } from "utils/contentPredicate";
import { CONTENT_QUERY_LINK } from "constants/contentQuery";

const RentalDetails = () => {
  return (
    <ContentLoader
      query={linksContentQuery}
      predicate={contentPredicate([CONTENT_QUERY_LINK])}
      render={() => (
        <RentalDetailsProvider>
          <RentalDetailsContent />
        </RentalDetailsProvider>
      )}
    />
  );
};

export default RentalDetails;
