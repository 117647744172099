import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import ModalCarousel from "components/ModalCarousel/ModalCarousel";
import { useItemsCount } from "components/PhotoReviewGallery/hooks/useItemsCount";
import classes from "./PhotoReviewGallery.module.css";

export const GalleryItem = ({ url, count, onClick }) => (
  <li className={classes.galleryItem}>
    <div className={classes.galleryItemInner} onClick={onClick}>
      {!count && <img src={url} alt="" className={classes.galleryPhoto} />}
      {!!count && (
        <Typography variant="body" size="l" className={classes.galleryCount}>
        + {count}
        </Typography>
      )}
    </div>
  </li>
);

export const PhotoReviewGallery = ({ hasSlider = true, initialItemCount, photos = [] }) => {
  const [showModalGallery, setShowModalGallery] = useState(false);
  const [initialSlide, setInitialSlide] = useState(1);

  const [placeholderCount, pageItemsCount] = useItemsCount(photos, initialItemCount);
  const handleClick = useCallback(
    (index) => {
      setInitialSlide(index);
      setShowModalGallery(true);
    },
    []
  );
  let galleryItems = photos.map(({ id, src, alt }, itemIndex) => (
    <GalleryItem key={id} url={src} onClick={() => handleClick(itemIndex)} />
  ));
  galleryItems.splice(pageItemsCount);

  if (!!placeholderCount) {
    galleryItems = [
      ...galleryItems,
      <GalleryItem
        key="placeholder"
        count={placeholderCount}
        onClick={() => handleClick(pageItemsCount)}
      />
    ];
  }

  return (
    <div className={classes.container}>

      <ul className={`${classes.gallery} ui-menu`}>
        {galleryItems}
      </ul>

      {hasSlider && (
        <ModalCarousel
          showModal={showModalGallery}
          photos={photos}
          onClose={() => setShowModalGallery(false)}
          initialSlide={initialSlide}
        />
      )}

    </div>
  );
};

PhotoReviewGallery.propTypes = {
  hasSlider: PropTypes.bool,
  initialItemCount: PropTypes.shape({
    mobile: PropTypes.number,
    tablet: PropTypes.number,
    desctop: PropTypes.number
  })
};
