import { gql } from "@apollo/client";

export const cancelAndDeclineReasonsQuery = gql`
  query cancelAndDeclineReasons($id: Int!) {
    cancellation_and_declining_reasons(id: $id) {
      key
      value
    }
  }
`;
