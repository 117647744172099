import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { userQuery } from "./queries";
import { contentQuery } from "../../queries";

import { extractText } from "../../utils/extractText";
import { MyAccountContext } from "./MyAccountContext";
import { SpinnerBack } from "components/Spinner/Spinner";
import { MyAccount } from "./MyAccount";

const section = "account_common";
const initialContent = {
  myAccountTitle: "",
  supportSectionTitle: "",
  supportSectionText: "",
  supportSectionPhoneNumber: "",
  supportSectionEmail: ""
};
const initialUserData = {
  id: null,
  email: "",
  email_verified: false,
  phone: "",
  phone_verified: false,
  password_exists: false,
  password_last_updated: "",
  good_sam_membership_validation: {
    key: "",
    value: ""
  }
};

const getExtraSteps = (content) => {
  let extraSteps = content?.content?.buttons || [];
  return extraSteps.map(step => ({ ...step, isExtraStep: true }));
};

export const MyAccountContainer = () => {
  const [accountContent, setAccountContent] = useState(initialContent);
  const [userData, setUserData] = useState(initialUserData);
  const [accountSection, setAccountSection] = useState("");

  const { loading: contentQueryLoading, data: content } = useQuery(
    contentQuery,
    {
      variables: {
        section
      }
    }
  );

  useEffect(() => {
    if (!contentQueryLoading && content?.content?.text) {
      setAccountContent({
        extraSteps: getExtraSteps(content),
        myAccountTitle: extractText(content.content.text, "my_account_title"),
        supportSectionTitle: extractText(
          content.content.text,
          "support_section_title"
        ),
        supportSectionText: extractText(
          content.content.text,
          "support_section_text"
        ),
        supportSectionPhoneNumber: extractText(
          content.content.text,
          "support_section_phone_number"
        ),
        supportSectionEmail: extractText(
          content.content.text,
          "support_section_email"
        )
      });
    }
  }, [contentQueryLoading, content]);

  const {
    loading: userQueryLoading,
    data: user,
    refetch: refetchUserQuery
  } = useQuery(userQuery, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (!userQueryLoading) {
      setUserData(user.user);
    }
  }, [userQueryLoading, user]);

  const updateUserData = useCallback((newPartialData) => {
    setUserData((p) => ({ ...p, ...newPartialData }));
  }, []);

  if (contentQueryLoading || userQueryLoading) {
    return <SpinnerBack />;
  }

  const value = {
    accountContent,
    userData: userData?.id ? userData : user?.user,
    updateUserData,
    accountSection,
    setAccountSection,
    refetchUserQuery
  };

  return (
    <MyAccountContext.Provider value={value}>
      <MyAccount />
    </MyAccountContext.Provider>
  );
};
