import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import Button from "../../Button";
import { MQ_BP, useMatchMedia } from "../../MatchMedia";
import { useAutoHeight } from "../../form/useAutoHeight";
import { ThreadType } from "../types";
import { closeIconCircle } from "components/Icon";
import classes from "./Footer.module.css";

const triggerResize = debounce(() => {
  window.dispatchEvent(new Event("resize"));
}, 100);

const Footer = (props) => {
  const {
    thread,
    placeholder,
    onClickAction,
    onInputChange,
    clearValueOnClickAction,
    actionTitle,
    actionIcon,
    iconStart,
    withClear,
    useTextarea
  } = props;
  const input = useRef(null);
  const [value, setValue] = useState("");
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  const onChangeInput = useCallback((event) => {
    const { value } = event.target;
    setValue(value);
  }, []);

  const submit = () => {
    onClickAction(value);
    if (clearValueOnClickAction) {
      setValue("");
    }
  };

  const onInputKeyDown = (event) => {
    if (event.key === "Enter") {
      if (useTextarea && event.metaKey) {
        // Enter + MetaKey (CTRL/CMD) = New line
        // 1. Inserting new line at current position
        const caret = event.target.selectionStart;
        event.target.setRangeText("\n", caret, caret, "end");
        setValue(event.target.value);
        // 2. Using a hack for scrolling to a cursor
        event.target.blur();
        event.target.focus();
      } else {
        // Enter only = Send message
        submit();
        event.preventDefault(); // Avoid new line
      }
    }
  };

  const handleAutoHeight = useAutoHeight(input);
  const FieldComponent = useTextarea ? "textarea" : "input";

  useEffect(() => {
    onInputChange?.(value);
  }, [value]);

  useEffect(() => {
    setValue("");
  }, [thread]);

  useEffect(() => {
    if (useTextarea) {
      handleAutoHeight();
    }
  }, [value, useTextarea, handleAutoHeight]);

  return (
    <div className={classnames(classes.root, "df t-m-8 b-m-8 l-16 l-m-24")}>
      {iconStart && (
        <div className={classnames(classes.iconStart, "df fcc r-12")}>
          {iconStart}
        </div>
      )}
      <FieldComponent
        key={thread?.id}
        ref={input}
        className={classes.input}
        rows={1}
        value={value}
        onChange={onChangeInput}
        placeholder={placeholder}
        onKeyDown={onInputKeyDown}
        onFocus={triggerResize}
        onBlur={triggerResize}
      />
      {withClear && value?.length > 0 && (
        <Button
          className={classnames(classes.clearButton, "mr-m-12")}
          ghost
          onClick={() => setValue("")}
          icon={closeIconCircle}
        />
      )}
      {(actionTitle && onClickAction) && (
        <Button
          className={isSmallDevice ? classes.mobileButton : classes.button}
          ghost
          disabled={value?.length === 0}
          onClick={submit}
          icon={isSmallDevice ? actionIcon : undefined}
        >
          {isSmallDevice ? undefined : actionTitle}
        </Button>
      )}
    </div>
  );
};


Footer.propTypes = {
  thread: ThreadType,
  placeholder: PropTypes.string.isRequired,
  onClickAction: PropTypes.func,
  onInputChange: PropTypes.func,
  clearValueOnClickAction: PropTypes.bool,
  actionTitle: PropTypes.string,
  actionIcon: PropTypes.node,
  iconStart: PropTypes.node,
  withClear: PropTypes.bool,
  useTextarea: PropTypes.bool
};

export default memo(Footer);
