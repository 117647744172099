import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FILTERS } from "components/Widget/filters";
import { getObjectPositiveValuesCount } from "utils/basic";
import FilterButton from "./FilterButton";
import classes from "./FilterLocations.module.css";
import omit from "lodash/omit";
import Checkbox from "components/form/Checkbox";
import Typography from "components/Typography";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { getLocation } from "components/Widget/utils/utils";
import cn from "classnames";

const FilterLocations = () => {
  const { filtersWatch, allCards, theme } = useWidgetContext();
  const { locations: filterLocations } = filtersWatch();
  const allLocations = useMemo(() => getLocation(allCards), [allCards]);

  return (
    <FilterButton
      popperClassName={classes.popper}
      id={FILTERS.ids.locations}
      getSelectedCount={getObjectPositiveValuesCount}
      renderForm={({ value, onChange }) => {
        return (
          <div>

            <Typography
              size="s"
              weight="bold"
              variant="subtitle"
              className={classes.title}
            >
              RV Locations
            </Typography>

            <div
              className={cn(
                `mt-m-8`,
                classes.row
              )}
            >
              {allLocations.map((i) => (
                <div
                  key={i.id}
                  className={cn(
                    `mt-m-20`,
                    classes.col
                  )}
                >
                  <Checkbox
                    className={cn(
                      theme === 'dark' && classes.checkboxDark,
                    )}
                    labelTextClassName={classes.checkboxLabel}
                    pseudoCheckClassName={cn(
                      classes.checkboxPseudoCheck
                    )}
                    id={i.id}
                    name={i.id}
                    text={i.name}
                    checked={filterLocations[i.id]?.checked || false}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      onChange(omit(
                        { ...value, [i.id]: { checked: checked, name: i.name } },
                        !checked ? i.id : null
                      ));
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
};

FilterLocations.propTypes = {
};

export default FilterLocations;
