import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Form from "components/form/Form/Form";
import { FieldController } from "components/form/FieldController/FieldController";
import GoodsamInsuranceOptions from "./GoodsamInsuranceOptions";

const DEFAULT_VALUES = {
  insurance: ""
};

const GoodsamInsuranceForm = forwardRef(function BookingInsuranceForm(
  props,
  ref
) {
  const { initialData, content, onSave, wrapRef } = props;
  const defaultValues = Object.assign({}, DEFAULT_VALUES, initialData);
  const { control, handleSubmit } = useForm({
    defaultValues
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit((values) => onSave(values))
  }));

  return (
    <Form>
      <FieldController
        name="insurance"
        control={control}
        render={({ value, ...renderProps }) => (
          <GoodsamInsuranceOptions
            items={content}
            value={value}
            wrapRef={wrapRef}
            {...renderProps}
          />
        )}
      />
    </Form>
  );
});

GoodsamInsuranceForm.propTypes = {
  initialData: PropTypes.exact({
    insurance: PropTypes.string.isRequired
  }).isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.shape({
        cents_total: PropTypes.number.isRequired,
        cents_as_part: PropTypes.number.isRequired,
        dollars_as_part: PropTypes.number.isRequired
      }),
      period: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onSave: PropTypes.func.isRequired
};

export default GoodsamInsuranceForm;
