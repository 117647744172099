import React, { useMemo } from "react";
import cn from 'classnames';
import moment from "moment";
import Typography from "components/Typography";
import { dateFormat } from "utils/dayPickerDataFormatting";
import { RENTAL_TYPE } from 'utils/constants/rentalType';

import classes from './SummaryPreview.module.css';

const SummaryPreview = ({ state }) => {

  const getLocation = useMemo(() => {
    if (state.specific_delivery_location) {
      return state.specific_delivery_location;
    }

    if (state.delivery_location) {
      return state.delivery_location;
    }

    return state.location?.short_address;
  }, [state.delivery_location, state.location?.short_address, state.specific_delivery_location]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.image} style={{ backgroundImage: `url(${state.title_image})` }} />
        <div className={classes.title}>
          <Typography
            component="p"
            variant="subtitle"
            size="s"
            className={classes.name}
          >
            {state.rv_name}
          </Typography>
        </div>
      </div>

      <div className={classes.table}>
        <div className={cn(classes.param, classes.rental_type)}>
          <div className={classes.key}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.key_text}
            >
              Rental type
            </Typography>
          </div>
          <div className={classes.value}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.value_text}
            >
              {state.rental_type === RENTAL_TYPE.STANDARD && "Standard rental"}
              {state.rental_type === RENTAL_TYPE.DELIVERY && "Delivery only rental"}
            </Typography>
          </div>
        </div>

        <div className={cn(classes.param, classes.departure_date)}>
          <div className={classes.key}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.key_text}
            >
              Pick-up
            </Typography>
          </div>
          <div className={classes.value}>
            <Typography
              component="p"
              variant="body"
              size="l"
              weight="bold"
              className={classes.value_text}
            >
              {dateFormat(state.departure_date)}
            </Typography>
          </div>
        </div>

        <div className={cn(classes.param, classes.return_date)}>
          <div className={classes.key}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.key_text}
            >
              Drop off
            </Typography>
          </div>
          <div className={classes.value}>
            <Typography
              component="p"
              variant="body"
              size="l"
              weight="bold"
              className={classes.value_text}
            >
              {dateFormat(state.return_date)}
            </Typography>
          </div>
        </div>

        <div className={cn(classes.param, classes.nights)}>
          <div className={classes.key}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.key_text}
            >
              Duration
            </Typography>
          </div>
          <div className={classes.value}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.value_text}
            >
              {moment(state.return_date).diff(moment(state.departure_date), 'days')} nights
            </Typography>
          </div>
        </div>

        <div className={cn(classes.param, classes.location)}>
          <div className={classes.key}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.key_text}
            >
              Location
            </Typography>
          </div>
          <div className={classes.value}>
            <Typography
              component="p"
              variant="body"
              size="l"
              className={classes.value_text}
            >
              {getLocation}
            </Typography>
          </div>
        </div>

        {state.selected_addons.length && (
          <div className={cn(classes.param, classes.add_ons)}>
            <div className={classes.key}>
              <Typography
                component="p"
                variant="body"
                size="l"
                className={classes.key_text}
              >
                Add-ons
              </Typography>
            </div>
            <div className={classes.value}>
              <Typography
                component="p"
                variant="body"
                size="l"
                className={classes.value_text}
              >
                {`${state.selected_addons.length} ${state.selected_addons.length > 1 ? "add-ons" : "add-on"}`}
              </Typography>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default SummaryPreview;
