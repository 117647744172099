import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { getPathError } from "utils/getPathError";

export function usePhotoInstant(mutation, pathError, handleError = () => {}) {
  const [action, { loading }] = useMutation(mutation);

  const handlePhotoInstant = useCallback(async (variables) => {
    try {
      const { data } = await action({ variables });
      handleError(getPathError(data, pathError));
      return getPathError(data, pathError);
    } catch (e) {
      handleError(e.message);
      return e.message;
    }
  }, [action, pathError, handleError]);

  return [handlePhotoInstant, loading];
}
