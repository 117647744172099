export const calculateTotalCurrency = (currencyArray) => {
  if (currencyArray && Array.isArray(currencyArray)) {
    return currencyArray.reduce((c1, c2) => {
      return c1 + c2;
    }, 0);
  } else return 0;
};

export const convertFromCentsToDollars = (cents) => {
  return cents ? cents / 100 : 0;
};

export const calculateTotalCurrencyDollarsAndCents = (currencyData, excludeCurrencyData) => {
  if (!(currencyData && Array.isArray(currencyData) && currencyData.length)) {
    return 0;
  }

  const dollarsTotal = calculateTotalCurrency(
    currencyData.map((currency) => currency.dollars)
  );
  const centsTotal = calculateTotalCurrency(
    currencyData.map((currency) => currency.cents)
  );
  const total = convertFromCentsToDollars(centsTotal) + dollarsTotal;

  if (!(excludeCurrencyData && Array.isArray(excludeCurrencyData) && excludeCurrencyData.length)) {
    return total;
  }
  const minusDollarsTotal = calculateTotalCurrency(
    excludeCurrencyData.map((currency) => currency.dollars)
  );
  const minusCentsTotal = calculateTotalCurrency(
    excludeCurrencyData.map((currency) => currency.cents)
  );
  return total - (convertFromCentsToDollars(minusCentsTotal) + minusDollarsTotal);
};
