import React from "react";
import Typography from "components/Typography";
import { formatCents } from "utils/convertCents";
import cn from 'classnames';
import classes from './ReferralsListItem.module.css';

const ReferralsListItem = ({ referral, index }) => {
  return (
    <div className={classes.row}>
      <div className={classes.number}>
        <Typography
          variant="subtitle"
          size="s"
          className={classes.rowText}
        >
          {index + 1}.
        </Typography>
      </div>
      <div className={classes.email}>
        <Typography
          component="div"
          variant="subtitle"
          size="s"
          className={cn(classes.rowText, 'fw-400')}
        >
          <span className="fw-400">{referral.referralEmail}</span>
        </Typography>
      </div>
      <div className={classes.sum}>
        <Typography
          variant="subtitle"
          size="s"
          className={classes.rowText}
        >
          $ <b className="fw-500">{referral.amount?.dollars_as_part}</b>.<span className="fw-400">{formatCents(referral.amount?.cents_as_part)}</span>
        </Typography>
      </div>
    </div>
  );
};

export default ReferralsListItem;
