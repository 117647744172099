import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { verificationCheckQuery } from "components/VerificationStepsModals/queries/verificationCheck";
import { VERIFICATION_STEPS, VERIFICATION_ORDER_DEFAULT } from "../constants";
import { path } from "ramda";

const getAccountType = path(["bank_account", "account_type", "value"]);

function getAccountTypeValue(data) {
  let accountTypeValue = getAccountType(data);

  if (accountTypeValue === '-') {
    accountTypeValue = '';
  }

  return accountTypeValue;
}

export const useVerificationCheck = () => {
  const { loading, data } = useQuery(verificationCheckQuery, {
    fetchPolicy: "no-cache"
  });

  const user = data?.user || {};

  const isPayoutInfoVerified = !!user?.bank_account?.account_number;

  const [verifications, setVerifications] = useState(null);

  useEffect(() => {
    if (!loading && data?.user && verifications === null) {
      setVerifications({
        [VERIFICATION_STEPS.email]: user.email_verified,
        [VERIFICATION_STEPS.phone]: user.phone_verified,
        [VERIFICATION_STEPS.payoutInfo]: isPayoutInfoVerified
      });
    }
  }, [data?.user, isPayoutInfoVerified, loading, user.email_verified, user.phone_verified, verifications]);

  return {
    loading,
    phone: user.phone,
    email: user.email,
    userData: user,
    accountTypeValue: getAccountTypeValue(user),
    verifications
  };
};
