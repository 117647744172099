import React from "react";
import Typography from "components/Typography";
import { ReactComponent as IconEdit } from "processes/PublicProfile/images/edit-outline.svg";
import Button from "components/Button";
import Rating from "components/Rating";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import useShareButton from "processes/PublicProfile/hooks/useShareButton.js";
import classes from "./ProfileMainInfo.module.css";

export const ProfileMainInfo = ({ data: { name, rating, address, date, avatarData } }) => {

  return (
    <div className={classes.container}>
      <div className={`${classes.mainInfo} b-xl-2`}>

        <div className={`${classes.edit}`}>
          <Button
            ghost
            icon={<IconEdit />}
            label=""
            onClick={function noRefCheck(){}}
            size="medium"
          />
        </div>

        <div className={classes.avatar}>
          <ProfileAvatar {...avatarData} />
        </div>

        <div className={classes.info}>

          <div className={classes.rating}>
            <Rating value={rating} />
          </div>

          <div className={`devider-solid--vertical ${classes.devider} dn db-m db-l`} />

          <div className={classes.infoDate}>

            <div className={classes.date}>
              <Typography variant="body" size="l">
                {date}
              </Typography>
              <div className={`devider-solid--vertical ${classes.deviderDate} dn db-l`} />
            </div>

            <div className={`devider-solid--vertical ${classes.devider} dn db-m dn-l`} />

            <div className={classes.share}>
              {useShareButton()}
            </div>

          </div>

        </div>

        <div className={classes.name}>
          <Typography variant="subtitle" size="l" weight="bold">
            {name}
          </Typography>
        </div>

        <div className={classes.address}>
          <Typography variant="body" size="l" weight="bold" className={classes.desctopCity}>
            {address}
          </Typography>
        </div>
      </div>
    </div>
  );
};

// ProfileMainInfo.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };
