import ImageCrop from "react-image-crop";
import classes from "./ImageCrop.module.css";

class CustomImageCrop extends ImageCrop {
  createCropSelection() {
    const originalSelection = super.createCropSelection();
    const style = this.getCropStyle();

    return (
      <>
        <div className={classes.overlay}>
          <div className={`${classes.shadow} imageCropShadow`} style={style} />
        </div>
        {originalSelection}
      </>
    );
  }
}

export default CustomImageCrop;
