import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AddOnsItem from "./AddOnsItem";
import { AddOnListItemType, AddOnSelectedItemType } from "components/AddOns/types";
import classes from "./AddOnsList.module.css";

const AddOnsList = (props) => {
  const { list, selected, onChange } = props;

  const handleOnChange = (item) => {
    const newAddOns = selected.find(i => i.id === item.id)
      ? selected.filter(i => i !== item)
      : [...selected, item];
    onChange(newAddOns);
  };

  return (
    <div className={classnames("add-ons", classes.root)}>
      <ul
        className={classnames(
          "list--bullet l-0 r-0 mb-0 mt-0",
          classes.list
        )}
      >
        {list?.map((item, i) => (
          <AddOnsItem
            key={item.id + i}
            item={item}
            onChange={handleOnChange}
            checked={Boolean(selected.find(i => i.id === item.id))}
          />
        ))}
      </ul>
    </div>
  );
};

AddOnsList.propTypes = {
  list: PropTypes.arrayOf(AddOnListItemType).isRequired,
  selected: PropTypes.arrayOf(AddOnSelectedItemType).isRequired,
  onChange: PropTypes.func.isRequired
};

export default AddOnsList;
