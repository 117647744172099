import React, { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import defaultRvImage from "assets/images/rv-default-placeholder.jpg";
import { MQ_BP, useMatchMedia } from "../../../../../components/MatchMedia";
import Tag from "../../../../../components/Tag";
import RentalDetailsRentalInfoItem from "./RentalDetailsRentalInfoItem";
import Button from "../../../../../components/Button";
import useDebounce from "../../../../../hooks/useDebounce";
import keyCodes from "../../../../../utils/constants/keyCodes";
import { ChevronIcon } from "../../../../../components/Icon/ChevronIcon";
import Typography from "../../../../../components/Typography";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { isUserOwner, isUserRenter, getPickUpLocationTitle } from "processes/Rentals/utils";
import { getRvGeneratorValue, getRvMileageValue, getRvPickUpLocation } from "utils/rv";
import { formatApiDate } from "utils/dateTime";
import TableInfoModal from "components/TableInfo/components/TableInfoModal";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { useRentalDetailsTableRow } from "processes/Rentals/RentalDetails/hooks/useRentalDetailsTableRow";
import { useRentalCancellingTableRow } from "processes/Rentals/RentalDetails/hooks/useRentalCancellingTableRow";
import tagIcon from "assets/images/lightning-small-icon.svg";
import Tooltip from "components/Tooltip/Tooltip";
import classes from "./RentalDetailsRentalInfo.module.css";
import { PriceForm, PriceFormModal } from "components/PriceForm";
import cn from 'classnames';
import { Button as ButtonContent } from "components/ContentSection/elements";

const RentalDetailsRentalInfo = () => {
  const { rental } = useRentalDetailsContext();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const activeDebounced = useDebounce(collapseOpen, 200);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const isMaxLarge = useMatchMedia({ maxWidth: MQ_BP.large });
  const isMaxMedium = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const [
    showNotificationPopover,
    setShowNotificationPopover
  ] = useState(false);

  useEffect(() => {
    if (!isMaxLarge) {
      setCollapseOpen(false);
    }
  }, [isMaxLarge]);

  const handleClick = useCallback(() => {
    setCollapseOpen((prev) => !prev);
  }, []);

  const handleKeyPress = (e) => {
    if ([keyCodes.ENTER].indexOf(e.which) >= 0) {
      handleClick();
    }
  };

  const handleToggleModal = useCallback(() => {
    setDetailsModalOpen((prev) => !prev);
  }, []);

  const collapseHeaderProps = {
    onClick: handleClick,
    role: "button",
    onKeyPress: handleKeyPress,
    tabIndex: "0"
  };

  const { status, rv, price, duration, rental_type: { key } } = rental;
  const instantRental = price?.instant_rental;
  const rentalTypeValue = Number(key) === 1 ? "Standard" : "Delivery only";
  const isOwner = isUserOwner(rental.role);
  const isRenter = isUserRenter(rental.role);
  const [showCancellingModal, setShowCancellingModal] = useState(false);
  const isRentalStatusCancelled = status?.name === 'Cancelled';
  const rvProfileUrl = rental?.rv?.public_url;
  const dates = `${formatApiDate(rental.departure_date)} — ${formatApiDate(rental.return_date)}`;
  const totalLabel = isOwner ? "You earn" : "Total";
  const total = isOwner ? price.owner_earns : price.payment_total;
  const durationStr = `${duration} ${duration > 1 ? "nights" : "night"}`;
  const mileageValue = getRvMileageValue(rental?.overages_conditions);
  const generatorValue = getRvGeneratorValue(rental?.overages_conditions);
  const pickUp = getRvPickUpLocation(rv, rental.pick_up_location);
  const pickUpLocationLabel = getPickUpLocationTitle(rental);

  const modalTitle = isOwner ? 'Payout info' : "Payment info";

  const cancellingTableData = [
    rental?.price_original_pf,
    rental?.price_refunds_pf,
    rental?.price_final_pf
  ];

  const tagStatus = status?.id >= 2 ? 'Confirmed rental' : status?.group;

  const {
    price_original: priceOriginal,
    price_refunds: priceRefunds
  } = rental;

  const showBanner = priceOriginal?.trip_interruption_insurance?.cents_total
    || priceRefunds?.trip_interruption_insurance?.cents_total
    || price?.trip_interruption_insurance?.cents_total;

  const isHideConfirmedSection = rental.status?.id === 0
    || rental.status?.id === 3
    || rental.status?.id === 4
    || rental.status?.id === 5;

  return (
    <div
      className={classnames(
        "rental-info",
        collapseOpen && "rental-info--active-transition",
        activeDebounced && "rental-info--active"
      )}
    >
      <a href={rvProfileUrl} target="_blank" rel="noreferrer">
        <picture className="rental-info__pic" >
          <source srcSet={rv.title_image} type="image/jpeg" />
          <img src={defaultRvImage} alt="Placeholder" />
        </picture>
      </a>

      {rental.show_confirmed_section
        && !isHideConfirmedSection
        && (
          <>
            <div className="rental-info__main">
              <div
                className="rental-info__main-header"
                {...(isMaxLarge ? collapseHeaderProps : {})}
              >
                <div className="rental-info__main-header-content">
                  {status && (isMaxLarge ? collapseOpen : true) && (
                    <p className="t-0 t-xl-16 b-0">
                      {instantRental ? (
                        <>
                          <Tooltip
                            tooltipContainerClassName={classes.statusTooltipContainer}
                            tooltipContent={"This rental was instantly confirmed with the Instant Book option."}
                          >
                            {(ref) => (
                              <Tag
                                text={tagStatus}
                                {...(isMaxSmall ? {
                                  size: "small",
                                  type: "default"
                                } : {
                                  inverse: true
                                })}
                                icon={instantRental ? tagIcon : null}
                                parentRef={ref}
                                className="rental-info__tag"
                              />
                            )}
                          </Tooltip>
                        </>
                      ) : (
                        <Tag
                          text={tagStatus}
                          {...(isMaxSmall ? {
                            size: "small",
                            type: "default"
                          } : {
                            inverse: true
                          })}
                          icon={instantRental ? tagIcon : null}
                          parentRef={null}
                          className="rental-info__tag"
                        />
                      )}


                    </p>
                  )}
                  {isMaxLarge && !isMaxSmall && !collapseOpen && (
                    <>
                      <RentalDetailsRentalInfoItem
                        label="Pick-up/Drop off"
                        value={dates}
                        variant="large"
                        boldValue
                      />
                      <RentalDetailsRentalInfoItem
                        label={totalLabel}
                        value={<FormattedPrice value={total} />}
                        variant="large"
                      />
                    </>
                  )}
                  {isMaxSmall && !collapseOpen && (
                    <>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className="rental-info__header-item"
                      >
                        {dates}
                      </Typography>
                      <Typography
                        variant="subtitle"
                        size="s"
                        className="rental-info__header-item rental-info__header-item--secondary"
                      >
                        {`${totalLabel}: $${total.user_friendly}`}
                      </Typography>
                    </>
                  )}
                </div>
                {isMaxLarge && (
                  <div className="rental-info__main-header-arrow">
                    <ChevronIcon />
                  </div>
                )}
              </div>
              {(isMaxLarge ? collapseOpen : true) && (
                <div className="rental-info__main-content">
                  <div className="rental-info__main-body">
                    <div className="rental-info__main-col">
                      <RentalDetailsRentalInfoItem
                        label="Rental type"
                        value={rentalTypeValue}
                        boldValue
                      />
                      <RentalDetailsRentalInfoItem
                        label="RV class"
                        value={rv.class}
                      />
                      <RentalDetailsRentalInfoItem
                        label="Pick-up"
                        value={formatApiDate(rental.departure_date)}
                        boldValue
                      />
                      <RentalDetailsRentalInfoItem
                        label="Drop off"
                        value={formatApiDate(rental.return_date)}
                        boldValue
                      />
                      <RentalDetailsRentalInfoItem
                        label="Duration"
                        value={durationStr}
                      />
                      {pickUp && (
                        <RentalDetailsRentalInfoItem
                          label={pickUpLocationLabel}
                          value={pickUp.value}
                        />
                      )}
                    </div>
                    <div className="rental-info__main-col">
                      {mileageValue && rentalTypeValue === "Standard" && (
                        <RentalDetailsRentalInfoItem
                          label="Mileage limit"
                          value={mileageValue.text}
                        />
                      )}
                      {rental?.overages_conditions?.mileage_overage_rate && mileageValue?.isFinite && rentalTypeValue === "Standard" && (
                        <RentalDetailsRentalInfoItem
                          label="Mileage overage rate"
                          value={
                            `$${rental?.overages_conditions?.mileage_overage_rate.user_friendly} per mile`
                          }
                        />
                      )}
                      {generatorValue && (
                        <RentalDetailsRentalInfoItem
                          label="Generator limit"
                          value={`${generatorValue.text}`}
                        />
                      )}
                      {rental?.overages_conditions?.generator_overage_rate && generatorValue?.isFinite && (
                        <RentalDetailsRentalInfoItem
                          label="Generator overage rate"
                          value={
                            `$${rental?.overages_conditions?.generator_overage_rate.user_friendly} per hour`
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="rental-info__main-footer">
                    <div className="rental-info__main-col">
                      <div className="rental-info__main-footer-items">
                        <PriceForm
                          data={[
                            rental?.rental_details_short_pf
                          ]}
                        />
                      </div>
                      <Button
                        ghost
                        className="rental-info__btn-details underline"
                        onClick={() => {
                          if (isRentalStatusCancelled) {
                            setShowCancellingModal(true);
                            setShowNotificationPopover(true);
                          } else {
                            handleToggleModal(true);
                          }
                        }}
                      >
                        Show details
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <PriceFormModal
              show={detailsModalOpen}
              title={modalTitle}
              data={[
                rental?.rental_details_pf
              ]}
              isFooter={false}
              onClose={handleToggleModal}
            />

            <PriceFormModal
              show={showCancellingModal}
              onClose={() => setShowCancellingModal(false)}
              title={modalTitle}
              data={cancellingTableData}
              menuData={[
                {
                  id: "1",
                  title: 'Original fee'
                },
                {
                  id: "2",
                  title: 'Refund'
                },
                {
                  id: "3",
                  title: 'Final amount'
                }
              ]}
              isFooter={false}
              banner={
                showBanner && showNotificationPopover && isRenter ? (
                  <NotificationPopover
                    show={true}
                    variant="static"
                    status="warning"
                    title="For Trip Interruption / Interior Protection Insurance coverage please contact IMG via:"
                    text={(
                      <span className="db mb-12">

                        <span className="db">
                          Phone at {" "}
                          <ButtonContent
                            id="tii_phone1"
                            section="linksContent"
                            label="1-866-243-7524"
                            ghost
                            prefixLink="tel:"
                            className={cn(classes.tiiLink, 'underline')}
                          />
                          {" "} or {" "}
                          <ButtonContent
                            id="tii_phone2"
                            section="linksContent"
                            label="317-655-9798"
                            ghost
                            prefixLink="tel:"
                            className={cn(classes.tiiLink, 'underline')}
                          />
                        </span>

                        <span className="db">
                          Email at {" "}
                          <ButtonContent
                            id="imglobal_claims_email"
                            section="linksContent"
                            label="itravelclaims@imglobal.com"
                            ghost
                            prefixLink="mailto:"
                            className={cn(classes.tiiLink, 'underline')}
                          />
                        </span>

                        <span className="db">
                          Claim forms can be found: {" "}
                          <ButtonContent
                            id="imglobal"
                            section="linksContent"
                            label="Claims Center - IMG"
                            ghost
                            newTab
                            className={cn(classes.tiiLink, 'underline')}
                          />
                        </span>

                      </span>
                    )}
                    onClose={() => { setShowNotificationPopover(false); }}
                  />) : null
              }
            />
          </>
        )}
    </div>
  );
};

export default RentalDetailsRentalInfo;
