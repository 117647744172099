/**
 * @param {String} name
 * @param {Object} [options]
 */
export function pushGoogleAnalytics(name, options = {}) {
  try {
    const { dataLayer } = window;

    if (typeof dataLayer === "object" && typeof dataLayer.push === "function") {
      dataLayer.push({
        event: name,
        ...options
      });
    }
  } catch (err) {
    console.error(err);
  }
}
