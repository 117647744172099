import { useLocation } from "react-router-dom";
import { parseQueryString } from "../utils/queryString";
import { useMemo } from "react";

export function useLocationQueryParams() {
  const location = useLocation();
  return useMemo(() => parseQueryString(location.search), [location.search]);
}

export function useLocationQueryParam(name) {
  const query = useLocationQueryParams(name);
  return query[name] || null;
}
