import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { max as rMax, min as rMin } from "ramda";
import FieldInput from "../FieldInput";
import Typography from "../../Typography";
import RangeSlider from "../../sliders/RangeSlider";

const ensureValidDisplay = (value) => {
  const intValue = parseInt(value);
  return Number.isNaN(intValue) ? "" : intValue.toString?.() || "";
};

const DEFAULT_INTERVAL = { min: 0, max: 1000000 };

const EMPTY_VALUE = "empty";

/**
 * Number Range Picker
 */
const NumberRangePicker = (props) => {
  const {
    className,
    startInputClassName,
    endInputClassName,
    sliderClassName,
    id,
    value,
    startLabel,
    endLabel,
    onChange,
    min,
    max,
    withSlider,
    useMinMaxAsDefaults
  } = props;

  const _min = min || DEFAULT_INTERVAL.min;
  const _max = max || DEFAULT_INTERVAL.max;

  const ensureMinMax = useCallback((value, type, doIntervalCheck = false) => {
    const isStart = type === "start";
    const intValue = parseInt(value);
    if (Number.isNaN(intValue)) {
      if (doIntervalCheck) {
        return isStart ? _min : _max;
      } else {
        return null;
      }
    }
    return doIntervalCheck ? rMax(rMin(intValue, _max), _min) : intValue;
  }, [_min, _max]);

  const _value = useMinMaxAsDefaults
    ? NumberRangePicker.getInitialValue(
      value?.start === EMPTY_VALUE ? _min : value?.start,
      value?.end === EMPTY_VALUE ? _max : value?.end
    )
    : value;

  const change = (start, end, doChecks = false) => {
    const result = { ..._value };
    if (start != null) {
      const prepared = ensureMinMax(start, "start", doChecks);
      result.start = doChecks && Number.isFinite(_value.end)
        ? rMin(prepared, _value.end)
        : prepared;
    }
    if (end != null) {
      const prepared = ensureMinMax(end, "end", doChecks);
      result.end = doChecks && Number.isFinite(_value.start)
        ? rMax(prepared, _value.start)
        : prepared;
    }
    // onChange will always get numbers or nulls
    onChange(result);
  };

  return (
    <div className={className}>
      <div className="field-group field-group--is-range">
        <FieldInput
          className={startInputClassName}
          id={`${id}_start`}
          label={startLabel}
          value={ensureValidDisplay(_value?.start)}
          onChange={(event) => change(event.target.value, null)}
          onBlur={(event) => change(event.target.value, null, true)}
          type="number"
        />
        <Typography
          className="field-group__divider ml-16 mr-16 ml-m-12 mr-m-12"
          variant="body"
          size="l"
        >
          {"—"}
        </Typography>
        <FieldInput
          className={endInputClassName}
          id={`${id}_end`}
          label={endLabel}
          value={ensureValidDisplay(_value?.end)}
          onChange={(event) => change(null, event.target.value)}
          onBlur={(event) => change(null, event.target.value, true)}
          type="number"
        />
      </div>
      {withSlider && (
        <Slider
          className={sliderClassName}
          value={_value}
          min={_min}
          max={_max}
          onChange={(value) => change(value[0], value[1])}
        />
      )}
    </div>
  );
};

/**
 * Isolated Slider with own local value
 */
const Slider = (props) => {
  const {
    className,
    value,
    min,
    max,
    onChange
  } = props;

  const toLocalValue = useCallback((value) => [
    value?.start,
    value?.end || max
  ], [max]);

  return (
    <RangeSlider
      className={`mt-32 mt-m-40 ${className}`}
      onChange={onChange}
      value={toLocalValue(value)}
      min={min}
      max={max}
    />
  );
};

NumberRangePicker.getInitialValue = (start, end) => ({ start, end });

NumberRangePicker.initialValue = NumberRangePicker.getInitialValue(
  EMPTY_VALUE,
  EMPTY_VALUE
);

NumberRangePicker.defaultProps = {
  withSlider: true,
  useMinMaxAsDefaults: true
};

const ValueEdgeType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

NumberRangePicker.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  value: PropTypes.shape({
    start: ValueEdgeType,
    end: ValueEdgeType
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  withSlider: PropTypes.bool,
  useMinMaxAsDefaults: PropTypes.bool
};

export default NumberRangePicker;
