import { gql } from "@apollo/client";

export const myProfileQueryAfterUpdate = gql`
  query {
    my_profile {
      id
      first_name
      last_name
      zip
      about
      images {
        profile_image {
          id
          srcset {
            url
            type
          }
        }
        cover_image {
          id
          srcset {
            url
            type
          }
        }
        profile_image_for_edit_tool {
          id
          srcset {
            url
            width
            height
            type
            rotation
            crop_area_x1
            crop_area_y1
            crop_area_x2
            crop_area_y2
          }
        }
        cover_image_for_edit_tool {
          id
          srcset {
            url
            width
            height
            type
            rotation
            crop_area_x1
            crop_area_y1
            crop_area_x2
            crop_area_y2
          }
        }
      }
    }
  }
`;
