import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { cameraIcon } from "components/Icon";
import classes from "./PhotoAvatarUpload.module.css";

export const PhotoAvatarUpload = ({ onUpload, disabled }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onUpload(acceptedFiles);
    },
    [onUpload]
  );

  const { open, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"]
    },
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled
  });

  const handleAreaClick = () => {
    if (window.Modernizr && window.Modernizr.touchevents) {
      open();
    }
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    open();
  };
  return (
    <div
      {...getRootProps({
        className: classes.avatarContainer
      })}
    >
      <div className={classes.avatarInner} onClick={handleAreaClick}>
        <div className={classes.avatarInner2} onClick={handleButtonClick}>
          <div className={classes.avatarPhotoCameraIcon}>{cameraIcon}</div>
          <div className={`${classes.avatarInput}`}>
            <input {...getInputProps()} />
          </div>
        </div>
      </div>
    </div>
  );
};

PhotoAvatarUpload.defaultProps = {
  disabled: false
};
