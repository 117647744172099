import React from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/Button";
import FieldInput from "../../components/form/FieldInput";
import Form from "../../components/form/Form/Form";
import Typography from "../../components/Typography";
import classes from "./PasswordResetForm.module.css";
import { LoginLinkProvider } from "processes/Login/LoginLinkProvider";

const defaultValues = {
  email: ""
};

const PasswordResetForm = (props) => {
  const {
    className,
    onPasswordRestoreSubmit,
    loading
  } = props;

  const { handleSubmit, errors, control } = useForm({
    defaultValues,
    mode: "onSubmit"
  });

  return (
    <div className={`row`}>
      <div className={`${className} col-l-10 col-xl-12`}>
        <div
          className={`col-m-8 col-l-5 col-xl-4 ${classes.passwordResetFormInner}`}
        >
          <Typography variant="headline" size="l" className={`row`}>
            Password recovery
          </Typography>
          <Form onSubmit={handleSubmit(onPasswordRestoreSubmit)} protect>
            <div className="t-4 b-20 t-m-12 mt-2 mt-m-0 row ">
              <Typography variant="body" size="l">
                Enter your email address
              </Typography>
              <Controller
                name="email"
                control={control}
                rules={{ required: "Email is required" }}
                render={(renderProps) => (
                  <FieldInput
                    {...renderProps}
                    errors={errors}
                    hasError={Boolean(errors?.email?.message)}
                    errorMessage={errors?.email?.message}
                    className="fw"
                    id="password-reset-email"
                    label="Email"
                  />
                )}
              />
            </div>
            <div className="t-20 row">
              <div className="btn-group">
                <Button type="submit" disabled={loading}>
                  Send new password
                </Button>
                <LoginLinkProvider>
                  <Button ghost>
                    Cancel
                  </Button>
                </LoginLinkProvider>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

PasswordResetForm.propTypes = {
  className: PropTypes.string,
  requestErrors: PropTypes.array,
  onPasswordRestoreSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default PasswordResetForm;
