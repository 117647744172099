import { Observable } from "@apollo/client";
import queueBuilder from "fastq";

function weave(name, fn) {
  return fn;

  /*
  return function (...args) {
    console.log(`before ${name}`);

    const result = fn.apply(this, args);

    console.log(`after ${name}`);

    return result;
  };
   */
}

function worker({ observer, operation, forward }, done) {
  // console.log('execute', operation);

  forward(operation).subscribe({
    next: weave('next', observer.next.bind(observer)),
    error: weave('error', observer.error.bind(observer)),
    complete: weave('complete', () => {
      observer.complete();
      done();
    })
  });
}

const queue = queueBuilder(worker, 1);
const refreshQueue = queueBuilder(worker, 1);

export function waitingLineLink(operation, forward) {
  // console.log('schedule', operation);

  return new Observable(weave('queue', observer => {
    const task = {
      observer,
      operation,
      forward
    };

    if (operation.operationName === "refresh_token") {
      refreshQueue.push(task);
    } else {
      queue.push(task);
    }
  }));
}
