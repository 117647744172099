import { useMemo } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

const itemCountDefault = {
  mobile: 3,
  tablet: 4,
  desctop: 5
};

function getCount(totalCount, count) {
  const remainCount = totalCount - count;

  if (remainCount < 0) return [null, count];
  return [remainCount, count];
}

export function useItemsCount(items, initialItemCount = itemCountDefault) {
  const isMobile = useMatchMedia({ maxWidth: MQ_BP.small });
  const isTablet = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.medium });

  const [totalCount, pageItemsCount] = useMemo(() => {

    const itemsCount = items?.length;
    const {
      mobile,
      tablet,
      desctop
    } = initialItemCount;

    if (isMobile) return getCount(itemsCount, mobile);
    else if (isTablet) return getCount(itemsCount, tablet);
    return getCount(itemsCount, desctop);


  }, [items?.length, initialItemCount, isMobile, isTablet]);

  return [totalCount, pageItemsCount];
}
