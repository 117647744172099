import React, { useState, useCallback, useMemo } from "react";
import Typography from "components/Typography";
import { TextItem } from "components/TextItem";
import { PriceFormSubItems } from "components/PriceForm";
import {
  PRICE_FORM_ITEM_STYLE,
  PRICE_FORM_ITEM_SEPARATOR,
  PRICE_FORM_ITEM_ACTION_POS,
  PRICE_FORM_ITEM_INFO_TYPE,
  PRICE_FORM_ITEM_TAGS
} from "components/PriceForm/constants";
import Tooltip from "components/Tooltip/Tooltip";
import Button from "components/Button";
import { chevronIcon, questionIconOutline } from "components/Icon";
import cn from "classnames";
import classes from "./PriceFormItem.module.css";

const PriceFormItem = (props) => {
  const {
    forms,
    itemInd,
    onAction: propsOnAction,
    onInfo
  } = props;

  const [expanded, setExpanded] = useState(false);

  const firstForm = forms[0];
  const firstFormItem = firstForm.items[itemInd];
  const propsActionId = firstFormItem?.action?.id;
  const actionPos = firstFormItem?.action?.pos;
  const isSubItems = firstFormItem?.sub_items?.length > 0;
  const info = firstFormItem?.info;
  const title = firstFormItem?.title;
  const style = firstFormItem?.style;
  const desc = firstFormItem?.desc;
  const separ = firstFormItem?.separ;
  const tooltipContent = firstFormItem?.info?.data?.value;
  const isSingleForm = forms?.length === 1;

  const onAction = useCallback(() => {
    if (propsActionId) propsOnAction(propsActionId);
  }, [propsActionId, propsOnAction]);

  const onExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const tagDesc = useMemo(() => [
    {
      tag: PRICE_FORM_ITEM_TAGS.BOLD,
      classNames: cn(style === PRICE_FORM_ITEM_STYLE.HEAD ? [classes.colorTextBody, classes.subTitleL] : [classes.colorTextBody, classes.bodyLBold])
    },
    {
      tag: PRICE_FORM_ITEM_TAGS.DISCOUNT,
      classNames: cn(style === PRICE_FORM_ITEM_STYLE.HEAD ? [classes.statusSuccess, classes.subTitleL] : [classes.statusSuccess, classes.bodyLBold])
    },
    {
      tag: PRICE_FORM_ITEM_TAGS.ACTION,
      classNames: cn(style === PRICE_FORM_ITEM_STYLE.HEAD ? [classes.action, classes.primary, classes.subTitleL] : [classes.action, classes.primary, classes.bodyLBold]),
      onClick: onAction
    },
    {
      tag: PRICE_FORM_ITEM_TAGS.LIGHT,
      classNames: cn(style === PRICE_FORM_ITEM_STYLE.HEAD ? [classes.colorTextBodyLight, classes.subTitleL] : [classes.colorTextBodyLight])
    }
  ], [onAction, style]);

  const descTagDesc = [
    {
      tag: PRICE_FORM_ITEM_TAGS.BOLD,
      classNames: cn([classes.colorTextBodyLight, classes.bodyLBold])
    },
    {
      tag: PRICE_FORM_ITEM_TAGS.DISCOUNT,
      classNames: cn([classes.statusSuccess, classes.bodyLBold])
    }
  ];

  return (
    <div className={cn(
      separ === PRICE_FORM_ITEM_SEPARATOR.SOLID && classes.borderTopSolid,
      separ === PRICE_FORM_ITEM_SEPARATOR.DASH && classes.borderTopDash,
      separ === PRICE_FORM_ITEM_SEPARATOR.JOIN && classes.borderTopJoin,
    )}>
      <div className={cn(
        classes.headerRow,
        isSingleForm && classes.isSingleForm
      )}>
        <div className={cn(classes.titleColumn)}>
          <div
            className={cn(
              classes.titleContainer,
              (actionPos === PRICE_FORM_ITEM_ACTION_POS.TITLE || isSubItems) && classes.cursorPointer
            )}
          >
            {title && (
              <div
                className={cn(
                  classes.textContainer,
                  (actionPos === PRICE_FORM_ITEM_ACTION_POS.TITLE || isSubItems) && classes.cursorPointer
                )}
                onClick={actionPos === PRICE_FORM_ITEM_ACTION_POS.TITLE ? onAction : isSubItems ? onExpand : null}
              >
                <TextItem
                  data={title}
                  plainStyle={
                    style === PRICE_FORM_ITEM_STYLE.HEAD ?
                        ((actionPos === PRICE_FORM_ITEM_ACTION_POS.TITLE) ? classes.headActionPlainCommonStyle : classes.headPlainCommonStyle) :
                        ((actionPos === PRICE_FORM_ITEM_ACTION_POS.TITLE) ? classes.regularActionPlainCommonStyle : classes.regularPlainCommonStyle)
                  }
                  tagCommonStyle={
                    style === PRICE_FORM_ITEM_STYLE.HEAD ?
                        ((actionPos === PRICE_FORM_ITEM_ACTION_POS.TITLE) ? classes.headActionTagCommonStyle : classes.headTagCommonStyle) :
                        ((actionPos === PRICE_FORM_ITEM_ACTION_POS.TITLE) ? classes.regularActionTagCommonStyle : classes.regularTagCommonStyle)
                  }
                  htmlStyle={classes.formDescHtmlStyle}
                  tagDesc={tagDesc}

                />
              </div>
            )}

            {info && (
              <div
                className={cn(
                  classes.infoContainer,
                )}
              >
                {info?.type === PRICE_FORM_ITEM_INFO_TYPE.TOOLTIP && (
                  <Tooltip
                    tooltipContent={tooltipContent}
                    offset={[8, 8]}
                  >
                    {(ref) => (
                      <span
                        ref={ref}
                        className={cn(
                          classes.questionIconContainer,
                        )}
                      >
                        {questionIconOutline}
                      </span>
                    )}
                  </Tooltip>
                )}

                {info?.type !== PRICE_FORM_ITEM_INFO_TYPE.TOOLTIP && (
                  <div
                    className={classes.infoModal}
                    onClick={() => {
                      if (onInfo) onInfo(info);
                    }}
                  >
                    <span
                      className={cn(
                        classes.questionIconContainer,
                      )}
                    >
                      {questionIconOutline}
                    </span>
                  </div>
                )}
              </div>
            )}

            {isSubItems && (
              <div
                className={cn(
                  classes.chevronIconContainer,
                )}
                onClick={onExpand}
              >
                <span
                  className={cn(
                    classes.chevronIcon,
                    expanded ? classes.chevronDown : classes.chevronUp
                  )}
                >
                  {chevronIcon}
                </span>
              </div>
            )}

          </div>
        </div>

        {forms.map((form, formIndex) => {
          const value = form?.items?.[itemInd]?.value;
          const units = form?.items?.[itemInd]?.units;

          return (
            <div
              className={classes.valueColumn}
              key={formIndex}
            >
              <div className={classes.valueContainer}>
                {value && (
                  <div
                    className={cn(
                      classes.textContainer,
                      actionPos === PRICE_FORM_ITEM_ACTION_POS.VALUE && classes.cursorPointer
                    )}
                    onClick={actionPos === PRICE_FORM_ITEM_ACTION_POS.VALUE ? onAction : null}
                  >
                    <TextItem
                      data={value}
                      plainStyle={
                        style === PRICE_FORM_ITEM_STYLE.HEAD ?
                            ((actionPos === PRICE_FORM_ITEM_ACTION_POS.VALUE) ? classes.headActionPlainCommonStyle : classes.headPlainCommonStyle) :
                            ((actionPos === PRICE_FORM_ITEM_ACTION_POS.VALUE) ? classes.regularActionPlainCommonStyle : classes.regularPlainCommonStyle)
                      }
                      tagCommonStyle={
                        style === PRICE_FORM_ITEM_STYLE.HEAD ?
                            ((actionPos === PRICE_FORM_ITEM_ACTION_POS.VALUE) ? classes.headActionTagCommonStyle : classes.headTagCommonStyle) :
                            ((actionPos === PRICE_FORM_ITEM_ACTION_POS.VALUE) ? classes.regularActionTagCommonStyle : classes.regularTagCommonStyle)
                      }
                      htmlStyle={classes.formDescHtmlStyle}
                      tagDesc={tagDesc}

                    />
                  </div>
                )}

                {units && (
                  <div className={classes.unitsContainer}>
                    <Typography
                      component="h1"
                      variant="headline"
                      size="m"
                      className={(
                        cn(
                          style === PRICE_FORM_ITEM_STYLE.HEAD ?
                              ((actionPos === 3) ? classes.headActionTagCommonStyle : classes.headTagCommonStyle) :
                              ((actionPos === 3) ? classes.regularActionTagCommonStyle : classes.regularTagCommonStyle)
                        )
                      )}
                    >
                      /{units}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {expanded && (
        <div className={cn(
          classes.subItemsContainer
        )}>
          <PriceFormSubItems
            forms={forms}
            itemInd={itemInd}
          />
        </div>
      )}

      {desc && (
        <div className={classes.descContainer}>
          <TextItem
            data={desc}
            plainStyle={classes.descPlainStyle}
            tagCommonStyle={classes.descTagCommonStyle}
            htmlStyle={classes.formDescHtmlStyle}
            tagDesc={descTagDesc}
          />
        </div>
      )}

      {actionPos === PRICE_FORM_ITEM_ACTION_POS.BUTTON && (
        <div className={classes.actionButtonContainer}>
          <Button
            ghost
            label={firstFormItem.action.title}
            onClick={onAction}
            size="medium"
            className={classes.actionButton}
          />
        </div>
      )}

    </div>
  );
};

PriceFormItem.propTypes = {
};

export default PriceFormItem;
