export function getSubstringBefore(string, sub) {
  if(string.indexOf(sub) < 0) return false;

  return string.substring(0, string.indexOf(sub));
}

export function getSubstringAfter(string, sub) {
  if(string.indexOf(sub) < 0) return false;

  return string.substring(string.indexOf(sub) + sub.length);

}

export function getSubstringFromBetween(string, sub1, sub2) {
  if(string.indexOf(sub1) < 0 || string.indexOf(sub2) < 0) return false;

  const SP = string.indexOf(sub1)+sub1.length;
  const string1 = string.substr(0,SP);
  const string2 = string.substr(SP);
  const TP = string1.length + string2.indexOf(sub2);

  return string.substring(SP,TP);
}

export function getSubstringsObj(string, sub1, sub2, sub3) {
  if(string.indexOf(sub1) < 0 || string.indexOf(sub2) < 0 || string.indexOf(sub3) < 0) return false;

  const subFromBetween = getSubstringFromBetween(string, sub1, sub2);
  const subBefore = getSubstringBefore(subFromBetween, sub3);
  const subAfter = getSubstringAfter(subFromBetween, sub3);

  return {
    initialString: string,
    subBefore: subBefore,
    subAfter: subAfter
  };
}

export function parseFormattedText(input, sub1 = "{{", sub2 = "}}", sub3 = "|") {
  if(
    input.indexOf(sub1) < 0
    || input.indexOf(sub2) < 0
    || input.indexOf(sub3) < 0
  ) {
    return [{
      s: input,
      tVal: null
    }];
  }

  const res = [];
  let currInd = 0;

  while (currInd < input.length) {
    const start = input.indexOf(sub1, currInd);
    if (start === -1) {
      res.push({ s: input.slice(currInd), tVal: null });
      break;
    }
    const end = input.indexOf(sub2, start + 2);
    if (end === -1) {
      res.push({ s: input.slice(currInd), tVal: null });
      break;
    }

    if (start !== currInd) {
      res.push({ s: input.slice(currInd, start), tVal: null });
    }

    const t = input.slice(start + 2, end);
    const [s, tVal] = t.split(sub3);
    res.push({ s, tVal: tVal || null });
    currInd = end + 2;
  }

  return res;
}
