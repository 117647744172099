import React, { forwardRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Radio from "./Radio";
import classes from "./RadioList.module.css";

const RadioList = forwardRef((props, ref) => {
  const { className, value, list, onChange, isVertical = false, ...other } = props;

  return (
    <div
      ref={ref}
      className={classnames(
        className,
        { "row no-gutters": !isVertical, [classes.isVertical]: isVertical }
      )}
    >
      {list.map(item => (
        <div key={item.value} className="checkbox-wrap">
          <Radio
            id={item.value}
            name={item.value}
            text={item.text}
            disabled={item.disabled}
            checked={value === item.value}
            onChange={(e) => {
              onChange(e.target.checked ? item.value : null);
            }}
            // {...other}
          />
        </div>
      ))}
    </div>
  );
});

RadioList.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  })),
  isVertical: PropTypes.bool
};

export default RadioList;
