import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Typography from "../../Typography";
import { ThreadType } from "../types";
import { useChatContext } from "../ChatContext";
import ThreadsListItem from "./ThreadsListItem";
import { useReachabilityState } from "../api";
import classes from "./ThreadsList.module.css";

const ThreadsList = (props) => {
  const { list, onClickThread } = props;
  const listRef = useRef();
  const { apiClient, getThreadUnreadCount, getThreadInfo } = useChatContext();
  const { isThreadOnline } = useReachabilityState({ threads: list, apiClient });

  return (
    <div className={classnames(classes.root, "df fdc")}>
      <Overlay listRef={listRef} />
      <div ref={listRef} className={classnames(classes.list, "df fdc")}>
        {list.length === 0 && (
          <div className={classnames(classes.empty, "df fcc tac r-16 l-16")}>
            <Typography variant="body" size="l">
              No threads found for this request
            </Typography>
          </div>
        )}
        {list.map(thread => (
          <ThreadsListItem
            key={thread.sid}
            thread={thread}
            isOnline={isThreadOnline(thread)}
            info={getThreadInfo(thread)}
            onClick={onClickThread}
            unreadCount={getThreadUnreadCount(thread)}
          />
        ))}
      </div>
    </div>
  );
};

const Overlay = ({ listRef }) => {
  const [hidden, setHidden] = useState();
  const list = listRef.current;

  const onScroll = useCallback((event) => {
    const element = event.target;
    const offset = 30;
    const scrollValue = element.scrollHeight - element.scrollTop - offset;
    const isAtBottom = scrollValue <= element.clientHeight;
    setHidden(isAtBottom);
  }, []);

  useEffect(() => {
    if (list) {
      list.addEventListener("scroll", onScroll);
      return () => list.removeEventListener("scroll", onScroll);
    }
  }, [list, onScroll]);

  return (
    <div
      className={classnames(
        classes.overlay,
        { [classes.overlayHidden]: hidden }
      )}
    />
  );
};

ThreadsList.propTypes = {
  list: PropTypes.arrayOf(ThreadType).isRequired,
  activeThread: PropTypes.object,
  onClickThread: PropTypes.func.isRequired
};

export default ThreadsList;
