import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Tooltip from "components/Tooltip/Tooltip";
import { lightningIcon } from "components/Icon";
import { deliveryOnlyIcon } from "components/Icon";
import { standardRentalIcon } from "components/Icon";
import cn from "classnames";
import classes from "./VehicleCardWidget.module.css";

function getFeatures(miles, length, sleeps, slideOuts, rentalType) {
  let features = [
    {
      id: 0,
      name: 'Miles allowed',
      numb: miles
    },
    {
      id: 1,
      name: 'Length (ft)',
      numb: length
    },
    {
      id: 2,
      name: 'Sleeps',
      numb: sleeps
    },
    {
      id: 3,
      name: 'Slide outs',
      numb: slideOuts
    }
  ];

  if (miles === 0 || rentalType === 2) {
    features = features.slice(1);
  }
  return features;
}

const VehicleCardFeatures = ({ data = [] }) => {
  const features = data.map(({ id, name, numb }, index) => (
    <div
      className={classes.featuresItem}
      key={id}
    >
      <Typography
        className={classes.featuresName}
        variant="caption"
      >
        {name}
      </Typography>
      <Typography
        className={` ${classes.featuresNumb}`}
        variant="body"
        size="s"
      >
        {numb}
      </Typography>
    </div>
  ));

  return (
    <div className={`${classes.features} mt-8 df`}>
      {features}
    </div>
  );
};

const VehicleCardWidget = (props) => {

  const {
    imageUrl,
    link,
    title,
    name,
    price,
    deliveredFrom,
    rvClass,
    length,
    sleeps,
    slideOuts,
    miles,
    rentalType,
    instantRental,
    isDarkTheme
  } = props;

  const features = getFeatures(
    miles,
    length,
    sleeps,
    slideOuts,
    rentalType
  );

  const preventPropagation = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={`${classes.container}`}
    >
      <div className={classes.imgContainer}>
        <img
          className={classes.img}
          src={imageUrl}
          alt={title}
        />
      </div>

      <div className={classes.content}>
        <div
          className={cn(
            classes.headerContainer,
            "r-16 l-16 b-8 b-m-12 l-m-24 r-m-24 mt-8"
          )}
        >
          <div className={classes.header}>

            <div className={classes.nameContainer}>
              <div className={classes.name}>
                <Typography
                  className={classes.title}
                  variant="body"
                  size="l"
                  weight="bold"
                >
                  {name}
                </Typography>
              </div>
              <div className={classes.rvClassContainer}>
                <Typography
                  className={classes.rvClass}
                  variant="body"
                  size="s"
                >
                  {rvClass}
                </Typography>
              </div>
            </div>

            <div className={classes.perNightContainer}>
              <div className={classes.priceContainer} onClick={instantRental ? preventPropagation : null} >
                {instantRental && (
                  <div className={classes.lightningIconContainer}>
                    <Tooltip
                      tooltipContainerClassName={cn(
                        classes.lightningTooltipContent,
                        isDarkTheme && classes.lightningTooltipContentDark
                      )}
                      tooltipContent={(
                        <span className="">
                          Instant Book: An easy way to book immediately without needing to send a request to the RV owner for approval.
                        </span>
                      )}
                      placement="left"
                      offset={[0, 8]}
                      trigger={['hover']}
                    >
                      {(ref) => (
                        <span
                          ref={ref}
                          className={cn(
                            classes.lightningIcon,
                            isDarkTheme && classes.lightningIconDark
                          )}
                        >
                          {lightningIcon}
                        </span>
                      )}
                    </Tooltip>
                  </div>
                )}

                <div className={classes.priceSumContainer}>
                  <Typography
                    className={classes.priceSum}
                    variant="body"
                    size="l"
                    weight="bold"
                  >
                    ${price}
                  </Typography>
                </div>
              </div>
              <div className={classes.priceTextContainer}>
                <Typography
                  className={classes.priceText}
                  variant="caption"
                >
                  per night
                </Typography>
              </div>
            </div>
          </div>
          <VehicleCardFeatures data={features} />
        </div>
        <div className={classes.footer}>
          <div
            className={cn(
              classes.footerInner,
              "r-16 l-16 l-m-24 r-m-24"
            )}
          >
            <div
              className={cn(
                classes.deliveredFrom,
                (rentalType === 1 || rentalType === 2) && classes.deliveredFromSmall
              )}
            >
              <div className={classes.deliveredFromInner}>
                <Typography
                  className={classes.deliveredFromTitle}
                  variant="body"
                  size="s"
                >
                  {deliveredFrom}
                </Typography>
              </div>
            </div>
            <div className={classes.footerIcons} onClick={preventPropagation}>
              {(rentalType === 1 || rentalType === 3) && (
                <div>
                  <Tooltip
                    tooltipContainerClassName={cn(
                      classes.footerIconContent,
                      isDarkTheme && classes.footerIconContentDark
                    )}
                    tooltipArrowClassName={cn(
                      isDarkTheme && classes.tooltipArrowDark
                    )}
                    tooltipContent={(
                      <span className="">
                        <strong className="fw-500">Standard rental:</strong> Owner allows renter to drive or tow their RV. Delivery may also be available.
                      </span>
                    )}
                    trigger={['hover']}
                  >
                    {(ref) => (
                      <span ref={ref} className={cn(
                        classes.standardRentalIcon,
                        isDarkTheme && classes.standardRentalIconDark
                      )}>
                        {standardRentalIcon}
                      </span>
                    )}
                  </Tooltip>
                </div>
              )}
              {(rentalType === 2 || rentalType === 3) && (
                <div>
                  <Tooltip
                    tooltipContainerClassName={cn(
                      classes.footerIconContent,
                      isDarkTheme && classes.footerIconContentDark
                    )}
                    tooltipArrowClassName={cn(
                      isDarkTheme && classes.tooltipArrowDark
                    )}
                    tooltipContent={(
                      <span className="">
                        <strong className="fw-500">Delivery only rental:</strong> This owner will deliver and setup their RV, only. No renter driving or towing
                      </span>
                    )}
                    trigger={['hover']}
                  >
                    {(ref) => (
                      <span ref={ref} className={cn(
                        classes.deliveryOnlyIcon,
                        isDarkTheme && classes.deliveryOnlyIconDark
                      )}>
                        {deliveryOnlyIcon}
                      </span>
                    )}
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );

};

VehicleCardWidget.propTypes = {
};

export default VehicleCardWidget;
