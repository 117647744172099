import { useRef, useState, useEffect } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Button from "components/Button";
import Dropdown from "components/Dropdown/Dropdown";
import { ReactComponent as IconShare } from "processes/PublicProfile/images/icon-share.svg";
import { ReactComponent as IconFacebook } from "assets/images/facebook.svg";
import { ReactComponent as IconTwitter } from "assets/images/twitter.svg";

function useShareButton(label = "") {
  const buttonShareRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMobile = useMatchMedia({ minWidth: MQ_BP.small });
  const isTable = useMatchMedia({ minWidth: MQ_BP.medium });

  useEffect(() => {
    setDropdownOpen(false);
  }, [isMobile, isTable]);
  return (
    <div>
      <Button
        ref={buttonShareRef}
        ghost
        icon={<IconShare />}
        iconPosition="before"
        label={label}
        onClick={() => setDropdownOpen(true)}
        size="medium"
      />
      <Dropdown
        open={dropdownOpen}
        onClose={() => setDropdownOpen(false)}
        anchorEl={buttonShareRef.current}
      >
        <Dropdown.List>
          <Dropdown.ListItem onClick={() => {}}><div className="fsc"><IconFacebook /> Facebook</div></Dropdown.ListItem>
          <Dropdown.ListItem onClick={() => {}}><div className="fsc"><IconTwitter /> Twitter</div></Dropdown.ListItem>
        </Dropdown.List>
      </Dropdown>
    </div>
  );
}

export default useShareButton;
