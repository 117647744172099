import { CLUB_IDS, LINKS_DEFAULT }  from "components/MembershipDetails/constants";

function returnLinks(links, club_id) {
  return {
    joinLink: links?.[club_id]?.joinLink || LINKS_DEFAULT[club_id].JOIN_LINK,
    memberLink: links?.[club_id]?.memberLink || LINKS_DEFAULT[club_id].MEMBER_LINK
  };
}

export function getLinks({ club_id }, links) {
  switch (club_id) {
  case CLUB_IDS.GOODSAM:
    return returnLinks(links, club_id);
  case CLUB_IDS.ROADSIDE:
    return returnLinks(links, club_id);
  case CLUB_IDS.TRAVELASSIST:
    return returnLinks(links, club_id);
  case CLUB_IDS.TWP:
    return returnLinks(links, club_id);
  default:
    return {
      joinLink: '',
      memberLink: ''
    };
  }
}
