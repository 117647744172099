import { useEffect, useReducer } from "react";

function sendPostMessageReady() {
  window.parent.postMessage({
    "type": "rv-rentals-widget:ready"
  },"*");
}

export function useGetWidgetData() {
  const [data, dispatch] = useReducer(getPostMessage, {
    theme: 'light',
    ownerDirectory: null,
    title: "",
    ownerPageUrl: {
      href: "",
      pathname: "",
      origin: ""
    },
    iframeUrl: "",
    iframeNumber: null
  });

  function getPostMessage(state, action) {
    if (action.data.type === 'rv-rentals-widget:config') {
      return {
        ownerDirectory: action.data.payload.ownerDirectory,
        theme: action.data.payload.theme,
        title: action.data.payload.title,
        ownerPageUrl: action.data.payload.ownerPageUrl,
        iframeUrl: action.data.payload.iframeUrl,
        iframeNumber: action.data.payload.iframeNumber
      };
    } else {
      return state;
    }
  }

  useEffect(() => {
    sendPostMessageReady();
    window.addEventListener('message', dispatch);
    return () => window.removeEventListener('message', dispatch);
  }, [dispatch]);

  return data;
}
