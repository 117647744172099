const ACCESS_TOKEN_KEY = 'cwrr:accessToken';
const ID_TOKEN_KEY = 'cwrr:idToken';
const REFRESH_TOKEN_KEY = 'cwrr:refreshToken';
const TOKEN_EXPIRES_KEY = 'cwrr:tokenExpires';
const OAUTH_CODE_VERIFIER_KEY = 'cwrr:oauthCodeVerifier';
const OAUTH_BACK_URL_KEY = 'cwrr:oauthBackUrl';
const OAUTH_URL_PARAMS = 'cwrr:oauthUrlParams';

function readValue(name) {
  try {
    return window.localStorage.getItem(name);
  } catch (e) {
    console.error(e);

    return null;
  }
}

function writeValue(name, value) {
  try {
    if (!value && typeof value === 'object') {
      window.localStorage.removeItem(name);
    } else {
      window.localStorage.setItem(name, value);
    }
  } catch (e) {
    console.error(e);
  }
}

function getExpires(lifetime) {
  const d = new Date();

  d.setSeconds(d.getSeconds() + lifetime);

  return d.getTime();
}

export function storeTokens(accessToken, refreshToken, tokenLifetime, idToken) {
  writeValue(ACCESS_TOKEN_KEY, accessToken);
  writeValue(REFRESH_TOKEN_KEY, refreshToken);
  writeValue(TOKEN_EXPIRES_KEY, getExpires(tokenLifetime ? tokenLifetime : 300));
  writeValue(ID_TOKEN_KEY, idToken);

  try {
    if (accessToken && idToken && refreshToken) {
      document.dispatchEvent(new CustomEvent('cwrr-set-tokens'));
    } else {
      document.dispatchEvent(new CustomEvent('cwrr-clear-tokens'));
    }
  } catch (e) {
  }
}

export function retrieveTokens() {
  return {
    expiresAt: readValue(TOKEN_EXPIRES_KEY),
    accessToken: readValue(ACCESS_TOKEN_KEY),
    idToken: readValue(ID_TOKEN_KEY),
    refreshToken: readValue(REFRESH_TOKEN_KEY)
  };
}

export function getIdToken() {
  return readValue(ID_TOKEN_KEY);
}

export function getOAuthCodeVerifier() {
  return readValue(OAUTH_CODE_VERIFIER_KEY);
}

export function setOAuthCodeVerifier(value) {
  return writeValue(OAUTH_CODE_VERIFIER_KEY, value);
}

export function getOAuthBackUrl() {
  return readValue(OAUTH_BACK_URL_KEY);
}

export function setOAuthBackUrl(value) {
  return writeValue(OAUTH_BACK_URL_KEY, value);
}

export function getOAuthUrlParams() {
  return readValue(OAUTH_URL_PARAMS);
}

export function setOAuthUrlParams(value) {
  return writeValue(OAUTH_URL_PARAMS, value);
}
