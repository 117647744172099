const getLocationFromSuggestion = async (
  suggestion,
  specificDeliveryLocations,
  rental,
  rentalType,
  isGeocodeLoaded
) => {
  return new Promise((resolve, reject) => {
    const { rv } = rental;

    if (suggestion.specific_delivery_location_id && suggestion.specific_delivery_location_id !== 0) {
      const location = specificDeliveryLocations.find((loc => suggestion.specific_delivery_location_id === loc.id))?.location;
      const locationId = specificDeliveryLocations.find((loc => suggestion.specific_delivery_location_id === loc.id))?.id;

      if (location && locationId) {
        resolve({
          [rentalType]: {
            locationSelected: true,
            fullAddress: location.fullAddress,
            deliveryRadius: null,
            specificDeliveryLocation: {
              location: location,
              id: Number(locationId)
            },
            radioState: String(locationId)
          }
        });
      } else {
        resolve({
          [rentalType]: {
            locationSelected: false
          }
        });
      }
    } else if (!suggestion.pick_up_location) {
      resolve({
        [rentalType]: {
          locationSelected: true,
          fullAddress: rv.location.full_address,
          deliveryRadius: null,
          specificDeliveryLocation: null,
          radioState: 'location-1'
        }
      });
    } else {
      if (isGeocodeLoaded) {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ 'address': suggestion.pick_up_location }).then((data) => {
          const { results } = data;

          resolve({
            [rentalType]: {
              locationSelected: true,
              fullAddress: suggestion.pick_up_location,
              deliveryRadius: {
                location: {
                  full_address: results[0].formatted_address,
                  latitude: results[0].geometry.location.lat(),
                  longitude: results[0].geometry.location.lng()
                },
                deliveryFee: suggestion.price.delivery_fee
              },
              specificDeliveryLocation: null,
              radioState: 'location-2'
            }
          });
        });
      } else {
        reject('Geocoder is not ready yet');
      }
    }
  });
};

export default getLocationFromSuggestion;
