import { BrowserRouter } from "react-router-dom";
import React, { StrictMode } from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/client";
import { ContentProvider } from "./components/ContentSection";
import { AppRouterSwitch } from "./router";

export const MainApp = () => (
  <StrictMode>
    <ApolloProvider client={client}>
      <ContentProvider>
        <BrowserRouter>
          <AppRouterSwitch />
        </BrowserRouter>
      </ContentProvider>
    </ApolloProvider>
  </StrictMode>
);
