import React, { useMemo } from "react";
import Typography from "components/Typography";
import ProfilePreDefinedAside from "processes/Rentals/RentalDetails/components/ProfileInfoAside/ProfilePreDefinedAside";
import ProfileInfoAside from "processes/Rentals/RentalDetails/components/ProfileInfoAside/ProfileInfoAside";
import ProfileInfoDeletedAside from "processes/Rentals/RentalDetails/components/ProfileInfoAside/ProfileInfoDeletedAside";
import { getRentalOpponent, isMembershipPassed, isUserOwner, isUserRenter } from "processes/Rentals/utils";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import { useStartChatAction } from "components/chat/api";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import cn from 'classnames';

function isHideTitle(data) {
  const { role, status, is_predefined } = data;
  const { id: statusId } = status;

  const isOwner = isUserOwner(role);

  return isOwner && is_predefined && statusId !== 0;
}

const ProfileInfoSection = () => {
  const { rental } = useRentalDetailsContext();
  const isRenter = isUserRenter(rental.role);
  const isOwner = isUserOwner(rental.role);
  const data = getRentalOpponent(rental);
  const opponentRole = isRenter ? 'Owner' : "Renter";
  const isRentalOpponentDeleted = data?.deleted;
  const isRentalPreDefined = rental.renter?.id === 0;
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });

  const {
    startChat,
    startChatLoading
  } = useStartChatAction(data.id);

  const asideData = useMemo(() => ({
    name: `${data.first_name} ${data.last_name}`,
    email: data?.email,
    phone: rental?.partner_phone,
    rating: data.rating,
    location: [data.city, data.state].filter(Boolean).join(", "),
    avatar: {
      src: data?.avatar?.srcset?.[0]?.url,
      alt: ""
    },
    hasBadge: isMembershipPassed(data),
    profileLink: `/profile/${data.id}`,
    onClickChat: startChat,
    chatLoading: startChatLoading,
    role: opponentRole
  }), [data, opponentRole, rental?.partner_phone, startChat, startChatLoading]);

  const renderInfo = () => {
    if (isRentalPreDefined && isOwner) {
      return <ProfilePreDefinedAside {...asideData} />;
    }

    if (isRentalOpponentDeleted) {
      return <ProfileInfoDeletedAside />;
    }

    return <ProfileInfoAside {...asideData} />;
  };

  return (
    <section
      className={cn(
        'b-20',
        !isMaxSmall && 't-20'
      )}
    >
      {!isHideTitle(rental) && (
        <Typography variant="subtitle" size="l" component="h2">
          {isRenter ? "Owner information" : "Renter information"}
        </Typography>
      )}

      <div className={cn(
        !isRentalPreDefined && 'dn db-m',
        isRentalPreDefined && !isMaxSmall && 't-20'
      )}>
        {renderInfo()}
      </div>
    </section>
  );
};

export default ProfileInfoSection;
