import React from "react";
import { ContentSectionElementImage } from "components/ContentSection/elements/Image";
import classes from "./PhotoImage.module.css";
import classNames from "classnames";
import Button from "components/Button";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";

export const PhotoImage = (props) => {
  const {
    className = "",
    cover,
    primary,
    content,
    onEdit,
    onDelete,
    attributes,
    listeners,
    dragOverlay,
    disabled
  } = props;
  const { id } = content;
  const elementId = `PhotoCoverImage-${id}`;

  const wrapperClassName = classNames(
    className,
    classes.wrapper,
    primary ? classes.primaryBadge : "",
    cover ? "" : classes.dragHandle
  );

  return (
    <div className={wrapperClassName}>
      <div className={classes.wrapperInside}>
        <div
          className={classes.aspectRatioKeeper}
          {...attributes}
          {...listeners}
        >
          <ContentSectionElementImage
            elementId={elementId}
            content={content}
            className={classes.image}
          />
        </div>

        {!dragOverlay && (
          <div className={classes.toolbarContainer}>
            <div className={cover ? classes.coverToolbar : classes.toolbar}>
              { onEdit && (
                <Button
                  icon={<EditIcon />}
                  label=""
                  noLabel=""
                  onClick={() => onEdit(id)}
                  size="medium"
                  disabled={disabled}
                  primary
                  inverse
                />
              )}
              {onDelete && (
                <Button
                  icon={<DeleteIcon />}
                  label=""
                  noLabel=""
                  onClick={() => onDelete(id)}
                  size="medium"
                  disabled={disabled}
                  className={classes.deleteButton}
                  primary
                  inverse
                />
              )}
            </div>

            <div className={cover ? classes.coverToolbarWrapper : classes.toolbarWrapper} />
          </div>
        )}
      </div>
    </div>
  );
};

PhotoImage.defaultProps = {
  disabled: false
};
