import React, { useState } from "react";
import Button from "components/Button";
import ModalCarousel from "components/ModalCarousel/ModalCarousel";
import classes from "./PhotoGrid.module.css";

export const PhotoGrid = ({ hasSlider = true, gridPhotos = [], sliderPhotos }) => {
  const [showModalGallery, setShowModalGallery] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const gridPhotosItems = gridPhotos.map(({ id, src, alt = '' }) => (
    <div key={id} className={classes.gridItem}>
      <div className={classes.gridPhotoContainer}>
        <img src={src} alt={alt} className={classes.gridPhoto}/>
      </div>
    </div>
  ));
  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        {gridPhotosItems}
      </div>

      {hasSlider && (
        <div className={classes.sliderContainer}>

          <div className={classes.showPhotosButton}>
            <Button
              label="Show all photos"
              onClick={() => setShowModalGallery(true)}
              secondary
            />
          </div>

          <div className={classes.slider}>
            <ModalCarousel
              showModal={showModalGallery}
              photos={sliderPhotos}
              onClose={() => setShowModalGallery(false)}
              initialSlide={initialSlide}
            />
          </div>
        </div>
      )}

    </div>
  );
};

// PhotoGrid.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

