import React, { useMemo, useEffect, forwardRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { rvsQuery } from "./queries/rvsQuery";
import { RvModelSelectorList } from "./RvModelSelectorList";
import { extractList } from "./utils";
import { Controller } from "react-hook-form";
import { SpinnerBack } from "../../../Spinner/Spinner";

const listExtractor = extractList("rvs");

const EmptyInput = forwardRef(({ onChange }, ref) => {
  useEffect(() => {
    onChange("");
  }, [onChange]);

  return <input ref={ref} type="hidden" value="" />;
});

export const RvModelSelector = ({
  control,
  year,
  manufacturer,
  make,
  renderManual,
  renderZeroResults
}) => {
  const [load, { data, loading }] = useLazyQuery(rvsQuery);

  useEffect(() => {
    load({
      variables: {
        year: parseInt(year, 10),
        manufacturer,
        make
      }
    });
  }, [year, manufacturer, make, load]);

  const list = useMemo(() => listExtractor(data), [data]);

  if (loading) {
    return <SpinnerBack />;
  }

  if (list.length < 1) {
    return (
      <>
        {renderZeroResults()}
        <Controller
          control={control}
          name="rvId"
          rules={{ required: "Required" }}
          render={(renderProps) => {
            return <EmptyInput {...renderProps} />;
          }}
        />
      </>
    );
  }

  return (
    <>
      <RvModelSelectorList list={list} control={control} />
      {renderManual()}
    </>
  );
};
