import React, { useCallback, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { path } from "ramda";
import classnames from "classnames";
import { useMutation } from "@apollo/client";
import { useForm, useWatch } from "react-hook-form";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { FieldInput } from "../form/FieldInput";
import Form from "../form/Form/Form";
import Button from "../Button";
import { VALIDATION } from "../../utils/validation";
import { SpinnerBack } from "../Spinner/Spinner";
import { FieldController } from "../form/FieldController/FieldController";
import { FormError } from "../../processes/MyAccount/steps/General/FormErrors";
import { extractServerErrors, extractSuccess } from "../../utils/extractErrors";
import { useApiButtons, useApiFields, useDefaultValues } from "./hooks";
import { FIELDS_IDS } from "./utils";
import { savePaymentInfo } from "./mutations/save";
import StripeInput from "./StripeInput";
import Select from "../form/Select";
import classes from "./PaymentForm.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const mutationName = "payment_info_save";
const extractMutationErrors = extractServerErrors(mutationName);
const extractMutationUser = path(["data", mutationName, "user"]);
const extractMutationSuccess = extractSuccess(mutationName);

const isUSA = (country) => country === "US";
const rawValueMapper = (v) => v;

const processCountryOptions = (options = []) => {
  return options.reduce(
    (prev, curr) => {
      const next = { ...prev };
      const processedItem = {
        value: curr.key,
        text: curr.value
      };

      if (processedItem.value === "US") {
        next.pinnedOptions.push(processedItem);
      } else {
        next.options.push(processedItem);
      }

      return next;
    },
    {
      pinnedOptions: [],
      options: []
    }
  );
};

const PaymentForm = (props) => {
  const {
    content,
    userData,
    updateUserData,
    onSave,
    onCancel,
    paymentFormContainerClassName,
    buttonsClassName,
    buttonCancelClassName,
    stepContainerClassNames,
    isCardExpired,
    textButtonNextStep,
    isMyAccount
  } = props;
  const [
    isShowNotificationPopoverSubmit,
    setShowNotificationPopoverSubmit
  ] = useState(true);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [action, result] = useMutation(savePaymentInfo);
  const defaultValues = useDefaultValues(userData);
  const stripe = useStripe();
  const elements = useElements();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues
  });

  const fields = useApiFields(content);
  const buttons = useApiButtons(content);
  const country = useWatch({ control, name: FIELDS_IDS.country });
  const _isUSA = isUSA(country);
  const countryOptions = useMemo(
    () => processCountryOptions(fields.country.options),
    [fields.country.options]
  );
  const stripeFields = useWatch({
    control,
    name: [FIELDS_IDS.card_number, FIELDS_IDS.expiration_date, FIELDS_IDS.cvv]
  });

  const getGlobalError = () => {
    const stripeValidationErrors = Object.values(stripeFields)
      .map((v) => v.error)
      .filter((v) => v);

    if (stripeValidationErrors.length) {
      return {
        errors: stripeValidationErrors,
        separator: " "
      };
    }

    return {
      errors: error ? [{ message: error }] : extractMutationErrors(result),
      separator: ", "
    };
  };
  const globalError = getGlobalError();

  const onSubmit = useCallback(
    async (data) => {
      setIsSubmitting(true);
      setError("");

      try {
        const element = elements.getElement(CardNumberElement);
        const _isUSA = isUSA(data[FIELDS_IDS.country]);
        const state = !_isUSA ? undefined : data[FIELDS_IDS.state];

        const stripeResult = await stripe.createToken(element, {
          name: data[FIELDS_IDS.card_holder],
          address_country: data[FIELDS_IDS.country],
          address_line1: data[FIELDS_IDS.address],
          address_city: data[FIELDS_IDS.city],
          address_state: state,
          address_zip: data[FIELDS_IDS.zip],
          currency: "usd"
        });

        const error = stripeResult?.error?.message;

        if (error) {
          setError(error);
          setIsSubmitting(false);
          setShowNotificationPopoverSubmit(true);
          return;
        }

        const stripeCard = stripeResult?.token?.card;
        const stripeToken = stripeResult?.token?.id;

        if (stripeToken) {
          const response = await action({
            variables: {
              ...data,
              [FIELDS_IDS.expiration_date]: undefined,
              [FIELDS_IDS.cvv]: undefined,
              [FIELDS_IDS.state]: state,
              [FIELDS_IDS.brand]: stripeCard.brand,
              [FIELDS_IDS.exp_month]: stripeCard.exp_month.toString(),
              [FIELDS_IDS.exp_year]: stripeCard.exp_year.toString(),
              [FIELDS_IDS.card_number]: stripeCard.last4,
              [FIELDS_IDS.token]: stripeToken
            }
          });

          const isSuccess = extractMutationSuccess(response);
          const user = extractMutationUser(response);
          if (isSuccess && user) {
            updateUserData?.(user);
            onSave();
          }
          let error = extractMutationErrors(response)?.[0]?.message;
          if (error) {
            setShowNotificationPopoverSubmit(true);
            setError(error);
          }

        } else {
          setError("Unknown error, please try again.");
          setShowNotificationPopoverSubmit(true);
        }
      } catch (e) {
        setError(e);
        setShowNotificationPopoverSubmit(true);
      }

      setIsSubmitting(false);
    },
    [
      stripe,
      onSave,
      elements,
      updateUserData,
      setShowNotificationPopoverSubmit,
      action
    ]
  );

  const stopPropagateParentForm = useCallback((callback) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      callback();
    };
  },[]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      setShowNotificationPopoverSubmit(true);
    }
  }, [errors]);

  return (
    <Form
      onSubmit={stopPropagateParentForm(handleSubmit(onSubmit))}
      className={classnames("t-16 t-m-20 b-16 b-m-0", paymentFormContainerClassName)}
    >
      {isSubmitting && <SpinnerBack />}
      <FieldsList>
        <FieldController
          name={FIELDS_IDS.card_number}
          control={control}
          rules={VALIDATION.rules.stripeElement}
          render={(renderProps) => (
            <FieldInput
              className="fw"
              label={fields.cardNumber.label}
              errors={errors}
              valueMapper={rawValueMapper}
              inputComponent={StripeInput}
              Element={CardNumberElement}
              stripeBrand={userData?.payment_info?.brand}
              stripeType="card"
              {...renderProps}
            />
          )}
        />
        <FieldsGroup>
          <FieldController
            name={FIELDS_IDS.expiration_date}
            control={control}
            rules={VALIDATION.rules.stripeElement}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.expDate.label}
                errors={errors}
                valueMapper={rawValueMapper}
                inputComponent={StripeInput}
                Element={CardExpiryElement}
                {...renderProps}
              />
            )}
          />
          <FieldController
            name={FIELDS_IDS.cvv}
            control={control}
            rules={VALIDATION.rules.stripeElement}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.cvv.label}
                errors={errors}
                valueMapper={rawValueMapper}
                inputComponent={StripeInput}
                Element={CardCvcElement}
                {...renderProps}
              />
            )}
          />
        </FieldsGroup>
        <FieldController
          name={FIELDS_IDS.card_holder}
          control={control}
          rules={VALIDATION.rules.required}
          render={(renderProps) => (
            <FieldInput
              className="fw"
              label={fields.cardHolder.label}
              errors={errors}
              {...renderProps}
            />
          )}
        />
        <FieldController
          name={FIELDS_IDS.country}
          control={control}
          rules={VALIDATION.rules.required}
          render={(renderProps) => (
            <Select
              className="fw"
              id={FIELDS_IDS.country}
              label={fields.country.label}
              options={countryOptions.options}
              pinnedOptions={countryOptions.pinnedOptions}
              errors={errors}
              {...renderProps}
            />
          )}
        />
        <FieldController
          name={FIELDS_IDS.address}
          control={control}
          rules={VALIDATION.rules.required}
          render={(renderProps) => (
            <FieldInput
              className="fw"
              label={fields.address.label}
              errors={errors}
              {...renderProps}
            />
          )}
        />
        <FieldController
          name={FIELDS_IDS.city}
          control={control}
          rules={VALIDATION.rules.required}
          render={(renderProps) => (
            <FieldInput
              className="fw"
              label={fields.city.label}
              errors={errors}
              {...renderProps}
            />
          )}
        />
        {_isUSA && (
          <FieldsGroup>
            <FieldController
              name={FIELDS_IDS.state}
              control={control}
              rules={VALIDATION.rules.required}
              render={(renderProps) => (
                <Select
                  className="fw"
                  id={FIELDS_IDS.state}
                  label={fields.state.label}
                  options={[
                    { value: "", text: "" },
                    ...fields.state.options?.map((i) => ({
                      value: i.key,
                      text: i.key
                    }))
                  ]}
                  errors={errors}
                  {...renderProps}
                />
              )}
            />
            <FieldController
              name={FIELDS_IDS.zip}
              control={control}
              rules={VALIDATION.rules.getZip()}
              render={(renderProps) => (
                <FieldInput
                  className="fw"
                  label={fields.zip.label}
                  errors={errors}
                  {...renderProps}
                />
              )}
            />
          </FieldsGroup>
        )}
        {!_isUSA && (
          <FieldController
            name={FIELDS_IDS.zip}
            control={control}
            rules={VALIDATION.rules.getZip()}
            render={(renderProps) => (
              <FieldInput
                className="fw"
                label={fields.postalCode.label}
                errors={errors}
                {...renderProps}
              />
            )}
          />
        )}
      </FieldsList>
      {globalError?.errors?.length !== 0 && Object.keys(errors).length > 0 && (
        <NotificationPopover
          show={isShowNotificationPopoverSubmit}
          status="error"
          text={globalError.errors
            .map((e) => e.message)
            .join(globalError.separator)}
          onClose={() => {
            setShowNotificationPopoverSubmit(false);
          }}
        />
      )}
      {error && isShowNotificationPopoverSubmit && (
        <NotificationPopover
          show={isShowNotificationPopoverSubmit}
          status="error"
          text={error}
          onClose={() => {
            setShowNotificationPopoverSubmit(false);
            setError("");
          }}
        />
      )}
      {isCardExpired && (
        <FormError
          requestErrors={[
            { message: "Your card has expired, please enter a new one" }
          ]}
        />
      )}
      <div
        className={classnames(stepContainerClassNames?.buttonsClassName || buttonsClassName, "df t-16 t-s-20 b-16 b-s-20")}
      >
        <div
          className={classnames(stepContainerClassNames?.buttonsContainerInner || classes.buttonsContainerInner, isMyAccount && classes.myAccountButtonsContainerInner)}
        >
          {onCancel && (
            <div className={classnames(buttonCancelClassName, " ")}>
              <Button
                disabled={result.loading}
                label="Cancel"
                onClick={onCancel}
                secondary
              />
            </div>
          )}
          <Button
            type="submit"
            disabled={result.loading || !stripe || !elements || !isDirty}
            label={textButtonNextStep || buttons.save?.label || "Save"}
          />
        </div>
      </div>
    </Form>
  );
};

const FieldsList = (props) => {
  const { children } = props;

  return (
    <div className={classnames(classes.fieldsList, "row")}>
      {(Array.isArray(children) ? children : [children])
        .filter(Boolean)
        .map((i, index) => (
          <div key={index} className="col-12 col-m-6 t-16 b-16 t-m-20 b-m-20">
            {i}
          </div>
        ))}
    </div>
  );
};

const FieldsGroup = (props) => {
  const { children } = props;

  const resultChildren = (Array.isArray(children)
    ? children
    : [children]
  ).filter(Boolean);

  return (
    <div className={classnames(classes.fieldsGroup, "row")}>
      {resultChildren.map((i, index) => (
        <div key={index} className="col-6">
          {i}
        </div>
      ))}
    </div>
  );
};

PaymentForm.propTypes = {
  content: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserData: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  buttonsClassName: PropTypes.string,
  isCardExpired: PropTypes.bool.isRequired
};

export default PaymentForm;
