import React from "react";
import { MileageAndGenerator } from "components/ModalInspections";
import { DateValue } from "components/ModalInspections";

export const IntValue = ({ title, value: rawValue, data: rawData }) => {
  const { units = null, type = false } = JSON.parse(rawData);

  if (units) {
    return <MileageAndGenerator title={title} value={rawValue} units={units} />;
  } else if (type === 'date') {
    const { date, time } = JSON.parse(rawValue);
    return <DateValue title={title} date={date} time={time} />;
  }
};
