import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";
import { STEPS } from "processes/Booking/components/BookingModal/steps";

export function handleBookingSegment(props) {
  const {
    data = {},
    eventName = "",
    formStep = false,
    formStepDescription = false,
    goodSamMember = false,
    formName = "Rentals Booking",
    buttonName = false,
    bookingAmount = false,
    rentalFee = false
  } = props;

  let dataAnalyticsTrack = {
    asset_id: String(data.rvId),
    location: String(data?.location),
    asset_year: String(data?.asset_year),
    asset_make: String(data?.asset_make),
    asset_model: String(data?.asset_model),
    asset_class: String(data?.asset_class),
    asset_price: Number(data?.asset_price),
    asset_brand: data?.asset_brand,
    asset_weight: Number(data?.asset_weight),
    asset_length: Number(data?.asset_length),
    asset_slideouts: Number(data?.asset_slideouts),
    asset_sleepnumber: Number(data?.asset_sleepnumber),
    owner_id: data?.owner_id,
    cwh_business: 'RV Rentals',
    fbp: "",
    fbc: ""
  };

  if (bookingAmount) {
    dataAnalyticsTrack.booking_amount = bookingAmount;
  }
  if (rentalFee) {
    dataAnalyticsTrack.rental_fee = rentalFee;
  }
  if (typeof formName !== "boolean" && formName === "Rentals Booking") {
    dataAnalyticsTrack.form_name = formName;
  }
  if (formStepDescription) {
    dataAnalyticsTrack.form_step_description = formStepDescription;
  }
  if (goodSamMember) {
    dataAnalyticsTrack.goodSamMember = data?.goodSamMember;
  }
  if (buttonName) {
    dataAnalyticsTrack.button_name = buttonName;
  }
  if (formStep) {
    dataAnalyticsTrack.form_step = formStep;
  }

  pushSegmentAnalyticsTrack(eventName, dataAnalyticsTrack);
}

export function handleInitialBookingSegment({ add_ons }, addInsuranceList, analyticsData) {
  let formStep = 3;
  let formStepDescription = STEPS.PAYMENT_METHOD.NAME;
  const isAddons = add_ons?.length > 0;
  const isInsurance = addInsuranceList?.insurances?.length > 0;

  if (isAddons) {
    formStep = 1;
    formStepDescription = STEPS.PAYMENT_METHOD.ADDONS;
  } else if (!isAddons && isInsurance) {
    formStep = 2;
    formStepDescription = STEPS.PAYMENT_OPTIONS.NAME;
  }
  handleBookingSegment({
    data: analyticsData,
    eventName: "Lead Form Step Viewed",
    formStep,
    goodSamMember: true,
    formStepDescription
  });
}

export function handleNextStepBookingSegment(currentStepIndex, addInsuranceList, steps, analyticsData) {
  let nextStepName = STEPS.PAYMENT_OPTIONS.NAME;

  if (steps[currentStepIndex]?.id === STEPS.ADDONS.ID && addInsuranceList?.insurances?.length) {
    nextStepName = STEPS.ADDITIONAL_INSURANCE.NAME;
  } else if ((steps[currentStepIndex]?.id === STEPS.ADDONS.ID && !addInsuranceList?.insurances?.length) || (steps[currentStepIndex]?.id === STEPS.ADDITIONAL_INSURANCE.ID)) {
    nextStepName = STEPS.PAYMENT_METHOD.NAME;
  }

  handleBookingSegment({
    data: analyticsData,
    eventName: "Lead Form Step Completed",
    formStep: steps[currentStepIndex]?.stepNumber,
    goodSamMember: true,
    formStepDescription: steps[currentStepIndex]?.title
  });
  handleBookingSegment({
    data: analyticsData,
    eventName: "Lead Form Step Viewed",
    formStep: steps[currentStepIndex]?.stepNumber + 1,
    goodSamMember: true,
    formStepDescription: nextStepName
  });
}
