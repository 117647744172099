import React, { forwardRef, useCallback, useEffect, useReducer, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Autocomplete from "react-google-autocomplete";
import { useFieldFocus } from "../useFieldFocus";
import "./style.css";

const componentRestrictions = {
  country: ["us"]
};
const DEFAULT_TYPES = ["address"];
/**
 * Component for finding an address
 * Don't forget to wrap the component in GoogleMapsProvider
 */
const AutocompleteAddress = forwardRef((props, ref) => {
  // Couldn't find another way to reset Google input value
  const [resetsCount, resetInput] = useReducer((p) => p + 1, 0);
  const {
    className,
    label,
    onChange,
    onChangeValue = () => {},
    defaultValue,
    resetOnDefaultValueChange,
    hasError,
    errorMessage,
    helperMessage,
    isReturnAllTypes = false
  } = props;
  const [value, setValue] = useState(defaultValue);
  const [focused, handleFocus, handleBlur] = useFieldFocus();
  const types = isReturnAllTypes ? [] : DEFAULT_TYPES;

  const onPlaceSelected = useCallback((place, e) => {
    const full_address = isReturnAllTypes ? e.value : place.formatted_address;
    const viewport = {
      center_lat: place.geometry.viewport.getCenter().lat(),
      center_lng: place.geometry.viewport.getCenter().lng(),
      viewport_northeast_lat: place.geometry.viewport.getNorthEast().lat(),
      viewport_northeast_lng: place.geometry.viewport.getNorthEast().lng(),
      viewport_southwest_lat: place.geometry.viewport.getSouthWest().lat(),
      viewport_southwest_lng:  place.geometry.viewport.getSouthWest().lng()
    };

    onChange({
      full_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      viewport: {
        ...viewport
      }
    });
  }, [isReturnAllTypes, onChange]);

  useEffect(() => {
    if (resetOnDefaultValueChange && value !== defaultValue) {
      setValue(defaultValue);
      resetInput();
    }
    // Must not trigger on value change:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const hint = errorMessage || helperMessage;

  return (
    <div
      className={classnames(
        className,
        "field-input",
        "field-input--type--text",
        "fw",
        {
          focused,
          filled: (value && value.length > 0),
          error: hasError
        }
      )}>
      <Autocomplete
        ref={ref}
        key={`Autocomplete_${resetsCount}`}
        className="field-input__field"
        options={{
          types,
          fields: ['geometry', 'formatted_address'],
          componentRestrictions
        }}
        onPlaceSelected={onPlaceSelected}
        inputAutocompleteValue={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeValue(e.target.value);
        }}
        defaultValue={defaultValue}
        placeholder=""
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label htmlFor="location" className="field-input__label">
        {label}
      </label>
      {hint && (
        <div className="field-input__helpers">
          <div className="field-input__hint">
            {hint}
          </div>
        </div>
      )}
    </div>
  );
});

AutocompleteAddress.defaultProps = {
  resetOnDefaultValueChange: true
};

AutocompleteAddress.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  resetOnDefaultValueChange: PropTypes.bool,
  helperMessage: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasError: PropTypes.bool
};

export default AutocompleteAddress;
