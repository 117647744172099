import React, { useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { randomBytes, createHash } from 'crypto';
import * as buffer from "buffer";
import base64url from 'base64url';
import { loginMutation } from "./mutations/loginMutation";
import { PasswordResetContainer } from "./PasswordResetContainer";
import { getBackUrlParam, getCodeParam } from "router/useLoginRedirect";
import { getOAuthBackUrl, getOAuthCodeVerifier, getOAuthUrlParams, setOAuthBackUrl, setOAuthCodeVerifier, setOAuthUrlParams, storeTokens } from "apollo/tokens";
import classes from "./LoginPage.module.css";
import Typography from "components/Typography";
import cartoonCarImageWebp from "./cartoon_car_dark.webp";
import cartoonCarImageGif from "./cartoon_car_green_dark.gif";
import { SpinnerBack } from "components/Spinner/Spinner";

window.Buffer = buffer.Buffer;

const REACT_APP_AUTH_URL = process.env.REACT_APP_AUTH_URL;
const REACT_APP_AUTH_CLIENT = process.env.REACT_APP_AUTH_CLIENT;

const getLoginUrl = (codeChallenge) => {
  const url = new URL(`${REACT_APP_AUTH_URL}/login`);
  url.searchParams.append('client_id', REACT_APP_AUTH_CLIENT);
  url.searchParams.append('scope', 'openid profile email');
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('redirect_uri', `${window.location.origin}/login`);
  url.searchParams.append('code_challenge', codeChallenge);
  url.searchParams.append('code_challenge_method', 'S256');

  return url.href;
};

const tokensRequest = async (code) => await fetch(`${REACT_APP_AUTH_URL}/oAuth/validateToken`, {
  method: 'POST',
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  },
  body: new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: REACT_APP_AUTH_CLIENT,
    code_verifier: getOAuthCodeVerifier(),
    code: code,
    redirect_uri: getOAuthBackUrl()
  })
}).catch(e => console.error(e.message));

export const Login = () => {
  const history = useHistory();
  const { search } = history.location;
  const urlParam = search.replace(/^\?/, '');
  const code = getCodeParam(search);

  const [action] = useMutation(loginMutation);

  const fetchToken = useCallback(async () => {
    const tokensRequestResult = await tokensRequest(code);
    const { access_token, id_token, refresh_token, expires_in } = await tokensRequestResult.json();

    if (access_token) {
      storeTokens(access_token, refresh_token, expires_in, id_token);

      await action({
        variables: {
          params_url: getOAuthUrlParams() || undefined
        }
      }).then(() => window.location.href = getOAuthBackUrl());

    } else {
      storeTokens(null, null, null, null);

      window.location.href = "/";
    }
  }, [action, code]);

  useEffect(() => {
    if (code) {
      fetchToken();
    } else {
      setOAuthBackUrl(getBackUrlParam(search) ?? window.location.origin);

      const codeVerifier = randomBytes(64).toString('hex');
      const hash = createHash('sha256').update(codeVerifier).digest();
      const codeChallenge = base64url(hash);
      const redirectURL = getLoginUrl(codeChallenge);

      setOAuthCodeVerifier(codeVerifier);
      setOAuthUrlParams(urlParam);

      window.location.href = redirectURL;
    }
  }, [code, fetchToken, search, urlParam]);

  return (
    <>
      {!code ? (
        <SpinnerBack />
      ) : (
        <div className={classes.redirectPage}>
          <div className={classes.redirectBlock}>
            <picture className={classes.redirectImgContainer}>
              <source srcSet={cartoonCarImageWebp} type="image/webp" />
              <img src={cartoonCarImageGif} alt="Let’s go on a journey..." />
            </picture>

            <Typography
              variant="headline"
              size="s"
              className={classes.redirectTitle}
            >
          Let’s go on a journey...
            </Typography>

            <Typography
              variant="subtitle"
              size="s"
              className={classes.redirectSubtitle}
            >
          We are redirecting you to another page, this may take some time.
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export const PasswordRecovery = () => {
  return <PasswordResetContainer />;
};
