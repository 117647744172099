import React from "react";
import ChatPopup from "./ChatPopup";
import { retrieveTokens } from "../../apollo/tokens";

const ChatContainer = ({ chatContainerSelector }) => {
  const { accessToken, refreshToken } = retrieveTokens();

  if (!Boolean(accessToken && refreshToken)) {
    return null;
  }

  return (
    <ChatPopup chatContainerSelector={chatContainerSelector} />
  );
};

export default ChatContainer;
