import { getMailLink } from "utils/basic";

const email = "support@rvrentals.com";
const mobileAppForIOSLink = "https://apps.apple.com/us/app/good-sam-travel/id1608428833";
const mobileAppForAndroidLink = "https://play.google.com/store/apps/details?id=com.goodsam.travel.android";

export const support = {
  email,
  emailLink: getMailLink(email),
  mobileAppForIOSLink,
  mobileAppForAndroidLink
};
