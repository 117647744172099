function scrollIntoView(cls, id, options = { behavior: "smooth", block: "center" }) {
  let el = null;

  if (id) {
    el = document.getElementById(id);
  } else if (cls) {
    el = document.querySelector(cls);
  }

  if (el) {

    el.scrollIntoView(options);
  }
}

export default scrollIntoView;
