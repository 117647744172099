import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useLocationQueryParams } from "../../hooks/useLocationQueryParam";
import { SearchContext } from "./SearchContext";
import { MQ_BP, useMatchMedia } from "../MatchMedia";
import { filterList, FILTERS, getSetFiltersIds, isFilterSet } from "./filters";
import { SORT, sortList } from "./sort";
import { searchQuery } from "./queries/searchQuery";
import { useFavorites } from "./hooks";
import { useGoogleMaps } from "../GoogleMapsProvider";
import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";
import { userQuery } from "utils/segment-analytics/queries/userQuery";
import { getFilterAnalyticsData } from "utils/segment-analytics/utils/getFilterAnalyticsData";
import { getListItemLatLng, getMapLatLng, getYNMMMParameters } from "./utils";
function SearchProvider(props) {
  const { location } = props;
  const { isLoaded: isMapLoaded } = useGoogleMaps();
  const [mapOpen, setMapOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const isMobileFilters = useMatchMedia({ maxWidth: MQ_BP.small });
  const [list, setList] = useState([]);
  const [mapBounds, setMapBounds] = useState({});
  const [showFullList, setShowFullList] = useState(false);
  const [allowInitialLocation, setAllowInitialLocation] = useState(true);
  const [selectedClasses, setSelectedClasses] = useState({});
  const [activeSort, setActiveSort] = useState(SORT.default);
  const isAnalyticsSent = useRef(false);
  // For sorting optimization:
  // based on the fact that the user filters more often than sorts, so we store the already filtered full list
  const sortedInitialList = useRef();

  /**
   * Active filters, that were applied
   */
  const [activeFilters, setActiveFilters] = useState({});

  /**
   * Temporary filters for local states
   */
  const {
    control: filtersControl,
    setValue,
    watch: filtersWatch
  } = useForm({
    defaultValues: FILTERS.defaults,
    // Prevent form clearing when inputs are removed from DOM of closed modal:
    shouldUnregister: false
  });

  const queryParams = useLocationQueryParams();

  const { data, loading, error } = useQuery(searchQuery, {
    variables: {
      location: queryParams.location || location,
      departure_date: queryParams.departure || null,
      return_date: queryParams.return || null,
      sleeps: queryParams.sleeps ? parseInt(queryParams.sleeps) : null,
      without_name: true
    },
    onCompleted: (data) => {
      const redirect = data?.search?.redirect;
      if (redirect) {
        window.location.href = redirect;
      }
    }
  });
  const { loadin: userDataLoading, data: userData } = useQuery(userQuery);

  const searchData = data?.search;
  const searchList = useMemo(() => getYNMMMParameters(searchData?.results), [searchData?.results]);
  const rvsInLocation = searchData?.rvs_in_location;
  const userId = userData?.user?.id;
  const { isInFavorites, onChangeFavorite } = useFavorites(searchList, userData?.user);

  const updateClassField = useCallback((classes, filter = {}) => (
    classes ? { ...filter, class: classes } : filter
  ), []);

  const getMappingClass = useCallback((filters) => {
    if (filters?.class && searchData?.classes) {
      const classNames = Object.keys(filters.class);
      return searchData
        .classes
        .map(cl => ({
          name: cl.name,
          mapping: cl.mapping
        }))
        .reduce((acc, cl) => classNames.includes(cl.name) ? [...acc, ...cl.mapping] : acc, [])
        .reduce((acc, cl) => ({ ...acc, [cl]: true }), {});
    }
  }, [searchData]);

  const applyFilters = useCallback(() => {
    const mappingClass = getMappingClass(filtersControl.getValues());
    const newActiveFilters = updateClassField(mappingClass, filtersControl.getValues());
    setActiveFilters(newActiveFilters);
    setSelectedClasses(filtersControl.getValues().class);
  }, [filtersControl, getMappingClass, updateClassField]);

  const hasSetFilters = useCallback((filters) => {
    return getSetFiltersIds(filters).length > 0;
  }, []);

  const getMapCenter = useCallback(() => {
    return {
      lat: searchData?.map?.center_lat,
      lng: searchData?.map?.center_lng
    };
  }, [searchData?.map]);

  useEffect(() => {
    const sorted = sortList(
      searchList,
      activeSort,
      activeFilters,
      getMapCenter()
    );
    sortedInitialList.current = sorted;
    setList(sorted);

    // Needs to be triggered by searchData results change only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchList]);

  useEffect(() => {
    if (isAnalyticsSent?.current) {
    // Using the saved sorted list without having to re-sort it
      let filtered = filterList(sortedInitialList.current, activeFilters);
      if (SORT.isEqual(activeSort, SORT.ids.distance)) {
        // Re-sort list when active sort is distance and address filter is set
        setList(sortList(filtered, activeSort, activeFilters, getMapCenter()));
        pushSegmentAnalyticsTrack(
          "Product List Filtered",
          getFilterAnalyticsData(
            sortList(filtered, activeSort, activeFilters, getMapCenter()),
            queryParams,
            activeSort,
            activeFilters,
            searchData?.amenities
          )
        );
      } else {
        setList(filtered);
        if (Object.keys(activeFilters).length !== 0) {
          pushSegmentAnalyticsTrack(
            "Product List Filtered",
            getFilterAnalyticsData(
              filtered,
              queryParams,
              activeSort,
              activeFilters,
              searchData?.amenities
            )
          );
        }
      }
    }

    // Needs to be triggered by activeFilters change only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  useEffect(() => {
    if (list.length && !isAnalyticsSent?.current) {
      isAnalyticsSent.current = true;
      pushSegmentAnalyticsTrack(
        "Product List Filtered",
        getFilterAnalyticsData(
          list,
          queryParams,
          activeSort,
          activeFilters,
          searchData?.amenities
        )
      );
    }
  }, [activeFilters, activeSort, list, queryParams, searchData?.amenities]);

  useEffect(() => {
    if (!isMobileFilters) {
      setMobileFiltersOpen(false);
    }
  }, [isMobileFilters]);

  useEffect(() => {
    if (isMapLoaded && searchData?.map) {
      const m = searchData?.map;
      // Set initial bounds

      setMapBounds(new window.google.maps.LatLngBounds(
        getMapLatLng(m.viewport_southwest_lat, m.viewport_southwest_lng),
        getMapLatLng(m.viewport_northeast_lat, m.viewport_northeast_lng)
      ));
    }
  }, [searchData?.map, isMapLoaded]);

  const boundedList = useMemo(() => {
    if (rvsInLocation && !mapOpen) {
      return list.filter(item => rvsInLocation.includes(item.id));
    } else {
      if (showFullList) {
        return list.filter(item => mapBounds?.contains?.(getListItemLatLng(item)));
      } else {
        return list.filter(item => rvsInLocation.includes(item.id));
      }
    }
  }, [list, mapBounds, mapOpen, rvsInLocation, showFullList]);

  const hasActiveFilters = hasSetFilters(activeFilters);

  const value = useMemo(() => ({
    userId,
    list,
    error,
    boundedList,
    showFullList,
    setShowFullList,
    allowInitialLocation,
    setAllowInitialLocation,
    rvsInLocation,
    searchData,
    loading,
    mapOpen,
    setMapOpen,
    isMobileFilters,
    mobileFiltersOpen,
    setMobileFiltersOpen,
    filtersControl,
    filtersWatch,
    activeFilters,
    selectedClasses,
    applyFilters,
    activeSort,
    queryParams,
    hasSetFilters,
    isFilterDisabled: () => {
      return !hasActiveFilters;
      // && boundedList.length <= 1;
    },
    setActiveSort: (value) => {
      const mapCenter = getMapCenter();
      // Sort current list
      const sortedList = sortList(list, value, activeFilters, mapCenter);
      setList(sortedList);
      // Update current sort value
      setActiveSort(value);
      // Update sorted initial list
      if (!hasSetFilters(activeFilters)) {
        // No active filter means that we've just sorted the full list - just save it
        sortedInitialList.current = sortedList;
      } else {
        sortedInitialList.current = sortList(
          searchList,
          value,
          activeFilters,
          mapCenter
        );
      }
      pushSegmentAnalyticsTrack(
        "Product List Filtered",
        getFilterAnalyticsData(
          sortedInitialList.current,
          queryParams,
          value,
          activeFilters,
          searchData?.amenities
        )
      );
    },
    hasActiveFilters,
    resetAllFilters: () => {
      // Using setValue instead of reset, because reset doesn't update values at Controllers
      Object.keys(FILTERS.defaults).forEach(name => {
        setValue(name, FILTERS.defaults[name]);
      });
      // Apply filters when resetting all of them
      applyFilters();
    },
    resetFilter: (name) => {
      const rentalTypeResetData = {
        standard: false,
        deliveryOnly: false
      };

      if (name === 'rentalType') {
        setValue("rentalType", rentalTypeResetData);
      } else {
        setValue(name, FILTERS.defaults[name]);
      }
    },
    isInFavorites,
    onChangeFavorite,
    mapBounds,
    setMapBounds
  }), [
    userId,
    list,
    error,
    queryParams,
    boundedList,
    showFullList,
    setShowFullList,
    allowInitialLocation,
    setAllowInitialLocation,
    rvsInLocation,
    searchData,
    loading,
    mapOpen,
    isMobileFilters,
    mobileFiltersOpen,
    filtersControl,
    filtersWatch,
    activeFilters,
    selectedClasses,
    applyFilters,
    setValue,
    activeSort,
    setActiveSort,
    hasSetFilters,
    hasActiveFilters,
    getMapCenter,
    isInFavorites,
    onChangeFavorite,
    searchList,
    mapBounds,
    setMapBounds
  ]);

  if (searchData?.redirect) {
    return null;
  }

  return (
    <SearchContext.Provider value={value} {...props} />
  );
}

SearchProvider.propTypes = {
  location: PropTypes.string
};

export default SearchProvider;
