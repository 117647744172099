import Typography from "../../../../../components/Typography";
import React from "react";
import { dateFormat } from "utils/dayPickerDataFormatting";
import { closeIconCircle } from "components/Icon";
import classes from "./HeaderInterval.module.css";

function getDisplayRange(from, to) {
  const displayFrom = dateFormat(from);
  const displayTo = dateFormat(to);

  return displayFrom !== displayTo
    ? `${displayFrom} - ${displayTo}`
    : displayFrom;
}

export const HeaderInterval = ({ from, to, resetPeriod }) => {
  if (from && to) {
    return (
      <Typography variant="subtitle" size="m" className={classes.interval}>
        <span className={classes.text}>
          {getDisplayRange(from, to)}
        </span>
        <span
          className={classes.reset}
          onClick={resetPeriod}
        >{closeIconCircle}</span>
      </Typography>
    );
  }

  return (
    <Typography variant="subtitle" size="l" className={classes.interval}>
      &nbsp;
    </Typography>
  );
};
