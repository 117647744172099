import { getSortOptionText } from "components/Search/sort";

function getRentalTypes(rentalTypes = {}) {
  if (!rentalTypes?.standard && !rentalTypes?.deliveryOnly) {
    return {
      isRentalTypes: false,
      data: {}
    };
  }

  if (rentalTypes?.standard && !rentalTypes?.deliveryOnly) {
    return {
      isRentalTypes: true,
      data: {
        0: "Standard rental"
      }
    };
  }

  if (!rentalTypes?.standard && rentalTypes?.deliveryOnly) {
    return {
      isRentalTypes: true,
      data: {
        0: "Delivery only rental"
      }
    };
  }

  if (rentalTypes?.standard && rentalTypes?.deliveryOnly) {
    return {
      isRentalTypes: true,
      data: {
        0: "Standard rental",
        1: "Delivery only rental"
      }
    };
  }
}

function getClasses(cls) {
  if (Object.keys(cls).length === 0) return [];
  return Object.keys(cls);
}

function getAmenities(activeAmenities, allAmenities) {
  if (Object.keys(activeAmenities).length === 0) return [];
  const allAmenitiesItems = allAmenities.reduce(
    ( prevItems, currentItems ) => [...prevItems, ...currentItems.items],
    []
  );
  const activeAmenitiesItems = Object.keys(activeAmenities).map(item => Number(item));
  const result = allAmenitiesItems
    .filter(item => activeAmenitiesItems.includes(item.id))
    .map(item => item.name);

  return result;
}

export function getFilterAnalyticsData(
  boundedList = [],
  queryParams = {},
  activeSort = '',
  activeFilters = {},
  allAmenities = []
) {
  const reducedBoundedList = [...boundedList.slice(0, 15)];
  let activeFiltersData = [];
  const activeFiltersDataDefault = [
    {
      type: 'class',
      value: []
    },
    {
      type: 'amenities',
      value: []
    }
  ];

  if (Object.keys(activeFilters).length !== 0) {

    const classesData =  getClasses(activeFilters?.class);
    const amenitiesData = getAmenities(activeFilters.amenities, allAmenities);
    const rentalTypesData =  getRentalTypes(activeFilters?.rentalType);

    activeFiltersData.push({
      type: 'class',
      value: classesData
    });
    if (amenitiesData.length) {
      activeFiltersData.push({
        type: 'amenities',
        value: amenitiesData
      });
    } else {
      activeFiltersData.push({
        type: 'amenities',
        value: []
      });
    }
    if (activeFilters?.length?.start !== 'empty') {
      activeFiltersData.push({
        type: 'length_min',
        value: activeFilters?.length?.start
      });
    }
    if (activeFilters?.length?.end !== 'empty') {
      activeFiltersData.push({
        type: 'length_max',
        value: activeFilters?.length?.end
      });
    }
    if (activeFilters?.price?.start !== 'empty') {
      activeFiltersData.push({
        type: 'price_min',
        value: activeFilters?.price?.start
      });
    }
    if (activeFilters?.price?.end !== 'empty') {
      activeFiltersData.push({
        type: 'price_max',
        value: activeFilters?.price?.end
      });
    }

    if (rentalTypesData.isRentalTypes) {
      activeFiltersData.push({
        type: 'rental_types',
        value: { ...rentalTypesData?.data }
      });
    }

  } else {
    activeFiltersData = [...activeFiltersDataDefault];
  }

  const filters = [];
  if (queryParams.sleeps) {
    filters.push({
      type: 'sleeps',
      value: queryParams.sleeps
    });
  }
  if (queryParams.location) {
    filters.push({
      type: 'location',
      value: queryParams.location
    });
  }
  if (queryParams.departure) {
    filters.push({
      type: 'departure_date',
      value: queryParams.departure
    });
  }
  if (queryParams.return) {
    filters.push({
      type: 'return_date',
      value: queryParams.return
    });
  }

  return {
    category: 'RV Rentals',
    products: reducedBoundedList.map((product, productIndex) => ({
      location: String(product?.lc?.replace('Delivered from ', '')),
      asset_make: String(product.mk),
      asset_model: String(product.md),
      asset_year: String(product.yr),
      asset_length: Number(product.le) * 12,
      asset_sleepnumber: Number(product.sl),
      asset_class: String(product.cl),
      asset_id: String(product.id),
      asset_slideouts: Number(product.so),
      asset_brand: product?.mr,
      list_position: productIndex
    })),
    filters: [
      ...filters,
      ...activeFiltersData
    ],
    sorts: activeSort.length !== 0 ? getSortOptionText(activeSort): null,
    cwh_business: 'RV Rentals'
  };
}
