import React, { useCallback, useState, useEffect } from "react";
import { FieldController } from "components/form/FieldController/FieldController";
import { RentalType } from "components/RentalTypes/RentalType";
import MobileFilterBlock from "./MobileFilterBlock";
import { FILTERS, INITIAL_RENTAL_TYPE_DATA } from "../../filters";
import { setSelectedRentalType, updateRentalType } from "components/Search/utils";
import classes from "components/Search/components/MobileFilters/MobileFilterRentalType.module.css";

function getLabelFromValue(value) {
  let str = "";
  if (value?.standard && value?.deliveryOnly) {
    str = "Standard, Delivery only";
  } else if (value?.standard) {
    str = "Standard";
  } else if (value?.deliveryOnly) {
    str = "Delivery only";
  }
  return str;
}

const MobileFilterRentalType = ({ filtersControl }) => {
  const [rentalTypeList, setRentalTypeList] = useState(INITIAL_RENTAL_TYPE_DATA);
  const { standard, deliveryOnly } = filtersControl.getValues().rentalType;

  useEffect(() => {
    updateRentalType(INITIAL_RENTAL_TYPE_DATA, standard, deliveryOnly, setRentalTypeList);
  }, [standard, deliveryOnly]);

  const resetFiltersRentalTypeList = useCallback(() => {
    setRentalTypeList(INITIAL_RENTAL_TYPE_DATA);
  }, [setRentalTypeList]);

  const setSelected = useCallback((name, isSelected, onChange, type) => {
    setSelectedRentalType(name, isSelected, rentalTypeList, setRentalTypeList);

    const rentalTypeData = filtersControl.getValues().rentalType;
    onChange({
      ...rentalTypeData,
      [type]: isSelected
    });
  }, [filtersControl, rentalTypeList, setRentalTypeList]);

  return (
    <div className={`${classes.container}`}>
      <MobileFilterBlock
        id={FILTERS.ids.rentalType}
        isShowClear={standard || deliveryOnly}
        onReset={resetFiltersRentalTypeList}
        getLabelFromValue={getLabelFromValue}
        renderForm={() => (
          <>
            <FieldController
              name="rentalType"
              control={filtersControl}
              render={(renderProps) => (
                <>
                  <div className="mt-16">
                    <RentalType
                      {...rentalTypeList[0]}
                      onSelected={(name, isSelected) => {
                        setSelected(name, isSelected, renderProps.onChange, 'standard');
                      }}
                    />
                  </div>

                  <div className="mt-16">
                    <RentalType
                      {...rentalTypeList[1]}
                      onSelected={(name, isSelected) => {
                        setSelected(name, isSelected, renderProps.onChange, 'deliveryOnly');
                      }}
                    />
                  </div>
                </>
              )}
            />
          </>
        )}
      />
    </div>
  );
};

MobileFilterRentalType.propTypes = {};

export default MobileFilterRentalType;
