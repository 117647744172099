import React from "react";
import PropTypes from "prop-types";
import Typography from "../Typography";
import classes from "./NoItemsPageNotice.module.css";

const NoItemsPageNotice = (props) => {
  const { icon, title, description, button } = props;

  return (
    <div className="df fcc fdc t-16 b-16 t-m-20 b-m-20">
      <div className="t-16 b-16 t-m-20 b-m-20">
        <img className={classes.icon} src={icon} alt="Icon" />
      </div>
      <Typography className="tac" variant="subtitle" size="m" weight="bold">
        {title}
      </Typography>
      <Typography className="tac" variant="body" size="l">
        {description}
      </Typography>
      <div className="t-12 b-16 t-m-20 b-m-20">
        {button}
      </div>
    </div>
  );
};

NoItemsPageNotice.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.node
};

export default NoItemsPageNotice;
