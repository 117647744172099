import React, { useState, useEffect } from "react";
import cn from 'classnames';
import { useQuery } from "@apollo/client";
import { setCookie } from "utils/cookie";
import { extractText } from "utils/extractText";
import { extractHtml } from "utils/exctractHtml";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import ReferUsersModal from "components/ReferUsersModal/ReferUsersModal";
import ReferralSuccessNotification from "components/ReferralSuccessNotification/ReferralSuccessNotification";

import { contentQuery } from "../../queries";
import Button from "../Button";

import classes from './ReferralNotificationPopover.module.css';

const ReferralNotificationPopover = ({
  title,
  text,
  acceptButton,
  declineButton,
  isFooterVisible,
  referralCookieTemplate,
  onClose = () => { /* TODO document why this arrow function is empty */ },
  openReferralModal = () => {}
}) => {
  const [showReferralNotificationPopover, setShowReferralNotificationPopover] = useState(true);
  const [content, setContent] = useState({
    listing_notification_title: "",
    listing_notification_text: "",
    listing_notification_accept_button: "",
    listing_notification_decline_button: "",
    referrals_list_congratulations_title: "",
    referrals_list_congratulations_text: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { loading, data } = useQuery(contentQuery, {
    variables: {
      section: "referral_content"
    },
    fetchPolicy: "no-cache"
  });

  const closeReferralNotification = () => {
    onClose();
    setCookie(referralCookieTemplate, true, 7);
    setShowReferralNotificationPopover(false);
  };

  const openReferModal = () => {
    setCookie(referralCookieTemplate, true, 7);
    setShowModal(true);
    setShowReferralNotificationPopover(false);
    openReferralModal();
  };

  const closeReferModal = () => {
    setShowModal(false);
  };

  const openSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (!loading) {
      setContent({
        listing_notification_title: extractText(data.content.text, title),
        listing_notification_text: extractHtml(data.content.text, text),
        listing_notification_accept_button: extractText(data.content.text, acceptButton),
        listing_notification_decline_button: extractText(data.content.text, declineButton),
        referrals_list_congratulations_title: extractText(data.content.text, "referrals_list_congratulations_title"),
        referrals_list_congratulations_text: extractText(data.content.text, "referrals_list_congratulations_text")
      });
    }
  }, [loading, data, title, text, acceptButton, declineButton]);

  if (loading) return null;

  return (
    <>
      <NotificationPopover
        show={showReferralNotificationPopover}
        status="success"
        title={content.listing_notification_title}
        text={content.listing_notification_text}
        bottomIndent={{
          hasIndent: isFooterVisible,
          size: 'large'
        }}
        onClose={closeReferralNotification}
      >
        <div className={cn("b-8 t-8", classes.referralNotificationActions)}>
          <div className={classes.referralNotificationAction}>
            <Button
              label={content.listing_notification_accept_button}
              size="medium"
              onClick={openReferModal}
            />
          </div>
          <div className={classes.referralNotificationAction}>
            <Button
              label={content.listing_notification_decline_button}
              secondary
              className={classes.referralNotificationAction}
              size="medium"
              onClick={closeReferralNotification}
            />
          </div>
        </div>
      </NotificationPopover>

      <ReferUsersModal
        show={showModal}
        onClose={closeReferModal}
        onSuccess={openSuccessModal}
      />

      <ReferralSuccessNotification
        show={showSuccessModal}
        content={content}
        onClose={closeSuccessModal}
      />
    </>
  );
};

export default ReferralNotificationPopover;
