import { createContext, useContext } from "react";

export const RentalDetailsContext = createContext(null);

export function useRentalDetailsContext() {
  const context = useContext(RentalDetailsContext);

  if (!context) {
    throw new Error("useRentalDetailsContext must be used within a RentalDetailsProvider");
  }

  return context;
}
