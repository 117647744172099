import { pipe, toPairs, map, join, pick, memoizeWith } from "ramda";

export function filterInitialData(props) {
  const propsFilter = pick(props);
  const keyGenerator = pipe(
    propsFilter,
    toPairs,
    map((tuple) => `${tuple[0]}:::${JSON.stringify(tuple[1])}`),
    join("###")
  );

  return memoizeWith(keyGenerator, propsFilter);
}
