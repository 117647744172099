import React from "react";
import { LocationRentalTabs } from "components/Listing/steps/LocationDelivery/components/LocationRentalTabs/LocationRentalTabs";

export const LocationDeliveryFormContent = ({
  register,
  control,
  errors,
  clearErrors,
  watch,
  initialData
}) => {

  return  (
    <>
      <LocationRentalTabs
        register={register}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        watch={watch}
        initialData={initialData}
      />
    </>
  );
};
