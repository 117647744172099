import React from "react";
import Typography from "../../../../../components/Typography";
import style from "./style.module.css";

const RentalMileageItemOverage = ({
  title,
  mileageOverageValue,
  mileageFeeValue,
  generatorOverageValue,
  generatorFeeValue,
  isShowMileage,
  isShowGenerator
}) => {

  return (
    <div className={style.rentalMileageItem}>
      <div className={style.rentalMileageItemInner}>
        <div className={style.rentalMileageItemHeader}>
          <Typography
            variant="body"
            size="l"
            className={style.rentalMileageItemHeaderTitle}
          >
            {title}
          </Typography>
        </div>

        <div className={style.content}>
          {isShowMileage && (
            <div className="row">
              <div className="col-5 col-m-4">
                <Typography
                  variant="body"
                  size="l"
                  className={style.rentalMileageItemHeaderSubtitle}
                >
                Mileage
                </Typography>
              </div>
              <div className="col-7 col-m-8">
                <Typography
                  variant="body"
                  size="l"
                  className={style.rentalMileageItemOverageTitle}
                >
                  <strong>{mileageOverageValue}</strong> miles
                  {mileageFeeValue !== 0 && mileageFeeValue !== null && <span className="ml-4">(<strong>${mileageFeeValue}</strong>)</span>}
                </Typography>
              </div>
            </div>
          )}

          {isShowGenerator && (
            <div className="row">
              <div className="col-5 col-m-5 col-l-4">
                <Typography
                  variant="body"
                  size="l"
                  className={style.rentalMileageItemHeaderSubtitle}
                >
                Generator
                </Typography>
              </div>
              <div className="col-7 col-m-7 col-l-8">
                <Typography
                  variant="body"
                  size="l"
                  className={style.rentalMileageItemOverageTitle}
                >
                  <strong>{generatorOverageValue}</strong> hours
                  {generatorFeeValue !== 0 && generatorFeeValue !== null && <span className="ml-4">(<strong>${generatorFeeValue}</strong>)</span>}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RentalMileageItemOverage;
