import React from "react";
import { AdditionalInsuranceSingleItem, AdditionalInsuranceMultipleItem } from "components/AdditionalInsuranceList";
import cn from 'classnames';
import classes from "./AdditionalInsuranceList.module.css";

const AdditionalInsuranceList = (props) => {
  const {
    insurances = [],
    selectedPackages,
    setSelectedPackages,
    mode,
    isLastBorder = true,
    formElementsData
  } = props;

  return (
    <div>
      {insurances.map((insurance) => {
        const {
          id: insuranceId,
          name,
          required,
          multi_select,
          packages,
          description
        } = insurance;

        if (packages?.length > 1) {
          return (
            <section className={cn(classes.section, isLastBorder && classes.isLastBorder)} key={insuranceId}>
              <AdditionalInsuranceMultipleItem
                insuranceId={insuranceId}
                name={name}
                insuranceDescription={description}
                required={required}
                multi_select={multi_select}
                itemPackages={packages}
                selectedPackages={selectedPackages}
                setSelectedPackages={setSelectedPackages}
                mode={mode}
                formElementsData={formElementsData}
              />
            </section>
          );
        } else {
          return (
            <section className={classes.section} key={insuranceId}>
              <AdditionalInsuranceSingleItem
                insuranceId={insuranceId}
                planName={name}
                insuranceDescription={description}
                description={packages[0]?.description}
                required={required}
                itemPackages={[packages[0]]}
                selectedPackages={selectedPackages}
                setSelectedPackages={setSelectedPackages}
                mode={mode}
              />
            </section>
          );
        }
      })}
    </div>
  );
};

AdditionalInsuranceList.propTypes = {
};

export default AdditionalInsuranceList;
