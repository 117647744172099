import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FILTERS } from "components/Widget/filters";
import { getObjectPositiveValuesCount } from "utils/basic";
import FilterButton from "./FilterButton";
import classes from "./FilterCalendar.module.css";
import classesDarkTheme from "./FilterCalendarDarkTheme.module.css";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { SmallDayPicker } from "components/SmallDayPicker/SmallDayPicker";
import moment from "moment";

const initialMonth = new Date();
initialMonth.setDate(initialMonth.getDate());

const FilterCalendar = () => {
  const [closePopup, setClousePopup] = useState({
    close: false
  });
  const { filtersWatch, handleSetActiveFilters, theme, loadSearch, ownerDirectory } = useWidgetContext();
  const { date: filterDate } = filtersWatch();
  const { fullDeparture } = filterDate;
  const [currentMounth, setCurrentMounth] = useState(fullDeparture || initialMonth);

  useEffect(() => {
    if (fullDeparture) {
      setCurrentMounth(fullDeparture);
    } else {
      setCurrentMounth(initialMonth);
    }
  }, [fullDeparture] );

  const hanldeAfterClearFilter = useCallback(() => {
    loadSearch({
      variables: {
        directory: ownerDirectory
      }
    });
  }, [loadSearch, ownerDirectory]);

  return (
    <FilterButton
      popperClassName={theme === 'dark' ? classesDarkTheme.popper : classes.popper}
      className={classes.filterButtonContainer}
      classNamefilterButton={classes.filterButton}
      id={FILTERS.ids.date}
      getSelectedCount={getObjectPositiveValuesCount}
      hanldeAfterClearFilter={hanldeAfterClearFilter}
      filterDate={filterDate}
      isActionBar={false}
      setClousePopup={setClousePopup}
      closePopup={closePopup}
      renderForm={({ value, onChange }) => {
        return (
          <div>
            <SmallDayPicker
              numberOfMonths={2}
              initialMonth={currentMounth}
              value={{
                from: value.fullDeparture,
                to: value.fullReturn
              }}
              onChangeDate={(state) => {
                const departure_date = moment(state?.from).format("DD-MM-YYYY");
                const return_date = moment(state?.to).format("DD-MM-YYYY");

                onChange({
                  departure: departure_date,
                  return: return_date,
                  fullDeparture: state?.from,
                  fullReturn: state?.to
                });

                handleSetActiveFilters();

                loadSearch({
                  variables: {
                    directory: ownerDirectory,
                    departure_date,
                    return_date
                  }
                });
                setClousePopup({
                  close: true
                });
              }}
              externalClassNames={theme === 'dark' ? classesDarkTheme : classes}
            />
          </div>
        );
      }}
    />
  );
};

FilterCalendar.propTypes = {
};

export default FilterCalendar;
