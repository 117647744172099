import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { AdditionalDriversList } from "components/AdditionalDrivers";
import { AdditionalDriversEmptyState } from "components/AdditionalDrivers";
import { ModalAdditionalDrivers } from "components/ModalAdditionalDrivers/ModalAdditionalDrivers";
import { additionalDriverRemoveMutation } from "components/AdditionalDrivers/mutations/additionalDriverRemoveMutation";
import { getErrorsAndSuccess } from "utils/extractErrors";
import { useMutation } from "@apollo/client";
import Typography from "components/Typography";
import Button from "components/Button";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import BasicCollapse from "components/RcCollapse/BasicCollapse";
import { ModalExit } from "components/ModalExit/ModalExit";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { SpinnerBack } from "components/Spinner/Spinner";
import cn from 'classnames';
import classes from "./AdditionalDrivers.module.css";
import { ModalDelete } from "components/ModalDelete/ModalDelete";

const additionalDriverRemoveMutationEAS = getErrorsAndSuccess("additional_driver_remove");

const AdditionalDrivers = (props) => {
  const {
    drivers,
    allowedRemoveDrivers,
    limitAddDrivers,
    isOwner,
    rentalId,
    handleOnDriversSuccessAdded = () => {},
    handleOnDriversErrorAdded = () => {},
    handleOnDriversSuccessRemoved = () => {}
  } = props;

  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const [showAddDriversModal, setShowAddDriversModal] = useState(false);
  const [showModalExit, setShowModalExit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [deletedDriver, setDeletedDriver] = useState(null);
  const [showAddDriversSuccessNotification, setShowAddDriversSuccessNotification] = useState(false);
  const [showRemoveDriversSuccessNotification, setShowRemoveDriversSuccessNotification] = useState(false);
  const [error, setError] = useState("");

  const [additionalDriverRemoveMutationAction,
    {
      loading: additionalDriverRemoveMutationLoading
    }
  ] = useMutation(
    additionalDriverRemoveMutation
  );

  const onAddDrivers = useCallback((e) => {
    e.stopPropagation();
    setShowAddDriversModal(true);
  }, []);

  const onConfirmedDeleteDriver = useCallback(async() => {
    setShowModalDelete(false);
    const response = await additionalDriverRemoveMutationAction({
      variables: {
        rental: deletedDriver?.rental,
        email: deletedDriver?.email
      }
    });

    if (additionalDriverRemoveMutationEAS.isSuccess(response)) {
      handleOnDriversSuccessRemoved();
      setDeletedDriver(null);
      setShowRemoveDriversSuccessNotification(true);
    } else if(additionalDriverRemoveMutationEAS.isErrors(response)) {
      setError(additionalDriverRemoveMutationEAS.errorMessage(response));
    }
  }, [additionalDriverRemoveMutationAction, deletedDriver?.email, deletedDriver?.rental, handleOnDriversSuccessRemoved]);

  const onDeleteDriver = useCallback((rental, email) => {
    setDeletedDriver({ rental, email });
    setShowModalDelete(true);
  }, []);

  const onDriversErrorAdded = useCallback((data = {}) => {
    setShowAddDriversModal(false);
    handleOnDriversErrorAdded();
    if (data.showError) {
      setError(data.message);
    }
  }, [handleOnDriversErrorAdded]);

  const onDriversSuccessAdded = useCallback(() => {
    setShowAddDriversModal(false);
    handleOnDriversSuccessAdded();
    setShowAddDriversSuccessNotification(true);
  }, [handleOnDriversSuccessAdded]);

  const onCloseAddDriversModal = useCallback((driver) => {
    setShowAddDriversModal(false);
    setShowModalExit(false);
  }, []);

  const onShowModalExit = useCallback(() => {
    setShowModalExit(true);
  }, []);

  const onCloseModalExit = useCallback(() => {
    setShowModalExit(false);
  }, []);

  const onShowModalDelete = useCallback(() => {
    setShowModalDelete(true);
  }, []);

  const onCloseModalDelete = useCallback(() => {
    setShowModalDelete(false);
  }, []);

  const isDrivers = drivers?.length > 0;

  return (
    <div>
      {isSmallDevice && (
        <div>
          <BasicCollapse
            defaultOpen={false}
            headerClassName="r-16"
            renderHeader={(open) => (
              <header className={classes.header}>
                <Typography
                  variant="subtitle"
                  size="l"
                  className={classes.titleMobile}
                >
                  Additional drivers
                </Typography>

                {limitAddDrivers > 0 && ((open && isDrivers) || (!open)) && (
                  <Button
                    ghost
                    className={cn(classes.addDriverButton, 'mr-8 mt-4 underline')}
                    onClick={onAddDrivers}
                  >
                    Add driver
                  </Button>
                )}
              </header>
            )}
          >
            <div className={classes.content}>
              {!isOwner && !isDrivers && limitAddDrivers > 0 && (
                <AdditionalDriversEmptyState onAddDrivers={onAddDrivers} />
              )}

              {isDrivers && (
                <div className="mt-12">
                  <AdditionalDriversList
                    drivers={drivers}
                    onDelete={onDeleteDriver}
                    isOwner={isOwner}
                    allowedRemoveDrivers={allowedRemoveDrivers}
                  />
                </div>
              )}
            </div>
          </BasicCollapse>
        </div>
      )}

      {!isSmallDevice && (
        <div>
          <header className={classes.header}>
            <Typography
              variant="subtitle"
              size="l"
            >
              Additional drivers
            </Typography>

            {isDrivers && limitAddDrivers > 0 && (
              <div className="mt-m-8 mt-xl-12">
                <Button
                  secondary
                  onClick={onAddDrivers}
                >
                  Add driver
                </Button>
              </div>
            )}
          </header>

          <div className={classes.content}>
            {!isOwner && !isDrivers && limitAddDrivers > 0 && (
              <AdditionalDriversEmptyState onAddDrivers={onAddDrivers} />
            )}

            {isDrivers && (
              <div className="mt-m-40 mt-xl-32">
                <AdditionalDriversList
                  drivers={drivers}
                  onDelete={onDeleteDriver}
                  isOwner={isOwner}
                  allowedRemoveDrivers={allowedRemoveDrivers}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {showAddDriversModal && (
        <ModalAdditionalDrivers
          show={showAddDriversModal}
          onClose={onShowModalExit}
          onDriversSuccessAdded={onDriversSuccessAdded}
          onDriversErrorAdded={onDriversErrorAdded}
          rentalId={rentalId}
          limitAddDrivers={limitAddDrivers}
        />
      )}

      <ModalExit
        show={showModalExit}
        onClose={onCloseModalExit}
        onBack={onCloseModalExit}
        onExit={onCloseAddDriversModal}
      />

      <ModalDelete
        show={showModalDelete}
        onClose={onCloseModalDelete}
        onDelete={onConfirmedDeleteDriver}
      />

      {showAddDriversSuccessNotification && (
        <NotificationPopover
          show
          status="success"
          title="Success!"
          text={"You have successfully added additional drivers"}
          onClose={() => {
            setShowAddDriversSuccessNotification(false);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}

      {error?.length > 0 && (
        <NotificationPopover
          show
          status="error"
          text={error}
          onClose={() => {
            setError('');
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}

      {showRemoveDriversSuccessNotification && (
        <NotificationPopover
          show
          status="success"
          text={"You have successfully removed additional driver"}
          onClose={() => {
            setShowRemoveDriversSuccessNotification(false);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}

      {additionalDriverRemoveMutationLoading && <SpinnerBack />}
    </div>
  );
};

AdditionalDrivers.propTypes = {

};

export default AdditionalDrivers;
