import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import Button from "../../../Button";
import { ContentLoader } from "../../../ContentSection";
import Message from "../../../Message";
import ProTipsCollapse from "../../../ProTipsCollapse";
import Typography from "../../../Typography";
import { AmenitiesList } from "./AmenitiesList";
import { rvAmenitiesQuery } from "./queries/rvAmenitiesQuery";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

function amenitiesPredicate(resources) {
  return !!resources.rvAmenities;
}

export const AmenitiesContentLoader = ({
  initialData,
  onBack,
  onLater,
  onSave,
  loading,
  updateContextSaveHandler,
  listingPublished,
  requestErrors,
  prevStep,
  nextStep
}) => {
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const [state, setState] = useState(initialData);
  const [isDirty, setIsDirty] = useState(false);
  const [globalError] = useRequestErrors(requestErrors);
  useFooterVisible(isDirty);

  const handleChange = useCallback(
    (id, checked) => {
      setIsDirty(true);
      setState((innerState) => ({
        ...innerState,
        [id]: checked
      }));
    },
    [setState]
  );

  const handleSave = useCallback(() => {
    onSave(state);
  }, [onSave, state]);

  useEffect(() => {
    updateContextSaveHandler(() => onSave(state, { toNextStep: false }));
  }, [handleSave, onSave, state, updateContextSaveHandler]);

  return (
    <ContentLoader
      query={rvAmenitiesQuery}
      predicate={amenitiesPredicate}
      render={({ rvAmenities: list }) => {
        return (
          <div>
            <div className="row">
              <div className="col b-l-12 b-xl-0">
                <Text
                  id="step_title"
                  section="amenitiesSectionContent"
                  variant="headline"
                  size="s"
                />
                <ProTipsCollapse className="t-16 b-32" mediaParams="767px" />
              </div>
            </div>
            <AmenitiesList list={list} state={state} onChange={handleChange} />
            {globalError && (
              <div className="t-16 t-m-40">
                <Message type="error" text={globalError} />
              </div>
            )}
            {!listingPublished && (
              <>
                { nextStep && isSmallDevice && (
                  <div className="col-9 t-4 b-36">
                    <Button disabled={loading} onClick={onLater} secondary>
                      Skip
                    </Button>
                  </div>
                )}
                <div className="btn-group btn-group--center btn-group--m-start t-16 t-m-40 b-16 b-s-20 btn-group_xs-fixed">
                  {prevStep && (
                    <ButtonContent
                      id="previous_button"
                      section="amenitiesSectionContent"
                      onClick={onBack}
                      disabled={loading}
                      secondary
                    />
                  )}
                  {nextStep && !isSmallDevice && (
                    <Button disabled={loading} onClick={onLater} secondary>
                      Skip
                    </Button>
                  )}

                  {nextStep && (
                    <ButtonContent
                      id="save_and_next_button"
                      section="amenitiesSectionContent"
                      onClick={handleSave}
                      disabled={loading}
                    />
                  )}

                  {!nextStep && (
                    <Button onClick={handleSave} disabled={loading}>
                  Save
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        );
      }}
    />
  );
};

AmenitiesContentLoader.propTypes = {
  initialData: PropTypes.objectOf(PropTypes.bool),
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  updateContextSaveHandler: PropTypes.func.isRequired,
  listingPublished: PropTypes.bool.isRequired,
  requestErrors: PropTypes.arrayOf(
    PropTypes.exact({
      field: PropTypes.string,
      message: PropTypes.string
    })
  )
};

AmenitiesContentLoader.defaultProps = {
  initialData: {},
  loading: false
};
