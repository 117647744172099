import React from "react";
import PropTypes from "prop-types";
import CheckboxCard from "../../../cards/CheckboxCard";
import style from "./AmenitiyItem.module.css";

export const AmenityItem = (props) => {
  const { id, title, img, checked, onChange } = props;

  return (
    <CheckboxCard id={id} checked={checked} onChange={onChange}>
      <div className={style.imageWrap}>
        <img src={img} alt={title} className={style.image} />
      </div>
      <div className={style.title}>{title}</div>
    </CheckboxCard>
  );
};

AmenityItem.defaultProps = {
  checked: false
};

AmenityItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};
