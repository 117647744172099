import React, { useEffect, useMemo, useRef, useState } from "react";
import Typography from "components/Typography";
import Tag from "components/Tag";
import defaultRvImage from "assets/images/rv-default-placeholder.jpg";
import { moreIconVertical } from "components/Icon";
import Button from "components/Button";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Dropdown from "components/Dropdown/Dropdown";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import Action from "processes/Rentals/components/Action";
import { useStartChatAction } from "components/chat/api";
import { SpinnerBack } from "components/Spinner/Spinner";
import { getRentalOpponent, getTagTypeFromStatus, isUserOwner, checkQuotePendingStatus } from "processes/Rentals/utils";
import { possessive } from "utils/possessive";

function isOwnerBeforeAcceptPredefined(rental) {
  return isUserOwner(rental?.role) && checkQuotePendingStatus(rental) && rental?.is_predefined;
}

function isHideMenu(data) {
  const { role, status, is_predefined } = data;
  const { id: statusId } = status;

  const isOwner = isUserOwner(role);

  return isOwner && is_predefined && (statusId === 3 || statusId === 4 || statusId === 5);
}

const RentalDetailsHeader = (props) => {
  const { toggleChangeModal } = props;
  const { rental, executeAction } = useRentalDetailsContext();
  const rvProfileUrl = rental?.rv?.public_url;
  const dropdownButtonRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const isOwner = isUserOwner(rental.role);
  const opponent = getRentalOpponent(rental);

  useEffect(() => {
    if (!isMaxSmall) {
      setDropdownOpen(false);
    }
  }, [isMaxSmall]);

  const {
    startChat,
    startChatLoading
  } = useStartChatAction(opponent.id);

  const menu = useMemo(() => {
    if (isOwnerBeforeAcceptPredefined(rental)) {
      return [
        {
          label: rental.action_button.value,
          onClick: () => executeAction(rental.action_button.key)
        }
      ];
    }

    return [
      {
        label: isOwner ? "Message renter" : "Message owner",
        onClick: startChat
      },
      opponent.phone && {
        label: isOwner ? "Call renter" : "Call owner",
        onClick: () => window.open(`tel:${opponent.phone}`, "_self")
      },
      {
        label: `${possessive(isOwner ? "Renter" : "Owner")} public profile`,
        onClick: () => window.open(`/profile/${opponent.id}`, "_self")
      },
      rental.show_suggestion_button && {
        label: "Suggest a change",
        onClick: toggleChangeModal
      },
      rental.action_button && {
        label: rental.action_button.value,
        onClick: () => executeAction(rental.action_button.key)
      }
    ].filter(Boolean);
  }, [rental, isOwner, startChat, opponent.phone, opponent.id, toggleChangeModal, executeAction]);

  return (
    <header className="rental-details-header">
      {startChatLoading && (
        <SpinnerBack />
      )}
      <div className="rental-details-header__title-wrap t-8 t-m-0">
        <Typography
          variant="subtitle"
          size="l"
          component="h1"
          className="rental-details-header__title"
        >
          <div className="dn db-m">
            <Action item={rental} />
          </div>
          <span>{rental.rv.rv_name}</span>
        </Typography>

        <a href={rvProfileUrl} target="_blank" rel="noreferrer" className="rental-details-header__pic-container">
          <picture className="rental-details-header__pic">
            <source srcSet={rental.rv.title_image} type="image/jpeg" />
            <img src={defaultRvImage} alt="Placeholder" />
          </picture>
        </a>
      </div>
      <div className="rental-details-header__mobile-row">
        <Typography
          variant="subtitle"
          size="l"
          className="rental-details-header__title"
        >
          <Action item={rental} />
        </Typography>

        {!isHideMenu(rental) && (
          <Button
            ref={dropdownButtonRef}
            secondary
            size="medium"
            icon={moreIconVertical}
            className="rental-details-header__mobile-row-btn"
            onClick={() => setDropdownOpen(true)}
          />
        )}

        <Dropdown
          open={dropdownOpen}
          onClose={() => setDropdownOpen(false)}
          anchorEl={dropdownButtonRef.current}
          paperClassName="rental-details-header__dropdown"
        >
          <Dropdown.List>
            {menu.map(i => (
              <Dropdown.ListItem
                key={i.label}
                onClick={() => {
                  setDropdownOpen(false);
                  i.onClick();
                }}
              >
                {i.label}
              </Dropdown.ListItem>
            ))}
          </Dropdown.List>
        </Dropdown>
      </div>
      <p className="t-0 b-0 dn db-m">
        <Tag
          size="small"
          text={rental.status.name}
          type={getTagTypeFromStatus(rental.status)}
          className="rental-details-header__tag"
        />
      </p>
    </header>
  );
};

export default RentalDetailsHeader;
