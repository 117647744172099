import React, { useState, useEffect, useCallback } from "react";
import Typography from "components/Typography";

import classnames from "classnames";

import style from "./style.module.css";

const ReviewPhotosList = ({ photoArray, openModalGallery, setInitialSlide }) => {

  const handleOpenModalGallery = useCallback(
    (slide, isLast) => {
      openModalGallery(slide, isLast);
    },
    [openModalGallery]
  );
  function useMedia(queries, values, defaultValue) {
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));
    const getValue = () => {
      const index = mediaQueryLists.findIndex((mql) => mql.matches);
      return typeof values[index] !== "undefined"
        ? values[index]
        : defaultValue;
    };
    const [value, setValue] = useState(getValue);
    useEffect(() => {
      const handler = () => setValue(getValue);
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      return () =>
        mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    }, []);
    return value;
  }

  const queryArr = [
    "(min-width: 768px) and (max-width: 1023px)",
    "(min-width: 1024px)"
  ];

  const sliceArr = [4, 5];

  const sliceIndex = useMedia(queryArr, sliceArr, 3);

  return (
    <ul className={classnames(style.photosList, "ui-menu col-12 col-xl-auto fss l-xl-0 r-xl-0 row")}>
      <div className="dn db-m col-2 col-l-1 dn-xl r-m-16"></div>
      {photoArray.slice(0, sliceIndex).map((photo, index) => {
        return (
          <li
            key={index}
            className={classnames(
              style.photosList__item,
              index > sliceIndex && "dn",
              "col-3 col-m-2 col-l-1 col-xl-2 "
            )}
            onClick={() => {
              handleOpenModalGallery(index, false);
            }}
            index={index}
          >
            <div className={style.photosList__aspectRatioBox}>

              <img
                className={style.photosList__photo}
                src={photo?.srcset?.[0]?.url}
                alt=""
              />

            </div>
          </li>
        );
      })}
      {photoArray.length > sliceIndex && <li
        className={classnames(
          style.photosList__item_last,
          "fcc col-3 col-m-2 col-l-1 col-xl-2"
        )}
        onClick={() => {
          handleOpenModalGallery(sliceIndex, true);
        }}
        index={sliceIndex}
      >
        <div className={style.photosList__aspectRatioBox_last}>
          <img
            className={style.photosList__photoLast}
            src={photoArray?.[0]?.srcset?.[0]?.url}
            alt=""
          />
          <Typography
            variant="subtitle"
            size="l"
            weight="bold"
            className="fw fcc"
          >{`+${photoArray.length - sliceIndex}`}</Typography>
        </div>
      </li>}
    </ul>
  );
};

export default ReviewPhotosList;
