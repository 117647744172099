import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import style from "./style.module.css";

export const Footer = ({ className, children }) => {
  const classNames = classnames(style.footer, "dn", "db-m", className);

  return (
    <footer className={classNames}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={style.footer__content}>{children}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
