import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const rvAvailabilityCalendar = gql`
  ${baseFragments.money}
  query rv_availability_calendar($id: Int!, $current_rental: Int) {
    rv_availability_calendar(id: $id, current_rental: $current_rental) {
      available
      busy
      date
      price {
        ...MoneyReturn
      }
    }
  }
`;
