import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const listQuery = gql`
  ${baseFragments.rental.status}
  query {
    all_rental_statuses {
      ...RentalStatus
    }
    my_rentals {
      id
      is_predefined
      action
      action_button {
        key
        value
      }
      content {
        text {
          value
        }
      }
      rental_type {
        key
        value
      }
      departure_date
      details_button {
        key
        value
      }
      duration
      owner {
        id
        short_name
      }
      pick_up_location
      price {
        rental_total {
          user_friendly
        }
        owner_earns {
          user_friendly
        }
      }
      renter {
        id
        short_name
      }
      return_date
      role {
        key
        value
      }
      rv {
        rv_name
        title_image
        location {
          full_address
          short_address
        }
      }
      specific_delivery_location_id
      status {
        ...RentalStatus
      }
      additional_status {
        key
        value
      }
      additional_info
    }
  }
`;
