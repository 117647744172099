import React, { useCallback } from "react";
import Modal from "components/Modal/Modal";
import { FieldInput } from "components/form/FieldInput";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import Form from "../form/Form/Form";
import Typography from "../Typography";
import { VALIDATION } from "utils/validation";
import cn from 'classnames';
import classes from "./ModalConfirmation.module.css";

export const ModalConfirmation = (props) => {
  const {
    show,
    onClose,
    title,
    textContent,
    inputLabel,
    buttons: {
      confirmButtonLabel = '',
      cancelButtonLabel = ''
    } = {},
    onSubmit,
    defaultValues = {
      field: ''
    }
  } = props;
  const { handleSubmit, errors, control, watch } = useForm({
    defaultValues
  });
  const { field } = watch();

  const handleOnSubmit = useCallback((values) => {
    onSubmit(values);
  }, [onSubmit]);
  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={`${classes.modalWrap}`}
      modalClassnames={cn(
        classes.modal,
        "mdl--full-screen-less-768"
      )}
      modalCrossButtonClassname={classes.crossButton}
    >
      <div>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Typography
            component="h1"
            variant="headline"
            size="m"
            className="mt-8 mt-m-0 mt-xl-0 mb-xl-0"
          >
            {title}
          </Typography>
          <div className="b-16 b-m-20">
            <Typography
              className={classes.subtitle}
              component="h5"
              variant="subtitle"
              size="s"
            >
              {textContent}
            </Typography>
          </div>
          <div className="col">
            <div className="row b-16 b-m-20">
              <Controller
                name="field"
                control={control}
                rules={VALIDATION.rules.required}
                render={(renderProps) => (
                  <FieldInput
                    label={inputLabel}
                    id="field"
                    className="fw"
                    errors={errors}
                    {...renderProps}
                  />
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col t-16 t-m-20 b-16 b-m-20">
              <div className="btn-group">
                <Button onClick={onClose} secondary>
                  {cancelButtonLabel}
                </Button>
                <Button type="submit" disabled={!field}>{confirmButtonLabel}</Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>

  );
};
