import React, { useMemo } from "react";
import Typography from "components/Typography";
import Modal from "components/Modal/Modal";
import { TextItem } from "components/TextItem";
import {
  PRICE_FORM_ITEM_TAGS
} from "components/PriceForm/constants";
import classes from "./PriceFormInfoModal.module.css";

const PriceFormInfoModal = (props) => {
  const {
    data: {
      show,
      title,
      data
    },
    onAction,
    onClose
  } = props;


  const tagDesc = useMemo(() => [
    {
      tag: PRICE_FORM_ITEM_TAGS.BOLD,
      classNames: [classes.colorTextBody, classes.bodyMBold]
    },
    {
      tag: PRICE_FORM_ITEM_TAGS.DISCOUNT,
      classNames: [classes.statusSuccess, classes.bodyMBold]
    },
    {
      tag: PRICE_FORM_ITEM_TAGS.ACTION,
      classNames: [classes.action, classes.primary, classes.bodyMBold],
      onClick: onAction
    },
    {
      tag: PRICE_FORM_ITEM_TAGS.LIGHT,
      classNames: [classes.colorTextBodyLight, classes.bodyMBold]
    }
  ], [onAction]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={classes.modalWrap}
      modalClassnames={classes.modal}
    >
      <div className={classes.container}>
        <div>
          <Typography
            component="h1"
            variant="headline"
            size="m"
            className={classes.title}
          >
            {title}
          </Typography>
        </div>

        <div>
          <TextItem
            data={data}
            plainStyle={classes.regularPlainCommonStyle}
            tagCommonStyle={classes.regularTagCommonStyle}
            tagDesc={tagDesc}
          />
        </div>
      </div>
    </Modal>
  );
};

PriceFormInfoModal.propTypes = {
};

export default PriceFormInfoModal;
