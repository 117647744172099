export function calculatePrice(data) {
  const rate = data.average_daily_rate.dollars_as_part;
  const rateWithoutDurationDiscount =
    data.average_daily_rate_without_duration_discount.dollars_as_part;
  const rentalFee = data.rental_fee.dollars_as_part;
  const rentalFeeWithoutDurationDiscount =
    data.rental_fee_without_duration_discount.dollars_as_part;
  const rentalFeeWithoutDurationDiscountUserFriendly =
    data.rental_fee_without_duration_discount.user_friendly;
  const rentalFeeWithoutDurationDiscountCentsTotal =
    data.rental_fee_without_duration_discount.cents_as_part;
  const durationDiscount = data.duration_discount;
  const duration = data.duration;

  const taxesAndFeesCurrencyData = [
    {
      cents: data?.booking_fee?.cents_as_part || 0,
      dollars: data?.booking_fee?.dollars_as_part || 0
    },
    {
      cents: data?.delivery_fee?.cents_as_part || 0,
      dollars: data?.delivery_fee?.dollars_as_part || 0
    },
    {
      cents: data?.cleaning_fee?.cents_as_part || 0,
      dollars: data?.cleaning_fee?.dollars_as_part || 0
    },
    {
      cents: data?.disposal_fee?.cents_as_part || 0,
      dollars: data?.disposal_fee?.dollars_as_part || 0
    },
    {
      cents: data?.taxes?.total?.cents_as_part || 0,
      dollars: data?.taxes?.total?.dollars_as_part || 0
    }
  ];

  const taxesAndFeesExcludeCurrencyData = [
    {
      cents: data?.good_sam_membership_discount?.cents_as_part || 0,
      dollars: data?.good_sam_membership_discount?.dollars_as_part || 0
    }
  ];
  const goodSamMembershipFeeCurrencyData = [
    {
      cents: data?.good_sam_membership_fee?.cents_as_part || 0,
      dollars: data?.good_sam_membership_fee?.dollars_as_part || 0
    }
  ];

  const totalCurrencyData = [
    {
      cents: data?.rental_fee?.cents_as_part || 0,
      dollars: data?.rental_fee?.dollars_as_part || 0
    },
    {
      cents: data?.insurance_fee?.cents_as_part || 0,
      dollars: data?.insurance_fee?.dollars_as_part || 0
    }
  ];
  const goodSamMembershipDiscountPlanFeeCurrencyData = [
    {
      cents: data?.good_sam_membership_discount_plan?.cents_as_part || 0,
      dollars: data?.good_sam_membership_discount_plan?.dollars_as_part || 0
    }
  ];

  const result = {
    price: null,
    total: null,
    discount: null,
    priceWidget: null,
    totalWidget: null,
    discountWidget: null,
    rentalRate: data.rental_fee.user_friendly,
    rentalRateDollarsAsPart: data.rental_fee.dollars_as_part,
    rentalFeeWithoutDurationDiscount,
    rentalFeeWithoutDurationDiscountUserFriendly,
    rentalFeeWithoutDurationDiscountCentsTotal,
    insurance: data.insurance_fee.user_friendly,
    insuranceDollars: data.insurance_fee.dollars_as_part,
    taxesFees: data.booking_fee.user_friendly,
    rentalTotal: data.rental_total.user_friendly,
    duration,
    memberAndSave: data.good_sam_membership_discount_plan.dollars_as_part,
    goodSamMembershipDiscountPlanUserFriendly:
      data.good_sam_membership_discount_plan.user_friendly,
    goodSamMembershipDiscountUserFriendly:
      data.good_sam_membership_discount.user_friendly,
    goodSamMembershipFee: data.good_sam_membership_fee.user_friendly,
    bookingFee: data.booking_fee.user_friendly,
    cleaningFee: data.cleaning_fee.user_friendly,
    deliveryFee: data.delivery_fee.user_friendly,
    disposalFee: data.disposal_fee.user_friendly,
    taxes: data.taxes.total.user_friendly,
    taxesAndFeesCurrencyData,
    taxesAndFeesExcludeCurrencyData,
    goodSamMembershipFeeCurrencyData,
    totalCurrencyData,
    goodSamMembershipDiscountPlanFeeCurrencyData
  };
  if (rate === rateWithoutDurationDiscount) {
    result.totalWidget = rate;
  } else {
    result.priceWidget = rateWithoutDurationDiscount;
    result.totalWidget = rate;
    result.discountWidget = durationDiscount;
  }
  if (rentalFee === rentalFeeWithoutDurationDiscount) {
    result.total = rentalFee;
  } else {
    result.price = rentalFeeWithoutDurationDiscount;
    result.total = rentalFee;
    result.discount = durationDiscount;
  }

  return result;
}
