import Button from "components/Button";
import React from "react";
import classes from "./BookingWidgetBarDefault.module.css";
import { useBottomBarSize } from "../../../../hooks/useBottomBarSize";

export const BookingWidgetBarDefault = (props) => {
  const {
    minimumDuration,
    setShowCalendar,
    dailyRateInitial,
    pushSegmentAnalyticsTrackData
  } = props;

  const barRef = useBottomBarSize();

  return (
    <div className={`${classes.BookingWidgetBarDefault}`} ref={barRef}>
      <div className={`${classes.bookingWidgetBarDefaultLeftColumn}`}>
        <div className={`${classes.bookingWidgetBarDefaultDiscountContainer}`}>
          <div className={`${classes.bookingWidgetBarDefaultDiscountInfo}`}>
            <div className={`${classes.bookingWidgetBarDefaultTotal}`}>
              {`$${dailyRateInitial}`}
              <span className={`${classes.bookingWidgetBarDefaultTotalText}`}>
                per night
              </span>
              <span
                className={`${classes.bookingWidgetBarDefaultTotalTextMin}`}
              >
                /night
              </span>
            </div>
          </div>
          <div className={`${classes.bookingWidgetBarDefaultDuration}`}>
            <span className={`${classes.bookingWidgetBarDefaultDurationMax}`}>
              {minimumDuration} nights minimum
            </span>
            <span className={`${classes.bookingWidgetBarDefaultDurationMin}`}>
              {minimumDuration} nights min.
            </span>
          </div>
        </div>
      </div>

      <div className={`${classes.bookingWidgetBarDefaultRightColumn}`}>
        <div className={`${classes.bookingWidgetBarDefaultButtonContainer}`}>
          <Button
            onClick={() => {
              setShowCalendar(true);
              pushSegmentAnalyticsTrackData();
            }}
            className={`${classes.bookingWidgetBarDefaultButton}`}
            label="Check availability"
          />
        </div>
      </div>
    </div>
  );
};
