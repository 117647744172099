import { useQuery } from "@apollo/client";
import { isDriverVerificationPendingQuery } from "components/Verification/DriverVerifyModal/queries/isDriverVerificationPendingQuery";

export const useNeedDriverVerification = () => {
  const {
    data,
    loading,
    refetch
  } = useQuery(isDriverVerificationPendingQuery, {
    fetchPolicy: "no-cache"
  });

  return {
    isNeedDriverVerify: data?.is_driver_verification_pending,
    loadingDriverVerificationPending: loading,
    refetchDriverVerificationPending: refetch
  };
};
