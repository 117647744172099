import React, { useCallback, useState, useMemo, useEffect } from "react";
import Button from "components/Button";
import cn from 'classnames';
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Typography from "components/Typography";
import { SpinnerBlur } from "components/Spinner/Spinner";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import RentalChange from "processes/Rentals/RentalChange";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import { DriverVerifyModalCheck } from "components/Verification/DriverVerifyModal/DriverVerifyModalCheck";
import { getCookie } from "utils/cookie";
import { RENTAL_TYPE } from "utils/constants/rentalType";
import ReferralNotificationPopover from "components/ReferralNotificationPopover/ReferralNotificationPopover";
import RentalPaymentModal from "components/RentalPaymentModal/RentalPaymentModal";
import WithVerificationsCheck from "components/Verification/WithVerificationsCheck";
import { isUserOwner, isUserRenter } from "../utils";
import RentalDetailsAsideInfo from "./components/RentalDetailsAsideInfo";
import RentalDetailsHeader from "./components/RentalDetailsHeader";
import RentalDetailsStatus from "./components/RentalDetailsStatus";
import ProfileInfoSection from "./components/ProfileInfoAside/ProfileInfoSection";
import RentalMileage from "./components/RentalMileage";
import RequestHistory from "./components/RequestHistory";
import AllReviews from "./components/AllReviews";
import RentalDetailsRentalInfo from "./components/RentalDetailsRentalInfo";
import { AdditionalDrivers } from "components/AdditionalDrivers";
import { readStorage, writeStorage } from "utils/storageFirstView";
import classes from './RentalDetailsContent.module.css';
import { TOAST_TYPE } from "constants/toastTypes";
import { getInspectionsData } from "processes/Rentals/RentalDetails/utils/getInspectionsData";
import {
  handleRentalsSegment,
  checkCancelationStatus,
  checkCompletedStatus
} from "processes/Rentals/utils";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import VerificationStepsModals from "components/VerificationStepsModals/VerificationStepsModals";
import { VERIFICATION_STEPS } from "components/VerificationStepsModals/constants";
import { useNeedDriverVerification } from "components/Verification/DriverVerifyModal/hooks/useNeedDriverVerification";
import { useUrlParams } from "components/Verification/DriverVerifyModal/hooks/useUrlParams";
import { DRIVER_AUTH_PASSED } from "components/Verification/DriverVerifyModal/constants";

const RentalDetailsContent = () => {
  const {
    rental = {},
    loading,
    refetch,
    isIMGlobalIntegrationIsEnabled,
    showRentalPaymentModal,
    closeRentalPaymentModal
  } = useRentalDetailsContext();

  const {
    isNeedDriverVerify,
    refetchDriverVerificationPending
  } = useNeedDriverVerification();

  const { getUrlWithAddedParam } = useUrlParams();

  const isRemovingAdditionalDriversIsEnabled = useFeatureFlags('removing_additional_drivers');
  const rentalId = rental?.id;

  const inspectionsData = useMemo(() => getInspectionsData(rental), [rental]);
  const [changeShow, setChangeShow] = useState(false);
  const [showPredefinedToast, setShowPredefinedToast] = useState(() => readStorage(rentalId, TOAST_TYPE.PREDEFINED));

  const [showReferralToast, setShowReferralToast] = useState(() => readStorage(rentalId, TOAST_TYPE.BOOKING));

  const [startDriverVerification, setStartDriverVerification] = useState(false);
  const isMaxLarge = useMatchMedia({ maxWidth: MQ_BP.large });
  const isMinSmall = useMatchMedia({ minWidth: MQ_BP.small });
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const isRentalStatusCompleted = checkCompletedStatus(rental);
  const isCancelationStatus = checkCancelationStatus(rental);
  const isRentalPreDefined = rental.is_predefined;

  const toggleChangeModal = useCallback(() => {
    setChangeShow((p) => !p);
  }, [setChangeShow]);

  const onSubmitChange = useCallback(() => {
    setChangeShow(false);
    refetch();
  }, [refetch, setChangeShow]);

  const handleScrollToAction = useCallback(() => {
    const actionElement = document.querySelector('#activeHistoryAction');

    if (actionElement) {
      window.scrollTo(0, actionElement?.offsetTop);
    }
  }, []);

  const handleOnDriversSuccessAdded = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOnDriversErrorAdded = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOnDriversSuccessRemoved = useCallback(() => {
    refetch();
  }, [refetch]);

  const closePreDefinedSuccessModal = () => {
    setShowPredefinedToast(false);
  };

  const closeReferralModal = () => {
    setShowReferralToast(false);
    handleRentalsSegment("Do it Later");
  };

  const openReferralModal = () => {
    handleRentalsSegment("Refer Users");
  };

  const closeDriverVerifyModal = () => {
    closeRentalPaymentModal();
    refetch();
    refetchDriverVerificationPending();
    setStartDriverVerification(false);
  };

  const onCloseRentalPaymentModal = useCallback(async() => {
    closeRentalPaymentModal();
    refetch();
    refetchDriverVerificationPending();
  }, [closeRentalPaymentModal, refetch, refetchDriverVerificationPending]);

  const onStartDriverVerify = useCallback(async() => {
    await refetchDriverVerificationPending();
    setStartDriverVerification(true);
  }, [refetchDriverVerificationPending]);

  const isRenter = isUserRenter(rental.role);
  const isOwner = isUserOwner(rental.role);

  const referralCookieTemplate = useMemo(() => `ReferralNotificationRental${rental?.id}Done`, [rental?.id]);

  const additionalDrivers = rental?.additional_drivers;
  const additionalDriversLimit = rental?.additional_drivers_limit;
  const limitAddDrivers = additionalDriversLimit - additionalDrivers?.length;
  const showDrivers = limitAddDrivers > 0 || additionalDrivers.length > 0;

  useEffect(() => {
    if (showPredefinedToast) {
      writeStorage(rentalId, TOAST_TYPE.PREDEFINED, false);
    }

    if (showReferralToast) {
      writeStorage(rentalId, TOAST_TYPE.BOOKING, false);
    }
  }, [rentalId, showPredefinedToast, showReferralToast]);

  if (loading) {
    return <SpinnerBlur />;
  }

  return (
    <div className="container t-36 t-m-60 t-l-40 t-xl-60 b-40 b-m-60 b-xl-120">
      {rental?.show_suggestion_button && (
        <RentalChange
          rental={rental}
          isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
          show={changeShow}
          onClose={toggleChangeModal}
          onSubmit={onSubmitChange}
        />
      )}

      <div className="row">
        {!isMaxLarge && <RentalDetailsAsideInfo />}

        <section className="col-12 col-xl-8 ml-a l-xl-12">
          <RentalDetailsHeader toggleChangeModal={toggleChangeModal} />

          {isMaxLarge && rental?.show_confirmed_section && (
            <section
              aria-label="Rental details"
              className={cn(
                !isCancelationStatus && "t-8 b-8 t-m-20 b-m-20",
                isCancelationStatus && "t-0 b-0 t-m-0 b-m-0"
              )}
            >
              { !isCancelationStatus && <div className="devider-solid devider-solid--light dn-m" /> }
              <RentalDetailsRentalInfo />
              { !isCancelationStatus && <div className="devider-solid devider-solid--light dn-m" /> }
            </section>
          )}

          <RentalDetailsStatus handleScrollToAction={handleScrollToAction} />

          {((isMinSmall && isMaxLarge) || (isOwner && isRentalPreDefined && isMaxSmall)) && <ProfileInfoSection />}

          {inspectionsData?.isShowInspections &&  (
            <>
              <div className="devider-solid devider-solid--light mt-12 mb-0 dn-m" />
              <RentalMileage
                rental={rental}
                inspectionOverageData={rental?.inspection_overage}
                inspectionsData={inspectionsData}
                onSubmitChange={onSubmitChange}
              />
            </>
          )}
          {showDrivers && (
            <div className="mb-16 mb-m-12 mt-xl-24 mb-m-52 mb-xl-32 mb-xxl-32">
              <div className="devider-solid devider-solid--light dn-m" />
              <div className="ml-0 ml-m-0">
                <AdditionalDrivers
                  drivers={additionalDrivers}
                  rentalId={rentalId}
                  handleOnDriversSuccessAdded={handleOnDriversSuccessAdded}
                  handleOnDriversErrorAdded={handleOnDriversErrorAdded}
                  handleOnDriversSuccessRemoved={handleOnDriversSuccessRemoved}
                  limitAddDrivers={limitAddDrivers}
                  isOwner={!isRenter}
                  allowedRemoveDrivers={!isRentalStatusCompleted && !isCancelationStatus && isRemovingAdditionalDriversIsEnabled}
                />
              </div>
            </div>
          )}

          <RequestHistory toggleChangeModal={toggleChangeModal} />

          {isRentalStatusCompleted && (
            <AllReviews
              rental={rental}
              refetchRental={refetch}
              rentalId={rentalId}
            />
          )}
        </section>

      </div>

      {showPredefinedToast &&
        <NotificationPopover
          show={showPredefinedToast}
          status="success"
          text={
            <>
              <Typography
                variant="subtitle"
                size="s"
                weight={"bold"}
                className={classes.notificationTitle}
              >
                Successfully
              </Typography>
              <Typography
                variant="body"
                size="l"
                className={classes.notificationText}
              >
                You have successfully sent a rental quote to <b>{rental?.renter?.email}</b>
              </Typography>
            </>
          }
          onClose={closePreDefinedSuccessModal}
        />}

      {showRentalPaymentModal && (
        <VerificationStepsModals
          onClose={closeRentalPaymentModal}
          verifyList={[VERIFICATION_STEPS.email, VERIFICATION_STEPS.phone]}
        >
          {() => (
            <RentalPaymentModal
              rental={rental}
              loading={loading}
              onClose={onCloseRentalPaymentModal}
              refetch={refetch}
              onStartDriverVerify={onStartDriverVerify}
            />
          )}
        </VerificationStepsModals>
      )}

      {
        isRenter
        && showReferralToast
        && !getCookie(referralCookieTemplate)
        && rental.status?.id !== 0
        && rental.status?.id !== 3
        && !rental.is_predefined
        && (
          <ReferralNotificationPopover
            title="specific_rental_notification_title"
            text="specific_rental_notification_text"
            acceptButton="specific_rental_notification_accept_button"
            declineButton="specific_rental_notification_decline_button"
            referralCookieTemplate={referralCookieTemplate}
            onClose={closeReferralModal}
            openReferralModal={openReferralModal}
          />
        )
      }
    </div>
  );
};

export default RentalDetailsContent;
