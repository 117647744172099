import React from "react";
import { PlusIcon } from "./PlusIcon";
import { CalendarIcon } from "./CalendarIcon";
import { TrashIcon } from "./TrashIcon";
import { InfoIcon } from "./InfoIcon";
import { InfoRedIcon } from "./InfoRedIcon";
import { InfoIconOutline } from "./InfoIconOutline";
import { CloseCircleIcon } from "./CloseCircleIcon";
import { CloseIcon } from "./CloseIcon";
import { CloseDakrThemeIcon } from "./CloseDakrThemeIcon";
import { TickIcon } from "./TickIcon";
import { TickGreenIcon } from "./TickGreenIcon";
import { TickDarkGreenIcon } from "./TickDarkGreenIcon";
import { TickGrayIcon } from "./TickGrayIcon";
import { TickLightGrayIcon } from "./TickLightGrayIcon";
import { TickQuaternaryDarkIcon } from "./TickQuaternaryDarkIcon";
import { MapIcon } from "./MapIcon";
import { FiltersIcon } from "./FiltersIcon";
import { SearchIcon } from "./SearchIcon";
import { ArrowIcon } from "./ArrowIcon";
import { SendIcon } from "./SendIcon";
import { ChatIcon } from "./ChatIcon";
import { MoreIcon } from "./MoreIcon";
import { MoreIconVertical } from "./MoreIconVertical";
import { EditIcon } from "./EditIcon";
import { SmartPhoneIcon } from "./SmartPhoneIcon";
import { ProfileIcon } from "./ProfileIcon";
import { LocationIcon } from "./LocationIcon";
import { BlackLightningIcon } from "./BlackLightningIcon";
import { WhiteLightningIcon } from "./WhiteLightningIcon";
import { InstantRental } from "./InstantRental";
import { AddFileIcon } from "./AddFileIcon";
import { LaunchIcon } from "./LaunchIcon";
import { LightningIcon } from "./LightningIcon";
import { DeliveryOnlyIcon } from "./DeliveryOnlyIcon";
import { StandardRentalIcon } from "./StandardRentalIcon";
import { DownloadIcon } from "./DownloadIcon";
import { CommentIcon } from "./CommentIcon";
import { PhotoPlaceholderLoaderIcon } from "./PhotoPlaceholderLoaderIcon";
import { AddonCheckedIcon } from "./AddonCheckedIcon";
import { AddonNotCheckedIcon } from "./AddonNotCheckedIcon";
import { DownloadRedIcon } from "./DownloadRedIcon";
import { RadioButtonLightGrayIcon } from "./RadioButtonLightGrayIcon";
import { RadioButtonQuaternaryDarkIcon } from "./RadioButtonQuaternaryDarkIcon";
import { ChevronIcon } from "./ChevronIcon";
import { ChevronDownIcon } from "./ChevronDownIcon";
import { ChevronUpIcon } from "./ChevronUpIcon";
import { ChevronRightCircleFillIcon } from "./ChevronRightCircleFillIcon";
import { QuestionIconOutline } from "./QuestionIconOutline";
import { QuestionIcon } from "./QuestionIcon";
import { CheckRedIcon } from "./CheckRedIcon";
import { CheckGrayIcon } from "./CheckGrayIcon";
import { CameraIcon } from "./CameraIcon";
import { HeadphonesIcon } from "./HeadphonesIcon";
import { MailIcon } from "./MailIcon";
import { CheckGreenIcon } from "./CheckGreenIcon";

export const plusIcon = <PlusIcon />;
export const calendarIcon = <CalendarIcon />;
export const trashIcon = <TrashIcon />;
export const explainerIcon = <InfoIcon />;
export const explainerIconOutline = <InfoIconOutline />;
export const explainerRedIconOutline = <InfoRedIcon />;
export const tickGreenIcon = <TickGreenIcon />;
export const tickDarkGreenIcon = <TickDarkGreenIcon />;
export const tickGrayIcon = <TickGrayIcon />;
export const tickLightGrayIcon = <TickLightGrayIcon />;
export const tickQuaternaryDarkIcon = <TickQuaternaryDarkIcon />;
export const closeIconCircle = <CloseCircleIcon />;
export const closeIcon = <CloseIcon />;
export const closeDarkThemeIcon = <CloseDakrThemeIcon />;
export const tickIcon = <TickIcon />;
export const mapIcon = <MapIcon />;
export const filtersIcon = <FiltersIcon />;
export const searchIcon = <SearchIcon />;
export const arrowIcon = <ArrowIcon />;
export const sendIcon = <SendIcon />;
export const chatIcon = <ChatIcon />;
export const moreIcon = <MoreIcon />;
export const moreIconVertical = <MoreIconVertical />;
export const editIcon = <EditIcon />;
export const smartPhoneIcon = <SmartPhoneIcon />;
export const profileIcon = <ProfileIcon />;
export const locationIcon = <LocationIcon />;
export const blackLightningIcon = <BlackLightningIcon />;
export const whiteLightningIcon = <WhiteLightningIcon />;
export const instantRentalIcon = <InstantRental />;
export const addFileIcon = <AddFileIcon />;
export const launchIcon = <LaunchIcon />;
export const lightningIcon = <LightningIcon />;
export const deliveryOnlyIcon = <DeliveryOnlyIcon />;
export const standardRentalIcon = <StandardRentalIcon />;
export const downloadIcon = <DownloadIcon />;
export const commentIcon = <CommentIcon />;
export const photoPlaceholderLoaderIcon = <PhotoPlaceholderLoaderIcon />;
export const addonCheckedIcon = <AddonCheckedIcon />;
export const addonNotCheckedIcon = <AddonNotCheckedIcon />;
export const downloadRedIcon = <DownloadRedIcon />;
export const radioButtonLightGrayIcon = <RadioButtonLightGrayIcon />;
export const radioButtonQuaternaryDarkIcon = <RadioButtonQuaternaryDarkIcon />;
export const chevronIcon = <ChevronIcon />;
export const chevronDownIcon = <ChevronDownIcon />;
export const chevronUpIcon = <ChevronUpIcon />;
export const chevronRightCircleFillIcon = <ChevronRightCircleFillIcon />;
export const questionIconOutline = <QuestionIconOutline />;
export const questionIcon = <QuestionIcon />;
export const checkRedIcon = <CheckRedIcon />;
export const checkGrayIcon = <CheckGrayIcon />;
export const cameraIcon = <CameraIcon />;
export const headphonesIcon = <HeadphonesIcon />;
export const mailIcon = <MailIcon />;
export const checkGreenIcon = <CheckGreenIcon />;

export { default } from "./Icon";
