import { gql } from "@apollo/client";

export const calculateDeliveryFee = gql`
  query calculateDeliveryFee($rv: Int!, $rental_type: Int, $location: String, $specific_delivery_location_id : Int) {
    calculate_delivery_fee(rv: $rv, rental_type: $rental_type, location: $location, specific_delivery_location_id: $specific_delivery_location_id) {
      result {
        success
        errors {
          code
          message
        }
      }
      delivery_fee {
        cents_total
        dollars_as_part
        cents_as_part
        user_friendly
      }
    }
  }
`;
