import React, { useRef } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Button from "components/Button";
import { useScrollShadow } from "../../hooks/useScrollShadow";
import { ChoosePhotosList } from "components/ChoosePhotos";
import classnames from "classnames";
import classes from "./ChoosePhotosModal.module.css";

export const ChoosePhotosModalContent = (props) => {

  const {
    photos,
    onContinue,
    onChangePhoto,
    title = "Choose photos from my Garage"
  } = props;

  const contentRef = useRef(null);
  const scrollShadowData = useScrollShadow(contentRef, true);

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <Typography variant="headline" size="m" weight="bold" className={classes.title}>
          {title}
        </Typography>
      </header>
      <div className={classes.content}>
        <div className={classes.contentInner1} ref={contentRef}>
          <div className={classes.contentInner2}>
            <ChoosePhotosList photos={photos} onChangePhoto={onChangePhoto} />
          </div>
        </div>
      </div>

      <footer
        className={
          classnames(
            classes.footer,
            scrollShadowData.bottom && classes.isShadow,
            ""
          )
        }
      >
        <div className={classes.footerInner}>
          <Button
            className={classes.button}
            onClick={onContinue}
            label="Continue"
          />
        </div>
      </footer>
    </div>
  );
};

ChoosePhotosModalContent.propTypes = {
  title: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string,
        srcset: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string
          })
        )
      }),
      id: PropTypes.string,
      is_used_on_rentals: PropTypes.bool
    })
  ),
  onContinue: PropTypes.func,
  onChangePhoto: PropTypes.func
};
