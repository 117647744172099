import Modal from "../Modal/Modal";
import classes from "./styles.module.css";

const ApplyCouponModal = ({ children, ...props }) => (
  <Modal
    {...props}
    modalWrapClassnames={`mdl-wrap--full-screen-less-768`}
    withContainer
    showCross={false}
    render={({ handleClose }) => (
      <div className="row fjc">
        <div className="col col-m-8 col-xl-4">
          <div className={`mdl mdl--full-screen-less-768 ${classes.mdl}`}>
            <div className={`${classes.mdlContent}`}>{children}</div>
            <div className="mdl__cross" onClick={handleClose} />
          </div>
        </div>
      </div>
    )}
  />
);

export default ApplyCouponModal;
