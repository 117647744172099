import React, { useState, useEffect, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { membershipDetailsQuery } from "../../queries/membershipDetailsQuery";
import BecomeMemberShip from "components/BecomeMemberShip";
import { MembershipDetailsList }  from "components/MembershipDetails";
import { ModalMembershipValidation } from "components/ModalMembershipValidation/ModalMembershipValidation";
import { SpinnerBack } from "components/Spinner/Spinner";
import { CLUB_IDS }  from "components/MembershipDetails/constants";
import classes from "./MembershipDetails.module.css";

export const MembershipDetails = ({ data: membershipDetails, onRefetch }) => {
  const [isShowBecomeMemberShip, setShowBecomeMemberShip] = useState(false);
  const [isShowModalMembershipValidation, setIsShowModalMembershipValidation] = useState(false);

  const [
    load,
    { data: { membership_details = [] } = {}, loading }
  ] = useLazyQuery(membershipDetailsQuery,{
    fetchPolicy: "no-cache"
  });

  const membershipDetailsData = membershipDetails || membership_details;

  const membershipDetailsLoad = useCallback(() => {
    load();
  }, [load]);

  const showModalMembershipValidation = useCallback(() => {
    setIsShowModalMembershipValidation(true);
  }, []);

  const onModalMembershipValidation = useCallback(() => {
    setIsShowModalMembershipValidation(false);
  }, []);

  const showBecomeMemberShip = useCallback(() => {
    setShowBecomeMemberShip(true);
  }, []);

  const onCloseBecomeMemberShip = useCallback(() => {
    setShowBecomeMemberShip(false);
  }, []);

  useEffect(() => {
    if (!membershipDetails) {
      membershipDetailsLoad();
    }
  }, [membershipDetails, membershipDetailsLoad]);

  return (
    <div className={classes.container}>
      <MembershipDetailsList
        data={membershipDetailsData}
        links={{
          [CLUB_IDS.GOODSAM]: {
            joinLink: showBecomeMemberShip,
            memberLink: showModalMembershipValidation
          }
        }}
      />
      <BecomeMemberShip
        show={isShowBecomeMemberShip}
        onCloseBecomeMemberShip={onCloseBecomeMemberShip}
        handleActionAfterLastStep={onRefetch || membershipDetailsLoad}
        setIsShowMembershipModal={() => {
          if (!isShowBecomeMemberShip) setShowBecomeMemberShip(true);
        }}
      />

      <ModalMembershipValidation
        show={isShowModalMembershipValidation}
        onClose={onModalMembershipValidation}
      />

      {loading && <SpinnerBack />}
    </div>
  );
};

MembershipDetails.defaultProps = {

};

MembershipDetails.propTypes = {

};
