export const CLUB_IDS = {
  GOODSAM: 'goodsam',
  ROADSIDE: 'roadside',
  TRAVELASSIST: 'travelassist',
  TWP: 'twp'
};

export const LINKS_DEFAULT = {
  [CLUB_IDS.GOODSAM]: {
    JOIN_LINK: "https://www.goodsam.com/club/join/default.aspx",
    MEMBER_LINK: 'https://myaccount.goodsam.com/my-account'
  },
  [CLUB_IDS.ROADSIDE]: {
    JOIN_LINK: "https://roadside.goodsam.com/checkout/ra-rv?selectedProd=2",
    MEMBER_LINK: 'https://myaccount.goodsam.com/my-account'
  },
  [CLUB_IDS.TRAVELASSIST]: {
    JOIN_LINK: "https://travelassist.goodsam.com/checkout",
    MEMBER_LINK: 'https://myaccount.goodsam.com/my-account'
  },
  [CLUB_IDS.TWP]: {
    JOIN_LINK: "https://tireandwheel.goodsam.com/checkout",
    MEMBER_LINK: 'https://myaccount.goodsam.com/my-account'
  }
};
