import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  chatIcon,
  smartPhoneIcon,
  profileIcon,
  locationIcon
} from "components/Icon";
import Typography from "components/Typography";
import classes from "./ProfileInfoAsideList.module.css";

const ProfileInfoAsideList = (props) => {
  const { location, phone, role, onClickChat, profileLink } = props;

  console.log(location);

  return (
    <div className={classes.container}>
      <div className={classnames(classes.listProfileInfo, "df db-xl")}>
        <div
          className={classnames(
            classes.listProfileInfoCol,
            "l-0 r-0 df-l tac-l col-xl-12 db-xl tal-xl",
            !!phone && "col-6 df fjc",
            !phone && "col-4 df fjc",
            !Boolean(location) && classes.listProfileInfoColDisplayNone
          )}
        >
          <div
            className={classnames(
              classes.listProfileInfoItem,
              !location && classes.listProfileInfoColDisplayNone,
              "col-12 l-0 r-12 mb-4 col-l-6 col-xl-12 mb-m-8 mb-xl-8"
            )}
          >
            <Typography component="p" variant="body" size="l">
              <span className="df db-l df-xl r-l-12 r-xl-0">
                <span className="df-l fjc mb-l-20 mb-xl-0">{locationIcon}</span>
                <span className="ml-12 ml-l-0 l-xl-12">{location}</span>
              </span>
            </Typography>
          </div>

          {!!phone && (
            <div
              className={classnames(
                classes.listProfileInfoItem,
                "col-12 col-l-6 l-0 r-0 col-xl-12 mb-xl-8"
              )}
            >
              <Typography component="p" variant="body">
                <span className="df db-l df-xl l-l-12 r-l-12 l-xl-0 r-xl-0">
                  <span className="df-l fjc mb-l-20 mb-xl-0">
                    {smartPhoneIcon}
                  </span>
                  <span className="ml-12 ml-l-0 l-xl-12">
                    <a
                      className={classes.listProfileInfoLink}
                      href={`tel:${phone}`}
                    >
                      <span className="dbi df-xl">
                        <span className="dn-l db-xl r-4">Call</span> {phone}
                      </span>
                    </a>
                  </span>
                </span>
              </Typography>
            </div>
          )}
        </div>

        <div
          className={classnames(
            classes.listProfileInfoCol,
            " l-4 l-l-0 r-0 df-l tac-l db-xl col-xl-12 tal-xl",
            !!phone && "col-6",
            !phone && "col-8"
          )}
        >
          <div
            className={classnames(
              classes.listProfileInfoItem,
              !Boolean(location) && classes.listProfileInfoColDisplayNone,
              "mb-8 col-12 col-l-6 l-0 r-0 col-xl-12 mb-xl-8"
            )}
          >
            <Typography component="p" variant="body">
              <span className="df db-l df-xl l-l-12 r-l-12 l-xl-0 r-xl-0">
                <span className="df-l fjc mb-l-20 mb-xl-0">{chatIcon}</span>
                <span
                  className={classnames(
                    classes.listProfileInfoLink,
                    "ml-12 ml-l-0 l-xl-12"
                  )}
                  onClick={onClickChat}
                >
                  {`Message ${role?.toLowerCase()}`}
                </span>
              </span>
            </Typography>
          </div>

          <div
            className={classnames(
              classes.listProfileInfoItem,
              "col-12 col-l-6 l-0 r-0 col-xl-12"
            )}
          >
            <Typography component="p" variant="body">
              <span className="df db-l df-xl l-l-12 l-xl-0">
                <span className="df-l fjc mb-l-20 mb-xl-0">{profileIcon}</span>
                <span className="ml-12 ml-l-0 l-xl-12">
                  <a className={classes.listProfileInfoLink} href={profileLink}>
                    <span>{`${role}'s public profile`}</span>
                  </a>
                </span>
              </span>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileInfoAsideList.propTypes = {
  role: PropTypes.string,
  isVertical: PropTypes.bool,
  phone: PropTypes.string,
  rating: PropTypes.number,
  location: PropTypes.string,
  profileLink: PropTypes.string.isRequired,
  onClickChat: PropTypes.func
};

export default ProfileInfoAsideList;
