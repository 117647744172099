import React from "react";
import Typography from "components/Typography";
import { Controller } from "react-hook-form";
import AutocompleteAddress from "components/form/AutoComplete/AutocompleteAddress";
import uniqueId from "lodash/uniqueId";
import classes from "./PickUpDeliveryModal.module.css";

const formatDeliveryFee = (value) => {
  return !value?.cents_total
    ? "Free"
    : `$${value.user_friendly}`;
};

export const getRadioList = (props) => {
  const {
    initialLocation,
    deliveryFee,
    showDefaultLocation,
    control,
    isMapLoaded,
    radioState,
    errors,
    currentLocation,
    setShowDefaultLocation,
    handleOnCalculateDeliveryFee,
    setCurrentLocation,
    setValue,
    setShowNotificationPopover,
    paidDelivery,
    specificLocations,
    rentalType,
    deliveryRadius,
    locationData,
    deliveryFeeData,
    setDeliveryFeeData
  } = props;

  const prefix = rentalType === 1 ? "Standard" : "Delivery";

  const list = [
    ...specificLocations.map((specLocs) => {
      const deliveryFee = specLocs?.delivery_fee.user_friendly === "0.00" ? "Free" : `$${specLocs?.delivery_fee?.user_friendly}`;

      return {
        id: uniqueId(`${prefix}_`),
        value: String(specLocs?.id),
        cost: (
          <span className="cost">
            <strong>{deliveryFee}</strong>
          </span>
        ),
        text: (
          <strong>{specLocs?.location?.full_address}</strong>
        ),
        contentList: [
          {
            show: specLocs?.description && specLocs?.description?.length !== 0,
            value: (
              <div className={`${classes.rowDescription} l-32`}>
                <Typography
                  variant="body"
                  size="l"
                >
                  {specLocs?.description}
                </Typography>
              </div>
            )
          }
        ]
      };
    })
  ];
  if (rentalType === 1) {
    const pickUp = {
      id: uniqueId(`${prefix}_`),
      text: (
        <strong>Pick-up location</strong>
      ),
      value: 'location-1',
      cost: (
        <span className="cost">
          <strong>Free</strong>
        </span>
      ),
      contentList: [
        {
          show: true,
          value: (
            <div className="l-32">
              <Typography
                variant="body"
                size="l"
                className={classes.rowDescription}
              >
                {initialLocation?.short_address}
              </Typography>
            </div>
          )
        }
      ]
    };
    list.splice(0, 0, pickUp);
  }

  if (deliveryRadius[rentalType]) {
    let deliveryFeeValue = (!deliveryFeeData?.deliveryFee || deliveryFeeData?.error || showDefaultLocation || !locationData[rentalType]?.deliveryRadius?.deliveryFee) && "(not specified)";

    if (!showDefaultLocation && !deliveryFeeData?.error && (locationData[rentalType]?.deliveryRadius?.deliveryFee || deliveryFeeData?.deliveryFee)) {
      deliveryFeeValue = <span className="cost"><b>{formatDeliveryFee(deliveryFeeData?.deliveryFee || locationData[rentalType]?.deliveryRadius?.deliveryFee)}</b></span>;
    };

    const dr = {
      id: uniqueId(`${prefix}_`),
      text: (
        <strong className="no-wrap">Location of your choice</strong>
      ),
      value: 'location-2',
      cost: deliveryFeeValue,
      contentList: [
        {
          show: true,
          value: (
            <div className="l-32">
              <div>
                <Controller
                  name="location"
                  control={control}
                  render={(renderProps) => {
                    return (
                      <>
                        {isMapLoaded && (
                          <div className={`${radioState !== 'location-2' ? 'dn' : 'mt-24'}`}>
                            <AutocompleteAddress
                              label={"Address"}
                              errors={errors}
                              defaultValue={currentLocation?.full_address || ""}
                              {...renderProps}
                              onChange={(e) => {
                                renderProps.onChange(e);
                                setShowDefaultLocation(false);
                                setDeliveryFeeData({
                                  deliveryFee: null,
                                  error: true
                                });
                                handleOnCalculateDeliveryFee(
                                  e?.full_address,
                                  null
                                );
                                setCurrentLocation(e);
                              }}
                              onChangeValue={() => {
                                setValue(`location`, {
                                  full_address: "",
                                  latitude: "",
                                  longitude: ""
                                });

                                setShowNotificationPopover(false);
                                setShowDefaultLocation(true);
                              }}
                            />
                          </div>
                        )}
                      </>
                    );
                  }}
                />
              </div>
              <div className={`${radioState !== 'location-2' ? 'dn' : 'mt-20'}`}>
                <Typography
                  variant="body"
                  size="l"
                  className={``}
                >
                  <strong>Note</strong>: The owner is willing to
                  deliver their RV within a {paidDelivery} mile
                  radius.
                </Typography>
              </div>
            </div>
          )
        }
      ]
    };
    if (rentalType === 1) {
      list.splice(1, 0, dr);
    } else {
      list.splice(0, 0, dr);
    }

  }

  return list;
};
