import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export const LoginLinkProvider = (props) => {
  const { children } = props;
  const history = useHistory();
  const { search } = history.location;
  const link = `/login${search}`;

  return (
    <Link to={link}>
      {children}
    </Link>
  );
};
