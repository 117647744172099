export const ADD_INSURANCE_LIST_MODES = {
  USING: 'using',
  SETTINGS: 'settings'
};

export const ADD_INSURANCE_EXTRA_IDS = {
  INSURANCE_ID: 'extraInsuranceId',
  PACKAGE_ID: 'extraPackageId',
  NO_COVERAGE: 'No coverage'
};

export const ADD_INSURANCE_DEFAULT_TAGS = {
  BIRTH: 'date_of_birth',
  NAME: 'full_name'
};

