import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Button from "../Button";

const ActionBar = (props) => {
  const {
    className = "",
    classNameClearButton = "",
    classNameApplyButton = "",
    clearTitle,
    onClear,
    clearDisabled,
    onSubmit,
    submitDisabled,
    theme
  } = props;

  return (
    <div className={classnames([className, "fbc"])}>
      {onClear ? (
        <Button
          ghost={theme !== "dark"}
          secondary={theme === "dark"}
          inverse={theme === "dark"}
          onClick={onClear}
          disabled={clearDisabled}
          className={classNameClearButton}
        >
          {clearTitle}
        </Button>
      ) : <div />}
      <div className="mr-16" />
      <Button
        type="submit"
        onClick={onSubmit}
        disabled={submitDisabled}
        className={classNameApplyButton}
        inverse={theme === "dark"}
      >
        Apply
      </Button>
    </div>
  );
};

ActionBar.defaultProps = {
  clearTitle: "Clear"
};

ActionBar.propTypes = {
  className: PropTypes.string,
  clearTitle: PropTypes.string,
  onClear: PropTypes.func,
  clearDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  theme: PropTypes.string
};

export default ActionBar;
