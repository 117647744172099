import React, { useMemo, useCallback, useState } from "react";
import classnames from "classnames";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Typography from "components/Typography";
import Button from "components/Button";
import { hasAction, isUserOwner } from "processes/Rentals/utils";

import { useRentalDetailsContext } from "../../RentalDetailsContext";
import RequestMessage from "./RequestMessage";
import RequestCollapse from "./RequestCollapse";

import style from "./style.module.css";

const VISIBLE_COUNT = 3;
const TITLE = "Request history";

const RequestHistory = (props) => {
  const { toggleChangeModal } = props;

  const {
    rental,
    rental: { history, show_suggestion_button, role, status },
    executeAction
  } = useRentalDetailsContext();

  const [historyOpen, setHistoryOpen] = useState(false);

  const toggleCollapse = useCallback(() => {
    setHistoryOpen(p => !p);
  }, []);

  const isMinSmall = useMatchMedia({ minWidth: MQ_BP.small });


  const isOwner = isUserOwner(role);

  const visibleMessages = history.slice(0, VISIBLE_COUNT)
    .map((item, index) => (
      <RequestMessage
        key={index}
        executeAction={executeAction}
        item={item}
        isOwner={isOwner}
        status={status}
        rental={rental}
      />
    ));

  const collapsedMessages = history.slice(VISIBLE_COUNT, history.length)
    .map((item, index) => (
      <RequestMessage
        key={index}
        executeAction={executeAction}
        item={item}
        isOwner={isOwner}
        status={status}
        rental={rental}
      />
    ));

  const hasCollapsedMessages = collapsedMessages.length > 0;

  const normalView = (
    <div className="dn db-m">
      <div className={classnames(style.requestHistory__heading, "b-20")}>
        <Typography variant="subtitle" size="l" component="h2">
          {TITLE}
        </Typography>

        {show_suggestion_button && (
          <Button
            secondary
            label="Suggest a change"
            onClick={toggleChangeModal}
          />
        )}
      </div>

      <div className="request-history__content">
        {visibleMessages}
      </div>

      {hasCollapsedMessages && (
        <RequestCollapse
          header="Show more"
          openHeader="Show less"
          content={collapsedMessages}
          toRight
          columnReverse
        />
      )}
    </div>
  );

  const collapseView = (
    <div className="dn-m">
      <RequestCollapse
        defaultOpen={history.some(i => hasAction(i, "any"))}
        noCollapse={visibleMessages.length === 1}
        header={TITLE}
        content={
          <>
            {show_suggestion_button && (
              <div className="t-20 b-20">
                <Button
                  secondary
                  label="Suggest a change"
                  onClick={toggleChangeModal}
                />
              </div>
            )}

            <div className="request-history__content t-8">
              {visibleMessages}
            </div>

            {hasCollapsedMessages && (
              <>
                {historyOpen && collapsedMessages}

                <div className="l-16">
                  <Button
                    ghost
                    label={historyOpen ? "Show less" : "Show more"}
                    onClick={toggleCollapse}
                  />
                </div>
              </>
            )}
          </>
        }
      />
    </div>
  );

  if (!history) {
    return null;
  }

  return (
    <div className={classnames(style.requestHistory, "mt-8 mt-m-0 t-8 b-8")}>
      {
        isMinSmall
          ? normalView
          : collapseView
      }
    </div >
  );
};

export default RequestHistory;
