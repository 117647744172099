import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DayPicker, { DateUtils } from "react-day-picker";
import { normalizeEntries } from "../../utils/dayPickerDataFormatting";
import classes from "./BigDayPicker.module.css";

const WEEKDAYS_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function filterDays(daysData, filterFn) {
  return (
    Object.values(daysData)
      .filter(filterFn)
      // new Date(date) returns the date based on UTC instead of local time
      .map((dayData) => moment(dayData.date).toDate())
  );
}

function filterDisabledDaysFn(daysData) {
  return filterDays(daysData, (dayData) => dayData.busy || !dayData.available);
}

export const BigDayPicker = (props) => {
  const {
    availability,
    value: { from, to },
    onChange,
    renderDay,
    filterDisabledDays,
    filterBusyDays,
    numberOfMonths,
    extraData: {
      extraClasses = '',
      extraDisabledDays = null
    } = {},
    isDisabledPrevDates,
    ...dayPickerProps
  } = props;

  const daysData = useMemo(() => normalizeEntries(availability), [
    availability
  ]);
  const disabledDays = useMemo(() => {
    const days = filterDisabledDays(daysData);

    return (day) =>
      days.some((disabledDay) => DateUtils.isSameDay(day, disabledDay));
  }, [daysData]);

  const busyDays = useMemo(() => {
    const days = filterBusyDays(daysData);

    return (day) => days.some((busyDay) => DateUtils.isSameDay(day, busyDay));
  }, [daysData]);

  const handleDayClick = useCallback(
    (day, modifiers) => {
      if (modifiers?.disabled) {
        return;
      }

      if (from && !to) {
        const tempPeriod = { from, to: day };

        if (tempPeriod.from > tempPeriod.to) {
          onChange({ from: tempPeriod.to, to: tempPeriod.from });
        } else {
          onChange(tempPeriod);
        }
      } else {
        onChange({ from: day, to: undefined });
      }
    },
    [from, to, onChange]
  );

  const selectedDays = useMemo(() => [from, { from, to }], [from, to]);

  const renderDayWithData = useCallback(
    (day) => (
      <div className={classes.daySquare}>
        <div className={classes.dayBox}>
          <div className={classes.dateLabel}>
            {day.getDate()}
          </div>
          {renderDay(day, daysData)}
        </div>
      </div>
    ),
    [daysData, renderDay]
  );

  const classNames = {
    ...classes,
    navButtonPrev: isDisabledPrevDates ? classes.navButtonPrevDisabled : classes.navButtonPrev
  };
  return (
    <DayPicker
      classNames={classNames}
      className={classes[`months${numberOfMonths}`]}
      numberOfMonths={numberOfMonths}
      firstDayOfWeek={0}
      weekdaysShort={WEEKDAYS_NAMES}
      onDayClick={handleDayClick}
      selectedDays={selectedDays}
      disabledDays={[disabledDays, extraDisabledDays]}
      modifiers={{
        [extraClasses.disabledPrevDates]: isDisabledPrevDates ? [disabledDays, extraDisabledDays] : [],
        [classes.busy]: busyDays,
        [classes.firstDay]: (day) => {
          return day.getDate() === 1;
        },
        [classes.lastDay]: (day) => {
          const nextDay = new Date(day);

          nextDay.setDate(nextDay.getDate() + 1);

          return nextDay.getDate() === 1;
        }
      }}
      renderDay={renderDayWithData}
      {...dayPickerProps}
    />
  );
};

BigDayPicker.defaultProps = {
  availability: [],
  filterDisabledDays: filterDisabledDaysFn,
  filterBusyDays: filterDisabledDaysFn
};

BigDayPicker.propTypes = {
  availability: PropTypes.array,
  value: PropTypes.shape({
    from: PropTypes.oneOfType([
      // PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    to: PropTypes.oneOfType([
      // PropTypes.string,
      PropTypes.instanceOf(Date)
    ])
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  renderDay: PropTypes.func.isRequired,
  filterDisabledDays: PropTypes.func,
  filterBusyDays: PropTypes.func
};
