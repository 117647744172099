import React from "react";
import PropTypes from "prop-types";
import { FILTERS } from "../../filters";
import NumberRangePicker from "../../../form/NumberRangePicker/NumberRangePicker";
import HeaderFilterButton from "./HeaderFilterButton";
import classes from "./FilterLength.module.css";

const FilterLength = (props) => {
  const { min, max } = props;

  return (
    <HeaderFilterButton
      id={FILTERS.ids.length}
      popperClassName={classes.popper}
      getLabelFromValue={value => (
        value.start || value.end
          ? `${value.start || "0"}-${value.end || "?"} ft`
          : FILTERS.labels.length
      )}
      renderForm={({ value, onChange }) => (
        <NumberRangePicker
          className="mt-20"
          startInputClassName={classes.startInput}
          endInputClassName={classes.endInput}
          sliderClassName="rc-slider-track__dark"
          id="length"
          startLabel="Min. ft"
          endLabel="Max. ft"
          min={min}
          max={max}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

FilterLength.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number
};

export default FilterLength;
