import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import VehicleCard, { VehicleCardSizeType, VehicleCardVariantType } from "../../../vehicles/VehicleCard";
import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";

/**
 * VehicleCard Search API Bridge
 */
const VehicleCardBridge = (props) => {
  const {
    photoUrlPrefix,
    linkPrefix,
    item,
    itemIndex,
    isInFavorites,
    onChangeFavorite,
    userId,
    ...other
  } = props;

  const onClickCardLink = useCallback(() => {
    pushSegmentAnalyticsTrack("Product Clicked", {
      location: String(item?.lc?.replace('Delivered from ', '')),
      asset_make: String(item.mk),
      asset_model: String(item.md),
      asset_year: String(item.yr),
      asset_length: Number(item.le) * 12,
      asset_sleepnumber: Number(item.sl),
      asset_class: String(item.cl),
      asset_id: String(item.id),
      asset_price: Number(item.pr),
      asset_brand: item.mr,
      asset_slideouts: Number(item.so),
      cwh_business: 'RV Rentals',
      fbp: "",
      fbc: ""
    });
  }, [item]);

  return (
    <VehicleCard
      {...other}
      imageUrl={`${photoUrlPrefix}${item.ph}`}
      link={`${linkPrefix}${item.ur}`}
      linkTarget="_blank"
      id={item.id}
      title={item.ti}
      name={item.nm}
      price={item.pr}
      deliveredFrom={item.lc}
      rvClass={item.cl}
      miles={item.ml}
      length={item.le}
      sleeps={item.sl}
      slideOuts={item.so}
      instantRentals={item.ir}
      rentalType={item.rt}
      isFavorite={isInFavorites(item.id)}
      onClickCardLink={onClickCardLink}
      onClickFavorite={onChangeFavorite}
      userId={userId}
    />
  );
};

VehicleCardBridge.propTypes = {
  photoUrlPrefix: PropTypes.string.isRequired,
  linkPrefix: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  isInFavorites: PropTypes.func.isRequired,
  onChangeFavorite: PropTypes.func.isRequired,
  // Other from VehicleCard:
  className: PropTypes.string,
  variant: VehicleCardVariantType,
  size: VehicleCardSizeType,
  onClose: PropTypes.func,
  pageIndex: PropTypes.number,
  totalPages: PropTypes.number,
  onClickPrevious: PropTypes.func,
  onClickNext: PropTypes.func
};

export default memo(VehicleCardBridge);
