import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { formatCents } from "utils/convertCents";
import { formatInteger } from "../../utils/formatNumber";

const FormattedPrice = (props) => {
  const { className, value, isNegative, isBold, isSubBold } = props;

  if (!value) {
    return null;
  }

  const dollarsClassName = classnames(
    isBold && "fw-700",
    isSubBold && "fw-500",
  );

  return (
    <span className={className}>
      {isNegative && <>&minus;&nbsp;</>}
      <span
        className={dollarsClassName}
      >
        {`$${formatInteger(value.dollars_as_part)}.`}
      </span>
      {formatCents(value.cents_as_part)}
    </span>
  );
};

FormattedPrice.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    dollars_as_part: PropTypes.number,
    cents_as_part: PropTypes.number
  }),
  isBold: PropTypes.bool,
  isNegative: PropTypes.bool
};

export default FormattedPrice;
