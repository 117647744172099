import { gql } from "@apollo/client";
import { baseFragments } from "../../../queries/fragments";

export const userQuery = gql`
  ${baseFragments.paymentInfo},
  ${baseFragments.bankAccount}
  
  query {
    user {
      id
      email
      email_verified
      phone
      phone_verified
      password_exists
      password_last_updated
      needs_moderation
      has_payment
      good_sam_membership_validation {
        key
        value
      }
      bank_account {
        ...BankAccount
      }
      payment_info {
        ...PaymentInfo
      }
      notifications {
        id
        type
        checked
      }
      driver_verification {
        key
        value
      }
    }
  }
`;
