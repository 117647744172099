import React, { useState, useCallback } from "react";
import cn from 'classnames';
import { useForm, Controller } from "react-hook-form";
import Typography from "components/Typography";
import { Datepicker } from "components/form";
import Checkbox from "components/form/Checkbox";
import { FieldTextarea } from "components/form/FieldTextarea";
import { FieldInput } from "components/form/FieldInput";
import { calendarIcon } from "components/Icon";
import RentalFeeModal from "processes/Rentals/RentalChange/components/RentalFeeModal";
import DeliveryFeeModal from "processes/Rentals/RentalChange/components/DeliveryFeeModal";
import { RE_DATE_FORMAT, RE_EMAIL_FORMAT } from "utils/constants/re";
import { PriceForm } from "components/PriceForm";
import InsurancePlan from 'processes/PreDefinedRental/components/InsurancePlan/InsurancePlan';
import { ACTION_IDS } from "constants/priceForm";
import classes from './SummaryTable.module.css';

const SummaryTable = ({
  state,
  insuranceData,
  expiration_date,
  onSelectFee,
  onChangeForm
}) => {
  const [showRentalFeeModal, setShowRentalFeeModal] = useState(false);
  const [showDeliveryFeeModal, setShowDeliveryFeeModal] = useState(false);
  const [showBookingInsuranceModal, setShowBookingInsuranceModal] = useState(false);

  const {
    control,
    formState: { errors },
    getValues
  } = useForm({ mode: "onChange", defaultValues: { expiration_date, message: "" } });

  const handleChange = useCallback(() => { onChangeForm(getValues()); }, [getValues, onChangeForm]);

  const openRentalFeeModal = () => { setShowRentalFeeModal(true); };

  const closeRentalFeeModal = () => { setShowRentalFeeModal(false); };

  const onSubmitRentalFee = useCallback((value) => {
    onSelectFee({ rental_fee: value, delivery_fee: state.delivery_fee });
  }, [onSelectFee, state.delivery_fee]);

  const openDeliveryFeeModal = () => { setShowDeliveryFeeModal(true); };

  const closeDeliveryFeeModal = () => { setShowDeliveryFeeModal(false); };

  const onSubmitDeliveryFee = useCallback((value) => {
    onSelectFee({ delivery_fee: value, rental_fee: state.rental_fee });
  }, [onSelectFee, state.rental_fee]);

  const onPriceFormAction = useCallback((id) => {
    switch (id) {
    case ACTION_IDS.BOOKING_PROTECTION_CHANGE:
      setShowBookingInsuranceModal(true);
      break;
    case ACTION_IDS.ADJUST_RENTAL_FEE:
      openRentalFeeModal(true);
      break;
    case ACTION_IDS.ADJUST_DELIVERY_FEE:
      openDeliveryFeeModal(true);
      break;
    default:
      break;
    }
  }, []);

  return (
    <>
      <form className={classes.wrapper}>
        {state?.price?.price_form_detailed && (
          <PriceForm
            data={[state?.price?.price_form_detailed]}
            onAction={onPriceFormAction}
          />
        )}

        <div class="t-16 t-m-20">
          <Typography
            component="p"
            variant="subtitle"
            size="m"
          >
            Any details or comments about your suggestion? Contact he renter using the form below.
          </Typography>
        </div>

        <div className="row">
          <div className="col-12 col-m-6 t-16 b-16 t-m-20 b-m-20">
            <Controller
              name="renter_email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: {
                  value: RE_EMAIL_FORMAT,
                  message: "Please enter a valid email"
                }
              }}
              render={({ onChange, ...renderProps }) => (
                <FieldInput
                  onChange={(e) => { onChange(e); handleChange(); }}
                  {...renderProps}
                  errors={errors}
                  label="Renter’s email"
                  className={classes.input}
                />
              )}
            />

          </div>

          <div className="col-12 col-m-6 t-16 b-16 t-m-20 b-m-20">
            <Controller
              name="expiration_date"
              control={control}
              defaultValue={expiration_date}
              rules={{
                pattern: {
                  value: RE_DATE_FORMAT,
                  message: "Please enter a valid date"
                }
              }}
              render={({ onChange, ...renderProps }) => (
                <Datepicker
                  onChange={(e) => { onChange(e); handleChange(); }}
                  {...renderProps}
                  errors={errors}
                  label="Expiration date"
                  className={classes.input}
                  icon={calendarIcon}
                  placeholder="MM/DD/YYYY"
                />
              )}
            />
          </div>
        </div>

        <div class="t-16 t-m-20 b-36 b-m-8">
          <Controller
            name="message"
            control={control}
            render={({ onChange, ...renderProps }) => (
              <FieldTextarea
                {...renderProps}
                onChange={(e) => { onChange(e); handleChange(); }}
                className={cn(classes.textarea, 'fw')}
                label="Your message"
                maxLength={500}
                helperMessage="(Optional)"
              />
            )}
          />

        </div>

        <div class="t-8 b-8 hidden-block-m">
          <Checkbox
            id="storybook-checkbox-full"
            text="Block these days to other renters"
            disabled
            defaultChecked={state.block_days}
          />
        </div>
      </form>

      <RentalFeeModal
        show={showRentalFeeModal}
        onClose={closeRentalFeeModal}
        onSubmit={onSubmitRentalFee}
      />

      <DeliveryFeeModal
        show={showDeliveryFeeModal}
        onClose={closeDeliveryFeeModal}
        onSubmit={onSubmitDeliveryFee}
      />

      <InsurancePlan
        showBookingInsuranceModal={showBookingInsuranceModal}
        setShowBookingInsuranceModal={setShowBookingInsuranceModal}
        {...insuranceData}
      />
    </>
  );
};

export default SummaryTable;
