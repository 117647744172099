import React, { useState, useCallback, useEffect } from "react";
import { Controller } from "react-hook-form";
import { AutocompleteLocationAddress }from "components/Listing/steps/LocationDelivery/components/AutocompleteLocationAddress/AutocompleteLocationAddress";
import { GoogleMapsProvider } from "components//GoogleMapsProvider";
import MenuPipe from "components/MenuPipe/MenuPipe";
import { LocationRentalTab } from "components/Listing/steps/LocationDelivery/components/LocationRentalTabs/LocationRentalTab";
import scrollIntoView from "utils/scrollIntoView";

const menuData = [
  {
    id: 'standard-rental',
    title: 'Standard rental'
  },
  {
    id: 'delivery-only-rental',
    title: 'Delivery only rental'
  }
];

export const LocationRentalTabs = (props) => {
  const {
    control,
    control: { setValue, trigger, getValues },
    errors,
    clearErrors,
    watch,
    initialData
  } = props;

  const { locationRentalTab, location, tabs } = watch();
  const {
    specificDeliveryLocations
  } = tabs[locationRentalTab];

  const [tab, setTab] = useState({ tabIndex: locationRentalTab });

  useEffect(() => {
    const deliveryOption1 = getValues("tabs[0].delivery_option");
    const deliveryOption2 = getValues("tabs[1].delivery_option");

    const specific_destinations1 = getValues("tabs[0].specific_destinations");
    const specific_destinations2 = getValues("tabs[1].specific_destinations");

    if (location.full_address === '' && (deliveryOption1 || deliveryOption2 || specific_destinations1 || specific_destinations2)) {
      setValue("tabs[0].delivery_option", false);
      setValue("tabs[1].delivery_option", false);
      setValue("tabs[0].specific_destinations", false);
      setValue("tabs[1].specific_destinations", false);
    }
  }, [getValues, location, setValue]);

  const validateTab = useCallback(async(_, tabIndx) => {
    const fields = [
      "location",
      `tabs[${locationRentalTab}].flat_delivery_price`,
      `tabs[${locationRentalTab}].cost_per_mile_value`,
      `tabs[${locationRentalTab}].paid_delivery`,
      `tabs[${locationRentalTab}].minimum_fee`
    ];
    if (
      specificDeliveryLocations
      && Array.isArray(specificDeliveryLocations)
      && specificDeliveryLocations?.length !== 0
    ) {
      specificDeliveryLocations.forEach((_, index) => {
        fields.push(`tabs[${locationRentalTab}].specificDeliveryLocations[${index}].delivery_fee`);
        fields.push(`tabs[${locationRentalTab}].specificDeliveryLocations[${index}].location`);
      });
    }
    const result = await trigger(fields, { shouldFocus: true });

    if (result) {
      setValue('locationRentalTab', tabIndx);
      setTab({ tabIndex: tabIndx });
    } else {
      scrollIntoView(".field-input.error");
    }
  }, [locationRentalTab, setValue, specificDeliveryLocations, trigger]);

  const locationRentalTabs = initialData?.tabs.map((data, tabIndex) => {
    return (
      <div key={'tab' + data?.id} className={locationRentalTab !== tabIndex ? "dn" : ""}>
        <LocationRentalTab
          key={'tab' + data?.id}
          tabIndex={tabIndex}
          data={data}
          dataKey="tabs"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          watch={watch}
        />
      </div>
    );
  });

  return  (
    <>
      <div>

        <div className="mt-m-16" >
          <Controller
            name="location"
            control={control}
            rules={{
              required: "Location is required",
              validate: (v) => {
                if (!v?.full_address) {
                  return "Please enter the address of your RV's location. This address will not be available for other users.";
                }
              }
            }}
            render={(renderProps) => (
              <GoogleMapsProvider>
                <AutocompleteLocationAddress
                  label={"RV location"}
                  hasError={Boolean(errors?.location)}
                  defaultValue={location?.full_address}
                  helperMessage={errors?.location?.message}
                  {...renderProps}
                  onChange={(e) => {
                    renderProps.onChange(e);
                    clearErrors(`location`);
                  }}
                  onChangeValue={(e) => {
                    if (!e) {
                      setValue(`location`, {
                        full_address: "",
                        latitude: "",
                        longitude: ""
                      });
                    } else {
                      const locationField = getValues("location");
                      setValue(`location`, {
                        full_address: e,
                        latitude: locationField.latitude,
                        longitude: locationField.longitude
                      });
                    }
                  }}
                />
              </GoogleMapsProvider>
            )}
          />
        </div>

        <div className="row mt-36 mt-m-32 allowSwitch-0 allowSwitch-1">
          <div className="col df">
            <Controller
              name="locationRentalTab"
              control={control}
              render={() => (
                <MenuPipe
                  data={menuData}
                  tab={tab}
                  isOuterTabControl={true}
                  onClickItem={validateTab}
                />
              )}
            />
          </div>
        </div>

        <div className="row mt-16">
          <div className="col">
            {locationRentalTabs}
          </div>
        </div>

      </div>
    </>
  );
};
