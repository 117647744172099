import React, { useRef } from "react";
import classnames from "classnames";
import { Header } from "./components/Header/Header";
import List from "./components/List/List";
import Map from "./components/Map/Map";
import { useSearchContext } from "./useSearchContext";
import MobileFilters from "./components/MobileFilters/MobileFilters";
import { useFooterVisibilityProcessing } from "./hooks";
import Spinner from "../Spinner/Spinner";
import { useGoogleMaps } from "../GoogleMapsProvider";
import PageError from "../PageError/PageError";
import { useMapSize } from "components/Search/components/Map/hooks/useMapSize";
import classes from "./Search.module.css";

export const Search = () => {
  const mapContainer = useRef();
  const {
    error,
    loading,
    mapOpen,
    isMobileFilters
  } = useSearchContext();
  const { isLoaded: isMapLoaded } = useGoogleMaps();

  useFooterVisibilityProcessing(mapOpen);

  useMapSize(mapContainer);

  if (loading || !isMapLoaded) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner position="absolute" />
      </div>
    );
  }

  if (error) {
    return (
      <PageError error={error} />
    );
  }

  return (
    <div
      className={classnames(
        classes.wrapper,
        mapOpen ? classes.isMapOpen : classes.isMapClosed
      )}
    >
      <div
        ref={mapContainer}
        className={classnames(
          classes.map,
          mapOpen ? classes.isMapOpen : classes.isMapClosed
        )}
        data-testid="SearchMap"
      >
        <div className={classes.mapInner}>
          <Map withControls />
        </div>
      </div>

      <div
        className={classes.root}
      >
        <div className={classes.content}>
          <Header />
          <List className={classes.list} />
        </div>

        {isMobileFilters && (
          <MobileFilters />
        )}
      </div>
    </div>
  );
};
