import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { ProfileImg } from "../ProfileImg/ProfileImg";
import Typography from "../../../Typography";
import { MatchMedia } from "../../../MatchMedia";
import HeaderDetailsModal from "./HeaderDetailsModal";
import HeaderDetailsToggle from "./HeaderDetailsToggle";
import HeaderNavToggle from "./HeaderNavToggle";
import style from "./style.module.css";
import HeaderDetailsLoader from "./HeaderDetailsLoader";

const DEFAULT_VALUES = {
  listing_title: "Add your RV"
};

export const Header = ({
  initialData,
  isMobileNavMode,
  setIsMobileNavMode,
  isListingPublished,
  isRvCreated,
  detailsModalActive,
  setDetailsModalActive
}) => {
  const handleShowMobileNav = useCallback(() => {
    setIsMobileNavMode(true);
  }, [setIsMobileNavMode]);

  const handleShowDetailsModal = () => {
    setDetailsModalActive(true);
  };

  const getListingTitle = () => {
    if (initialData.year && initialData.make && initialData.model) {
      return `${initialData.year} ${initialData.make} ${initialData.model}`;
    }

    return DEFAULT_VALUES.listing_title;
  };

  return (
    <header className="container">
      <div
        className={classnames(
          "row",
          style.header,
          isListingPublished && style.header_details
        )}
      >
        <div
          className={`${style.header__content} col-12 col-m-8 col-xl-6 ml-m-32 ml-xl-64 mb-xl-20 mb-xxl-32 fsc`}
        >
          {!isMobileNavMode && isRvCreated && (
            <HeaderNavToggle onToggle={handleShowMobileNav}>
              {isListingPublished ? "Back to vehicle" : "View all steps"}
            </HeaderNavToggle>
          )}
          {isListingPublished && !isMobileNavMode && (
            <MatchMedia mediaQuery="(max-width: 767px)">
              <HeaderDetailsToggle onToggle={handleShowDetailsModal} />
            </MatchMedia>
          )}
          <Typography component="h1" variant="headline" size="l">
            {getListingTitle()}
          </Typography>
          {isListingPublished && (
            <>
              <MatchMedia mediaQuery="(min-width: 768px)">
                <HeaderDetailsLoader data={initialData} />
              </MatchMedia>
              <MatchMedia mediaQuery="(max-width: 768px)">
                <HeaderDetailsModal
                  title="RV Statistic"
                  show={detailsModalActive}
                  setShow={setDetailsModalActive}
                >
                  <HeaderDetailsLoader data={initialData} />
                </HeaderDetailsModal>
              </MatchMedia>
            </>
          )}
        </div>
        <div className="col-12 col-m-2-wide no-gutter">
          <div className="t-16 b-16 t-m-20 b-m-20 dn db-m _visible-all-steps-view">
            <ProfileImg
              image={initialData.images ? initialData.images[0] : null}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  initialData: PropTypes.object.isRequired,
  isMobileNavMode: PropTypes.bool.isRequired,
  setIsMobileNavMode: PropTypes.func.isRequired,
  isListingPublished: PropTypes.bool
};

Header.defaultProps = {
  isMobileNavMode: true,
  isListingPublished: false
};
