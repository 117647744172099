import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  General,
  GoodSam,
  Referrals,
  Payout,
  Payment,
  Notifications,
  Profile,
  DriverVerification,
  TransactionHistory
} from "../../steps";
import { WithScrollToTop } from "components/ScrollToTop/ScrollToTop";

export const StepsRouter = ({ has_payment }) => {
  return (
    <WithScrollToTop>
      <Switch>
        <Route exact path="/account/general-settings" component={General} />
        <Route exact path="/account/my-profile" component={Profile} />
        <Route exact path="/account/notifications" component={Notifications} />
        <Route exact path="/account/good-sam-membership" component={GoodSam} />
        <Route exact path="/account/my-referrals" component={Referrals} />
        <Route exact path="/account/payment" component={Payment} />
        <Route exact path="/account/payout" component={Payout} />
        <Route exact path="/account/driver-verification" component={DriverVerification} />
        { has_payment && <Route exact path="/account/transaction-history" component={TransactionHistory} />}
        <Redirect to={{ pathname: "/account/general-settings", state: { menu: true } }}/>
      </Switch>
    </WithScrollToTop>
  );
};

StepsRouter.propTypes = {
  isMobileNavMode: PropTypes.bool
};
