import React, { useCallback, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { extractHtml } from "utils/exctractHtml";
import { extractText } from "utils/extractText";
import { extractBoolean } from "utils/extractBoolean";
import { extractField } from "utils/extractField";
import {
  readStorage,
  writeStorage
} from "processes/Rentals/RentalList/storage";
import PreDefinedRental from "processes/PreDefinedRental/PreDefinedRental";
import Typography from "components/Typography";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Button from "components/Button";
import { filtersIcon, addFileIcon } from "components/Icon";
import VerificationStepsModals from "components/VerificationStepsModals/VerificationStepsModals";
import { Select } from "../../../components/form";
import { createSort, SORT_TYPES } from "../../../utils/sort";
import { contentQuery } from "../../../queries";
import { listQuery } from "./queries/listQuery";
import List from "./components/List/List";
import Filters from "./components/Filters/Filters";
import FiltersModal from "./components/Filters/FiltersModal";
import { SpinnerBlur } from "components/Spinner/Spinner";
import { filterList } from "./filters";

import classes from "./RentalList.module.css";

const SORT_IDS = {
  date_latest: "date_latest",
  date_oldest: "date_oldest"
};

export const RentalList = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [filters, setFilters] = useState(readStorage());
  const [showPreDefinedRentalModal, setShowPreDefinedRentalModal] =
    useState(false);

  const isMinLargeScreen = useMatchMedia({ minWidth: MQ_BP.large });
  const isMinSmallScreen = useMatchMedia({ minWidth: MQ_BP.medium });
  const isMaxSmallScreen = useMatchMedia({ maxWidth: MQ_BP.small });

  const { loading, data } = useQuery(listQuery);

  const statuses = useMemo(
    () => data?.all_rental_statuses || [],
    [data?.all_rental_statuses]
  );

  const [preDefinedRentalsContent, setPreDefinedRentalsContent] = useState({
    enabled: false,
    buttonText: "",
    title: "",
    description: ""
  });

  const { loading: preDefinedRentalsLoading, data: preDefinedRentalsData } =
    useQuery(contentQuery, {
      fetchPolicy: "no-cache",
      variables: {
        section: "pre_defined_rentals"
      }
    });

  useEffect(() => {
    if (!preDefinedRentalsLoading && preDefinedRentalsData?.["content"]?.text) {
      setPreDefinedRentalsContent({
        enabled: extractBoolean(
          preDefinedRentalsData["content"].text,
          "build_a_rental_button"
        ),
        buttonText: extractText(
          preDefinedRentalsData["content"].text,
          "build_a_rental_button_text"
        ),
        title: extractText(
          preDefinedRentalsData["content"].text,
          "pro_tips_section_title"
        ),
        description: extractHtml(
          preDefinedRentalsData["content"].text,
          "pro_tips_section_text"
        ),
        expiration_date: extractField(
          preDefinedRentalsData["content"].form,
          "date_expiration"
        )
      });
    }
  }, [preDefinedRentalsData, preDefinedRentalsLoading]);

  const SORT = useMemo(() => {
    const prefix = isMaxSmallScreen ? "" : "Rental ";
    return createSort(
      [
        {
          id: SORT_IDS.date_latest,
          text: [prefix, "start date - Latest"].join(""),
          type: SORT_TYPES.desc,
          itemProp: "_startTimestamp"
        },
        {
          id: SORT_IDS.date_oldest,
          text: [prefix, "start date - Oldest"].join(""),
          type: SORT_TYPES.asc,
          itemProp: "_startTimestamp"
        }
      ],
      1
    );
  }, [isMaxSmallScreen]);

  const [sort, setSort] = useState(SORT.defaultValue);

  const list = useMemo(() => {
    if (!data?.my_rentals) {
      return [];
    } else {
      return data?.my_rentals.map((i) => ({
        ...i,
        _startTimestamp: moment(i.departure_date, "YYYY-MM-DD").unix()
      }));
    }
  }, [data?.my_rentals]);

  const onChangeFilter = useCallback(
    ({ id, checked }) => {
      const updatedFitlers = { ...filters, [id]: checked };
      setFilters(updatedFitlers);
      writeStorage(updatedFitlers);
    },
    [filters]
  );

  const sortedList = useMemo(() => {
    return SORT.sortList(list, sort);
  }, [SORT, list, sort]);

  const resultList = useMemo(() => {
    return filterList(sortedList, filters, statuses);
  }, [sortedList, filters, statuses]);

  useEffect(() => {
    setMobileFiltersOpen(false);
  }, [isMinLargeScreen]);

  const preDefinedRentalModalOpen = () => {
    setShowPreDefinedRentalModal(true);
  };

  const preDefinedRentalModalClose = () => {
    setShowPreDefinedRentalModal(false);
  };

  if (loading) {
    return <SpinnerBlur />;
  }

  return (
    <>
      <div
        className={classnames(
          classes.root,
          "container t-40 t-m-60 b-40 b-m-60 b-xl-120"
        )}
      >
        {!isMinLargeScreen && (
          <FiltersModal
            open={mobileFiltersOpen}
            onClose={() => setMobileFiltersOpen(false)}
            initialValues={filters}
            onSave={setFilters}
            statuses={statuses}
          />
        )}
        <header className={classnames(classes.header, "df fbc")}>
          <Typography component="h1" variant="headline" size="l">
            My rentals
          </Typography>
          <div
            className={classnames(
              "fsc t-12 b-16 t-m-20 b-m-20",
              classes.actions
            )}
          >
            {!isMinLargeScreen && (
              <Button
                secondary
                icon={filtersIcon}
                iconPosition="before"
                size={isMinSmallScreen ? "medium" : ""}
                label={isMinSmallScreen ? "Filter" : undefined}
                onClick={() => setMobileFiltersOpen(true)}
              />
            )}

            <div className="r-16 r-l-24" />

            {preDefinedRentalsContent.enabled && (
              <div
                className={classnames(
                  "r-0 r-s-16 r-l-24 r-xl-32 t-32 t-m-0",
                  classes.buildRental
                )}
              >
                <Button
                  secondary
                  icon={addFileIcon}
                  iconPosition="before"
                  label={preDefinedRentalsContent.buttonText}
                  onClick={preDefinedRentalModalOpen}
                  size="medium"
                />
              </div>
            )}

            <Select
              key={isMaxSmallScreen} // To reset options
              className={classes.sortSelect}
              id="sort"
              label="Sort by"
              options={SORT.options}
              value={sort}
              onChange={setSort}
            />
          </div>
        </header>
        <div className="df">
          {isMinLargeScreen && (
            <div className={classes.filters}>
              <Filters
                statuses={statuses}
                values={filters}
                onChange={onChangeFilter}
              />
            </div>
          )}
          <List className={classes.list} items={resultList} />
        </div>
      </div>

      {showPreDefinedRentalModal && (
        <VerificationStepsModals onClose={preDefinedRentalModalClose}>
          {() => (
            <PreDefinedRental
              content={preDefinedRentalsContent}
              show={showPreDefinedRentalModal}
              onClose={preDefinedRentalModalClose}
            />
          )}
        </VerificationStepsModals>
      )}
    </>
  );
};

export default RentalList;
