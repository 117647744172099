import React, { useState, useCallback } from "react";
import Coupon from "processes/Booking/components/BookingModal/steps/BookingPaymentOptions/BookingPaymentOptionsTable/components/Coupon";
import { useBookingModalContext } from "processes/Booking/components/BookingModal/BookingModalContext";
import InsurancePlan from "processes/Booking/components/BookingModal/steps/BookingPaymentOptions/BookingPaymentOptionsTable/components/InsurancePlan";
import BecomeGoodSamMember from "processes/Booking/components/BookingModal/steps/BookingPaymentOptions/BookingPaymentOptionsTable/components/BecomeGoodSamMember";
import RentalInterruptionInsurance from "processes/Booking/components/BookingModal/steps/BookingPaymentOptions/BookingPaymentOptionsTable/components/RentalInterruptionInsurance";
import { PriceForm } from "components/PriceForm";
import { CUSTOM_RENDER_IDS, ACTION_IDS } from "constants/priceForm";
import {
  removeExtraInsuranceIdFromSelectPackages
} from "components/AdditionalInsuranceList/utils";
import { STEPS } from "processes/Booking/components/BookingModal/steps";

const BookingPaymentOptionsTable = ({ prices, price_form_detailed }) => {
  const { setCoupon, setMembershipId, setIsInterruptionVisible, setIsNoSplitPayments, setInsuranceId, onClickStepById, selectedPackagesAdditionalInsurance, setSelectedPackagesAdditionalInsurance, setIsClearTii } = useBookingModalContext();
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showBookingInsuranceModal, setShowBookingInsuranceModal] = useState(false);

  const handleSpecRender = useCallback(({ type = null }) => {
    if (type === CUSTOM_RENDER_IDS.BOOKING_PAYMENT_GS_MEMBER) {
      return <BecomeGoodSamMember price={prices?.good_sam_membership_discount_plan} />;
    }

    return false;

  }, [prices?.good_sam_membership_discount_plan]);

  const onPriceFormAction = useCallback((id) => {
    switch (id) {
    case ACTION_IDS.BOOKING_APPLY_COUPON:
      setShowCouponModal(true);
      break;
    case ACTION_IDS.BOOKING_DISCARD_COUPON:
      setCoupon(null);
      break;
    case ACTION_IDS.BOOKING_PROTECTION_CHANGE:
      setShowBookingInsuranceModal(true);
      break;
    case ACTION_IDS.BOOKING_REMOVE_GS_MEMBER:
      setMembershipId(null);
      break;
    case ACTION_IDS.BOOKING_REMOVE_TII:
      setIsInterruptionVisible(undefined);
      setSelectedPackagesAdditionalInsurance(removeExtraInsuranceIdFromSelectPackages(selectedPackagesAdditionalInsurance));
      setIsClearTii(true);
      break;
    case ACTION_IDS.NO_SPLIT_PAYMENTS:
      setIsNoSplitPayments(true);
      break;
    case ACTION_IDS.SPLIT_PAYMENTS:
      setIsNoSplitPayments(false);
      break;
    case ACTION_IDS.ADDITIONAL_INSURANCE_CHANGE:
      onClickStepById(STEPS.ADDITIONAL_INSURANCE.ID);
      break;
    default:
      break;
    }
  }, [setCoupon, setMembershipId, setIsInterruptionVisible, setSelectedPackagesAdditionalInsurance, selectedPackagesAdditionalInsurance, setIsClearTii, setIsNoSplitPayments, onClickStepById]);

  return (
    <div>
      <PriceForm
        data={[price_form_detailed]}
        specRender={handleSpecRender}
        onAction={onPriceFormAction}
      />

      <Coupon
        showCouponModal={showCouponModal}
        setShowCouponModal={setShowCouponModal}
      />

      <InsurancePlan
        showBookingInsuranceModal={showBookingInsuranceModal}
        setShowBookingInsuranceModal={setShowBookingInsuranceModal}
      />
    </div>
  );
};

export default BookingPaymentOptionsTable;
