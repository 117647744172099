import React, { forwardRef } from "react";
import { GoogleMapsProvider } from "../../../../../GoogleMapsProvider";
import { MapLocationDeliveryLoader } from "./MapLocationDeliveryLoader";

const libraries = ["places"];

export const MapLocationDelivery = forwardRef((props, ref) => (
  <GoogleMapsProvider libraries={libraries}>
    <MapLocationDeliveryLoader ref={ref} {...props} />
  </GoogleMapsProvider>
));
