const EARTH_RADIUS_METERS = 6371e3;

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

/**
 * Returns distance between 2 points in meters.
 *
 * This uses the ‘haversine’ formula to calculate the great-circle distance
 * between two points – that is, the shortest distance over the earth’s surface
 * – giving an ‘as-the-crow-flies’ distance between the points.
 * @see https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @returns {number}
 */
export const calcDistanceBetweenPoints = (lat1, lng1, lat2, lng2) => {
  if ([lat1, lng1, lat2, lng2].some(i => !Number.isFinite(i))) {
    return null;
  }

  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lng2 - lng1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS_METERS * c;
};

/**
 * @returns {boolean}
 */
export const isPointInRadius = (lat1, lng1, lat2, lng2, radiusMeters) => {
  if ([lat1, lng1, lat2, lng2, radiusMeters].some(i => !Number.isFinite(i))) {
    return false;
  }
  return calcDistanceBetweenPoints(lat1, lng1, lat2, lng2) <= radiusMeters;
};
