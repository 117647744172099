import React from "react";
import { Route, Switch } from "react-router-dom";
import { LoginRouter, PasswordRecoveryRouter } from "./LoginRouter";
import { ListingRouter } from "./ListingRouter";
import { MyAccountRouter } from "./MyAccountRouter";
import { SearchRouter } from "./SearchRouter";
import { RentalsRouter } from "./RentalsRouter";
import { withAuthCheck } from "./withAuthCheck";
import { useLoginRedirect } from "./useLoginRedirect";
import { RvProfileRouter } from "./RvProfileRouter";
import { EmailVerification } from "components/EmailVerification/EmailVerification";
import { PreDefinedStatus } from "processes/PreDefinedRental/components/PreDefinedStatus/PreDefinedStatus";

/* TODO: this test routes: */
import { PublicProfileRouter } from "./PublicProfileRouter";
import { PublicProfileWithOneCard } from "processes/PublicProfile/testPages/PublicProfileWithOneCard";
import { PublicProfileWithSeveralCard } from "processes/PublicProfile/testPages/PublicProfileWithSeveralCard";
import { PublicProfileEmpty } from "processes/PublicProfile/testPages/PublicProfileEmpty";
import { PublicProfileEmptyCards } from "processes/PublicProfile/testPages/PublicProfileEmptyCards";

import { PublicProfileWithOneCard2 } from "processes/PublicProfile/testPages/PublicProfileWithOneCard2";
import { PublicProfileWithSeveralCard2 } from "processes/PublicProfile/testPages/PublicProfileWithSeveralCard2";
import { PublicProfileEmpty2 } from "processes/PublicProfile/testPages/PublicProfileEmpty2";

// import TempMapRouter from "./TempMap";
// import { SignUpRouter } from "./SignUpRouter";

const PrivateRoute = withAuthCheck(Route);

export const AppRouterSwitch = () => {
  useLoginRedirect();

  return (
    <Switch>
      {/*<Route path="/temp-map" component={TempMapRouter} />*/}
      {/* <Route path="/signup" component={SignUpRouter} /> */}
      <Route path="/search" component={SearchRouter} />
      <Route path="/login" component={LoginRouter} />
      <Route path="/password-recovery" component={PasswordRecoveryRouter} />
      <Route path="/email-successful-verification" component={EmailVerification} />
      <Route path="/pre-defined-status" component={PreDefinedStatus} />
      <PrivateRoute path="/listing" component={ListingRouter} />
      <PrivateRoute path="/account" component={MyAccountRouter} />
      <PrivateRoute path="/rentals" component={RentalsRouter} />
      <PrivateRoute path="/public-profile/with-one-card-2" component={PublicProfileWithOneCard2} />
      <PrivateRoute path="/public-profile/with-several-card-2" component={PublicProfileWithSeveralCard2} />
      <PrivateRoute path="/public-profile/empty-2" component={PublicProfileEmpty2} />
      <PrivateRoute path="/public-profile/empty-cards" component={PublicProfileEmptyCards} />
      <PrivateRoute path="/public-profile/empty" component={PublicProfileEmpty} />
      <PrivateRoute path="/public-profile/with-one-card" component={PublicProfileWithOneCard} />
      <PrivateRoute path="/public-profile/with-several-card" component={PublicProfileWithSeveralCard} />
      <PrivateRoute path="/public-profile" component={PublicProfileRouter} />
      <PrivateRoute path="/rv-profile" component={RvProfileRouter} />
    </Switch>
  );
};
