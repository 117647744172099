import React, { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import FieldInput from "../../../form/FieldInput";
import Select from "../../../form/Select";
import { rvClassesQuery } from "./queries/rvClassesQuery";
import { extractList } from "./utils";
import Spinner, { SpinnerBack } from "../../../Spinner/Spinner";
import { LabelContent } from "components/ContentSection/LabelContent";

const RvManualModelAndClassFields = (props) => {
  const { rvClasses, control } = props;

  return (
    <div className="row b-s-20">
      <div className="col-s-6 t-16 b-16 t-s-0 b-s-0">
        <Controller
          name="model"
          control={control}
          rules={{
            required: "Required"
          }}
          render={(renderProps) => (
            <LabelContent section="generalInfoSectionContent" id="model">
              {(label) => (
                <FieldInput
                  className="fw"
                  id="rv-model"
                  label={label}
                  {...renderProps}
                />
              )}
            </LabelContent>
          )}
        />
      </div>
      <div className="col-s-6 t-16 b-16 t-s-0 b-s-0">
        <Controller
          name="class"
          control={control}
          rules={{
            required: "Required"
          }}
          render={(renderProps) => (
            <LabelContent section="generalInfoSectionContent" id="rv_class">
              {(label) => (
                <Select
                  className="fw"
                  id="rv-class"
                  label={label}
                  options={rvClasses}
                  {...renderProps}
                />
              )}
            </LabelContent>
          )}
        />
      </div>
    </div>
  );
};

const extractRvClassesList = extractList("rv_classes");

export const RvManualModelAndClass = ({ control }) => {
  const [load, { data, loading }] = useLazyQuery(rvClassesQuery);

  useEffect(() => {
    load();
  }, [load]);

  const rvClasses = useMemo(() => {
    const list = extractRvClassesList(data).map((value) => ({
      value,
      text: value
    }));

    list.unshift({
      value: "",
      text: ""
    });

    return list;
  }, [data]);

  if (!data || loading) {
    return <SpinnerBack />;
  }

  return (
    <>
      <RvManualModelAndClassFields rvClasses={rvClasses} control={control} />
    </>
  );
};
