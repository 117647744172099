import React from "react";
import cn from 'classnames';
import Typography from "components/Typography";
import { Button as ButtonContent } from "components/ContentSection/elements";
import classes from './RentalDetailsCancelationStatusContent.module.css';

const RentalDetailsCancelationStatusContent = ({
  title = "",
  reason = "",
  details = ""
}) => {
  return (
    <div>
      {title && (
        <Typography
          variant="subtitle"
          size="m"
          weight="bold"
          className={cn(
            classes.title,
            (reason || details) && "mb-16 mb-m-20 mb-xl-16",
          )}
        >
          {title}
        </Typography>
      )}

      {reason && (
        <div className={classes.reasonContainer}>
          <Typography
            variant="subtitle"
            size="m"
            weight="bold"
            className={classes.subtitle}
          >
            Reason:
          </Typography>

          <Typography
            variant="body"
            size="l"
            className={classes.reason}
          >
            {reason}
          </Typography>
        </div>
      )}

      {details && (
        <div className={classes.detailsContainer}>
          <Typography
            variant="subtitle"
            size="m"
            weight="bold"
            className={classes.subtitle}
          >
            Details:
          </Typography>

          <Typography
            variant="body"
            size="l"
            className={cn(classes.details)}
          >
            {details}
          </Typography>
        </div>
      )}

      <div className={classes.policyLinkContainer}>
        <ButtonContent
          id="cancellation_policy"
          section="linksContent"
          label="Cancellation policy"
          ghost
          newTab
          className={cn(classes.policyLink, 'underline')}
        />
      </div>
    </div>
  );
};

export default RentalDetailsCancelationStatusContent;
