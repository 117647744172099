import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const PaymentBrandLogo = (props) => {
  const { className, brand } = props;

  if (!brand || brand === "unknown") {
    return null;
  }

  return (
    <div
      className={
        classnames(className, "payment-brand", `payment-brand--${brand}`)
      }
    />
  );
};

PaymentBrandLogo.propTypes = {
  className: PropTypes.string,
  brand: PropTypes.oneOf([
    "visa",
    "mastercard",
    "amex",
    "discover",
    "diners",
    "jcb",
    "unknown"
  ]).isRequired
};

export default PaymentBrandLogo;
