/*
 * Sort Utils
 */

import size from "lodash/size";
import orderBy from "lodash/orderBy";
import { FILTERS } from "./filters";
import { calcDistanceBetweenPoints } from "../../utils/distance";

//
// TODO: Use createSort
//

/*
 * Constants
 */

const SORT_IDS = {
  distance: "distance",
  price: "price",
  length: "length"
};

const SORT_TYPES = {
  asc: "asc",
  desc: "desc"
};

const ascSortId = (id) => `${id}_${SORT_TYPES.asc}`;
const descSortId = (id) => `${id}_${SORT_TYPES.desc}`;

const sortOptions = [
  { id: SORT_IDS.distance, type: SORT_TYPES.asc, text: "Distance" },
  { id: SORT_IDS.price, type: SORT_TYPES.desc, text: "Price: High to low", itemProp: "pr" },
  { id: SORT_IDS.price, type: SORT_TYPES.asc, text: "Price: Low to high", itemProp: "pr" },
  { id: SORT_IDS.length, type: SORT_TYPES.asc, text: "Length: Short to long", itemProp: "le" },
  { id: SORT_IDS.length, type: SORT_TYPES.desc, text: "Length: Long to short", itemProp: "le" }
].map(i => ({
  ...i,
  value: i.type === SORT_TYPES.asc ? ascSortId(i.id) : descSortId(i.id)
}));

/**
 * Provides faster access to the current item
 * without having to calculate type and id every time
 */
const sortOptionsByValue = sortOptions.reduce((acc, item) => {
  const { id, value, type, itemProp } = item;
  const isDesc = type === SORT_TYPES.desc;
  acc[value] = { item, id, itemProp, isDesc, isAsc: !isDesc };
  return acc;
}, {});

export const SORT = {
  ids: SORT_IDS,
  types: SORT_TYPES,
  default: sortOptions[0].value,
  options: sortOptions,
  getItemProp: (value) => sortOptionsByValue[value].itemProp || null,
  isEqual: (value, id) => sortOptionsByValue[value].id === id,
  isAsc: (value) => sortOptionsByValue[value].isAsc,
  isDesc: (value) => sortOptionsByValue[value].isDesc
};

/*
 * Utilities
 */

export const getSortOptionText = (activeSort = '') => {
  if (activeSort?.length === 0) return null;
  const activeSortData = {
    id: activeSort.split("_")[0],
    type: activeSort.split("_")[1]
  };
  const sortOptionText = sortOptions.find(option => (
    option.id === activeSortData.id && option.type === activeSortData.type)
  ).text;
  if (sortOptionText.includes(":")) {
    return {
      type: sortOptionText.split(":")[0],
      value: sortOptionText.split(":")[1]
    };
  }
  return sortOptionText;
};

const sort = (list, key, isDesc) => {
  return orderBy(list, [key], [isDesc ? "desc" : "asc"]);
};

export const sortList = (list, sortValue, activeFilters, defaultLocation) => {
  if (!size(list) || !Array.isArray(list)) {
    return [];
  }

  const isDesc = SORT.isDesc(sortValue);
  const itemProp = SORT.getItemProp(sortValue);

  if (itemProp) {
    return sort(list, itemProp, isDesc);
  } else if (SORT.isEqual(sortValue, SORT.ids.distance)) {

    const key = "distance";
    const newList = list.map(i => ({
      ...i,
      [key]: calcDistanceBetweenPoints(
        i.lt,
        i.ln,
        defaultLocation.lat,
        defaultLocation.lng
      )
    }));
    return sort(newList, key, isDesc);
  }

  return list;
};
