import React, { useCallback, Fragment } from "react";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import { Checkbox } from "../../../../../components/form";
import Typography from "../../../../../components/Typography";
import { RentalStatusType } from "processes/Rentals/types";

const LABELS = {
  i_am_renter: "I am a Renter",
  i_am_owner: "I am an Owner"
};
const RENTAL_TYPES = {
  standard_rental: "Standard rental",
  delivery_only_rental: "Delivery only rental"
};
const Filters = (props) => {
  const { statuses, values, onChange } = props;

  const renderCheckbox = useCallback(
    (id, label) => {
      return (
        <Fragment key={id}>
          <Checkbox
            className="ml-16 mr-16 ml-m-0 mr-m-0 t-8 b-8"
            id={`filter-${id}`}
            name={id}
            text={label}
            defaultChecked={values[id]}
            mobileVariant="stretch-reverse"
            onChange={(event) => {
              const checked = event.target.checked;
              onChange({ id, checked });
            }}
          />
          <div className="mt-2" />
        </Fragment>
      );
    },
    [values, onChange]
  );

  const renderBlock = useCallback(
    (label, checkboxes, withPadding) => {
      return (
        <div className={withPadding ? "b-8" : ""}>
          <Typography variant="subtitle" size="m">
            {label}
          </Typography>
          <div>
            {checkboxes.map((item) => renderCheckbox(item.id, item.label))}
          </div>
        </div>
      );
    },
    [renderCheckbox]
  );

  return (
    <div className="">
      {renderBlock(
        "Rental roles",
        ["i_am_renter", "i_am_owner"].map((id) => ({
          id,
          label: LABELS[id]
        })),
        true
      )}
      {renderBlock(
        "Rental status",
        uniqBy(
          statuses?.map((i) => ({
            id: i.id,
            label: i.group
          })),
          "label"
        ),
        true
      )}
      {renderBlock(
        "Rental types",
        ["standard_rental", "delivery_only_rental"].map((id) => ({
          id,
          label: RENTAL_TYPES[id]
        })),
        true
      )}
    </div>
  );
};

Filters.propTypes = {
  statuses: PropTypes.arrayOf(RentalStatusType).isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Filters;
