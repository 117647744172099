import React from "react";
import Typography from "../../../../../components/Typography";
import { tickDarkGreenIcon, tickGrayIcon, tickLightGrayIcon } from "components/Icon";
import Button from "../../../../../components/Button";
import style from "./style.module.css";
import { INSPECTION_STATUS_ICONS, RENTAL_INSPECTION_TYPE_TITLES } from "utils/constants/inspections";
import cn from 'classnames';

const getIcon = (status) => {
  switch (status) {
  case INSPECTION_STATUS_ICONS.LIGHT_GRAY:
    return tickLightGrayIcon;
  case INSPECTION_STATUS_ICONS.GRAY:
    return tickGrayIcon;
  case INSPECTION_STATUS_ICONS.GREEN:
    return tickDarkGreenIcon;
  default:
    return null;
  }
};

const RentalMileageItem = ({
  type,
  inspectionsData: {
    isShowMileage,
    isShowGenerator,
    mileageValue,
    generatorValue,
    mileageMinValue,
    generatorMinValue,
    mileageMinError,
    generatorMinError,
    submitMode,
    buttonDisabled,
    statusIcon
  } = {},
  setAddMileageModalData,
  setCurrentInspection,
  setShowInspectionModal,
  openAddMileage
}) => {

  return (
    <div className={style.rentalMileageItem}>
      <div className={style.rentalMileageItemInner}>
        <div className={style.rentalMileageItemHeader}>
          <Typography
            variant="body"
            size="l"
            className={style.rentalMileageItemHeaderTitle}
          >
            {RENTAL_INSPECTION_TYPE_TITLES[type].mainTitle}
          </Typography>


          <div className={style.rentalMileageItemStatusIcon}>
            {getIcon(statusIcon)}
          </div>
        </div>

        <div className={style.content}>
          {isShowMileage && (
            <div className="row">
              <div className="col-5 col-m-6">
                <Typography
                  variant="body"
                  size="l"
                  className={style.rentalMileageItemHeaderSubtitle}
                >
                  Mileage
                </Typography>
              </div>
              <div className="col-7 col-m-6">
                <Typography
                  variant="body"
                  size="l"
                >
                  {mileageValue !== "" ? <span><strong>{mileageValue}</strong> miles</span> : '(not specified)'}
                </Typography>
              </div>
            </div>
          )}

          {isShowGenerator && (
            <div className="row">
              <div className="col-5 col-m-6">
                <Typography
                  variant="body"
                  size="l"
                  className={style.rentalMileageItemHeaderSubtitle}
                >
                  Generator
                </Typography>
              </div>
              <div className="col-7 col-m-6">
                <Typography
                  variant="body"
                  size="l"
                >
                  {generatorValue !== "" ? <span><strong>{generatorValue}</strong> hours</span> : '(not specified)'}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
      <footer>
        {submitMode ? (
          <div>
            <Button
              ghost
              disabled={buttonDisabled}
              className={cn(style.rentalMileageItemLink)}
              label={RENTAL_INSPECTION_TYPE_TITLES[type].submitTitle}
              onClick={() => {
                setAddMileageModalData({
                  type,
                  title: RENTAL_INSPECTION_TYPE_TITLES[type].submitTitle,
                  isShowMileage,
                  isShowGenerator,
                  mileageMinValue,
                  generatorMinValue,
                  mileageMinError,
                  generatorMinError
                });
                openAddMileage();
              }}
            />
          </div>
        ) : (
          <div>
            <Button
              ghost
              label="Show inspection details"
              disabled={buttonDisabled}
              className={cn(style.rentalMileageItemLink)}
              onClick={() => {
                setCurrentInspection(RENTAL_INSPECTION_TYPE_TITLES[type].currentInspectionTitle);
                setShowInspectionModal(true);
              }}
            />
          </div>
        )}
      </footer>
    </div>
  );
};

export default RentalMileageItem;
