import React, { forwardRef, useCallback, useImperativeHandle, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import classnames from "classnames";
import moment from "moment";
import Spinner from "../../Spinner/Spinner";
import Typography from "../../Typography";
import { ThreadInfoType, ThreadType } from "../types";
import Message from "./Message";
import SystemMessage from "./SystemMessage";
import { useThreadScrollState } from "../hooks/useThreadScrollState";
import { usePreparedMessages } from "../hooks/usePreparedMessages";
import { useMessagesInfinitePagination } from "../hooks/useMessagesInfinitePagination";
import { useMessagesScrollFixOnPagination } from "../hooks/useMessagesScrollFixOnPagination";
import TypingIndicator from "./TypingIndicator";
import classes from "./MessagesList.module.css";
import { useOnResize } from "../useOnResize";
import size from "lodash/size";

function deleteEmptyMessages(groupedMessages, userIdentity) {
  return groupedMessages.filter((group) => {
    let groupMessages = group.messages || [];

    groupMessages = groupMessages.filter(message => {
      const messageState = message?.state;

      if (messageState) {
        if (typeof messageState?.attributes === 'object' && size(messageState?.attributes) === 0) {
          return true;
        }

        const userAttributes = messageState?.attributes?.users?.find(i => {
          return parseInt(i?.user_id) === parseInt(userIdentity);
        });

        if (!userAttributes) {
          return false;
        }
      }

      return true;
    });

    if (groupMessages.length === 0) {
      return false;
    }
    return true;
  });
}

const MessagesList = forwardRef((props, ref) => {
  const {
    apiClient,
    loading,
    loadingMore,
    messages,
    thread,
    threadInfo,
    isActiveThread,
    loadMore,
    hasMore,
    typingMember
  } = props;
  const listRef = useRef(null);
  const groupedMessages = usePreparedMessages(messages);
  const completedMessages = useMemo(() => deleteEmptyMessages(
    groupedMessages, apiClient.user.identity
  ), [apiClient.user.identity, groupedMessages]);

  useMessagesInfinitePagination(listRef, {
    onFire: loadMore,
    disabled: loading || !isActiveThread || !hasMore
  });

  useMessagesScrollFixOnPagination(listRef, { loadingMore, thread });

  const {
    scrollToEnd,
    isScrollAtEnd
  } = useThreadScrollState(listRef, { messages, thread, loading });

  useImperativeHandle(ref, () => ({
    scrollToEnd
  }), [scrollToEnd]);

  const debouncedScroll = useCallback(debounce(() => {
    scrollToEnd();
  }, 400), [scrollToEnd]);

  useOnResize(debouncedScroll);

  return (
    <div className={classnames(classes.root, "df fdc")}>
      {loading && (
        <Spinner position="absolute" />
      )}
      {!loading && completedMessages.length === 0 && (
        <div className={classnames(classes.empty, "df fcc tac r-16 l-16")}>
          <Typography variant="body" size="l">
            Start conversation by typing your message!
          </Typography>
        </div>
      )}
      {completedMessages.length > 0 && (
        <div
          ref={listRef}
          className={classnames(
            classes.list,
            "df fdc",
            "t-20 r-20 l-16",
            "r-m-32 l-m-24"
          )}
        >
          {!loading && completedMessages.map(group => (
            <div key={group.timestamp} className={classes.group}>
              <Typography
                className={classnames(classes.groupTitle, "tac mt-8 mb-12")}
                variant="body"
                size="s"
              >
                {moment.unix(group.timestamp).format("MMM D, YYYY")}
              </Typography>
              <div className="df fdc">
                {group.messages.map(m => {
                  const Component = m.state.author === "system"
                    ? SystemMessage
                    : Message;

                  return (
                    <Component
                      key={m.state.sid}
                      className={classes.message}
                      data={m.state}
                      thread={thread}
                      threadInfo={threadInfo}
                      userIdentity={apiClient.user.identity}
                      isReceived={m.state.author !== apiClient.user.identity}
                    />
                  );
                })}
              </div>
            </div>
          ))}
          <div
            className={classnames(
              classes.typingPadding,
              { [classes.typingPaddingActive]: Boolean(typingMember) }
            )}
          />
        </div>
      )}
      {!loading && (
        <TypingIndicator
          typingUserName={typingMember ? threadInfo?.title : null}
          isScrollAtEnd={isScrollAtEnd}
        />
      )}
    </div>
  );
});

MessagesList.propTypes = {
  apiClient: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  thread: ThreadType.isRequired,
  threadInfo: ThreadInfoType.isRequired,
  messages: PropTypes.array.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  typingMember: PropTypes.object
};

export default MessagesList;
