import React from "react";
import Typography from "components/Typography";
import classes from "./Select.module.css";

export function Select({ data: rawData, value: rawValue, title }) {
  const { value: id, comment } = JSON.parse(rawValue);
  const { items } = JSON.parse(rawData);
  const value = items.find(item => item?.id === id)?.dispText;

  return (
    <div className={classes.container}>
      <div>
        {title && (
          <Typography
            variant="body"
            size="l"
            className={classes.title}
          >
            {title}
          </Typography>
        )}
      </div>
      <div>
        {value && (
          <div>
            <Typography
              variant="body"
              size="l"
              weight="bold"
              className={classes.value}
            >
              {value}
            </Typography>
          </div>
        )}
        {comment && (
          <div>
            <Typography
              variant="body"
              size="l"
              className={classes.comment}
            >
              {comment}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
