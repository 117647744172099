import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import classnames from "classnames";
import BasicCollapse from "components/RcCollapse/BasicCollapse";
import Typography from "components/Typography";
import { useWidgetContext } from "components/Widget/useWidgetContext";
import { FILTERS, isFilterSet }  from "components/Widget/filters";
import classes from "./MobileFilterBlock.module.css";
import cn from "classnames";

const MobileFilterBlock = (props) => {
  const { id, getLabelFromValue, renderForm, hanldeAfterClearFilter = () => {} } = props;
  const {
    filtersControl,
    resetFilter,
    applyFilters,
    theme
  } = useWidgetContext();

  return (
    <Controller
      name={id}
      control={filtersControl}
      render={({ onChange }) => {
        const value = filtersControl.getValues(id);
        const _isFilterSet = isFilterSet(id, value);
        const valueLabel = Boolean(getLabelFromValue && value)
          ? getLabelFromValue(value)
          : null;

        return (
          <div
            className={classnames(
              classes.root
              // { [classes.disabled]: isFilterDisabled(id) && cards?.length === 0 }
            )}
          >
            <div className="t-16 r-16 b-16">
              <BasicCollapse
                headerClassName={classes.header}
                iconClassName={classes.headerIcon}
                renderHeader={(open) => (
                  <div>
                    <div className="row no-gutters f-nowrap fbc">
                      <Typography
                        variant="subtitle"
                        size="s"
                        weight="bold"
                        className={classes.title}
                      >
                        {FILTERS.labels[id]}
                      </Typography>
                      {open && _isFilterSet && (
                        <Typography
                          className={cn(
                            classes.clearer,
                            theme === 'dark' && classes.clearerDark,
                          )}
                          variant="subtitle"
                          size="s"
                          onClick={(event) => {
                            event.stopPropagation();
                            resetFilter(id);
                            applyFilters();
                            hanldeAfterClearFilter();
                          }}
                        >
                          Clear
                        </Typography>
                      )}
                    </div>
                    {!open && valueLabel && (
                      <Typography
                        className={`ml-16 ${classes.valueLabel}`}
                        variant="body"
                        size="l"
                      >
                        {valueLabel}
                      </Typography>
                    )}
                  </div>
                )}>
                <div className="b-8">
                  {renderForm({
                    value: value || FILTERS.defaults[id],
                    onChange
                  })}
                </div>
              </BasicCollapse>
            </div>
          </div>
        );
      }}
    />
  );
};

MobileFilterBlock.propTypes = {
  id: PropTypes.string.isRequired,
  getLabelFromValue: PropTypes.func,
  renderForm: PropTypes.func.isRequired
};

export default MobileFilterBlock;
