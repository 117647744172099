import { useContentSection } from "components/ContentSection/useContent";

export const LabelsContent = ({ section, ids, children }) => {
  const content = useContentSection(section);
  const labels = {};
  content["form"].forEach((element) => {
    if (ids.includes(element.id)) {
      labels[element.id] = element.label;
    }
  });

  return children(labels);
};
