import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Button from "components/Button";
import { MQ_BP, useMatchMedia } from "../../../MatchMedia";
import { ReactComponent as QuestionIcon } from "./question-icon.svg";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import classnames from "classnames";
import classes from "./TaxCard.module.css";
import { TAB_IDS } from "processes/MyAccount/steps/TransactionHistory/constants";
import { MoneyType } from "types/money";
import { PriceFormModal } from "components/PriceForm";

export const TaxCard = (props) => {
  const {
    containerClassName,
    id,
    billing_date,
    billing_amount,
    scheduled_date,
    total_earning,
    expected_payout,
    taxes,
    first_name,
    last_name,
    payout_count = null,
    renter_id,
    rv_name,
    rv_url,
    rental_id,
    departure_date,
    return_date,
    nights,
    tab,
    payout_info_pf
  } = props;

  const isSmallDevice = useMatchMedia({ minWidth: MQ_BP.small });

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const showDetailsModal = useCallback(() => {
    setDetailsModalOpen(true);
  }, []);

  const closeDetailsModal = useCallback(() => {
    setDetailsModalOpen(false);
  }, []);

  return (
    <>
      <div
        id={id}
        className={classnames(
          classes.container,
          containerClassName,
          "l-16 r-16 t-8 b-12 l-m-24 t-m-12 r-m-24 b-m-16 l-xxl-32 r-xxl-32"
        )}
      >
        <div className={classes.topContent}>
          {tab === TAB_IDS.COMPLETED
            ? (
                <>
                  <div
                    className={classnames(
                      classes.date,
                      "r-m-24"
                    )}
                  >
                    <Typography
                      variant="subtitle"
                      size="m"
                      className={classes.dateText}>
                      {billing_date}
                    </Typography>
                  </div>

                  <div className={classes.total}>
                    <Typography variant="subtitle" size="m" className={classes.totalText}>
                      <FormattedPrice value={billing_amount} isSubBold className={classes.totalTextPrice} />
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={classnames(
                      classes.date,
                      "r-m-24"
                    )}
                  >
                    <Typography
                      variant="subtitle"
                      size="m"
                      className={classes.dateText}>
                      {scheduled_date}
                    </Typography>
                  </div>

                  <div className={classes.total}>
                    <Typography variant="subtitle" size="m" className={classes.totalText}>
                      <FormattedPrice value={expected_payout} isSubBold className={classes.totalTextPrice} />
                    </Typography>
                  </div>
                </>
              )
          }

          <div className={classes.totalEarnContainer} >
            <div className={classes.totalEarnContainerInner} onClick={isSmallDevice ? showDetailsModal : null}>
              <div
                className={classnames(
                  classes.totalEarnTitle,
                  "mr-m-4"
                )}
              >
                <Typography variant="caption" className={classes.totalEarnText}>
                  { payout_count !== null && payout_count > 1
                    ? `Total earnings for this rental (${payout_count} payouts)`
                    : "Total earnings for this rental"
                  }
                </Typography>
              </div>

              <div className={classes.totalEarn} onClick={!isSmallDevice ? showDetailsModal : null}>
                <Typography variant="caption" className={classes.totalEarnSum}>
                  <FormattedPrice value={total_earning} isSubBold />
                  <span
                    className={classnames(
                      classes.questionIcon,
                      "ml-4"
                    )}
                  >
                    <QuestionIcon />
                  </span>
                </Typography>
              </div>
            </div>
          </div>

          {taxes && (
            <div
              className={classnames(
                classes.taxesContainer,
                "t-8 b-8 mt-2 t-m-0 mt-m-0 l-m-24 b-m-0"
              )}
            >
              <div className={classes.taxesTextContainer}>
                <Typography variant="subtitle" size="s" className={classes.taxesTitleText}>
                  Taxes:
                </Typography>
              </div>

              <div
                className={classnames(
                  classes.taxes,
                  "l-m-8"
                )}
              >
                <Typography variant="subtitle" size="s" className={classes.taxesText}>
                  <FormattedPrice isSubBold value={taxes} className={classes.taxesTextPrice} />
                </Typography>
              </div>
            </div>
          )}
        </div>

        <div
          className={classnames(
            classes.footer,
            "t-8 mt-m-12 t-m-12"
          )}
        >

          <div
            className={classnames(
              classes.autorName,
              classes.footerItem
            )}
          >
            <Button
              ghost
              href={`/profile/${renter_id}`}
              target="_blank"
              noopener="true"
              norefferer="true"
              className={classnames(
                classes.footerLink,
                classes.footerAutorNameLink,
                "body--s"
              )}
            >
              {`${first_name} ${last_name}`}
            </Button>
          </div>

          <div
            className={classnames(
              classes.rvName,
              classes.footerItem
            )}
          >
            <Button
              ghost
              href={rv_url}
              target="_blank"
              noopener="true"
              norefferer="true"
              className={classnames(
                classes.footerLink,
                classes.rvNameLink,
                "body--s"
              )}
            >
              {rv_name}
            </Button>
          </div>

          <div
            className={classnames(
              classes.fullDate,
              classes.footerItem
            )}
          >
            <Button
              ghost
              href={`/rentals/details?id=${rental_id}`}
              target="_blank"
              noopener="true"
              norefferer="true"
              className={classnames(
                classes.footerLink,
                classes.footerFullDateLink,
                "body--s"
              )}
            >
              {`${departure_date} - ${return_date} (${nights} ${nights > 1 ? 'nights' : 'night'})`}
            </Button>
          </div>
        </div>
      </div>

      <PriceFormModal
        show={detailsModalOpen}
        data={[payout_info_pf]}
        title="Earnings"
        isFooter={false}
        onClose={closeDetailsModal}
      />
    </>
  );
};

TaxCard.propTypes = {
  containerClassName: PropTypes.string,
  billing_amount: MoneyType,
  total_earning: MoneyType,
  expected_payout: MoneyType,
  owner_earns: MoneyType,
  taxes: MoneyType,
  rental_fee: MoneyType,
  delivery_fee: MoneyType,
  cleaning_fee: MoneyType,
  disposal_fee: MoneyType,
  add_on_fee: MoneyType,
  mileage_overage_fee: MoneyType,
  generator_hours_overage_fee: MoneyType,
  mileage_overage: PropTypes.number,
  generator_hours_overage: PropTypes.number,
  payout_count: PropTypes.number,
  renter_id: PropTypes.number,
  rental_id: PropTypes.number,
  nights: PropTypes.number,
  your_share: PropTypes.string,
  billing_date: PropTypes.string,
  scheduled_date: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  rv_name: PropTypes.string,
  rv_url: PropTypes.string,
  departure_date: PropTypes.string,
  return_date: PropTypes.string,
  tab: PropTypes.string
};
