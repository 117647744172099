import React, { useState, useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import ReferUsersModal from "components/ReferUsersModal/ReferUsersModal";
import ReferralSuccessNotification from "components/ReferralSuccessNotification/ReferralSuccessNotification";

import { referralsQuery } from "../../../../../../queries";
import { SpinnerBlur } from "../../../../../../components/Spinner/Spinner";
import Button from "../../../../../../components/Button";
import Typography from "../../../../../../components/Typography";
import NotificationPopover from "../../../../../../components/NotificationPopover";
import { MatchMedia } from "../../../../../../components/MatchMedia";
import RcCollapse from "../../../../../../components/RcCollapse";
import ReferralsList from "../ReferralsList/ReferralsList";
import { MyAccountContext } from '../../../../MyAccountContext';

import classes from './ReferralsContent.module.css';

const ReferralsContent = ({ content }) => {
  const { loading, data } = useQuery(referralsQuery, {
    fetchPolicy: "no-cache"
  });

  const [showNotification, setShowNotification] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const myAccountContext = useContext(MyAccountContext);

  const closeNotification = () => {
    setShowNotification(false);
  };

  const openReferModal = () => {
    setShowModal(true);
  };

  const closeReferModal = () => {
    setShowModal(false);
  };

  const openSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const payoutInfoFilled = useMemo(() => (
    myAccountContext.userData?.bank_account?.account_number &&
    myAccountContext.userData?.bank_account?.routing_number
  ), [myAccountContext.userData?.bank_account?.account_number, myAccountContext.userData?.bank_account?.routing_number]);

  return (
    <>
      <Typography component="h2" variant="headline" size="s">
        {content.sectionTitle}
      </Typography>

      <MatchMedia mediaQuery="(max-width: 767px)">
        <div className="t-16 b-16">
          <RcCollapse
            header={content.proTipsSectionTitle}
            content={content.proTipsSectionText}
          />
        </div>
      </MatchMedia>

      {!payoutInfoFilled
        && <NotificationPopover
          variant="static"
          status="error"
          show={showNotification}
          title={content.notificationTitle}
          text={content.notificationText}
          onClose={closeNotification}
        >
          <div className="b-8 t-8">
            <Button
              label={content.notificationButtonText}
              secondary
              className={classes.NotificationButton}
              size="medium"
              href={"/account/payout"}
            />
          </div>
        </NotificationPopover>
      }

      {
        loading
          ? <SpinnerBlur />
          : <ReferralsList content={content} referrals={data.referral_list} />
      }


      <div className="b-16 t-16 b-m-20 t-m-20">
        <Button
          label={content.referUsersButtonText}
          onClick={openReferModal}
        />
      </div>

      <ReferUsersModal
        show={showModal}
        onClose={closeReferModal}
        onSuccess={openSuccessModal}
      />

      <ReferralSuccessNotification
        show={showSuccessModal}
        content={content}
        onClose={closeSuccessModal}
      />
    </>
  );
};

export default ReferralsContent;
