import React, { useState, useEffect } from "react";
import Typography from "../../../Typography";
import Tag from "../../../Tag";
import { MatchMedia } from "../../../MatchMedia";
import HeaderDetailsToggle from "../Header/HeaderDetailsToggle";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

export const NavStatus = ({
  code = "1",
  text = "Incomplete",
  message,
  showDetailsToggle,
  setDetailsModalActive,
  updateContext,
  context
}) => {
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );
  let type = "warning";
  if (code === "1") type = "default-dark";
  if (code === "4") type = "success-dark";

  const handleShowDetailsModal = () => {
    setDetailsModalActive(true);
  };
  useEffect(() => {
    if (context?.errors && context?.errors?.length > 0) {
      setShowNotificationPopover(true);
    }
  }, [context?.errors]);
  return (
    <div className="dn db-m _visible-all-steps-view">
      <Typography component="p" className="t-8 b-8">
        {`Status:`}
        <Tag type={type} text={text} size="small" />
      </Typography>
      {message && (
        <Typography
          component="p"
          className={message[1] === "error" ? "c-error-dark" : "c-charcoal"}
        >
          {message[0]}
        </Typography>
      )}
      {showDetailsToggle && (
        <MatchMedia mediaQuery="(max-width: 767px)">
          <HeaderDetailsToggle onToggle={handleShowDetailsModal} />
        </MatchMedia>
      )}
    </div>
  );
};
