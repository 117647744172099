import { path } from "ramda";
import { ApolloLink, Observable } from "@apollo/client";
import { makeRefreshRequest } from "./makeRefreshRequest";
import { retrieveTokens } from "./tokens";

const getDefinitions = path(["query", "definitions"]);
const getSelections = path(["selectionSet", "selections"]);

function isUserQuery(operation) {
  /* Do not refresh the token before user query */
  return false;
  /*
  const definitions = getDefinitions(operation);

  return definitions.some((definition) => {
    const selections = getSelections(definition);

    return selections.some((selection) => {
      const { kind, name } = selection;

      return kind === "Field" && name?.kind === "Name" && name?.value === "user";
    });
  });
  */
}

function isRefreshTokenQuery(operation) {
  return operation.operationName === "refresh_token";
}

function isExpiredToken(operation) {
  if (isRefreshTokenQuery(operation)) {
    return false;
  }

  const { expiresAt } = retrieveTokens();
  const d = new Date();

  d.setSeconds(d.getSeconds() + 10);

  return d.getTime() > expiresAt;
}

async function beforeOperation(operation) {
  if (!isUserQuery(operation) && !isExpiredToken(operation)) {
    return false;
  }

  await makeRefreshRequest();

  return true;
}

export const authenticatedUserLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    beforeOperation(operation)
      .then((isFreshToken) => {
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        });
      });
  });
});
