import { gql } from "@apollo/client";

export const initialDataQuery = gql`
  query initialData($id: Int!, $for_listing: Boolean!) {
    user {
      good_sam_membership_validation {
        key
        value
      }
    }
    rv(id: $id, for_listing: $for_listing) {
      id
      rv_name
      class
      model
      year
      make
      manufacturer
      title_image
      availability_calendar {
        date
      }
      delivery_option
      location {
        state
        city
        full_address
      }
      is_trailed_class
      miles_included
      limited_daily_mileage
      generator_hours_included
      generator_not_applicable
      limited_generator_hours
      add_ons {
        id
        name
        price {
          cents_as_part
          dollars_as_part
          cents_total
          user_friendly
        }
        price_type {
          key
          value
        }
      }
    }
  }
`;
