import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "../../../../../components/Typography";
import TooltipToggleQuestion from "../../../../../components/TooltipToggleQuestion";

const RentalDetailsRentalInfoItem = (props) => {
  const { label, value, variant, tooltip, boldLabel, boldValue } = props;

  const typographyProps = {
    variant: variant === "large" || variant === "medium" ? "subtitle" : "body",
    size: "l",
    component: "span"
  };
  if (variant === "large") {
    typographyProps.size = "m";
  }
  if (variant === "medium") {
    typographyProps.size = "s";
  }

  return (
    <p
      className={classnames(
        "rental-info__item",
        variant && `rental-info__item--${variant}`
      )}
    >
      <Typography
        {...typographyProps}
        weight={boldLabel ? "bold" : "normal"}
        className="rental-info__item-cell rental-info__item-cell--label"
      >
        {label}
        {tooltip && (
          <TooltipToggleQuestion className="rental-info__tooltip-btn">
            <Typography variant="body" size="l">
              {tooltip}
            </Typography>
          </TooltipToggleQuestion>
        )}
      </Typography>
      <Typography
        {...typographyProps}
        className={classnames(
          "rental-info__item-cell rental-info__item-cell--value",
          boldValue && 'rental-info__item-cell--value__boldValue'
        )}
      >
        {value}
      </Typography>
    </p>
  );
};

RentalDetailsRentalInfoItem.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["large", "medium"]),
  boldLabel: PropTypes.bool,
  boldValue: PropTypes.bool
};

export default RentalDetailsRentalInfoItem;
