import { gql } from '@apollo/client';
import { baseFragments } from "queries/fragments";

export const verificationCheckQuery = gql`
  ${baseFragments.paymentInfo},
  ${baseFragments.bankAccount},

  query verificationCheck {
    user {
      email
      email_verified
      phone
      phone_verified
      bank_account {
        ...BankAccount
      }
      payment_info {
        ...PaymentInfo
      }
    }
  }
`;
