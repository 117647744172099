import React, {  forwardRef, useMemo, useState, useEffect, useCallback } from "react";
import Typography from "components/Typography";
import PropTypes from "prop-types";
import DoubleSelect from "components/form/DoubleSelect/DoubleSelect";
import Button from "components/Button";
import classnames from "classnames";
import classes from "./DoubleDateSelectInterval.module.css";

const defaultSelectData = [
  {
    id: 'month',
    label: 'Month',
    options: [
      {
        text: "January",
        value: "01"
      },
      {
        text: "February",
        value: "02"
      },
      {
        text: "March",
        value: "03"
      },
      {
        text: "April",
        value: "04"
      },
      {
        text: "May",
        value: "05"
      },
      {
        text: "June",
        value: "06"
      },
      {
        text: "July",
        value: "07"
      },
      {
        text: "August",
        value: "08"
      },
      {
        text: "September",
        value: "09"
      },
      {
        text: "October",
        value: "10"
      },
      {
        text: "November",
        value: "11"
      },
      {
        text: "December",
        value: "12"
      }
    ],
    className: classes.select1
  },
  {
    id: 'year',
    label: 'Year',
    options: [],
    className: classes.select2
  }
];

const DoubleDateSelectInterval = forwardRef((props, ref) => {
  const {
    id,
    className,
    select1Value,
    select2Value,
    yearsOptions,
    onSelect1Change,
    onSelect2Change,
    onChange
  } = props;

  const [selectsValue, setSelectsValue] = useState({
    select1: select1Value,
    select2: select2Value
  });

  useEffect(() => {
    setSelectsValue({
      select1: select1Value,
      select2: select2Value
    });
  }, [select1Value, select2Value]);

  const selectData = useMemo(() => {
    return [
      { ...defaultSelectData[0] },
      {
        ...defaultSelectData[1],
        options: yearsOptions
      }];
  }, [yearsOptions]);

  const handleOnChange = useCallback(() => {
    onChange(selectsValue);
  }, [onChange, selectsValue]);

  return (
    <div
      ref={ref}
      id={id}
      className={classnames(
        classes.container,
        className
      )}
    >
      <div className={classes.content}>
        <div
          className={classnames(
            classes.col1,
            't-16 b-16 b-m-20 t-m-20'
          )}
        >
          <DoubleSelect
            value={select1Value}
            data={selectData}
            onChange={onSelect1Change}
          />
        </div>
        <div
          className={classnames(
            classes.col2,
            'l-m-16 r-m-16 b-m-20 t-m-20'
          )}
        >
          —
        </div>
        <div
          className={classnames(
            classes.col3,
            't-16 b-16 b-m-20 t-m-20'
          )}
        >
          <DoubleSelect
            value={select2Value}
            data={selectData}
            onChange={onSelect2Change}
          />
        </div>
        <div
          className={classnames(
            classes.col4,
            't-16 b-16 l-m-24 b-m-20 t-m-20 l-xxl-32'
          )}
        >
          <Button
            onClick={handleOnChange}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
});

DoubleDateSelectInterval.displayName = "DoubleDateSelectInterval";

DoubleDateSelectInterval.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  select1Value: PropTypes.shape({
    month: PropTypes.string,
    year: PropTypes.string
  }),
  select2Value: PropTypes.shape({
    month: PropTypes.string,
    year: PropTypes.string
  }),
  yearsOptions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  })),
  onSelect1Change: PropTypes.func,
  onSelect2Change: PropTypes.func,
  onChange: PropTypes.func
};
export default DoubleDateSelectInterval;
