import React from "react";
import PropTypes from "prop-types";
import WidgetProvider from "./WidgetProvider";
import Widget from "components/Widget/Widget";
import { useWidgetContext } from "./useWidgetContext";
import { SpinnerBack } from "components/Spinner/Spinner";
import PageError from "../PageError/PageError";
import classes from "./WidgetContainer.module.css";
import cn from "classnames";

const WidgetContainer = () => {
  return (
    <WidgetProvider>
      <Content />
    </WidgetProvider>
  );
};

export const Content = () => {
  const { error, loading, theme } = useWidgetContext();

  if (error) {
    return (
      <PageError error={error} />
    );
  }

  return (
    <div className={classes.root}>
      <Widget className={classes.widget} />
      { loading && (
        <SpinnerBack
          className={cn(
            classes.spinner,
            theme === 'dark' && classes.spinnerWLDark
          )}
          containerClassName={theme === 'dark' ? classes.spinnerContainer : ""}
        />
      )}
    </div>
  );
};

WidgetContainer.propTypes = {
};

export default WidgetContainer;
