import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button/Button";
import BasicButton from "components/Button/BasicButton";
import { ButtonType } from "utils/types";

const ApiButton = (props) => {
  const { data, onClick, ...other } = props;

  return (
    <Button
      {...other}
      label={data.label}
      disabled={data.disabled}
      secondary={data.type === "secondary"}
      accent={data.type === "accent"}
      ghost={data.type === "link"}
      {...(data.new_tab
        ? { target: "_blank", rel: "noopener noreferrer" }
        : {})}
      href={!onClick && data.link}
      onClick={onClick}
    />
  );
};

ApiButton.propTypes = {
  ...BasicButton.propTypes,
  data: ButtonType.isRequired,
  onClick: PropTypes.func
};

export default ApiButton;
