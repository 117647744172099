import { useCallback, useEffect } from "react";

export function useAutoHeight(ref) {
  const handleInput = useCallback(() => {
    if (ref && ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = (ref.current.scrollHeight) + "px";
    }
  }, [ref]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.style["overflow-y"] = "hidden";
      ref.current.style.height = (ref.current.scrollHeight) + "px";
    }
  }, [ref, ref.current]); // eslint-disable-line

  return handleInput;
}
