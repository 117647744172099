import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import repeat from "lodash/repeat";
import { path } from "ramda";
import classnames from "classnames";
import Typography from "../Typography";
import Button from "../Button";
import BecomeMemberShip from "components/BecomeMemberShip";
import FormattedPrice from "../FormattedPrice/FormattedPrice";
import classes from "./styles.module.css";
import { ReactComponent as GoodsamLogo } from "./goodsam-logo.svg";

function extractData(ownerIncomeForecastQueryData) {
  const commonPath = ownerIncomeForecastQueryData?.owner_income_forecast;
  const difference = commonPath?.difference?.user_friendly;
  const memberDiscount = commonPath?.member_discount;
  const memberIncome = commonPath?.member_income;
  const nonMemberIncome = commonPath?.non_member_income;

  return {
    difference,
    memberDiscount,
    memberIncome,
    nonMemberIncome
  };
}

function createTextSkeleton(baseClassName) {
  const TextSkeleton = ({ className, loading, size, children }) => {
    if (loading) {
      return <span className={classnames(baseClassName, className)}>{repeat("9", size)}</span>;
    }

    return children && typeof children === "function" ? children() : null;
  };

  TextSkeleton.displayName = "TextSkeleton";

  return TextSkeleton;
}

const TextSkeletonWhite = createTextSkeleton(classes.loadingQueryPlaceholderWhite);
const TextSkeletonGray = createTextSkeleton(classes.loadingQueryPlaceholderGray);

const MembershipBanner = (props) => {
  const [isShowBecomeMemberShip, setShowBecomeMemberShip] = useState(false);
  const onCloseBecomeMemberShip = useCallback(() => {
    setShowBecomeMemberShip(false);
  }, []);
  const { className, ownerIncomeForecastQueryData, loadingOwnerIncomeForecastQuery, handleActionAfterLastStep } = props;

  const { difference, memberDiscount, memberIncome, nonMemberIncome } = extractData(ownerIncomeForecastQueryData);

  return (
    <div className={className}>
      <BecomeMemberShip
        show={isShowBecomeMemberShip}
        onCloseBecomeMemberShip={onCloseBecomeMemberShip}
        handleActionAfterLastStep={handleActionAfterLastStep}
      />
      <div className={classes.main}>
        <Typography variant="subtitle" size="s" weight="bold" className={`tac c-tertiary ${classes.title}`}>
          <span>Join </span>
          <GoodsamLogo className={classes.logo} />
          <br className="dn-m" />
          <span>
            {" "}
            and earn an extra{" "}
            <TextSkeletonWhite loading={loadingOwnerIncomeForecastQuery} size={5}>
              {() => <span>${difference}</span>}
            </TextSkeletonWhite>
            *
          </span>
        </Typography>
        <div className={classes.body}>
          <div className={`${classes.items}`}>
            <div className={`${classes.item}`}>
              <Typography variant="body" size="l" className={`c-tertiary ${classes.itemTitle}`}>
                <span className="">Non-member income: </span>
                <TextSkeletonGray loading={loadingOwnerIncomeForecastQuery} size={5} className="fw-700 db-m fw-m">
                  {() => <FormattedPrice value={nonMemberIncome} isBold />}
                </TextSkeletonGray>
              </Typography>
              <Typography variant="body" size="s" className={`c-tertiary-light ${classes.itemText}`}>
                Based on the daily rate and the commission associated
              </Typography>
            </div>
            <div className={`${classes.item}`}>
              <Typography variant="body" size="l" className={`c-tertiary ${classes.itemTitle}`}>
                <span className="">Member income: </span>
                <TextSkeletonGray loading={loadingOwnerIncomeForecastQuery} size={5} className="fw-700 db-m fw-m">
                  {() => <FormattedPrice value={memberIncome} isBold />}
                </TextSkeletonGray>
              </Typography>
              <Typography variant="body" size="s" className={`c-tertiary-light ${classes.itemText}`}>
                Good Sam members receives{" "}
                <TextSkeletonGray loading={loadingOwnerIncomeForecastQuery} size={3}>
                  {() => <span>{memberDiscount}%</span>}
                </TextSkeletonGray>{" "}
                discount on owner commissions
              </Typography>
            </div>
          </div>
          <div className={classes.cta}>
            <Button secondary outline onClick={() => setShowBecomeMemberShip(true)}>
              Join today
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Typography variant="caption" className="tac tar-m c-tertiary-light">
          * bases on daily rental rate for a 6 day rental
        </Typography>
      </div>
    </div>
  );
};

MembershipBanner.propTypes = {
  className: PropTypes.string
};

export default MembershipBanner;
