import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";

export const handleListingSegment = (
  data,
  eventName,
  listingStepName,
  stepNumber,
  additionalData = {},
  hasNotFormStep
) => {
  if (!data) return;

  const owner_id = data?.owner?.id;
  const isGoodSamMember = data?.owner?.good_sam_membership_validation?.value === "Passed" ? "yes" : "no";

  const formStepData = hasNotFormStep ? {} : {
    form_step: stepNumber,
    form_step_description: listingStepName
  };

  pushSegmentAnalyticsTrack(eventName, {
    cwh_business: 'RV Rentals',
    form_name: "Rental Listing",
    ...formStepData,
    owner_id,
    goodSamMember: isGoodSamMember,
    fbp: "",
    fbc: "",
    ...additionalData
  });
};
