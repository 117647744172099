import padStart from "lodash/padStart";

export const convertCents = (c) => {
  return typeof c === "number" ? (c / 100).toFixed(2) : "";
};

export const formatCents = (v) => {
  if (!v) {
    return "00";
  } else {
    return padStart(v.toString(), 2, "0");
  }
};
