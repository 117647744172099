import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MoneyType } from "types/money";
import { useForm } from "react-hook-form";
import { Map } from "./components/Map/Map";
import Form from "components/form/Form/Form";
import { Modal } from "components/Modal";
import Typography from "components/Typography";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import classes from "./PickUpDeliveryModal.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import PickUpDeliveryModalFooter from "processes/Booking/components/PickUpDeliveryModal/components/PickUpDeliveryModalFooter";
import { BigVerticalRadioListContainer } from "processes/Booking/components/PickUpDeliveryModal/components/BigVerticalRadioListContainer";
import classnames from "classnames";

const DEFAULT_VALUES = {
  location: {
    full_address: "",
    latitude: "",
    longitude: ""
  },
  radio: 'location-1'
};

const PickUpDeliveryModal = ({
  onSelectLocation,
  onClosePickUpDeliveryModal,
  handleOnCalculateDeliveryFee,
  initialLocation,
  show,
  paidDelivery,
  isDeliveryFeeError,
  deliveryFeeErrorMessage,
  deliveryFee,
  resultData,
  rentalType = 1,
  specificDeliveryLocations = [],
  locationData = {},
  deliveryRadius = { 1: true, 2: false },
  deliveryFeeData,
  setDeliveryFeeData
}) => {
  const mdlRef = useScrollBarWidth();
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );
  const [keyMap, setKeyMap] = useState(1);
  const [isMapLoaded, setMapLoaded] = useState(false);
  const [showDefaultLocation, setShowDefaultLocation] = useState(false);

  const [radioState, setRadioState] = useState('location-1');
  const [prevRadioState, setPrevRadioState] = useState('location-1');

  const [currentLocation, setCurrentLocation] = useState({});
  const [prevLocation, setPrevLocation] = useState({});

  const shortAddress = initialLocation?.short_address;

  const {
    watch,
    control,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: Object.assign({}, DEFAULT_VALUES),
    mode: "onChange",
    shouldUnregister: false
  });

  const { location } = watch();

  const handleClose = useCallback(() => {
    setRadioState(prevRadioState);
    setCurrentLocation(prevLocation);
    onClosePickUpDeliveryModal();
    setShowNotificationPopover(false);
    setDeliveryFeeData({
      deliveryFee: null,
      error: null
    });
  }, [onClosePickUpDeliveryModal, prevLocation, prevRadioState, setDeliveryFeeData]);

  const setLocaionData = useCallback((key) => {
    const radioState = locationData[rentalType]?.radioState || 'location-1';
    if (key) {
      const location = locationData[rentalType][key].location;
      setCurrentLocation(location);
      setPrevLocation(location);

      if (key === 'deliveryRadius') {
        setValue(`location`, location);
      } else {
        setValue(`location`, {
          full_address: "",
          latitude: "",
          longitude: ""
        });
      }
    } else {
      setValue(`location`, {
        full_address: "",
        latitude: "",
        longitude: ""
      });
      setCurrentLocation(initialLocation.location);
      setPrevLocation(initialLocation.location);
    }

    setRadioState(radioState);
    setPrevRadioState(radioState);
  }, [initialLocation.location, locationData, rentalType, setValue]);

  const onSave = useCallback(() => {
    if (radioState === 'location-1') {
      onSelectLocation({
        fullAddress: shortAddress,
        deliveryLocation: null,
        specific_delivery_location_id: null,
        deliveryFee: null,
        locationData: {
          ...locationData,
          [rentalType]: {
            locationSelected: true,
            fullAddress: shortAddress,
            deliveryRadius: null,
            specificDeliveryLocation: null,
            radioState: 'location-1'
          }
        }
      });
      setCurrentLocation({});
      setPrevLocation(initialLocation.location);
      setPrevRadioState('location-1');
      setValue(`location`, {
        full_address: "",
        latitude: "",
        longitude: ""
      });
    } else if (radioState === 'location-2') {
      onSelectLocation({
        fullAddress: location?.full_address,
        deliveryLocation: location?.full_address,
        deliveryFee,
        specific_delivery_location_id: null,
        locationData: {
          ...locationData,
          [rentalType]: {
            locationSelected: true,
            fullAddress: location?.full_address,
            deliveryRadius: {
              deliveryFee,
              location
            },
            specificDeliveryLocation: null,
            radioState: 'location-2'
          }
        }
      });
      setPrevLocation(location);
      setPrevRadioState('location-2');
    } else {
      const location = specificDeliveryLocations.find((loc => radioState === String(loc.id))).location;
      const locationId = specificDeliveryLocations.find((loc => radioState === String(loc.id))).id;
      const deliveryFee = specificDeliveryLocations.find((loc => radioState === String(loc.id))).delivery_fee;
      setPrevLocation(location);
      setPrevRadioState(radioState);
      setValue(`location`, {
        full_address: "",
        latitude: "",
        longitude: ""
      });
      onSelectLocation({
        fullAddress: location.full_address,
        deliveryLocation: null,
        specific_delivery_location_id: Number(locationId),
        deliveryFee,
        locationData: {
          ...locationData,
          [rentalType]: {
            locationSelected: true,
            fullAddress: location?.full_address,
            deliveryRadius: null,
            specificDeliveryLocation: {
              location,
              id: Number(locationId)
            },
            radioState: radioState
          }
        }
      });
    }
    setDeliveryFeeData({
      deliveryFee: null,
      error: null
    });
  }, [deliveryFee, initialLocation.location, location, locationData, onSelectLocation, radioState, rentalType, setDeliveryFeeData, setValue, shortAddress, specificDeliveryLocations]);

  useEffect(() => {
    if (resultData?.result?.errors) {
      setShowNotificationPopover(true);
    }
  }, [resultData]);

  useEffect(() => {
    setKeyMap((key) => key + 1);
  }, [radioState]);

  useEffect(() => {
    if (rentalType === 2 && !locationData?.[rentalType]?.locationSelected) {
      if ((deliveryRadius[2] && specificDeliveryLocations.length > 0) || (specificDeliveryLocations.length > 0)) {
        setRadioState('');
        setPrevRadioState('');
      } else if (deliveryRadius[2]) {
        setRadioState('location-2');
        setPrevRadioState('location-2');
      }
      setCurrentLocation(initialLocation.location);
      setPrevLocation(initialLocation.location);
      setValue(`location`, {
        full_address: "",
        latitude: "",
        longitude: ""
      });
    } else if (Object.keys(locationData).length !== 0) {
      if (locationData[rentalType]?.deliveryRadius) {
        setShowDefaultLocation(false);
        setLocaionData('deliveryRadius');
      } else if (locationData[rentalType]?.specificDeliveryLocation) {
        setLocaionData('specificDeliveryLocation');
      } else {
        setLocaionData();
      }
    }
  }, [rentalType, locationData, setLocaionData, specificDeliveryLocations.length, deliveryRadius, initialLocation.location, setValue]);

  const isDisabled =
  (radioState === 'location-2' && (!currentLocation?.full_address || showDefaultLocation || isDeliveryFeeError)) || (rentalType === 2 && radioState === '');

  return (
    <Modal
      show={show}
      onClose={handleClose}
      modalWrapClassnames={classes.modalRoot}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
      mdlRef={mdlRef}
    >
      <div className="container">
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(event);
          }}
        >
          <NotificationPopover
            show={isShowNotificationPopover}
            status="warning"
            text={deliveryFeeErrorMessage}
            onClose={() => {
              setShowNotificationPopover(false);
            }}
            bottomIndent={{
              hasIndent: true,
              size: 'Xlarge-m'
            }}
          />

          <div
            className={
              classnames(
                classes.title,
                "dn db-l"
              )
            }
          >
            <Typography
              className={classes.pickUpDeliveryModalTitle}
              variant="headline"
              size="m"
            >
              {rentalType === 1 && 'Pick-up/Delivery location'}
              {rentalType === 2 && 'Delivery location'}
            </Typography>
          </div>

          <main className={`row ${classes.main} mt-8 mt-l-12`}>
            <div
              className={
                classnames(
                  classes.title,
                  "dn-l"
                )
              }
            >
              <Typography
                className={classes.pickUpDeliveryModalTitle}
                variant="headline"
                size="m"
              >
                {rentalType === 1 && 'Pick-up/Delivery location'}
                {rentalType === 2 && 'Delivery location'}
              </Typography>
            </div>
            <div
              className={
                classnames(
                  classes.leftColumn,
                  "col-12 col-m-8 col-l-4"
                )
              }
            >
              <BigVerticalRadioListContainer
                initialLocation={initialLocation}
                deliveryFee={deliveryFee}
                showDefaultLocation={showDefaultLocation}
                control={control}
                errors={errors}
                isMapLoaded={isMapLoaded}
                radioState={radioState}
                currentLocation={currentLocation}
                handleOnCalculateDeliveryFee={handleOnCalculateDeliveryFee}
                paidDelivery={paidDelivery}
                specificLocations={specificDeliveryLocations}
                setShowDefaultLocation={setShowDefaultLocation}
                setCurrentLocation={setCurrentLocation}
                setValue={setValue}
                setShowNotificationPopover={setShowNotificationPopover}
                location={location}
                setRadioState={setRadioState}
                rentalType={rentalType}
                deliveryRadius={deliveryRadius}
                locationData={locationData}
                deliveryFeeData={deliveryFeeData}
                setDeliveryFeeData={setDeliveryFeeData}
              />

            </div>

            <div
              className={
                classnames(
                  classes.rightColumn,
                  "col-l-8"
                )
              }
            >
              <div
                className={
                  classnames(
                    classes.rightColumnInner,
                    "l-m-24 mt-l-12 l-xxl-32"
                  )
                }
              >
                <Map
                  key={keyMap}
                  paidDelivery={paidDelivery}
                  initialLocation={initialLocation}
                  value={radioState !== 'location-1' && !showDefaultLocation ? currentLocation : null}
                  setMapLoaded={setMapLoaded}
                  specificDeliveryLocations={specificDeliveryLocations}

                  showGeoObjects={deliveryRadius[rentalType]}
                  showAllLocations={rentalType === 2 && !locationData?.[rentalType]?.locationSelected && radioState === ''}
                  isDefaultLocationOnly={radioState === 'location-1' && rentalType === 1 && !deliveryRadius[rentalType]}
                  isSelectedSpecificLocation={
                    ((rentalType === 1 || rentalType === 2) && radioState !== '' && radioState !== 'location-1' && radioState !== 'location-2')
                  }
                  hasNotDeliveryRadius={rentalType === 2 && !deliveryRadius[2] && radioState === ''}
                />
              </div>
            </div>
          </main>

          <footer>
            <PickUpDeliveryModalFooter
              classes={classes}
              setShowNotificationPopover={setShowNotificationPopover}
              handleOnClosePickUpDeliveryModal={handleClose}
              onSave={onSave}
              isDisabled={isDisabled}
            />
          </footer>
        </Form>
      </div>

    </Modal>
  );
};

PickUpDeliveryModal.propTypes = {
  onSelectLocation: PropTypes.func,
  onClosePickUpDeliveryModal: PropTypes.func,
  handleOnCalculateDeliveryFee: PropTypes.func,
  initialLocation: PropTypes.shape({
    city: PropTypes.string,
    full_address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    short_address: PropTypes.string,
    state: PropTypes.string
  }),
  show: PropTypes.bool,
  paidDelivery: PropTypes.number,
  isDeliveryFeeError: PropTypes.bool,
  deliveryFeeErrorMessage: PropTypes.string,
  deliveryFee: MoneyType,
  resultData: PropTypes.shape({
    delivery_fee: MoneyType,
    result: PropTypes.shape({
      errors: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.number,
          message: PropTypes.string
        })
      ),
      success: PropTypes.bool
    })
  }),
  rentalType: PropTypes.oneOf([1, 2]),
  specificDeliveryLocations: PropTypes.arrayOf(
    PropTypes.shape({
      delivery_fee: MoneyType,
      description: PropTypes.string,
      id: PropTypes.number,
      location: PropTypes.shape({
        full_address: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string
      })
    })
  ),
  locationData: PropTypes.shape({
    1: PropTypes.shape({
      deliveryRadius: PropTypes.shape({
        deliveryFee: MoneyType,
        location: PropTypes.shape({
          full_address: PropTypes.string,
          latitude: PropTypes.string,
          longitude: PropTypes.string
        })
      }),
      fullAddress: PropTypes.string,
      locationSelected: PropTypes.bool,
      radioState: PropTypes.string,
      specificDeliveryLocation: PropTypes.shape({
        id: PropTypes.number,
        location: PropTypes.shape({
          full_address: PropTypes.string,
          latitude: PropTypes.string,
          longitude: PropTypes.string
        })
      })
    }),
    2: PropTypes.shape({
      deliveryRadius: PropTypes.shape({
        deliveryFee: MoneyType,
        location: PropTypes.shape({
          full_address: PropTypes.string,
          latitude: PropTypes.string,
          longitude: PropTypes.string
        })
      }),
      fullAddress: PropTypes.string,
      locationSelected: PropTypes.bool,
      radioState: PropTypes.string,
      specificDeliveryLocation: PropTypes.shape({
        id: PropTypes.number,
        location: PropTypes.shape({
          full_address: PropTypes.string,
          latitude: PropTypes.string,
          longitude: PropTypes.string
        })
      })
    })
  }),
  deliveryRadius: PropTypes.shape({
    1: PropTypes.bool,
    2: PropTypes.bool
  }),
  deliveryFeeData: PropTypes.shape({
    deliveryFee: MoneyType,
    error: PropTypes.shape({
      code: PropTypes.number,
      message: PropTypes.string
    })
  }),
  setDeliveryFeeData: PropTypes.func
};

export default PickUpDeliveryModal;
