import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { PhoneNumberVerificationForm } from "./PhoneNumberVerificationForm";
import { CodeVerificationForm } from "./CodeVerificationForm";
import { savePhoneMutation } from "./mutations/savePhoneMutation";
import { verifyPhoneMutation } from "./mutations/verifyPhoneMutation";
import { sendPhoneVerificationCodeMutation } from "./mutations/sendPhoneVerificationCodeMutation";
import {
  extractServerErrorMessage,
  extractSuccess,
  extractServerErrorsCode
} from "../../utils/extractErrors";
import { SpinnerBack } from "../../components/Spinner/Spinner";
import { removeNonDigits } from "utils/basic";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const extractPhoneSaveSuccess = extractSuccess("phone_save");
const extractPhoneSaveError = extractServerErrorMessage("phone_save");
const extractPhoneVerifySuccess = extractSuccess("phone_verify");
const extractPhoneVerifyError = extractServerErrorMessage("phone_verify");
const extractResendCodeError = extractServerErrorMessage(
  "send_phone_verification_code"
);
const extractSendPhoneVerificationCodeSuccess = extractSuccess("send_phone_verification_code");

function useSavePhone({ initialPhone }) {
  const [savedPhone, setSavedPhone] = useState(initialPhone);
  const [isCodeSent, setCodeSent] = useState(false);
  const [saveAction, saveResult] = useMutation(savePhoneMutation);
  const [sendCodeAction, sendCodeResult] = useMutation(
    sendPhoneVerificationCodeMutation
  );

  const handleSubmit = useCallback(
    async (variables) => {
      try {
        const savedPhoneCleared = removeNonDigits(savedPhone);
        const newPhoneCleared = removeNonDigits(variables.phone_number);
        if (savedPhoneCleared !== newPhoneCleared) {
          const response = await saveAction({ variables });

          if (extractPhoneSaveSuccess(response)) {
            setCodeSent(true);
            setSavedPhone(variables.phone_number);
          }
        } else {
          const response = await sendCodeAction();

          if (extractResendCodeError(response)) {
            setCodeSent(false);
          }

          if (extractSendPhoneVerificationCodeSuccess(response)) {
            setCodeSent(true);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    [saveAction, sendCodeAction, setCodeSent, savedPhone, setSavedPhone]
  );

  const handleEdit = useCallback(() => {
    setCodeSent(false);
  }, [setCodeSent]);

  return [isCodeSent, handleSubmit, handleEdit, saveResult, sendCodeResult];
}

function useSendPhoneVerificationCode() {
  const [action, result] = useMutation(sendPhoneVerificationCodeMutation);

  const handleResendCode = useCallback(() => action(), [action]);

  return [handleResendCode, result];
}

function useVerifyPhone() {
  const [action, result] = useMutation(verifyPhoneMutation);

  const handleCompleteCode = useCallback(
    (code) =>
      action({
        variables: {
          code
        }
      }),
    [action]
  );

  return [handleCompleteCode, result];
}

export const PhoneNumberVerificationFormContainer = ({
  className,
  done,
  initialPhone,
  buttonsVariant,
  onCancel
}) => {
  const [
    isCodeSent,
    handleSubmit,
    handleEdit,
    resultSavePhone,
    resultSendCode
  ] = useSavePhone({ initialPhone });
  const [error, setError] = useState("");
  const [saveError, setSaveError] = useState("");
  const [handleResendCode, resultResendCode] = useSendPhoneVerificationCode();
  const [handleVerifyPhone, resultVerifyPhone] = useVerifyPhone();

  const verifySuccess = extractPhoneVerifySuccess(resultVerifyPhone);

  // We use intermediate storage for the error because we need to combine response from two mutations. Last error wins.
  const [verifyError, setVerifyError] = useState(null);

  useEffect(() => {
    setSaveError(extractPhoneSaveError(resultSavePhone));
    if (extractPhoneSaveError(resultSavePhone)) {
      setError(extractPhoneSaveError(resultSavePhone) || '');
    }
  }, [resultSavePhone?.data]);

  useEffect(() => {
    setSaveError(extractResendCodeError(resultSendCode));
    if (extractResendCodeError(resultSendCode)) {
      setError(extractResendCodeError(resultSendCode) || '');
    }
  }, [resultSendCode?.data]);

  useEffect(() => {
    if (extractPhoneVerifyError(resultVerifyPhone)) {
      setError(extractPhoneVerifyError(resultVerifyPhone) || '');
    }
  }, [resultVerifyPhone?.data, setVerifyError]);

  useEffect(() => {
    if (extractResendCodeError(resultResendCode)) {
      setError(extractResendCodeError(resultResendCode) || '');
    }
  }, [resultResendCode?.data, setVerifyError]);

  useEffect(() => {
    if (verifySuccess) {
      pushGoogleAnalytics("phoneVerified");
      done();
    }
  }, [verifySuccess]);

  return (
    <div className={className}>
      <PhoneNumberVerificationForm
        error={saveError}
        isCodeSent={isCodeSent}
        loading={resultSavePhone.loading || resultSendCode.loading}
        onSubmit={handleSubmit}
        onEdit={handleEdit}
        initialPhone={initialPhone}
        buttonsVariant={buttonsVariant}
        onCancel={onCancel}
      />
      {isCodeSent && (
        <CodeVerificationForm
          key={resultResendCode.loading ? 1 : 2}
          loading={resultVerifyPhone.loading || resultResendCode.loading}
          onComplete={handleVerifyPhone}
          onResend={handleResendCode}
        />
      )}

      {error?.length !== 0 && (
        <div className="t-8 b-8">
          <NotificationPopover
            show={true}
            status="error"
            text={error}
            onClose={() => {
              setError('');
              setSaveError('');
            }}
          />
        </div>
      )}
      {(resultSavePhone.loading ||
        resultSendCode.loading ||
        resultVerifyPhone.loading ||
        resultResendCode.loading) && <SpinnerBack />}
    </div>
  );
};
