import React from "react";
import { PhotoGridSlider } from "processes/RvProfile/components/PhotoGridSlider/PhotoGridSlider";
import { PhotoGrid } from "processes/RvProfile/components/PhotoGrid/PhotoGrid";
import { useFillGridPhotosTemplates } from "processes/RvProfile/components/PhotoGridSection/hooks/useFillGridPhotosTemplates";
import classes from "./PhotoGridSection.module.css";

const photos = [
  {
    id: '1',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce83e3c2.jpg',
    alt: ''
  },
  {
    id: '2',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce85747a.jpg',
    alt: ''
  },
  {
    id: '3',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce86f2c6.jpg',
    alt: ''
  },
  {
    id: '4',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce887406.jpg',
    alt: ''
  },
  {
    id: '5',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce89edd2.jpg',
    alt: ''
  },
  {
    id: '6',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce8b5ff7.jpg',
    alt: ''
  },
  {
    id: '7',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce83e3c2.jpg',
    alt: ''
  },
  {
    id: '8',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce89edd2.jpg',
    alt: ''
  },
  {
    id: '9',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daace1f5.jpg',
    alt: ''
  },
  {
    id: '10',
    src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daaac947.jpg',
    alt: ''
  }
];
export const PhotoGridSection = () => {
  const urls = useFillGridPhotosTemplates(5, photos);

  return (
    <div className={classes.addedPhotos}>
      <div className="dn-m"><PhotoGridSlider photos={photos} /></div>
      <div className="dn db-m container"><PhotoGrid gridPhotos={urls} sliderPhotos={photos} /></div>
    </div>
  );
};

// PhotoGridSection.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

