export const ACTION = {
  NAME: 'action',
  VALUE: 'ad'
};

export const DRIVER_AUTH_PASSED = {
  NAME: "driverAuthPassed",
  VALUE: 'true'
};

export const replace_url = window?.CWRR?.Authenticate?.REPLACE_URL || process.env.REACT_APP_AUTHENTICATE_REPLACE_URL;
