import { number, shape, string } from "prop-types";
import { KeyValueType } from "utils/types";

export const ListItemType = shape({
  id: number,
  action: string,
  departure_date: string,
  details_button: KeyValueType,
  duration: number,
  owner: shape({
    id: number,
    short_name: string
  }),
  pick_up_location: string,
  price: shape({
    rental_total: shape({
      user_friendly: string
    }),
    owner_earns: shape({
      user_friendly: string
    })
  }),
  renter: shape({
    id: number,
    short_name: string
  }),
  return_date: string,
  role: KeyValueType,
  rv: shape({
    rv_name: string,
    title_image: string
  }),
  status: KeyValueType,
  status_group: string,
  sub_status: string
});
