import React from "react";
import { useBookingModalContext } from "../../BookingModalContext";
import StepContainer from "../../components/StepContainer/StepContainer";
import Button from "components/Button";
import { SpinnerBack } from "components/Spinner/Spinner";
import AddOnsList from "components/AddOns/AddOnsList";

const AddOns = ({ hasScrollShadow }) => {
  const {
    rvData,
    addOns,
    setAddOns,
    handleNextStep,
    isLoading
  } = useBookingModalContext();

  if (isLoading) return <SpinnerBack />;

  const buttons = (
    <>
      <Button
        onClick={handleNextStep}
        label="Next"
      />
    </>
  );

  return (
    <StepContainer buttons={buttons} hasScrollShadow={hasScrollShadow}>
      <div className="t-16">
        <AddOnsList
          list={rvData.add_ons}
          onChange={setAddOns}
          selected={addOns}
        />
      </div>
    </StepContainer>
  );
};

export default AddOns;
