import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { BecomeAMember } from "./components/BecomeAMember";
import BookingMembership from "./BookingMembership/BookingMembership";
import BookingInsurance from "./BookingInsurance/BookingInsurance";
import BookingRentalDetails from "./BookingRentalDetails/BookingRentalDetails";
import MenuPipe from "components/MenuPipe/MenuPipe";
import { Insurance } from "./components/Insurance";
import { Rates } from "./components/Rates";
import BookingContinueButton from "./components/BookingContinueButton";
import { PickUpDelevery } from "./components/PickUpDelevery";
import { BookingCalendarProvider } from "./components/Calendar/BookingCalendarProvider";
import { PickUpDeliveryModalProvider } from "./components/PickUpDeliveryModal/PickUpDeliveryModalProvider";
import { BookingDateField } from "./BookingDateField";
import { BookingWidgetBar } from "./components/BookingWidgetBar/BookingWidgetBar";
import { BookingWidgetBarDefault } from "./components/BookingWidgetBarDefault/BookingWidgetBarDefault";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Typography from "components/Typography";
import {
  BOOKING_CONTINUE_HASH,
  BookingModalWithAuthCheck
} from "./components/BookingModal/BookingModal";
import { createCalculatePriceFetchData } from "./utils/createCalculatePriceFetchData";
import Button from "components/Button";
import classes from "./components/Booking.module.css";
import { handleBookingSegment } from "processes/Booking/utils/handleBookingSegment";
import { formatInteger } from "utils/formatNumber";
import { ReactComponent as IconQuestion } from "assets/images/icon-question.svg";
import BookingRentalTypeModal from "processes/Booking/BookingRentalTypeModal/BookingRentalTypeModal";
import Spinner from "components/Spinner/Spinner";
import { useNeedDriverVerification } from "components/Verification/DriverVerifyModal/hooks/useNeedDriverVerification";
import { useUrlParams } from "components/Verification/DriverVerifyModal/hooks/useUrlParams";

const menuRentalTypeData = [
  {
    id: 'standard',
    index: 0,
    title: 'Standard rental',
    value: "Standard"
  },
  {
    id: 'delivery-only-rental',
    index: 1,
    title: 'Delivery only',
    value: "Delivery only"
  }
];

export const BookingWidget = (props) => {
  const {
    analyticsData,
    state,
    price,
    isIMGlobalIntegrationIsEnabled,
    onCalculatePrice,
    isAllData,
    isShowModalBookingWidget,
    setIsShowModalBookingWidget,
    dispatch,
    onReserve,
    isShowCalendar,
    isShowPickUpDeliveryModal,
    rentalId,
    isShowBookingInsurance,
    isShowBookingMembership,
    isShowBookingRentalDetails,
    setRentalId,
    setShowCalendar,
    setShowPickUpDeliveryModal,
    setIsShowBookingInsurance,
    setIsShowBookingMembership,
    setIsShowBookingRentalDetails,
    isShowModal,
    setShowModal,
    onRefetchRvInitialData
  } = props;

  const { push } = useHistory();
  const bookingStartClass = !isAllData ? classes.bookingBoxStart : null;
  const isMediumDevice = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const isMinWidthMediumDevice = useMatchMedia({ minWidth: MQ_BP.medium });
  const isMinWidthSmallDevice = useMatchMedia({ minWidth: MQ_BP.small });
  const instantRentalDuration = state?.instantRentalDuration;
  const isInstantRental = state?.instantRentals;
  const [showInstantRental, setShowInstantRental] = useState(false);
  const rentalType = state?.rental_type || 1;
  const rentalValue = menuRentalTypeData.find(item => item.index === rentalType - 1).value;
  const [rentalTypeValue, setRentalTypeValue] = useState(rentalValue);
  const [showBookingRentalTypeModal, setShowBookingRentalTypeModal] = useState(false);
  const [isRefetchCalculatePrice, setIsRefetchCalculatePrice] = useState(false);
  const [isRefetchBookingModalData, setIsRefetchBookingModalData] = useState(false);

  const onSelectDate = useCallback(
    (periodFrom, periodTo) => {
      let isPickUpDeliveryLocEdit = state.deliveryOption || state.specificDeliveryLocations.length > 0;
      if (rentalType === 2) {
        isPickUpDeliveryLocEdit = state.dorDeliveryOption || state.dorSpecificDeliveryLocations.length > 0;
      }

      if (isSmallDevice && !isAllData && isPickUpDeliveryLocEdit) {
        dispatch({
          date: { departure: periodFrom, return: periodTo },
          fullAddress: state.initialLocation.full_address
        });
        setShowCalendar(false);
        setIsShowModalBookingWidget(true);
        onCalculatePrice(
          createCalculatePriceFetchData(state, periodFrom, periodTo)
        );
      } else if (!isAllData && isPickUpDeliveryLocEdit) {
        dispatch({
          date: { departure: periodFrom, return: periodTo }
        });
        setShowPickUpDeliveryModal(true);
      } else if (!isAllData && !isPickUpDeliveryLocEdit) {
        let locationData = {
          ...state.locationData,
          [rentalType]: {
            ...state.locationData[rentalType],
            locationSelected: true
          }
        };

        dispatch({
          date: { departure: periodFrom, return: periodTo },
          fullAddress: state.initialLocation.full_address,
          locationData
        });
        onCalculatePrice(
          createCalculatePriceFetchData(state, periodFrom, periodTo)
        );
      } else if (isAllData) {
        dispatch({
          date: { departure: periodFrom, return: periodTo }
        });
        onCalculatePrice(
          createCalculatePriceFetchData(state, periodFrom, periodTo)
        );
      }
      if (isShowModal) {
        setIsRefetchCalculatePrice(true);
        setIsRefetchBookingModalData(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isAllData, isSmallDevice, onCalculatePrice, setShowCalendar, setShowPickUpDeliveryModal, state, isShowModal]
  );

  const onSelectLocation = useCallback(
    ({
      fullAddress,
      deliveryLocation,
      specific_delivery_location_id,
      locationData
    }) => {
      let isRentalType1PickUpDeliveryLocEdit = state.deliveryOption || state.specificDeliveryLocations.length > 0;
      let locData = locationData;
      if (!isRentalType1PickUpDeliveryLocEdit && rentalType === 2) {
        locData = {
          1: {
            ...locationData[1],
            locationSelected: true
          },
          2: {
            ...locationData[2],
            locationSelected: true
          }
        };
      }
      if (!isAllData) {
        dispatch({
          fullAddress,
          deliveryLocation,
          specific_delivery_location_id,
          locationData: locData,
          isLocationWarning: false
        });
        if (isMediumDevice) {
          setIsShowModalBookingWidget(true);
        }
      } else {
        dispatch({
          fullAddress,
          deliveryLocation,
          specific_delivery_location_id,
          locationData,
          isLocationWarning: false
        });
      }

      onCalculatePrice({
        ...createCalculatePriceFetchData(state),
        delivery_location: deliveryLocation,
        rental_type: rentalType,
        specific_delivery_location_id
      });

      setShowPickUpDeliveryModal(false);

      if (isShowModal) {
        setIsRefetchCalculatePrice(true);
      }
    },
    [state, rentalType, isAllData, onCalculatePrice, setShowPickUpDeliveryModal, isShowModal, dispatch, isMediumDevice, setIsShowModalBookingWidget]
  );
  const onSelectInsurance = useCallback(
    (insuranceItemNumber) => {
      if (!insuranceItemNumber) setIsShowBookingInsurance(false);
      dispatch({
        insurancePackage: insuranceItemNumber
      });

      onCalculatePrice({
        ...createCalculatePriceFetchData(state),
        insurance_package: insuranceItemNumber
      });

      setIsShowBookingInsurance(false);
    },
    [
      setIsShowBookingInsurance,
      dispatch,
      onCalculatePrice,
      state
    ]
  );

  const onCloseBookingMembership = useCallback(
    (goodsamMembershipPlanId) => {
      dispatch({
        isGoodSamMember: true,
        goodsamMembershipPlanId
      });
      setIsShowBookingMembership(false);

      onCalculatePrice({
        ...createCalculatePriceFetchData(state),
        good_sam_membership_package: goodsamMembershipPlanId
      });
    },
    [dispatch, onCalculatePrice, setIsShowBookingMembership, state]
  );

  const onCloseCalendar = useCallback(() => {
    setShowCalendar(false);
  }, [setShowCalendar]);

  const onClosePickUpDeliveryModal = useCallback(() => {
    if (!isAllData) {
      dispatch({
        date: {
          departure: null,
          return: null
        }
      });
    }
    setShowPickUpDeliveryModal(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAllData]);

  const onCloseBookingRentalDetails = useCallback(
    (isGoodSamMember) => {
      if (!isGoodSamMember) {
        dispatch({
          isGoodSamMember,
          goodsamMembershipPlanId: undefined
        });
      } else {
        dispatch({
          isGoodSamMember
        });
      }

      setIsShowBookingRentalDetails(false);
    },
    [dispatch]
  );

  const onCloseBookingInsurance = useCallback(
    () => {
      setIsShowBookingInsurance(false);
    },
    [setIsShowBookingInsurance]
  );

  const showBookingModal = useCallback(() => {
    push({ hash: BOOKING_CONTINUE_HASH });
    setShowModal(true);
    setIsShowModalBookingWidget(false);
  }, [push, setShowModal]);

  const changeRentalType = useCallback((tabIndex) => {
    if (rentalType !== tabIndex) {
      const locationData = state.locationData;
      const specific_delivery_location_id = locationData?.[tabIndex]?.specificDeliveryLocation?.id;
      const deliveryRadius = locationData?.[tabIndex]?.deliveryRadius;
      const specificDeliveryLocation = locationData?.[tabIndex]?.specificDeliveryLocation;
      const locationSelected = locationData?.[tabIndex]?.locationSelected;
      const location1TabSelected = locationData?.[1]?.locationSelected;
      const location2TabSelected = locationData?.[2]?.locationSelected;
      let fullAddress = locationData?.[tabIndex]?.fullAddress;
      let isLocationWarning = false;

      setRentalTypeValue(menuRentalTypeData[tabIndex - 1].value);

      if (
        (tabIndex === 2 && (!locationData[1]?.fullAddress && !locationData[2]?.fullAddress))
        || (tabIndex === 1 && (!locationData[1]?.fullAddress && !locationData[2]?.fullAddress))
      ) {
        dispatch({
          rental_type: tabIndex
        });
        return;
      }

      if (!deliveryRadius && !specificDeliveryLocation) {
        fullAddress = state.initialLocation.short_address;
      }

      if (!locationSelected && (location1TabSelected || location2TabSelected)) {
        isLocationWarning = true;
      }

      dispatch({
        rental_type: tabIndex,
        specific_delivery_location_id: specific_delivery_location_id || null,
        fullAddress,
        isLocationWarning
      });

      onCalculatePrice({
        ...createCalculatePriceFetchData(state),
        rental_type: tabIndex,
        specific_delivery_location_id: specific_delivery_location_id || null
      });
    }
  }, [dispatch, onCalculatePrice, rentalType, state]);

  useEffect(() => {
    if (!isShowModal) {
      push({ hash: "" });
    }
  }, [isShowModal]);

  const isBecomeAMember =
    !state.isClientGoodSamMember &&
    (price.memberAndSave || price.memberAndSave === 0) &&
    !state.isGoodSamMember;

  const isBookingWidgetBar =
    isAllData && !isShowModalBookingWidget && isMediumDevice;
  const isBookingWidgetBarDefault = !isAllData && isMediumDevice;

  const isBookingWidget =
    (isAllData && isShowModalBookingWidget && isMediumDevice) ||
    isMinWidthMediumDevice;
  const isBookingWidgetDefault = !isAllData && isMinWidthMediumDevice;

  const dailyRateInitial = formatInteger(state.dailyRateDollarAsPart);
  const totalWidget = price.totalWidget && formatInteger(price.totalWidget);
  const priceWidget = price.priceWidget && formatInteger(price.priceWidget);

  const disabledTabs = [];
  const tabsTooltipData = {
    0: {
      show: !state.allowStandardRental,
      data: {
        tooltipContent:  "This RV is only available for delivery rentals",
        offset: [0, 8]
      }
    },
    1: {
      show: !state.allowDeliveryOnlyRental,
      data: {
        tooltipContent:  "This RV is only available for standard rentals",
        offset: [0, 8]
      }
    }
  };
  if (!state.allowStandardRental) {
    disabledTabs.push(0);
  }
  if (!state.allowDeliveryOnlyRental) {
    disabledTabs.push(1);
  }

  const specific_delivery_location_id = state?.specific_delivery_location_id || null;
  const deliveryRadius = {
    1: state.deliveryOption,
    2: state.dorDeliveryOption
  };
  let paidDelivery = state.paidDelivery;
  let specificDeliveryLocations = state.specificDeliveryLocations;
  let isPickUpDeliveryLocationEdit = state.deliveryOption || state.specificDeliveryLocations.length !== 0;

  if (rentalType === 2) {
    paidDelivery = state.dorPaidDelivery;
    specificDeliveryLocations = state.dorSpecificDeliveryLocations;
    isPickUpDeliveryLocationEdit = state.dorDeliveryOption || state.dorSpecificDeliveryLocations.length !== 0;
  }

  let continueButtonText = 'Continue booking';

  if (isMinWidthSmallDevice) {
    if (rentalType === 1) {
      continueButtonText = "Continue “Standard” booking";
    } else if (rentalType === 2) {
      continueButtonText = "Continue “Delivery only” booking";
    }
  }

  return (
    <>
      {isBookingWidgetBarDefault && (
        <BookingWidgetBarDefault
          {...state}
          {...price}
          dailyRateInitial={dailyRateInitial}
          setShowCalendar={setShowCalendar}
          pushSegmentAnalyticsTrackData={() => {
            handleBookingSegment({
              data: analyticsData,
              eventName: "Product Button Clicked",
              buttonName: "Check Availability",
              formName: false
            });
          }}
        />
      )}
      {isBookingWidgetBar && (
        <BookingWidgetBar
          setIsShowModalBookingWidget={setIsShowModalBookingWidget}
          pushSegmentAnalyticsTrackData={() => {
            handleBookingSegment({
              data: analyticsData,
              eventName: "Lead Form Submitted",
              goodSamMember: true
            });
          }}
          {...state}
          {...price}
        />
      )}
      {(isBookingWidget || isBookingWidgetDefault) && (
        <div
          className={`${classes.bookingContainer} ${bookingStartClass} container`}
        >
          <div
            className={`${classes.boxingContainerInner} booking-box-container-inner row`}
          >
            <div
              className={` ${classes.boxingContainer} col-12 col-s-8 col-l-auto`}
            >
              <div
                className={`booking-box booking-box--processing ${classes.booking}`}
              >
                <div
                  className={`booking-box__info booking-box__info--processing ${classes.bookingBoxInfo}`}
                >
                  <Typography
                    variant="body"
                    size="l"
                    className={classes.tabsTitle}
                  >
                    Choose rental type
                  </Typography>
                  <div
                    className={`mt-m-0 mb-28 mb-m-36 mb-l-32 ${classes.tabsContainer}`}
                  >
                    <div className="dib">
                      <MenuPipe
                        data={menuRentalTypeData}
                        tab={{ tabIndex: rentalType - 1 }}
                        size="middle"
                        onClickItem={(_, tabIndex) => {
                          changeRentalType(tabIndex + 1);
                        }}
                        tooltipData={tabsTooltipData}
                        disabledItems={disabledTabs}
                      />
                    </div>

                    <div className={classes.tabsTooltipContainer}>
                      <span
                        className={`${classes.btnIcon} dib mt-xl-4`}
                        onClick={() => setShowBookingRentalTypeModal(true)}
                      >
                        <IconQuestion  />
                      </span>
                      <BookingRentalTypeModal
                        show={showBookingRentalTypeModal}
                        onClose={() => setShowBookingRentalTypeModal(false)}
                      />
                    </div>
                  </div>
                  <div className={`${classes.boxingBoxPrice}`}>
                    {price.loading && (
                      <div className={`fcc ${classes.boxingBoxPriceSpinnerContainer}`}>
                        <Spinner />
                      </div>
                    )}
                    {!isAllData && !price.loading && (
                      <div className={`${classes.boxingBoxPriceStart}`}>
                        <div
                          className={`${classes.boxingBoxPriceStartDailyRateContainer}`}
                        >
                          <b
                            className={`${classes.boxingBoxPriceStartDailyRate}`}
                          >{`$${dailyRateInitial}`}</b>
                          <span
                            className={`${classes.boxingBoxPriceStartDailyRateTextMax}`}
                          >
                            per night
                          </span>
                          <span
                            className={`${classes.boxingBoxPriceStartDailyRateTextMin}`}
                          >
                            /night
                          </span>
                        </div>
                        <div
                          className={`${classes.boxingBoxPriceStartMinimumDuration}`}
                        >
                          {state.minimumDuration} night(s)
                          <span
                            className={`${classes.boxingBoxPriceStartMinimumDurationTextMax}`}
                          >
                            minimum
                          </span>
                          <span
                            className={`${classes.boxingBoxPriceStartMinimumDurationTextMin}`}
                          >
                            min.
                          </span>
                        </div>
                      </div>
                    )}

                    {isAllData && price?.totalWidget && (
                      <div className={`${classes.boxingBoxPriceContainer}`}>
                        <div className={`${classes.boxingBoxPriceInner}`}>
                          <div className={`${classes.boxingBoxPricePerNight}`}>
                            {price.priceWidget && (
                              <span
                                className={`${classes.boxingBoxPricePrice}`}
                              >{`$${priceWidget}`}</span>
                            )}
                            <b
                              className={`${classes.boxingBoxPriceTotal}`}
                            >{`$${totalWidget}`}</b>{" "}
                            <span
                              className={`${classes.boxingBoxPricePerNightText}`}
                            >
                              per night
                            </span>
                          </div>
                          <div className={`${classes.boxingBoxPriceDuration}`}>
                            {state.minimumDuration} nights minimum
                          </div>
                        </div>
                        {price.discountWidget && (
                          <div className={`${classes.boxingBoxPriceDiscount}`}>
                            -{price.discountWidget.toFixed()}%
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className={`${classes.dayPickerInfo} booking-box-start-daypicker`}
                  >
                    <div className={classes.dayPickerInner}>
                      <BookingDateField
                        classes={classes}
                        labelText="Departure"
                        setShowCalendar={setShowCalendar}
                        date={state.date.departure}
                        isShowDates={state.isShowDates}
                      />
                      <BookingDateField
                        classes={classes}
                        labelText="Return"
                        setShowCalendar={setShowCalendar}
                        date={state.date.return}
                        isShowDates={state.isShowDates}
                      />
                    </div>
                  </div>
                  {!isAllData && (
                    <div className={`${classes.checkAvailability}`}>
                      <div
                        className={`${classes.checkAvailabilityButtonContainer}`}
                      >
                        <Button
                          onClick={() => {
                            setShowCalendar(true);
                            handleBookingSegment({
                              data: analyticsData,
                              eventName: "Product Button Clicked",
                              buttonName: "Check Availability",
                              formName: false
                            });
                          }}
                          className={`${classes.checkAvailabilityButton}`}
                          label="Check availability"
                        />
                      </div>
                    </div>
                  )}
                  {isAllData && (
                    <div>
                      <div
                        className={`${classes.boxingBoxPickUpDeliveryContainer}`}
                      >
                        <PickUpDelevery
                          isPickUpDeliveryLocationEdit={isPickUpDeliveryLocationEdit}
                          fullAddress={state.fullAddress}
                          short_address={state.initialLocation?.short_address}
                          isLocationWarning={state.isLocationWarning}
                          setShowPickUpDeliveryModal={
                            setShowPickUpDeliveryModal
                          }
                          dispatch={dispatch}
                          rentalType={rentalType}
                          deliveryOnlyData={state.locationData?.[2]}
                        />
                      </div>
                      <Rates
                        setIsShowBookingInsurance={setIsShowBookingInsurance}
                        setIsShowBookingMembership={setIsShowBookingMembership}
                        setIsShowBookingRentalDetails={
                          setIsShowBookingRentalDetails
                        }
                        {...price}
                        {...state}
                      />
                      <BookingContinueButton
                        isInstantRental={showInstantRental}
                        contentText="You won’t be charged yet"
                        buttonText={continueButtonText}
                        onClick={() => {
                          handleBookingSegment({
                            data: analyticsData,
                            eventName: "Lead Form Initiated",
                            goodSamMember: true
                          });
                          showBookingModal();
                        }}
                        classesParent={classes}
                        rentalType={rentalType}
                        deliveryOnlyData={state.locationData?.[2]}
                      />
                      {isBecomeAMember && (
                        <BecomeAMember
                          setIsShowBookingMembership={
                            setIsShowBookingMembership
                          }
                          {...price}
                          {...state}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>

              {isAllData && <Insurance />}
            </div>
          </div>
        </div>
      )}

      <BookingCalendarProvider
        rvId={state.rvId}
        show={isShowCalendar}
        onSelectDate={onSelectDate}
        onCloseCalendar={onCloseCalendar}
        rentalType={rentalType}
        specific_delivery_location_id={specific_delivery_location_id}
        availability={[...state.availability_calendar]}
        dailyRateCentsTotal={state.dailyRateCentsTotal}
        dailyRateDollarAsPart={state.dailyRateDollarAsPart}
        minimumDuration={state.minimumDuration}
        discount={state.discount}
        total={state.total}
        departureDate={state.date.departure}
        returnDate={state.date.return}
        deliveryLocation={state.deliveryLocation}
        isInstantRental={isInstantRental}
        instantRentalDuration={instantRentalDuration}
        setShowInstantRental={setShowInstantRental}
        isDisabledPrevDates={true}
      />
      <PickUpDeliveryModalProvider
        rvId={state.rvId}
        show={isShowPickUpDeliveryModal}
        onSelectLocation={onSelectLocation}
        onClosePickUpDeliveryModal={onClosePickUpDeliveryModal}
        initialLocation={state.initialLocation}
        paidDelivery={paidDelivery}
        rentalType={rentalType}
        specificDeliveryLocations={specificDeliveryLocations}
        locationData={state.locationData}
        deliveryRadius={deliveryRadius}
      />

      {isShowBookingInsurance && (
        <BookingInsurance
          rvId={state.rvId}
          show={isShowBookingInsurance}
          onClose={onCloseBookingInsurance}
          setIsShowBookingInsurance={setIsShowBookingInsurance}
          onSelectInsurance={onSelectInsurance}
          insurance={state.insurancePackage}
          rentalType={rentalType}
        />
      )}

      <BookingMembership
        show={isShowBookingMembership}
        setIsShowBookingMembership={setIsShowBookingMembership}
        onCloseBookingMembership={onCloseBookingMembership}
        goodsamMembershipPlanId={state.goodsamMembershipPlanId}
      />
      <BookingRentalDetails
        show={isShowBookingRentalDetails}
        onCloseBookingRentalDetails={onCloseBookingRentalDetails}
        isGoodSamMemberFlag={state.isGoodSamMember}
        onCalculatePrice={(data) => {
          onCalculatePrice({
            ...createCalculatePriceFetchData(state),
            ...data
          });
        }}
        {...state}
        {...price}
      />
      {isShowModal && (
        <BookingModalWithAuthCheck
          show
          rvId={state.rvId}
          isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
          analyticsData={analyticsData}
          isInstantRental={showInstantRental}
          rentalTypeValue={rentalTypeValue}
          rentalType={rentalType}
          rvLocationData={{
            hasSpecificLocations: specificDeliveryLocations.length !== 0 || deliveryRadius[rentalType],
            isInitialLocation: state?.initialLocation?.short_address === state.fullAddress || state?.initialLocation?.full_address === state.fullAddress,
            rentalType,
            fullAddress: state.fullAddress
          }}
          session={{
            departureDate: moment(state.date.departure).format("MM/DD/YYYY"),
            returnDate: moment(state.date.return).format("MM/DD/YYYY"),
            location: state.fullAddress,
            deliveryLocation: state.deliveryLocation,
            insurance: state.insurancePackage,
            membership: state.goodsamMembershipPlanId,
            isClientGoodSamMember: state.isClientGoodSamMember,
            rentalType: state.rental_type,
            specific_delivery_location_id: state.specific_delivery_location_id,
            addons: [],
            hasAddons: state?.addons?.length > 0
          }}
          isRefetchCalculatePrice={isRefetchCalculatePrice}
          setIsRefetchCalculatePrice={setIsRefetchCalculatePrice}
          isRefetchBookingModalData={isRefetchBookingModalData}
          setIsRefetchBookingModalData={setIsRefetchBookingModalData}
          onReserve={(id) => {
            console.log(`rental: ${id}`);
            setShowModal(false);
            setRentalId(null);
            onReserve(id);
          }}
          onClose={() => setShowModal(false)}
          onChangePickUp={() => setShowPickUpDeliveryModal(true)}
          onChangeCalendar={() => setShowCalendar(true)}
          onRefetchRvInitialData={onRefetchRvInitialData}
        />
      )}
    </>
  );
};
