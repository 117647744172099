import React, { useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { membershipOffersQuery } from "components/GoodsamMembership/query.js";
import { getErrorsAndSuccess } from "utils/extractErrors";
import GoodsamMembership from "components/GoodsamMembership";
import GoodsamMembershipPlanSelection from "components/GoodsamMembership/GoodsamMembershipPlanSelection";
import { SpinnerBack } from "components/Spinner/Spinner";
import { steps as stepsList } from "./steps";
import {
  setSelectedToStep,
  setCompletedToStep,
  setSelectedAndWasSelectedToStep,
  isLastStep
} from "components/StepsMenu/utils";

const goodSamMembershipOffersEAS = getErrorsAndSuccess("good_sam_membership_offers");

const BecomeMemberShip = ({
  show,
  clubId,
  onCloseBecomeMemberShip = () => {},
  handleActionAfterLastStep,
  setIsShowMembershipModal,
  onMembershipValidationSuccess
}) => {
  const [isDeclined, setIsDeclined] = useState(false);
  const [steps, changeSteps] = useState(stepsList || []);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [planId, setPlanId] = useState(null);
  const [planPrice, setPlanPrice] = useState(null);
  const CurrentStepComponent =
    steps[currentStepIndex]?.component || GoodsamMembershipPlanSelection;

  const { loading: membershipOffersLoading, refetch: refetchMembershipOffers } = useQuery(membershipOffersQuery, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });
  const stepId = steps?.[currentStepIndex]?.id || null;

  const onChangeStep = useCallback(
    () => {
      changeSteps(setCompletedToStep(setSelectedAndWasSelectedToStep(steps, currentStepIndex + 1), currentStepIndex));
      if (!isLastStep(steps, currentStepIndex)) {
        setCurrentStepIndex(currentStepIndex + 1);
      } else {
        onCloseBecomeMemberShip();
        handleActionAfterLastStep?.();
      }
    },
    [currentStepIndex, handleActionAfterLastStep, onCloseBecomeMemberShip, steps]
  );

  const onRefetchMembershipOffers = useCallback(
    async () => {
      const response = await refetchMembershipOffers();
      const goodSamMembershipOffers = goodSamMembershipOffersEAS.getQueryData(response);

      if (goodSamMembershipOffers) {
        const { price, price_total, tax } = goodSamMembershipOffers.find(({ id }) => planId === id);

        setPlanPrice({
          price: price,
          priceTotal: price_total,
          taxes: tax
        });
      }
    },
    [planId, refetchMembershipOffers]
  );

  const goNextStep = useCallback(async ({ idStep = null } = {}) => {
    if (idStep === "payment-method") {
      await onRefetchMembershipOffers();
    } else {
      onChangeStep();
    }
  }, [onChangeStep, onRefetchMembershipOffers]);

  const onSavePlan = useCallback(
    (id, price) => {
      setPlanId(id);
      setPlanPrice(price);
      goNextStep();
    },
    [goNextStep]
  );

  const onClickStep = useCallback(({ steps, selectedStepIndex }) => {
    changeSteps(setSelectedToStep(steps, selectedStepIndex));
    setCurrentStepIndex(selectedStepIndex);
  }, []);

  return (
    <>
      {membershipOffersLoading && <SpinnerBack />}
      <GoodsamMembership
        show={show}
        onClose={() => {
          onCloseBecomeMemberShip();
          setTimeout(() => setCurrentStepIndex(0), 500);
        }}
        onMembershipValidationSuccess={onMembershipValidationSuccess}
        setIsShowMembershipModal={setIsShowMembershipModal}
        setIsGoodSamMember={() => {}}
        setIsDeclined={setIsDeclined}
        onSavePlan={onSavePlan}
        activeId="1"
        onClickStep={onClickStep}
        onNextStep={goNextStep}
        steps={steps}
        stepId={stepId}
        currentStepIndex={currentStepIndex}
        CurrentStepComponent={CurrentStepComponent}
        planPrice={planPrice}
        planId={planId}
        clubId={clubId}
      />
    </>
  );
};

BecomeMemberShip.propTypes = {};

export default BecomeMemberShip;
