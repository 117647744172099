import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import NavMenuItem from "../NavMenuItem/NavMenuItem";
import useGetElementContentScroll from "../../../../hooks/useGetElementContentScroll";
import { steps } from "./steps";
import classes from "./NavMenu.module.css";

const NavMenu = ({ id, isMobileNavMode, setIsMobileNavMode, has_payment, extraSteps }) => {
  const [listRef, listScroll] = useGetElementContentScroll();
  const handleItemClick = useCallback(() => {
    setIsMobileNavMode(false);
  }, [setIsMobileNavMode]);

  const allSteps = [...steps, ...extraSteps];

  return (
    <nav
      className={classnames(
        classes.root,
        listScroll.left === 0 && classes.withGradient
      )}
    >
      <ul ref={listRef} className={`${classes.list} _visible-all-steps-view`}>
        {allSteps.map((step) => {
          if (step.path === "transaction-history" && !has_payment) {
            return null;
          }
          return (
            <NavMenuItem
              key={step.path}
              id={id}
              step={step}
              onClick={handleItemClick}
              showActive={!isMobileNavMode}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default NavMenu;

NavMenu.propTypes = {
  id: PropTypes.number,
  setIsMobileNavMode: PropTypes.func.isRequired
};
