import { gql } from "@apollo/client";

export const tripInterruptionInsuranceCancellationContent = gql`
  query($section: String) {
    tripInterruptionInsuranceCancellationContent: content(section: $section) {
      text {
        id
        value
        type
      }
    }
  }
`;
