import React from "react";
import Modal from "components/Modal/Modal";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "./ModalDelete.module.css";

export const ModalDelete = (props) => {
  const {
    show,
    onClose,
    onDelete,
    title = "Delete driver",
    description = "Do you want to remove this additional driver?",
    cancelButtonLabel = "Cancel",
    deleteButtonLabel = "Delete"
  } = props;

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalClassnames={classes.modal}
      variant="basic"
    >
      <div>
        <Typography variant="headline" size="m" weight="bold" className={classes.title}>
          {title}
        </Typography>

        <div className="mt-m-2 mt-l-0">
          <Typography variant="subtitle" size="s" className={classes.description}>
            {description}
          </Typography>
        </div>

        <div className="mt-16 mt-m-20">
          <Button
            secondary
            onClick={onClose}
            className="mr-16 mr-l-32"
          >
            {cancelButtonLabel}
          </Button>

          <Button
            onClick={onDelete}
          >
            {deleteButtonLabel}
          </Button>
        </div>
      </div>
    </Modal>

  );
};
