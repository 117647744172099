import { useEffect, useRef, useState, useCallback } from "react";

function getWidth(el) {
  return el ? el.clientWidth : 0;
}

function getHeight(el) {
  return el ? el.clientHeight : 0;
}

export function useDimensions() {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });

  const refresh = useCallback(() => {
    setDimensions({
      width: getWidth(ref.current),
      height: getHeight(ref.current)
    });
  }, [setDimensions]);

  useEffect(() => {
    let timeoutId = null;

    function resizeListener() {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(refresh, 150);
    }

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [ref, refresh]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [
    ref,
    dimensions.width,
    dimensions.height
  ];
}
