import { gql } from "@apollo/client";

export const saveNotificationsSettings = gql`
  mutation saveNotificationsSettings(
    $notifications: [NotificationInput],
  ) {
    notifications_change(
      notifications: $notifications,
    ) {
      result {
        success
        errors {
          code
          field
          section
          message
        }
      }
    }
  }
`;
