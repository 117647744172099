import { gql } from "@apollo/client";

export const driverVerificationContentQuery = gql`
  query {
    rvrentals_app {
      content(section: "driver_verification") {
        text {
          id
          value
          type
        }
      }
    }
  }
`;
