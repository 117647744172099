import React from "react";
import cn from 'classnames';
import Button from "components/Button";
import { SpinnerBack } from "components/Spinner/Spinner";
import { useBottomBarSize } from "hooks/useBottomBarSize";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Checkbox from "components/form/Checkbox";
import { Discount } from "../Discount/Discount";
import { STEP } from "../../constants/step";
import classes from './StatusFooter.module.css';

function isShowDiscount(state) {
  return (
    state.currentStep === STEP.PERIOD
    && state.departure_date
    && state.return_date
    && !state.price?.errorMessage
    && !state.priceLoading
    && state.price
  );
}

const StatusFooter = ({
  state,
  onCancel,
  onBack,
  onNext,
  onStartOver,
  onSubmit
}) => {
  const isNotMobile = useMatchMedia({ minWidth: MQ_BP.small });
  const wrapperRef = useBottomBarSize();

  return (
    <div
      ref={wrapperRef}
      className={cn(
        classes.wrapper,
        state.currentStep === STEP.PERIOD && !isNotMobile && classes.wrapperStepPeriod,
        isShowDiscount(state) && !isNotMobile && classes.wrapperStepPeriodFullHeight
      )}>
      <div className={cn("container", classes.container)}>
        <div className={cn(
          classes.left,
          state.currentStep === STEP.PERIOD && !isNotMobile && classes.leftStepPeriod
        )}>
          {state.currentStep === STEP.PERIOD
            && state.departure_date
            && state.return_date
            && !state.price?.errorMessage
            && (
              <div className={classes.footerDiscount}>
                {state.priceLoading && <SpinnerBack className={classes.spinner} />}
                {state.price && (
                  <Discount
                    price={state.price.price}
                    total={state.price.total}
                    discount={state.price.discount}
                  />
                )}
              </div>
            )}

          {state.currentStep === STEP.SUMMARY && (
            <div className={cn("hidden-block", { 'active': isNotMobile })}>
              <Checkbox
                id="storybook-checkbox-full"
                text="Block these days to other renters"
                disabled
                defaultChecked={state.block_days}
              />
            </div>
          )}
        </div>
        <div className={cn(
          classes.right,
          state.currentStep === STEP.PERIOD && !isNotMobile && classes.rightStepPeriod,
        )}>
          <div className={cn("t-16 t-m-20 b-16 b-m-20", classes.actions)}>
            {state.currentStep === STEP.RV &&
              (<div className={classes.button}>
                <Button
                  secondary
                  label="Cancel"
                  onClick={onCancel}
                />
              </div>)
            }

            {state.currentStep !== STEP.RV && state.currentStep !== STEP.SUMMARY &&
              (<div className={classes.button}>
                <Button
                  secondary
                  label="Back"
                  onClick={onBack}
                />
              </div>)
            }

            {state.currentStep !== STEP.SUMMARY &&
              (<div className={classes.button}>
                <Button
                  label={`Next`}
                  onClick={onNext}
                  disabled={
                    !(state.currentStep === STEP.RV && state.rv)
                    && !(state.currentStep === STEP.PERIOD && state.departure_date && state.return_date && state.price?.total)
                    && !(state.currentStep === STEP.LOCATION && state.rental_type && state.valid_location)
                    && state.currentStep !== STEP.ADDONS
                  }
                />
              </div>)
            }

            {state.currentStep === STEP.SUMMARY && (<>
              <div className={classes.button}>
                <Button
                  secondary
                  label="Start over"
                  onClick={onStartOver}
                />
              </div>
              <div className={classes.button}>
                <Button
                  label={isNotMobile ? "Submit suggestion" : "Submit"}
                  onClick={onSubmit}
                />
              </div>
            </>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusFooter;
