import React from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import classes from "processes/RvProfile/components/TabSliderContainer/TabSlider/TabSlider.module.css";

export const TabSliderItem = ({ id, photo, title, description }) => {

  return (
    <div key={id} className={classes.container}>
      <div className={`${classes.content} row`}>
        <div className={`col-12 ${classes.contentColumn}`}>
          <div className={classes.photoContainer}>
            <img src={photo} alt={title} className={classes.photo} />
          </div>
        </div>
        <div className={`col-12 ${classes.contentColumn}`}>
          <div className={`t-16 t-m-20 t-l-0 t-xl-4 ${classes.title}`}>
            <Typography
              variant="subtitle"
              size="m"
              weight="bold"
              className={classes.titleText}
            >
              {title}
            </Typography>
          </div>
          <div className={classes.description}>
            <Typography
              variant="subtitle"
              size="s"
            >
              {description}
            </Typography>
          </div>

          <div className="t-20 b-20">
            <Button
              secondary
              label="Read More"
              onClick={function noRefCheck(){}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TabSliderItem.propTypes = {

};

