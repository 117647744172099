import React from "react";
import Typography from "components/Typography";
import { HeaderInterval } from "./HeaderInterval";
import tagIcon from "assets/images/lightning-small-icon.svg";
import classes from "./Header.module.css";

export const Header = (props) => {
  const {
    from,
    to,
    resetPeriod,
    minimumDuration,
    instantRentalData: {
      isInstantRental,
      instantRentalDuration
    } = {}
  } = props;

  const instantRentalDurationText = instantRentalDuration === 0
    ? "Instant rentals available for bookings."
    : `Instant rentals available for bookings more than ${instantRentalDuration} days in advance.`;
  return (
    <div className={classes.root}>
      <Typography variant="headline" size="m" className={classes.title}>
        Departure/Return
      </Typography>

      <div className={classes.interval}>
        <HeaderInterval
          from={from}
          to={to}
          resetPeriod={resetPeriod}
        />
      </div>

      <Typography variant="body" size="l" className={classes.minDuration}>
        {`${minimumDuration} night(s) minimum`}
      </Typography>

      {isInstantRental && (
        <Typography variant="body" size="l" className={`${classes.instantRentals} mt-8 mt-m-0 mt-xl-2 mt-xxl-0`}>
          <img alt="" src={tagIcon} className={`${classes.instantRentalsImg} mr-8`} />
          {instantRentalDurationText}
        </Typography>
      )}
    </div>
  );
};
