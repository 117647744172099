import React, { useCallback, useEffect, useState } from "react";
import cn from 'classnames';
import Typography from "components/Typography";
import AddOnsList from "components/AddOns/AddOnsList";

import classes from './SelectAddons.module.css';

const SelectAddons = ({ state, onSelectAddons }) => {
  const [selectedAddons, setSelectedAddons] = useState(() => {
    const result = [];

    state.add_ons.forEach((add_on) => {
      const item = state.selected_addons.find((a) => a === add_on.id);

      if (item) {
        result.push(add_on);
      }
    });

    return result;
  });

  const onChange = useCallback((items) => {
    setSelectedAddons(items);
    onSelectAddons(
      items.map(item => item.id),
      items
    );
  }, [onSelectAddons]);

  return (
    <div className={classes.wrapper}>
      <Typography
        component="h2"
        variant="headline"
        size="m"
        className={classes.title}
      >
        Add-ons
      </Typography>

      <div className={cn(classes.main, "row mt-16 mt-l-20")}>
        <div className={cn(classes.list, "col")}>
          <AddOnsList list={state.add_ons} selected={selectedAddons} onChange={onChange} />
        </div>
      </div>


    </div>
  );
};

export default SelectAddons;
