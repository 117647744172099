import { useCallback, useEffect, useRef } from "react";
import { pipe } from "ramda";
import { useLazyQuery } from "@apollo/client";
import { ensureStrings, extractList, filterList } from "./utils";
import { yearsQuery } from "./queries/yearsQuery";

const extractYearsList = pipe(extractList("rv_years"), ensureStrings);

export function useSourceYears() {
  const [load, { data }] = useLazyQuery(yearsQuery);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) load();

    return () => {
      isMounted.current = false;
    };
  }, [load]);

  const filter = filterList(extractYearsList(data));

  return useCallback(async (term) => filter(term), [filter]);
}
