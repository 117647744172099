import React from "react";
import RentalDetailsRentalInfo from "../RentalDetailsRentalInfo";
import ProfileInfoSection from "../ProfileInfoAside/ProfileInfoSection";
import classes from "processes/Rentals/RentalDetails/RentalDetailsContent.module.css";

const RentalDetailsAsideInfo = () => {
  return (
    <aside className={`col-12 col-xl-3 ${classes.aside}`}>
      <section aria-label="Rental details" className="t-20 b-20">
        <RentalDetailsRentalInfo />
      </section>
      <ProfileInfoSection />
    </aside>
  );
};

export default RentalDetailsAsideInfo;
