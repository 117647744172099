import React, { useMemo, useState, useCallback, useEffect } from "react";
import { WidgetContext } from "./WidgetContext";
import { useForm } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import { searchQuery } from "components/Search/queries/searchQuery";
import { FILTERS, filterCards } from "./filters";
import { calcDistanceBetweenPoints } from "utils/distance";
import { useTheme } from "components/Widget/hooks/useTheme";
import { SORT, sort } from "./sort";

const initialCenterDefault = {
  lat: 37.09024,
  lng: -95.712891
};

function setDistanceForCards(cards = [], center = {}) {
  return cards.map((card) => {
    const { lt, ln } = card;

    return {
      ...card,
      ds: calcDistanceBetweenPoints(
        lt,
        ln,
        center.lat,
        center.lng
      )
    };
  });
}

function makeSorting(activeSort, resultCards) {
  const type = activeSort.split("_")[1];
  const prop = SORT.getItemProp(activeSort);
  return sort(resultCards, prop, type);
}

function makeFiltering(filtersControl, allCards, activeSort, setCards) {
  const resultFilteredCards = filterCards(allCards, filtersControl.getValues());
  const resultSortedCards = makeSorting(activeSort, resultFilteredCards);
  setCards(resultSortedCards);
}
function WidgetProvider(props) {

  const {
    control: filtersControl,
    setValue,
    watch: filtersWatch
  } = useForm({
    defaultValues: FILTERS.defaults,
    shouldUnregister: false
  });

  const [loadSearch, { data, error, loading }] = useLazyQuery(searchQuery, {
    fetchPolicy: "no-cache"
  });
  const [activeFilters, setActiveFilters] = useState({});
  const [cardsLength, setCardsLength] = useState(null);
  const [theme, setTheme] = useState('light');
  const [headerTitle, setHeaderTitle] = useState('');
  const [ownerDirectory, setOwnerDirectory] = useState(undefined);
  const [iframeNumber, setIframeNumber] = useState(null);
  const [ownerPageUrl, setOwnerPageUrl] = useState({
    href: "",
    pathname: "",
    origin: ""
  });
  const [iframeUrl, setIframeUrl] = useState("");
  const [activeSort, setActiveSort] = useState(SORT.default);
  const photoUrlPrefix = data?.search?.photo_url_prefix || "";
  const rvUrlPrefix = data?.search?.rv_url_prefix || "";
  const initialCenter = useMemo(() => {
    return {
      lat: data?.search?.map?.center_lat || initialCenterDefault.lat,
      lng: data?.search?.map?.center_lng || initialCenterDefault.lng
    };
  }, [data?.search?.map?.center_lat, data?.search?.map?.center_lng]);

  const allCards = useMemo(() => {
    return setDistanceForCards(data?.search?.results, initialCenter);
  }, [data?.search?.results, initialCenter]);

  const [cards, setCards] = useState(allCards);

  const handleSetActiveFilters = useCallback(() => {
    setActiveFilters(filtersControl.getValues());
  }, [filtersControl]);

  const applyFilters = useCallback(() => {
    setActiveFilters(filtersControl.getValues());
    makeFiltering(filtersControl, allCards, activeSort, setCards);
  }, [filtersControl, allCards, activeSort]);

  const resetFilter = useCallback((name) => {
    setValue(name, FILTERS.defaults[name]);
  }, [setValue]);

  const resetAllFilters = useCallback(() => {
    Object.keys(FILTERS.defaults).forEach(name => {
      setValue(name, FILTERS.defaults[name]);
    });
    setActiveFilters(filtersControl.getValues());
  }, [filtersControl, setValue]);

  const resetClassAndLocationFilters = useCallback(() => {
    setValue('class', FILTERS.defaults['class']);
    setValue('locations', FILTERS.defaults['locations']);

    setActiveFilters(filtersControl.getValues());
  }, [filtersControl, setValue]);

  useEffect(() => {
    makeFiltering(filtersControl, allCards, activeSort, setCards);
  }, [activeSort, allCards, filtersControl]);

  useEffect(() => {
    const { date: { fullDeparture, fullReturn } } = filtersControl.getValues();

    if (data?.search?.results?.length === 0 && fullDeparture && fullReturn) {
      resetClassAndLocationFilters();
    }
  }, [data?.search?.results?.length, filtersControl, resetClassAndLocationFilters]);

  useEffect(() => {
    if (cardsLength === null && allCards?.length > 0) {
      setCardsLength(allCards?.length);
    }
  }, [cardsLength, allCards, setCardsLength]);

  useTheme(theme);

  const value = useMemo(() => ({
    photoUrlPrefix,
    rvUrlPrefix,
    filtersControl,
    handleSetActiveFilters,
    filtersWatch,
    applyFilters,
    resetFilter,
    resetAllFilters,
    activeFilters,
    activeSort,
    setActiveSort,
    cardsLength,
    theme,
    setTheme,
    headerTitle,
    setHeaderTitle,
    ownerDirectory,
    setOwnerDirectory,
    ownerPageUrl,
    setOwnerPageUrl,
    iframeUrl,
    setIframeUrl,
    iframeNumber,
    setIframeNumber,
    cards,
    allCards,
    error,
    loading,
    loadSearch
  }), [
    photoUrlPrefix,
    rvUrlPrefix,
    filtersControl,
    handleSetActiveFilters,
    filtersWatch,
    applyFilters,
    resetFilter,
    resetAllFilters,
    activeFilters,
    activeSort,
    setActiveSort,
    cardsLength,
    theme,
    setTheme,
    headerTitle,
    setHeaderTitle,
    ownerDirectory,
    setOwnerDirectory,
    ownerPageUrl,
    setOwnerPageUrl,
    iframeUrl,
    setIframeUrl,
    iframeNumber,
    setIframeNumber,
    cards,
    allCards,
    error,
    loading,
    loadSearch
  ]);
  return (
    <WidgetContext.Provider value={value} {...props} />
  );
}

WidgetProvider.propTypes = {

};

export default WidgetProvider;
