import React, { StrictMode } from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/client";
import { BrowserRouter } from "react-router-dom";
import WidgetContainer from "./components/Widget/WidgetContainer";

export const WhiteLabelWidgetApp = () => (
  <StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <WidgetContainer />
      </BrowserRouter>
    </ApolloProvider>
  </StrictMode>
);
