import React, { useRef } from "react";
import classnames from "classnames";
import Typography from "../../../../../components/Typography";
import { ReactComponent as IconCheck } from "./icon-check.svg";
import { ReactComponent as IconRefresh } from "./icon-refresh.svg";
import { ReactComponent as IconWarning } from "./icon-warning.svg";
import { MQ_BP, useMatchMedia } from "../../../../../components/MatchMedia";
import { RentalProgressBarType } from "processes/Rentals/types";
import Tooltip from "components/Tooltip/Tooltip";

const iconsMap = {
  "processing": <IconRefresh />,
  "failed": <IconWarning />,
  "pending": null,
  "passed": <IconCheck />
};

const statusMap = {
  "processing": "current",
  "failed": null,
  "pending": null,
  "passed": "completed"
};

const typesMap = {
  "processing": "processing",
  "failed": "alert",
  "pending": null,
  "passed": "success"
};

const RentalDetailsStatusBarItem = (props) => {
  const { item } = props;
  const isSmallDevice = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.medium });
  const ref = useRef(null);

  const label = item.item;
  const type = typesMap[item.style];
  const status = statusMap[item.style];

  const statusBarItem = (
    <>
      <div className="rental-details-status-bar__status">
        <span className="rental-details-status-bar__status-icon">
          {iconsMap[item.style]}
        </span>
      </div>
      <Typography
        variant="body"
        size="s"
        className="rental-details-status-bar__item-label"
      >
        {label}
      </Typography>
    </>
  );

  return (
    <li
      ref={ref}
      className={classnames(
        "rental-details-status-bar__item",
        type && `rental-details-status-bar__item--type-${type}`,
        status && `rental-details-status-bar__item--status-${status}`
      )}
    >
      {!isSmallDevice ? statusBarItem
        : (
            <Tooltip
              tooltipContent={label}
              offset={[0, 24]}
            >
              {(ref) => (
                <div ref={ref}>
                  {statusBarItem}
                </div>
              )}
            </Tooltip>
          )}
    </li>
  );
};

RentalDetailsStatusBarItem.propTypes = {
  item: RentalProgressBarType.isRequired
};

export default RentalDetailsStatusBarItem;
