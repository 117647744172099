import { gql } from "@apollo/client";
import { baseFragments } from "../../../../../queries/fragments";

export const savePayoutSettings = gql`
  ${baseFragments.bankAccount}

  mutation savePayoutSettings(
    $account_number: String!,
    $account_type: String!,
    $address: String!,
    $bank_name: String!,
    $city: String!,
    $company_name: String,
    $company_tax_id: String,
    $dob_day: String!,
    $dob_month: String!,
    $dob_year: String!,
    $first_name: String!,
    $last_name: String!,
    $routing_number: String!,
    $ssn: String!,
    $state: String!,
    $stripe_bank_account_id: String,
    $token: String!,
    $zip: String!
  ) {
    payout_info_save(
      account_number: $account_number,
      account_type: $account_type,
      address: $address,
      bank_name: $bank_name,
      city: $city,
      company_name: $company_name,
      company_tax_id: $company_tax_id,
      dob_day: $dob_day,
      dob_month: $dob_month,
      dob_year: $dob_year,
      first_name: $first_name,
      last_name: $last_name,
      routing_number: $routing_number,
      ssn: $ssn,
      state: $state,
      stripe_bank_account_id: $stripe_bank_account_id,
      token: $token,
      zip: $zip
    ) {
      user {
        bank_account {
          ...BankAccount
        }
      }
      result {
        success
        errors {
          code
          field
          section
          message
        }
      }
    }
  }
`;
