import React from "react";

import classnames from "classnames";
import { ProTips } from "../../../../components/ProTips";
import { SupportInfo } from "./SupportInfo";

import style from "./style.module.css";

export const AsideInfo = ({ className, section }) => {
  const classNames = classnames(style["aside-info"], className);

  return (
    <aside className={`${classNames}`}>
      <ProTips section={section} style={style} />
      <SupportInfo />
    </aside>
  );
};
