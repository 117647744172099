import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { PhotoCoverImage } from "components/PhotoCoverImage";
import { PhotoEditTool } from "components/PhotoEditTool";
import { PhotoUploadArea } from "components/PhotoUploadArea";
import classes from "./PhotoCoverImageSingle.module.css";
import Spinner from "components/Spinner/Spinner";

export const PhotoCoverImageSingle = ({
  coverId,
  coverContent,
  onUpload,
  onCoverDelete,
  editToolData,
  onEditDone,
  onEditCancel,
  isEdit,
  disabled
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [coverContent]);

  return (
    <div className={classes.photoCover}>
      <div className={classes.photoCoverInner}>
        {loading && (
          <Spinner className={classes.photoCoverSpinner} position="absolute" />
        )}
        {coverContent && (
          <div className={classes.photoCoverImageContainer}>
            <PhotoCoverImage
              content={coverContent}
              onEdit={() => {
                isEdit && setShowEdit(true);
              }}
              onDelete={onCoverDelete}
              className={`${classes.photoCoverImage}`}
              disabled={disabled}
            />
          </div>
        )}
        {!coverContent && (
          <div className={classnames(classes.photoUploadAreaContainer)}>
            <PhotoUploadArea
              onUpload={(files) => {
                setLoading(true);
                onUpload(files);
              }}
              disabled={disabled}
            />
          </div>
        )}
        {isEdit && (
          <div className={classes.photoEditToolContainer}>
            <PhotoEditTool
              item={showEdit ? editToolData : null}
              onDone={(values) => {
                onEditDone && onEditDone(values, coverId);
                setShowEdit(false);
                setLoading(true);
              }}
              onCancel={(values) => {
                onEditCancel && onEditCancel(values, coverId);
                setShowEdit(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

PhotoCoverImageSingle.defaultProps = {
  disabled: false
};
