import React from "react";
import PropTypes from "prop-types";
import { FILTERS } from "../../filters";
import NumberRangePicker from "../../../form/NumberRangePicker/NumberRangePicker";
import HeaderFilterButton from "./HeaderFilterButton";
import classes from "./FilterPrice.module.css";

const FilterPrice = (props) => {
  const { min, max } = props;

  return (
    <HeaderFilterButton
      id={FILTERS.ids.price}
      popperClassName={classes.popper}
      getLabelFromValue={value => (
        value.start || value.end
          ? `$${value.start || "0"}-${value.end || ""}`
          : null
      )}
      renderForm={({ value, onChange }) => (
        <NumberRangePicker
          className="mt-20"
          startInputClassName={classes.startInput}
          endInputClassName={classes.endInput}
          sliderClassName="rc-slider-track__dark"
          id="price"
          startLabel="From, $"
          endLabel="To, $"
          min={min}
          max={max}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

FilterPrice.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number
};

export default FilterPrice;
