import React, { useEffect, forwardRef, useCallback } from "react";
import PropTypes from "prop-types";
import FieldInput from "../FieldInput/FieldInput";
import { useJqueryComponent } from "../../../hooks";
import { createCachedSelector } from "re-reselect";

const optionSelector = createCachedSelector(
  (id, forwardedRef) => forwardedRef,
  (id, forwardedRef, select) => select,
  (forwardedRef, select) => ({
    forwardedRef,
    select
  })
)(id => id);

export const Autocomplete = forwardRef((props, forwardedRef) => {
  const { id, onChange, source, ...inputProps } = props;

  const handleSelect = useCallback((e, { item }) => {
    onChange(item.value);
  }, [onChange]);

  const [ref, setOptions] = useJqueryComponent(
    "Autocomplete",
    optionSelector(id, forwardedRef, handleSelect)
  );

  useEffect(() => {
    setOptions({
      source({ term }, callback) {
        if (!source) {
          return callback();
        }

        source(term)
          .then(callback)
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }, [setOptions, source]);

  return (
    <FieldInput
      ref={ref}
      id={id}
      onChange={onChange}
      {...inputProps}
    />
  );
});

/* eslint-disable react/forbid-foreign-prop-types */
if (FieldInput.propTypes) {
  const { type, ...inputPropTypes } = FieldInput.propTypes;

  Autocomplete.propTypes = {
    ...inputPropTypes,
    source: PropTypes.func.isRequired
  };
}
