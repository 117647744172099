import { createContext, useContext } from "react";

export const ListingContext = createContext(null);

export function useListingContext() {
  const context = useContext(ListingContext);

  if (!context) {
    throw new Error("useListingContext must be used within a ListingContainer");
  }

  return context;
}
