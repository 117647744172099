import React from "react";
import { calendarIcon } from "components/Icon";
import { dateFormat } from "utils/dayPickerDataFormatting";

export const BookingDateField = ({
  classes,
  labelText,
  date,
  setShowCalendar,
  isShowDates
}) => {
  const dateValue = date && isShowDates ? dateFormat(date) : "Choose date";
  return (
    <>
      <div
        className={classes.dayPickerRow}
        onClick={() => setShowCalendar(true)}
      >
        <div className={`${classes.fieldInputIcon}`}>{calendarIcon}</div>

        <span className={`${classes.dayPickerLabel}`}>{labelText}</span>
        <p className={`${classes.dayPickerValue}`}>{dateValue}</p>
      </div>
    </>
  );
};
