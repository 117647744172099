import React from "react";
import PropTypes from "prop-types";
import { ChoosePhotosListItem } from "components/ChoosePhotos";
import classnames from "classnames";
import classes from "./ChoosePhotosList.module.css";

export const ChoosePhotosList = ({ photos = [], onChangePhoto }) => {

  const items = photos.map((item, itemIndex) => (
    <ChoosePhotosListItem
      key={item?.id || itemIndex}
      onChangePhoto={onChangePhoto}
      {...item}
    />
  ));

  return (
    <div
      className={classes.container}
    >
      {items}
    </div>
  );
};

ChoosePhotosList.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string,
        srcset: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string
          })
        )
      }),
      id: PropTypes.string,
      is_used_on_rentals: PropTypes.bool
    })
  ),
  onChangePhoto: PropTypes.func
};
