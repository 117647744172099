import { gql } from "@apollo/client";

export const rvAdditionalInsuranceQuery = gql`
  query rv_additional_insurance(
    $rv: Int!
  ) {
    rv_additional_insurance(
      rv: $rv
    ) {
      description {
        id
        type
        value
      }
      insurances {
        id
        name
        required
        multi_select
        description {
          id
          type
          value
        }
        packages {
          id
          is_available
          name
          description {
            id
            type
            value
          }
          value {
            id
            type
            value
          }
          is_default
          price
        }
      }
    }
  }
`;
