export const STEPS = {
  ADDITIONAL_INSURANCE: {
    ID: 'additional-insurance',
    NAME: 'Additional insurance'
  },
  PAYMENT_METHOD: {
    ID: 'payment-method',
    NAME: 'Payment method'
  },
  PAYMENT_OPTIONS: {
    ID: 'payment-options',
    NAME: 'Payment options'
  }
};
