/**
 * @param {Array<String>} propNames
 * @return {function(Object): Object}
 */
export function trimSpaces(propNames) {
  /**
   * @param {Object} obj
   * @return {Object}
   */
  return function (obj) {
    return Object
      .keys(obj)
      .reduce((acc, key) => {
        const v = obj[key];

        acc[key] = (propNames.indexOf(key) >= 0 && typeof v === 'string') ? v.trim() : v;

        return acc;
      }, {});
  };
}
