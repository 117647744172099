export const steps = [
  {
    id: "additional-drivers",
    title: "Additional drivers",
    index: 0,
    stepNumber: 1,
    wasSelected: true,
    completed: false,
    selected: true
  },
  {
    id: "payment-method",
    title: "Payment method",
    index: 1,
    stepNumber: 2,
    wasSelected: false,
    completed: false,
    selected: false
  },
  {
    id: "checkout",
    title: "Checkout",
    index: 2,
    stepNumber: 3,
    wasSelected: false,
    completed: false,
    selected: false
  }
];
