import React from "react";
import Modal from "components/Modal/Modal";
import PayoutForm from "processes/MyAccount/steps/Payout/PayoutForm";
import { PAYOUT_DATA } from "processes/MyAccount/steps/Payout/utils";
import Typography from "components/Typography";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import { useTagSize } from "components/Tag/hooks/useTagSize";
import Tag from "components/Tag";
import classes from "./ModalPayoutForm.module.css";

export const ModalPayoutForm = (props) => {
  const {
    show,
    onClose,
    onSave = () => {},
    onSuccessSubmit,
    userData,
    updateUserData,
    accountType,
    submitButtonData
  } = props;

  const mdlRef = useScrollBarWidth();
  const tagSize = useTagSize();

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={`${classes.modalWrap}`}
      modalClassnames={`${classes.modal}`}
      modalCrossButtonClassname={classes.crossButton}
      mdlRef={mdlRef}
    >
      <div className={classes.content}>

        <div>
          <Typography variant="headline" size="m" weight="bold">
            Payout settings
          </Typography>
        </div>

        {accountType !== '' && (
          <div className="t-16 b-16 t-m-20 b-m-20">
            <Tag
              className={`${classes.tagAccountType}`}
              text={accountType}
              type="alt"
              size={tagSize}
            />
          </div>
        )}

        <PayoutForm
          userData={userData}
          updateUserData={updateUserData}
          content={PAYOUT_DATA}
          onSave={onSave}
          onSuccessSubmit={onSuccessSubmit}
          submitButtonData={submitButtonData}
        />
      </div>
    </Modal>

  );
};
