import React from "react";
import Typography from "../../../../components/Typography";
import RcCollapse from "../../../../components/RcCollapse";
import { MembershipDetails }  from "components/MembershipDetails";

export const GoodSamContent = (props) => {
  const { content } = props;

  return (
    <div className="b-12 b-m-20">
      <div className="b-xl-0">
        <Typography
          component="h2"
          variant="headline"
          size="s"
          className=""
        >
          {content.sectionTitle}
        </Typography>
      </div>
      <div className="t-16 b-16 dn-m">
        <RcCollapse
          header={content.proTipsSectionTitle}
          content={content.proTipsSectionText}
        />
      </div>
      <MembershipDetails />
    </div>
  );
};
