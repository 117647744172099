export const VERIFICATION_ERROR_CODES = {
  email: -26,
  phone: -28,
  phoneSendCode: -27,
  payout: -14
};

export const VERIFICATION_ERROR_CODE_LIST = [
  VERIFICATION_ERROR_CODES.email,
  VERIFICATION_ERROR_CODES.phone,
  VERIFICATION_ERROR_CODES.phoneSendCode,
  VERIFICATION_ERROR_CODES.payout
];

export const VERIFICATION_MODALS = {
  [VERIFICATION_ERROR_CODES.email]: 'email',
  [VERIFICATION_ERROR_CODES.phone]: 'phone',
  [VERIFICATION_ERROR_CODES.phoneSendCode]: 'phoneSendCode',
  [VERIFICATION_ERROR_CODES.payout]: 'payout'
};

export const DEFAULT_DATA = {
  code: null,
  variables: null
};
