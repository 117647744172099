import moment from 'moment';

const getCalculatePriceParams = (state, delivery_fee, rental_fee) => {
  const priceParams = {
    departure_date: moment(state.departure_date).format("YYYY-MM-DD"),
    return_date: moment(state.return_date).format("YYYY-MM-DD"),
    rv: state.rv,
    rental_fee: rental_fee ? Number(rental_fee) : state.rental_fee,
    delivery_fee: delivery_fee ? Number(delivery_fee) : state.delivery_fee,
    rental_type: state.rental_type,
    add_ons: state.add_ons,
    delivery_location: null,
    specific_delivery_location_id: null,
    insurance_package: state.insurance_package,
    is_predefined: state.is_predefined
  };

  if (state.specific_delivery_location_id) {
    priceParams.specific_delivery_location_id = state.specific_delivery_location_id;
  } else {
    if (state.delivery_location) {
      priceParams.delivery_location = state.delivery_location;
    }
  }

  return priceParams;
};

export default getCalculatePriceParams;
