import React, { useState, useEffect } from "react";
import Typography from "components/Typography";
import FieldTextarea from "components/form/FieldTextarea/FieldTextarea";
import { Modal } from "components/Modal";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Form from "components/form/Form/Form";
import InputRating from "./InputRating";
import Button from "components/Button";
import ReviewPhotosContainer from "./ReviewPhotosContainer";
import classnames from "classnames";
import style from "./style.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";

const EditReviewModal = (props) => {
  const {
    showModal,
    rentalId,
    initialPhotos,
    data,
    needsModeration,
    updateTestimonial,
    updateStars,
    updatePhotoIds,
    handleSubmit,
    handleClose
  } = props;
  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const [isSumbitDisabled, setSumbitDisabled] = useState(true);

  useEffect(() => {
    if (showModal) setSumbitDisabled(true);
  }, [showModal, setSumbitDisabled]);
  const mdlRef = useScrollBarWidth();
  return (
    <Modal
      show={showModal}
      onClose={handleClose}
      modalWrapClassnames={style.modalWrapEditReview}
      modalClassnames={style.modalEditReview}
      modalCrossButtonClassname={style.crossButton}
      variant="full-screen"
      mdlRef={mdlRef}
    >
      <div className="edit-review container b-80 mb-8 mb-m-40 b-m-80 b-l-120 mb-l-12 mt-m-0 mt-l-0">
        <div className="t-16 t-m-20">
          <div className="row">
            <div className={`edit-review__header col-xxxl-10 ${style.editReviewHeader}`}>
              <Typography
                variant="headline"
                size="m"
                weight="bold"
                component="h2"
                className={`${style.editReviewTitle} `}
              >My review</Typography>
            </div>
          </div>
          <div className="mt-m-4 fcc">
            <div className="l-0 r-0 l-l-auto r-l-auto col-l-8 col-xl-6 r-xxl-8 l-xxl-8 l-l-4 r-l-4 r-xl-4 l-xl-4">
              <Form id="edit-review__form" className="r-xl-2 l-xl-2 l-xxl-0 r-xxl-0">
                {needsModeration && (
                  <div className="t-16 b-16 b-m-20">
                    <NotificationPopover
                      show={true}
                      variant="static"
                      status="default"
                      text="We are reviewing your review changes."
                    />
                  </div>
                )}
                <div className="mb-32 mb-m-28 mt-m-4">
                  <FieldTextarea
                    label="Your testimonial"
                    value={data.testimonial}
                    onChange={(e) => {
                      updateTestimonial(e.target.value);
                      setSumbitDisabled(false);
                    }}
                    className={classnames(style.editReview__textarea,"fw mt-16")}
                    maxLength={1500}
                  />
                </div>
                <div className={classnames(style.editReview__rating, "mt-36 mt-m-0")}>
                  <Typography
                    variant="body"
                    size="l"
                    className={classnames(style.editReview__ratingTitle, "mr-m-16 mb-8 mt-m-8")}
                  >
                    Rate your experience
                  </Typography>

                  <InputRating
                    value={data.rating}
                    onChange={(num) => {
                      updateStars(num);
                      setSumbitDisabled(false);
                    }}
                    canChange
                  />
                </div>
                <div className="edit-review__photo-upload t-24 b-20 t-m-16 mt-m-2 b-l-8">
                  <ReviewPhotosContainer
                    rentalId={rentalId}
                    initialPhotos={initialPhotos}
                    updatePhotoIds={(idsToOrder, idToDelete) => {
                      updatePhotoIds(idsToOrder, idToDelete);
                      setSumbitDisabled(false);
                    }}
                  />
                </div>
              </Form>
            </div>
          </div>
          <div className={classnames(style.editReview__footer)}>
            <div className="container">
              <div className={classnames(style.editReview__footerContent, "col-12 l-m-0 r-m-0 col-xxxl-10")}>
                <div className="r-16 r-m-16 r-l-24 r-xxl-32">
                  <Button
                    secondary
                    label="Cancel"
                    onClick={handleClose}
                  />
                </div>
                <Button
                  label="Submit review"
                  onClick={handleSubmit}
                  disabled={isSumbitDisabled}
                  className={`${style.submitReviewBtn} `}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditReviewModal;
