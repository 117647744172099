import { arrayOf, number, object, shape, string } from "prop-types";
import { KeyValueType } from "utils/types";

export const RentalSuggestionType = shape({
  add_ons: arrayOf(string),
  departure_date: string,
  duration: number,
  id: number,
  initiator: KeyValueType,
  message: string,
  pick_up_location: string,
  price: object,
  return_date: string
});
