/*
 * Basic types
 */

import { bool, number, oneOf, shape, string } from "prop-types";

export const MoneyType = shape({
  cents_as_part: number,
  cents_total: number,
  dollars_as_part: number,
  user_friendly: string
});

export const KeyValueType = shape({
  key: string,
  value: string
});

export const ButtonType = shape({
  id: string,
  label: string,
  link: string,
  disabled: bool,
  new_tab: bool,
  type: oneOf(["primary", "secondary", "accent", "link"])
});
