import { arrayOf, func, number, shape, string } from "prop-types";

export const StepType = shape({
  id: string,
  index: number,
  title: string,
  component: func,
  isHidden: func
});

export const BookingSession = shape({
  departureDate: string,
  returnDate: string,
  location: string,
  insurance: number,
  membership: string,
  addons: arrayOf(number)
});
