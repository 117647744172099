import React from "react";
import Button from "../../Button";

export const ContentSectionElementButton = ({
  elementId,
  content,
  prefixLink,
  newTab,
  ...rest
}) => {
  const { label, type, link, disabled, new_tab } = content;

  return (
    <Button
      href={prefixLink ? `${prefixLink}${link}` : link}
      label={label}
      disabled={disabled}
      secondary={type === "secondary"}
      accent={type === "accent"}
      ghost={type === "ghost" || type === "link"}
      {...(new_tab || newTab
        ? {
            target: "_blank",
            rel: "noopener noreferrer"
          }
        : {})}
      {...rest}
    />
  );
};
