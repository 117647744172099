import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import RentalList from "../processes/Rentals/RentalList";
import RentalDetails from "../processes/Rentals/RentalDetails";
import { WithScrollToTop } from "components/ScrollToTop/ScrollToTop";

export const RentalsRouter = () => {
  return (
    <WithScrollToTop>
      <Switch>
        <Route exact path="/rentals" component={RentalList} />
        <Route exact path="/rentals/details" component={RentalDetails} />
        <Redirect to="/rentals" />
      </Switch>
    </WithScrollToTop>
  );
};
