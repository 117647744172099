import React, { useCallback } from "react";
import Button from "../../../Button";
import { closeIcon, filtersIcon } from "../../../Icon";
import { useSearchContext } from "../../useSearchContext";
import { FieldController } from "components/form/FieldController/FieldController";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Checkbox from "components/form/Checkbox";
import classes from "./MapControls.module.css";

const MapControls = () => {
  const {
    setMapOpen,
    setMobileFiltersOpen,
    showFullList,
    setShowFullList,
    filtersControl,
    setAllowInitialLocation,
    applyFilters,
    mapOpen
  } = useSearchContext();

  const isBetweenSmallAndLargeWidth = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.large });
  const { checked } = filtersControl.getValues().instantRentalsOnly;

  const onClickFilters = useCallback(() => {
    setMobileFiltersOpen(true);
  }, [setMobileFiltersOpen]);

  const onClickClose = useCallback(() => {
    setMapOpen(false);
    setShowFullList(false);
    setAllowInitialLocation(true);
  }, [setAllowInitialLocation, setMapOpen, setShowFullList]);

  const onClickShowFullList = useCallback((value) => {
    if (value?.target?.checked) {
      setShowFullList(true);
    } else {
      setShowFullList(false);
      setAllowInitialLocation(true);
    }
  }, [setAllowInitialLocation, setShowFullList]);

  return (
    <div className={classes.mapControls}>
      <div>
        <Button
          className={classes.filters}
          inverse
          size={"medium"}
          icon={filtersIcon}
          onClick={onClickFilters}
        />
        {mapOpen && isBetweenSmallAndLargeWidth && (
          <div className={`${classes.instantRentalsOnly} b-xl-16`}>
            <FieldController
              name="instantRentalsOnly"
              control={filtersControl}
              render={(renderProps) => (
                <Checkbox
                  {...renderProps}
                  className={classes.instantRentalsOnlyLabelContainer}
                  pseudoCheckClassName={classes.instantRentalsOnlyCheck}
                  labelTextClassName={classes.instantRentalsOnlyCheckboxTxt}
                  id={'instantRentalsOnly'}
                  name={'instantRentalsOnly'}
                  text={"Instant Rentals Only"}
                  onChange={(event) => {
                    renderProps.onChange({
                      isMobileChecked: event.target.checked,
                      checked: event.target.checked
                    });
                    applyFilters();
                  }}
                  checked={checked}
                />
              )}
            />
          </div>
        )}
        <div className={classes.showFullListCheckboxContainer}>
          <Checkbox
            id={`search-this-area`}
            name="search-this-area-checkbox"
            text={"Search this area"}
            checked={showFullList}
            className={classes.showFullListCheckbox}
            pseudoCheckClassName={classes.showFullListCheckboxSquare}
            onChange={onClickShowFullList}
          />
        </div>
      </div>
      <Button
        className={classes.close}
        inverse
        size={"medium"}
        icon={closeIcon}
        onClick={onClickClose}
      />
    </div>
  );
};

export default MapControls;
