import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import BasicCollapse from "components/RcCollapse/BasicCollapse";
import classes from "./InfoBlock.module.css";

const InfoBlock = (props) => {
  const {
    className,
    cardClassName,
    label,
    children,
    paddingVariant = "base",
    expandable,
    defaultOpen
  } = props;

  const card = (
    <div
      className={classnames(
        classes.card,
        cardClassName,
        paddingVariant === "base" ? "t-xl-32 b-xl-16 l-xl-40 r-xl-40" : "",
        "t-m-16 b-m-16 l-m-20 r-m-20",
        "t-12 b-12 l-16 r-16"
      )}
    >
      {children}
    </div>
  );

  if (expandable) {
    return (
      <BasicCollapse
        className={classnames(className, classes.root, classes.collapse)}
        headerClassName={classnames(
          classes.collapseHeader,
          "t-8 r-16 b-8 l-16"
        )}
        iconClassName={classes.headerIcon}
        defaultOpen={defaultOpen}
        renderHeader={(open) => (
          <Typography variant="subtitle" size="s" className={classes.headerTitle}>
            {label}
          </Typography>
        )}
      >
        {(open) =>
          open && ( // This helps to avoid problems with auto-height textarea
            <div className="b-16">{card}</div>
          )
        }
      </BasicCollapse>
    );
  }

  return (
    <div className={classnames(className, classes.root)}>
      {label && (
        <Typography variant="subtitle" size="s">
          {label}
        </Typography>
      )}
      <div className="mt-12">{card}</div>
    </div>
  );
};

InfoBlock.propTypes = {
  className: PropTypes.string,
  cardClassName: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  paddingVariant: PropTypes.oneOf(["base", "small"]),
  expandable: PropTypes.bool,
  defaultOpen: PropTypes.bool
};

export default InfoBlock;
