import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Modal } from "components/Modal";
import Form from "components/form/Form/Form";
import { FieldController } from "components/form/FieldController/FieldController";
import { FieldInput } from "components/form/FieldInput";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { getErrorsAndSuccess } from "utils/extractErrors";
import Typography from "components/Typography";
import Checkbox from "components/form/Checkbox";
import DateSelect from "components/form/DateSelect";
import Button from "components/Button";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { generateMedallionToken } from "./mutations/generateMedallionToken";
import { VALIDATION } from "utils/validation";
import { userQuery } from "./queries/userQuery";
import { useQuery } from "@apollo/client";
import { SpinnerBack } from "components/Spinner/Spinner";
import cn from "classnames";
import classes from "./DriverVerifyModal.module.css";
import { replace_url } from "components/Verification/DriverVerifyModal/constants";
import { driverVerificationContentQuery } from "./queries/driverVerificationContentQuery";

const generateMedallionTokenMutationEAS = getErrorsAndSuccess("generate_medallion_token");

export const DriverVerifyModal = (props) => {
  const {
    show = false,
    title = "Driver verification",
    redirectURL,
    onDriverVerificationModalSubmitSuccess,
    onClose
  } = props;

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError: setFieldsError,
    clearErrors
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      middle_name: '',
      is_middle_name: false,
      date_of_birth: {},
      years: false
    }
  });

  const {
    loading: userQueryLoading,
    data: userQueryData
  } = useQuery(userQuery, {
    fetchPolicy: "no-cache"
  });

  const {
    loading: driverVerificationContentQueryLoading,
    data: driverVerificationContentQueryData
  } = useQuery(driverVerificationContentQuery, {
    fetchPolicy: "no-cache"
  });

  const [error, setError] = useState("");
  const { first_name, last_name, date_of_birth, years, is_middle_name } = watch();

  const [action, { loading }] = useMutation(generateMedallionToken);

  useEffect(() => {
    if (
      !first_name
      && !last_name
      && userQueryData?.user?.first_name
      && userQueryData?.user?.last_name
    ) {
      setValue('first_name', userQueryData?.user?.first_name);
      setValue('last_name', userQueryData?.user?.last_name);
    }
  }, [
    first_name,
    last_name,
    setValue,
    userQueryData?.user?.first_name,
    userQueryData?.user?.last_name
  ]);

  const onSubmit = useCallback(async(values) => {
    const { is_middle_name, years, middle_name, ...otherValues } = values;

    if (!is_middle_name && !values.middle_name) {
      setError('Please complete all required fields');
      setFieldsError("middle_name", {
        type: "error",
        message: ""
      });

      return null;
    }

    const response = await action({
      variables: {
        ...otherValues,
        middle_name: !is_middle_name ? middle_name : "",
        redirect_url: redirectURL,
        date_of_birth: `${date_of_birth?.year}-${date_of_birth?.month}-${date_of_birth?.day}`
      }
    });

    if (generateMedallionTokenMutationEAS.isSuccess(response)) {
      const token = response.data.generate_medallion_token.token;
      window.location.replace(`${replace_url}${token}`);
      onDriverVerificationModalSubmitSuccess();
    } else if(generateMedallionTokenMutationEAS.isErrors(response)) {
      setError(generateMedallionTokenMutationEAS.errorMessage(response));
    }
  }, [action, date_of_birth?.day, date_of_birth?.month, date_of_birth?.year, onDriverVerificationModalSubmitSuccess, redirectURL, setFieldsError]);

  const yearsCount = Number(driverVerificationContentQueryData?.rvrentals_app?.content?.text?.[0]?.value);
  const isSubmitDisabled = Boolean(yearsCount)
    ? !first_name || !last_name || !date_of_birth?.month || !date_of_birth?.day || !date_of_birth?.year || !years
    : !first_name || !last_name || !date_of_birth?.month || !date_of_birth?.day || !date_of_birth?.year;

  return (
    <Modal
      show={show}
      onClose={onClose}
      variant="full-screen-less-768"
      modalWrapClassnames={classes.modalRoot}
      modalClassnames={classes.modal}
      modalCrossButtonClassname={classes.crossButton}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.header}>
          <Typography
            variant="headline"
            size="m"
            className={classes.title}
          >
            {title}
          </Typography>
        </div>

        <div>
          <NotificationPopover
            show={true}
            variant="static"
            status="warning"
            title="Check your data"
            text="Please ensure your details are entered correctly and match the name on the Government ID you will use for verification."
          />
        </div>

        <div className={classes.content}>
          <div className="b-20 b-m-16 t-l-20 t-xl-24">
            <Typography
              variant="subtitle"
              size="l"
              className={classes.subTitle}
            >
              Renter's name
            </Typography>

            <div className="row mt-4 mt-m-8 mt-xl-8">
              <div className="col-12 col-m-6">
                <FieldController
                  name="first_name"
                  control={control}
                  rules={VALIDATION.rules.required}
                  render={(renderProps) => (
                    <FieldInput
                      className="fw"
                      label="First name"
                      errors={errors}
                      {...renderProps}
                    />
                  )}
                />
              </div>

              <div className="col-12 col-m-6 mt-32 mt-m-0">
                <FieldController
                  name="middle_name"
                  control={control}
                  render={(renderProps) => (
                    <FieldInput
                      className="fw"
                      label="Middle name"
                      errors={errors}
                      {...renderProps}
                      disabled={is_middle_name}
                    />
                  )}
                />

                <div className="mt-8 mt-m-8">
                  <FieldController
                    name="is_middle_name"
                    control={control}
                    render={(renderProps) => (
                      <Checkbox
                        {...renderProps}
                        text={<p>I don’t have a middle name</p>}
                        onChange={(event) => {
                          renderProps.onChange(event.target.checked);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-12 mt-m-20 mt-xl-20">
            <div className="col-12 col-m-6">
              <FieldController
                name="last_name"
                control={control}
                rules={VALIDATION.rules.required}
                render={(renderProps) => (
                  <FieldInput
                    className="fw"
                    label="Last name"
                    errors={errors}
                    {...renderProps}
                  />
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="t-32 b-16 t-m-56 b-m-20 t-l-60 t-xl-64 col-12 col-m-6">
              <Typography
                variant="subtitle"
                size="l"
                className={classes.subTitle}
              >
                Date of birth
              </Typography>

              <div className="mt-4 mt-m-8 mt-xl-8">
                <FieldController
                  name="date_of_birth"
                  control={control}
                  rules={VALIDATION.rules.date}
                  render={(renderProps) => {
                    return (
                      <DateSelect
                        className={classes.dateSelect}
                        errors={errors}
                        {...renderProps}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>

          {Boolean(yearsCount) && (
            <div className="t-8 b-16">
              <FieldController
                name="years"
                control={control}
                rules={VALIDATION.rules.trueCheckbox}
                render={(renderProps) => (
                  <Checkbox
                    {...renderProps}
                    text={<p>{`I certify that I am at least ${yearsCount} years old.`}</p>}
                    onChange={(event) => {
                      renderProps.onChange(event.target.checked);
                    }}
                  />
                )}
              />
            </div>
          )}
          <div className="">
            <Typography
              variant="subtitle"
              size="s"
              weight="normal"
              className={classes.description}
            >
              We need to clarify some information before proceeding to the driver verification process.
            </Typography>
          </div>
        </div>

        <div className={cn(classes.footer, 't-48 b-20 t-m-64 b-m-20')}>
          <Button
            secondary
            className="mr-16 mr-m-24 mr-l-24 mr-xxl-32"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </div>

        {error?.length > 0 && (
          <NotificationPopover
            show
            status="error"
            text={error}
            onClose={() => {
              setError("");
              clearErrors('middle_name');
            }}
          />
        )}

        {(loading || userQueryLoading || driverVerificationContentQueryLoading) && <SpinnerBack />}
      </Form>
    </Modal>
  );
};
