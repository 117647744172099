import { MyAccountContext } from "../../MyAccountContext";
import React, { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { contentQuery } from "../../../../queries";
import FormContainer from "../../components/FormContainer/FormContainer";
import { SpinnerBlur } from "../../../../components/Spinner/Spinner";
import { useBasicTexts } from "./hooks";
import PaymentView from "../../../../components/payment/PaymentView";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const section = "account_section_payment_settings";

const PaymentDataProvider = (props) => {
  const { userData, updateUserData, setAccountSection, refetchUserQuery } = props;
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    true
  );

  const { loading, data } = useQuery(contentQuery, {
    variables: { section },
    fetchPolicy: "no-cache"
  });

  const content = data?.content;
  const texts = useBasicTexts(content);

  useEffect(() => {
    setAccountSection(section);
  }, []);

  useEffect(() => {
    if (!data?.content) {
      setShowNotificationPopover(true);
    }
  }, [data]);

  useEffect(() => {
    refetchUserQuery();
  }, [refetchUserQuery]);

  const onSave = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  if (loading) {
    return <SpinnerBlur />;
  }

  if (!content) {
    return (
      <NotificationPopover
        show={isShowNotificationPopover}
        status="error"
        text="Content not found"
        onClose={() => {
          setShowNotificationPopover(false);
        }}
      />
    );
  }

  return (
    <FormContainer
      title={texts.title || ""}
      proTipsTitle={texts.proTipsTitle || ""}
      proTipsText={texts.proTipsText || ""}
    >
      <PaymentView
        userData={userData}
        updateUserData={updateUserData}
        content={content}
        onSave={onSave}
        isMyAccount
      />
    </FormContainer>
  );
};

export const Payment = () => (
  <MyAccountContext.Consumer>
    {(value) => <PaymentDataProvider {...value} />}
  </MyAccountContext.Consumer>
);
