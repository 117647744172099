import React, { useCallback } from "react";
import Modal from "components/Modal/Modal";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import Typography from "../Typography";
import classes from "./ModalGoTo.module.css";

export const ModalGoTo = (props) => {
  const {
    show,
    onClose,
    title,
    textContent,
    buttons: {
      actionButtonLabel = '',
      cancelButtonLabel = ''
    } = {},
    linkPageTo
  } = props;

  const history = useHistory();

  const handleAction = useCallback(() => {
    history.push(linkPageTo);
    onClose();
  }, [history, linkPageTo, onClose]);
  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={`${classes.modalWrap}`}
      modalClassnames={`${classes.modal}`}
      variant="full-screen-less-768"
    >
      <div>
        <Typography
          component="h1"
          variant="headline"
          size="m"
          className={classes.title}
        >
          {title}
        </Typography>
        <div className="">
          <Typography
            className={classes.subtitle}
            variant="body"
            size="l"
          >
            {textContent}
          </Typography>
        </div>

        <div className="row">
          <div className="col t-12 t-m-20 b-16 b-m-20">
            <div className={`btn-group ${classes.buttons}`}>
              <Button onClick={onClose} secondary className={classes.buttonCancel}>
                {cancelButtonLabel}
              </Button>
              <Button onClick={handleAction}>{actionButtonLabel}</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  );
};
