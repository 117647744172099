import { useState, useEffect } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

const MAX_HEIGHT_XXSMALL = 24 * 6;
const MAX_HEIGHT_SMALL = 27 * 5;
const MAX_HEIGHT_MEDIUM = 27 * 6;
const MAX_HEIGHT_XLARGE = 27 * 5;

export function useDescriptionCollapse(ref) {
  const [isCollapse, setIsCollapse] = useState(false);
  const isXXSmallDevice = useMatchMedia({ minWidth: MQ_BP.xxSmall, maxWidth: MQ_BP.small });
  const isSmallDevice = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.medium });
  const isMediumDevice = useMatchMedia({ minWidth: MQ_BP.medium, maxWidth: MQ_BP.xLarge });
  const isXLargeDevice = useMatchMedia({ minWidth: MQ_BP.xLarge });

  useEffect(() => {
    if (ref?.current) {
      const element = ref.current;
      const elementHeight = element.offsetHeight;
      setIsCollapse(false);
      if (isXXSmallDevice) {
        if (elementHeight >= MAX_HEIGHT_XXSMALL) setIsCollapse(true);
      } else if (isSmallDevice) {
        if (elementHeight >= MAX_HEIGHT_SMALL) setIsCollapse(true);
      } else if (isMediumDevice) {
        if (elementHeight >= MAX_HEIGHT_MEDIUM) setIsCollapse(true);
      } else if (isXLargeDevice) {
        if (elementHeight >= MAX_HEIGHT_XLARGE) setIsCollapse(true);
      }
    }
  }, [ref, isSmallDevice, isMediumDevice, isXLargeDevice, isXXSmallDevice]);

  return isCollapse;
}
