import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const dateOfBirthMutation = gql`
  ${baseFragments.bankAccount}
  mutation dateOfBirthMutation($date_of_birth: String!) {
    date_of_birth_change(date_of_birth: $date_of_birth) {
      result {
        success
        errors {
          field
          message
        }
      }
      user {
        bank_account {
          ...BankAccount
        }
      }
    }
  }
`;
