import { gql } from '@apollo/client';

export const passwordChangeMutation = gql`
  mutation password_change($old_password: String, $new_password: String) {
    password_change(old_password: $old_password, new_password: $new_password) {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
      user {
        password_exists
        password_last_updated
      }
    }
  }
`;
