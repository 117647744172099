import React, { forwardRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Radio from "./Radio";
import Typography from "components/Typography";
import classes from "./BigVerticalRadioList.module.css";

const BigVerticalRadioList = forwardRef((props, ref) => {
  const { className, value, list, onChange, ...inputProps } = props;

  return (
    <div
      ref={ref}
      className={classnames(
        className
      )}
    >
      {list.map((item, itemIndex) => (
        <div key={item.value} className={`${classes.container}`}>

          <div className="row mb-12 mt-12 mt-m-16 mb-m-16">
            <div className="col">
              <Radio
                id={item.id}
                value={item.value}
                name={item.value}
                text={item.text}
                disabled={item.disabled}
                checked={value === item.value}
                onChange={(e) => {
                  onChange(e.target.checked ? item.value : null);
                }}
                className="radio-row__item"
                {...inputProps}
              />
            </div>

            <div className="col-auto">

              <Typography variant="body" size="l">
                <span  className="no-wrap">{item.cost}</span>
              </Typography>
            </div>
          </div>

          <div className={classes.content}>
            {item.contentList.map(content => {
              if (content.show) {
                return content.value;
              }
              return null;
            })}
          </div>

          {itemIndex !== list.length - 1 && (
            <div className="devider-solid mt-8 mb-8 mt-m-12 mb-m-12" />
          )}
        </div>
      ))}
    </div>
  );
});

BigVerticalRadioList.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  }))
};

export default BigVerticalRadioList;
