import React from "react";
import { ReactComponent as IconQuestion } from "./icon-question.svg";
import Tooltip from "components/Tooltip/Tooltip";
import Typography from "components/Typography";
import cn from "classnames";
import classes from "./MenuPipeTitle.module.css";

export const MenuPipeTitle = ({ title, isShowTooltip, isShowHint, hintData, tooltipData, size, isTableTabs }) => {

  const content = isShowTooltip ? (
    <Tooltip
      {...tooltipData}
    >
      {(ref) => (
        <span
          ref={ref}
          className={cn(
            classes.title,
            size === 'middle' && classes.sizeMiddle,
            size === 'large' && classes.sizeLarge
          )}
        >
          {title}
          {isShowHint && (
            <Tooltip
              {...hintData}
            >
              {(ref) => (
                <span className={`${classes.hintIcon} dib ml-4 ml-m-8 ml-l-4`} ref={ref}>
                  <IconQuestion  />
                </span>
              )}
            </Tooltip>
          )}
        </span>
      )}
    </Tooltip>
  ): (
    <span
      className={cn(
        classes.title,
        isTableTabs && classes.isTableTabs,
        size === 'middle' && classes.sizeMiddle,
        size === 'large' && classes.sizeLarge
      )}
    >
      {title}
      {isShowHint && (
        <Tooltip
          {...hintData}
        >
          {(ref) => (
            <span className={`${classes.hintIcon} dib ml-4 ml-m-8 ml-l-4`} ref={ref}>
              <IconQuestion  />
            </span>
          )}
        </Tooltip>
      )}
    </span>
  );

  return (
    <>
      <Typography
        variant="subtitle"
        size="s"
        className={cn(
          classes.titleContainer,
          isTableTabs && classes.isTableTabs,
        )}
      >
        {content}
      </Typography>
    </>
  );
};
