import React from "react";
import classnames from "classnames";
import Typography from "../../Typography";
import { searchIcon } from "../../Icon";
import Button from "../../Button";
import classes from "./NoThreads.module.css";

const NoThreads = () => {
  return (
    <div className={classnames(classes.root, "df fdc fcc l-16 r-16")}>
      <Typography variant="subtitle" size="m">
        You haven't started any conversations yet
      </Typography>
      <Typography variant="body" size="l">
        Start by searching RVs
      </Typography>
      <div className="t-12 t-m-20">
        <Button
          icon={searchIcon}
          iconPosition="before"
          href="/search"
        >
          Browse RV
        </Button>
      </div>
    </div>
  );
};

export default NoThreads;
