import React from "react";
import { AmenityItem } from "./AmenityItem";
import Typography from "../../../Typography";

export const AmenitiesList = ({ list, state, onChange }) => {
  return (
    <div className="b-16 b-m-0">
      {list.map((item, index) => (
        <div className="b-16 b-m-20" key={index}>
          <Typography variant="subtitle" size="m">
            {item.category}
          </Typography>
          <div className="row fixed-gutters t-m-4">
            {item.items.map(({ id, name, icon }) => (
              <div key={id} className="col-6 col-m-3 t-16">
                <AmenityItem
                  key={id}
                  id={id}
                  title={name}
                  img={icon}
                  checked={state[id]}
                  onChange={(checked) => onChange(id, checked)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
