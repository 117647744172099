import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import classes from "./BasicCard.module.css";

const BasicCard = (props) => {
  const { className, children } = props;

  return (
    <div className={classnames(className, classes.root)}>
      {children}
    </div>
  );
};

BasicCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default BasicCard;
