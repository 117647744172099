import React from "react";
import SearchProvider from "./SearchProvider";
import { Search } from "./Search";
import { GoogleMapsProvider } from "../GoogleMapsProvider";

export const SearchContainer = () => {
  return (
    <GoogleMapsProvider>
      <SearchProvider>
        <Search />
      </SearchProvider>
    </GoogleMapsProvider>
  );
};
