import React from "react";
import Button from "components/Button";
import Typography from "../../../../components/Typography";
import { mailIcon, headphonesIcon }  from "components/Icon";
import { Button as ButtonContent } from "components/ContentSection/elements";
import cn from 'classnames';
import style from "./style.module.css";

export const SupportInfoContent = ({ title, text, phone, email }) => {
  return (
    <div
      className={`${style["aside-info__item"]} ${style["aside-info__item-fill"]}`}
    >
      <div className={style["aside-info__block"]}>
        <Typography
          className={style["aside-info__title"]}
          component="h6"
          variant="subtitle"
          size="m"
        >
          <strong>{title}</strong>
        </Typography>
      </div>
      <div className={style["aside-info__block"]}>
        <div className={style["aside-info__description"]}>
          <Typography component="p" variant="body">
            {text}
          </Typography>
        </div>
        <div className={style["aside-info__contacts"]}>
          {false && (
            <Button
              className={cn(style.headphonesButton, 'underline')}
              icon={headphonesIcon}
              iconPosition="before"
              ghost
              href="tel:1-888-626-7576"
              label={(
                <>
                  <span className="dn db-xl">{phone}</span>
                  <span className="dn-xl">Call us</span>
                </>
              )}
            />
          )}
          {email && (
            <ButtonContent
              id="rv_rentals_support_email"
              section="linksContent"
              label={(
                <>
                  <span className="dn db-xl">support@rvrentals.com</span>
                  <span className="dn-xl">Email us</span>
                </>
              )}
              ghost
              icon={mailIcon}
              iconPosition="before"
              prefixLink="mailto:"
              className={cn('', style.rv_rentals_support_email, 'underline')}
            />
          )}
        </div>
      </div>
    </div>
  );
};
