import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import GoodsamInsuranceOptionCard from "./GoodsamInsuranceOptionCard";
import classes from "./GoodsamInsuranceOptions.module.css";
import { useMatchMedia } from "components/MatchMedia";
import GoodsamInsuranceOptionCollapse from "./GoodsamInsuranceOptionCollapse";

const GoodsamInsuranceOptions = forwardRef((props, ref) => {
  const { items, value, wrapRef, ...other } = props;
  const minWidth1024 = useMatchMedia({ minWidth: 1024 });
  if (minWidth1024) {
    return (
      <ul
        className={`${classes.optionsList} ${classes.optionsListCard} t-20 b-20`}
      >
        {items.map((item) => (
          <li
            key={item.id}
            className={`${classes.optionsListItem} ${classes.optionsListItemCard}`}
          >
            <GoodsamInsuranceOptionCard
              key={item.id}
              ref={ref}
              id={item.id}
              value={item.id}
              checked={String(value) === item.id}
              label={item.label}
              price={item.price}
              period={item.period}
              description={item.description}
              {...other}
            />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul className={`${classes.optionsList} t-8 t-m-12 b-8 b-m-12`}>
      {items.map((item) => (
        <li
          key={item.id}
          className={`${classes.optionsListItem} ${classes.optionsListItemCollapse}`}
        >
          <GoodsamInsuranceOptionCollapse
            ref={ref}
            wrapRef={wrapRef}
            id={item.id}
            value={item.id}
            checked={String(value) === item.id}
            label={item.label}
            price={item.price}
            period={item.period}
            description={item.description}
            {...other}
          />
        </li>
      ))}
    </ul>
  );
});

GoodsamInsuranceOptions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.shape({
        cents_total: PropTypes.number.isRequired,
        cents_as_part: PropTypes.number.isRequired,
        dollars_as_part: PropTypes.number.isRequired
      }),
      period: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  value: PropTypes.string.isRequired
};

export default GoodsamInsuranceOptions;
