import React, { useMemo, useCallback } from "react";
import moment from "moment";
import classnames from "classnames";
import Typography from "components/Typography";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import BasicCollapse from "components/RcCollapse/BasicCollapse";
import { Controller } from "react-hook-form";
import Select from "components/form/Select";
import DoubleDateSelectInterval from "components/form/DoubleDateSelectInterval/DoubleDateSelectInterval";

import classes from "./Filters.module.css";

export const Filters = (props) => {

  const {
    allCards,
    currentRvSelectData,
    setCurrentRvSelectData,
    control,
    filterWatch,
    yearsOptions,
    onRvSelectChange,
    onDateSelectsChange
  } = props;

  const isXxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const { select1, select2, rvSelect } = filterWatch();

  const rvSelectData = useMemo(() => {
    const result = [{
      text: "All RVs",
      value: "0"
    }];

    if (currentRvSelectData && currentRvSelectData?.value !== "0") {
      result.push(currentRvSelectData);
    }

    allCards.forEach(card => {
      if (result.find((item) => item.value === String(card.rv_id)) === undefined) {
        result.push({
          text: card.rv_name,
          value: String(card.rv_id)
        });
      }
    });

    return result;
  }, [allCards, currentRvSelectData]);

  const handleOnDateSelectsChange = useCallback(() => {
    const value = rvSelectData.find(data => data.value === rvSelect);
    setCurrentRvSelectData(value);
    onDateSelectsChange();
  }, [onDateSelectsChange, rvSelect, rvSelectData, setCurrentRvSelectData]);

  const resultMonth1 = moment(`${select1.year}-${select1.month}`).format("MMMM");
  const resultYear1 = select1.year;
  const resultMonth2 = moment(`${select2.year}-${select2.month}`).format("MMMM");
  const resultYear2 = select2.year;
  const rvSelectValue = rvSelectData.find(data => data.value === rvSelect)?.text || "All RVs";

  return (
    <div className={classes.filters}>

      <div className="mb-8">
        {isXxSmall ? (
          <div className="l-16 r-16">
            <BasicCollapse
              renderHeader={() => (
                <Typography variant="body" size="l" weight="bold">Period</Typography>
              )}
            >
              {(open) => (
                <div>
                  {open ? (
                    <Controller
                      name="select1"
                      control={control}
                      render={({ onChange: onSelect1Change }) => {
                        return (
                          <Controller
                            name="select2"
                            control={control}
                            render={({ onChange: onSelect2Change }) => {
                              return (
                                <DoubleDateSelectInterval
                                  yearsOptions={yearsOptions}
                                  select1Value={select1}
                                  select2Value={select2}
                                  onSelect1Change={onSelect1Change}
                                  onSelect2Change={onSelect2Change}
                                  onChange={handleOnDateSelectsChange}
                                />
                              );
                            }}
                          />
                        );
                      }}
                    />
                  ): (
                    <Typography variant="body" size="s" className={classes.placeholderDateText}>
                      {resultMonth1}, {resultYear1} — {resultMonth2}, {resultYear2}
                    </Typography>
                  )}
                </div>
              )}
            </BasicCollapse>
          </div>
        ) : (
          <div className="t-12">
            <Controller
              name="select1"
              control={control}
              render={({ onChange: onSelect1Change }) => {
                return (
                  <Controller
                    name="select2"
                    control={control}
                    render={({ onChange: onSelect2Change }) => {
                      return (
                        <DoubleDateSelectInterval
                          yearsOptions={yearsOptions}
                          select1Value={select1}
                          select2Value={select2}
                          onSelect1Change={onSelect1Change}
                          onSelect2Change={onSelect2Change}
                          onChange={handleOnDateSelectsChange}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </div>
        )}
      </div>

      <div
        className={classnames(
          classes.pageDevider,
          "devider-solid dn-m"
        )}
      />

      <div className="mt-8">
        {isXxSmall ? (
          <div className="l-16 r-16">
            <BasicCollapse
              renderHeader={() => (
                <Typography variant="body" size="l" weight="bold">RV’s</Typography>
              )}
            >
              {(open) => (
                <div
                  className={classnames(
                    open && "t-16 b-16"
                  )}
                >
                  {open ? (
                    <Controller
                      name="rvSelect"
                      control={control}
                      render={({ onChange: rvSelectOnChange, ...renderProps }) => {
                        return (
                          <Select
                            className="fw"
                            id="rvSelect"
                            label="RVs"
                            options={rvSelectData}
                            {...renderProps}
                            value={rvSelect}
                            onChange={(value) => {
                              rvSelectOnChange(value);
                              onRvSelectChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  ) : (
                    <Typography variant="body" size="s" className={classes.placeholderDateText}>
                      {rvSelectValue}
                    </Typography>
                  )}
                </div>
              )}
            </BasicCollapse>
          </div>
        ) : (
          <div className="mt-m-20">
            <Controller
              name="rvSelect"
              control={control}
              render={({ onChange: rvSelectOnChange, ...renderProps }) => {
                return (
                  <Select
                    className="fw"
                    id="rvSelect"
                    label="RVs"
                    options={rvSelectData}
                    {...renderProps}
                    value={rvSelect}
                    onChange={(value) => {
                      rvSelectOnChange(value);
                      onRvSelectChange(value);
                    }}
                  />
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
