import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ApplyCouponModalForm } from "./ApplyCouponModalForm";
import ApplyCouponModal from "./ApplyCouponModal";
import { checkCouponQuery } from "components/ApplyCoupon/query";
import { SpinnerBack } from "components/Spinner/Spinner";
import Message from "components/Message";
import { extractServerErrors } from "utils/extractErrors";
import { useLazyQuery } from "@apollo/client";

const extractErrors = extractServerErrors("check_coupon");

export const ApplyCoupon = ({ content, show, toggleShow, onSave }) => {
  const [errors, setErrors] = useState(null);
  const [check, result] = useLazyQuery(checkCouponQuery, {
    fetchPolicy: "no-cache"
  });

  const onToggleShow = useCallback(() => {
    setErrors(null);
    toggleShow();
  }, [toggleShow]);

  const onSubmit = useCallback(async (variables) => {
    await check({ variables });
  }, [check]);

  useEffect(() => {
    if (result.data?.check_coupon?.result.success) {
      onSave(result.data.check_coupon.coupon);
    }

    if (result.data?.check_coupon?.result?.errors) {
      setErrors(extractErrors(result)?.[0]?.message);
    }
  }, [onSave, result, result.data]);

  return (
    <>
      <ApplyCouponModal show={show} onClose={onToggleShow}>
        <ApplyCouponModalForm
          content={content}
          toggleShow={onToggleShow}
          onSubmit={onSubmit}
          customError={errors && (
            <Message type="error" text={errors} />
          )}
        />
        {result.loading && <SpinnerBack />}
      </ApplyCouponModal>
    </>
  );
};

ApplyCoupon.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    cancelButton: PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired,
    confirmButton: PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
