import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import Button from "components/Button";
import { AdditionalDriversListItem } from "components/AdditionalDrivers";
import cn from 'classnames';
import classes from "./AdditionalDriversList.module.css";

const AdditionalDriversList = (props) => {
  const {
    drivers,
    isOwner,
    onDelete,
    allowedRemoveDrivers
  } = props;

  return (
    <div className={classes.list}>
      {drivers.map((driver, index) => (
        <AdditionalDriversListItem
          key={driver?.id || index}
          onDelete={onDelete}
          isOwner={isOwner}
          allowedRemoveDrivers={allowedRemoveDrivers}
          {...driver}
        />
      ))}
    </div>
  );
};

AdditionalDriversList.propTypes = {

};

export default AdditionalDriversList;
