import { gql } from "@apollo/client";

export const userQuery = gql`
  query {
    user {
      id
      good_sam_membership_validation {
        key
        value
      }
    }
  }
`;
