import React from "react";
import Typography from "components/Typography";
import classes from "./TabSliderMenu.module.css";

export const TabSliderMenu = ({ data, tabActive, onClick }) => {
  const menuItems = data.map(({ id, title }, tabIndex) => {
    return (
      <li key={id} className={`${classes.menuItem}`}>
        <div
          className={`
            ${classes.menuItemInner} 
            ${tabActive === tabIndex ? classes.menuItemActive : ''}
            t-2 b-4 l-24 r-24 t-m-8 b-m-8
          `}
          onClick={() => onClick(tabIndex)}
        >
          <Typography
            variant="body"
            size="l"
          >
            {title}
          </Typography>
        </div>
      </li>
    );
  });

  return (
    <div className={classes.container}>
      <ul className={`${classes.menu} ui-menu`}>
        {menuItems}
      </ul>
    </div>
  );
};

TabSliderMenu.propTypes = {

};

