import React from "react";
import Typography from "components/Typography";
import { TextItem } from "components/TextItem";
import { checkGreenIcon } from "components/Icon";
import classes from "./AdditionalInsuranceItemHeader.module.css";
import Switch from "components/form/Switch";
import { ADD_INSURANCE_LIST_MODES, ADD_INSURANCE_EXTRA_IDS } from "components/AdditionalInsuranceList/constants";
import cn from 'classnames';

const AdditionalInsuranceItemHeader = (props) => {
  const {
    planName,
    insuranceDescription,
    required,
    selectedItemPackages = [],
    isSwitchChecked,
    onSwitchChange,
    showOptions = false,
    mode = ADD_INSURANCE_LIST_MODES.USING
  } = props;

  const showSelectedItemPackagesIcon = mode === ADD_INSURANCE_LIST_MODES.USING && !showOptions && (selectedItemPackages?.[0]?.id >= 0 || selectedItemPackages?.[0]?.id === ADD_INSURANCE_EXTRA_IDS.PACKAGE_ID);

  return (
    <header className={classes.header}>
      <div className={classes.planContainer}>
        <Typography
          variant="subtitle"
          size="m"
          className={classes.planName}
        >
          {planName}
        </Typography>

        {Boolean(insuranceDescription?.value) && (
          <Typography
            component="div"
            variant="body"
            size="l"
            className={classes.insuranceDescription}
          >
            <TextItem
              data={insuranceDescription}
              plainStyle={classes.insuranceDescriptionValuePlainStyle}
              htmlStyle={classes.insuranceDescriptionHtmlStyle}
            />
          </Typography>
        )}

        {selectedItemPackages?.length > 0 && !showOptions && (
          <div
            className={cn(
              classes.selectedItemPackagesList,
              showSelectedItemPackagesIcon && classes.selectedItemPackagesListWithBeforeIcon
            )}
          >
            {showSelectedItemPackagesIcon && (
              <div className={classes.selectedItemPackagesBeforeIcon}>
                {checkGreenIcon}
              </div>
            )}

            {selectedItemPackages.map(({
              id,
              name: packageName,
              value: packageValue
            }, selectedItemPackagesIndex) => (id >= 0 || id === ADD_INSURANCE_EXTRA_IDS.PACKAGE_ID) && (
              <>
                <div className={classes.selectedItemPackagesListItem}>
                  <Typography
                    key={id}
                    className={classes.selectedPackages}
                    component="div"
                    variant="body"
                    size="l"
                  >
                    <div className={classes.selectedItemPackagesListItemContent}>
                      <span>{packageName}, </span>
                      <div className="dib">
                        <div className="dib">
                          <TextItem
                            data={packageValue}
                            plainStyle={classes.valuePlainStyle}
                          />
                        </div>
                        {selectedItemPackages?.length - 1 > selectedItemPackagesIndex && <span className="mr-16">;</span>}
                      </div>
                    </div>
                  </Typography>
                </div>
              </>
            ))}
          </div>
        )}
      </div>

      {mode === ADD_INSURANCE_LIST_MODES.SETTINGS && (
        <div className={classes.switchContainer}>
          <Switch
            onChange={onSwitchChange}
            checked={isSwitchChecked}
            disabled={required}
          />
        </div>
      )}
    </header>
  );
};

AdditionalInsuranceItemHeader.propTypes = {
};

export default AdditionalInsuranceItemHeader;
