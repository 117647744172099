import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  lensProp,
  over,
  path,
  pipe,
  prop,
  defaultTo,
  pick,
  map,
  filter
} from "ramda";
import { useMutation } from "@apollo/client";
import { ListingContext } from "../../ListingContext";
import { filterInitialData } from "../filterInitialData";
import {
  extractServerErrors,
  extractSuccess
} from "../../../../utils/extractErrors";
import { otherFeesMutation } from "../../mutations/otherFeesMutation";
import { OtherFeesForm } from "./OtherFeesForm";
import { convertCents } from "../../../../utils/convertCents";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { otherFeesAddonsSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";

const extractRvUpdateErrors = extractServerErrors("rv_update");
const extractRvUpdateSuccess = extractSuccess("rv_update");
const extractRv = path(["data", "rv_update", "rv"]);

const filterInitialListingDetails = filterInitialData([
  "limited_daily_mileage",
  "miles_included",
  "mileage_overage_rate",
  "limited_generator_hours",
  "generator_hours_included",
  "generator_overage_rate",
  "generator_not_applicable",
  "cleaning_fee",
  "disposal_fee",
  "add_ons"
]);

const defaultToArr = defaultTo([]);

const processInitialData = pipe(
  over(
    lensProp("mileage_overage_rate"),
    pipe(prop("cents_total"), convertCents)
  ),
  over(
    lensProp("generator_overage_rate"),
    pipe(prop("cents_total"), convertCents)
  ),
  over(lensProp("cleaning_fee"), pipe(prop("cents_total"), convertCents)),
  over(lensProp("disposal_fee"), pipe(prop("cents_total"), convertCents)),
  over(
    lensProp("add_ons"),
    pipe(
      defaultToArr,
      map(pick(["name", "price", "price_type"])),
      map(
        pipe(
          over(lensProp("price"), pipe(prop("cents_total"), convertCents)),
          over(lensProp("price_type"), pipe(prop("key"), defaultTo("1")))
        )
      )
    )
  ),
  filterInitialListingDetails
);

const normalizeValues = pipe(
  over(lensProp("miles_included"), (v) => (v ? parseInt(v, 10) : v)),
  over(lensProp("mileage_overage_rate"), (v) => (v ? parseFloat(v) : v)),
  over(lensProp("generator_hours_included"), (v) => (v ? parseInt(v, 10) : v)),
  over(lensProp("generator_overage_rate"), (v) => (v ? parseFloat(v) : v)),
  over(lensProp("cleaning_fee"), (v) => parseFloat(v)),
  over(lensProp("disposal_fee"), (v) => parseFloat(v)),
  over(
    lensProp("add_ons"),
    pipe(
      defaultToArr,
      map(
        pipe(
          over(lensProp("price"), (v) => parseFloat(v)),
          over(lensProp("price_type"), (v) => parseInt(v, 10))
        )
      )
    )
  )
);

const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "add_ons"),
  map(pick(["field", "message"]))
);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "add_ons")
);

const OtherFeesDataProvider = ({
  context,
  updateContext,
  currStep,
  prevStep,
  nextStep
}) => {
  const { id: initialId, not_applicable_listing_options } = context.initialData;
  const isSegmentSent = useRef(null);
  const history = useHistory();
  const [action, result] = useMutation(otherFeesMutation);

  const handleSave = useCallback(
    async (values, { toNextStep = true }) => {
      const response = await action({
        variables: {
          ...normalizeValues(values),
          id: initialId
        }
      });

      if (extractRvUpdateSuccess(response)) {
        const rv = extractRv(response);

        if (rv) {
          pushGoogleAnalytics("listVehicleOtherFees");
          handleListingSegment(rv, "Lead Form Step Completed", "Other Fees & Add-ons", 8, {
            location: rv?.location?.short_address,
            cust_asset_class: rv?.class,
            cust_asset_id: String(rv?.id),
            cust_asset_make: rv?.make,
            cust_asset_model: rv?.model,
            cust_asset_year: String(rv?.year),
            cust_asset_length: rv?.length?.inches_total,
            cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
            cust_asset_sleepnumber: rv?.sleeps,
            cust_asset_slideouts: rv?.slide_outs,
            cust_asset_weight: rv?.weight
          });
          updateContext({
            initialData: {
              ...context.initialData,
              ...rv
            },
            errors: cleanSectionErrors(context.errors)
          });

          if (nextStep && toNextStep) {
            history.push(`/listing/${nextStep.path}?id=${rv.id}`, {
              prevStep: currStep
            });
          }
        }
      }
    },
    [
      action,
      initialId,
      updateContext,
      context.initialData,
      context.errors,
      nextStep,
      history,
      currStep
    ]
  );

  const handleBack = useCallback(() => {
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);


  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  useEffect(() => {
    if (!isSegmentSent.current) {
      const rv = context?.initialData;
      handleListingSegment(context?.initialData, "Lead Form Step Viewed", "Other Fees & Add-ons", 8, {
        cust_asset_class: rv?.class,
        cust_asset_id: String(rv?.id),
        cust_asset_make: rv?.make,
        cust_asset_model: rv?.model,
        cust_asset_year: String(rv?.year),
        cust_asset_length: rv?.length?.inches_total,
        cust_asset_price: Math.round(rv?.daily_rate?.cents_total / 100),
        cust_asset_sleepnumber: rv?.sleeps,
        cust_asset_slideouts: rv?.slide_outs,
        cust_asset_weight: rv?.weight
      });
      isSegmentSent.current = true;
    }
  }, [context?.initialData]);

  const publishErrors = filterPublishErrorsBySection(context.errors);

  const stepErrors = extractRvUpdateErrors(result);

  return (
    <>
      <OtherFeesForm
        initialData={processInitialData(context.initialData)}
        notApplicableOptions={not_applicable_listing_options}
        requestErrors={publishErrors.length ? publishErrors : stepErrors}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        loading={result.loading}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        prevStep={prevStep}
        nextStep={nextStep}
      />
      {result.loading && <SpinnerBack />}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const otherFeesAddonsSectionContentPredicate = everyKey([
  "otherFeesAddonsSectionContent"
]);
export const OtherFees = ({ currStep, prevStep, nextStep }) => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <ContentLoader
          query={otherFeesAddonsSectionContentQuery}
          predicate={otherFeesAddonsSectionContentPredicate}
          render={() => (
            <OtherFeesDataProvider
              context={context[0]}
              updateContext={context[1]}
              currStep={currStep}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
        />
      )}
    </ListingContext.Consumer>
  );
};
