import { useCallback, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { makesQuery } from "./queries/makesQuery";
import { extractList, filterList } from "./utils";

const extractMakesList = extractList("rv_makes");

export function useSourceMakes(manufacturer) {
  const [load, { data }] = useLazyQuery(makesQuery, {
    variables: {
      manufacturer
    }
  });
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) load();

    return () => {
      isMounted.current = false;
    };
  }, [load]);

  const filter = filterList(extractMakesList(data));

  return useCallback(async (term) => filter(term), [filter]);
}
