import React from "react";
import ReactDOM from "react-dom";
import { MainApp } from "./MainApp";
import { MapBlockApp } from "./MapBlockApp";
import { ChatApp } from "./ChatApp";
import { BookingWidgetApp } from "./BookingWidgetApp";
import { WhiteLabelWidgetApp } from "./WhiteLabelWidgetApp";

/**
 * Render the component into the main element of the page
 * @param {ReactComponent} AppComponent
 */
function renderMainApp(AppComponent) {
  if (!AppComponent) {
    throw new Error("The main app component is not defined");
  }

  const mainElementSelector = "main";
  const mainElement = document.getElementById(mainElementSelector);

  if (mainElement) {
    ReactDOM.render(<AppComponent mainElementSelector={mainElementSelector} />, mainElement);
  }
}

/**
 * Render the component into the main element of the page
 */
function renderBookingWidget() {
  const bookingWidgetElement = document.getElementById("react-booking-widget");

  if (bookingWidgetElement) {
    ReactDOM.render(<BookingWidgetApp />, bookingWidgetElement);
  }
}

/**
 * Render interactive blocks on the landing pages
 */
function renderMapBlockApps() {
  const elements = document.getElementsByClassName("react-map");

  for (let element of elements) {
    ReactDOM.render(
      <MapBlockApp location={element.getAttribute("data-location")} />,
      element
    );
  }
}

/**
 * Render interactive blocks on the landing pages
 */
function renderChatApp() {
  const chatContainerSelector = "chat-container";

  const element = document.getElementById(chatContainerSelector);

  if (element) {
    ReactDOM.render(<ChatApp mainElementSelector={chatContainerSelector} />, element);
  }
}

/**
 * Render interactive blocks on the landing pages
 */
function renderWhiteLabelWidgetApp() {
  const widgetContainerSelector = "white-label-widget";

  const element = document.getElementById(widgetContainerSelector);

  if (element) {
    ReactDOM.render(<WhiteLabelWidgetApp />, element);
  }
}

/**
 * @return {ReactComponent}
 */
function getAppComponent() {
  if (process.env.NODE_ENV === "production") {
    return MainApp;
  }

  if (process.env.REACT_APP_ENTRY === "map") {
    return MapBlockApp;
  }

  if (process.env.REACT_APP_ENTRY === "chat") {
    return ChatApp;
  }

  if (process.env.REACT_APP_ENTRY === "booking") {
    return BookingWidgetApp;
  }

  return MainApp;
}

renderMainApp(getAppComponent());
renderMapBlockApps();
renderChatApp();
renderBookingWidget();
renderWhiteLabelWidgetApp();
