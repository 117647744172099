import React, { useMemo, useCallback, useRef } from "react";
import classnames from "classnames";
import moment from "moment";
import Typography from "components/Typography";
import Button from "components/Button/Button";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { getRvGeneratorValue, getRvMileageValue, getRvPickUpLocation } from "utils/rv";
import Tooltip from "components/Tooltip/Tooltip";
import { doNeedTooltip } from "components/Tooltip/utils/doNeedTooltip";
import lightningIcon from "assets/images/lightning-small-icon.svg";
import RvInfo from 'components/RentalPaymentModal/components/RvInfo/RvInfo';

import style from "./AsideRvInfo.module.css";

const AsideRvInfo = ({
  rental,
  showAsideRvInfoDetails,
  setShowAsideRvInfoDetails
}) => {
  const {
    rv: rvData,
    departure_date: startDate,
    return_date: endDate,
    pick_up_location: fullAddress,
    isInstantRental = true,
    rental_type
  } = rental;

  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const isMaxMedium = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isMinHeightMedium = useMatchMedia({ mediaQuery: "(min-width: 1024px) and (min-height: 730px)" });

  const getPickUpContent = useCallback((value, width) => {
    if (isMinHeightMedium && doNeedTooltip(value, width)) {
      return (
        <>
          <Tooltip
            tooltipContainerClassName={style.deliveryTooltipContainer}
            tooltipContent={value}
          >
            {(ref) => (
              <span ref={ref} className={style.textEllipsis}>{value}</span>
            )}
          </Tooltip>
        </>
      );
    }
    return value;
  }, [isMinHeightMedium]);

  const infos = useMemo(() => {
    const diffDate = moment(endDate).diff(moment(startDate), "days");
    const mileageValue = getRvMileageValue(rvData);
    const generatorValue = getRvGeneratorValue(rvData);
    const pickUp = getRvPickUpLocation(rvData, fullAddress);

    return [
      {
        title: "Rental type",
        value: rental_type.value
      },
      {
        title: "RV class",
        value: rvData.class
      },
      {
        title: "Pick up",
        value: moment(startDate).format("M/DD/YYYY")
      },
      {
        title: "Drop off",
        value: moment(endDate).format("M/DD/YYYY")
      },
      {
        title: "Duration",
        value: diffDate + " nights"
      },
      pickUp && {
        title: pickUp.text,
        value: getPickUpContent(pickUp.value, 130)
      },
      mileageValue && Number(rental_type.key) === 1 && {
        title: "Mileage",
        value: mileageValue.text
      },
      generatorValue && {
        title: "Generator",
        value: generatorValue.text
      }
    ].filter(Boolean);
  }, [endDate, startDate, rvData, fullAddress, rental_type.value, rental_type.key, getPickUpContent]);

  const columns = (!isMaxSmall && isMaxMedium) || !isMinHeightMedium ? 2 : 1;

  const onToggleShowDetails = useCallback(() => {
    setShowAsideRvInfoDetails((p) => !p);
  }, [setShowAsideRvInfoDetails]);

  return (
    <section className={classnames(style.root, "fw")}>
      {isInstantRental && isMaxMedium && (
        <Typography variant="body" size="l" className={`${style.instantRental} mt-4 mt-m-0 mb-m-12 mb-l-16 t-2 t-m-0`}>
          <img alt="" src={lightningIcon} className={`${style.instantRentalImg}`} />
          Instant rental
        </Typography>
      )}
      {isMaxSmall && (
        <div
          className={classnames(
            style.details,
            showAsideRvInfoDetails ? "l-16 r-16 mt-16 mb-16" : "",
            "df fdc fw",
            showAsideRvInfoDetails && style.showAsideRvInfoDetails,
          )}
        >
          {showAsideRvInfoDetails && (
            <div className={classnames("t-16 b-16")}>
              <RvInfo list={infos} columns={columns} />
            </div>
          )}
          <div className={classnames("t-16 t-m-28 b-16")}>
            <Button
              label={
                showAsideRvInfoDetails
                  ? "Hide booking details"
                  : "Show booking details"
              }
              onClick={onToggleShowDetails}
              className="fw"
              secondary
            />
          </div>
        </div>
      )}
      {!isMaxSmall && (
        <div className={style.wrap}>
          <div
            className={classnames(
              style.image,
              "col col-4 l-24 r-0 t-20 b-20 col-l-auto t-l-0 b-l-0 l-l-0 r-l-0"
            )}
          >
            <img src={rvData.title_image} alt={rvData.rv_name} />
          </div>
          <div
            className={classnames(
              style.wrapInner,
              "fw r-32 l-32 r-l-24 l-l-24 t-20 b-20 t-l-0"
            )}
          >
            <Typography
              variant="subtitle"
              size="l"
              weight="bold"
              className="dn db-l"
            >
              {rvData.rv_name}
            </Typography>
            {isInstantRental && !isMaxMedium && (
              <Typography variant="body" size="l" className={`${style.instantRental} mb-l-4`}>
                <img alt="" src={lightningIcon} className={`${style.instantRentalImg}`} />
                Instant rental
              </Typography>
            )}
            <RvInfo list={infos} columns={columns} />
          </div>
        </div>
      )}
    </section>
  );
};

export default AsideRvInfo;
