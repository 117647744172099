import { useContext } from "react";
import { ContentContext } from "./ContentContext";

export function useContent() {
  const context = useContext(ContentContext);

  if (!context) {
    throw new Error("useContent must be used within a ContentProvider");
  }

  return context;
}

export function useContentSection(section) {
  const context = useContext(ContentContext);

  if (!context[0][section]) {
    throw new Error(`content is not defined for the section ${section}`);
  }

  return context[0][section];
}

export function useContentElement(section, elementType, id) {
  const content = useContentSection(section);
  return content?.[elementType]?.find((item) => item.id === id);
}
