import React, { useCallback, useState, useEffect } from "react";
import classnames from "classnames";
import Typography from "components/Typography";
import { Modal } from "components/Modal";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { RX_INT } from "utils/constants/RX";
import Form from "components/form/Form/Form";
import { FieldInput } from "components/form/FieldInput";
import Button from "components/Button";
import { SpinnerBack } from "components/Spinner/Spinner";
import { useSaveTempInspection } from "./mutation";
import size from "lodash/size";
import style from "./style.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const AddMileageModal = ({
  type,
  isShowMileage,
  isShowGenerator,
  mileageMinValue,
  generatorMinValue,
  mileageMinError,
  generatorMinError,
  showModal,
  onClose,
  title
}) => {

  const {
    control,
    handleSubmit,
    formState: { errors: errorsFields },
    watch,
    reset
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      ...(isShowMileage ? { mileage: "" } : {}),
      ...(isShowGenerator ? { generator: "" } : {})
    },
    mode: "onSubmit"
  });

  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );

  const handleClose = useCallback(
    () => {
      reset();
      onClose();
    },
    [onClose, reset]
  );

  const { action, loading, errors } = useSaveTempInspection({
    onSuccess: handleClose
  });

  const onSubmit = useCallback(
    ({ mileage, generator }) => {
      try {
        const mileageValueParametr = isShowMileage ? Number(mileage) : null;
        const generatorValueParametr = isShowGenerator
          ? Number(generator)
          : null;

        void action(type, generatorValueParametr, mileageValueParametr);
      } catch (e) {
        console.error(e);
      }
    },
    [action, isShowGenerator, isShowMileage, type]
  );

  useEffect(() => {
    if (errors?.length && errors[0] && errors[0]?.message) {
      setShowNotificationPopover(true);
    }
  }, [errors]);

  const { mileage, generator } = watch([
    "mileage",
    "generator"
  ]);

  let isSubmitDisabled = false;
  if (isShowMileage && isShowGenerator) {
    if (!mileage || !generator || size(errorsFields) > 0) {
      isSubmitDisabled = true;
    }
  } else if (isShowGenerator) {
    if (!generator || size(errorsFields) > 0) {
      isSubmitDisabled = true;
    }
  } else if (isShowMileage) {
    if (!mileage || size(errorsFields) > 0) {
      isSubmitDisabled = true;
    }
  }

  return (
    <Modal
      show={showModal}
      onClose={handleClose}
      modalClassnames={classnames(
        style.modalAddMileage,
        ""
      )}
      modalWrapClassnames={`mdl-wrap--full-screen-less-768`}
    >
      <div className={`${style.modalAddMileageInner} `}>
        {loading && <SpinnerBack />}
        <div className="add-mileage__wrap">
          <NotificationPopover
            show={isShowNotificationPopover}
            status="error"
            text={errors?.length && errors[0].message}
            onClose={() => {
              setShowNotificationPopover(false);
            }}
          />
          <Typography variant="headline" size="m" className="">
            {title}
          </Typography>
          <Form
            id="add-mileage__form"
            className="add-mileage__form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {isShowMileage && (
              <div
                className={classnames(
                  "t-20 b-20 fss",
                  style.inputContainer
                )}
              >
                <Controller
                  name="mileage"
                  control={control}
                  rules={{
                    required: mileageMinError,
                    validate: (v) => {
                      const num = parseInt(v, 10);
                      if (num < mileageMinValue) return mileageMinError;
                    },
                    pattern: {
                      value: RX_INT,
                      message: "Incorrect input"
                    }
                  }}
                  render={(renderProps) => (
                    <FieldInput
                      masked
                      hideMaxLength
                      maxLength={7}
                      className={style.inputFieldAttachedTo}
                      id="mileage"
                      label="Mileage"
                      errors={errorsFields}
                      {...renderProps}
                    />
                  )}
                />
                <FieldInput
                  disabled
                  label="miles"
                  value=""
                  className={style.inputFieldAttached}
                />
              </div>
            )}
            {isShowGenerator && (
              <div
                className={classnames(
                  "t-20 b-20 fss",
                  style.inputContainer
                )}
              >
                <Controller
                  name="generator"
                  control={control}
                  rules={{
                    required: generatorMinError,
                    validate: (v) => {
                      const num = parseInt(v, 10);
                      if (num < generatorMinValue) return generatorMinError;
                    },
                    pattern: {
                      value: RX_INT,
                      message: "Incorrect input"
                    }
                  }}
                  render={(renderProps) => (
                    <FieldInput
                      masked
                      hideMaxLength
                      maxLength={7}
                      className={style.inputFieldAttachedTo}
                      id="generator"
                      label="Generator"
                      errors={errorsFields}
                      {...renderProps}
                    />
                  )}
                />
                <FieldInput
                  disabled
                  label="hours"
                  value=""
                  className={style.inputFieldAttached}
                />
              </div>
            )}
            <div
              className={classnames(
                style.editReview__footerContent,
                "t-20 b-20 fss"
              )}
            >
              <div className="r-24">
                <Button
                  secondary
                  label="Cancel"
                  onClick={handleClose}
                />
              </div>
              <Button
                label="Submit"
                type="submit"
                disabled={isSubmitDisabled}
                className="ml-m-8"
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default AddMileageModal;
