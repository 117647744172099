import React from "react";
import { Email } from "./Email/Email";
import { PhoneNumber } from "./PhoneNumber/PhoneNumber";
import { Password } from "./Password/Password";
import { DeleteAccount } from "./DeleteAccount/DeleteAccount";

export const MyAccountLoader = (props) => {
  const { content, userData, sectionInEditing, toggleSectionInEditing } = props;
  return (
    <>
      <Email content={content} userData={userData} />
      <PhoneNumber
        content={content}
        userData={userData}
        sectionInEditing={sectionInEditing}
        toggleSectionInEditing={toggleSectionInEditing}
      />
      <Password
        content={content}
        userData={userData}
        sectionInEditing={sectionInEditing}
        toggleSectionInEditing={toggleSectionInEditing}
      />
      <DeleteAccount />
    </>
  );
};
