import React, { useState } from "react";
import GoodsamMembershipPlanSelection from "components/GoodsamMembership/GoodsamMembershipPlanSelection";

const PlanSelection = (props) => {
  return <GoodsamMembershipPlanSelection {...props} />;
};

PlanSelection.propTypes = {};

export default PlanSelection;
