import React from "react";
import Typography from "components/Typography";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as IconQuestion } from "assets/images/icon-question.svg";
import { FieldController } from "components/form/FieldController/FieldController";
import Switch from "components/form/Switch";
import scrollIntoView from "utils/scrollIntoView";
import { MapLocationDelivery } from "components/Listing/steps/LocationDelivery/components/MapLocationDelivery/MapLocationDelivery";
import classes from "../LocationRentalTabs/LocationRentalTab.module.css";

export const RentalType = (props) => {

  const {
    control,
    tabIndex,
    watch
  } = props;

  const { location, tabs } = watch();
  const {
    allowSwitch,
    delivery_option,
    specific_destinations,
    specificDeliveryLocations,
    free_delivery,
    paid_delivery
  } = tabs[tabIndex];

  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  let locations = [];
  if (specificDeliveryLocations && specificDeliveryLocations.length > 0) {
    locations = specificDeliveryLocations
      .filter((dest) => dest.location?.full_address.length !== 0)
      .map((dest) => dest.location);
  }

  let rentalTypeTitle = tabIndex === 0 && "Allow standard rental";
  if (tabIndex === 1) {
    rentalTypeTitle = isSmallDevice ? "Allow delivery only" : "Allow delivery only rental";
  }

  let tooltipText = "Standard rental: this type of rental allows you to offer both renter pick-up and delivery. If you are comfortable with renters driving or towing your RV, this selection allows them to do so. You can also set customized delivery options. Offering delivery is not required.";

  if (tabIndex === 1) {
    tooltipText = "Delivery only rental: do you own a larger RV or a fifth wheel? Maybe you would just feel more comfortable not allowing anyone else to drive or tow your RV on their own? This rental type allows you to set custom delivery and setup fees so you can transport your RV to your renter’s destination, stress-free.";
  }
  return (
    <>
      <div className="row fsc mt-m-8 mt-xl-4">
        <div className="col fsc">
          <Typography variant="subtitle" size="m" className={classes.title}>
            {rentalTypeTitle}
          </Typography>

          <Tooltip
            tooltipContainerClassName={classes.tooltipQuestionContainer}
            trigger={['click']}
            tooltipContent={tooltipText}
          >
            {(ref) => (
              <span className={`${classes.btnIcon} dib`} ref={ref}>
                <IconQuestion  />
              </span>
            )}
          </Tooltip>

        </div>
        <div className={`col-auto`}>
          <div className="t-xl-8">
            <FieldController
              name={`tabs[${tabIndex}].allowSwitch`}
              control={control}
              render={(renderProps) => (
                <Switch
                  id={`allowSwitch-${tabIndex}`}
                  {...renderProps}
                  onChange={(checked) => {
                    renderProps.onChange(checked);
                    if (checked) {
                      scrollIntoView(`.allowSwitch-${tabIndex}`, null, { behavior: "smooth", block: "start" });
                    }
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="row mt-24 mt-m-32">
        <div className="col">
          <MapLocationDelivery
            freeMilesRadius={free_delivery}
            paidMilesRadius={paid_delivery}
            isShowDeliveryOption={delivery_option && allowSwitch}
            isShowLocations={specific_destinations && allowSwitch}
            value={location}
            locations={locations}
          />
        </div>
      </div>
    </>
  );
};
