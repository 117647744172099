import { gql } from "@apollo/client";

export const initialDataQuery = gql`
  query initialData($rvId: Int!, $features: [String]!, $rental_type: Int) {
    user {
      good_sam_membership_validation {
        key
        value
      }
    }
    insurance_packages(rv: $rvId, rental_type: $rental_type) {
      id
      name
      description
      price: price_per_day {
        dollars_as_part
        cents_as_part
        cents_total
        user_friendly
      }
    }
    feature_flags_list (
      feature_flags_list: $features
    )
  }
`;
