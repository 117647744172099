import React, { useState } from "react";
import { downloadIcon, commentIcon } from "components/Icon";
import photoPlaceholderLoaderIcon from "components/ModalInspections/components/Photos/photoPlaceholderLoaderIcon.svg";
import ModalCarouselInspection from "components/ModalCarouselInspection/ModalCarouselInspection";
import Button from "components/Button";
import Typography from "components/Typography";
import photoPlaceholder320 from "components/ModalInspections/components/Photos/photoPlaceholder/photoPlaceholder320.jpg";
import photoPlaceholder768 from "components/ModalInspections/components/Photos/photoPlaceholder/photoPlaceholder768.jpg";
import photoPlaceholder1024 from "components/ModalInspections/components/Photos/photoPlaceholder/photoPlaceholder1024.jpg";
import photoPlaceholder1440 from "components/ModalInspections/components/Photos/photoPlaceholder/photoPlaceholder1440.jpg";
import photoPlaceholder1680 from "components/ModalInspections/components/Photos/photoPlaceholder/photoPlaceholder1680.jpg";
import sliderPhotoPlaceholder320 from "components/ModalInspections/components/Photos/sliderPhotoPlaceholder/sliderPhotoPlaceholder320.jpg";
import sliderPhotoPlaceholder768 from "components/ModalInspections/components/Photos/sliderPhotoPlaceholder/sliderPhotoPlaceholder768.jpg";
import sliderPhotoPlaceholder1024 from "components/ModalInspections/components/Photos/sliderPhotoPlaceholder/sliderPhotoPlaceholder1024.jpg";
import sliderPhotoPlaceholder1440 from "components/ModalInspections/components/Photos/sliderPhotoPlaceholder/sliderPhotoPlaceholder1440.jpg";
import sliderPhotoPlaceholder1680 from "components/ModalInspections/components/Photos/sliderPhotoPlaceholder/sliderPhotoPlaceholder1680.jpg";
import cn from "classnames";
import classes from "./Photos.module.css";

const photoPlaceholder = {
  320: photoPlaceholder320,
  768: photoPlaceholder768,
  1024: photoPlaceholder1024,
  1440: photoPlaceholder1440,
  1680: photoPlaceholder1680
};

const sliderPhotoPlaceholder = {
  320: sliderPhotoPlaceholder320,
  768: sliderPhotoPlaceholder768,
  1024: sliderPhotoPlaceholder1024,
  1440: sliderPhotoPlaceholder1440,
  1680: sliderPhotoPlaceholder1680
};

export function Photos({ data: rawData, value: rawValue }) {
  const [showSlider, setShowSlider] = useState(false);
  const [slideIndex, setSlideIndex] = useState(1);

  const values = JSON.parse(rawValue);
  const { requiredImagesName } = JSON.parse(rawData);

  const sliderPhotos = values
    .map((item, itemIndex) => ({ ...item, id: `id-${itemIndex}`,commentChecked: true, src: item.url ? item.url : false }))
    .filter((item) => Boolean(item.src));

  const list = values.map((item, itemIndex) => {
    return { ...item, url: `${item.url}.small.jpg`, caption: requiredImagesName?.[itemIndex] ? requiredImagesName?.[itemIndex]: "" };
  });

  return (
    <div className={classes.container}>
      <ul className={classes.list}>
        {list.map(({ id = 'id', url, caption, comment = "" }, itemIndex) => (
          <li key={`${id}-${itemIndex}`} className={classes.listItem}>
            <div
              className={
                cn(
                  classes.listItemInner,
                  !url && classes.hasNotImage
                )}
            >
              {!url && (
                <div className={classes.photoPlaceholder}>
                  <img
                    src={photoPlaceholderLoaderIcon}
                    alt=""
                  />
                </div>
              )}
              {/* {url && (
                <Button
                  icon={downloadIcon}
                  inverse
                  label=""
                  onClick={() => {}}
                  size="small"
                  className={`${classes.button} ${classes.downloadButton}`}
                  href={url}
                  download
                />
              )} */}

              {url && comment?.length > 0 && (
                <Button
                  icon={commentIcon}
                  inverse
                  label=""
                  onClick={() => {
                    setSlideIndex(itemIndex);
                    setShowSlider(true);
                  }}
                  size="medium"
                  className={`${classes.button} ${classes.commentButton}`}
                />
              )}
              {url && (
                <img
                  src={url}
                  alt=""
                  className={classes.img}
                  onClick={() => {
                    setSlideIndex(itemIndex);
                    setShowSlider(true);
                  }}
                />
              )}

              <Typography
                variant="body"
                size="m"
                className={classes.caption}
              >
                {caption}
              </Typography>
            </div>
          </li>
        ))}
      </ul>

      <div className={classes.slider}>
        <ModalCarouselInspection
          showModal={showSlider}
          photos={sliderPhotos}
          onClose={() => setShowSlider(false)}
          initialSlide={slideIndex}
        />
      </div>
    </div>
  );
}
