import React, { useCallback, useEffect, useState } from "react";
import { calculateDeliveryFee } from "./queries/calculateDeliveryFee";
import { useLazyQuery } from "@apollo/client";
import PickUpDeliveryModal from "./PickUpDeliveryModal";

export const PickUpDeliveryModalProvider = (props) => {
  const { rvId, rentalType } = props;
  const [loadCalculateDeliveryFee, { data }] = useLazyQuery(
    calculateDeliveryFee,
    {
      fetchPolicy: "no-cache"
    }
  );

  const handleOnCalculateDeliveryFee = useCallback(
    (location, specificDeliveryLocationId) => {
      loadCalculateDeliveryFee({
        variables: {
          rv: rvId,
          location,
          rental_type: rentalType,
          specific_delivery_location_id: specificDeliveryLocationId
        }
      });
    },
    [loadCalculateDeliveryFee, rentalType, rvId]
  );
  const [deliveryFeeData, setDeliveryFeeData] = useState({
    deliveryFee: null,
    error: null
  });
  let isError = true;
  let errorMessage = "";
  let deliveryFee = "";

  const resultData = data?.calculate_delivery_fee;
  if (resultData?.result?.errors) {
    errorMessage = resultData.result.errors[0].message;
  } else if (resultData?.delivery_fee) {
    isError = false;
    errorMessage = "";
    deliveryFee = resultData?.delivery_fee;
  }

  useEffect(() => {
    const result = data?.calculate_delivery_fee;
    if (result?.delivery_fee) {
      setDeliveryFeeData({
        deliveryFee: result?.delivery_fee,
        error: null
      });
    } else if (result?.result?.errors) {
      setDeliveryFeeData({
        deliveryFee: null,
        error: result?.result?.errors
      });
    }
  }, [data]);

  return (
    <PickUpDeliveryModal
      handleOnCalculateDeliveryFee={handleOnCalculateDeliveryFee}
      deliveryFeeErrorMessage={errorMessage}
      isDeliveryFeeError={isError}
      deliveryFee={deliveryFee}
      deliveryFeeData={deliveryFeeData}
      setDeliveryFeeData={setDeliveryFeeData}
      resultData={resultData}
      {...props}
      {...data}
    />
  );
};
