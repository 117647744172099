import React, { useMemo } from "react";
import { TaxCardContainer } from "components/TaxCard";
import { EmptyState } from "processes/MyAccount/steps/TransactionHistory";
import { TAB_IDS } from "processes/MyAccount/steps/TransactionHistory/constants";
import MenuPipe from "components/MenuPipe/MenuPipe";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

import classes from "./CardTabs.module.css";

export const CardTabs = (props) => {

  const {
    cards = [],
    total = {},
    expected = {},
    taxes = {},
    downloadUrl,
    tab,
    onResetFilters,
    onTabChange
  } = props;

  const isEmptyState = cards?.length === 0;
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  const tabsData = useMemo(() => ([
    {
      id: TAB_IDS.COMPLETED,
      index: 0,
      title: isSmallDevice ? "Completed" : "Completed payouts",
      value: "Completed payouts"
    },
    {
      id: TAB_IDS.UPCOMING,
      index: 1,
      title: isSmallDevice ? "Upcoming" : "Upcoming payouts",
      value: "Upcoming payouts"
    }
  ]), [isSmallDevice]);


  return (
    <div className={classes.cardTabs}>

      <div className="tabs">
        <div className="dib">
          <MenuPipe
            data={tabsData}
            onClickItem={onTabChange}
            size="middle"
          />
        </div>
      </div>

      <div className="mt-24 mt-m-32">

        <div>
          {!isEmptyState && (
            <div>
              <TaxCardContainer
                data={cards}
                total={total}
                expected={expected}
                taxes={taxes}
                downloadUrl={downloadUrl}
                tab={tab}
              />
            </div>
          )}
          {isEmptyState && (
            <div className="mt-32">
              <EmptyState onResetFilters={onResetFilters} />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};
