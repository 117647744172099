import React from "react";
import Typography from "components/Typography";
import Button from "components/Button";
import { PriceForm, PriceFormTabs } from 'components/PriceForm';
import Modal from "components/Modal/Modal";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import cn from "classnames";
import classes from "./PriceFormModal.module.css";

const MULTI_COLUMN = 2;

const PriceFormModal = (props) => {
  const {
    show,
    data,
    menuData = [],
    title,
    specRender,
    buttons: {
      cancelButtonLabel = 'Back',
      actionButtonLabel = 'Cancel rental'
    } = {},
    handleAction,
    handleCancel,
    onAction = () => {},
    onClose,
    isFooter = true,
    banner
  } = props;

  const isTabs = menuData?.length > MULTI_COLUMN;

  const mdlRef = useScrollBarWidth();

  return (
    <Modal
      show={show}
      onClose={onClose}
      modalWrapClassnames={classes.modalWrap}
      modalClassnames={cn(
        classes.modal,
        isTabs && classes.multiСolumn
      )}
      mdlRef={mdlRef}
      modalCrossButtonClassname={classes.crossButton}
    >
      <div className={cn(
        classes.container,
        isTabs && classes.multiСolumn
      )}
      >
        <div>
          <Typography
            component="h1"
            variant="headline"
            size="m"
            className={classes.title}
          >
            {title}
          </Typography>

          <div>
            {banner}
          </div>
        </div>

        <div className={classes.content}>
          {isTabs && (
            <>
              <PriceFormTabs
                data={data}
                menuData={menuData}
                specRender={specRender}
                onAction={onAction}
              />
            </>
          )}

          {!isTabs && (
            <div className={classes.priceFormContainer}>
              <PriceForm
                data={data}
                specRender={specRender}
                onAction={onAction}
              />
            </div>
          )}

        </div>

        {isFooter && (
          <footer className={classes.footer}>
            <div className={cn(
              classes.footerInner,
              'l-16 r-16'
            )}>
              <div>
                <Button
                  secondary
                  onClick={handleCancel}
                >
                  {cancelButtonLabel}
                </Button>
              </div>

              <div>
                <Button
                  onClick={handleAction}
                  className={classes.buttonCancel}
                >
                  {actionButtonLabel}
                </Button>
              </div>
            </div>
          </footer>
        )}
      </div>
    </Modal>
  );
};

PriceFormModal.propTypes = {
};

export default PriceFormModal;
