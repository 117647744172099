import React, { useMemo, useCallback, useRef } from "react";
import classnames from "classnames";
import moment from "moment";
import chunk from "lodash/chunk";
import Typography from "components/Typography";
import Button from "components/Button/Button";
import { useBookingModalContext } from "../../BookingModalContext";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { getRvGeneratorValue, getRvMileageValue, getRvLocation } from "utils/rv";
import Tooltip from "components/Tooltip/Tooltip";
import { doNeedTooltip } from "components/Tooltip/utils/doNeedTooltip";
import lightningIcon from "assets/images/lightning-small-icon.svg";
import style from "./AsideRvInfo.module.css";

const AsideRvInfo = ({ rvData, startDate, endDate, fullAddress }) => {
  const {
    toggleShowPickUpDelivery,
    toggleShowCalendar,
    showAsideRvInfoDetails,
    setShowAsideRvInfoDetails,
    isInstantRental,
    rentalType,
    rentalTypeValue,
    rvLocationData
  } = useBookingModalContext();

  const isMaxSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  const isMaxMedium = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isMinHeightMedium = useMatchMedia({ mediaQuery: "(min-width: 1024px) and (min-height: 730px)" });

  const getPickUpContent = useCallback((value, width) => {
    if (isMinHeightMedium && doNeedTooltip(value, width)) {
      return (
        <>
          <Tooltip
            tooltipContainerClassName={style.deliveryTooltipContainer}
            tooltipContent={value}
          >
            {(ref) => (
              <span ref={ref} className={style.textEllipsis}>{value}</span>
            )}
          </Tooltip>
        </>
      );
    }
    return value;
  }, [isMinHeightMedium]);

  const infos = useMemo(() => {
    const diffDate = moment(endDate).diff(moment(startDate), "days");
    const mileageValue = getRvMileageValue(rvData);
    const generatorValue = getRvGeneratorValue(rvData);
    const rvLocation = getRvLocation(rvLocationData);

    return [
      {
        title: "Rental type",
        value: rentalTypeValue
      },
      {
        title: "RV class",
        value: rvData.class
      },
      {
        title: "Pick up",
        value: moment(startDate).format("M/DD/YYYY"),
        onClick: toggleShowCalendar
      },
      {
        title: "Drop off",
        value: moment(endDate).format("M/DD/YYYY"),
        onClick: toggleShowCalendar
      },
      {
        title: "Duration",
        value: diffDate + " nights"
      },
      rvLocation && {
        title: rvLocation.text,
        value: getPickUpContent(rvLocation.value, 130),
        onClick: rvLocation.isAllowChooseLocation ? toggleShowPickUpDelivery : undefined
      },
      mileageValue && rentalType === 1 && {
        title: "Mileage",
        value: mileageValue.text
      },
      generatorValue && {
        title: "Generator",
        value: generatorValue.text
      }
    ].filter(Boolean);
  }, [
    endDate,
    startDate,
    rvData,
    rvLocationData,
    rentalTypeValue,
    toggleShowCalendar,
    getPickUpContent,
    toggleShowPickUpDelivery,
    rentalType
  ]);

  const columns = (!isMaxSmall && isMaxMedium) || !isMinHeightMedium ? 2 : 1;

  return (
    <section className={classnames(style.root, "fw")}>
      {isInstantRental && isMaxMedium && (
        <Typography variant="body" size="l" className={`${style.instantRental} mt-4 mt-m-0 mb-m-12 mb-l-16 t-2 t-m-0`}>
          <img alt="" src={lightningIcon}  className={`${style.instantRentalImg}`} />
          Instant rental
        </Typography>
      )}
      {isMaxSmall && (
        <div
          className={classnames(
            style.details,
            showAsideRvInfoDetails ? "bg-grey l-16 r-16 mt-16 mb-16" : "",
            "df fdc fw",
            showAsideRvInfoDetails && style.showAsideRvInfoDetails,
          )}
        >
          {showAsideRvInfoDetails && (
            <div className={classnames("t-16 b-16")}>
              <RvInfo list={infos} columns={columns} />
            </div>
          )}
          <div className={classnames("t-16 t-m-28 b-16")}>
            <Button
              label={
                showAsideRvInfoDetails
                  ? "Hide booking details"
                  : "Show booking details"
              }
              onClick={() => setShowAsideRvInfoDetails((p) => !p)}
              className="fw"
              secondary
            />
          </div>
        </div>
      )}
      {!isMaxSmall && (
        <div className={style.wrap}>
          <div
            className={classnames(
              style.image,
              "col col-4 l-24 r-0 t-20 b-20 col-l-auto t-l-0 b-l-0 l-l-0 r-l-0"
            )}
          >
            <img src={rvData.title_image} alt={rvData.rv_name} />
          </div>
          <div
            className={classnames(
              style.wrapInner,
              "fw r-32 l-32 r-l-24 l-l-24 t-20 b-20 t-l-0"
            )}
          >
            <Typography
              variant="subtitle"
              size="l"
              weight="bold"
              className="dn db-l"
            >
              {rvData.rv_name}
            </Typography>
            {isInstantRental && !isMaxMedium && (
              <Typography variant="body" size="l" className={`${style.instantRental} mb-l-4`}>
                <img alt="" src={lightningIcon} className={`${style.instantRentalImg}`} />
                Instant rental
              </Typography>
            )}
            <RvInfo list={infos} columns={columns} />
          </div>
        </div>
      )}
    </section>
  );
};

const RvInfo = ({ list, columns = 1 }) => {
  // Couldn't solve text overflow issue with a plain CSS, so using chunking
  return (
    <div className={style.info}>
      {chunk(list, Math.ceil(list.length / columns)).map((column, cIndex) => (
        <div key={cIndex} className={style.infoItemColumn}>
          {column.map((item) => (
            <div key={item.title} className={style.infoItem}>
              <div className={style.titleWrap}>
                <Typography
                  variant="body"
                  size="l"
                  weight="normal"
                  className={style.title}
                >
                  {item.title}
                </Typography>
              </div>
              <div
                className={classnames(
                  style.valueWrap,
                  "l-8",
                  { "r-32": cIndex !== columns - 1 }
                )}
              >
                <Typography
                  variant="body"
                  size="l"
                  weight="normal"
                  className={
                    Boolean(item.onClick) ? style.touchableValue : style.value
                  }
                >
                  <span onClick={item.onClick}>
                    {item.value}
                  </span>
                </Typography>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AsideRvInfo;
