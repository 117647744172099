import React, { useCallback } from "react";
import BookingInsurance from "processes/Booking/BookingInsurance/BookingInsurance";

const InsurancePlan = ({
  rvData,
  insuranceId,
  setInsuranceId,
  showBookingInsuranceModal,
  setShowBookingInsuranceModal,
  rental_type
}) => {

  const toggleOpen = useCallback(() => {
    setShowBookingInsuranceModal((p) => !p);
  }, [setShowBookingInsuranceModal]);

  const onSave = useCallback(
    (value) => {
      setInsuranceId(value);
      toggleOpen();
    },
    [setInsuranceId, toggleOpen]
  );

  return (
    <>
      <BookingInsurance
        rentalType={rental_type}
        rvId={rvData.id}
        show={showBookingInsuranceModal}
        onClose={toggleOpen}
        setIsShowBookingInsurance={toggleOpen}
        onSelectInsurance={onSave}
        insurance={String(insuranceId || 0)}
        backTo={{ label: "Back to booking" }}
      />
    </>
  );
};

export default InsurancePlan;
