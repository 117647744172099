import React from "react";
import Typography from "components/Typography";
import Button from "components/Button";
import coverImg from "processes/PublicProfile/images/cover.jpg";
import { ProfileInfo } from "processes/PublicProfile/components/ProfileInfo/ProfileInfo";
import VehicleCard from "components/vehicles/VehicleCard";
import useCardSize from "processes/PublicProfile/hooks/useCardSize";
import { ReviewsShowMore } from "components/Reviews/components/ReviewsShowMore/ReviewsShowMore";
import { ReviewsEmpty } from "components/Reviews/components/ReviewsEmpty/ReviewsEmpty";
import { PublicProfileEmptyState } from "processes/PublicProfile/components/PublicProfileEmptyState/PublicProfileEmptyState";
import classes from "processes/PublicProfile/PublicProfile.module.css";

export const PublicProfileWithSeveralCard = () => {
  const cardSize = useCardSize();

  const cards = {
    "variant": "col",
    "size": cardSize,
    "imageUrl": "https://cw-rr.dev.quintegro.net/files/rv_images/400/1637483404_php619a038c775d7.376.jpg",
    "link": "https://cw-rr.dev.quintegro.net/files/rv_images/400/1637483404_php619a038c775d7.376.jpg",
    "linkTarget": "_blank",
    "id": 12,
    "title": "Listing Title Listing Title Listing Title Listing Title Listing Title Listing Title Listing Title Listing Title Listing Title Listing Title Listing Title ",
    "name": "1983 AIRSTREAM LIMITED-CB",
    "price": 40,
    "deliveredFrom": "Delivered from Joliet, IL",
    "rvClass": "Travel Trailer",
    "length": 3,
    "sleeps": 1,
    "slideOuts": 0,
    "isFavorite": false,
    "hasButtonControls": true,
    "onClickFavorite": () => {},
    "className": "dddd",
    badge: {
      text: 'Active',
      status: 'success'
    }
  };
  const photos = [
    {
      id: '1',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce83e3c2.jpg',
      alt: ''
    },
    {
      id: '2',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce85747a.jpg',
      alt: ''
    },
    {
      id: '3',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce86f2c6.jpg',
      alt: ''
    },
    {
      id: '4',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce887406.jpg',
      alt: ''
    },
    {
      id: '5',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce89edd2.jpg',
      alt: ''
    },
    {
      id: '6',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce8b5ff7.jpg',
      alt: ''
    },
    {
      id: '7',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce83e3c2.jpg',
      alt: ''
    },
    {
      id: '8',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305576_php61974ce89edd2.jpg',
      alt: ''
    },
    {
      id: '9',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daace1f5.jpg',
      alt: ''
    },
    {
      id: '10',
      src: 'https://cw-rr.dev.quintegro.net/files/review_images/1637305770_php61974daaac947.jpg',
      alt: ''
    }
  ];

  const reviews = [
    {
      id: "1",
      photo: {
        src: 'http://cw-rr.dev.quintegro.net/files/profiles/1000/1633085684_php6156e8f48d47f.150.jpg',
        alt: ''
      },
      name: 'Mike J.',
      rating: 3,
      date: 'Feb 10, 2021',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      galeryPhotos: photos
    },
    {
      id: "2",
      photo: {
        src: 'http://cw-rr.dev.quintegro.net/files/profiles/1000/1633085684_php6156e8f48d47f.150.jpg',
        alt: ''
      },
      name: 'John M.',
      rating: 3,
      date: 'Feb 10, 2021',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      galeryPhotos: photos
    },
    {
      id: "3",
      photo: {
        src: 'http://cw-rr.dev.quintegro.net/files/profiles/1000/1633085684_php6156e8f48d47f.150.jpg',
        alt: ''
      },
      name: 'John M.',
      rating: 3,
      date: 'Feb 10, 2021',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      galeryPhotos: photos
    },
    {
      id: "4",
      photo: {
        src: 'http://cw-rr.dev.quintegro.net/files/profiles/1000/1633085684_php6156e8f48d47f.150.jpg',
        alt: ''
      },
      name: 'John M.',
      rating: 3,
      date: 'Feb 10, 2021',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      galeryPhotos: photos
    }
  ];

  const profileInfoData = {
    aboutTitle: 'About',
    aboutText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis Rconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad'
  };
  const profileMainInfoData = {
    name: 'Raymond Johnsson',
    rating: 4,
    address: 'Boston, MA',
    date: 'Joined September 2020'
  };

  const avatarData = {
    avatar: {
      src: 'https://cw-rr.dev.quintegro.net/files/profiles/1000/1622639668_php60b784346688e.150.jpg',
      alt: ''
    },
    hasBadge: true
  };

  const hasCoverImg = true;
  const isProfileEmpty = false;
  return (
    <div className={classes.publicProfile}>
      <div className={`${classes.headerCover}`}>
        {hasCoverImg && (
          <div className={`${classes.headerCoverInner}  profile-img`}>
            <picture className={`${classes.headerCoverImg} profile-img__img`}>
              <img src={coverImg} alt="" />
            </picture>
          </div>
        )}
      </div>
      <div className={`container b-40 b-m-80 b-l-60 mb-xl-120 b-xl-0`}>
        <div className="row">
          <div className="col-12 col-l-4 col-xl-3">
            <div
              className={`
                ${classes.profileInfoContainer} ${isProfileEmpty && classes.profileEmpty} 
                mr-neg-l-24 mr-neg-xxl-32
            `}>
              <ProfileInfo
                {...profileInfoData}
                profileMainInfoData={profileMainInfoData}
                avatarData={avatarData}
              />
            </div>
          </div>
          <div className="col-12 col-l-7 offset-l-1 col-xl-8 t-2">
            {true && (

              <div className="b-16 b-m-20">

                <div className="devider-dashed mt-16 mb-16 dn-m" />
                <div className="mb-16 mt-m-52 b-m-4 mt-l-60 mb-l-20 mb-xl-16">
                  <Typography variant="subtitle" size="l" weight="bold">
Vehicle(s)
                  </Typography>
                </div>

                <div className={`${classes.vehicleCards} row`}>
                  <div className="col-12 col-s-6 col-xl-6 t-12 t-m-16 b-12 b-m-16">
                    <VehicleCard {...cards}/>
                  </div>
                  <div className="col-12 col-s-6 col-xl-6 t-12 t-m-16 b-12 b-m-16">
                    <VehicleCard {...cards}/>
                  </div>
                  <div className="col-12 col-s-6 col-xl-6 t-12 t-m-16 b-12 b-m-16">
                    <VehicleCard {...cards}/>
                  </div>
                </div>
                <div className="t-16 b-16 t-m-20 b-m-20 t-l-24 t-xl-20">
                  <Button
                    label="List Your RV"
                    onClick={function noRefCheck(){}}
                    secondary
                  />
                </div>

              </div>
            )}


            {false && (
              <div>

                <div className=" t-m-52 t-l-60 b-m-20">
                  <PublicProfileEmptyState contentText="You haven't added any RV yet" buttonLabel="List Your RV" />
                </div>

              </div>
            )}


            <div className="devider-dashed mt-16 mb-16 mt-m-20 mb-m-20" />


            {true && (
              <div>

                <div className="t-4 t-m-20">
                  <ReviewsEmpty contentText="You haven't rented any RV yet" buttonLabel="Search RVs" />
                </div>

              </div>
            )}

            {false && (
              <div>

                <div className={`${classes.reviewsContainer} t-4 t-m-20 mb-16`}>
                  <ReviewsShowMore data={reviews} count={reviews.length} />
                </div>

              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

// PublicProfileWithSeveralCard.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };
