import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useCalculatePrice } from "../../CalculatePrice/useCalculatePrice";
import Calendar from "./Calendar";
import { pushGoogleAnalytics } from "utils/google-analytics/push";

export const BookingCalendarProvider = (props) => {
  const {
    show,
    rvId,
    currentRentalId,
    deliveryLocation,
    insurancePackage = undefined,
    setShowInstantRental = () => {},
    rentalType,
    specific_delivery_location_id,
    departureDate,
    returnDate
  } = props;

  const [valueCalculatePrice, onCalculatePrice] = useCalculatePrice(rvId, currentRentalId);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [resetAllError, setResetAllError] = useState(false);

  const handleOnCalculatePrice = useCallback(
    (periodFrom, periodTo) => {
      const departure_date = moment(periodFrom).format("YYYY-MM-DD");
      const return_date = moment(periodTo).format("YYYY-MM-DD");
      onCalculatePrice({
        departure_date,
        return_date,
        delivery_location: deliveryLocation || null,
        insurance_package: insurancePackage,
        rental_type: rentalType,
        specific_delivery_location_id
      });
    },
    [deliveryLocation, insurancePackage, onCalculatePrice, rentalType, specific_delivery_location_id]
  );
  if (show) {
    pushGoogleAnalytics("selectDates", {
      vehicleID: rvId
    });
  }
  useEffect(() => {
    if (valueCalculatePrice?.data) {
      setShowInstantRental(valueCalculatePrice?.data?.instant_rental);
    }
  }, [setShowInstantRental, valueCalculatePrice]);

  useEffect(() => {
    if (resetAllError) {
      setIsError(false);
      setErrorMessage("");
    } else {
      setIsError(valueCalculatePrice.error);
      setErrorMessage(valueCalculatePrice.errorMessage);
    }
  }, [resetAllError, valueCalculatePrice]);

  useEffect(() => {
    if (!departureDate && !returnDate) {
      setIsError(false);
      setErrorMessage("");
    }
  }, [departureDate, returnDate]);

  return (
    <Calendar
      isDateIntervalError={isError}
      dateIntervalErrorMessage={errorMessage}
      calculatePriceLoading={valueCalculatePrice.loading}
      handleOnCalculatePrice={handleOnCalculatePrice}
      valueCalculatePriceData={valueCalculatePrice}
      setResetAllError={setResetAllError}
      {...props}
      {...valueCalculatePrice}
    />
  );
};
