import { gql } from "@apollo/client";

export const addAdditionalDriversMutation = gql`
  mutation additional_drivers_add($rental: Int!, $additional_drivers: [InputAdditionalDriver]) {
    additional_drivers_add(rental: $rental, additional_drivers: $additional_drivers) {
      result {
        success
        errors {
          code
          message
        }
      }
    }
  }
`;

