import React, { useState } from "react";
import cn from 'classnames';
import { useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "components/Modal";
import Button from "components/Button";
import Form from "components/form/Form/Form";
import { FieldArray } from "components/form/FieldArray";
import Typography from "components/Typography";
import { FieldInput } from "components/form/FieldInput";
import { PlusIcon } from "components/Icon/PlusIcon";
import { TrashIcon } from "components/Icon/TrashIcon";

import referralInvite from "./mutation/referralInvite";

import classes from './ReferUsersModal.module.css';

const defaultValues = {
  emails: [{ value: "" }]
};

const ReferUsersModal = ({
  show,
  onClose,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  const [referralInviteAction] = useMutation(referralInvite);

  const onSubmit = async (data) => {
    setLoading(true);

    const emails = data.emails.map(item => item.value);

    try {
      await referralInviteAction({ variables: { emails } });
      onClose();
      onSuccess();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      modalWrapClassnames={classes.wrapper}
      modalClassnames={classes.modal}
      onClose={onClose}
      modalCrossButtonClassname={classes.crossButton}
    >
      <Typography
        component="h2"
        variant="headline"
        size="m"
        className={classes.title}
      >
        Refer users
      </Typography>

      <div className={classes.form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldArray
            control={control}
            defaultValues={[{ value: "" }]}
            name={`emails`}
            trigger={() => { return true; }}
            errors={errors}
            renderRow={(rowProps) => (
              <div key={rowProps.rowIndex} className={cn("t-16 b-16 t-s-20 b-s-20", classes.listItem)}>
                <Controller
                  name={`emails.${rowProps.rowIndex}.value`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: {
                      value: /[\w-]+@([\w-]+\.)+[\w-]+/,
                      message: "Please enter a valid email"
                    }
                  }}
                  render={(renderProps) => (
                    <>
                      <FieldInput {...renderProps} label="User email" errors={errors} />
                      {rowProps.rowIndex > 0
                        && (<Button icon={<TrashIcon />} secondary onClick={() => rowProps.handleRemove(rowProps.rowIndex)} />)}
                    </>
                  )}
                />
              </div>
            )}
            render={({ handleAdd, rowsCount }) => {
              return rowsCount > 4
                ? null
                : (
                    <div className={cn("b-16 b-s-20", classes.addButton)}>
                      <Button
                        icon={<PlusIcon />}
                        iconPosition="before"
                        label="Add more"
                        ghost
                        onClick={handleAdd}
                      />
                    </div>
                  );
            }
            }
          />

          <div className={cn("t-16 b-16 t-s-20 b-s-20", classes.formActions)}>
            <div className={classes.formAction}>
              <Button label="Cancel" secondary onClick={onClose} />
            </div>
            <div className={classes.formAction}>
              <Button label="Submit" type="submit" disabled={loading} />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ReferUsersModal;
