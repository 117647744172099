import { gql, useQuery } from "@apollo/client";

export const insurancePackagesQuery = gql`
  query insurancePackages($rvId: Int!, $rental_type: Int) {
    insurance_packages(rv: $rvId, rental_type: $rental_type) {
      id
      name
      description
      price: price_per_day {
        dollars_as_part
        cents_as_part
        cents_total
        user_friendly
      }
    }
  }
`;

export const useInsurances = (rvId, rentalType = undefined) => {
  return useQuery(insurancePackagesQuery, {
    variables: { rvId, rental_type: rentalType }
  });
};
