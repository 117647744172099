/*
 * Payment Form Utils
 */

export const FIELDS_IDS = {
  card_number: "number",
  card_holder: "holder",
  expiration_date: "exp_date",
  exp_month: "exp_month",
  exp_year: "exp_year",
  cvv: "cvv",
  address: "address",
  city: "city",
  state: "state",
  brand: "brand",
  zip: "zip",
  country: "country",
  token: "token"
};

export const DEFAULT_VALUES = {
  ...Object.values(FIELDS_IDS).reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {}),
  [FIELDS_IDS.country]: "US"
};
