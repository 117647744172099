import React from "react";
import PropTypes from "prop-types";
import { MyAccountContext } from "../../MyAccountContext";
import NavMenu  from "../NavMenu/NavMenu";

export const AsideNav = ({ isMobileNavMode, setIsMobileNavMode }) => {
  return (
    <MyAccountContext.Consumer>
      {(context) => {
        const { id, myAccount_steps_completed: completed } =
          context?.[0] ?? {};

        const { userData, accountContent: { extraSteps = [] } = {} } = context;
        const { has_payment } = userData;

        return (
          <aside className="no-gutter col-12 col-xl-2-wide">
            <NavMenu
              id={id}
              completed={completed}
              isMobileNavMode={isMobileNavMode}
              setIsMobileNavMode={setIsMobileNavMode}
              has_payment={has_payment}
              extraSteps={extraSteps}
            />
          </aside>
        );
      }}
    </MyAccountContext.Consumer>
  );
};

AsideNav.propTypes = {
  setIsMobileNavMode: PropTypes.func.isRequired
};
