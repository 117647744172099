export const CUSTOM_RENDER_IDS = {
  BOOKING_PAYMENT_GS_MEMBER: 'booking_payment_gs_member',
  BOOKING_PAYMENT_TII: 'booking_payment_tii'
};

export const ACTION_IDS = {
  BOOKING_APPLY_COUPON: "booking_apply_coupon",
  BOOKING_DISCARD_COUPON: "booking_discard_coupon",
  BOOKING_PROTECTION_CHANGE: "booking_protection_change",
  BOOKING_REMOVE_GS_MEMBER: "booking_remove_gs_member",
  BOOKING_REMOVE_TII: "booking_remove_tii",
  NO_SPLIT_PAYMENTS: 'no_split_payments',
  SPLIT_PAYMENTS: 'split_payments',
  ADJUST_RENTAL_FEE: 'adjust_rental_fee',
  ADJUST_DELIVERY_FEE: 'adjust_delivery_fee',
  ADDITIONAL_INSURANCE_CHANGE: 'additional_insurance_change'
};
