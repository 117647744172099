import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import Button from "../../../Button";
import Form from "../../../form/Form/Form";
import Message from "../../../Message";
import ProTipsCollapse from "../../../ProTipsCollapse";
import { OtherFeesFormContent } from "./OtherFeesFormContent";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

const DEFAULT_VALUES = {
  limited_daily_mileage: false,
  miles_included: 0,
  mileage_overage_rate: 0,
  limited_generator_hours: false,
  generator_hours_included: 0,
  generator_overage_rate: 0,
  cleaning_fee: 0,
  disposal_fee: 0,
  add_ons: []
};

const ADDONS_DEFAULT_VALUES = { name: "", price: "", price_type: "1" };

export const OtherFeesForm = ({
  initialData,
  notApplicableOptions,
  requestErrors,
  onBack,
  onLater,
  onSave,
  loading,
  updateContextSaveHandler,
  listingPublished,
  prevStep,
  nextStep
}) => {
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const defaultValues = Object.assign({}, DEFAULT_VALUES, initialData);

  const {
    control,
    handleSubmit,
    formState,
    formState: { isDirty },
    trigger: triggerFormValidate,
    setError,
    watch,
    reset,
    setValue,
    getValues
  } = useForm({
    shouldUnregister: false,
    defaultValues,
    mode: "onSubmit"
  });
  useFooterVisible(isDirty);

  const [globalError] = useRequestErrors(requestErrors, setError);

  useEffect(() => {
    updateContextSaveHandler(
      handleSubmit(async (values) => {
        const saveResult = await onSave(values, { toNextStep: false });
        reset(getValues(), {
          keepDirty: false
        });
        return saveResult;
      })
    );
  }, [handleSubmit, getValues, onSave, reset, updateContextSaveHandler]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSave)}>
        <div className="row b-m-12 b-l-0">
          <div className="col">
            <Text
              id="step_title"
              section="otherFeesAddonsSectionContent"
              component="h4"
              variant="title"
              weight="bold"
            />
            <ProTipsCollapse className="t-16 b-16 b-m-32" mediaParams="767px" />
          </div>
        </div>
        <OtherFeesFormContent
          control={control}
          setValue={setValue}
          watch={watch}
          errors={formState.errors}
          triggerFormValidate={triggerFormValidate}
          addonsDefaultValues={ADDONS_DEFAULT_VALUES}
          notApplicableOptions={notApplicableOptions}
        />
        {globalError && (
          <div className="t-16 t-m-40">
            <Message type="error" text={globalError} />
          </div>
        )}
        {!listingPublished && (
          <div className="row">
            { nextStep && isSmallDevice && (
              <div className="col-9 t-20 b-36">
                <Button disabled={loading} onClick={onLater} secondary>
                Skip
                </Button>
              </div>
            )}
            <div className="col t-16 t-m-20 b-16 b-m-20">
              <div className="btn-group btn-group--center btn-group--m-start btn-group_xs-fixed">
                {prevStep && (
                  <ButtonContent
                    id="previous_button"
                    section="otherFeesAddonsSectionContent"
                    onClick={onBack}
                    disabled={loading}
                    secondary
                  />
                )}
                {nextStep && !isSmallDevice && (
                  <Button disabled={loading} onClick={onLater} secondary>
                    Skip
                  </Button>
                )}
                {nextStep && (
                  <ButtonContent
                    id="save_and_next_button"
                    section="otherFeesAddonsSectionContent"
                    type="submit"
                    disabled={loading}
                  />
                )}

                {!nextStep && (
                  <Button type="submit" disabled={loading}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </Form>
    </>
  );
};
