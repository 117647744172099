import React, { useEffect, useCallback } from "react";
import Button from "components/Button";
import { useForm, Controller } from "react-hook-form";
import Form from "components/form/Form/Form";
import { FieldArray } from "components/form/FieldArray";
import Typography from "components/Typography";
import { FieldInput } from "components/form/FieldInput";
import { PlusIcon } from "components/Icon/PlusIcon";
import { TrashIcon } from "components/Icon/TrashIcon";
import { MAX_DRIVERS_COUNT_DEFAULT } from "components/ModalAdditionalDrivers/constants";
import { RE_EMAIL_FORMAT } from "utils/constants/re";
import AdditionalDriversFooter from "./AdditionalDriversFooter";
import cn from 'classnames';
import classes from './AdditionalDrivers.module.css';

function getDefaultValues(drivers = []) {

  const resultDrivers = drivers.map(driver => ({ name: driver.name, email: driver.email }));

  if (!resultDrivers.length) {
    return [{ name: "", email: "" }];
  }

  return resultDrivers;
}

function validEmailField(email, drivers) {
  const isEmailCorrect = RE_EMAIL_FORMAT.test(email);
  if (!isEmailCorrect) return "Please enter a valid email";

  const duplicatedEmails = drivers.filter((driver) => driver?.email?.trim() === email?.trim());
  if (duplicatedEmails?.length >= 2) return "Duplicate email";

  return true;
}


const AdditionalDrivers = (props) => {
  const {
    onClose,
    onAddDriversSubmit,
    maxDrivers = MAX_DRIVERS_COUNT_DEFAULT,
    driversList = []
  } = props;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      drivers: getDefaultValues(driversList)
    },
    mode: "onSubmit",
    shouldUnregister: false
  });

  const onSubmit = useCallback(({ drivers }) => {
    onAddDriversSubmit(drivers);
  }, [onAddDriversSubmit]);

  const { drivers } = watch([
    "drivers"
  ]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.content}>
        <Typography
          variant="subtitle"
          size="s"
          className={cn(classes.subtitle, 'tac')}
        >
            You may add additional drivers if needed (up to {maxDrivers}).
        </Typography>

        <div
          className={cn(
            classes.form,
            "mt-16 mt-m-20"
          )}
        >
          <FieldArray
            control={control}
            defaultValues={[{ name: "", email: "" }]}
            name={`drivers`}
            trigger={() => { return true; }}
            errors={errors}
            renderRow={(rowProps) => {
              return (
                <>
                  {rowProps.rowIndex !== 0 && <div className="devider-solid mt-24 mb-24 mt-m-32 mb-m-32" />}
                  <div key={rowProps.rowIndex} className={cn(classes.formItem)}>
                    <div className={classes.formItemName}>
                      <Controller
                        name={`drivers.${rowProps.rowIndex}.name`}
                        control={control}
                        defaultValue={rowProps.name}
                        rules={{
                          required: true,
                          validate: (v) => {
                            if (!v) return "Please enter valid name";
                          }
                        }}
                        render={(renderProps) => (
                          <>
                            <FieldInput {...renderProps} label="User name" errors={errors} className="fw" />
                          </>
                        )}
                      />
                    </div>
                    <div className={classes.formItemEmail}>
                      <Controller
                        name={`drivers.${rowProps.rowIndex}.email`}
                        control={control}
                        defaultValue={rowProps.email}
                        rules={{
                          required: true,
                          validate: (v) => {
                            return validEmailField(v, drivers);
                          }
                        }}
                        render={(renderProps) => (
                          <>
                            <FieldInput {...renderProps} label="User email" errors={errors} className="fw" />
                          </>
                        )}
                      />
                    </div>
                    <div className={classes.formItemRemoveButton}>
                      <Button
                        icon={<TrashIcon />}
                        secondary
                        onClick={() => rowProps.handleRemove(rowProps.rowIndex)}
                        disabled={drivers?.length === 1}
                        className={rowProps.rowIndex === 0 && drivers?.length === 1 && classes.trashDisabled}
                      />
                    </div>
                  </div>
                </>
              );
            }}
            render={({ handleAdd, rowsCount }) => {
              return rowsCount >= maxDrivers
                ? null
                : (
                    <div className={cn("mt-32 mb-16 mt-m-40 mb-l-20", classes.addButtonContainer)}>
                      <Button
                        icon={<PlusIcon />}
                        iconPosition="before"
                        label="Add additional drivers"
                        className={classes.addButton}
                        ghost
                        onClick={handleAdd}
                      />
                    </div>
                  );
            }
            }
          />
        </div>

      </div>
      <div className="t-l-20 b-l-20">
        <AdditionalDriversFooter
          onClose={onClose}
        />
      </div>
    </Form>
  );
};

export default AdditionalDrivers;
