import React from "react";
import { AdditionalInsurancePackageItem } from "components/AdditionalInsuranceList";
import cn from "classnames";
import classes from "./AdditionalInsuranceMultipleItemContent.module.css";

const AdditionalInsuranceMultipleItemContent = (props) => {
  const {
    multi_select,
    itemPackages = [],
    selectedPackages,
    onCheckTap,
    onRadioTap,
    isItemPackagesDisabled
  } = props;

  return (
    <div className={classes.sectionsContainer}>
      {itemPackages.map(({ id, name, description, value }) => (
        <section className={cn(classes.section, multi_select && classes.sectionMultiSelect)} key={id}>
          <AdditionalInsurancePackageItem
            packageId={id}
            name={name}
            description={description}
            value={value}
            multi_select={multi_select}
            isItemPackagesDisabled={isItemPackagesDisabled}
            selectedPackages={selectedPackages}
            onChange={multi_select? onCheckTap : onRadioTap}
          />
        </section>
      ))}
    </div>
  );
};

AdditionalInsuranceMultipleItemContent.propTypes = {
};

export default AdditionalInsuranceMultipleItemContent;
