import pencilIcon from "assets/images/pencil.svg";
import React, { useEffect, useState } from "react";
import Tooltip from "components/Tooltip/Tooltip";
import classes from "./PickUpDelevery.module.css";

export const PickUpDelevery = ({
  fullAddress,
  isPickUpDeliveryLocationEdit,
  short_address,
  setShowPickUpDeliveryModal,
  isLocationWarning = false,
  dispatch,
  rentalType,
  deliveryOnlyData = {}
}) => {
  const deliveryOptionFalseClasses = isPickUpDeliveryLocationEdit
    ? classes.pickUpDelevery
    : classes.pickUpDeleveryNoClick;

  const deliveryOnlyFullAddress = deliveryOnlyData?.fullAddress;
  const isDeliveryOnlyLocationSelected = deliveryOnlyData?.locationSelected;

  const [tooltipShown, setTooltipShown] = useState(false);

  useEffect(() => {
    if (isLocationWarning) {
      setTooltipShown(true);
    }
  }, [isLocationWarning]);

  return (
    <div
      className={deliveryOptionFalseClasses}
      onClick={isPickUpDeliveryLocationEdit ? () => setShowPickUpDeliveryModal(true) : null}
    >
      <div className={classes.label}>
        <Tooltip
          tooltipContainerClassName={classes.tooltip}
          placement="top"
          tooltipShown={tooltipShown}
          trigger="click"
          tooltipContent={(
            <div>
              Please select pickup or delivery location
            </div>
          )}
          onVisibilityChange={(event) => {
            if (!event) {
              setTooltipShown(false);
              dispatch({
                isLocationWarning: false
              });
            }
          }}
          tooltipOnClick={() => {
            setTooltipShown(false);
            dispatch({
              isLocationWarning: false
            });
          }}
        >
          {(ref) => (
            <p htmlFor="pickup" className={classes.title} ref={ref}>
              {rentalType === 2 && (<span>Delivery location</span>)}
              {rentalType === 1 && (<>Pick-up{isPickUpDeliveryLocationEdit && <span>/Delivery location</span>}</>)}
            </p>
          )}
        </Tooltip>
        {isPickUpDeliveryLocationEdit && (
          <img src={pencilIcon} alt="Icon" className={classes.pencil} />
        )}
      </div>
      {rentalType === 2 && !isDeliveryOnlyLocationSelected && (<p className={classes.fullAddress}>Not specified</p>)}
      {(rentalType === 2 && isDeliveryOnlyLocationSelected) && (<p className={classes.fullAddress}>{deliveryOnlyFullAddress}</p>)}
      {rentalType === 1 && (<p className={classes.fullAddress}>{fullAddress || short_address}</p>)}
    </div>
  );
};
