import React, { useState } from "react";
import Button from "components/Button";
import { Reviews } from "components/Reviews/Reviews";
import Typography from "components/Typography";
import classes from "./ReviewsEmpty.module.css";

export const ReviewsEmpty = ({ contentText, buttonLabel }) => {

  return (
    <div className={`${classes.container}`}>
      <div className="">
        <Typography variant="subtitle" size="l" weight="bold">
          My reviews
        </Typography>
      </div>

      <div className="">
        <Typography variant="body" size="l" className={classes.contentText}>
          {contentText}
        </Typography>
      </div>
      <div className="t-12 t-m-20 b-16 b-m-20">
        <Button
          label={buttonLabel}
          onClick={() => {}}
          secondary
        />
      </div>
    </div>
  );
};

ReviewsEmpty.propTypes = {

};

