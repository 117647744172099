import React, { useState } from "react";
import Review from "./Review";
import { getRentalOpponent, getRentalReviewData } from "processes/Rentals/utils";
import classnames from "classnames";
import style from "./style.module.css";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

function getReviewData(data) {
  return {
    name: data?.author_name,
    rating: data?.rating || 0,
    date: data?.date,
    testimonial: data?.testimonial,
    avatarUrl: data?.author_photo?.srcset?.[0]?.url,
    photos: data?.photos || [],
    moderated: data?.moderated
  };
}

const AllReviews = (props) => {
  const {
    rental,
    refetchRental,
    rentalId
  } = props;
  const [showNotificationPopover, setShowNotificationPopover] = useState(false);
  const myReviewData = getRentalReviewData(rental)?.myReviewData;
  const opponentReviewData = getRentalReviewData(rental)?.opponentReviewData;
  const [myReviewState, setMyReviewState] = useState(!!myReviewData);
  const [opponentReviewState, setOpponentReviewState] = useState(!!opponentReviewData);
  const opponentData = getRentalOpponent(rental);
  const opponentRole = rental?.role?.value === "Owner" ? "Renter" : "Owner";
  const actor = {
    self: 'self',
    opponent: opponentData?.short_name
  };

  const myReview = getReviewData(myReviewData);
  const opponentReview = getReviewData(opponentReviewData);

  return (
    <section className={classnames(style.myReviews, "ml-a")}>

      {/* { myReviewState && (
        <Review
          completed={myReviewState}
          actor={actor.self}
          data={myReview}
          opponentRole={opponentRole}
          refetchRental={refetchRental}
          rentalId={rentalId}
          setReviewState={setMyReviewState}
          setShowNotificationPopover={setShowNotificationPopover}
        />
      )}
      { !myReviewState && (
        <Review
          completed={false}
          actor={actor.self}
          opponentRole={opponentRole}
          refetchRental={refetchRental}
          rentalId={rentalId}
          setReviewState={setMyReviewState}
          setShowNotificationPopover={setShowNotificationPopover}
        />
      )} */}
      <Review
        completed={myReviewState}
        actor={actor.self}
        data={myReview}
        opponentRole={opponentRole}
        refetchRental={refetchRental}
        rentalId={rentalId}
        setReviewState={setMyReviewState}
        setShowNotificationPopover={setShowNotificationPopover}
      />

      <Review
        completed={opponentReviewState}
        actor={actor.opponent}
        data={opponentReview}
        opponentRole={opponentRole}
        refetchRental={refetchRental}
        rentalId={rentalId}
        isOpponent
        setReviewState={setOpponentReviewState}
        setShowNotificationPopover={setShowNotificationPopover}
      />
      {/* { opponentReviewState && (
        <Review
          completed={opponentReviewState}
          actor={actor.opponent}
          data={opponentReview}
          opponentRole={opponentRole}
          refetchRental={refetchRental}
          rentalId={rentalId}
          isOpponent
          setReviewState={setOpponentReviewState}
          setShowNotificationPopover={setShowNotificationPopover}
        />
      )}

      { !opponentReviewState && (
        <Review
          completed={false}
          actor={actor.opponent}
          opponentRole={opponentRole}
          refetchRental={refetchRental}
          rentalId={rentalId}
          isOpponent
          setReviewState={setOpponentReviewState}
          setShowNotificationPopover={setShowNotificationPopover}
        />
      )} */}

      {showNotificationPopover && (
        <NotificationPopover
          show={showNotificationPopover}
          status="default"
          text={"We are reviewing your review changes."}
          onClose={() => {
            setShowNotificationPopover(false);
          }}
        />
      )}
    </section>
  );
};

export default AllReviews;
