import { useCallback, useEffect } from "react";

const CHAT_TOP_POSITION = "--cwrr-chat-top-position";
const CHAT_INITIAL_TOP_POSITION = 140;
const NAV_INITIAL_HEIGHT = 36;

function calculateScroll() {
  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  if (scrollTop <= NAV_INITIAL_HEIGHT) {
    return CHAT_INITIAL_TOP_POSITION - scrollTop;
  }
  return 104;
}

export function useChatTopPosition() {
  const scrollFunc = useCallback(() => {
    const top = calculateScroll();
    document.documentElement.style.setProperty(CHAT_TOP_POSITION, top + 'px');
  }, []);

  useEffect(() => {
    scrollFunc();
    window.addEventListener("scroll", scrollFunc);
    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [scrollFunc]);
}
