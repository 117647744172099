import React, { useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { insurancePackages } from "processes/PreDefinedRental/queries/insurancePackages";
import GoodsamInsurance from "components/GoodsamInsurance/GoodsamInsurance";

const InsurancePlan = ({
  insuranceId,
  setInsuranceId,
  rvId,
  rental_type,
  handleSummaryOnCalculatePrice,
  showBookingInsuranceModal,
  setShowBookingInsuranceModal
}) => {

  const [load, { data, loading }] = useLazyQuery(insurancePackages);

  useEffect(() => {
    if (rvId) {
      load({
        variables: {
          rv: rvId,
          rental_type
        }
      });
    }
  }, [load, rental_type, rvId]);

  const toggleOpen = useCallback(() => {
    setShowBookingInsuranceModal((p) => !p);
  }, [setShowBookingInsuranceModal]);

  const onSave = useCallback(
    (value) => {
      setInsuranceId(value);
      handleSummaryOnCalculatePrice();
      toggleOpen();
    },
    [handleSummaryOnCalculatePrice, setInsuranceId, toggleOpen]
  );

  if (loading || !data) return null;

  return (
    <>
      <GoodsamInsurance
        rvId={rvId}
        show={showBookingInsuranceModal}
        onClose={toggleOpen}
        formData={{ insurance: insuranceId - 1 }}
        setIsShowBookingInsurance={toggleOpen}
        onSelectInsurance={onSave}
        data={data}
      />
    </>
  );
};

export default InsurancePlan;
