import { featureFlagsQuery } from "queries/featureFlagsQuery";
import { useQuery } from "@apollo/client";

export function checkFeatureIsEnabled(data = {}, feature) {
  const {
    feature_flags = []
  } = data;

  if (feature_flags.length > 0) {
    if (feature_flags.includes(feature)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function useFeatureFlags(feature) {
  const { data: featureFlagsData } = useQuery(featureFlagsQuery, {
    fetchPolicy: "no-cache"
  });

  return checkFeatureIsEnabled(featureFlagsData, feature);
}
