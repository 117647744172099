import React from "react";
import moment from "moment";

export function renderDay(
  day,
  daysData,
  dailyRateCentsTotal,
  dailyRateDollarAsPart,
  classes
) {
  if (!day && !daysData) {
    return null;
  }

  const indexOfTheDay = moment(day).format("YYYY-MM-DD");
  const dayPrice = daysData[indexOfTheDay]?.price?.dollars_as_part;
  const dayCentsTotal = daysData[indexOfTheDay]?.price?.cents_total;
  const isDiffPrice = !!dayCentsTotal && dayCentsTotal !== dailyRateCentsTotal;

  return (
    <div className={isDiffPrice ? classes.dayPriceDiff : classes.dayPrice}>
      ${isDiffPrice ? dayPrice : dailyRateDollarAsPart}
    </div>
  );
}
