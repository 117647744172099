import { gql } from "@apollo/client";

export const photoInstantOrderMutation = gql`
  mutation photoInstantOrderMutation (
    $rv_id: Int!
    $photos_order: [Int]!
    $timestamp: Float!
  ) {
    rv_images_instant_order (
      rv_id: $rv_id
      photos_order: $photos_order
      timestamp: $timestamp
    ) {
        result {
          success
          errors {
            code
            field
            message
          }
        }
    }
  }
`;
