import PropTypes from 'prop-types';
import { useContentSection } from '../useContent';

function cleanUpSingle(entity) {
  const { __typename, ...rest } = entity;

  return rest;
}

function cleanUp(entity) {
  return Array.isArray(entity) ? entity.map(cleanUpSingle) : cleanUpSingle(entity);
}

const findById = id => content => content.find(item => item.id === id);

export function withContent(ElementComponent, elementType, entityProvider = findById) {
  if (!elementType) {
    throw new Error('elementType is not defined');
  }

  const ConnectedElement = ({ id, section, ...rest }) => {
    const content = useContentSection(section);

    if (!content[elementType]) {
      return null;
    }

    const entity = entityProvider(id)(content[elementType]);

    if (!entity || (Array.isArray(entity) && !entity.length)) {
      return null;
    }

    return (
      <ElementComponent
        elementId={`${section}--${elementType}--${id}`}
        content={cleanUp(entity)}
        {...rest}
      />
    );
  };

  ConnectedElement.propTypes = {
    id: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired
  };

  ConnectedElement.displayName = 'Connected' + (ElementComponent.displayName || 'Element');

  return ConnectedElement;
}
