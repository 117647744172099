import { gql } from "@apollo/client";

export const vehiclesQuery = gql`
query {
  user {
    vehicles {
      availability_calendar {
        date
        busy
        available
        price {
          cents_as_part
          dollars_as_part
          cents_total
          user_friendly
        }
      }
      discounts {
        from
        till
        discount
      }
      id
      rv_name
      class
      length {
        feet_as_part
      }
      sleeps
      slide_outs
      status {
        value
      }
      daily_rate {
        dollars_as_part
        cents_total
      }
      title_image
      public_url
      location {
        full_address
        short_address
        building
        street
        city
        state
        zip
        zip_suffix
        latitude
        longitude
      }
      minimum_duration
      allow_standard_rental
      allow_delivery_only_rental
      delivery_option
      dor_delivery_option
      paid_delivery
      dor_paid_delivery
      paid_delivery
      dor_paid_delivery
      specific_delivery_locations {
        id
        delivery_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        description
        location {
          full_address
          short_address
          building
          street
          city
          state
          zip
          zip_suffix
          latitude
          longitude
        }
      }
      dor_specific_delivery_locations {
        id
        delivery_fee {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        description
        location {
          full_address
          short_address
          building
          street
          city
          state
          zip
          zip_suffix
          latitude
          longitude
        }
      }
      add_ons {
        id
        name
        price {
          cents_total
          dollars_as_part
          cents_as_part
          user_friendly
        }
        price_type {
          key
          value
        }
      }
    }
  }
}
`;
