import { gql } from "@apollo/client";

export const garagePhotosQuery = gql`
  query garage_photos(
    $rv_garage_id: String!
  ) {
    garage_photos(
      rv_garage_id: $rv_garage_id
    ) {
      id
      image {
        id
        caption
        srcset {
          url
         }
      }
      rv_garage_id
      is_used_on_rentals
      is_used_on_exchange
    }
  }
`;
