import React, { useMemo, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import maxSize from "popper-max-size-modifier";
import Portal from "../Portal";
import classes from "./Dropdown.module.css";
import DropdownList from "./DropdownList";
import DropdownListItem from "./DropdownListItem";

const sameWidthModifier = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
  }
};

const DropdownComponent = (props) => {
  const {
    paperClassName,
    anchorEl,
    placement,
    children,
    onClose,
    modifiers,
    boundaryElement,
    boundaryPadding
  } = props;
  const [dropdownElement, setDropdownElement] = useState(null);

  const modifiersMemoized = useMemo(() => {
    const value = [...modifiers, sameWidthModifier];

    if (boundaryElement) {
      value.push(
        {
          name: "preventOverflow",
          options: {
            boundary: boundaryElement,
            padding: boundaryPadding
          }
        },
        {
          ...maxSize,
          options: {
            boundary: boundaryElement,
            padding: boundaryPadding
          }
        },
        {
          name: "applyMaxSize",
          enabled: true,
          phase: "beforeWrite",
          requires: ["maxSize"],
          fn({ state }) {
            const { width, height } = state.modifiersData.maxSize;

            state.styles.popper = {
              ...state.styles.popper,
              maxWidth: `${width}px`,
              maxHeight: `${height}px`
            };
          }
        }
      );
    }

    return value;
  }, [boundaryElement, boundaryPadding, modifiers]);

  const { styles, attributes } = usePopper(anchorEl, dropdownElement, {
    placement,
    modifiers: modifiersMemoized
  });

  return (
    <div className={classes.root}>
      <div
        ref={setDropdownElement}
        className={classes.dropdown}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className={classnames([classes.paper, paperClassName])}>
          {typeof children === "function" ? children() : children}
        </div>
      </div>
      <div className={classes.backdrop} onClick={onClose} />
    </div>
  );
};

/**
 * Popper
 * rely on the 3rd party library [Popper.js](https://popper.js.org/react-popper/) for positioning.
 */
const Dropdown = (props) => {
  const {
    paperClassName,
    anchorEl,
    children,
    container,
    disablePortal = false,
    open,
    onClose,
    placement = "bottom-start",
    modifiers,
    boundaryElement,
    boundaryPadding
  } = props;
  return (
    <Portal disablePortal={disablePortal} container={container}>
      {anchorEl && open && (
        <DropdownComponent
          paperClassName={paperClassName}
          anchorEl={anchorEl}
          placement={placement}
          onClose={onClose}
          modifiers={modifiers}
          boundaryElement={boundaryElement}
          boundaryPadding={boundaryPadding}
        >
          {children}
        </DropdownComponent>
      )}
    </Portal>
  );
};

Dropdown.defaultProps = {
  modifiers: [{ name: "offset", options: { offset: [0, 4] } }]
};

const popperPropTypes = {
  /**
   * Class Name for Paper
   */
  paperClassName: PropTypes.string,
  /**
   * A HTML element or a function that returns either.
   * It's used to set the position of the popper.
   * The return value will passed as the reference object of the Popper instance.
   */
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Popper render function or node.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * Popper.js is based on a "plugin-like" architecture,
   * most of its features are fully encapsulated "modifiers".
   *
   * A modifier is a function that is called each time Popper.js needs to
   * compute the position of the popper.
   * For this reason, modifiers should be very performant to avoid bottlenecks.
   * To learn how to create a modifier, [read the modifiers documentation](https://popper.js.org/docs/v2/modifiers/).
   */
  modifiers: PropTypes.array,
  /**
   * Callback when popper is being closed
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Popper placement.
   */
  placement: PropTypes.oneOf([
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top"
  ]),

  /**
   * Element to fit dropdown and padding
   */
  boundaryElement: PropTypes.instanceOf(Element),
  boundaryPadding: PropTypes.number
};

DropdownComponent.propTypes = popperPropTypes;

Dropdown.propTypes = {
  ...popperPropTypes,
  /**
   * If `true`, the popper is visible.
   */
  open: PropTypes.bool.isRequired,
  /**
   * A HTML element that returns either.
   * The `container` will have the portal children appended to it.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: PropTypes.instanceOf(Element),
  /**
   * Disable the portal behavior.
   * The children stay within it's parent DOM hierarchy.
   */
  disablePortal: PropTypes.bool
};

Dropdown.List = DropdownList;
Dropdown.ListItem = DropdownListItem;

export default Dropdown;
