import React, { useState, useCallback } from "react";
import { PriceForm } from "components/PriceForm";
import { CUSTOM_RENDER_IDS, ACTION_IDS } from "constants/priceForm";
import Coupon from "./components/Coupon";
import InsurancePlan from "./components/InsurancePlan";
import {
  removeExtraInsuranceIdFromSelectPackages
} from "components/AdditionalInsuranceList/utils";
import BecomeGoodSamMember from "./components/BecomeGoodSamMember";
import { STEPS } from "processes/Booking/components/BookingModal/steps";

const BookingPaymentOptionsTable = ({
  rvData,
  prices,
  price_form_detailed,
  membershipId,
  insuranceId,
  setCoupon,
  setIsInterruptionVisible,
  setMembershipId,
  setInsuranceId,
  setIsNoSplitPayments,
  rental_type,
  onClickStepById,
  setSelectedPackagesAdditionalInsurance,
  selectedPackagesAdditionalInsurance,
  setIsClearTii
}) => {
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showBookingInsuranceModal, setShowBookingInsuranceModal] = useState(false);

  const handleSpecRender = useCallback(({ type = null }) => {
    if (type === CUSTOM_RENDER_IDS.BOOKING_PAYMENT_GS_MEMBER) {
      return (
        <BecomeGoodSamMember
          price={prices?.good_sam_membership_discount_plan}
          membershipId={membershipId}
          setMembershipId={setMembershipId}
        />
      );
    }

    return false;

  }, [membershipId, prices?.good_sam_membership_discount_plan, setMembershipId]);

  const onPriceFormAction = useCallback((id) => {
    switch (id) {
    case ACTION_IDS.BOOKING_APPLY_COUPON:
      setShowCouponModal(true);
      break;
    case ACTION_IDS.BOOKING_DISCARD_COUPON:
      setCoupon(null);
      break;
    case ACTION_IDS.BOOKING_PROTECTION_CHANGE:
      setShowBookingInsuranceModal(true);
      break;
    case ACTION_IDS.BOOKING_REMOVE_TII:
      setIsInterruptionVisible(undefined);
      setSelectedPackagesAdditionalInsurance(removeExtraInsuranceIdFromSelectPackages(selectedPackagesAdditionalInsurance));
      setIsClearTii(true);
      break;
    case ACTION_IDS.BOOKING_REMOVE_GS_MEMBER:
      setMembershipId(null);
      break;
    case ACTION_IDS.NO_SPLIT_PAYMENTS:
      setIsNoSplitPayments(true);
      break;
    case ACTION_IDS.SPLIT_PAYMENTS:
      setIsNoSplitPayments(false);
      break;
    case ACTION_IDS.ADDITIONAL_INSURANCE_CHANGE:
      onClickStepById(STEPS.ADDITIONAL_INSURANCE.ID);
      break;
    default:
      break;
    }
  }, [setCoupon, setIsInterruptionVisible, setSelectedPackagesAdditionalInsurance, selectedPackagesAdditionalInsurance, setIsClearTii, setMembershipId, setIsNoSplitPayments, onClickStepById]);

  return (
    <div>
      {price_form_detailed && (
        <PriceForm
          data={[price_form_detailed]}
          specRender={handleSpecRender}
          onAction={onPriceFormAction}
        />
      )}

      <Coupon
        showCouponModal={showCouponModal}
        setShowCouponModal={setShowCouponModal}
        setCoupon={setCoupon}
      />

      <InsurancePlan
        rvData={rvData}
        showBookingInsuranceModal={showBookingInsuranceModal}
        setShowBookingInsuranceModal={setShowBookingInsuranceModal}
        insuranceId={insuranceId}
        setInsuranceId={setInsuranceId}
        rental_type={rental_type}
      />
    </div>
  );
};

export default BookingPaymentOptionsTable;
