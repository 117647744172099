import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import Button from "../../../Button/Button";
import Form from "../../../form/Form/Form";
import Message from "../../../Message";
import ProTipsCollapse from "../../../ProTipsCollapse";
import { ListingDetailsFormContent } from "./ListingDetailsFormContent";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

const DEFAULT_VALUES = {
  listing_title: "",
  listing_description: "",
  what_is_included: "",
  recommendations: ""
};

export const ListingDetailsForm = ({
  initialData,
  requestErrors,
  onBack,
  onLater,
  onSave,
  loading,
  updateContextSaveHandler,
  listingPublished,
  prevStep,
  nextStep
}) => {
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const defaultValues = Object.assign({}, DEFAULT_VALUES, initialData);
  const {
    control,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues,
    mode: "onSubmit"
  });

  useFooterVisible(isDirty);
  const [globalError] = useRequestErrors(requestErrors, setError);

  useEffect(() => {
    updateContextSaveHandler(
      handleSubmit(async (values) => {
        const saveResult = await onSave(values, { toNextStep: false });
        reset(getValues(), {
          keepDirty: false
        });
        return saveResult;
      })
    );
  }, [getValues, handleSubmit, onSave, reset, updateContextSaveHandler]);

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <div className="row">
        <div className="col">
          <Text
            id="step_title"
            section="listingDetailsSectionContent"
            variant="headline"
            size="s"
          />
          <ProTipsCollapse className="t-16 b-32" mediaParams="767px" />
        </div>
      </div>
      <ListingDetailsFormContent control={control} errors={errors} />
      {globalError && (
        <div className="t-16 t-m-20">
          <Message type="error" text={globalError} />
        </div>
      )}
      {!listingPublished && (
        <div className="row">
          { nextStep && isSmallDevice && (
            <div className="col-9 t-16 b-36">
              <Button disabled={loading} onClick={onLater} secondary>
                Skip
              </Button>
            </div>
          )}
          <div className="col t-16 t-m-20 b-16 b-m-20">
            <div className="btn-group btn-group--center btn-group--m-start btn-group_xs-fixed">
              {prevStep && (
                <ButtonContent
                  id="previous_button"
                  section="listingDetailsSectionContent"
                  onClick={onBack}
                  disabled={loading}
                  secondary
                />
              )}
              {nextStep && !isSmallDevice && (
                <Button disabled={loading} onClick={onLater} secondary>
                  Skip
                </Button>
              )}

              {nextStep && (
                <ButtonContent
                  id="save_and_next_button"
                  section="listingDetailsSectionContent"
                  type="submit"
                  disabled={loading}
                />
              )}

              {!nextStep && (
                <Button type="submit" disabled={loading}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
