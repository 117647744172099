import React from "react";
import PropTypes from "prop-types";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as IconQuestion } from "./icon-question.svg";
import classes from "./styles.module.css";

const TooltipToggleQuestion = ({ children }) => {
  return (
    <>
      <Tooltip
        tooltipContainerClassName={classes.questionTooltipContainer}
        trigger="click"
        tooltipContent={children}
        offset={[0, 12]}
      >
        {(ref) => (
          <span ref={ref} className={classes.iconQuestionContainer}>
            <IconQuestion className={classes.btnIcon} />
          </span>
        )}
      </Tooltip>
    </>
  );
};

TooltipToggleQuestion.propTypes = {
  children: PropTypes.node.isRequired
};

export default TooltipToggleQuestion;
