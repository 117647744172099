import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CollapseIcon from "./CollapseIcon";
import classes from "./BasicCollapse.module.css";

const BasicCollapse = (props) => {
  const {
    className,
    headerClassName,
    iconClassName,
    iconOpenStateClassName,
    iconCloseStateClassName,
    defaultOpen = false,
    renderHeader,
    onChange,
    children,
    columnReverse
  } = props;
  const [open, setOpen] = useState(defaultOpen);
  const isChildrenFunc = typeof children === "function";

  useEffect(() => {
    onChange?.(open);
  }, [onChange, open]);

  return (
    <div
      className={classnames(
        className,
        classes.root,
        "df fdc",
        {
          [classes.closed]: !open && !isChildrenFunc,
          [classes.columnReverse]: columnReverse
        }
      )}
    >
      <div
        className={classnames(
          headerClassName,
          classes.header,
          "row f-nowrap no-gutters"
        )}
        data-testid="toggle"
        onClick={() => setOpen(p => !p)}
      >
        <div className={classes.headerInner}>{renderHeader(open)}</div>
        <CollapseIcon
          className={classnames(open ? iconOpenStateClassName : iconCloseStateClassName, iconClassName, "df fcc", classes.icon)}
          isActive={open}
        />
      </div>
      <div className={classes.content}>
        {isChildrenFunc ? children(open) : children}
      </div>
    </div>
  );
};

BasicCollapse.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  defaultOpen: PropTypes.bool,
  renderHeader: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  columnReverse: PropTypes.bool
};

export default BasicCollapse;
