import React from "react";

import Switch from "../../../form/Switch";
import { Controller } from "react-hook-form";
import { Text } from "components/ContentSection/elements";
import { LabelContent } from "components/ContentSection/LabelContent";

export const AvailabilityFormContent = ({ control, errors }) => (
  <>
    <div className="row t-16 t-m-20">
      <div className="col">
        <Text
          id="visibility_section_title"
          section="availabilitySectionContent"
          component="h6"
          variant="subtitle"
          weight="bold"
        />
      </div>
    </div>
    <div className="row">
      <div className="col t-12 t-m-20 b-m-8">
        <Controller
          name="visible"
          control={control}
          render={(renderProps) => (
            <LabelContent
              section="availabilitySectionContent"
              id="visibility_toggle"
            >
              {(label) => <Switch id="visible" text={label} {...renderProps} />}
            </LabelContent>
          )}
        />
      </div>
    </div>
    <div className="row">
      <div className="col t-2 t-m-12 b-16 b-m-20 mt-8 mt-m-0">
        <Text
          id="visibility_description"
          section="availabilitySectionContent"
          variant="body"
          size="s"
        />
      </div>
    </div>
  </>
);
