import HeaderDetails from "./HeaderDetails";
import React from "react";
import { filterInitialData } from "../../steps/filterInitialData";
import { defaultTo, lensProp, over, pipe, prop } from "ramda";

const filterDetails = filterInitialData([
  "images",
  "listing_title",
  "number_of_requests",
  "number_of_confirmed_trips",
  "number_of_completed_trips",
  "number_of_expired_trips",
  "views",
  "rating",
  "number_of_reviews",
  "total_revenue",
  "year",
  "make",
  "model"
]);

const defaultToZero = defaultTo(0);

const processDetails = pipe(
  over(
    lensProp("total_revenue"),
    pipe(prop("cents_total"), defaultToZero, (v) => v / 100)
  ),
  filterDetails
);

const HeaderDetailsLoader = ({ data }) => {
  const normalizedData = processDetails(data);

  return (
    <HeaderDetails>
      <HeaderDetails.Item title="Requests">
        {normalizedData.number_of_requests}
      </HeaderDetails.Item>
      <HeaderDetails.Item title="Confirmed">
        {normalizedData.number_of_confirmed_trips}
      </HeaderDetails.Item>
      <HeaderDetails.Item title="Completed">
        {normalizedData.number_of_completed_trips}
      </HeaderDetails.Item>
      <HeaderDetails.Item title="Expired">
        {normalizedData.number_of_expired_trips}
      </HeaderDetails.Item>
      {/* <HeaderDetails.Item
        title={
          <>
            Views<span className="dn di-xl"> (last 30 days)</span>
          </>
        }
      >
        {normalizedData.views}
      </HeaderDetails.Item> */}
      <HeaderDetails.Item title="Rating">
        {normalizedData.rating}
        <HeaderDetails.ItemDataAdditional>
          {normalizedData.number_of_reviews}
        </HeaderDetails.ItemDataAdditional>
      </HeaderDetails.Item>
      {/* <HeaderDetails.Item title="Total Revenue">
        {normalizedData.total_revenue}
      </HeaderDetails.Item> */}
    </HeaderDetails>
  );
};

export default HeaderDetailsLoader;
