import React, { createContext } from "react";
import ProTipsCollapseProvider from './ProTipsCollapseProvider';

export const ProTipsCollapseContext = createContext(null);

export const ProTipsCollapse = ({ className, mediaParams }) =>  (
  <ProTipsCollapseContext.Consumer>
    {context => (
      <ProTipsCollapseProvider {...context} className={className} mediaParams={mediaParams}/>
    )}
  </ProTipsCollapseContext.Consumer>
);
