import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import { FieldInput } from "components/form/FieldInput";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import classes from "./EditorToolbar.module.css";

const rotateLeftIcon = <span className={classes.rotateLeftIcon} />;

const rotateRightIcon = <span className={classes.rotateRightIcon} />;

const ImageCaptionField = ({ value, setValue }) => (
  <div className={`${classes.imageCaptionContainer} ml-a mr-m-80 r-m-24`}>
    <FieldInput
      placeholder="Image caption"
      helperMessage="(Optional)"
      value={value}
      maxLength={100}
      onChange={(e) => setValue(e.target.value)}
      className={`${classes.imageCaptionField} fw`}
    />
  </div>
);

export const EditorToolbar = (props) => {
  const {
    className,
    onRotateLeft,
    onRotateRight,
    onDone,
    hasImageCaption,
    initialImageCaption = ''
  } = props;

  const [imageCaptionValue, setImageCaptionValue] = useState(initialImageCaption);
  const isWidthSmall = useMatchMedia({ maxWidth: MQ_BP.small });
  return (
    <div className={className}>
      <div className={classes.toolbar}>
        { hasImageCaption && isWidthSmall && (
          <div className="t-20 b-20">
            <ImageCaptionField value={imageCaptionValue} setValue={setImageCaptionValue} />
          </div>
        )}
        <div className={classes.actions}>
          <div className="btn-group">
            <Button
              secondary
              label="Rotate Left"
              icon={rotateLeftIcon}
              iconPosition="before"
              noLabel={hasImageCaption ? 'xl' : 'm'}
              onClick={onRotateLeft}
            />
            <Button
              secondary
              label="Rotate Right"
              icon={rotateRightIcon}
              iconPosition="after"
              noLabel={hasImageCaption ? 'xl' : 'm'}
              onClick={onRotateRight}
            />
          </div>

          { hasImageCaption && !isWidthSmall && (
            <ImageCaptionField value={imageCaptionValue} setValue={setImageCaptionValue} />
          )}

          <div className={classes.done}>
            <Button label="Done" onClick={() => {
              onDone(imageCaptionValue);
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

EditorToolbar.propTypes = {
  className: PropTypes.string.isRequired,
  onRotateLeft: PropTypes.func.isRequired,
  onRotateRight: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired
};
