function getKey(id, type) {
  return `CWRR:firstView:${id}:${type}`;
}

/**
 * @param {number} id
 * @param {string} type
 * @return {boolean|null}
 */
export function readStorage(id, type) {
  try {
    return JSON.parse(window.localStorage.getItem(getKey(id, type)));
  } catch (e) {
    return null;
  }
}

/**
 * @param {number} rvId
 * @param {string} type
 * @param {boolean|null} flag
 */
export function writeStorage(id, type, flag = true) {
  try {
    if (flag === null) {
      window.localStorage.removeItem(getKey(id, type));
    } else {
      window.localStorage.setItem(getKey(id, type), flag);
    }
    return true;
  } catch (e) {
    return false;
  }
}
