import React, { useState, useCallback } from "react";
import { PhotoUploadArea } from "components/PhotoUploadArea";
import usePhotoUpload from "hooks/usePhotoUpload";
import { photoImageUploadMutation } from "processes/Rentals/RentalDetails/mutations/photoImageUploadMutation";
import { PhotoGallery } from "components/PhotoGallery";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { SpinnerBack } from "components/Spinner/Spinner";
import style from "./style.module.css";

function getPhotoIds(photos) {
  return photos.map(item => item.id);
}

const ReviewPhotosContainer = (props) => {
  const {
    rentalId,
    initialPhotos,
    updatePhotoIds
  } = props;

  const [showNotificationPopover, setShowNotificationPopover] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const { handlePhotoImageUpload, photoImageUploadLoading } = usePhotoUpload(photoImageUploadMutation, 'review_image_upload');
  const [items, setItems] = useState(initialPhotos);

  const handleReorder = useCallback(
    (updateItems) => {
      const nextItems = updateItems(items);
      const nextItemIds = getPhotoIds(nextItems);
      setItems(nextItems);
      updatePhotoIds(nextItemIds, null);
    },
    [updatePhotoIds, items, setItems]
  );

  const handleUpload = useCallback(async (values) => {
    const { images, errors } = await handlePhotoImageUpload(values, rentalId);

    const nextItems = [...items, ...images];
    const nextItemIds = getPhotoIds(nextItems);

    setItems([...items, ...images]);
    updatePhotoIds(nextItemIds, null);

    if (errors.length > 0) {
      setGlobalError(errors.join(", "));
      setShowNotificationPopover(true);
    }
  }, [
    handlePhotoImageUpload,
    rentalId,
    items,
    updatePhotoIds,
    setItems,
    setGlobalError,
    setShowNotificationPopover
  ]);

  const handleDelete = useCallback(
    (id) => {
      const nextItems = items.filter((item) => item.id !== id);
      const nextItemIds = getPhotoIds(nextItems);
      setItems(nextItems);
      updatePhotoIds(nextItemIds, id);
    },
    [items, setItems, updatePhotoIds]
  );

  return (
    <div>
      <PhotoUploadArea onUpload={handleUpload} />

      {items.length !== 0 && (
        <div className={style.photoGalleryContainer}>
          <PhotoGallery
            items={items}
            noPrimary
            onReorder={handleReorder}
            onDelete={handleDelete}
          />
        </div>
      )}

      {globalError && (
        <NotificationPopover
          show={showNotificationPopover}
          status="error"
          text={globalError}
          onClose={() => {
            setShowNotificationPopover(false);
            setGlobalError(null);
          }}
        />
      )}
      {photoImageUploadLoading && <SpinnerBack />}
    </div>
  );
};

export default ReviewPhotosContainer;


