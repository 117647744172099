import { ApolloClient, ApolloLink, from, Observable } from "@apollo/client";
import PushStream from 'zen-push';

class TrackRequests extends PushStream {
  constructor() {
    super();

    this._requestCounter = 0;
    this._lastValue = false;
  }

  update(delta) {
    this._requestCounter += delta;
    this._lastValue = this._requestCounter > 0;
    this.next(this._lastValue);
  }

  get lastValue() {
    return this._lastValue;
  }
}

const pushStream = new TrackRequests();

const requestTrackerLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    pushStream.update(1);

    forward(operation).subscribe({
      next: observer.next.bind(observer),
      error: observer.error.bind(observer),
      complete() {
        pushStream.update(-1);

        observer.complete();
      }
    });
  });
});

export class CustomClient extends ApolloClient {
  constructor(options) {
    super({
      ...options,
      link: from([
        requestTrackerLink,
        options.link
      ])
    });
  }

  /**
   * @return {Observable}
   */
  trackRequests() {
    return new Observable(observer => {
      observer.next(pushStream.lastValue);

      let timer = null;

      pushStream.observable.subscribe((value) => {
        if (value) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }

          observer.next(true);
        } else {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }

          timer = setTimeout(() => {
            observer.next(false);
          }, 200);
        }
      });
    });
  }
}
