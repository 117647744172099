import { gql } from "@apollo/client";

export const photoInstantRemoveMutation = gql`
  mutation photoInstantRemoveMutation (
    $rv_id: Int!
    $photo_id: Int!
  ) {
    rv_image_instant_remove (
      rv_id: $rv_id
      photo_id: $photo_id
    ) {
        result {
          success
          errors {
            code
            field
            message
          }
        }
    }
  }
`;
