import React from "react";
import Icon from "./Icon";

export const FiltersIcon = () => (
  <Icon>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.43747 6.75H4C3.44772 6.75 3 6.30228 3 5.75C3 5.23716 3.38604 4.81449 3.88338 4.75673L4 4.75H4.43747C4.8375 3.72566 5.83401 3 7 3C8.16599 3 9.1625 3.72566 9.56253 4.75H20C20.5523 4.75 21 5.19772 21 5.75C21 6.26284 20.614 6.68551 20.1166 6.74327L20 6.75H9.56253C9.1625 7.77434 8.16599 8.5 7 8.5C5.83401 8.5 4.8375 7.77434 4.43747 6.75ZM7 4.5C7.69036 4.5 8.25 5.05964 8.25 5.75C8.25 6.44036 7.69036 7 7 7C6.30964 7 5.75 6.44036 5.75 5.75C5.75 5.05964 6.30964 4.5 7 4.5ZM20 18.75H9.56253C9.1625 19.7743 8.16599 20.5 7 20.5C5.83401 20.5 4.8375 19.7743 4.43747 18.75H4C3.44772 18.75 3 18.3023 3 17.75C3 17.2372 3.38604 16.8145 3.88338 16.7567L4 16.75H4.43747C4.8375 15.7257 5.83401 15 7 15C8.16599 15 9.1625 15.7257 9.56253 16.75H20C20.5523 16.75 21 17.1977 21 17.75C21 18.2628 20.614 18.6855 20.1166 18.7433L20 18.75ZM17.5625 10.75C17.1625 9.72566 16.166 9 15 9C13.834 9 12.8375 9.72566 12.4375 10.75H4L3.88338 10.7567C3.38604 10.8145 3 11.2372 3 11.75C3 12.3023 3.44772 12.75 4 12.75H12.4375C12.8375 13.7743 13.834 14.5 15 14.5C16.166 14.5 17.1625 13.7743 17.5625 12.75H20L20.1166 12.7433C20.614 12.6855 21 12.2628 21 11.75C21 11.1977 20.5523 10.75 20 10.75H17.5625ZM8.25 17.75C8.25 17.0596 7.69036 16.5 7 16.5C6.30964 16.5 5.75 17.0596 5.75 17.75C5.75 18.4404 6.30964 19 7 19C7.69036 19 8.25 18.4404 8.25 17.75ZM15 10.5C15.6904 10.5 16.25 11.0596 16.25 11.75C16.25 12.4404 15.6904 13 15 13C14.3096 13 13.75 12.4404 13.75 11.75C13.75 11.0596 14.3096 10.5 15 10.5Z"
    />
  </Icon>
);
