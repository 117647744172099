import React from "react";
import PropTypes from "prop-types";
import Rating from "components/Rating";
import Typography from "components/Typography";
import ProfileInfoAsideAvatar from "./ProfileInfoAsideAvatar";
import ProfileInfoAsideList from "./ProfileInfoAsideList";
import classes from "./ProfileInfoAside.module.css";

const ProfileInfoAside = (props) => {
  const {
    name,
    rating
  } = props;

  return (
    <div aria-label={name} className={classes.container}>
      <div className='t-32 r-24 l-24 b-36 b-l-28 b-xl-36'>
        <div className={classes.avatar}>
          <ProfileInfoAsideAvatar {...props} />
        </div>

        <div className={classes.content}>
          <header className={classes.header}>
            <div className={classes.headerInner}>
              <div className="mb-xl-20">
                <Typography
                  variant="subtitle"
                  size="l"
                  component="strong"
                  weight="bold"
                >
                  <span className={classes.name}>
                    {name}
                  </span>
                </Typography>
              </div>
              <div className={`mb-xl-8 mt-xl-8 ${classes.ratingContainer}`}>
                <Rating value={rating} />
              </div>
            </div>
          </header>

          <div className="mt-xl-40">
            <ProfileInfoAsideList {...props} />
          </div>
        </div>

      </div>
    </div>
  );
};

ProfileInfoAside.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  rating: PropTypes.number,
  location: PropTypes.string,
  avatar: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  hasBadge: PropTypes.bool,
  profileLink: PropTypes.string.isRequired,
  onClickChat: PropTypes.func,
  chatLoading: PropTypes.bool
};

export default ProfileInfoAside;
