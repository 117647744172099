import React from "react";
import PropTypes from "prop-types";

const UserLink = ({ id, name }) => {

  return (
    <a className="underline fw-500" href={`/profile/${id}`} target="_blank" rel="noreferrer">
      {name}
    </a>
  );
};

UserLink.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string
};

export default UserLink;
