import React, { useCallback, useEffect, useState } from "react";
import cn from 'classnames';
import Typography from "components/Typography";
import { SpinnerBlur } from "components/Spinner/Spinner";

import SummaryPreview from '../SummaryPreview/SummaryPreview';
import SummaryTable from '../SummaryTable/SummaryTable';

import classes from './Summary.module.css';

const Summary = ({
  state,
  insuranceData,
  expiration_date,
  onChangeForm,
  onCalculatePrice,
  onSelectFee
}) => {

  useEffect(() => {
    onCalculatePrice();
  }, [onCalculatePrice]);

  if (state.priceLoading) {
    return (
      <SpinnerBlur />
    );
  }

  return (
    <div className={classes.wrapper}>
      <Typography
        component="h2"
        variant="headline"
        size="m"
        className={classes.title}
      >
        Build a quote
      </Typography>

      <div className={cn(classes.main, "row t-m-20")}>
        <div className={cn(classes.summary, "col")}>
          <SummaryPreview state={state} />
        </div>
        <div className={cn(classes.table, "col")}>
          <SummaryTable
            state={state}
            insuranceData={insuranceData}
            expiration_date={expiration_date}
            onChangeForm={onChangeForm}
            onSelectFee={onSelectFee}
          />
        </div>
      </div>
    </div>
  );
};

export default Summary;
