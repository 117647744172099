import { useRef, useEffect, useCallback } from "react";

function getComponentMethods(componentName) {
  const methods = window?.CWRR?.[componentName];

  return methods || {};
}

function useJqueryComponent(componentName, componentOptions = {}) {
  const { forwardedRef } = componentOptions;
  const inputRef = useRef(null);
  const ref = forwardedRef || inputRef;

  useEffect(() => {
    const node = ref.current;
    const {
      init: initComponent,
      destroy: destroyComponent,
      close: closeSelectMenu
    } = getComponentMethods(componentName);

    if (node && initComponent) {
      const { forwardedRef, ...options } = componentOptions;

      initComponent(node, options);
    }

    const handleSelectMenuClose = () => {
      closeSelectMenu(node);
    };

    if (componentName === "Select") {
      window.addEventListener("resize", handleSelectMenuClose);
      window.addEventListener("orientationchange", handleSelectMenuClose);
    }

    return () => {
      if (node && destroyComponent) {
        destroyComponent(node);
      }

      window.removeEventListener("resize", handleSelectMenuClose);
      window.removeEventListener("orientationchange", handleSelectMenuClose);
    };
  }, [ref, componentName, componentOptions]);

  const setOptions = useCallback(
    (options) => {
      const node = ref.current;
      const { setOption: setComponentOption } = getComponentMethods(
        componentName
      );

      if (node && setComponentOption) {
        Object.keys(options).forEach((key) =>
          setComponentOption(node, key, options[key])
        );
      }
    },
    [ref, componentName]
  );

  return [ref, setOptions];
}

export default useJqueryComponent;
