import React from 'react';

export const ContentSectionElementImage = ({ elementId, content, simple, ...rest }) => {
  const { srcset, alt } = content;

  if (simple) {
    return (
      <img
        id={elementId}
        src={srcset[0].url}
        alt={alt}
        {...rest}
      />
    );
  }

  return (
    <picture
      id={elementId}
      {...rest}
    >
      {srcset.map((item, index) => {
        if (index < srcset.length - 1) {
          return (
            <source key={index} srcSet={item.url} type={item.type}/>
          );
        }

        return (
          <img key={index} src={item.url} alt={alt}/>
        );
      })}
    </picture>
  );
};
