import React, { useMemo, useReducer } from "react";
import { ContentContext } from './ContentContext';

function reducer(dictionary, state) {
  return {
    ...state,
    ...dictionary
  };
}

export function ContentProvider(props) {
  const [content, setContent] = useReducer(reducer, {});

  const value = useMemo(() => [content, setContent], [content]);

  return (
    <ContentContext.Provider value={value} {...props} />
  );
}
