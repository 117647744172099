import { MyAccountContext } from "../../MyAccountContext";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { contentQuery } from "../../../../queries";
import FormContainer from "../../components/FormContainer/FormContainer";
import PayoutForm from "./PayoutForm";
import { SpinnerBlur } from "../../../../components/Spinner/Spinner";
import PayoutPreview from "./PayoutPreview";
import { useBasicTexts } from "./hooks";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const section = "account_section_payout_settings";

const PayoutDataProvider = (props) => {
  const { setAccountSection } = props;
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    true
  );
  const { loading, data } = useQuery(contentQuery, {
    variables: { section },
    fetchPolicy: "no-cache"
  });

  const content = data?.content;
  const texts = useBasicTexts(content);

  useEffect(() => {
    setAccountSection(section);
  }, []);

  useEffect(() => {
    if (!data?.content) {
      setShowNotificationPopover(true);
    }
  }, [data]);
  if (loading) {
    return <SpinnerBlur />;
  }

  if (!content) {
    return (
      <NotificationPopover
        show={isShowNotificationPopover}
        status="error"
        text="Content not found"
        onClose={() => {
          setShowNotificationPopover(false);
        }}
      />
    );
  }

  return (
    <FormContainer
      title={texts.title || ""}
      proTipsTitle={texts.proTipsTitle || ""}
      proTipsText={texts.proTipsText || ""}
    >
      <FormContent {...props} content={content} />
    </FormContainer>
  );
};

const FormContent = ({ content, userData, updateUserData }) => {
  const [isEditing, setIsEditing] = useState(
    !Boolean(userData?.bank_account?.account_number)
  );

  if (isEditing) {
    return (
      <PayoutForm
        userData={userData}
        updateUserData={updateUserData}
        content={content}
        onSave={() => setIsEditing(false)}
        isVerificationsRequired
      />
    );
  }

  return (
    <PayoutPreview
      userData={userData}
      content={content}
      onStartEditing={() => setIsEditing(true)}
    />
  );
};

export const Payout = () => (
  <MyAccountContext.Consumer>
    {(value) => <PayoutDataProvider {...value} />}
  </MyAccountContext.Consumer>
);
