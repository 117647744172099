import { useCallback, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { transactionHistoryQuery } from "../queries/transactionHistoryQuery";

export function useTransactionHistory() {
  const [loadTransactionHistory, { data, loading, error }] = useLazyQuery(transactionHistoryQuery, {
    fetchPolicy: "no-cache"
  });

  const onGetTransactionHistory = useCallback((variables) => {
    return loadTransactionHistory({
      variables: {
        ...variables
      }
    });
  }, [loadTransactionHistory]);

  const payouts = useMemo(() => {
    if (!data && !error) {
      return {
        loading,
        error: false,
        errorMessage: ""
      };
    }

    if (data?.tax_dashboard?.result?.success) {
      const payouts = data.tax_dashboard;
      return {
        data: payouts,
        loading,
        error: false,
        errorMessage: ""
      };
    }

    return {
      loading: false,
      error: true,
      errorMessage: (error && error.toString()) ||
        data?.tax_dashboard?.result?.errors[0].message ||
        "Server error"
    };
  }, [data, loading, error]);

  return [payouts, onGetTransactionHistory];
}
