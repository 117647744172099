import React, { useEffect, useState, useCallback, useRef } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { extractSuccess, extractServerErrors } from "utils/extractErrors";
import { ProfileContent } from "./ProfileContent";
import { profileImagesUploadMutation } from "../../mutations/profileImagesUploadMutation";
import useProfileImageCrop from "./hooks/useProfileImageCrop";
import { profileImageCropMutation } from "./mutations/profileImageCropMutation";
import { myProfileQueryAfterUpdate } from "./queries/myProfileQueryAfterUpdate";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";

const extractProfileImageUploadSuccess = extractSuccess("profile_image_upload");

const extractProfileImageUploadErrors = extractServerErrors(
  "profile_image_upload"
);

export const ProfileContentContainer = (props) => {
  const {
    content,
    myProfileData: {
      images: {
        profile_image,
        cover_image,
        profile_image_for_edit_tool: avatarEditTool,
        cover_image_for_edit_tool: coverEditTool
      }
    },
    userData: { needs_moderation },
    errorValue: errorData,
    setErrorValue: setErrorData,
    handleUpdateProfile
  } = props;

  const [isShowNotificationPopover, setShowNotificationPopover] = useState(
    false
  );
  const [errorValue, setErrorValue] = useState("");

  const [avatarUrl, setAvatarUrl] = useState(
    profile_image && profile_image.srcset && profile_image.srcset[0].url
  );
  const [avatarEditToolData, setAvatarEditToolData] = useState(avatarEditTool);

  const [coverContent, setCoverContent] = useState(cover_image);

  const [coverEditToolData, setCoverEditToolData] = useState(coverEditTool);

  const typeRef = useRef(null);
  const [profileUploadImage] = useMutation(profileImagesUploadMutation);
  const [
    myProfileQueryAfterUpdateLoad,
    { data: myProfileQueryAfterUpdateData }
  ] = useLazyQuery(myProfileQueryAfterUpdate, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (myProfileQueryAfterUpdateData) {
      setCoverEditToolData(
        myProfileQueryAfterUpdateData?.my_profile?.images
          ?.cover_image_for_edit_tool
      );
      setCoverContent(
        myProfileQueryAfterUpdateData?.my_profile?.images?.cover_image
      );

      setAvatarEditToolData(
        myProfileQueryAfterUpdateData?.my_profile?.images
          ?.profile_image_for_edit_tool
      );
      setAvatarUrl(
        myProfileQueryAfterUpdateData?.my_profile?.images?.profile_image
          ?.srcset[0]?.url
      );
    }
  }, [myProfileQueryAfterUpdateData]);

  const handleUpload = useCallback(
    async (values, type) => {
      const response = await profileUploadImage({
        variables: {
          type,
          image: values[0]
        }
      });

      if (extractProfileImageUploadSuccess(response)) {
        myProfileQueryAfterUpdateLoad();
      } else if (extractProfileImageUploadErrors(response)) {
        myProfileQueryAfterUpdateLoad();
        //setShowNotificationPopover(true);
        // setErrorValue()
        /* TODO: handle the error */
      }
    },
    [profileUploadImage, myProfileQueryAfterUpdateLoad]
  );

  const [handleDoneEditing, cropMutationData] = useProfileImageCrop(
    profileImageCropMutation
  );
  useEffect(() => {
    if (cropMutationData?.data && typeRef.current === "profile") {
      myProfileQueryAfterUpdateLoad();
      typeRef.current = null;
    } else if (cropMutationData?.data && typeRef.current === "cover") {
      typeRef.current = null;
      myProfileQueryAfterUpdateLoad();
    } else if (cropMutationData?.error) {
      /* TODO: handle the error */
      //setShowNotificationPopover(true);
      // setErrorValue()
      myProfileQueryAfterUpdateLoad();
      typeRef.current = null;
    }
  }, [cropMutationData, myProfileQueryAfterUpdateLoad]);

  useEffect(() => {
    if (errorData) {
      setShowNotificationPopover(true);
      setErrorValue(errorData);
    }
  }, [errorData, setErrorValue]);

  const handleAvatarUpload = useCallback(
    async (values, type) => {
      handleUpload(values, type);
    },
    [handleUpload]
  );

  const handleAvatarEdit = useCallback(
    (values, type) => {
      typeRef.current = type;
      handleDoneEditing(values, type);
    },
    [handleDoneEditing]
  );

  const handleCoverUpload = useCallback(
    async (values, type) => {
      handleUpload(values, type);
    },
    [handleUpload]
  );

  const handeCoverEdit = useCallback(
    async (values, type) => {
      typeRef.current = type;
      handleDoneEditing(values, type);
    },
    [handleDoneEditing]
  );

  const handleCoverDelete = useCallback((data) => {
    setCoverContent(null);
    handleUpdateProfile(data);
  }, [handleUpdateProfile]);

  return (
    <>
      <ProfileContent
        content={content}
        avatarUrl={avatarUrl}
        avatarEditToolData={avatarEditToolData}
        onAvatarUpload={handleAvatarUpload}
        onAvatarEdit={handleAvatarEdit}
        coverContent={coverContent}
        coverEditToolData={coverEditToolData}
        onCoverUpload={handleCoverUpload}
        onCoverEdit={handeCoverEdit}
        onCoverDelete={handleCoverDelete}
        needsModeration={needs_moderation}
        handleUpdateProfile={handleUpdateProfile}
        {...props}
      />
      {errorValue && (
        <NotificationPopover
          show={isShowNotificationPopover}
          status="error"
          text={errorValue}
          onClose={() => {
            setShowNotificationPopover(false);
            setErrorValue("");
            setErrorData("");
          }}
        />
      )}
    </>
  );
};
