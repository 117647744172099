import React, { useEffect, useRef, useMemo } from "react";
import classnames from "classnames";
import { usePipeAnimation } from "hooks/usePipeAnimation";
import { MenuPipeTitle } from "./components/MenuPipeTitle/MenuPipeTitle";
import cn from "classnames";
import classes from "./MenuPipe.module.css";

export const MenuPipe = (props) => {
  const {
    data,
    onClickItem,
    isOuterTabControl = false,
    tab = {},
    isTableTabs,
    disabledItems = [],
    tooltipData = [],
    hintData = [],
    variant = "default",
    size = 'default'
  } = props;

  const menuContainer = useRef(null);
  const updatePipeTab = useRef(null);
  const tabIndex = useMemo(() => tab?.tabIndex || 0, [tab]);
  updatePipeTab.current = usePipeAnimation(menuContainer, 'menuItem', tabIndex);

  useEffect(() => {
    updatePipeTab.current(tabIndex);
  }, [tabIndex]);

  const menuItems = data.map(({ id, title }, index) => {
    const isDisabled = disabledItems.includes(index);
    const isShowTooltip = tooltipData?.[index] && typeof tooltipData?.[index] === 'object' ? tooltipData?.[index]?.show : false;
    const tData = tooltipData?.[index] && typeof tooltipData?.[index] === 'object' ? tooltipData?.[index]?.data : {};

    const isShowHint = hintData?.[index] && typeof hintData?.[index] === 'object' ? hintData?.[index]?.show : false;
    const hData = hintData?.[index] && typeof hintData?.[index] === 'object' ? hintData?.[index]?.data : {};

    let onClick = () => {
      updatePipeTab.current(index);
      onClickItem && onClickItem(id, index);
    };
    if (isOuterTabControl) {
      onClick = () => {
        onClickItem && onClickItem(id, index);
      };
    } else if (isDisabled) {
      onClick = null;
    }

    return (
      <li
        key={id}
        className={classnames(
          `${classes.menuItem} menuItem`,
          isDisabled && classes.menuItemDisabled,
          variant === 'stretch' && classes.menuItemStretch,
          isTableTabs && classes.isTableTabs
        )}
        onClick={onClick}
      >
        <MenuPipeTitle
          isShowTooltip={isShowTooltip}
          tooltipData={tData}
          isShowHint={isShowHint}
          hintData={hData}
          isTableTabs={isTableTabs}
          title={title}
          size={size}
        />
      </li>
    );
  });

  return (
    <div className={classes.container}>
      <div className={cn(classes.menuContainer, isTableTabs && classes.isTableTabs)} ref={menuContainer}>
        <ul
          className={classnames(
            `${classes.menu} ui-menu`,
            variant === 'stretch' && classes.menuStretch,
            size === 'large' && classes.sizeLarge,
            size === 'middle' && classes.sizeMiddle,
            isTableTabs && classes.isTableTabs
          )}
        >
          {menuItems}
        </ul>
      </div>
    </div>
  );
};

MenuPipe.propTypes = {

};

export default MenuPipe;


