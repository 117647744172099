import React from "react";
import PropTypes from "prop-types";

const HeaderDetailsItemDataAdditional = ({ children }) => (
  <span className="listing-header-details__additional">({children})</span>
);

HeaderDetailsItemDataAdditional.propTypes = {
  children: PropTypes.node.isRequired
};

export default HeaderDetailsItemDataAdditional;
