import { pick } from "ramda";

/*
  year: "",
  manufacturer: "",
  make: "",
  manual: "yes"
  rvId: "",
  model: "",
  rvClass: ""

  1) year (4 digits), manufacturer, make — are required
     if these are defined, then we are on the step 2

  2) if we have manual == false, then we are in the list.
     rvId can be defined here to indicate selected model and class

  3) if manual == true, then rvId is undefined, but model and class must be filled in

 */

const pickFilter = pick(["year", "manufacturer", "make"]);
// const pickModel = pick(["model", "rvClass"]);

function isValidRequiredFields(filterValues) {
  return Object.values(filterValues)
    .map(v => !!v.trim())
    .every(v => v);
}

function isValidFilter(state) {
  const filterValues = pickFilter(state);
  const test1 = isValidRequiredFields(filterValues);
  const test2 = /^\d{4}$/.test(filterValues.year);

  return test1 && test2;
}

/**
 *
 * @param {Object}state object from location
 * @returns {number}
 */
export function calculateStep(state) {
  if (state.manual === "yes") {
    return STEP_MODEL;
  }

  if (state.manual !== "yes" && isValidFilter(state)) {
    return STEP_RV_ID;
  }

  return STEP_FILTER;
}

export const STEP_FILTER = 1;
export const STEP_RV_ID = 2;
export const STEP_MODEL = 3;
