import classes from "./Discount.module.css";
import { formatInteger } from "utils/formatNumber";

export const Discount = ({ price, total, discount }) => {
  const newPrice = price && formatInteger(price);
  const newTotal = total && formatInteger(total);

  return (
    <div className={classes.discountContainer}>
      <div className={classes.discountPriceContainer}>
        {discount && (
          <span className={classes.discount}>-{discount.toFixed()}%</span>
        )}
        {price && <span className={classes.discountPrice}>${newPrice}</span>}
      </div>

      {total && (
        <span className={classes.total}>
          $<b>{newTotal}</b> <span className={classes.totalText}>total</span>
        </span>
      )}
    </div>
  );
};
