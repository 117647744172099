import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const Icon = ({ className, src, children }) => {
  return (
    <svg className={classnames(className, "icon")} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      {src ? <use href={src} /> : children}
    </svg>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.node
};

export default Icon;
