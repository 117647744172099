import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Switch = forwardRef((props, ref) => {
  const {
    id,
    text,
    disabled,
    value,
    onChange,
    mobileVariant,
    ...inputProps
  } = props;

  const classNames = classnames("switch", `mobile--${mobileVariant}`, {
    disabled
  });

  const handleChange = (e) => {
    if (onChange) onChange(e.target.checked);
  };

  return (
    <label htmlFor={id} className={classNames}>
      <input
        id={id}
        type="checkbox"
        className="switch__input"
        checked={value}
        onChange={handleChange}
        disabled={disabled}
        {...inputProps}
      />
      <span className="switch__toggle" />
      {text && (
        <span className="switch__label">{text}</span>
      )}
    </label>
  );
});

Switch.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  mobileVariant: PropTypes.oneOf(["base", "stretch-reverse"])
};

export default Switch;
