import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useJqueryComponent } from "../../../hooks";
import { createCachedSelector } from "re-reselect";

const optionSelector = createCachedSelector(
  (id, forwardedRef) => forwardedRef,
  (id, forwardedRef) => ({
    forwardedRef
  })
)((id) => id);

const FieldPhone = forwardRef((props, forwardedRef) => {
  const {
    id,
    label,
    helperMessage,
    className,
    hasError,
    errorMessage,
    disabled,
    ...inputProps
  } = props;

  const [ref] = useJqueryComponent(
    "FieldPhone",
    optionSelector(id, forwardedRef)
  );

  const fieldClassNames = classnames(
    "field-input",
    "field-input--phone",
    {
      "no-label": !label,
      disabled,
      filled: !!label,
      error: hasError
    },
    className
  );

  const hint = errorMessage || helperMessage;

  return (
    <div className={fieldClassNames}>
      <input
        ref={ref}
        className="field-input__field js-field-state"
        id={id}
        type="text"
        autoComplete="off"
        {...inputProps}
      />
      {label && (
        <label htmlFor={id} className="field-input__label">
          {label}
        </label>
      )}
      {hint && (
        <div className="field-input__helpers">
          <div className="field-input__hint">{hint}</div>
        </div>
      )}
    </div>
  );
});

FieldPhone.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperMessage: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.node,
  className: PropTypes.string
};

export default FieldPhone;
