import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const rvPublish = gql`
${baseFragments.result}
  mutation rv_publish($id: Int!) {
    rv_publish(id: $id) {
      result {
        ...Result
      }
    }
  }
`;
