import { gql } from "@apollo/client";

export const tokenQuery = gql`
  query {
    chat_token {
      access_token
      result {
        success
        errors {
          field
          message
        }
      }
    }
  }
`;
