import React from "react";
import { Footer } from "./Footer";

export const CalendarModalFooter = (props) => {
  const {
    discount,
    from,
    to,
    total,
    price,
    isLoading,
    isShowDiscount,
    isDateIntervalError,
    dateIntervalErrorMessage,
    onCloseCalendar,
    onClickSelect,
    classes
  } = props;

  return (
    <div className={classes.calendarFooter}>
      <div className="container">
        <div className="row">
          <div className="col-xxl-10 offset-xxl-1">
            <div

              className={`${classes.calendarFooterInner} ${classes.gutterRemover}`}
            >
              <Footer
                period={{ from, to }}
                discount={discount}
                total={total}
                price={price}
                isLoading={isLoading}
                isShowDiscount={isShowDiscount}
                isDateIntervalError={isDateIntervalError}
                dateIntervalErrorMessage={dateIntervalErrorMessage}
                onClickCancel={onCloseCalendar}
                onClickSelect={onClickSelect}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
