import { useCallback, useState } from "react";

export function useFieldFocus(onFocus, onBlur) {
  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(
    e => {
      setFocused(true);

      if (onFocus) {
        onFocus(e);
      }
    },
    [setFocused, onFocus]
  );

  const handleBlur = useCallback(
    e => {
      setFocused(false);

      if (onBlur) {
        onBlur(e);
      }
    },
    [setFocused, onBlur]
  );

  return [focused, handleFocus, handleBlur];
}
