import { gql } from "@apollo/client";

export const membershipDetailsQuery = gql`
  query membership_details {
    membership_details {
      club_id
      club_name
      club_icon
      club_description
      is_member
      plan_type
      activation_date
      expiration_date
      member_number
      auto_renew
      join_button_caption
      join_button_url
      built_in_join_process
      validation_link_caption
      validation_link_url
      built_in_validation_process
      manage_button_caption
      manage_button_url
      renew_button_caption
      renew_button_url
    }
  }
`;
