import React from "react";
import PropTypes from "prop-types";
import Typography from "../Typography";

const PageError = (props) => {
  const { heading, error } = props;

  return (
    <div className="tac t-60 b-60">
      <Typography variant="headline" size="l">
        {heading}
      </Typography>
      <Typography variant="title" size="m">
        {typeof error === "string" ? error : error.toString?.() || "Unknown"}
      </Typography>
    </div>
  );
};

PageError.defaultProps = {
  heading: "An error occurred"
};

PageError.propTypes = {
  heading: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default PageError;
