import { useState, useEffect, useCallback } from "react";
import AddOns from "./AddOns/AddOns";
import AdditionalInsurance from "./AdditionalInsurance/AdditionalInsurance";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import BookingPaymentOptions from "./BookingPaymentOptions/BookingPaymentOptions";
import {
  setSelectedToStep,
  setCompletedToStep,
  setSelectedAndWasSelectedToStep,
  isLastStep,
  setSelectedToStepById,
  getStepIndexById
} from "components/StepsMenu/utils";

export const STEPS = {
  ADDONS: {
    ID: 'addons',
    NAME: 'Add-ons'
  },
  ADDITIONAL_INSURANCE: {
    ID: 'additional-insurance',
    NAME: 'Additional insurance'
  },
  PAYMENT_METHOD: {
    ID: 'payment-method',
    NAME: 'Payment method'
  },
  PAYMENT_OPTIONS: {
    ID: 'payment-options',
    NAME: 'Payment options'
  }
};

const stepsList = [
  {
    id: STEPS.ADDONS.ID,
    title: STEPS.ADDONS.NAME,
    component: AddOns,
    index: 0,
    stepNumber: 1,
    wasSelected: false,
    completed: false,
    selected: true,
    isHidden: (rvData) => rvData?.add_ons?.length
  },
  {
    id: STEPS.ADDITIONAL_INSURANCE.ID,
    title: STEPS.ADDITIONAL_INSURANCE.NAME,
    component: AdditionalInsurance,
    index: 1,
    stepNumber: 2,
    wasSelected: false,
    completed: false,
    selected: false,
    isHidden: (_, addInsuranceList, isTII) => isTII || Boolean(addInsuranceList?.insurances?.length)
  },
  {
    id: STEPS.PAYMENT_METHOD.ID,
    title: STEPS.PAYMENT_METHOD.NAME,
    component: PaymentMethod,
    index: 2,
    stepNumber: 3,
    wasSelected: false,
    completed: false,
    selected: false
  },
  {
    id: STEPS.PAYMENT_OPTIONS.ID,
    title: STEPS.PAYMENT_OPTIONS.NAME,
    component: BookingPaymentOptions,
    index: 3,
    stepNumber: 4,
    wasSelected: false,
    completed: false,
    selected: false
  }
];

function filterStepsData(data, addInsuranceList, isTII, steps) {
  return steps
    .filter(step => step?.isHidden ? step?.isHidden(data, addInsuranceList, isTII) : step);
}

function getInitialStepsData(data, addInsuranceList, isTII, steps) {
  let initialStepIndex = 0;

  return steps
    .filter(step => step?.isHidden ? step?.isHidden(data, addInsuranceList, isTII) : step)
    .map((step, stepIndex) => ({
      ...step,
      index: initialStepIndex++,
      stepNumber: initialStepIndex,
      selected: !stepIndex ? true : false,
      wasSelected: !stepIndex ? true : false
    }));
}

export const useBookingModalSteps = (rvData, addInsuranceList, isTII) => {
  const [steps, changeSteps] = useState(getInitialStepsData(rvData, addInsuranceList, isTII, stepsList));
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const onClickStep = useCallback(({ steps, selectedStepIndex }) => {
    changeSteps(setSelectedToStep(steps, selectedStepIndex));
    setCurrentStepIndex(selectedStepIndex);
  }, []);

  const onClickStepById = useCallback((stepId) => {
    changeSteps(setSelectedToStepById(steps, stepId));
    setCurrentStepIndex(getStepIndexById(steps, stepId));
  }, [steps]);

  const onNextStep = useCallback(() => {
    changeSteps(setCompletedToStep(setSelectedAndWasSelectedToStep(steps, currentStepIndex + 1), currentStepIndex));
    if (!isLastStep(steps, currentStepIndex)) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }, [currentStepIndex, steps]);

  const onPrevStep = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  useEffect(() => {
    if (addInsuranceList && rvData) {
      if (!currentStepIndex) {
        changeSteps(getInitialStepsData(rvData, addInsuranceList, isTII, stepsList));
      } else {
        changeSteps(filterStepsData(rvData, addInsuranceList, isTII, steps));
      }
    }
  }, [addInsuranceList, currentStepIndex, isTII, rvData]);

  return {
    steps,
    currentStepIndex,
    onClickStep,
    onClickStepById,
    onNextStep,
    onPrevStep
  };
};
