import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import { closeIcon } from "components/Icon";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import classnames from "classnames";
import classes from "./FilterResetButton.module.css";

const FilterResetButton = ({
  hasSetFilters,
  activeFilters,
  resetAllFilters,
  mapOpen
}) => {
  const isMediumOrLess = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isLarge = useMatchMedia({
    minWidth: MQ_BP.large,
    maxWidth: MQ_BP.xLarge
  });
  const isSmallFilters = isMediumOrLess || (mapOpen && isLarge);

  const hasActiveFilters = hasSetFilters(activeFilters);
  return (
    <div className={`${classes.buttonResetFilters} l-8 l-m-4 l-l-8 l-xl-4 l-xxl-8`}>
      <Button
        secondary
        icon={closeIcon}
        className={classnames(
          classes.buttonResetFilter,
          hasActiveFilters && classes.buttonResetFilterActive
        )}
        disabled={!hasActiveFilters}
        onClick={resetAllFilters}
        size={isSmallFilters ? "medium" : undefined}
      />
    </div>
  );
};

FilterResetButton.propTypes = {
  amenities: PropTypes.array.isRequired
};

export default FilterResetButton;
