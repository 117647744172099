import React from "react";
import PropTypes from "prop-types";

const HeaderDetailsItem = ({ title, children }) => (
  <div className="listing-header-details__item">
    <div className="listing-header-details__title">{title}</div>
    <div className="listing-header-details__data">{children}</div>
  </div>
);

HeaderDetailsItem.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default HeaderDetailsItem;
