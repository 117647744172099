import { useCallback, useMemo } from "react";
import { calculatePrice } from "./utils";
import { useLazyQuery } from "@apollo/client";
import { calculatePriceQuery } from "./queries/calculatePriceQuery";

export function useCalculatePrice(rvId, currentRental) {
  const [loadCalculatePrice, { data, loading, error }] = useLazyQuery(calculatePriceQuery, {
    fetchPolicy: "no-cache"
  });

  const onCalculatePrice = useCallback((variables) => {
    return loadCalculatePrice({
      variables: {
        rv: rvId,
        current_rental: currentRental,
        ...variables
      }
    });
  }, [loadCalculatePrice, rvId, currentRental]);

  const value = useMemo(() => {
    if (!data && !error) {
      return {
        loading,
        error: false,
        errorCode: null,
        errorMessage: ""
      };
    }

    if (data?.calculate_price?.result?.success) {
      const prices = data.calculate_price.price;
      return {
        data: prices,
        price_form_detailed: data.calculate_price.price_form_detailed,
        price_form_your_suggestion: data.calculate_price.price_form_your_suggestion,
        loading,
        error: false,
        errorMessage: "",
        errorCode: null,
        ...calculatePrice(prices)
      };
    }
    const errorCode = data?.calculate_price?.result?.errors?.[0]?.code;
    return {
      loading: false,
      error: true,
      errorCode,
      errorMessage: (error && error.toString()) ||
        data?.calculate_price?.result?.errors[0].message ||
        "Server error"
    };
  }, [data, loading, error]);

  return [value, onCalculatePrice];
}
