import React from "react";
import PropTypes from "prop-types";

const CollapseIcon = (props) => {
  const { className, size = 24, isActive = false } = props;

  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transition: "transform .2s",
          transform: `rotate(${isActive ? 180 : 0}deg)`
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.88 9.29L12 13.17 8.12 9.29a.996.996 0 10-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 000-1.41c-.39-.38-1.03-.39-1.42 0z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

CollapseIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  isActive: PropTypes.bool
};

export default CollapseIcon;
