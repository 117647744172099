import React, { useMemo } from "react";
import { ChatContext } from "./ChatContext";
import { useApiClient, useUnreadMessagesState } from "./api";
import { useThreads } from "./hooks/useThreads";

function ChatProvider(props) {
  const { chatOpponents } = props;

  const {
    apiClient,
    initError
  } = useApiClient();

  const {
    loading: loadingThreads,
    list: threads,
    activeThread,
    selectThread,
    getThreadInfo,
    setActiveThreadIfExists
  } = useThreads({ apiClient, chatOpponents });

  const {
    totalUnread,
    hasUnreadMessages,
    getThreadUnreadCount
  } = useUnreadMessagesState({ threads, activeThread });

  const value = useMemo(() => ({
    apiClient,
    initError,
    loadingThreads,
    threads,
    getThreadInfo,
    activeThread,
    selectThread,
    totalUnread,
    hasUnreadMessages,
    getThreadUnreadCount,
    setActiveThreadIfExists
  }), [
    apiClient,
    initError,
    loadingThreads,
    threads,
    getThreadInfo,
    activeThread,
    selectThread,
    totalUnread,
    hasUnreadMessages,
    getThreadUnreadCount,
    setActiveThreadIfExists
  ]);

  return (
    <ChatContext.Provider value={value} {...props} />
  );
}

export default ChatProvider;
