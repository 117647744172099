import React, { useState } from "react";
import Button from "components/Button";
import { Reviews } from "components/Reviews/Reviews";
import classes from "./ReviewsShowMore.module.css";

const COUNT_DEFAULT = 3;

export const ReviewsShowMore = (props) => {
  const { data, count, countDefault = COUNT_DEFAULT, hasSelects, selectVehicleOptions } = props;
  const [showFullData, setShowFullData] = useState(false);

  const buttonLabel = showFullData ?  `Show Less All Reviews` : `Show All Reviews (${count})`;
  const showButton = count > countDefault;
  let initialData = data;
  if (count > countDefault) initialData = data.slice(0, countDefault);

  return (
    <div className={`${classes.container}`}>

      <Reviews
        data={showFullData ? data : initialData}
        count={count}
        hasSelects={hasSelects}
        selectVehicleOptions={selectVehicleOptions}
      />

      { showButton && (
        <div className="t-16 b-16 t-m-20 b-m-20 mt-m-20">
          <Button
            label={buttonLabel}
            onClick={() => setShowFullData((p) => !p)}
            secondary
          />
        </div>
      )}
    </div>
  );
};

ReviewsShowMore.propTypes = {

};

