import React from "react";
import { PhotoImage } from "components/PhotoImage";
import classes from "./PhotoCoverImage.module.css";

export const PhotoCoverImage = ({
  content,
  onEdit,
  onDelete,
  disabled,
  className
}) => {
  return (
    <PhotoImage
      cover
      className={`${classes.cover} ${className}`}
      content={content}
      onEdit={onEdit}
      onDelete={onDelete}
      disabled={disabled}
    />
  );
};
