import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Typography = ({
  component,
  variant,
  size,
  weight,
  className: propsClassNames,
  children,
  ...props
}) => {
  const Component = component;
  const classNames = classnames(propsClassNames, {
    [`${variant}`]: Boolean(variant),
    [`${variant}--${size}`]: Boolean(variant && size),
    [`${variant}--${weight}`]: Boolean(variant && weight)
  });

  return (
    <Component className={classNames} {...props}>
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  component: "p"
};

Typography.propTypes = {
  component: PropTypes.string,
  variant: PropTypes.oneOf([
    "headline",
    "title",
    "subtitle",
    "body",
    "overline",
    "caption"
  ]),
  size: PropTypes.oneOf([
    "xxl",
    "xl",
    "l",
    "m",
    "s",
    "xs",
    "l-fixed",
    "m-fixed",
    "s-fixed"
  ]),
  className: PropTypes.string,
  weight: PropTypes.oneOf(["bold", "normal"])
};

export default Typography;
