import React, { useState } from "react";
import classnames from "classnames";

import style from "./style.module.css";

const InputRating = ( { value, onChange, maxValue = 5, canChange } ) => {

  const [hovered, setHovered] = useState(0);
  const handleChangeHover = (star) => {
    setHovered(star);
  };

  const starsArray =
      Array.from({ length: maxValue }, (_, index) => (
        <div
          key={index.toString()}
          className={
            classnames(
              style.ratingStar, (index < value || index  < hovered)  ? style.ratingStar__full : style.ratingStar__empty,
              canChange && style.canChange,
              'mr-m-4'
            )
          }
          onMouseOver={() =>  handleChangeHover(index+1)}
          onMouseLeave={() =>  handleChangeHover(0)}
          onClick={() =>  onChange(index+1)}
        />
      ));


  return (
    <div className={classnames(style.inputRating, "fcc")}>
      {starsArray}
    </div>
  );
};

export default InputRating;
