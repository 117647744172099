/*
 * Payment Form Hooks
 */

import { useMemo } from "react";
import { extractText } from "../../../../utils/extractText";

export const useBasicTexts = (content) => {
  return useMemo(() => {
    if (content) {
      const e = (key) => extractText(content.text, key);
      return {
        title: e("section_title"),
        proTipsTitle: e("pro_tips_section_title"),
        proTipsText: e("pro_tips_section_text")
      };
    }
    return {};
  }, [content]);
};
