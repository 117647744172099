import { gql } from '@apollo/client';

export const emailVerifyMutation = gql`
  mutation email_verify ($params_url: String) {
     email_verify (params_url: $params_url) {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
      user {
        email
        email_verified
      }
    }
  }
`;
