import { gql } from "@apollo/client";

export const sendPhoneVerificationCodeMutation = gql`
  mutation sendPhoneVerificationCode {
    send_phone_verification_code {
      result {
        success,
        errors {
          code,
          field,
          message,
          section
        }
      }
    }
  }
`;
