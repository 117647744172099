import React, { useState } from "react";
import PropTypes from "prop-types";
import { FieldInput } from "components/form/FieldInput";
import { calendarIcon } from "components/Icon";
import { parseDateOfBirthMask } from "components/form/DateOfBirthInput/utils/parseDateOfBirthMask";
import classes from "./DateOfBirthInput.module.css";

function isAllowedCharacter(value, char) {
  const firstCase = value.length === 1;
  const secondCase = (Number.isNaN(Number(char)) && typeof Number(char) === 'number');
  const thirdCase = char === '/';
  return firstCase && (secondCase || thirdCase);
}

function setVisibilityPh(eventName, value, cb) {
  switch (eventName) {
  case 'change':
    const lastCharacter = value.slice(-1);
    if (isAllowedCharacter(value, lastCharacter) || !value) {
      cb(true);
    } else {
      cb(false);
    }
    break;
  case 'focus':
    if (!value) {
      cb(true);
    } else {
      cb(false);
    }
    break;
  default:
    if (!value) {
      cb(false);
    }
  }
}

const DateOfBirthInput = (props) => {
  const {
    name = "dateOfBirth",
    id = "dateOfBirth",
    label = "Date of birth",
    disabled = false,
    errors,
    renderProps,
    getValues,
    setError,
    clearErrors
  } = props;

  const [showPlaceholder, setShowPlaceholder] = useState(false);
  return (
    <div className={classes.container}>
      <span className={classes.dateIcon}>{calendarIcon}</span>
      <span
        className={`
          ${classes.placeholder} ${showPlaceholder && classes.isShowPlaceholder}`
        }>
        MM / DD / YYYY
      </span>
      <FieldInput
        className="fw"
        id={id}
        label={label}

        disabled={disabled}
        {...renderProps}
        errors={errors}
        onFocus={(event) => setVisibilityPh('focus', event.target.value, setShowPlaceholder)}
        onBlur={(event) => setVisibilityPh('blur', event.target.value, setShowPlaceholder)}
        onChange={(event) => {
          const curValue = event.target.value;
          const prevValue = getValues(name);
          const setValue =  renderProps.onChange;

          parseDateOfBirthMask(curValue, prevValue, setValue, setError, clearErrors, name);
          setVisibilityPh('change', curValue, setShowPlaceholder);
        }}
      />
    </div>
  );
};

DateOfBirthInput.displayName = "DateOfBirthInput";

DateOfBirthInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  renderProps: PropTypes.object,
  getValues: PropTypes.func
};

export default DateOfBirthInput;
